import * as _ from "lodash";
import { makeUtcCustomPeriod } from "services/project-utils";
import { AllUniversalOutputValues } from "components/filters/types";

export type SubmitValues = Pick<
    AllUniversalOutputValues,
    "currency" | "period"
> & {
    registrationDate?: null | string;
};

export const generalQueryParams = (filter: SubmitValues) => {
    const { currency, period, registrationDate } = filter;

    const { start, end } = makeUtcCustomPeriod(period.start, period.end);

    return {
        currencyId: currency && _.parseInt(currency.id),
        startPeriod: (start && start.toISOString()) || "",
        endPeriod: (end && end.toISOString()) || "",
        methood: "get",
        registrationDate,
    };
};
