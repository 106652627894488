import { AdminWrapper } from "styled/layouts";
import { Tabs } from "components/tabs";
import { Tab } from "components/tabs/components/tab";
import { Title } from "services/styled-components/typogoraphy";
import { useTranslation } from "react-i18next";
import { Table } from "./table";

export const PartnerSites = ({ partnerId }: { partnerId: number }) => {
    const [__] = useTranslation();
    return (
        <AdminWrapper>
            <Title>{__("Партнерские сайты")}</Title>
            <Tabs name="partnerSites" default={0}>
                <Tab title={__("Активные")} key={0}>
                    <Table key={0} partnerId={partnerId} isVisible />
                </Tab>
                <Tab title={__("Скрытые")} key={1}>
                    <Table key={1} partnerId={partnerId} />
                </Tab>
            </Tabs>
        </AdminWrapper>
    );
};
