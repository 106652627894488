import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { FormDirectionRow, FormGroup, FormGroupButton } from "styled/layouts";
import { Field, Form } from "components/formik";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";
import { toast } from "react-toastify";
import { ErrorType } from "server/errors/error-type";
import { errorHandler } from "services/error-handler";
import {
    AddFile,
    getFileNameWithoutExtention,
    uploadFile,
} from "views/manager/advertising/components/add-file";
import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { useObjectValidations } from "validation/validaion";

interface IProps {
    onLoaded: () => void;
}

export const LoadFile = (props: IProps) => {
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT } = useTranslationList();
    const { fileRule } = useObjectValidations();

    const accept = ".xls, .xlsx";
    const validationSchema = yup.object().shape({
        fileName: yup
            .string()
            .required(FIELD_SHOULD_BE_FILLED)
            .matches(/^[a-zA-ZА-Яа-я0-9 _\-()]{1,50}$/, INVALID_FORMAT),
        file: fileRule(accept),
    });

    return (
        <FormikConsoleErrors
            initialValues={{
                fileName: "",
                file: null as File | null,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async values => {
                try {
                    if (values.file) {
                        await uploadFile(
                            "advertisingpayment/addadvertisementpaymentordersfromfile",
                            values.file,
                            { FileName: values.fileName },
                        );
                        toast.success(__("Файл успешно загружен"));
                        props.onLoaded();
                    }
                } catch (e: any) {
                    void errorHandler(e, error => {
                        switch (error.data.code) {
                            // приме
                            case ErrorType.INVALID_FILE_STRUCTURE:
                                toast.error(__("Неверная структура файла."));
                                break;
                        }
                    });
                }
            }, validationSchema)}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <FormDirectionRow>
                        <FormGroup>
                            <Field
                                name="fileName"
                                type="text"
                                label={__("Имя файла")}
                                placeholder={__("Имя файла")}
                                style={{ minWidth: "200px" }}
                            />
                        </FormGroup>
                        <AddFile
                            name="file"
                            values={values}
                            setFieldValue={(field, value) => {
                                setFieldValue(field, value);
                                if (value) {
                                    setFieldValue(
                                        "fileName",
                                        getFileNameWithoutExtention(
                                            (value as File).name,
                                        ),
                                    );
                                }
                            }}
                            accept={accept}
                            buttonType="clean"
                            placeholder={__("*.xls или *.xlsx")}
                            stylesFileField={{ minWidth: "300px" }}
                        />
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>{__("Загрузить")}</SubmitButton>
                        </FormGroupButton>
                    </FormDirectionRow>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
