import { css } from "styled-components";

const fontsPath = "../assets/fonts/play/";

export const PlayFonts = css`
    @font-face {
        font-family: "Play";
        src: url("${fontsPath}Play-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
`;
