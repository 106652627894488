import { useMemo } from "react";
import {
    LanguageActive,
    LanguageActiveWrapper,
    LanguageList,
    LanguageSwitcherSC,
    StyledLinkBasedOnLink,
} from "./styled";
import { useLanguageSwitcher } from "./use-language-switcher";
export { CustomLanguage } from "./custom-language-styled";

interface IProps {
    className?: string;
    sectionAdminPartner?: boolean;
    textColor: string;
    bgColor: string;
    accentColor: string;
    textColorHover?: string;
    countryFontWeight?: number;
    screen: "mobile" | "desktop";
    listHeight?: string;
}

export const LanguageSwitcher = React.memo((props: IProps) => {
    const {
        sortedLanguageList,
        curLanguage,
        setCurLanguage,
        routeLocation,
        createLanguageRedirectRouteLocation,
        refLanguageSwitcher,
        listOpened,
        setListOpened,
    } = useLanguageSwitcher();

    const languageItems = useMemo(() => {
        return sortedLanguageList.map(lang => (
            <li key={lang.code}>
                <StyledLinkBasedOnLink
                    to={createLanguageRedirectRouteLocation(
                        curLanguage,
                        lang,
                        routeLocation,
                    )}
                    data-title={lang.shortName}
                    data-country={lang.flag}
                    onClick={(e: any) => {
                        setCurLanguage(lang);
                        setListOpened(false);
                        e.preventDefault();
                        return false;
                    }}
                    lang={lang.code}
                >
                    <span>{lang.nativeName}</span>
                </StyledLinkBasedOnLink>
            </li>
        ));
    }, [
        curLanguage,
        routeLocation,
        setCurLanguage,
        createLanguageRedirectRouteLocation,
        setListOpened,
        sortedLanguageList,
    ]);

    return (
        <LanguageSwitcherSC
            ref={refLanguageSwitcher}
            onClick={e => {
                setListOpened(!listOpened);
                e.stopPropagation();
            }}
            opened={listOpened}
            className={props.className}
            sectionAdminPartner={props.sectionAdminPartner}
            textColor={props.textColor}
            accentColor={props.accentColor}
            screen={props.screen}
        >
            <LanguageActiveWrapper
                opened={listOpened}
                textColor={props.textColor}
            >
                <LanguageActive
                    data-title={curLanguage.shortName}
                    data-country={curLanguage.flag}
                    opened={listOpened}
                    role="button"
                    tabIndex={0}
                    accentColor={props.accentColor}
                    countryFontWeight={props.countryFontWeight}
                    screen={props.screen}
                >
                    <span>{curLanguage.nativeName}</span>
                </LanguageActive>
            </LanguageActiveWrapper>
            <LanguageList
                role="list"
                opened={listOpened} /* isHidden={!listOpened} */
                textColor={props.textColor}
                bgColor={props.bgColor}
                accentColor={props.accentColor}
                textColorHover={props.textColorHover}
                screen={props.screen}
                listHeight={props.listHeight}
            >
                {languageItems}
            </LanguageList>
        </LanguageSwitcherSC>
    );
});
