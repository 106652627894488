import {
    Formik,
    FormikConfig,
    FormikProps,
    FormikValues,
    useFormikContext,
} from "components/formik";
import { useConsoleErrors } from "../use-formik-console-errors";

export interface IFormikConsoleErrorsProps<T> {
    children: React.ReactNode | ((props: FormikProps<T>) => React.ReactNode);
}

const ConsoleErrors = <Values extends FormikValues>({
    children,
}: IFormikConsoleErrorsProps<Values>) => {
    const context = useFormikContext<Values>();
    const { errors, submitCount } = context;
    useConsoleErrors(errors, submitCount);
    return <>{typeof children === "function" ? children(context) : children}</>;
};

export const FormikConsoleErrors = <
    Values extends FormikValues,
    ExtraProps = Record<string, unknown>,
>(
    props: FormikConfig<Values> & ExtraProps,
) => {
    const { children, ...rest } = props;

    return (
        <Formik {...rest}>
            <ConsoleErrors>{children}</ConsoleErrors>
        </Formik>
    );
};
