export const polishedWrapper = (value: any) => {
    function polished<T>(func?: (...args: T[]) => any, ...args: T[]) {
        if (!func) {
            return (props: any) => value;
        }
        return (props: any) => func(value, ...args);
    }
    return polished;
};

export const backgroundColor0 = (props: any) => "#ffffff";
export const backgroundColor3 = (props: any) => "#262626";

export const backgroundColor4Polished = polishedWrapper("#3c9fdd");

export const infoColor = (props: any) => "#9fa8bc";
export const infoBgColor = (props: any) => "#CFD8DC";
export const successColor = (props: any) => "#AED581";
export const successBgColor = (props: any) => "#C8E6C9";
export const warningColor = (props: any) => "#FFF59D";
export const dangerColor = (props: any) => "#ff5f5f";
export const dangerBgColor = (props: any) => "#ef9a9a";
export const dangerTextColor = (props: any) => "#b71c1c";
export const inverseColor = (props: any) => "#000";
export const disabledColor = (props: any) => "#d5d8da";
export const spinnerColor = (props: any) => "#3c9fdd";
export const activeColor = (props: any) => "#45b8ff";
export const modalColor = (props: any) => "#3c9fdd";
export const focusColor = (props: any) => "#4d90fe";
export const inputColor = (props: any) => "#5a6e81";
export const titleColor = (props: any) => "#16202a";

export const adminBorderColor = (props: any) => "#e8e8e8"; // elements borders in admin panel
export const adminBorderColor2 = (props: any) => "#a0a6ab"; // input border
export const adminBorderColor3 = (props: any) => "#eeeff3"; // td
