import { Roller } from "components/loaders/roller";

export const PaginationItem = ({
    current,
    page,
    loading,
    type,
    element,
}: any) => {
    if (type === "page") {
        return loading && page && page + 1 === current ? (
            <div>
                <Roller color="#fff" />
            </div>
        ) : (
            <div>{current}</div>
        );
    }

    return element;
};
