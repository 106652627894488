import { disabledColor } from "services/styled-components/themes";
import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";
import { BtnColor } from "styled/button";
import { IProps, IButtonColoredProps } from "styled/button";

const getButtonType = (props: IProps) => {
    switch (props.btnColor) {
        case BtnColor.Extra:
            return css`
                border-color: ${props.theme.additionalColor4};
                background-color: ${props.theme.additionalColor4};
            `;
        case BtnColor.Special:
            return css`
                border-color: ${props.theme.additionalColor5};
                background-color: ${props.theme.additionalColor5};
            `;
        case BtnColor.Faded:
            return css`
                border-color: ${props.theme.additionalColor1};
                background-color: ${props.theme.additionalColor1};
            `;
        case BtnColor.Gloomy:
            return css`
                border-color: ${props.theme.frontBackgroundColor};
                background-color: ${props.theme.frontBackgroundColor};
            `;
    }
};

const liveBtn = css<IProps>`
    ${props =>
        css`
            color: ${props.theme.buttons.color};
            border-color: ${props.theme.accentColor};
            background-color: ${props.theme.accentColor};
        `}
    ${props => getButtonType(props)}
    transition: all 0.3s ease;
    &:link,
    &:visited,
    &:active {
        color: ${props => props.theme.buttons.default.color};
    }
`;

const disabledBtn = css`
    color: ${props => props.theme.primaryColor};
    border-color: ${disabledColor};
    background-color: ${disabledColor};
    pointer-events: none;
`;

export const btn = css<IButtonColoredProps>`
    text-align: center;
    padding: 0.563em 1.5625em;
    border-width: 1px;
    border-style: solid;
    text-transform: uppercase;
    border-radius: ${props => props.theme.buttons.default.borderRadius};
    ${props => (props.disabled ? disabledBtn : liveBtn)}
`;

export const btnTextAndPossiblyIcon = css`
    ${btn}
    svg {
        vertical-align: sub;
        ${rtl`margin-right: 5px;`};
    }
    padding-top: 0.715em;
    padding-bottom: 0.715em;
    font-size: 0.875em;
    line-height: 16.1px;
`;

export const btnHover = css`
    background-color: ${props => props.theme.accentHoverColor};
    border-color: ${props => props.theme.accentHoverColor};
`;
const btnActive = css`
    transform: translateY(0.2em);
`;

export const ButtonCss = css`
    background-color: transparent;
    text-align: center;
    &:hover {
        cursor: pointer;
        ${btnHover}
    }
    &:active {
        ${btnActive}
    }
    &:focus {
        outline: 0 none;
    }
`;

export const Button = styled.button`
    ${ButtonCss}
`;

export const LinkButton = css<IButtonColoredProps>`
    min-width: ${props => props.minWidth && `${props.minWidth}px`};
    width: ${props => props.width && props.width};
    display: inline-block;
    text-decoration: none !important;
    ${btnTextAndPossiblyIcon}
    &:active,
    &:hover {
        ${btnHover}
    }
    &:active {
        ${btnActive}
    }
`;
