import { Field } from "components/formik";
import { useGetSites } from "hooks/query/use-get-sites";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

interface IInjectedProps {}

interface IProps extends IInjectedProps, ISelectFild {
    partnerId?: number;
}

export const WebsiteField = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { name, placeholder, partnerId, ...rest } = props;
    const { sitesList, loading } = useGetSites({ hidden: false, partnerId });

    const options = [
        ..._.map(
            sitesList,
            wbsite =>
                ({
                    value: wbsite,
                    label: wbsite.name,
                } as any),
        ),
    ];

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={props.name}
            label={__("Сайт")}
            type="react-select"
            options={options}
            isLoading={loading}
            isDisabled={loading}
            placeholder={placeholder || __("Все")}
            minWidth={300}
            {...rest}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
