import { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import moment from "moment";
import {
    UpdatePartnerPartnerGroup,
    UpdatePartnerPartnerGroupVariables,
    PartnerGroups_authorized_manager_main_partnerGroups_data,
} from "gql/types/operation-result-types";
import { IState as IPaginationAndSortingState } from "hooks/use-pagination";
import {
    FormDirectionRow,
    FormDirectionColumn,
    FormGroup,
    FormGroupCheckbox,
    FormGroupButton,
} from "styled/layouts";
import { ButtonStyled } from "styled/button";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form, Field } from "components/formik";
import { FakeLabel } from "components/filters/form";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { useValidationSchema, TFormValues } from "./use-validation-schema";
import GET_PARTNER_GROUPS from "../get-partner-groups.gql";
import UPDATE_PARTNER_GROUP from "./update-partner-group.gql";
import { getInitialValues } from "./get-initial-values";

interface IProps {
    group: PartnerGroups_authorized_manager_main_partnerGroups_data;
    paginationAndSortingState: IPaginationAndSortingState;
    resetGroup: () => void;
}

export const EditPartnerGroupForm = ({
    group,
    paginationAndSortingState,
    resetGroup,
}: IProps) => {
    const { params } = useRouteMatch<{ id: string }>();
    const partnerId = Number(params.id);
    const [__] = useTranslation();
    const validationSchema = useValidationSchema();
    const [updateGroup] = useMutation<
        UpdatePartnerPartnerGroup,
        UpdatePartnerPartnerGroupVariables
    >(UPDATE_PARTNER_GROUP);
    const { setColumnData } = useContext(PickCurrentLineContext);
    const initialValues = getInitialValues(group);

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values: TFormValues) => {
                await updateGroup({
                    variables: {
                        data: {
                            id: group.id,
                            partnerGroupId: group.partnerGroupId,
                            endless: values.endless,
                            dateEnd: values.endless
                                ? null
                                : values.dateEnd?.toISOString(),
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_PARTNER_GROUPS,
                            variables: {
                                params: {
                                    ...paginationAndSortingState,
                                    id: partnerId,
                                },
                            },
                        },
                    ],
                    onCompleted: () => {
                        setColumnData({
                            name: "id",
                            id: group.id,
                            tableName: "partner-groups",
                        });
                        resetGroup();
                        toast.success(__("Группа обновлена"));
                    },
                    onError: () => toast.error(__("Группа не обновлена")),
                });
            }}
        >
            {({ setFieldValue, values, isSubmitting }) => (
                <Form>
                    <FormDirectionRow>
                        <FormDirectionColumn>
                            <FormGroup>
                                <Field
                                    name="dateStart"
                                    type="date-single-picker"
                                    label={__("Дата начала")}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroupCheckbox>
                                <Field
                                    name="endless"
                                    type="checkbox"
                                    label={__("Бессрочная группа")}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const currentValue = e.target.checked;
                                        setFieldValue("endless", currentValue);
                                        setFieldValue(
                                            "dateEnd",
                                            currentValue
                                                ? null
                                                : moment().endOf("month"),
                                        );
                                    }}
                                />
                            </FormGroupCheckbox>
                        </FormDirectionColumn>
                        <FormGroup>
                            <Field
                                name="dateEnd"
                                type="date-single-picker"
                                label={__("Дата окончания")}
                                disabled={values.endless}
                            />
                        </FormGroup>
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>{__("Редактировать")}</SubmitButton>
                        </FormGroupButton>
                        <FormGroupButton>
                            <FakeLabel />
                            <ButtonStyled
                                type="button"
                                onClick={resetGroup}
                                disabled={isSubmitting}
                            >
                                {__("Отмена")}
                            </ButtonStyled>
                        </FormGroupButton>
                    </FormDirectionRow>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
