import { getCurrencyById, getCampaignStatus } from "services/project-utils";
import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";

export function useColumns(): IReactTableColumn[] {
    const [__] = useTranslation();
    return [
        {
            Header: __("ID кампании"),
            accessor: "id",
            minWidth: 80,
        },
        {
            Header: __("Имя кампании"),
            accessor: "name",
            minWidth: 120,
        },
        {
            Header: __("Начало"),
            accessor: "dateStart",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Окончание"),
            accessor: "dateEnd",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Статус"),
            accessor: "status",
            minWidth: 100,
            Cell: ({ value }) => getCampaignStatus(__, value),
        },
        {
            Header: __("Ссылка"),
            accessor: "url",
            minWidth: 120,
        },
        {
            Header: __("Валюта"),
            accessor: "currencyId",
            minWidth: 80,
            Cell: ({ value }) => getCurrencyById(value),
        },
    ];
}
