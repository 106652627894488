import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import {
    Partner,
    PartnerVariables,
    UpdatePartner,
    UpdatePartnerVariables,
} from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Title } from "services/styled-components/typogoraphy";
import GET_PARTNER from "views/manager/main/partner-list/edit-partner/gql/get-partner.gql";
import UPDATE_PARTNER from "views/manager/main/partner-list/edit-partner/edit-partner-tabs/profile/gql/update-partner.gql";
import { useSetOptions } from "./hooks/use-set-options";
import { useValidationSchema } from "./hooks/use-validation-schema";
import { useInitialFormValues } from "./hooks/use-initial-form-values";
import { ContactInfo } from "./contact-info";
import { GeneralInfo } from "./general-info";
import { Options } from "./options";
import { ProfileSC, ProfileSide } from "./styled";

export const Profile = () => {
    const [__] = useTranslation();
    const [contactInfoDisabled, setContactInfoDisabled] = useState(true);
    const { params } = useRouteMatch<{ id: string }>();
    const id = Number(params.id);
    const { data: partnerData } = useQuery<Partner, PartnerVariables>(
        GET_PARTNER,
        {
            variables: {
                id,
            },
        },
    );
    const partner = partnerData?.authorized.manager.main.partner;
    const [updatePartner] = useMutation<UpdatePartner, UpdatePartnerVariables>(
        UPDATE_PARTNER,
    );
    const { getOptionsData } = useSetOptions(partner);
    const { initialValues, loading } = useInitialFormValues(partner);
    const validationSchema = useValidationSchema();

    if (!partner || loading) {
        return null;
    }

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async values => {
                const data: UpdatePartnerVariables["data"] = {};
                const codeLimit = Number(values.setCodeUsageLimit);

                if (values.country && values.language) {
                    data.generalInfoData = {
                        status: values.status,
                        countryId: values.country?.id,
                        languageId: values.language?.id,
                        currencies: values.currencies.map(currencyId =>
                            Number(currencyId),
                        ),
                        partnerSiteId: Number(values.partnerSiteId),
                        partnerId: Number(values.partnerId),
                        note: values.note,
                        promoCodeLimit: codeLimit,
                    };
                }

                if (values.messenger && values.url && values.email) {
                    data.contactInfoData = {
                        url: values.url,
                        phoneNumber: values.phoneNumber,
                        email: values.email,
                        messenger: {
                            id: values.messenger.id,
                            credentials: values.messengerCredentials,
                        },
                    };
                }

                if (
                    (codeLimit || codeLimit === 0) &&
                    codeLimit !== partner.promoCodsLimit
                ) {
                    data.optionsData = getOptionsData({
                        setCodeUsageLimit: Number(values.setCodeUsageLimit),
                    });
                }

                await updatePartner({
                    variables: {
                        id,
                        data,
                    },
                    refetchQueries: [
                        {
                            query: GET_PARTNER,
                            variables: { id },
                        },
                    ],
                });

                setContactInfoDisabled(true);
            }}
        >
            {() => (
                <Form>
                    <ProfileSC>
                        <ProfileSide>
                            <AdminWrapper>
                                <Title>{__("Контактная информация")}</Title>
                                <ContactInfo
                                    disabled={contactInfoDisabled}
                                    onEditClick={() =>
                                        setContactInfoDisabled(false)
                                    }
                                />
                            </AdminWrapper>

                            <AdminWrapper>
                                <Title>{__("Возможности партнера")}</Title>
                                <Options partner={partner} />
                            </AdminWrapper>
                        </ProfileSide>

                        <ProfileSide>
                            <AdminWrapper>
                                <Title>{__("Общая информация")}</Title>
                                <GeneralInfo />
                            </AdminWrapper>
                        </ProfileSide>

                        <SubmitButton width="100%">
                            {__("Сохранить")}
                        </SubmitButton>
                    </ProfileSC>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
