import { PartnersPromocodes } from "gql/types/operation-result-types";
import GET_PROMOCODES from "../gql/promocodes.gql";

import { useQuery } from "@apollo/client";

export const usePartnerPromocodes = () => {
    const queryResult = useQuery<PartnersPromocodes>(GET_PROMOCODES, {
        ssr: true,
    });

    const data = g(queryResult.data, "authorized", "partner", "promoCodes");

    return { data, loading: queryResult.loading };
};
