import { LoadingText } from "components/react-table/loading-text";
import {
    PartnerFullReport,
    PartnerFullReportVariables,
} from "gql/types/operation-result-types";
import { ReportReactTable } from "components/react-table/report-react-table";
import { Export } from "./export";
import { useReportColumns } from "views/partner-manager/report-columns";
import { SubmitValues, generalQueryParams } from "../general-query-params";
import { QueryResult } from "@apollo/client";
import { ITablePagination } from "hooks/use-pagination";

interface IProps {
    actualValues?: SubmitValues;
    queryResult?: QueryResult<PartnerFullReport, PartnerFullReportVariables>;
    externalPaginationAndSorting: ITablePagination;
}

export const FullReportEmptyTable = ({
    actualValues,
    queryResult,
    externalPaginationAndSorting,
}: IProps) => {
    const columns = useReportColumns(actualValues?.currency?.name || "");

    return (
        <ReportReactTable
            exportComponent={
                actualValues && (
                    <Export filters={generalQueryParams(actualValues)} />
                )
            }
            reportName="Full"
            columns={columns}
            shownColumnsByDefault={[
                "siteId",
                "siteName",
                "countOfRegistrations",
                "countOfNewAccountsWithDeposits",
                "depositAmount",
                "bonusAmount",
                "profit",
                "comissionAmount",
            ]}
            externalPaginationAndSorting={externalPaginationAndSorting}
            queryResult={queryResult}
            data={
                queryResult?.data?.authorized.partner.reports.fullReport.rows ||
                []
            }
            total={
                queryResult?.data?.authorized.partner.reports.fullReport.total
            }
            reportStatus={
                queryResult?.data?.authorized.partner.reports.fullReport.status
            }
            dataLength={
                queryResult?.data?.authorized.partner.reports.fullReport
                    .pagesCount
            }
            loadingText={<LoadingText fastLoading={true} />}
        />
    );
};
