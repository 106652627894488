import { PartnerList } from "views/manager/main/partner-list";
import { PartnerListType } from "gql/types/operation-result-types";
import { SelectedPartner } from "views/manager/advertising/fin-plan/edit-fin-plan-steps/selected-partner";

interface IProps {
    partnerId: number | undefined;
    partnerListType: PartnerListType;
    choosePartner: (partnerId: number) => void;
}

export const ChoosePartner = ({
    partnerId,
    partnerListType,
    choosePartner,
}: IProps) => (
    <>
        <SelectedPartner partnerId={partnerId} />
        <PartnerList
            partnerListType={partnerListType}
            choosePartner={(currentPartnerId: number) => {
                choosePartner(currentPartnerId);
            }}
        />
    </>
);
