import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useTranslation } from "react-i18next";

export function ToFinplans() {
    const [__] = useTranslation();
    return (
        <FormDirectionRow direction="right" stretch>
            <FormGroupButton>
                <LocalizedNavLinkWithOptionalBackground
                    showBackground={true}
                    to="/agent/advertising/fin-plans"
                    exact={true}
                >
                    {/* TODO: БА настаивает на том чтобы эта кнопка называлась "Сохранить"
             хотя по ней ничего не сохраняется */}
                    {__("К финпланам")}
                </LocalizedNavLinkWithOptionalBackground>
            </FormGroupButton>
        </FormDirectionRow>
    );
}
