import { toast } from "react-toastify";
import { t as __ } from "components/language-provider";

export enum SuccessTypes {
    CREATE_PARTNER_LINK,
    CREATE_POSTBACK,
    UPDATE_POSTBACK,
    CREATE_SITE,
    CREATE_PROMO_CODE,
    DELETE_POSTBACK,
    SHOW_LINK,
    HIDE_LINK,
    EDIT_PARTNER_ACCOUNT,
    EDIT_ACCOUNT_PASSWORD,
    TOKEN_CREATED,
    ACCOUNT_SUBSCRIBE,
    HIDE_SITE,
    SHOW_SITE,
    RECEIVE_LETTER,
    CREATE_PARTNER,
    MODERATE_POSTBACK,
    SUCCESS_DOWNLOAD_CAMPAIGN_APK,
}

export const successHandler = <T>(type: SuccessTypes, payload?: T) => {
    switch (type) {
        case SuccessTypes.CREATE_PARTNER_LINK:
            return toast.success(
                format(__("Партнерская ссылка для сайта - {payload} создана"), {
                    payload,
                }),
            );
        case SuccessTypes.CREATE_POSTBACK:
            return toast.success(
                format(__("Постбек номер - {payload} создан"), { payload }),
            );
        case SuccessTypes.UPDATE_POSTBACK:
            return toast.success(
                format(__("Постбек номер - {payload} обновлен"), { payload }),
            );
        case SuccessTypes.CREATE_SITE:
            return toast.success(
                format(__("Сайт - {payload} создан"), { payload }),
            );
        case SuccessTypes.CREATE_PROMO_CODE:
            return toast.success(
                format(__("Промокод номер - {payload} создан"), { payload }),
            );
        case SuccessTypes.DELETE_POSTBACK:
            return toast.success(
                format(__("Postback номер - {payload} удален"), { payload }),
            );
        case SuccessTypes.SHOW_LINK:
            return toast.success(
                format(__("Ссылка номер - {payload} открыта"), { payload }),
            );
        case SuccessTypes.HIDE_LINK:
            return toast.success(
                format(__("Ссылка номер - {payload} скрыта"), { payload }),
            );
        case SuccessTypes.EDIT_PARTNER_ACCOUNT:
            return toast.success(__("Изменения успешно сохранены!"));
        case SuccessTypes.EDIT_ACCOUNT_PASSWORD:
            return toast.success(__("Пароль успешно изменен!"));
        case SuccessTypes.TOKEN_CREATED:
            return toast.success(__("Токен создан!"));
        case SuccessTypes.ACCOUNT_SUBSCRIBE:
            return toast.success(__("Подписки успешно изменены!"));
        case SuccessTypes.SHOW_SITE:
            return toast.success(
                format(__("Сайт номер - {payload} открыт"), { payload }),
            );
        case SuccessTypes.HIDE_SITE:
            return toast.success(
                format(__("Сайт номер - {payload} скрыт"), { payload }),
            );
        case SuccessTypes.RECEIVE_LETTER:
            return toast.success(__("Советы уже у тебя 👌"));
        case SuccessTypes.CREATE_PARTNER:
            return toast.success(
                format(__("Партнер с логином - {payload} успешно создан"), {
                    payload,
                }),
            );
        case SuccessTypes.MODERATE_POSTBACK:
            return toast.success(
                format(
                    __(
                        "Постбек с id - {payload} успешно отправлен на модерацию",
                    ),
                    {
                        payload,
                    },
                ),
            );
        case SuccessTypes.SUCCESS_DOWNLOAD_CAMPAIGN_APK:
            return toast.success(
                format(
                    __(
                        "Скачивание APK для кампании {payload} успешно завершилось",
                    ),
                    {
                        payload,
                    },
                ),
            );
        default:
            return toast.error(__("Ошибка оповещения"));
    }
};
