import { useDefinedContext } from "hooks/use-context-exist";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "styled/layouts";
import { PartnersProgramGeneralInformationContext } from "views";
import { Cookies } from "./components/cookies";
import { SealItems } from "./components/seal";
import { SealItemWrapper } from "./components/seal/styled";
import { SecondMenu } from "./components/second-menu";
import { SendManagement } from "./components/send-management";
import { FooterContent, FooterSC, Copyright } from "./styled";
import {
    _1XBET_PARTNERS,
    PARTNERS_1XBET,
    _1XBET_PARTNERS_IRELAND,
} from "../../../server/partner-programs";
import { AskQuestions } from "components/header/components/ask-questions";

export const Footer = React.memo(() => {
    const currentFullYear = useMemo(
        () => new Date().getFullYear().toString(),
        [],
    );
    const [__] = useTranslation();
    const {
        landingMainPage,
        copyrightPeriodStart,
        partnerProgramCompanyName,
        partnersProgramId,
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    const isXbetpartners =
        _1XBET_PARTNERS === partnersProgramId ||
        PARTNERS_1XBET === partnersProgramId;

    const datePeriod =
        copyrightPeriodStart === currentFullYear
            ? copyrightPeriodStart
            : `${copyrightPeriodStart}-${currentFullYear}`;

    return (
        <FooterSC>
            <Wrapper>
                {partnersProgramId !== _1XBET_PARTNERS_IRELAND && <Cookies />}
                {isXbetpartners && (
                    <>
                        <AskQuestions className={"footer-ask-questions"} />
                        <SendManagement />
                    </>
                )}
            </Wrapper>
            <Wrapper>
                <FooterContent>
                    <SecondMenu />
                    {Boolean(landingMainPage?.partnersSeals.length) && (
                        <SealItemWrapper>
                            {landingMainPage?.partnersSeals.map(seal => (
                                <SealItems
                                    key={seal.imageAdress}
                                    SealImg={seal.imageAdress}
                                    SealLink={seal.ref}
                                />
                            ))}
                        </SealItemWrapper>
                    )}
                    <Copyright>
                        Copyright © {datePeriod} &quot;
                        {partnerProgramCompanyName}
                        &quot;‎. {__("Все права защищены")}.
                    </Copyright>
                </FooterContent>
            </Wrapper>
        </FooterSC>
    );
});
