import {
    CreatePartnerLink,
    CreatePartnerLinkVariables,
} from "gql/types/operation-result-types";
import { MutationFunction } from "@apollo/client";
import { errorHandler } from "services/error-handler";
import { AllUniversalOutputValues } from "components/filters/types";
import { successHandler, SuccessTypes } from "services/success-handler";

export type TFilters = Pick<
    AllUniversalOutputValues,
    "currency" | "website" | "subId" | "campaign" | "landing"
>;

export const createPartnerLink = async (
    mutate: MutationFunction<CreatePartnerLink, CreatePartnerLinkVariables>,
    filters: TFilters,
) => {
    const { website, currency, campaign, subId, landing } = filters;
    try {
        await mutate({
            variables: {
                filter: {
                    siteId: website.id,
                    currencyId: parseInt(currency.id),
                    campaignId: parseInt(campaign.id),
                    subId,
                    landingPage: landing,
                },
            },
        });

        successHandler(SuccessTypes.CREATE_PARTNER_LINK, website.name);
    } catch (error: any) {
        void errorHandler(error);
        throw error;
    }
};
