import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { rtl } from "services/styled-components/rtl";

export const SecondMenuSC = styled.nav`
    ${media.mdMax`
        margin-bottom: 20px;
    `};
    ul {
        display: flex;
        ${media.mdMax`
            display:flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
                & li.linkMobile {
                    display: none;
                }
            `}
        li {
            a {
                color: ${props => props.theme.textColor};
                display: inline-block;
                padding: 10px 0;
                ${rtl`margin-right: 20px;`};
                text-transform: uppercase;
                font-weight: 500;
                transition: 0.3s ease 0s;
                &:hover {
                    color: ${props => props.theme.activeColor};
                }
                ${media.mdMax`
                    padding: 5px 0;
                    margin-right: 0;
                `}
            }
            &.android-item {
                a {
                    display: flex;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    text-transform: none;
                }
                svg {
                    margin-right: 10px;
                }
            }

            ${media.xs`
                display: inline-block;
            `}
        }
    }
`;
