import {
    AddPromocode,
    AddPromocodeVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import ADD_PROMOCODE from "../gql/add-promocode.gql";
import GET_PROMOCODES from "../gql/promocodes.gql";

export const useAddPropmocode = () => {
    const [mutation] = useMutation<AddPromocode, AddPromocodeVariables>(
        ADD_PROMOCODE,
        {
            refetchQueries: [
                {
                    query: GET_PROMOCODES,
                },
            ],
        },
    );

    return { mutation };
};
