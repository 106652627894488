import { Modal } from "components/components-common/modal";
import { StyledInner } from "components/components-common/modal/styled";
import styled from "styled-components";

export const ModalLWithSmallWidthDisappearingFixed = styled(Modal)`
    ${StyledInner} {
        max-width: 800px;
        width: 85%;
    }
`;
