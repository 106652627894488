import rtlcss from "rtlcss";
import { css } from "styled-components";

interface IProps {
    theme: { dir: "ltr" | "rtl" };
}

function cssToString(cssStyles: any[], props: IProps) {
    return cssStyles.reduce((result, v) => {
        let addition = v;
        if (typeof v === "function") {
            const vResult = v(props);
            addition =
                typeof vResult === "string"
                    ? vResult
                    : cssToString(vResult, props);
        }
        return result + addition;
    }, "");
}

export const rtl =
    (...styles: any[]) =>
    (props: IProps) => {
        const cssStyles = (css as any)(...styles);
        return props.theme.dir === "rtl"
            ? rtlcss.process(cssToString(cssStyles, props))
            : cssStyles;
    };
