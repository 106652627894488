import { CreatePartnerLink_authorized_partner_partnerLinksMutations_createPartnerLink } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { Container, Text } from "./styled";
import { Row } from "styled/layouts";

interface IProps {
    currentLink?: CreatePartnerLink_authorized_partner_partnerLinksMutations_createPartnerLink;
}

export const CreatedPartnerLink = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { currentLink } = props;
    const link = currentLink?.link;
    const token = currentLink?.token;

    return (
        <Row align="flex-start">
            <Container>
                <Text>{__("Готовая ссылка")}</Text>
                <input type="text" value={link} disabled={true} />
            </Container>
            {token && (
                <Container>
                    <Text>{__("Токен")}</Text>
                    <input type="text" value={token} disabled={true} />
                </Container>
            )}
        </Row>
    );
});
