import {
    mergeSelectorData,
    getOriginalDataForObject,
    mockCountryList,
} from "components/filters/services";
import { useTranslation } from "react-i18next";
import { useCountries } from "hooks/query/use-countries";
import { GetCountries_countries } from "gql/types/operation-result-types";

export interface ICountry {
    label: string;
    value: GetCountries_countries;
}

export const useCountriesWithTranslations = () => {
    const [__] = useTranslation();
    const { countries, loading } = useCountries();

    const translatedData = React.useMemo(
        () =>
            mergeSelectorData(
                mockCountryList(__),
                getOriginalDataForObject(countries),
            ),
        [__, countries],
    ) as ICountry[];

    translatedData.sort((a, b) => a.label.localeCompare(b.label));

    return {
        countries: translatedData,
        loading,
    };
};
