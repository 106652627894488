import { connect, FormikContextType } from "components/formik";
import { useDefinedContext } from "hooks/use-context-exist";
import { ReactNode } from "react";
import { MEGAPARI_PARTNERS } from "server/partner-programs";
import { ButtonStyled } from "styled/button";
import { PartnersProgramGeneralInformationContext } from "views";

interface IInjectedProps {
    formik: FormikContextType<any>;
}

export function createSubmitButton(ButtonSC: React.FC<any>) {
    interface IProps extends IInjectedProps {
        children: ReactNode;
        width?: string;
        onClick?: () => void;
    }

    return (connect as any)(
        React.memo((props: IProps) => {
            const { isSubmitting } = props.formik;

            const partnersGeneralInformation = useDefinedContext(
                PartnersProgramGeneralInformationContext,
            );

            const buttonGradient =
                partnersGeneralInformation.partnersProgramId ===
                MEGAPARI_PARTNERS
                    ? "linear-gradient(150.29deg,rgb(27, 157, 217) -2.02%,rgb(230, 53, 43) 101.94%)"
                    : undefined;

            return (
                <ButtonSC
                    width={props.width}
                    type="submit"
                    disabled={isSubmitting}
                    onClick={props.onClick}
                    buttonGradient={buttonGradient}
                >
                    {/* {isSubmitting && <Loader display="inline" />} */}
                    {props.children}
                </ButtonSC>
            );
        }),
    ) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
}

export const SubmitButton = createSubmitButton(ButtonStyled);
