import { Title } from "components/title";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { AdminWrapperContainer } from "styled/layouts";
import { usePartnerChart } from "../hooks/use-partner-chart";
import { ChartWrapper } from "views/partner-manager/chart/chart-wrapper";
import { StatesActionCreators } from "services/redux/root/states/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { options } from "./options";
import { Chart } from "views/partner-manager/chart";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    mockPeriod: string;
    currency:
        | GetCurrencies_authorized_partnerAndManager_data_currencies
        | undefined;
    loadCurrency: boolean;
}

export const PartnerStatisticsChart = React.memo(
    ({ mockPeriod, currency, loadCurrency }: IProps) => {
        const [__] = useTranslation();
        const {
            data: transformData,
            loading,
            queryResult,
        } = usePartnerChart(mockPeriod, currency);
        const dispatch = useDispatch();

        useEffect(() => {
            if (!currency) {
                return;
            }
            dispatch(
                StatesActionCreators.setStatisticLastParamsMainInfoAndGraph({
                    currency,
                    mockPeriod,
                }),
            );
        }, [mockPeriod, currency, dispatch]);

        if (queryResult.error) {
            return (
                <Title>
                    {__("При получении данных для графиков произошла ошибка")}
                </Title>
            );
        }

        const PassChart = (
            <Chart options={options({ transformData, __ }).chart1} />
        );
        const PassChartWrapper = (
            <ChartWrapper
                loading={loading || loadCurrency}
                title={__("Статистика переходов")}
                component={PassChart}
            />
        );

        const RegistrationsChart = (
            <Chart options={options({ transformData, __ }).chart2} />
        );

        const RegistrationsChartWrapper = (
            <ChartWrapper
                loading={loading || loadCurrency}
                title={__("Статистика регистраций")}
                component={RegistrationsChart}
            />
        );

        return (
            <AdminWrapperContainer>
                {PassChartWrapper}
                {RegistrationsChartWrapper}
            </AdminWrapperContainer>
        );
    },
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
