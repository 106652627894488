import styled from "styled-components";
import { DesktopMainMenu } from "./index";
import { media } from "services/styled-components/variables";

export const DesktopMainMenuSC = styled(DesktopMainMenu)<
    typeof DesktopMainMenu
>`
    ${media.smMax`
        display: none;
    `}
`;
