import { useState } from "react";
export enum SignInStage {
    SIMPLE_SIGN_IN,
    TWO_FA,
    SUCCESS,
    SIMPLE_SIGN_IN_ERROR,
    TWO_FA_ERROR,
    FINISH,
}

export function useStage() {
    const [stage, setStage] = useState(SignInStage.SIMPLE_SIGN_IN);
    return {
        stage,
        setStage,
    };
}
