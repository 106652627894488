import { adminBorderColor } from "services/styled-components/themes";
import { media } from "services/styled-components/variables";
import styled, { DefaultTheme } from "styled-components";

export const MainInfoItemTitle = styled.div`
    font-weight: 500;
    font-size: 2.8em;
    line-height: 1.2;
    display: flex;
    align-items: center;
`;

export const MainInfoItemDesc = styled.div`
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    > svg {
        vertical-align: sub;
    }
`;

export const MilliBTCInsertion = styled.span<{ size: string }>`
    position: relative;
    font-size: ${props => props.size}px;
    left: 0.22em;
    font-weight: 400;
    text-transform: lowercase;
`;

export enum MainInfoItemTypeType {
    IsAvailable,
    Yesterday,
    CurrentMonth,
    ThirtyDays,
    Total,
}

interface IProps {
    mainInfoItemType: MainInfoItemTypeType;
    theme: DefaultTheme;
}

const getType = (props: IProps) => {
    switch (props.mainInfoItemType) {
        case MainInfoItemTypeType.IsAvailable:
            return props.theme.additionalColor1;
        case MainInfoItemTypeType.Yesterday:
            return props.theme.additionalColor2;
        case MainInfoItemTypeType.CurrentMonth:
            return props.theme.additionalColor3;
        case MainInfoItemTypeType.ThirtyDays:
            return props.theme.additionalColor4;
        case MainInfoItemTypeType.Total:
            return props.theme.additionalColor5;
    }
};

export const MainInfoItemSC = styled.div<IProps>`
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10.3em;
    position: relative;
    overflow: hidden;
    z-index: 0;
    /* flex: 1 1 auto; */
    text-align: center;
    border-bottom: 4px solid ${props => getType(props)};
    border-left: 1px solid ${adminBorderColor};
    ${media.xs`
        flex: 50 0 auto;
        width: 50%;
    `}
    ${media.md`
        flex: 1 0 auto;
        width: auto;
        font-size: 11px;
    `}

    ${/* sc-sel */ MainInfoItemTitle},
    ${/* sc-sel */ MainInfoItemDesc} svg,
    ${MilliBTCInsertion} {
        color: ${props => getType(props)};
    }
    /* stylelint-disable */
    > svg {
        position: absolute;
        bottom: -18%;
        right: -9%;
        z-index: -1;
        color: rgba(0, 0, 0, 0.02);
    }
    &:first-of-type {
        border-left: 0 none;
    }
`;
