import _ from "lodash";
import { ReactElement, useState } from "react";

interface IAdminContext {
    pageTitle: string;
    additionalPageTitle?: string;
    setPageTitle: (value: string) => void;
    setAddititonalPageTitle: (value: string | undefined) => void;
}
export const TitleContext = React.createContext<IAdminContext>({
    pageTitle: "",
    additionalPageTitle: "",
    // eslint-disable-next-line @typescript-eslint/unbound-method
    setPageTitle: _.noop,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    setAddititonalPageTitle: _.noop,
});

interface IProps {
    children: ReactElement | ReactElement[];
}

export const TitleContextWraper = ({ children }: IProps) => {
    const [pageTitle, setPageTitle] = useState("");
    const [additionalPageTitle, setAddititonalPageTitle] = useState<string>();

    return (
        <TitleContext.Provider
            value={{
                pageTitle,
                setPageTitle,
                additionalPageTitle,
                setAddititonalPageTitle,
            }}
        >
            {children}
        </TitleContext.Provider>
    );
};
