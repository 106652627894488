import { List } from "react-content-loader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import { errorHandler } from "services/error-handler";
import { ContentLoader } from "components/components-common/content-loader";
import { AdminWrapperEdit } from "styled/layouts";
import { ButtonColored } from "styled/button";
import {
    SendEmailConfirmation,
    SendEmailConfirmationVariables,
} from "gql/types/operation-result-types";
import SEND_EMAIL_CONFIRMATION from "./send-email-confirmation.gql";

interface IProps {
    email?: string | null;
    isEmailConfirmed: boolean;
    isLoading: boolean;
}

export const ConfirmEmail = ({
    email,
    isEmailConfirmed,
    isLoading,
}: IProps) => {
    const [__] = useTranslation();

    const [sendMutation, { loading: isSubmitting }] = useMutation<
        SendEmailConfirmation,
        SendEmailConfirmationVariables
    >(SEND_EMAIL_CONFIRMATION);

    const onConfirmHandler = async () => {
        try {
            const { data } = await sendMutation();
            const code = data?.authorized.partnerAccount.sendEmailConfirmation;

            if (code === 1) {
                toast.success(__("Письмо отправлено на указанный адрес"), {
                    autoClose: 3000,
                });
            } else if (code === 2) {
                toast.error(
                    __(
                        "Что-то пошло не так, ошибка: sendEmailConfirmation code: 2",
                    ),
                    {
                        autoClose: 3000,
                    },
                );
            }
        } catch (error: any) {
            void errorHandler(error);
        }
    };

    return (
        <AdminWrapperEdit>
            <ContentLoader component={List} loading={isLoading}>
                <Title>{__("Подтверждение почты")}</Title>
                {email && (
                    <Paragraph>
                        {__("Почта")}
                        <span>:</span>
                        <span style={{ marginLeft: "0.25rem" }}>{email}</span>
                    </Paragraph>
                )}
                <Paragraph>
                    {format(__("Почта подтверждена: {result}"), {
                        result: isEmailConfirmed ? __("Да") : __("Нет"),
                    })}
                </Paragraph>
                <ButtonColored
                    disabled={isEmailConfirmed || isSubmitting}
                    onClick={onConfirmHandler}
                >
                    {__("Подтвердить")}
                </ButtonColored>
            </ContentLoader>
        </AdminWrapperEdit>
    );
};
