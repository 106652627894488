import { Field, Form, FormikHelpers as FormikActions } from "components/formik";
import { CopyToClipboard } from "components/copy-to-clipboard";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import {
    GetAllActualDomainAPIResources,
    GetAllActualDomainAPIResources_authorized_partner_actualDomainAPIResources,
    GetNewActualDomainAPIToken,
    GetNewActualDomainAPITokenVariables,
    GetAllActualDomainAPIResources_authorized_partner_actualDomainAPIResources_token,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import _ from "lodash";
import { DataProxy, MutationFunction, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import { successHandler, SuccessTypes } from "services/success-handler";
import * as yup from "yup";
import {
    FormDirectionColumn,
    FormGroup,
    FormGroupButton,
    FormGroupInputWrapper,
    FormGroupLabel,
} from "../../../../../styled/layouts";
import GET_ALL_ACTUAL_DOMAIN_API_TOKENS from "../gql/get-all-actual-domain-api-resources.gql";
import GET_ACTUAL_DOMAIN_API_TOKEN from "../gql/get-new-actual-domain-api-token.gql";
import moment from "moment";
import { useLanguage } from "components/language-provider";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";

interface ILifetime {
    lifetime: number;
}

type InitialValues = ILifetime &
    Omit<
        GetAllActualDomainAPIResources_authorized_partner_actualDomainAPIResources_token,
        "__typename"
    >;

const regularTokensLifetime = 30;

export const Resource = (
    props: GetAllActualDomainAPIResources_authorized_partner_actualDomainAPIResources,
) => {
    const [__] = useTranslation();
    const [mutation] = useMutation<
        GetNewActualDomainAPIToken,
        GetNewActualDomainAPITokenVariables
    >(GET_ACTUAL_DOMAIN_API_TOKEN, {
        update,
    });

    const { language } = useLanguage();
    const initialValues: InitialValues = {
        lifetime: regularTokensLifetime,
        token:
            props?.token?.token ||
            __("Введите поле время жизни и сгенерируйте новый токен"),
        expirationDate: props?.token
            ? moment(new Date(props.token.expirationDate))
                  .locale(language.code)
                  .format("LL LTS")
            : "",
    };

    const validationSchema = yup.object().shape({
        lifetime: yup.number().required().min(1).max(regularTokensLifetime),
    });

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmitWrapper(
                async (
                    values: ILifetime,
                    formikActions: FormikActions<ILifetime>,
                ) => {
                    await getNewToken(
                        props.currencyId,
                        values.lifetime,
                        mutation,
                        __,
                    );
                    formikActions.resetForm();
                },
                validationSchema,
            )}
            validationSchema={validationSchema}
        >
            {() => {
                return (
                    <Form>
                        <Title>{props.currencyName}</Title>
                        {props.resourceName && (
                            <Paragraph>{props.resourceName}</Paragraph>
                        )}
                        <FormDirectionColumn>
                            <FormGroup>
                                <FormGroupLabel htmlFor="token">
                                    {__("Сгенерированный токен")}
                                </FormGroupLabel>
                                <FormGroupInputWrapper>
                                    <Field name="token" type="text" disabled />
                                    {props.token && (
                                        <CopyToClipboard
                                            text={props.token.token}
                                        />
                                    )}
                                </FormGroupInputWrapper>
                            </FormGroup>

                            {props.token && (
                                <FormGroup>
                                    <FormGroupLabel htmlFor="expirationDate">
                                        {__("Дата истечения")}
                                    </FormGroupLabel>
                                    <FormGroupInputWrapper>
                                        <Field
                                            name="expirationDate"
                                            type="text"
                                            disabled
                                        />
                                    </FormGroupInputWrapper>
                                </FormGroup>
                            )}

                            <FormGroup>
                                <FormGroupLabel htmlFor="lifetime">
                                    {`${__("Время жизни токена, дни")} *`}
                                </FormGroupLabel>
                                <FormGroupInputWrapper>
                                    <Field
                                        name="lifetime"
                                        type="text"
                                        placeholder={regularTokensLifetime.toString()}
                                    />
                                </FormGroupInputWrapper>
                            </FormGroup>

                            <FormGroupButton>
                                <SubmitButton width="100%">
                                    {__("Сгенерировать новый токен")}
                                </SubmitButton>
                            </FormGroupButton>
                        </FormDirectionColumn>
                    </Form>
                );
            }}
        </FormikConsoleErrors>
    );
};

const getNewToken = async (
    currencyId: number,
    expirationTimeDays: number,
    mutation: MutationFunction<
        GetNewActualDomainAPIToken,
        GetNewActualDomainAPITokenVariables
    >,
    __: TFunction,
) => {
    try {
        await mutation({
            variables: {
                currencyId,
                expirationTimeDays,
            },
        });
        successHandler(SuccessTypes.TOKEN_CREATED);
    } catch (e: any) {
        void errorHandler(e, () => {
            return __("Ошибка");
        });
    }
};

function update(
    cache: DataProxy,

    { data }: { data?: GetNewActualDomainAPIToken },
) {
    const queryResult = cache.readQuery<GetAllActualDomainAPIResources>({
        query: GET_ALL_ACTUAL_DOMAIN_API_TOKENS,
    });

    const newToken = g(
        data,
        "authorized",
        "partner",
        "tokenForActualDomainAPI",
    );

    if (!(queryResult && newToken)) {
        return;
    }

    const newQueryResult = _.cloneDeep(queryResult);

    const changingResource =
        newQueryResult.authorized.partner.actualDomainAPIResources.filter(
            res => res.currencyId === newToken.currencyId,
        );
    if (!changingResource || changingResource.length < 1) {
        return;
    }

    changingResource[0].token = newToken.token;

    cache.writeQuery({
        query: GET_ALL_ACTUAL_DOMAIN_API_TOKENS,

        data: newQueryResult,
    });
}
