import { LoadingText } from "components/react-table/loading-text";
import { ReportReactTable } from "../../../../../components/react-table/report-react-table";
import { usePartnerLinks } from "../hooks/use-partner-links";
import React from "react";
import { useColumns } from "../hooks/use-columns";

export const HiddenTab = () => {
    const rest = usePartnerLinks(true);
    const columns = useColumns();

    return (
        <ReportReactTable
            {...rest}
            columns={columns}
            loadingText={<LoadingText fastLoading />}
        />
    );
};
