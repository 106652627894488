import { UniversalForm } from "components/filters";
import { PartnersProgramGeneralInformationContext } from "views";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useDefinedContext } from "hooks/use-context-exist";
import { useTranslation } from "react-i18next";
import { AdminWrapper, AdminWrapperContainer } from "styled/layouts";
import { ReportTabs } from "./report-tabs";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { getDatesByPeriod } from "components/filters/services/get-dates-by-period";
import { Period, Periods } from "components/filters/fields/period";
import {
    IBaseFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";
import GET_PARTNER from "views/manager/main/partner-list/edit-partner/gql/get-partner.gql";
import PARTNER_INFORMATION from "views/partner/components/notification-popup/partnerInformationQuery.gql";
import { useQuery } from "@apollo/client";
import {
    Partner,
    PartnerInformation,
    PartnerVariables,
} from "gql/types/operation-result-types";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const PaymentHistory = React.memo((props: IProps) => {
    const [__] = useTranslation();

    const {
        partnerInfo: { minimalPaymentWeekly, minimalPaymentMonthly },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    const { data: partnerInformationData, loading: partnerInformationLoading } =
        useQuery<PartnerInformation>(PARTNER_INFORMATION, {
            ssr: true,
        });

    const { data: partnerData } = useQuery<Partner, PartnerVariables>(
        GET_PARTNER,
        {
            variables: {
                id: Number(partnerInformationData?.authorized.user.affiliateId),
            },
            skip: partnerInformationLoading,
        },
    );

    const isCalcMonthly =
        partnerData?.authorized.manager.main.partner.calcPeriodMonth;

    const paymentMessage = __(
        "Для получения выплаты, обратитесь, пожалуйста, к менеджеру. Это нужно сделать всего лишь один раз, далее выплаты будут проходить в автоматическом режиме.",
    );

    const [intro1part1, intro1link1, intro1part2] = __(
        "Вы можете связаться с нашими менеджерами, используя их {separator}контактную информацию{separator}, доступную на веб-сайте Партнерской программы.",
    ).split("{separator}");

    return (
        <>
            <UniversalForm
                fields={["currency", "period"]}
                submitButtonName={__("Сгенерировать отчет")}
                additionalFields={{
                    period: {
                        initialValue: {
                            interval: Periods.CUSTOM,
                            ...getDatesByPeriod(Periods.TODAY),
                        },
                        component: (periodProps: IBaseFieldProps) => (
                            <Period {...selectFieldsProps(periodProps)} />
                        ),
                    },
                }}
            >
                {({ submittedValues, actualValues, form }) => {
                    return (
                        <>
                            {form}
                            <FirstLoadTable
                                actualValues={actualValues}
                                submittedValues={submittedValues}
                            >
                                {values => <ReportTabs values={values} />}
                            </FirstLoadTable>
                        </>
                    );
                }}
            </UniversalForm>
            <p />
            <AdminWrapperContainer>
                <AdminWrapper>
                    <p>{paymentMessage}</p>
                    {(minimalPaymentMonthly || minimalPaymentWeekly) && (
                        <p>
                            {isCalcMonthly
                                ? format(
                                      __(
                                          "Минимальная сумма выплат составляет {minimalPaymentMonthly} в месяц",
                                      ),
                                      {
                                          minimalPaymentMonthly,
                                      },
                                  )
                                : format(
                                      __(
                                          "Минимальная сумма выплат составляет {minimalPaymentWeekly} в неделю",
                                      ),
                                      {
                                          minimalPaymentWeekly,
                                      },
                                  )}
                        </p>
                    )}
                </AdminWrapper>
                <AdminWrapper>
                    <p>
                        {intro1part1}
                        <LocalizedNavLinkWithOptionalBackground to="/partner/contacts">
                            {intro1link1}
                        </LocalizedNavLinkWithOptionalBackground>
                        {intro1part2}
                    </p>
                </AdminWrapper>
            </AdminWrapperContainer>
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
