import { useTranslation } from "react-i18next";
import {
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
    UpdateAdvertisingPaymentPlan,
    UpdateAdvertisingPaymentPlanVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import UPDATE_ADVERTISING_PAYMENT_PLAN from "./update.gql";
import { PaymentPlanForm, SubmitValuesWithReset } from "../payment-plan-form";

interface EditPlanProps {
    // eslint-disable-next-line max-len
    paymentPlan: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans;
    finPlanId: number;
    onFinishEditing: () => void;
    onCompareSumms?: (currentAmount: number) => boolean;
}

export const EditAdvPaymentPlan = ({
    paymentPlan,
    finPlanId,
    onFinishEditing,
    onCompareSumms,
}: EditPlanProps) => {
    const [__] = useTranslation();

    const [mutation] = useMutation<
        UpdateAdvertisingPaymentPlan,
        UpdateAdvertisingPaymentPlanVariables
    >(UPDATE_ADVERTISING_PAYMENT_PLAN);

    return (
        <PaymentPlanForm
            onSubmit={async ({
                submittedPaymentPlanValues,
            }: SubmitValuesWithReset) => {
                await mutation({
                    variables: {
                        planId: finPlanId,
                        id: paymentPlan.id,
                        paymentPlan: submittedPaymentPlanValues,
                    },
                });
                onFinishEditing();
            }}
            onCancel={onFinishEditing}
            submitButtonText={__("Сохранить план платеж")}
            paymentPlan={paymentPlan}
            onCompareSumms={onCompareSumms}
        />
    );
};
