import { PaymentFieldInput } from "gql/types/operation-result-types";
import { AddPaymentProps } from "../add-payment";
import { UpdateStatusProps } from "../view-and-edit-payment/helpers";

export const getPaymentSystem = (
    values: UpdateStatusProps["values"] | AddPaymentProps["values"],
) => {
    const arFields: PaymentFieldInput[] = [];
    if (values.paymentSystem.fields) {
        values.paymentSystem.fields.forEach(fld => {
            arFields.push({
                id: fld.id,
                value: fld.value,
            });
        });
    }
    return {
        id: values.paymentSystem.id,
        fields: arFields,
        advertisingCompanyId: values.company || null,
        countryId: values.country?.id || null,
    };
};
