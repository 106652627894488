import { useState, useMemo } from "react";
import { Checkbox } from "components/formik/universal-field/fields/checkbox";
import { IReactTableColumnWithId } from "../column";
import { uniqueId as lodashUniqueId } from "lodash";

type IdType = number | string;

export interface IId {
    id: IdType;
}

export interface IRowsCheckObj<DataType> {
    shouldShowCheckbox?: (original: DataType) => boolean;
    resultHash: string;
}

export function useRowsCheck<DataType extends IId>(
    columns: IReactTableColumnWithId[],
    params?: IRowsCheckObj<DataType>,
) {
    const [checkedRows, setCheckedRows] = useState(new Set<IdType>());
    const [prevResultHash, setPrevResultHash] = useState(params?.resultHash);
    const uid = React.useRef(`row-selection-${lodashUniqueId()}`);

    const columnsWithCheck = useMemo(() => {
        if (!params) {
            return columns;
        }

        // if result was changed, then it's a good idea to reset checked rows,
        // because it might abdolutely change how manager wants to check rows
        if (prevResultHash !== params.resultHash) {
            setPrevResultHash(params.resultHash);
            setCheckedRows(new Set<IdType>());
        }

        return [
            {
                id: "Control checkbox",
                accessor: "Control checkbox",
                minWidth: 40,
                show: false,
                sortable: false,
                disabled: true,
                Header: ({ data }: { data: DataType[] }) => {
                    const checkableIds = data.reduce<IdType[]>(
                        (acc, original) => {
                            if (
                                !params.shouldShowCheckbox ||
                                params.shouldShowCheckbox(original)
                            ) {
                                acc.push(original.id);
                            }
                            return acc;
                        },
                        [],
                    );

                    const allChecked =
                        // > 0 because if there's nothing to check we treat it as nothing is checked (arguable)
                        checkableIds.length > 0 &&
                        checkableIds.every(id => checkedRows.has(id));

                    const onChange = (
                        event: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                        const newChecked = event.target.checked
                            ? new Set(checkableIds)
                            : new Set<IdType>();

                        setCheckedRows(newChecked);
                    };

                    return (
                        <Checkbox
                            id="row-selection-all"
                            type="checkbox"
                            checked={allChecked}
                            onChange={onChange}
                        />
                    );
                },
                Cell: ({ original }: { original: DataType }) => {
                    const checked = checkedRows.has(original.id);

                    const onChange = (
                        event: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                        const newChecked = new Set(checkedRows);
                        newChecked[event.target.checked ? "add" : "delete"](
                            original.id,
                        );
                        setCheckedRows(newChecked);
                    };

                    if (
                        params.shouldShowCheckbox &&
                        !params.shouldShowCheckbox(original)
                    ) {
                        return null;
                    }

                    return (
                        <div>
                            <Checkbox
                                // TODO: switch to external uniqueId
                                id={`row-check-${
                                    uid.current
                                }-${original.id.toString()}`}
                                type="checkbox"
                                checked={checked}
                                onChange={onChange}
                            />
                        </div>
                    );
                },
            },
            ...columns,
        ];
    }, [checkedRows, columns, params, prevResultHash, uid]);

    return {
        checkedRows,
        columnsWithCheck,
    };
}
