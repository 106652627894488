import React from "react";
import { ExportButton as _ExportButton } from "components/react-table/table-select-variables/export/components/export-button";
import { ReportFormat } from "gql/types/enumReportFormat";
import { useExportPaymentHistory } from "../hooks/use-export-payment-history";
import { IExportPaymentHistoryFilter } from "server/middlewares/get-export-payment-history";

interface IProps {
    filters: IExportPaymentHistoryFilter;
    format: ReportFormat;
    caption: string;
}

export const ExportButton = React.memo((props: IProps) => {
    const { format, caption, filters } = props;
    const { loading, exportReport } = useExportPaymentHistory(filters, format);

    return (
        <_ExportButton
            key={format}
            exportData={exportReport}
            saving={loading}
            caption={caption}
        />
    );
});
