import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useState, useContext } from "react";
import {
    AdvertisingErrorStatus,
    AdvertisingPaymentsFromFile,
    AdvertisingPaymentsFromFileVariables,
} from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";
import { ButtonColored } from "styled/button";
import { ReactTable } from "components/react-table";
import { IReactTableColumn } from "components/react-table/column";
import ADVERTISING_PAYMENTS_FROM_FILE from "./query.gql";
import { TFunction } from "i18next";
import { FormGroupButton } from "styled/layouts";
import { GeneratePaymentsWithModal } from "./generate-payments-with-modal";
import { ModalSize } from "components/components-common/modal/modal-size";
import { useModalState } from "hooks/use-modal-state";
import { PaymentsFromFileForm } from "./form";
import { CellWithButtons } from "components/table-item";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { Edit } from "@styled-icons/boxicons-regular/Edit/Edit";
import { PickCurrentLineContext } from "components/get-active-link-context";

function getStatusErrorText(
    status: AdvertisingErrorStatus[] | null,
    __: TFunction,
) {
    const statusMap = new Map([
        [
            AdvertisingErrorStatus.WALLET_NUMBER_INCORRECT,
            __("Поле 'Кошелек' содержит некорректные значения."),
        ],
        [AdvertisingErrorStatus.CURRENCY_NOT_FOUND, __("Валюта не найдена.")],
        [
            AdvertisingErrorStatus.CURRENCY_NOT_MATCH_PAYMENT_METHOD,
            __("Валюта не совпадает с платежным методом."),
        ],
        [
            AdvertisingErrorStatus.PAYMENT_METHOD_NOT_FOUND,
            __("Платежный метод не найден."),
        ],
        [
            AdvertisingErrorStatus.PARTNER_ID_INCORRECT,
            __("Неверный ID партнера."),
        ],
        [AdvertisingErrorStatus.SITE_ID_INCORRECT, __("Неверный ID сайта.")],
    ]);

    const errorText = (status || [])
        .map(item => statusMap.get(item) || "")
        .join(" ");

    return errorText;
}

export const PaymentsFromFileTable = ({ fileId }: { fileId: number }) => {
    const [__] = useTranslation();
    const [editingFileLine, setEditingFileLine] = useState<number>();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });

    const queryResult = useQuery<
        AdvertisingPaymentsFromFile,
        AdvertisingPaymentsFromFileVariables
    >(ADVERTISING_PAYMENTS_FROM_FILE, {
        variables: {
            fileId,
        },
    });

    const payments =
        queryResult.data?.authorized.manager.advertising.advertisingPayments
            .advertisingPaymentsFromFile || [];
    const editingPayment = payments.find(
        payment => payment.fileLine === editingFileLine,
    );
    const columns: IReactTableColumn[] = [
        {
            Header: __("Номер строки Excel файла"),
            accessor: "fileLine",
            minWidth: 80,
            Cell: ({ original }) => {
                const handleClick = () => {
                    setEditingFileLine(original.fileLine);
                    setColumnData({
                        name: "fileLine",
                        id: original.fileLine,
                        tableName: "adv-payments-plans-add-from-file",
                    });
                };
                const button = (
                    <ButtonColoredWithIcon
                        btnColor={BtnColor.Faded}
                        type="button"
                        onClick={handleClick}
                    >
                        <Edit size={15} />
                    </ButtonColoredWithIcon>
                );
                return (
                    <CellWithButtons
                        button={button}
                        link={false}
                        text={original.fileLine}
                    />
                );
            },
        },
        {
            Header: __("Кошелек"),
            accessor: "walletNumber",
            minWidth: 80,
        },
        {
            Header: __("Сумма"),
            accessor: "amountRequest",
            minWidth: 80,
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Платежный метод"),
            accessor: "paymentMethodName",
            minWidth: 80,
        },
        {
            Header: __("Описание"),
            accessor: "comments",
            minWidth: 80,
        },
        {
            Header: __("ID партнера"),
            accessor: "partnerId",
            minWidth: 80,
        },
        {
            Header: __("ID сайта"),
            accessor: "siteId",
            minWidth: 80,
        },
        {
            Header: __("Ссылка на документ"),
            accessor: "documentLink",
            minWidth: 80,
        },
        {
            Header: __("Статус"),
            accessor: "statusCode",
            minWidth: 120,
            Cell: ({ original }) => getStatusErrorText(original.statusCode, __),
        },
    ];
    return (
        <>
            {editingPayment && (
                <PaymentsFromFileForm
                    fileId={fileId}
                    editingPayment={editingPayment}
                />
            )}
            <AdminWrapper>
                <ReactTable
                    columns={columns}
                    data={payments}
                    loading={queryResult?.loading}
                    tableName="adv-payments-plans-add-from-file"
                    linkButtons={
                        payments.length
                            ? [
                                  <FormGroupButton key={1}>
                                      <ButtonColored
                                          type="button"
                                          onClick={open}
                                      >
                                          {__("Создать рекламные выплаты")}
                                      </ButtonColored>
                                  </FormGroupButton>,
                              ]
                            : undefined
                    }
                    getTdProps={(tableState: any, rowInfo: any) => ({
                        style: {
                            backgroundColor:
                                rowInfo &&
                                rowInfo.row.statusCode &&
                                "#ff000078",
                        },
                    })}
                />
            </AdminWrapper>
            <GeneratePaymentsWithModal
                modalProps={modalProps}
                close={close}
                fileId={fileId}
            />
        </>
    );
};
