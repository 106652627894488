import ReactSelect from "react-select";
import { connect, Field, FormikContextType } from "components/formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikErrors } from "formik";
import isPlainObject from "lodash/isPlainObject";
import { FormGroupInputWrapper, FormGroup } from "styled/layouts";
import { ILoadingContext } from "../hooks/use-is-select-iptions-loading";
import { usePeriodOptions } from "../hooks/use-period-options";
import { getDatesByPeriod } from "../services/get-dates-by-period";
import { DisabledShape } from "react-dates";

export enum Periods {
    CUSTOM = "CUSTOM",
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    CURRENT_MONTH = "CURRENT_MONTH",
    PREVIOUS_MONTH = "PREVIOUS_MONTH",
    CURRENT_YEAR = "CURRENT_YEAR",
    PREVIOUS_YEAR = "PREVIOUS_YEAR",
}

interface IInjectedProps {
    formik: FormikContextType<any>;
}

interface IProps extends IInjectedProps {
    name: string;
    fakeSwapLabel?: JSX.Element;
    showPeriods?: boolean;
    label?: string;
    loadingContext?: ILoadingContext;
    disabled?: DisabledShape;
}

type TFieldOnChangeEvent = React.ChangeEvent<ReactSelect> & {
    target: {
        value: Periods;
    };
};

export const Period = connect(
    React.memo(
        ({
            formik,
            name,
            fakeSwapLabel,
            showPeriods = true,
            label,
            loadingContext,
            disabled,
        }: IProps) => {
            const [__] = useTranslation();
            const periodOptions = usePeriodOptions();

            useEffect(() => {
                if (loadingContext) {
                    loadingContext.setLoadingFields((loadingFields: any) => ({
                        ...loadingFields,
                        period: false,
                    }));
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [formik.values[name].interval]);

            useEffect(() => {
                if (isPlainObject(formik.errors[name])) {
                    const errors = formik.errors[name] as FormikErrors<{
                        start: string;
                        end: string;
                    }>;

                    if (errors.start || errors.end) {
                        formik.setFieldError(name, errors.start || errors.end);
                    }
                }

                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [formik.errors, name]);

            const handleChangePeriod = (event: TFieldOnChangeEvent) => {
                const { start, end } = getDatesByPeriod(event.target.value);

                formik.setFieldValue(`${name}.start`, start);
                formik.setFieldValue(`${name}.end`, end);
            };

            return (
                <>
                    {showPeriods && (
                        <FormGroup>
                            <FormGroupInputWrapper>
                                <Field
                                    name={`${name}.interval`}
                                    type="react-select"
                                    label={__("Период")}
                                    options={periodOptions}
                                    minWidth={200}
                                    fieldOnChange={handleChangePeriod}
                                />
                            </FormGroupInputWrapper>
                        </FormGroup>
                    )}
                    <FormGroup>
                        <FormGroupInputWrapper>
                            <Field
                                name={name}
                                type="date-range-picker"
                                fakeSwapLabel={fakeSwapLabel}
                                label={label || ""}
                                disabled={
                                    disabled ||
                                    (showPeriods &&
                                        formik.values[name].interval !==
                                            Periods.CUSTOM)
                                }
                                showClearDates={false}
                            />
                        </FormGroupInputWrapper>
                    </FormGroup>
                </>
            );
        },
    ),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
