import React from "react";
import { ExportButton as _ExportButton } from "components/react-table/table-select-variables/export/components/export-button";
import { useExportFullReport } from "views/manager/reports/full-report/report-table/use-export-full-report";
import { ManagerFullReportFilter } from "gql/types/operation-result-types";
import { ReportFormat } from "gql/types/enumReportFormat";

interface IProps {
    filters: ManagerFullReportFilter;
    button: {
        format: ReportFormat;
        caption: string;
    };
}

export const ExportButton = React.memo((props: IProps) => {
    const {
        button: { format, caption },
        filters,
    } = props;
    const { loading, exportReport } = useExportFullReport(filters, format);

    return (
        <_ExportButton
            key={format}
            exportData={exportReport}
            saving={loading}
            caption={caption}
        />
    );
});
