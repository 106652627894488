import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    Partner_authorized_manager_main_partner,
    SetOptionsVariables,
} from "gql/types/operation-result-types";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { Field } from "components/formik";
import { useSetOptions } from "views/manager/main/partner-list/edit-partner/edit-partner-tabs/profile/hooks/use-set-options";
import { Checkbox } from "./checkbox";

interface IProps {
    partner: Partner_authorized_manager_main_partner;
}

export const Options = (props: IProps) => {
    const [__] = useTranslation();
    const { params } = useRouteMatch<{ id: string }>();
    const id = Number(params.id);
    const { partner } = props;
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const { setOptions, loading, getOptionsData } = useSetOptions(partner);

    const makeOnChange =
        (fieldName: keyof SetOptionsVariables["data"]) =>
        (paramName: keyof Partner_authorized_manager_main_partner) => {
            if (!loading) {
                void setOptions({
                    variables: {
                        id,
                        data: getOptionsData({
                            [fieldName]: !partner[paramName],
                        }),
                    },
                    update: cache => {
                        cache.modify({
                            id: cache.identify({
                                __typename: "Partner",
                                id,
                            }),
                            fields: {
                                [paramName]: (isActive: boolean) => !isActive,
                            },
                        });
                    },
                });
            }
        };

    return (
        <>
            <Checkbox
                id="isPostback"
                label="Postback"
                checked={partner.isPostback}
                disabled={!permissions?.postbacks4}
                onChange={makeOnChange("enablePostBack")}
            />
            <Checkbox
                id="isDomainApi"
                label="Domain API"
                checked={partner.isDomainApi}
                disabled={!permissions?.tokensForGetingActualDomain}
                onChange={makeOnChange("enableDomainApi")}
            />
            <Checkbox
                id="autoPayment"
                label={__("Получать платежи автоматически")}
                checked={partner.autoPayment}
                disabled={!permissions?.paymentsAndInvoices}
                onChange={makeOnChange("enablePaymentsAutomatically")}
            />
            <Checkbox
                id="calcPeriodMonth"
                label={__("Получать платежи раз в месяц")}
                checked={partner.calcPeriodMonth}
                disabled={!permissions?.paymentsAndInvoices}
                onChange={makeOnChange("enablePerMonthlyPayments")}
            />
            <Field
                name="setCodeUsageLimit"
                type="text"
                label={__("Лимит промо-кодов")}
            />
        </>
    );
};
