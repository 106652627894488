import { useSiteCategory } from "hooks/query/use-get-site-category";
import {
    getOriginalData,
    mergeSelectorData,
} from "components/filters/services";
import { mockSiteCategory } from "components/filters/services/mock-selector-data";
import { useTranslation } from "react-i18next";

export const useSiteCategoriesWithTranslations = () => {
    const [__] = useTranslation();
    const { loading, data } = useSiteCategory();
    const siteCategories = React.useMemo(
        () => mergeSelectorData(mockSiteCategory(__), getOriginalData(data)),
        [__, data],
    );

    return {
        siteCategories: siteCategories as Array<{
            label: string;
            value: number;
        }>,
        loading,
    };
};
