import { createSelector } from "reselect";
import { ISettings } from "services/redux/root/settings/reducer";

const getSettingsRoot = (state: any) => state.settings as ISettings;

const getSettings = createSelector(getSettingsRoot, s => s);

export const settingsSelectors = {
    getSettings,
};
