import { UniversalForm } from "components/filters";
import { useTranslation } from "react-i18next";
import { ReportTableWithQuery } from "views/partner-manager/report-table-with-query";
import { MarketingToolsReportEmptyTable } from "./empty-table/index";
import GET_MARKETING_TOOLS_REPORT from "./query.gql";
import { generalQueryParams } from "./general-query-params";
import {
    GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes,
    PartnerSites_authorized_partnerAndManager_data_sites,
} from "gql/types/operation-result-types";
import { useObjectValidations } from "validation/validaion";
import { getDatesByPeriod } from "components/filters/services/get-dates-by-period";
import { Period, Periods } from "components/filters/fields/period";
import {
    IBaseFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const MarketingToolsReport = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { objectRule } = useObjectValidations();

    return (
        <UniversalForm
            fields={[
                "currency",
                "website",
                "subId",
                "period",
                "marketingToolsType",
                "marketingToolId",
                "registrationSource",
            ]}
            uniqueValidation={{
                website:
                    objectRule<PartnerSites_authorized_partnerAndManager_data_sites>(),
                marketingToolsType:
                    objectRule<GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes>(),
            }}
            submitButtonName={__("Сгенерировать отчет")}
            additionalFields={{
                period: {
                    initialValue: {
                        interval: Periods.CUSTOM,
                        ...getDatesByPeriod(Periods.TODAY),
                    },
                    component: (periodProps: IBaseFieldProps) => (
                        <Period {...selectFieldsProps(periodProps)} />
                    ),
                },
            }}
        >
            {({ submittedValues, form, actualValues }) => {
                return (
                    <ReportTableWithQuery
                        actualValues={actualValues}
                        submittedValues={submittedValues}
                        form={form}
                        QUERY={GET_MARKETING_TOOLS_REPORT}
                        emptyTable={MarketingToolsReportEmptyTable}
                        transfromToQueryParams={generalQueryParams}
                    />
                );
            }}
        </UniversalForm>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
