import { ReactTable } from "components/react-table";
import { usePartnersGroups } from "hooks/query/use-partners-groups";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { getColumns } from "./columns";

export const PartnerGroupsTable: React.FC = () => {
    const [__] = useTranslation();
    const { data, paginationAndSorting, loading } = usePartnersGroups();
    const columns = getColumns(__);

    return (
        <AdminWrapper>
            <ReactTable
                columns={columns}
                data={data?.partnerGroups}
                dataLength={data?.rowsCount}
                externalPaginationAndSorting={paginationAndSorting}
                loading={loading}
                linkButtons={[
                    <LocalizedNavLinkWithOptionalBackground
                        key="create-group"
                        to="/agent/main/partners-groups/add"
                        showBackground
                        exact
                    >
                        {__("Создать группу")}
                    </LocalizedNavLinkWithOptionalBackground>,
                ]}
            />
        </AdminWrapper>
    );
};
