/* eslint-disable no-console */
import { PageRoute } from "components/check-roles/guard";
import { NotFoundHandler } from "components/not-found-handler";
import { useTranslation } from "react-i18next";
import { Switch, useRouteMatch } from "react-router-dom";
import urljoin from "url-join";
import { AddSiteIndex } from "./main/sites/index";
import { ComissionStructure } from "./main/comission-structure";
import { Contacts } from "./main/contacts";
import { Feedback } from "./main/feedback";
import { Home } from "./main/home";
import { PaymentHistory } from "./main/payment-history";
import { Edit } from "./main/profile";
import { TwoFactorActivation } from "./main/profile/components/two-factor-auth/activation";
import { SendManagement } from "./main/send-management";
import { DomainToken } from "./marketing-tools/domain-token";
import { Media } from "./marketing-tools/media";
import { PartnerLinks } from "./marketing-tools/partner-links";
import { PartnerLinksGuide } from "./marketing-tools/partner-links-guide";
import { Promocodes } from "./marketing-tools/promo-codes";
import { FullReport } from "./reports/full-report";
import { MarketingToolsReport } from "./reports/marketing-tools-report";
import { PlayersReport } from "./reports/players-report";
import { QuickReport } from "./reports/quick-report";
import { SubPartnerReport } from "./reports/sub-partners-report";
import { useDefinedContext } from "hooks/use-context-exist";
import { ScrollToTop } from "components/scroll-to-top";
import { PartnersProgramGeneralInformationContext } from "views";
import { useUser } from "hooks/query/use-user";
import { partner } from "components/check-roles/rules";
import { LocalizedRedirect } from "components/language-provider";
import { Loader } from "components/components-common/loaders/cub";
import {
    _1XBET_PARTNERS,
    COINPLAY_PARTNERS,
    PARTNERS_1XBET,
} from "server/partner-programs";
import { Postbacks } from "./marketing-tools/postbacks";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const PartnerPartition = ((props: IProps) => {
    const [__] = useTranslation();
    const { url } = useRouteMatch();
    const { permissions, partnersProgramId, stylesTheme } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const { user, loading } = useUser(true);
    const isCoinplayPartners = COINPLAY_PARTNERS === partnersProgramId;
    if (loading) {
        return (
            <Loader
                cubesColor={stylesTheme.accentColor}
                bgColor={stylesTheme.backgroundColor}
            />
        );
    }
    if (!user || !partner({ user })) {
        return <LocalizedRedirect to="/" />;
    }

    return (
        <ScrollToTop>
            <Switch>
                <PageRoute
                    component={Home}
                    path={urljoin(url)}
                    pageTitle={__("Главная")}
                />
                <PageRoute
                    path={urljoin(url, "websites")}
                    component={AddSiteIndex}
                    pageTitle={__("Веб-сайты")}
                />
                <PageRoute
                    path={urljoin(url, "comission-structure")}
                    component={ComissionStructure}
                    pageTitle={__("Структура комиссий")}
                />
                {permissions && permissions.paymentHistory && (
                    <PageRoute
                        path={urljoin(url, "payment-history")}
                        component={PaymentHistory}
                        pageTitle={__("История выплат")}
                    />
                )}
                <PageRoute
                    path={urljoin(url, "user-profile")}
                    component={Edit}
                    pageTitle={__("Профиль пользователя")}
                />
                <PageRoute
                    path={urljoin(url, "contacts")}
                    component={Contacts}
                    pageTitle={__("Контакты")}
                />
                <PageRoute
                    path={urljoin(url, "marketing-tools/links")}
                    component={PartnerLinks}
                    pageTitle={__("Партнерские ссылки")}
                />
                {isCoinplayPartners && (
                    <PageRoute
                        path={urljoin(url, "marketing-tools/links-guide")}
                        component={PartnerLinksGuide}
                        pageTitle={__("Партнерские ссылки")}
                    />
                )}
                <PageRoute
                    path={urljoin(url, "marketing-tools/promo-codes")}
                    component={Promocodes}
                    pageTitle={__("Промокоды")}
                />
                <PageRoute
                    path={urljoin(url, "marketing-tools/media")}
                    component={Media}
                    pageTitle={__("Медиа")}
                />
                {permissions && permissions.postbacks && (
                    <PageRoute
                        exact={false}
                        path={urljoin(url, "marketing-tools/postbacks")}
                        component={Postbacks}
                        pageTitle="Postbacks"
                    />
                )}
                {permissions && permissions.tokensForGetingActualDomain && (
                    <PageRoute
                        path={urljoin(url, "marketing-tools/domain-token")}
                        component={DomainToken}
                        pageTitle={__("Токен домена")}
                    />
                )}

                <PageRoute
                    path={urljoin(url, "reports/quick")}
                    component={QuickReport}
                    pageTitle={__("Краткий суммарный отчет")}
                />
                <PageRoute
                    path={urljoin(url, "reports/full")}
                    component={FullReport}
                    pageTitle={__("Полный отчет")}
                />
                <PageRoute
                    path={urljoin(url, "reports/marketing-tools")}
                    component={MarketingToolsReport}
                    pageTitle={__("Отчет по маркетинговым инструментам")}
                />
                <PageRoute
                    path={urljoin(url, "reports/players")}
                    component={PlayersReport}
                    pageTitle={__("Отчет по игрокам")}
                />
                <PageRoute
                    path={urljoin(url, "reports/sub-partners")}
                    component={SubPartnerReport}
                    pageTitle={__("Отчет по суб-партнерам")}
                />
                {partnersProgramId === _1XBET_PARTNERS ||
                partnersProgramId === PARTNERS_1XBET ? (
                    <PageRoute
                        path={urljoin(url, "sendmail")}
                        component={SendManagement}
                        pageTitle={__("Сообщите руководству")}
                    />
                ) : null}

                {partnersProgramId === _1XBET_PARTNERS ||
                partnersProgramId === PARTNERS_1XBET ? (
                    <PageRoute
                        path={urljoin(url, "feedback")}
                        component={Feedback}
                        pageTitle={__("Обратная связь")}
                    />
                ) : null}
                <PageRoute
                    path={urljoin(url, "user-profile/two-fa-activation")}
                    component={TwoFactorActivation}
                    pageTitle={__("Активация двуфакторной аутентификации")}
                />
                <PageRoute component={NotFoundHandler} path="*" />
            </Switch>
        </ScrollToTop>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
