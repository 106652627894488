import { useMutation } from "@apollo/client";
import {
    Partner_authorized_manager_main_partner,
    SetOptions,
    SetOptionsVariables,
} from "gql/types/operation-result-types";
import SET_OPTIONS from "../gql/set-options.gql";

export const useSetOptions = (
    partner?: Partner_authorized_manager_main_partner,
) => {
    const [setOptions, { loading }] = useMutation<
        SetOptions,
        SetOptionsVariables
    >(SET_OPTIONS);

    const getOptionsData = (
        data: Partial<SetOptionsVariables["data"]>,
    ): SetOptionsVariables["data"] => ({
        enableDomainApi: !!partner?.isDomainApi,
        enablePaymentsAutomatically: !!partner?.autoPayment,
        enablePerMonthlyPayments: !!partner?.calcPeriodMonth,
        enablePostBack: !!partner?.isPostback,
        setCodeUsageLimit: Number(partner?.promoCodsLimit),
        ...data,
    });

    return {
        setOptions,
        loading,
        getOptionsData,
    };
};
