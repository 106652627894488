import {
    UpdatePostback,
    UpdatePostbackVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import UPDATE_POSTBACK from "../gql/update.gql";
import POSTBACKS from "../gql/postbacks.gql";

export const useUpdate = () => {
    const [updatePostback] = useMutation<
        UpdatePostback,
        UpdatePostbackVariables
    >(UPDATE_POSTBACK, {
        refetchQueries: [{ query: POSTBACKS }],
        awaitRefetchQueries: true,
    });

    return { updatePostback };
};
