import { useCookies } from "react-cookie";
import { Variables } from "services/variables";

export function useHiddenColumnsStorge(tableId: string) {
    // чнение и сохраниение колонок и куков
    const shownColumnsCookieName = `${tableId}_hfc`;
    const [cookies, setCookies, removeCookie] = useCookies([
        shownColumnsCookieName,
    ]);
    const shownColumnsIdsWithField = (cookies[shownColumnsCookieName] ||
        []) as string[];
    const shownColumnsIds = shownColumnsIdsWithField.map(idWithField =>
        idWithField.replace(/^field_/, ""),
    );

    function saveShownColumnsIds(ids: string[]) {
        if (ids) {
            setCookies(
                shownColumnsCookieName,
                ids.map(fld => `field_${fld}`),
                Variables.cookiesEndlessOptions,
            );
        } else {
            removeCookie(
                shownColumnsCookieName,
                Variables.cookiesEndlessOptions,
            );
        }
    }

    return {
        shownColumnsIds,
        saveShownColumnsIds,
    };
}
