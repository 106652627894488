import { useTranslation } from "react-i18next";
import { Row } from "styled/layouts";
import { LocalizedNavLink } from "components/language-provider";
import { IconedButton } from "styled/iconed-button";
import { CustomDeleteIcon, CustomEditIcon } from "components/table-item";
import { BtnColor } from "styled/button";
import urljoin from "url-join";
import { DeletePostbackModal } from "../delete-postback-modal";
import { useModalState } from "hooks/use-modal-state";
import { ModalSize } from "components/components-common/modal/modal-size";

interface IProps {
    url: string;
    id: number;
}

export const TableItem = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { url, id } = props;

    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });

    return (
        <Row>
            <LocalizedNavLink to={urljoin(url, `/update/${id}`)}>
                <IconedButton
                    text={__("Редактировать")}
                    color={BtnColor.Faded}
                    icon={CustomEditIcon}
                />
            </LocalizedNavLink>
            <IconedButton
                text={__("Удалить")}
                color={BtnColor.Extra}
                icon={CustomDeleteIcon}
                action={open}
            />
            <DeletePostbackModal
                modalProps={modalProps}
                close={close}
                id={id}
            />
        </Row>
    );
}) as React.ComponentType<IProps>;
