import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { successHandler, SuccessTypes } from "services/success-handler";
import {
    EditAccount as EditAccountMutatuion,
    EditAccountVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import PARTNER_ACCOUNT from "../../../../partner-account.gql";
import EDIT_PARTNER_ACCOUNT from "../../edit-partner-account.gql";
import {
    IOutputValues,
    onSubmitWrapper,
} from "components/formik/on-submit-wrapper";
import { IInitialValues } from "./use-initial-values";
import { ValidationSchemaFunc } from "./use-validation-schema";

// eslint-disable-next-line @typescript-eslint/ban-types
export function useOnSubmit(
    validationSchema: ReturnType<ValidationSchemaFunc>,
) {
    const [__] = useTranslation();
    const [mutation] = useMutation<EditAccountMutatuion, EditAccountVariables>(
        EDIT_PARTNER_ACCOUNT,
        {
            refetchQueries: [{ query: PARTNER_ACCOUNT }],
        },
    );

    return onSubmitWrapper(
        async (values: IOutputValues<IInitialValues, ValidationSchemaFunc>) => {
            log.debug("Submit формы редактрования аккаунта", values);
            try {
                const result = await mutation({
                    variables: {
                        accountInfo: {
                            countryId: values.country.id,
                            languageId: values.language.id,
                            getChangeDomainEmails: values.getChangeDomainEmails,
                        },
                    },
                });
                log.debug(
                    "Результат вызова мутации в форме  редактрования аккаунта",
                    result,
                );

                successHandler(SuccessTypes.EDIT_PARTNER_ACCOUNT);
            } catch (e: any) {
                void errorHandler(e, () => {
                    return __("Ошибка изменения данных партнера");
                });
            }
        },
        validationSchema,
    );
}
