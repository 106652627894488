import { PlusLg } from "@styled-icons/bootstrap/PlusLg";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { ButtonColoredWithIcon, BtnColor, ButtonColored } from "styled/button";
import { Trash2Outline } from "@styled-icons/evaicons-outline/Trash2Outline";
import { LinkSquare } from "@styled-icons/fluentui-system-filled/LinkSquare";
import { PlusCircle } from "@styled-icons/heroicons-solid/PlusCircle";
import { Edit2 } from "@styled-icons/evaicons-solid/Edit2";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { ShowAlt } from "@styled-icons/boxicons-regular/ShowAlt";
import { useTranslation } from "react-i18next";
import { Download } from "@styled-icons/fa-solid/Download/Download";

import { LocalizedLink } from "components/language-provider";

interface ButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
}

interface IButtonPropsWithRebind extends ButtonProps {
    rebind?: boolean;
}

interface ButtonWithLinkProps {
    to: string;
}

export const PlusButton = ({ onClick }: ButtonProps) => {
    return (
        <ButtonColoredWithIcon onClick={onClick} type="button">
            <PlusLg size={20} color={"#fffff"} />
        </ButtonColoredWithIcon>
    );
};

export const DeleteButton = (props: ButtonProps) => {
    return (
        <ButtonColoredWithIcon {...props} type="button">
            <Delete size={28} color={"#ffffff"} />
        </ButtonColoredWithIcon>
    );
};

export const DownloadButton = () => {
    return (
        <ButtonColoredWithIcon type="button">
            <Download size={28} color={"#fffff"} />
        </ButtonColoredWithIcon>
    );
};

export const DeletePaymentPlanButton = (props: ButtonProps) => {
    const [__] = useTranslation();

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Удалить")}
            type="button"
            {...props}
        >
            <Trash2Outline size={15} />
        </ButtonColoredWithIcon>
    );
};

export const GoToPaymentListForConnectionButton = (
    props: IButtonPropsWithRebind,
) => {
    const [__] = useTranslation();

    const title = props.rebind ? __("Перепривязать") : __("Связать");

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={title}
            type="button"
            {...props}
        >
            <LinkSquare size={15} />
        </ButtonColoredWithIcon>
    );
};

export const CancelButton: React.FC<ButtonProps> = ({ onClick, children }) => (
    <ButtonColored btnColor={BtnColor.Faded} onClick={onClick} type="button">
        {children}
    </ButtonColored>
);

export const SubmitButton: React.FC<ButtonProps> = ({
    onClick,
    children,
    disabled,
}) => (
    <ButtonColored onClick={onClick} disabled={disabled}>
        {children || "OK"}
    </ButtonColored>
);

export const CreateAdvertisingPaymentButton = ({ onClick }: ButtonProps) => {
    const [__] = useTranslation();

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Добавить")}
            type="button"
            onClick={onClick}
        >
            <PlusCircle size={15} />
        </ButtonColoredWithIcon>
    );
};

export const EditConnectedAdvertisingButton = ({ onClick }: ButtonProps) => {
    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            type="button"
            onClick={onClick}
        >
            <Edit2 size={15} />
        </ButtonColoredWithIcon>
    );
};

export const ViewConnectedAdvertisingButton = ({ onClick }: ButtonProps) => {
    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            type="button"
            onClick={onClick}
        >
            <ShowAlt size={15} />
        </ButtonColoredWithIcon>
    );
};

export const EditFinPlanButton = ({ to }: ButtonWithLinkProps) => {
    const [__] = useTranslation();

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Редактировать")}
            type="button"
        >
            <LocalizedLink className="no-indent" to={to}>
                <EditOutline size={15} />
            </LocalizedLink>
        </ButtonColoredWithIcon>
    );
};

export const DeleteFinPlanButton = ({ onClick }: ButtonProps) => {
    const [__] = useTranslation();

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Удалить")}
            type="button"
            onClick={onClick}
        >
            <Delete size={15} />
        </ButtonColoredWithIcon>
    );
};
