import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    ShortTimePeriods,
} from "gql/types/operation-result-types";
import { handleActions } from "redux-actions";
import {
    LAST_NOT_FOUND_PATHNAME_SET,
    MOBILE_MENU_SHOW_SET,
    PROFILE_MENU_CLICKED_SET,
    MAIN_MENU_CLICKED_SET,
    STATISTIC_LAST_PARAMS_MAININFO_AND_GRAPH,
    STATISTIC_LAST_PARAMS_QUICK,
} from "./actions";

export interface IMainInfoAndGraph {
    currency: GetCurrencies_authorized_partnerAndManager_data_currencies;
    mockPeriod: string;
}

export interface IStates {
    lastNotFoundPathname: string | undefined;
    isMobileMenuShow?: boolean;
    isProfileMenuClicked?: boolean;
    isMainMenuClicked?: boolean;
    statisticLastParams?: {
        mainInfoAndGraph?: {
            currency: GetCurrencies_authorized_partnerAndManager_data_currencies;
            mockPeriod: string;
        };
        quick?: {
            period: ShortTimePeriods;
        };
    };
}

const initialState: IStates = {
    lastNotFoundPathname: undefined,
    isMobileMenuShow: false,
    isProfileMenuClicked: false,
    isMainMenuClicked: false,
    statisticLastParams: undefined,
};

export const statesReducer = handleActions(
    {
        [LAST_NOT_FOUND_PATHNAME_SET]: (state, { payload }: any) => ({
            ...state,
            lastNotFoundPathname: payload,
        }),
        [MOBILE_MENU_SHOW_SET]: (state, { payload }: any) => ({
            ...state,
            isMobileMenuShow: payload,
        }),
        [PROFILE_MENU_CLICKED_SET]: (state, { payload }: any) => ({
            ...state,
            isProfileMenuClicked: payload,
        }),
        [MAIN_MENU_CLICKED_SET]: (
            state,
            { payload }: { payload: boolean },
        ) => ({
            ...state,
            isMainMenuClicked: payload,
        }),
        [STATISTIC_LAST_PARAMS_MAININFO_AND_GRAPH]: (
            state,
            { payload }: { payload: IMainInfoAndGraph },
        ) => ({
            ...state,
            statisticLastParams: {
                ...state.statisticLastParams,
                mainInfoAndGraph: payload,
            },
        }),
        [STATISTIC_LAST_PARAMS_QUICK]: (
            state,
            { payload }: { payload: ShortTimePeriods },
        ) => ({
            ...state,
            statisticLastParams: {
                ...state.statisticLastParams,
                quick: {
                    period: payload,
                },
            },
        }),
    },
    initialState,
);
