import { UniversalForm } from "components/filters";
import { useTranslation } from "react-i18next";
import { NotificationPopupsList } from "../../components/notification-popup/notification-popups-list";
import { MainInfo } from "./main-info";
import { PartnerStatisticsChart } from "./partner-statistic-chart";
import { QuickStatistic } from "views/partner-manager/statistic-table";
import { QuickStatisticTable } from "./quick-statistic-table";
import { ShortTimePeriods } from "gql/types/operation-result-types";
import { FirstLoadTable } from "views/partner-manager/first-load-table";

export const Home = () => {
    const [__] = useTranslation();

    return (
        <>
            <UniversalForm
                fields={["mockPeriod", "currency"]}
                submitButtonName={__("Применить")}
            >
                {({ form, submittedValues, actualValues }) => (
                    <>
                        <FirstLoadTable
                            actualValues={actualValues}
                            submittedValues={submittedValues}
                        >
                            {values => (
                                <MainInfo
                                    currency={values.currency}
                                    loadCurrency={!values.currency}
                                />
                            )}
                        </FirstLoadTable>
                        <>
                            {form}
                            <FirstLoadTable
                                actualValues={actualValues}
                                submittedValues={submittedValues}
                            >
                                {values => (
                                    <PartnerStatisticsChart
                                        currency={values.currency}
                                        mockPeriod={values.mockPeriod}
                                        loadCurrency={!values.currency}
                                    />
                                )}
                            </FirstLoadTable>
                        </>
                    </>
                )}
            </UniversalForm>
            <QuickStatistic
                table={QuickStatisticTable}
                initialShortPeriod={ShortTimePeriods.YESTERDAY}
            />
            <NotificationPopupsList />
        </>
    );
};
