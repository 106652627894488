import { css } from "styled-components";

const fontsPath = "../assets/fonts/montserrat/";

export const MontserratFonts = css`
    @font-face {
        font-family: "Montserrat";
        font-display: swap;
        src: url("${fontsPath}MontserratRegular.eot");
        src: url("${fontsPath}MontserratRegular.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}MontserratRegular.woff") format("woff"),
            url("${fontsPath}MontserratRegular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("${fontsPath}Montserrat-Light.eot");
        src: url("${fontsPath}Montserrat-Light.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Montserrat-Light.woff") format("woff"),
            url("${fontsPath}Montserrat-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("${fontsPath}Montserrat-Bold.eot");
        src: url("${fontsPath}Montserrat-Bold.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Montserrat-Bold.woff") format("woff"),
            url("${fontsPath}Montserrat-Bold.ttf") format("truetype");
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("${fontsPath}Montserrat-Medium.eot");
        src: url("${fontsPath}Montserrat-Medium.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Montserrat-Medium.woff") format("woff"),
            url("${fontsPath}Montserrat-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat";
        src: url("${fontsPath}Montserrat-SemiBold.eot");
        src: url("${fontsPath}Montserrat-SemiBold.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Montserrat-SemiBold.woff") format("woff"),
            url("${fontsPath}Montserrat-SemiBold.ttf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat";
        font-display: swap;
        src: url("${fontsPath}MontserratExtraBold.eot");
        src: url("${fontsPath}MontserratExtraBold.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}MontserratExtraBold.woff") format("woff"),
            url("${fontsPath}MontserratExtraBold.ttf") format("truetype");
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: "Montserrat";
        font-display: swap;
        src: url("${fontsPath}Montserrat-Black.eot");
        src: url("${fontsPath}Montserrat-Black.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Montserrat-Black.woff") format("woff"),
            url("${fontsPath}Montserrat-Black.ttf") format("truetype");
        font-weight: 900;
        font-style: normal;
    }
`;
