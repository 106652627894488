import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { AdminWrapper, AdminWrapperContainer } from "styled/layouts";
import { PartnerPaymentInfo } from "./partner-payment-info";
import { AddPaymentFile } from "./add-payment-file";
import { PartnerPaymentFiles } from "./partner-payment-files";
import PAYMENT_FILES from "./gql/get-payment-files.gql";
import {
    PaymentFiles,
    PaymentFilesVariables,
    SortedPaymentFilesType,
} from "gql/types/operation-result-types";

export const PaymentDetails = () => {
    const { id } = useParams<{ id: string }>();
    const externalPaginationAndSorting = usePaginationAndSorting();

    const variables = {
        filter: {
            partnerId: Number(id),
            ...externalPaginationAndSorting.state,
            sorted: externalPaginationAndSorting.state
                .sorted as SortedPaymentFilesType,
        },
    };

    const queryResult = useQuery<PaymentFiles, PaymentFilesVariables>(
        PAYMENT_FILES,
        {
            variables,
            ssr: true,
            fetchPolicy: "network-only",
        },
    );

    const refetchFiles = () => queryResult.refetch(variables);

    return (
        <>
            <AdminWrapperContainer>
                <AdminWrapper width="67%">
                    <PartnerPaymentInfo />
                </AdminWrapper>
                <AdminWrapper width="32%">
                    <AddPaymentFile onLoaded={refetchFiles} />
                </AdminWrapper>
            </AdminWrapperContainer>
            <AdminWrapper>
                <PartnerPaymentFiles
                    queryResult={queryResult}
                    externalPaginationAndSorting={externalPaginationAndSorting}
                    refetchFiles={refetchFiles}
                />
            </AdminWrapper>
        </>
    );
};
