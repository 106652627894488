import { css } from "styled-components";

const fontsPath = "../assets/fonts/rubik/";

export const RubikFonts = css`
    @font-face {
        font-family: "Rubik";
        src: url("${fontsPath}Rubik-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Rubik";
        src: url("${fontsPath}Rubik-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Rubik";
        src: url("${fontsPath}Rubik-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Rubik";
        src: url("${fontsPath}Rubik-SemiBold.ttf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Rubik";
        src: url("${fontsPath}Rubik-Bold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}Rubik-ExtraBold.ttf") format("truetype");
        font-weight: 800;
        font-style: normal;
    }
`;
