import { useTranslationList } from "hooks/use-translations-list";
import { createWebsiteRule } from "services/validators";
import * as yup from "yup";

export const useValidationSchema = () => {
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT, IVALID_PARAMETERS_STRING } =
        useTranslationList();

    const parameterRegExp = /^[a-zA-Z0-9\-_]{1,20}$/;

    const requiredString = yup.string().required(FIELD_SHOULD_BE_FILLED);
    const notRequiredString = yup.string();
    const requiredBoolean = yup.boolean().required(FIELD_SHOULD_BE_FILLED);
    const postbackParameter = requiredString
        .typeError(INVALID_FORMAT)
        .matches(parameterRegExp, IVALID_PARAMETERS_STRING);

    const validationSchema = yup.object().shape({
        id: requiredString,
        partnerId: requiredString,
        internalId: requiredString,
        siteId: requiredString,
        campaignId: requiredString,
        typeRequestId: requiredString,
        linkForPostback: notRequiredString,
        postbackTypeId: requiredString,
        variable: notRequiredString
            .nullable(true)
            .matches(/^[a-zA-Z0-9_\\.]{0,20}$/, IVALID_PARAMETERS_STRING)
            .defined(),
        url: createWebsiteRule(INVALID_FORMAT).required(FIELD_SHOULD_BE_FILLED),
        linkForTraffic: notRequiredString,
        linkForPurlostback: notRequiredString,
        staticParameters: requiredString
            .nullable(true)
            .matches(
                /^([a-zA-Z_0-9]+=[\w-]+&)*?([a-zA-Z_0-9]+=[\w-]+)$/,
                IVALID_PARAMETERS_STRING,
            )
            .defined(),
        isClickIdPostbackParameter: requiredBoolean,
        clickIdPostbackParameter: postbackParameter,
        isSiteIdPostbackParameter: requiredBoolean,
        siteIdPostbackParameter: postbackParameter,
        isPartnerIdPostbackParameter: requiredBoolean,
        partnerIdPostbackParameter: postbackParameter,
        isOtherPostbackParameter: requiredBoolean,
        otherPostbackParameter: postbackParameter,
        isTransaction: requiredBoolean,
        transaction: postbackParameter,
        isSumdepPostbackParameter: requiredBoolean,
        sumdepPostbackParameter: postbackParameter,
        childGuid: notRequiredString.nullable(true),
        statusId: requiredString,
        newStatusId: requiredString,
    });

    return validationSchema;
};
