import styled, { css, keyframes } from "styled-components";
import { SelectSC } from "../../formik/universal-field/fields/select/styled";

export const StyledError = styled.div<{ width?: string }>`
    ${props => props.width && `width: ${props.width};`}
    color: #ff5e5e;
    font-style: italic;
    font-size: 80%;
    margin-top: 0.5em;
`;

export const SuccessText = styled.p<{ width?: string }>`
    ${props => props.width && `width: ${props.width};`}
    color: #068b06;
    font-style: italic;
    font-size: 80%;
    margin-top: 0.5em;
    max-width: 100%;
`;

const blinkKeyframs = keyframes`
    from {
        border-color: red
    }
    to {
        border-color: transparent
    }
`;

export const StyledUniversalField = styled.div<{
    hasError: boolean;
    isValid?: boolean;
    blink?: number;
}>`
    display: flex;
    flex-direction: column;
    position: relative;
    label {
        display: block;
        margin-bottom: 5px;
    }
    label + * {
        width: 100%;
    }
    width: 100%;

    input.labelNowrap + label {
        white-space: nowrap;
    }

    .defaultWidthField {
        width: 100%;
        min-width: 150px;
    }

    ${props => css`
        input,
        input[type="radio"] + label::before,
        input[type="checkbox"] + label::before,
        ${SelectSC} {
            ${props.hasError &&
            css`
                outline: 1px solid #ff0000;
            `}
        }
    `}

    border: ${props => css`
        ${props.isValid ? "1px solid green" : "1px solid transparent"};
    `}
    
    ${props =>
        props.blink &&
        css`
            border: 2px solid red;
            animation: ${blinkKeyframs} ${props.blink}ms ease-out;
        `}
`;
