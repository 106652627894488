import {
    CreatePartnerLink,
    CreatePartnerLink_authorized_partner_partnerLinksMutations_createPartnerLink,
    CreatePartnerLinkVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import CREATE_PARTNER_LINK from "../gql/create-partner-link.gql";
import GET_PARTNER_LINKS from "../gql/get-partner-links.gql";

export const useCreatePartnerLink = () => {
    const [loading, setLoading] = React.useState(true);
    const [currentLink, setCurrentLink] = React.useState<
        | CreatePartnerLink_authorized_partner_partnerLinksMutations_createPartnerLink
        | undefined
    >(undefined);

    const [mutation] = useMutation<
        CreatePartnerLink,
        CreatePartnerLinkVariables
    >(CREATE_PARTNER_LINK, {
        refetchQueries: [
            {
                query: GET_PARTNER_LINKS,
                variables: { hidden: false },
            },
        ],
        onCompleted: data => {
            setCurrentLink(
                g(
                    data,
                    "authorized",
                    "partner",
                    "partnerLinksMutations",
                    "createPartnerLink",
                ),
            );
            setLoading(false);
        },
    });

    return { loading, mutation, currentLink };
};
