import { placeholder, rgba, textInputs } from "polished";
import { createGlobalStyle, css } from "styled-components";
import styled from "styled-components";
import { Animate } from "components/components-common/animate/animate";
import { ReactDates } from "styled/react-dates";
import { UbuntuFonts } from "assets/fonts";
import {
    adminBorderColor2,
    adminBorderColor3,
    dangerBgColor,
    dangerColor,
    dangerTextColor,
    disabledColor,
    focusColor,
    infoBgColor,
    successBgColor,
    warningColor,
    backgroundColor0,
} from "./themes";
import { Typogoraphy } from "./typogoraphy";
import { media } from "./variables";
import { ResetCss } from "./reset";

const globalStylesCss = css`
    ${ResetCss};
    ${UbuntuFonts};
    ${Typogoraphy};

    [role="button"] {
        cursor: pointer;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
    a:focus {
        outline: 0 none;
    }
    input[type="button"],
    input[type="submit"],
    input[type="reset"],
    input[type="file"],
    button {
        color: inherit;
    }
    .hidden {
        display: none !important;
    }
    :focus::-webkit-input-placeholder {
        color: transparent;
    }
    :focus::-moz-placeholder {
        color: transparent;
    }
    :focus:-moz-placeholder {
        color: transparent;
    }
    :focus:-ms-input-placeholder {
        color: transparent;
    }
    *::selection {
        background: ${props => props.theme.accentColor};
        color: white;
    }
    html {
        min-height: 100%;
        height: 100%;
        position: relative;
        /* ${media.md`height: 100%;`} */
        /* background: ${props => props.theme.backgroundColor}; */
    }

    body {
        line-height: 1.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    }

    /* #root,
    #root > div,
    #root > div > div:first-of-type{
        min-height: 100vh;
        height: 100%;
    } */

    /* Form
-----------------------------------------------------------------------------*/

    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    button,
    ${textInputs()} input[type="submit"],
    textarea {
        margin: 0;
        box-sizing: border-box;
        max-width: 100%;
        font-family: inherit;
    }
    textarea {
        min-width: 100%;
        min-height: 42px;
    }
    p textarea {
        font-family: inherit;
        font-size: inherit;
        width: 100%;
        max-height: 100%;
    }
    button,
    input,
    textarea {
        appearance: none;
        transition: all 0.2s ease;
    }
    label {
        font-weight: normal;
    }
    ${textInputs()}
    textarea,
    select {
        line-height: normal;
        background: transparent;
        border: 1px solid ${adminBorderColor2};
        color: ${props => props.theme.primaryColor};
        display: inline-block;
        outline: 0 none;
        padding: 0.563em 0.8em;
        ${placeholder({
            color: "#a0a6ab",
        })}
    }
    ${textInputs()}
    textarea,
    select,
    button[type="submit"],
    input[type="submit"],
    input[type="button"] {
        &:focus {
            box-shadow: 0 0 0.25em ${focusColor};
            outline: 0 none;
        }
    }
    input:required:invalid,
    input.error {
        /* border: 1px solid ${dangerColor} !important; */
        box-shadow: 0px 1px 0px 1px ${dangerColor},
            0px -1px 0px 1px ${dangerColor}, 1px 0px 0px 1px ${dangerColor},
            -1px 0px 0px 1px ${dangerColor} !important;
        /* background-color: ${rgba("#ff5f5f", 0.2)} !important; */
    }
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        background-color: unset !important;
        background-image: unset !important;
        color: unset !important;
    }
    label + select {
        margin-left: 0.5em;
    }
    select {
        color: inherit;
        outline: 0 none;
        font-size: 0.97em;
        cursor: pointer;
        padding-right: 2em;
        position: relative;
        -webkit-appearance: none;
        /* background-image: url("~assets/gfx/select_apperance.svg"); */
        background-position: right center;
        background-repeat: no-repeat;
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: "";
        -ms-appearance: none;
        appearance: none !important;
        &::selection {
            background-color: ${props => props.theme.activeColor};
            color: white;
        }
        option {
            outline: 0 none;
            border: 0 none;
            line-height: normal;
            background-color: ${props => props.theme.textColor};
            &:focus,
            &:active {
                outline: 0 none;
            }
            &:checked {
                box-shadow: inset 50em 50em ${props => props.theme.textColor};
            }
            &[selected="selected"] {
                box-shadow: inset 50em 50em ${props => props.theme.textColor};
            }
            &::selection {
                background-color: ${props => props.theme.activeColor};
                color: white;
            }
        }
    }
    select::-ms-expand {
        display: none;
    }
    button,
    input[type="submit"],
    input[type="button"] {
        vertical-align: top;
        max-width: 100%;
        color: ${props => props.theme.buttons.default.color};
        background-color: transparent;
        display: inline-block;
        text-align: center;
        border: medium none;
        cursor: pointer;
        appearance: none;
        user-select: none;
        transition: all 0.1s ease 0s;
        &:hover {
            cursor: pointer;
        }
    }
    input[disabled],
    textarea[disabled] {
        background-color: ${disabledColor};
    }

    /* Libs
-----------------------------------------------------------------------------*/
    #root {
        ${ReactDates}

        ${Animate}

        .rc-pagination {
            display: flex;
            flex-wrap: wrap;

            > li {
                cursor: pointer;
                display: inline-block;
                height: 2.5em;
                min-width: 2.5em;
                font-size: 1em;
                padding: 0.5em 1em;
                border-radius: 2px;
                transition: background-color 0.25s ease, color 0.25s ease,
                    opacity 0.25s ease;
                margin-right: 1px;
                margin-left: 1px;
                color: ${adminBorderColor2};
                background-color: ${adminBorderColor3};
                outline: none;
                &:hover {
                    background-color: ${props => props.theme.activeColor};
                    color: ${props => props.theme.textColor};
                }
                &.rc-pagination-item-active {
                    color: ${backgroundColor0};
                    background-color: ${props => props.theme.accentColor};
                }

                &.rc-pagination-prev {
                    .rc-pagination-item-link {
                        &:before {
                            content: "<";
                            width: 14px;
                        }
                    }
                }
                &.rc-pagination-next {
                    .rc-pagination-item-link {
                        &:before {
                            content: ">";
                            width: 14px;
                        }
                    }
                }
                &.rc-pagination-jump-prev,
                &.rc-pagination-jump-next {
                    .rc-pagination-item-link {
                        &:before {
                            content: "...";
                            width: 14px;
                        }
                    }
                }
            }

            @media (max-width: 350px) {
                .rc-pagination-item {
                    display: none;

                    &.rc-pagination-item-active {
                        display: inline-block;

                        & + * {
                            display: inline-block;
                        }
                    }
                }
                .rc-pagination-jump-next {
                    & + * {
                        display: initial;
                    }
                }
            }

            @media (max-width: 320px) {
                .rc-pagination-jump-next {
                    display: none;
                    & + * {
                        display: none;
                    }
                }
            }
        }
        .Toastify__toast-container {
            color: ${props => props.theme.primaryColor};
            font-size: 16px;
            font-family: "Ubuntu", helvetica, arial, sans-serif;
            font-weight: normal;
            ${media.ltMax`
                font-size: 14px;
            `}
        }
        .Toastify__toast {
            padding: 8px 10px 8px 12px;
        }
        .Toastify__toast--info {
            background: ${infoBgColor};
        }
        .Toastify__toast--success {
            background: ${successBgColor};
        }
        .Toastify__toast--warning {
            background: ${warningColor};
        }
        .Toastify__toast--error {
            color: ${dangerTextColor};
            background: ${dangerBgColor};
        }
        .Toastify__close-button {
            color: ${props => props.theme.primaryColor};
        }
        .info-row span img {
            width: 38px;
        }
    }
`;

export const RegularGlobalStyles =
    createGlobalStyle`${globalStylesCss};` as any;

export const JustToImportStyled = styled.div`
    display: none;
`;
