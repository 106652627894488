import { useMutation } from "@apollo/client";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { errorHandler } from "services/error-handler";
import { AddPartnerForm } from "./add-partner-form";
import { useInitialValues } from "./hooks/use-initial-values";
import { useValidationSchema } from "./hooks/use-validation-schema";
import ADD_PARTNER from "./gql/add-partner.gql";
import {
    AddPartnerVariables,
    AddPartner as AddPartnerData,
} from "gql/types/operation-result-types";
import { SuccessTypes, successHandler } from "services/success-handler";

export const AddPartner = () => {
    const [create] = useMutation<AddPartnerData, AddPartnerVariables>(
        ADD_PARTNER,
    );

    const initialValues = useInitialValues();
    const validationSchema = useValidationSchema();

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async values => {
                try {
                    await create({
                        variables: {
                            partner: {
                                login: values.login,
                                password: values.password,
                                firstname: values.firstname,
                                surname: values.surname,
                                email: values.email as string,
                                messenger: values?.messenger?.id
                                    ? {
                                          id: values.messenger.id,
                                          login: values.messenger.name,
                                      }
                                    : null,
                                phone: values.phone,
                                website: values.website,
                                country: values.country.id,
                                language: values.language.id,
                                note: values.message,
                                currencyId: values.currency.id,
                                commisionGroupId: values.commisionGroup?.id,
                                partnersGroupId: values.partnersGroup?.id,
                            },
                        },
                    });

                    return successHandler(
                        SuccessTypes.CREATE_PARTNER,
                        values.login,
                    );
                } catch (error: any) {
                    void errorHandler(error);
                    throw error;
                }
            }, validationSchema)}
        >
            {() => <AddPartnerForm />}
        </FormikConsoleErrors>
    );
};
