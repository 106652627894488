import { UniversalForm } from "components/filters";
import {
    GetQuickReport,
    GetQuickReportVariables,
    PartnerSites_authorized_partnerAndManager_data_sites,
} from "gql/types/operation-result-types";
import { usePooling } from "hooks/use-pooling";
import * as _ from "lodash";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import GET_QUICK_REPORT from "./query.gql";
import { makeUtcCustomPeriod } from "services/project-utils";
import { QuickReportTable } from "views/partner-manager/quick-report";
import { useTranslationList } from "hooks/use-translations-list";
import { getDatesByPeriod } from "components/filters/services/get-dates-by-period";
import { Period, Periods } from "components/filters/fields/period";
import {
    IBaseFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const QuickReport = React.memo((props: IProps) => {
    const [__] = useTranslation();

    const [getQuickReport, queryResult] = useLazyQuery<
        GetQuickReport,
        GetQuickReportVariables
    >(GET_QUICK_REPORT);

    usePooling(
        queryResult,
        queryResult.data?.authorized.partner.reports.quickReport.status,
        4000,
    );
    const { INVALID_FORMAT } = useTranslationList();

    return (
        <UniversalForm
            fields={["currency", "website", "marketingToolId", "period"]}
            uniqueValidation={{
                website: yup
                    .object<PartnerSites_authorized_partnerAndManager_data_sites>()
                    .typeError(INVALID_FORMAT),
            }}
            onSubmit={async submittedValues => {
                const { currency, website, marketingToolId, period } =
                    submittedValues;
                const { start, end } = makeUtcCustomPeriod(
                    period.start,
                    period.end,
                );
                await getQuickReport({
                    variables: {
                        filter: {
                            currencyId: _.parseInt(currency && currency.id),
                            startPeriod: (start && start.toISOString()) || "",
                            endPeriod: (end && end.toISOString()) || "",
                            promoId:
                                (marketingToolId &&
                                    marketingToolId.toString()) ||
                                undefined,
                            siteId: website && website.id,
                        },
                    },
                });
            }}
            submitButtonName={__("Сгенерировать отчет")}
            additionalFields={{
                period: {
                    initialValue: {
                        interval: Periods.CUSTOM,
                        ...getDatesByPeriod(Periods.TODAY),
                    },
                    component: (periodProps: IBaseFieldProps) => (
                        <Period {...selectFieldsProps(periodProps)} />
                    ),
                },
            }}
        >
            {({ form, submittedValues }) => {
                return (
                    <>
                        {form}
                        <QuickReportTable
                            tableData={
                                queryResult.data?.authorized.partner.reports
                                    .quickReport.total
                            }
                            currency={submittedValues?.currency}
                            queryResult={queryResult}
                        />
                    </>
                );
            }}
        </UniversalForm>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
