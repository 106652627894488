import { PostbackLists } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import POSTBACK_LISTS from "../gql/postbackLists.gql";

export const usePostbackLists = (id: number | null) => {
    const queryResult = useQuery<PostbackLists>(POSTBACK_LISTS, {
        ssr: true,
        fetchPolicy: "network-only",
        variables: { id },
    });
    const data = queryResult.data?.authorized.partner.postback.postbackLists;

    return {
        data,
        loading: queryResult.loading,
    };
};
