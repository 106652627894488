import { Utils } from "@webx/utils";
import { Variables } from "services/variables";

export const addressOfPartnersv1 = () => {
    let redirectAddress = "";
    if (!__ENVIRONMENT__.development && Utils.browser.hasWindow()) {
        // подставляет перед текущим доменом "manager." если адрес двух-уровневый
        // и "manager-" если адрес трех-уровневый
        // (необходимо для 1xstavka партнерки т.к. не выпустили нужный сертификат)
        const domainRegex = new RegExp(/(http(s)?:\/\/)((.+\.)?(.+\.)(.+))/g); // (тайпскипт убирает именованные группы)
        const domain = window.location.origin;

        const result = domainRegex.exec(domain);
        if (!result) {
            throw new Error("The domain structure isn't recognised");
        }

        let replacePattern = "";
        // двух-уровневый
        if (!result[4]) {
            replacePattern = "$1manager.$3";
        }
        // трех-уровневый
        else {
            replacePattern = "$1manager-$3";
        }

        redirectAddress = domain.replace(domainRegex, replacePattern);
    } else {
        redirectAddress = `${Variables.localAddressOfPartnersv1}`;
    }
    return redirectAddress;
};
