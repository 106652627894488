import { Contacts as ContactsQuery } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_CONTACTS = gql`
    query Contacts {
        partnersProgram {
            contacts
        }
    }
`;

export const useUserContacts = (user?: { id: string }) => {
    const queruResult = useQuery<ContactsQuery>(GET_CONTACTS, {
        ssr: true,
        variables: { userId: user && user.id },
    });

    const contactList = g(queruResult.data, "partnersProgram", "contacts");

    return { contactList, loading: queruResult.loading };
};
