import arrowBottomSmall from "assets/images/arrow-bottom-small.svg";
import { HeaderItemSC } from "components/header/styled";
import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";

export const UserInfo = styled.div`
    ${rtl`padding-right: 18px;`};
    position: relative;
    display: flex;
    align-items: center;
    & svg {
        ${rtl`margin-right: 5px;`};
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        ${rtl`right: 0;`};
        width: 8px;
        height: 5px;
        margin-top: -2px;
        background-image: url(${arrowBottomSmall});
        transition: all 0.3s ease;
    }
`;

interface IUserMenuSwitcherSCProps {
    opened: boolean;
}

export const ProfileMenuSC = styled.div<IUserMenuSwitcherSCProps>`
    ${media.smMax`
        display:none;
    `}
    color: ${props => props.theme.textColor};
    ${HeaderItemSC}
    flex: 1 1 auto;
    cursor: pointer;
    ${props =>
        props.opened &&
        css`
            box-shadow: inset 0 -4px 0 0 ${props.theme.secondBorderColor};
            ${/* sc-sel */ UserInfo}::after {
                transform: rotate(-180deg);
            }
        `}
    &:hover ${/* sc-sel */ UserInfo}::after {
        ${props =>
            props.opened
                ? css`
                      margin-top: -4px;
                  `
                : css`
                      margin-top: 0px;
                  `}
    }

    ul {
        position: absolute;
        top: 100%;

        ${rtl`
            right: 0;
            left: auto;
        `};
        opacity: 0;
        box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
            0 2px 10px 0 rgba(34, 36, 38, 0.15);
        max-height: 0;
        overflow: hidden;
        transition: display 0s linear 0.3s, opacity 0.3s linear,
            max-height 0.3s linear;
        /* display: none; */
        ${props =>
            props.opened &&
            css`
                /* display: block; */
                max-height: 700px;
                opacity: 1;
                transition-delay: 0s;
            `}
        li {
            margin-bottom: -1px;
            min-width: 250px;
            a,
            span {
                color: ${props => props.theme.textColor};
                background-color: ${props => props.theme.frontBackgroundColor};
                display: flex;
                align-items: center;
                padding: 10px;
                border-style: solid;
                border-color: ${props => props.theme.backgroundColor};
                ${rtl`border-width: 0 0 2px;`};
                ${rtl`text-align: left;`};
                &:hover {
                    background-color: ${props => props.theme.secondBorderColor};
                    color: ${props => props.theme.textColor};
                }
            }
            svg {
                ${rtl`margin-right: 5px;`};
            }
            &:last-child a,
            &:last-child span {
                border-bottom-width: 0;
            }
        }
    }
`;
