import { ShortTimePeriods } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { useStatisticColumns } from "views/partner-manager/statistic-table/statistic-table-colums";
import { ReportReactTable } from "components/react-table/report-react-table";
import GET_PARTNER_QUICK_REPORT from "../gql/partner-quick-statistic.gql";
import {
    PartnerQuickStatistic,
    PartnerQuickStatisticVariables,
} from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { StatesActionCreators } from "services/redux/root/states/actions";
import { useEffect } from "react";

interface IInjectedProps {
    shortPeriod: ShortTimePeriods;
}

export const QuickStatisticTable = ({ shortPeriod }: IInjectedProps) => {
    const [__] = useTranslation();
    const columns = useStatisticColumns();
    const queryResult = useQuery<
        PartnerQuickStatistic,
        PartnerQuickStatisticVariables
    >(GET_PARTNER_QUICK_REPORT, {
        variables: {
            period: shortPeriod,
        },
        fetchPolicy: "network-only",
        ssr: true,
    });
    const dispatch = useDispatch();

    // запоминает выбранный параметр, в том числе и при самой начальной загрузке,
    // когда пользователь еще ничего не выбирал
    useEffect(() => {
        dispatch(StatesActionCreators.setStatisticLastParamsQuick(shortPeriod));
    }, [shortPeriod, dispatch]);

    return (
        <ReportReactTable
            showPagination={false}
            reportName="Statistic"
            columns={columns}
            queryResult={queryResult}
            data={queryResult.data?.authorized.partner.mainStatistic.quick.rows}
            reportStatus={
                queryResult.data?.authorized.partner.mainStatistic.quick.status
            }
            loadingText={__(
                "Отчёт формируется... Это может занять 15 минут и более",
            )}
        />
    );
};
