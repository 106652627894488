import { Download } from "@styled-icons/fa-solid/Download/Download";
import { useTranslation } from "react-i18next";
import { BtnColor, ButtonColored } from "styled/button";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    mediaId: number;
    width?: string;
}

export const DownloadMediaFile = React.memo((props: IProps) => {
    const [__] = useTranslation();
    return (
        <a
            href={`/lapi/files/media-code-downloading/${props.mediaId}`}
            download={""}
        >
            <ButtonColored
                width={props.width}
                minWidth={190}
                btnColor={BtnColor.Extra}
            >
                <Download size={13} /> {__("Скачать")}
            </ButtonColored>
        </a>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
