import { ObjectSchema } from "yup";

interface Exclusive {
    required: boolean;
}

interface SchemaFieldWithExclusive {
    _exclusive?: Exclusive;
}

export const checkRequired = (
    validationSchema: ObjectSchema<
        | {
              [x: string]: unknown;
          }
        | undefined
    >,
    field: string,
) => {
    const fieldSchema =
        validationSchema.fields && validationSchema.fields[field];
    if (fieldSchema) {
        return Boolean(
            (fieldSchema as SchemaFieldWithExclusive)._exclusive?.required,
        );
    } else {
        return false;
    }
};
