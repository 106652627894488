import { useContext, Dispatch, SetStateAction } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { GetPartnerSitesParams } from "gql/types/operation-result-types";
import { errorHandler } from "services/error-handler";
import { createWebsiteRule } from "services/validators";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { Form, Field } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { FakeLabel } from "components/filters/form";
import { useTranslationList } from "hooks/use-translations-list";
import { FormDirectionRow, FormGroup, FormGroupButton } from "styled/layouts";
import { useEditPartnerSite } from "../../hooks/edit-partner-site";
import { ButtonStyled } from "styled/button";
import { EditData } from "../../table";

interface IEditForm {
    getSitesVariables: GetPartnerSitesParams;
    setEditData: Dispatch<SetStateAction<EditData>>;
    editData: EditData;
}

export const EditForm = ({
    getSitesVariables,
    editData,
    setEditData,
}: IEditForm) => {
    const [__] = useTranslation();
    const { INVALID_FORMAT, FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const { editPartnerSite } = useEditPartnerSite(getSitesVariables);

    const initialValues = {
        name: editData?.name || "",
    };

    const validationSchema = yup.object({
        name: createWebsiteRule(INVALID_FORMAT)
            .required(FIELD_SHOULD_BE_FILLED)
            .trim(),
    });

    return (
        <FormikConsoleErrors<typeof initialValues>
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async values => {
                if (editData) {
                    try {
                        await editPartnerSite({
                            variables: { id: editData.id, name: values.name },
                        });
                    } catch (e: any) {
                        void errorHandler(e);
                    }
                    setColumnData({
                        name: "id",
                        id: editData.id,
                        tableName: "partnerSites",
                    });
                    setEditData(null);
                }
            }}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormDirectionRow>
                        <FormGroup>
                            <Field
                                name="name"
                                type="text"
                                label={__("SiteUrl")}
                                autoComplete="on"
                            />
                        </FormGroup>
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>{__("Редактировать")}</SubmitButton>
                        </FormGroupButton>
                        <FormGroupButton>
                            <FakeLabel />
                            <ButtonStyled
                                type="button"
                                disabled={isSubmitting}
                                onClick={() => setEditData(null)}
                            >
                                {__("Отмена")}
                            </ButtonStyled>
                        </FormGroupButton>
                    </FormDirectionRow>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
