import React from "react";

interface INoScript {
    noscript?: string | null;
}

export const NoScriptBodyInjector = React.memo(
    ({ noscript = "" }: INoScript) => {
        return noscript ? (
            <div
                id="noscript-wrapper"
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: noscript,
                }}
            />
        ) : null;
    },
);
