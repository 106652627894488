import {
    SetStateAction,
    useState,
    forwardRef,
    useImperativeHandle,
} from "react";
import { useMutation } from "@apollo/client";
import { Paragraph } from "services/styled-components/typogoraphy";
import { useTranslation } from "react-i18next";
import { useModalState } from "hooks/use-modal-state";
import { ModalSize } from "components/components-common/modal/modal-size";
import { Modal } from "components/components-common/modal";
import DELETE_ADVERTISING_PAYMENT_PLAN from "./delete.gql";
import {
    DeleteAdvertisingPaymentPlan,
    DeleteAdvertisingPaymentPlanVariables,
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
} from "gql/types/operation-result-types";

import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { CancelButton, SubmitButton } from "../../buttons";

type GeneratePaymentPlans =
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans;

interface DeletePaymentPlanModalProps {
    finPlanId?: number;
    setPaymentPlans?: React.Dispatch<
        SetStateAction<GeneratePaymentPlans[] | undefined>
    >;
}

export interface SetPaymentPlanIdProps {
    setPaymentPlanId: (paymentPlanId: string) => void;
}

export const DeletePaymentPlanModalInternal: React.ForwardRefRenderFunction<
    SetPaymentPlanIdProps,
    DeletePaymentPlanModalProps
> = (props, ref) => {
    const { setPaymentPlans, finPlanId } = props;
    const [__] = useTranslation();
    const [currentPaymentPlanId, setCurrentPaymentPlanId] = useState<string>();

    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });

    const [mutation] = useMutation<
        DeleteAdvertisingPaymentPlan,
        DeleteAdvertisingPaymentPlanVariables
    >(DELETE_ADVERTISING_PAYMENT_PLAN);

    const deletePaymentPlan = async () => {
        if (finPlanId && currentPaymentPlanId) {
            const deleted = await mutation({
                variables: {
                    planId: finPlanId,
                    id: currentPaymentPlanId,
                },
            });
            if (deleted && setPaymentPlans) {
                setPaymentPlans(prevPaymentPlans => {
                    if (prevPaymentPlans) {
                        return prevPaymentPlans.filter(
                            paymentPlan =>
                                paymentPlan.id !== currentPaymentPlanId,
                        );
                    }
                });
            }
        }
    };

    const closeModal = () => {
        setCurrentPaymentPlanId(undefined);
        close();
    };

    useImperativeHandle(ref, () => ({
        setPaymentPlanId: (paymentPlanId: string) => {
            setCurrentPaymentPlanId(paymentPlanId);
            open();
        },
    }));

    const handleSubmit = async () => {
        await deletePaymentPlan();
        closeModal();
    };

    return (
        <Modal {...modalProps}>
            <Paragraph>{__("Удалить план-платеж?")}</Paragraph>
            <FormDirectionRow>
                <FormGroupButton>
                    <SubmitButton onClick={handleSubmit} />
                </FormGroupButton>
                <FormGroupButton>
                    <CancelButton onClick={closeModal}>
                        {__("Отмена")}
                    </CancelButton>
                </FormGroupButton>
            </FormDirectionRow>
        </Modal>
    );
};

export const DeletePaymentPlanModal = forwardRef(
    DeletePaymentPlanModalInternal,
);
