import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddPayment, useDefaultInitilValues } from "../components/add-payment";
import { Steps } from "components/steps";
import { BackNextButtons } from "components/steps/buttons";
import { ChooseSite } from "../components/choose-site";
import { ChoosePartner } from "../../components/choose-partner";
import { useLocation } from "react-router-dom";
import { GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment } from "gql/types/operation-result-types";
import { useHistory } from "react-router-dom";
import { PartnerListType } from "gql/types/operation-result-types";
import { useGetInitialValuesFromPayment } from "../components/common-form-advertising/get-initial-values-from-payment";

export const AddPaymentSteps = () => {
    const [__] = useTranslation();
    const { state: advPayment } = useLocation<
        | GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment
        | undefined
    >();
    const [step, setStep] = useState(advPayment ? 2 : 0);
    const [partnerId, setPartnerId] = useState(advPayment?.partnerId);
    const [siteId, setSiteId] = useState(advPayment?.siteId || undefined);
    const history = useHistory();
    let initialValues = useDefaultInitilValues(partnerId, siteId);
    const getInitialValuesFromPayment = useGetInitialValuesFromPayment({
        partnerId,
        siteId,
    });
    if (advPayment) {
        initialValues = getInitialValuesFromPayment(advPayment);
    }

    const stepData: Array<{
        component: ReactNode;
        stepTitle: string;
    }> = [
        {
            component: (
                <ChoosePartner
                    partnerId={partnerId}
                    partnerListType={PartnerListType.AdvertisingPayments}
                    choosePartner={newPartnerId => {
                        setPartnerId(newPartnerId);
                        if (newPartnerId !== partnerId) {
                            // после перевыбора партнера сайт обнуляем (предыдущий сайт может не относиться к партнеру)
                            setSiteId(undefined);
                        }
                        setStep(step + 1);
                    }}
                />
            ),
            stepTitle: __("Выбрать партнёра"),
        },
        {
            component: (
                <ChooseSite
                    partnerId={partnerId}
                    siteId={siteId}
                    chooseSite={newSiteId => {
                        setSiteId(newSiteId);
                        setStep(step + 1);
                    }}
                />
            ),
            stepTitle: __("Выбрать сайт"),
        },
        {
            component: (
                <AddPayment
                    onPaymentAdded={payment => {
                        history.push(`view/${payment.id}`);
                    }}
                    initialValues={initialValues}
                />
            ),
            stepTitle: __("Добавить платежные данные"),
        },
    ];

    const canGoNext = step !== 0 || partnerId !== undefined;

    return (
        <>
            <Steps
                titleText={__("Добавьте рекламную выплату в 3 шага:")}
                stepTitles={stepData.map(item => item.stepTitle)}
                currentStep={step}
            />
            {stepData[step].component}
            <BackNextButtons
                step={step}
                setStep={setStep}
                numberOfSteps={stepData.length}
                canGoNext={canGoNext}
                canGoBack={true}
            />
        </>
    );
};
