import { ContentLoader } from "components/components-common/content-loader";
import { PartnerAccount } from "gql/types/operation-result-types";
import { AdminWrapperEdit } from "styled/layouts";
import { EditForm } from "./edit-account-form";
import { List } from "react-content-loader";

interface IProps {
    data: PartnerAccount | undefined;
    loading: boolean;
}

export const EditAccount = (props: IProps) => {
    return (
        <AdminWrapperEdit>
            <ContentLoader component={List} loading={props.loading}>
                <EditForm partnerAccountData={props.data} />
            </ContentLoader>
        </AdminWrapperEdit>
    );
};
