import { useState, useContext, useEffect } from "react";
import { errorHandler } from "services/error-handler";
import { ReactTable } from "components/react-table";
import { CellWithButtons } from "components/table-item";
import { IReactTableColumn } from "components/react-table/column";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { AdminWrapper } from "styled/layouts";
import { usePartnerSites } from "../hooks/partner-sites";
import { EditForm } from "./edit-form";
import { EditButtons } from "./edit-buttons";
import { useChangePartnerSiteVisibility } from "../hooks/change-partner-site-visibility";
import { GetPartnerSites_authorized_manager_main_getPartnerSites_data } from "gql/types/operation-result-types";

export type EditData = {
    id: number;
    name: string;
} | null;

interface ITable {
    isVisible?: boolean;
    partnerId: number;
}

export const Table = ({ partnerId, isVisible }: ITable) => {
    const [editData, setEditData] = useState<EditData>(null);
    const { setColumnData } = useContext(PickCurrentLineContext);
    const externalPaginationAndSorting = usePaginationAndSorting();

    useEffect(() => {
        return () =>
            setColumnData({
                name: "id",
                id: null,
                tableName: "partnerSites",
            });
    }, [setColumnData]);

    const getSitesVariables = {
        isVisible: !!isVisible,
        partnerId,
        ...externalPaginationAndSorting.state,
    };
    const { sitesList, loading } = usePartnerSites(getSitesVariables);

    const { changePartnerSiteVisibility, loading: siteVisibilityloading } =
        useChangePartnerSiteVisibility(getSitesVariables);

    const handleEdit = (
        original: GetPartnerSites_authorized_manager_main_getPartnerSites_data,
    ) => {
        setEditData({ id: original.id, name: original.name });
        setColumnData({
            name: "id",
            id: null,
            tableName: "partnerSites",
        });
    };

    const handleViewChange = async (
        original: GetPartnerSites_authorized_manager_main_getPartnerSites_data,
    ) => {
        try {
            await changePartnerSiteVisibility({
                variables: { id: original.id, isVisible: !isVisible },
            });
        } catch (e: any) {
            void errorHandler(e);
        }
    };

    const columns: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "id",
            minWidth: 15,
            Cell: ({
                original,
            }: {
                original: GetPartnerSites_authorized_manager_main_getPartnerSites_data;
            }) => {
                const buttons = (
                    <EditButtons
                        isVisible={isVisible}
                        handleViewChange={() => handleViewChange(original)}
                        handleEdit={() => handleEdit(original)}
                        loading={siteVisibilityloading}
                        disabled={siteVisibilityloading || !!editData}
                    />
                );
                return (
                    <CellWithButtons
                        button={buttons}
                        link={false}
                        text={original.id.toString()}
                    />
                );
            },
        },
        {
            Header: "SiteUrl",
            accessor: "name",
            minWidth: 80,
        },
    ];
    return (
        <AdminWrapper>
            {editData && (
                <EditForm
                    editData={editData}
                    setEditData={setEditData}
                    getSitesVariables={getSitesVariables}
                />
            )}
            <ReactTable
                tableName={"partnerSites"}
                externalPaginationAndSorting={externalPaginationAndSorting}
                dataLength={sitesList?.totalCount}
                columns={columns}
                data={sitesList?.data}
                loading={loading}
            />
        </AdminWrapper>
    );
};
