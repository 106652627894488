import { useTranslation } from "react-i18next";
import { Paragraph } from "services/styled-components/typogoraphy";
import { BtnColor, ButtonColored } from "styled/button";
import { Modal } from "components/components-common/modal";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { useModalState } from "hooks/use-modal-state";

interface IProps {
    modalProps: ReturnType<typeof useModalState>["modalProps"];
    close: () => void;
    onClickRegenerate: () => Promise<void>;
    loading: boolean;
}

export const RegenerateInvoiceModal = ({
    modalProps,
    close,
    onClickRegenerate,
    loading,
}: IProps) => {
    const [__] = useTranslation();

    const handleSubmit = async () => {
        await onClickRegenerate();
        close();
    };

    return (
        <Modal {...modalProps}>
            <Paragraph>
                {__("Вы действительно хотите заменить инвойс?")}
            </Paragraph>
            <FormDirectionRow>
                <FormGroupButton>
                    <ButtonColored onClick={handleSubmit} disabled={!!loading}>
                        OK
                    </ButtonColored>
                </FormGroupButton>
                <FormGroupButton>
                    <ButtonColored
                        btnColor={BtnColor.Faded}
                        onClick={close}
                        disabled={!!loading}
                    >
                        {__("Отмена")}
                    </ButtonColored>
                </FormGroupButton>
            </FormDirectionRow>
        </Modal>
    );
};
