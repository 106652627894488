import styled from "styled-components";

export const ErrorText = styled.span`
    display: block;
    font-size: 1.5rem;
    line-height: 2.48rem;
    margin: 1.25rem 0;
    font-weight: 700;
    text-align: center;
`;
