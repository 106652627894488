import { User_user } from "gql/types/operation-result-types";
import { roles as RolesNames } from "./roles-enum";

interface IUserContext {
    user: User_user;
}

export function auth(context: IUserContext) {
    return Boolean(context.user);
}

/** Что бы не искать роль под номером 12 / 24 /7
 *  решил сделать конкретно для агента, партнера и менеджера гард
 */
export function adAgent({ user }: IUserContext) {
    if (user && user.roles) {
        return user.roles.includes(RolesNames.adAgent);
    }
    return false;
}

export function partner({ user }: IUserContext) {
    if (user && user.roles) {
        return (
            user.roles.includes(RolesNames.partner) &&
            user !== undefined &&
            user.affiliateId !== ""
        );
    }
    return false;
}

export function manager({ user }: IUserContext) {
    if (user && user.roles) {
        return user.roles.includes(RolesNames.manager);
    }
    return false;
}

/** Проверяет, что одна из ролей проходит, использовать, если нужен доступ для одной из нескольких ролей */
export function anyRole(roles: Array<(context: IUserContext) => boolean>) {
    return (context: IUserContext) => {
        return roles.some(role => role(context));
    };
}

/** Если у пользователя есть роль, исключит доступ для нее */
export function exceptRoles(roles: Array<(context: IUserContext) => boolean>) {
    return (context: IUserContext) => {
        return roles.every(role => !role(context));
    };
}
