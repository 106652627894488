import styled from "styled-components";
import { AdminWrapperContainer } from "styled/layouts";
import { media } from "services/styled-components/variables";

export const ProfileSC = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    textarea {
        resize: none;
    }
`;

export const ProfileSide = styled(AdminWrapperContainer)`
    width: 50%;

    :first-child {
        flex-direction: column;
    }

    ${media.mdMax`
        width: calc(100% + 1rem);
    `}
`;

export const FormRow = styled.div`
    display: flex;
    gap: 14px;

    :not(:last-child) {
        margin-bottom: 14px;
    }

    ${media.smMax`
        flex-wrap: wrap;
    `}
`;
