import { Field } from "components/formik";
import { PostbackLists_authorized_partner_postback_postbackLists_types } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { usePostbackLists } from "../../hooks/use-postback-lists";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    name: string;
    setFieldValue: any;
    selectFirst?: boolean;
    isClearable?: boolean;
    id: number | null;
}

export const PostbackTypeField = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { data, loading } = usePostbackLists(props.id);
    return (
        <Field
            name={props.name}
            label={__("Тип postback")}
            type="react-select"
            options={(data?.types || []).map(
                type =>
                    ({
                        value: type.id,
                        label: type.name,
                    } as any),
            )}
            fieldOnChange={(e: any) =>
                props.setFieldValue(
                    "variableNameInPartnerSystem",
                    getVariableNameInPartnerSystem(data?.types, e.target.value),
                )
            }
            isLoading={loading}
            isDisabled={loading}
            selectFirst={props.selectFirst}
            isClearable={props.isClearable}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

const getVariableNameInPartnerSystem = (
    postbackType:
        | PostbackLists_authorized_partner_postback_postbackLists_types[]
        | undefined,
    value: number,
): string => {
    const postbackName = postbackType?.find(type => type.id === value)?.name;
    if (!postbackName) {
        return "";
    }
    const path = /\{(.*?)\}/;
    const matchPath = path.exec(postbackName);
    if (matchPath) {
        return matchPath[1];
    }
    return "";
};
