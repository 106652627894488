import { Field } from "components/formik";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form } from "components/formik";
import { FormDirectionRow, FormGroup } from "styled/layouts";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";
import { FormGroupButton } from "styled/layouts";
import { useTranslation } from "react-i18next";
import { CurrencyAdversitingPaymentsField } from "components/filters/fields/currency-adversiting-payments";
import { usePaymentSystems } from "hooks/query/use-payment-systems";
import UPDATE_ADVERTISING_PAYMENTS_FROM_FILE from "./update-payments.gql";
import ADVERTISING_PAYMENTS_FROM_FILE from "../query.gql";
import { useMutation } from "@apollo/client";
import {
    UpdateAdvertisingPaymentFromFile,
    UpdateAdvertisingPaymentFromFileVariables,
    AdvertisingPaymentsFromFile_authorized_manager_advertising_advertisingPayments_advertisingPaymentsFromFile,
} from "gql/types/operation-result-types";
import { useInitialValues } from "./hooks/use-initial-values";
import { useValidationSchema } from "./hooks/use-validation-schema";
import { errorHandler } from "services/error-handler";
import { toast } from "react-toastify";

// eslint-disable-next-line max-len
export type AdvertisingPaymentsFromFile =
    AdvertisingPaymentsFromFile_authorized_manager_advertising_advertisingPayments_advertisingPaymentsFromFile;

interface IProps {
    editingPayment: AdvertisingPaymentsFromFile;
    fileId: number;
}

interface SelectOption {
    value: number | null;
    label: string;
}

export const PaymentsFromFileForm = ({ fileId, editingPayment }: IProps) => {
    const [__] = useTranslation();
    const [mutation] = useMutation<
        UpdateAdvertisingPaymentFromFile,
        UpdateAdvertisingPaymentFromFileVariables
    >(UPDATE_ADVERTISING_PAYMENTS_FROM_FILE);

    const { paymentSystems, loading } = usePaymentSystems();
    const foundedPaymentSystem = (paymentSystems || []).find(paymentSystem => {
        return paymentSystem.code === editingPayment.paymentMethodName;
    });
    const valuesAndLabels = paymentSystems?.reduce<SelectOption[]>(
        (acc, paymentSystem) => {
            acc.push({
                value: paymentSystem.id,
                label: paymentSystem.name,
            });
            return acc;
        },
        [
            {
                value: null,
                label: "",
            },
        ],
    );
    const initialValues = useInitialValues(
        editingPayment,
        foundedPaymentSystem,
    );
    const validationSchema = useValidationSchema();

    return (
        <FormikConsoleErrors
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async values => {
                try {
                    const edited = await mutation({
                        variables: {
                            filter: {
                                walletNumber: values.walletNumber,
                                description: values.description,
                                sum: values.sum,
                                currencyId: values.currencyAdversitingPayments
                                    ?.id as number,
                                documentLinks: values.documentLinks,
                                partnerId: values.partnerId,
                                paymentMethodId: values.paymentMethodId,
                                siteId: values.siteId,
                                id: editingPayment.paymentId,
                                fileId,
                            },
                        },
                        refetchQueries: [
                            {
                                query: ADVERTISING_PAYMENTS_FROM_FILE,
                                variables: {
                                    fileId,
                                },
                            },
                        ],
                    });
                    if (edited) {
                        toast.success(
                            format(
                                __(
                                    "Рекламная выплата из файла на строке {fileLine} успешно изменена",
                                ),
                                {
                                    fileLine: editingPayment.fileLine,
                                },
                            ),
                        );
                    } else {
                        toast.error(
                            __("Ошибка при редактировании рекламной выплаты"),
                        );
                    }
                } catch (error) {
                    void errorHandler(error as any);
                }
            }, validationSchema)}
        >
            <Form>
                <FormDirectionRow>
                    <FormGroup>
                        <Field
                            name="walletNumber"
                            type="text"
                            label={__("Кошелек")}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="description"
                            type="text"
                            label={__("Описание")}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field name="sum" type="text" label={__("Сумма")} />
                    </FormGroup>
                    <FormGroup>
                        <CurrencyAdversitingPaymentsField
                            name="currencyAdversitingPayments"
                            label={__("Валюта")}
                            isClearable={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="documentLinks"
                            type="text"
                            label={__("Ссылка на документ")}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="partnerId"
                            type="text"
                            label={__("ID партнера")}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="paymentMethodId"
                            label={__("Платежный метод код")}
                            type="react-select"
                            options={valuesAndLabels}
                            selectFirst
                            isLoading={loading}
                            isSearchable
                            isClearable={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="siteId"
                            type="text"
                            label={__("ID сайта")}
                        />
                    </FormGroup>
                    <FormGroupButton>
                        <FakeLabel />
                        <SubmitButton>{__("Редактировать")}</SubmitButton>
                    </FormGroupButton>
                </FormDirectionRow>
            </Form>
        </FormikConsoleErrors>
    );
};
