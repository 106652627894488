import { PartnerSites_authorized_partnerAndManager_data_sites } from "gql/types/operation-result-types";
import * as _ from "lodash";
import { makeUtcCustomPeriod } from "services/project-utils";
import { AllUniversalOutputValues } from "components/filters/types";

export type SubmitValues = Pick<
    AllUniversalOutputValues,
    "marketingToolId" | "period" | "registrationSource" | "currency"
> & {
    website: PartnerSites_authorized_partnerAndManager_data_sites | undefined;
};

export const generalQueryParams = (filter: SubmitValues) => {
    const s = filter.period && filter.period.start;
    const e = filter.period && filter.period.end;
    const { start, end } = makeUtcCustomPeriod(s, e);

    return {
        merchant: parseInt(filter.currency.id),
        siteId: filter.website?.id,
        promoId: _.toString(filter.marketingToolId),
        startPeriod: (start && start.toISOString()) || "",
        endPeriod: (end && end.toISOString()) || "",
        registrationSourceId:
            filter.registrationSource && filter.registrationSource.id,
        methood: "get",
    };
};
