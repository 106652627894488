/*
    src/client/hooks/use-modal-state
    src/client/components/simple-sign-in/hooks/use-login.ts
    src/client/hooks/use-2fa-submit.ts
    В эти файлы (в рамках этого коммита) были добавлены временные доп. логи,
    которые должны помочь отследить https://youtrack.plan365.org/agiles/94-602/95-1227?issue=FRP-646
 */

import { browserLoglevelRemote } from "@webx/loglevel-remote/lib/browser-loglevel-remote";
import "url-search-params-polyfill";
browserLoglevelRemote.apply({
    environment: {
        current: __ENVIRONMENT__.current,
        commitHash: __ENVIRONMENT__.commitHash,
        buildNumber: __ENVIRONMENT__.buildNumber,
        project: __ENVIRONMENT__.project,
        monitor: __ENVIRONMENT__.monitor,
    },
    // eslint-disable-next-line no-nested-ternary
    logLevel: __ENVIRONMENT__.production
        ? log.levels.INFO
        : __ENVIRONMENT__.testing
        ? log.levels.DEBUG
        : log.levels.WARN,
});

import moment from "moment";
if ((window as any).initMomentLocale) {
    (window as any).moment = moment;
    (window as any).initMomentLocale();
}

import "react-toastify/dist/ReactToastify.min.css";
import { initI18n } from "components/language-provider";
import { LANGUAGE_LIST } from "services/i18n/language-list";
import { renderApp } from "./render-app";

if (!(window as any).__I18N__) {
    (window as any).__I18N__ = {} as any;
}
void initI18n(LANGUAGE_LIST.getFallback(), {
    lng: (window as any).__I18N__.lng,
    resources: (window as any).__I18N__.store,
});
delete (window as any).__I18N__;
(window as any).initMomentLocale();

declare let module: any;
if (module && module.hot) {
    module.hot.accept(); // убрать, если необходимо, чтобы после обновления кода, страница перезагружалась
}

if (!__ENVIRONMENT__.production) {
    log.debug("Current environment", __ENVIRONMENT__.current);
}

if (
    ["complete", "loaded", "interactive"].includes(document.readyState) &&
    document.body
) {
    renderApp();
} else {
    window.addEventListener("DOMContentLoaded", () => renderApp(), false);
}
