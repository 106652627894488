import { MailOutline } from "@styled-icons/material/MailOutline/MailOutline";
import { Access } from "components/check-roles/access";
import { auth } from "components/check-roles/rules";
import { AuxiliaryBlockButton } from "components/header/styled";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface IProps {
    className?: string;
}

export const AskQuestions = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { pathname } = useLocation();

    return pathname !== "/partner/feedback" ? (
        <Access guards={[auth]}>
            <AuxiliaryBlockButton className={props.className}>
                <LocalizedNavLinkWithOptionalBackground
                    showBackground={true}
                    to="/partner/feedback"
                    exact={true}
                >
                    <MailOutline size={16} /> {__("Задать вопрос")}
                </LocalizedNavLinkWithOptionalBackground>
            </AuxiliaryBlockButton>
        </Access>
    ) : null;
});
