import { SitePartitionType } from "views/site-patrition-wrapper";
import { AgentMenuItems } from "../agent-menu-items";
import { PartnerMenuItems } from "../partner-menu-items";

interface IProps {
    adminRole: SitePartitionType;
}

export const MainMenuContent = React.memo((props: IProps) => {
    switch (props.adminRole) {
        case SitePartitionType.Manager:
            return <AgentMenuItems />;
        case SitePartitionType.Partner:
        default:
            return <PartnerMenuItems />;
    }
});
