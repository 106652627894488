import { GetAllActualDomainAPIResources } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { AdminWrapperEdit, WrapperContainer } from "styled/layouts";
import { APIDescription } from "./api-description";
import GET_ALL_ACTUAL_DOMAIN_API_TOKENS from "./gql/get-all-actual-domain-api-resources.gql";
import { Resource } from "./token";

export const DomainToken = () => {
    const queryResult = useQuery<GetAllActualDomainAPIResources>(
        GET_ALL_ACTUAL_DOMAIN_API_TOKENS,
        { ssr: true },
    );
    const [__] = useTranslation();

    const resources = g(
        queryResult.data,
        "authorized",
        "partner",
        "actualDomainAPIResources",
    );

    if (!resources) {
        return <Title>{__("Загрузка")}</Title>;
    }

    return (
        <WrapperContainer>
            {resources &&
                resources.map(res => (
                    <AdminWrapperEdit key={res.currencyId}>
                        <Resource {...res} />
                    </AdminWrapperEdit>
                ))}
            <AdminWrapperEdit>
                <APIDescription />
            </AdminWrapperEdit>
        </WrapperContainer>
    );
};
