import { useTranslation } from "react-i18next";
import { ReportReactTable } from "components/react-table/report-react-table";
import {
    ManagerTopMarketingTools_authorized_manager_main_mainStatistic_topMarketingTools_rows,
    ShortTimePeriods,
    Status,
} from "gql/types/operation-result-types";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import MANAGER_TOP_MARKETING_TOOLS from "../../gql/manager-top-marketing-tools.gql";
import {
    ManagerTopMarketingTools,
    ManagerTopMarketingToolsVariables,
} from "gql/types/operation-result-types";
import {
    MediaPreviewWithModal,
    useMediaPreviewWidth,
} from "views/partner-manager/media-preview-with-modal";

interface IInjectedProps {
    currency: GetCurrencies_authorized_partnerAndManager_data_currencies | null;
    shortPeriod: ShortTimePeriods;
}

export const TopMarketingToolsTable = ({
    currency,
    shortPeriod,
}: IInjectedProps) => {
    const [__] = useTranslation();
    const queryResult = useQuery<
        ManagerTopMarketingTools,
        ManagerTopMarketingToolsVariables
    >(MANAGER_TOP_MARKETING_TOOLS, {
        variables: {
            currencyId: parseInt(currency?.id || "0"),
            period: shortPeriod,
        },
        ssr: true,
        skip: !currency,
    });
    const currencyName = currency?.name || "";
    const previewWidth = useMediaPreviewWidth();
    return (
        <ReportReactTable
            showPagination={false}
            reportName="TopPromo"
            columns={[
                {
                    Header: __("ID инструмента"),
                    minWidth: 100,
                    accessor: "toolId",
                    disabled: true,
                },
                {
                    Header: __("Превью"),
                    accessor: "preview",
                    Cell: cellInfo => {
                        const original =
                            // eslint-disable-next-line max-len
                            cellInfo.original as ManagerTopMarketingTools_authorized_manager_main_mainStatistic_topMarketingTools_rows;
                        if (original.status === Status.INACTIVE) {
                            return __("Не активный");
                        }
                        if (original.marketingToolsType.id === "12") {
                            return original.marketingToolsType.name;
                        }
                        return (
                            <MediaPreviewWithModal
                                previewInsideTable={true}
                                media={{
                                    mediaType: original.marketingToolsType,
                                    preview: original.preview,
                                }}
                                previewWidth="100%"
                            />
                        );
                    },
                    minWidth: previewWidth,
                },
                {
                    Header: __("Показы"),
                    minWidth: 90,
                    accessor: "countOfViews",
                    disabled: true,
                },
                {
                    Header: __("Клики"),
                    minWidth: 90,
                    accessor: "countOfClicks",
                },
                {
                    Header: __("Прямые ссылки"),
                    accessor: "countOfDirectLinks",
                    minWidth: 90,
                },
                {
                    Header: "CTR",
                    accessor: "CTR",
                    dataType: "percent",
                    minWidth: 80,
                },
                {
                    Header: __("Регистрации"),
                    accessor: "countOfRegistrations",
                    minWidth: 90,
                },
                {
                    Header: __("Доход компании (общий)"),
                    accessor: "profit",
                    minWidth: 90,
                    dataType: "currency",
                    currency: currencyName,
                },
            ]}
            queryResult={queryResult}
            data={
                queryResult.data?.authorized.manager.main.mainStatistic
                    .topMarketingTools.rows
            }
            reportStatus={
                queryResult.data?.authorized.manager.main.mainStatistic
                    .topMarketingTools.status
            }
            loadingText={__(
                "Отчёт формируется... Это может занять 15 минут и более",
            )}
        />
    );
};
