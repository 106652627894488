import { ShortTimePeriods } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { useStatisticColumns } from "views/partner-manager/statistic-table/statistic-table-colums";
import { ReportReactTable } from "components/react-table/report-react-table";
import MANAGER_QUICK_STATISTIC from "../gql/manager-quick-statistic.gql";
import {
    ManagerQuickStatistic,
    ManagerQuickStatisticVariables,
} from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";

interface IInjectedProps {
    shortPeriod: ShortTimePeriods;
}

export const QuickStatisticTable = ({ shortPeriod }: IInjectedProps) => {
    const [__] = useTranslation();
    const columns = useStatisticColumns();
    const queryResult = useQuery<
        ManagerQuickStatistic,
        ManagerQuickStatisticVariables
    >(MANAGER_QUICK_STATISTIC, {
        variables: {
            period: shortPeriod,
        },
        ssr: true,
    });

    return (
        <ReportReactTable
            showPagination={false}
            reportName="Statistic"
            columns={columns}
            queryResult={queryResult}
            data={
                queryResult.data?.authorized.manager.main.mainStatistic.quick
                    .rows
            }
            reportStatus={
                queryResult.data?.authorized.manager.main.mainStatistic.quick
                    .status
            }
            loadingText={__(
                "Отчёт формируется... Это может занять 15 минут и более",
            )}
        />
    );
};
