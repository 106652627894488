import { Field, Form } from "components/formik";
import { useOnClickOutside } from "hooks/use-on-click-outside";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownSelector, SelectorInfo } from "./styled";
import { IReactTableColumnWithId } from "../column";
import { useHiddenColumnsStorge } from "./hooks/use-hidden-columns-storge";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    // потому-что, id будет точно определен к этому моменту, он не будет undefined
    columns: IReactTableColumnWithId[];
    uniqueId: string;
    shownColumnsByDefault?: string[];
    loading?: boolean;
    onFilteredColumnsChange?: (
        filteredColumns: IReactTableColumnWithId[],
    ) => void;
}

export const FilterColumns = React.memo((props: IProps) => {
    // eslint-disable-next-line prefer-const
    let { shownColumnsIds, saveShownColumnsIds } = useHiddenColumnsStorge(
        props.uniqueId,
    );
    // если пользователь еще не сохранил свой выбор,
    // то мы отображаем дефолтные колонки, или,
    // если они не установленны - то все (имеет смысл
    // не отчетных таблицах, которые используют тоже этот компонент,
    // хотя по идее не должны)
    if (shownColumnsIds.length === 0) {
        shownColumnsIds =
            props.shownColumnsByDefault || props.columns.map(clm => clm.id);
    }
    // disabled поля всегда включены, и пользователь не может это менять.
    // в v1 их нет, в v2 они вероятно появились,
    // чтобы не возникло пустой таблицы
    props.columns
        .filter(clm => clm.disabled)
        .forEach(clm => {
            if (!shownColumnsIds.includes(clm.id)) {
                shownColumnsIds.push(clm.id);
            }
        });

    const [__] = useTranslation();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const refSelectorInfo = useRef(null);
    useOnClickOutside(refSelectorInfo, () => setMenuOpen(false));

    const initialState = React.useMemo(() => {
        return props.columns.reduce(
            (memo, column) => {
                const key = column.id;
                if (_.has(memo, key)) {
                    log.warn("React table columns has duplicate key: ", key);
                }
                memo[key] =
                    _.includes(shownColumnsIds, key) === true ? true : false;

                return memo;
            },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            { __all: false } as { [key: string]: boolean },
        );
    }, [shownColumnsIds, props.columns]);
    const [formikValues, setFromikValues] = useState<any>();
    const { onFilteredColumnsChange, columns } = props;
    useEffect(() => {
        if (onFilteredColumnsChange && formikValues) {
            onFilteredColumnsChange(
                _.filter(
                    columns,
                    column =>
                        formikValues.selectedColumns[column.id] !== undefined &&
                        _.includes(shownColumnsIds, column.id),
                ),
            );
        }
        // props.getVisibility,
    }, [onFilteredColumnsChange, formikValues, columns, shownColumnsIds]);

    return (
        <FormikConsoleErrors
            initialValues={{
                selectedColumns: initialState,
            }}
            // eslint-disable-next-line @typescript-eslint/unbound-method
            onSubmit={_.noop}
        >
            {({ values, setFieldValue }) => {
                values.selectedColumns = _.omit(
                    values.selectedColumns,
                    "__all",
                );
                const checkedAll = _.every(_.values(values.selectedColumns));

                const selectedValuesAmount = _.keys(
                    _.filter(values.selectedColumns, v => v),
                ).length;

                setFromikValues(values);

                return (
                    <DropdownSelector ref={refSelectorInfo} opened={isMenuOpen}>
                        <Form>
                            <SelectorInfo
                                onClick={() => setMenuOpen(!isMenuOpen)}
                                opened={isMenuOpen}
                            >
                                {format(__("Выбрано {columns} п."), {
                                    columns: selectedValuesAmount,
                                })}
                            </SelectorInfo>
                            <ul>
                                <li key="all">
                                    <Field
                                        name={"selectedColumns.__all"}
                                        type="checkbox"
                                        label={__("Выбрать все")}
                                        checked={checkedAll}
                                        onClick={(
                                            event: React.FormEvent<HTMLInputElement>,
                                        ) =>
                                            onClickAllColumns(
                                                event.currentTarget.checked,
                                                props.columns,
                                                setFieldValue,
                                                saveShownColumnsIds,
                                            )
                                        }
                                    />
                                </li>
                                {_.map(props.columns, (column, i) => {
                                    return (
                                        <li key={i}>
                                            <Field
                                                name={`selectedColumns.${column.id}`}
                                                type="checkbox"
                                                label={
                                                    (typeof column.Header ===
                                                        "string" &&
                                                        column.Header) ||
                                                    column.id
                                                }
                                                onClick={(
                                                    event: React.FormEvent<HTMLInputElement>,
                                                ) =>
                                                    onClickedColumn(
                                                        event.currentTarget
                                                            .checked,
                                                        column,
                                                        shownColumnsIds,
                                                        saveShownColumnsIds,
                                                    )
                                                }
                                                disabled={
                                                    column.disabled
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </Form>
                    </DropdownSelector>
                );
            }}
        </FormikConsoleErrors>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

const onClickAllColumns = (
    clicked: boolean,
    columns: IReactTableColumnWithId[],
    setFieldValue: (field: string, value: any) => void,
    saveShownColumnsIds: (ids: string[]) => void,
) => {
    // установить все поля в соотв положение
    // (disabled мы не трогаем, пользователь не может ими управлять)
    columns.forEach(clm => {
        if (!clm.disabled) {
            setFieldValue(`selectedColumns.${clm.id}`, clicked);
        }
    });
    if (clicked) {
        saveShownColumnsIds(columns.map(clm => clm.id));
    } else {
        saveShownColumnsIds([]);
    }
};

const onClickedColumn = (
    clicked: boolean,
    column: IReactTableColumnWithId,
    shownColumnsIds: string[],
    saveShownColumnsIds: (ids: string[]) => void,
) => {
    if (clicked) {
        saveShownColumnsIds([column.id, ...shownColumnsIds]);
    } else {
        saveShownColumnsIds(_.without(shownColumnsIds, column.id));
    }
};
