import { FormikHelpers as FormikActions } from "components/formik";
import {
    CreateFeedback,
    CreateFeedbackVariables,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { MutationFunction } from "@apollo/client";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "../../../../../../server/errors/error-type";

const feedbackMutation = async (
    mutation: MutationFunction<CreateFeedback, CreateFeedbackVariables>,
    setFeedbackResponse: Dispatch<SetStateAction<any>>,
    value: { message: string },
    actions: FormikActions<Record<string, unknown>>,
    __: TFunction,
) => {
    try {
        const fetchResult = await mutation({
            variables: {
                message: value.message,
            },
        });
        if (fetchResult && fetchResult.data) {
            g(fetchResult, "data", "authorized", "partner", "createFeedback");
            setFeedbackResponse({
                error: false,
                message: __("Сообщение отправлено"),
            });
            actions.resetForm();
            // actions.setSubmitting(false);
        }
    } catch (e: any) {
        void errorHandler(e, error => {
            switch (error.data.code) {
                case ErrorType.FEEDBACK_MESSAGE_NO_SEND:
                case ErrorType.FEEDBACK_WRONG_AGENT:
                default:
                    return setFeedbackResponse({
                        error: true,
                        message: __("Сообщение не отправлено"),
                    });
            }
        });
    }
};

export const createFeedbackMutation = () => feedbackMutation;
