import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import {
    PaymentFiles,
    PaymentFilesVariables,
    Status,
} from "gql/types/operation-result-types";
import { ReactTable } from "components/react-table";
import { CellWithButtons } from "components/table-item";
import { QueryResult } from "@apollo/client";
import { ITablePagination } from "hooks/use-pagination";
import { useFormikContext } from "formik";
import { useState } from "react";
import { Checkbox } from "components/formik/universal-field/fields/checkbox";

interface IProps {
    queryResult: QueryResult<PaymentFiles, PaymentFilesVariables>;
    externalPaginationAndSorting: ITablePagination;
}

export const PaymentFilesTable = ({
    queryResult,
    externalPaginationAndSorting,
}: IProps) => {
    const [__] = useTranslation();
    const { setFieldValue } = useFormikContext();
    const [checkedId, setChechedId] = useState("");

    const paymentFiles = queryResult.data?.authorized.manager.main.paymentFiles;

    const handleChange = (id: string, fileName = "") => {
        setChechedId(prev => {
            const newId = prev !== id ? id : "";
            setFieldValue("checkedFileId", newId);
            setFieldValue("checkedFileName", fileName);
            return newId;
        });
    };

    const columns: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "fileId",
            minWidth: 80,
            Cell: ({ original }) => {
                return (
                    <Checkbox
                        id={original.fileId}
                        label={original.fileId}
                        name="checked-file-id"
                        type="checkbox"
                        checked={checkedId === original.fileId}
                        onChange={() =>
                            handleChange(original.fileId, original.fileName)
                        }
                    />
                );
            },
        },
        {
            Header: __("Имя файла"),
            accessor: "fileName",
            minWidth: 80,
        },
        {
            Header: __("Ссылка файла"),
            accessor: "fileLink",
            minWidth: 80,
            Cell: ({ value }) =>
                value ? <CellWithButtons link={false} text={value} /> : "",
        },
        {
            Header: __("Статус"),
            accessor: "status",
            minWidth: 80,
            Cell: ({ original }) => {
                return original.status === Status.ACTIVE
                    ? __("Активный")
                    : __("Не активный");
            },
        },
        {
            Header: __("Кем обновлено"),
            accessor: "updatedBy",
            minWidth: 80,
        },
        {
            Header: __("Дата обновления"),
            accessor: "updatedDate",
            minWidth: 80,
            dataType: "date",
        },
    ];

    const defaultSorted = [
        {
            id: "id",
            desc: true,
        },
    ];

    return (
        <>
            <ReactTable
                defaultSorted={defaultSorted}
                externalPaginationAndSorting={externalPaginationAndSorting}
                columns={columns}
                data={paymentFiles?.files}
                dataLength={paymentFiles?.rowsCount}
                loading={queryResult?.loading}
            />
        </>
    );
};
