import { UniversalForm } from "components/filters";
import { useTranslation } from "react-i18next";
import { ReportTableWithQuery } from "views/partner-manager/report-table-with-query";
import { SubPartnerReportEmptyTable } from "./empty-table";
import { generalQueryParams } from "./general-query-params";
import GET_SUB_PARTNERS_REPORT from "./query.gql";
import { getDatesByPeriod } from "components/filters/services/get-dates-by-period";
import { Period, Periods } from "components/filters/fields/period";
import {
    IBaseFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";
import { Field } from "components/formik";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const SubPartnerReport = React.memo(() => {
    const [__] = useTranslation();

    return (
        <UniversalForm
            fields={["currency", "period"]}
            submitButtonName={__("Сгенерировать отчет")}
            additionalFields={{
                period: {
                    initialValue: {
                        interval: Periods.CUSTOM,
                        ...getDatesByPeriod(Periods.TODAY),
                    },
                    component: (periodProps: IBaseFieldProps) => (
                        <Period {...selectFieldsProps(periodProps)} />
                    ),
                },
                registrationDate: {
                    initialValue: null,
                    component: (dateProps: IBaseFieldProps) => (
                        <Field
                            {...selectFieldsProps(dateProps)}
                            type="date-single-picker"
                            label={__("Дата регистрации суб-партнера")}
                        />
                    ),
                },
            }}
        >
            {({ submittedValues, form, actualValues }) => {
                return (
                    <ReportTableWithQuery
                        actualValues={actualValues}
                        submittedValues={submittedValues}
                        form={form}
                        QUERY={GET_SUB_PARTNERS_REPORT}
                        emptyTable={SubPartnerReportEmptyTable}
                        transfromToQueryParams={generalQueryParams}
                    />
                );
            }}
        </UniversalForm>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
