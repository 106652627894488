import { CurrencyCellSC } from "./styled";
import * as _ from "lodash";
import { formatCurrency } from "services/project-utils";

export const CurrencyCell = ({
    value,
    currency,
}: {
    value: any;
    currency: any;
}) => (
    <CurrencyCellSC value={_.toNumber(value)}>
        {value === null || value === undefined
            ? ""
            : `${formatCurrency(value)} ${getCurrencySymbol(currency)}`}
    </CurrencyCellSC>
);

const getCurrencySymbol = (currency: string) => {
    switch (currency.toUpperCase()) {
        case "RUB":
            return "₽";
        case "USD":
            return "$";
        case "UAH":
            return "₴";
        default:
            return currency;
    }
};
