import {
    ApolloClient,
    ApolloProvider,
    ApolloLink,
    HttpLink,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import { RestLink } from "apollo-link-rest";
import { RetryLink } from "@apollo/client/link/retry";
import "cross-fetch/polyfill";
import { User } from "gql/types/operation-result-types";
import _, { get } from "lodash";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ReduxStoreCreator } from "services/redux";
import { ViewsRoot } from "views";
import { RequestError } from "../server/errors/request-error";
import * as qs from "query-string";

import GET_USER from "gql/queries/user.gql";
import { ErrorType } from "../server/errors/error-type";
import { toast } from "react-toastify";
import { InvalidCSRFToken } from "services/long-running-issues/invalid-csrf-token";
import { createApolloMemoryCache } from "client-server/apollo-memory-cache";
import { loadableReady } from "@loadable/component";

declare let module: any;

export const renderApp = () => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map((graphQLError: any) => {
                const { name, message, data, locations, path } = graphQLError;
                if (RequestError.isInstance({ name })) {
                    if (data.code < 1000) {
                        // Единственная ошибка сообщение о которой будет показываться здесь.
                        // Потому, что никто не предполгал, что такое может случится,
                        // но тем не менее.
                        if (data.code === ErrorType.INTERNAL_SERVER_ERROR) {
                            toast.error(message, { autoClose: 15000 });
                        }
                        log.error(
                            "Request error",
                            // TODO: передавать больше инфы из graphQLError,
                            // а не создавать новую тут (может брать original, там вроде есть)
                            // т.к. при создании new новой ошибки тут весть стек берется отсюда и ничего нельзя понять
                            new RequestError(data.code, message, data.details),
                            {
                                data,
                                locations,
                                path,
                            },
                        );
                    } else if (data.code >= 4000) {
                        location.reload();
                    }
                } else {
                    if (
                        get(graphQLError, "extensions.code") ===
                        "INTERNAL_SERVER_ERROR"
                    ) {
                        // В этом случае что-то совсем пошло не так.
                        // И мы определённо не хотим показывать сообщение о том, что именно именно.
                        // i18n на клиенте доступен только через хук.
                        // TODO: Поэтому нужно засунуть функционал из класса LanguageRequestContextHelper в либу
                        // чтобы можно была изспользовать функцию перевода здесь.
                        // Пока, временно, будем показывать сообщение на английском.
                        toast.error("Something went wrong!", {
                            autoClose: 15000,
                        });
                    }
                    log.error("GraphQL error", {
                        message,
                        locations,
                        path,
                    });
                }
            });
        }
        if (networkError) {
            log.error("Network error", networkError);
        }
    });

    const preloadedState: any = ReduxStoreCreator.getPreloadedState();

    const graphqlBatching = _.get(preloadedState, "settings.graphqlBatching");

    const httpLink = () =>
        new HttpLink({
            fetch: (uri, options) => {
                if (!options) {
                    options = {};
                }
                (options as any).credentials = "same-origin";
                const { body, ...newOptions } = options as any;
                // turn the object into a query string, try `object-to-querystring` package
                const preparedBody = JSON.parse(body);
                if (
                    !_.startsWith(preparedBody.query, "query") ||
                    _.includes(preparedBody.query, "user {")
                ) {
                    (options as any).method = "POST";
                    if (_.includes(preparedBody.query, "authorized")) {
                        const userQueryData: User | null = client.readQuery({
                            query: GET_USER,
                            variables: { updateTokenSwitcher: 0 },
                        });
                        if (!options.headers) {
                            options.headers = {};
                        }
                        void InvalidCSRFToken.logCSRFTokenSecurly(
                            "Setting csrf token at httpLink before calling the server",
                            userQueryData?.user?.csrf,
                        );
                        (options as any).headers["x-csrf-token"] =
                            userQueryData &&
                            userQueryData.user &&
                            userQueryData.user.csrf;
                    }
                    return fetch(uri, options);
                }
                _.forEach(preparedBody, (value, key) => {
                    if (_.isObject(value)) {
                        preparedBody[key] = JSON.stringify(value);
                    }
                });
                const queryString = qs.stringify(preparedBody);
                const requestedString = uri + "?" + queryString;
                newOptions.method = "GET";
                return fetch(requestedString, newOptions);
            },
        });

    const batchLink = () => {
        return new BatchHttpLink({
            fetch: async (url: string, options: RequestInit) => {
                options.credentials = "same-origin";
                if (
                    _.isString(options.body) &&
                    _.includes(options.body, "authorized")
                ) {
                    const userQueryData = await client.query<User>({
                        query: GET_USER,
                        fetchPolicy: "cache-only",
                        variables: { updateTokenSwitcher: 0 },
                    });
                    const user = userQueryData.data.user;
                    void InvalidCSRFToken.logCSRFTokenSecurly(
                        "Setting csrf token at batchLink before calling the server",
                        user?.csrf,
                    );
                    if (user) {
                        if (!options.headers) {
                            options.headers = {};
                        }
                        (options.headers as any)["x-csrf-token"] = user.csrf;
                    }
                }
                return fetch(url, options);
            },
        });
    };

    const retryLink = new RetryLink({
        attempts: {
            max: 3,
        },
    });

    const restLink = new RestLink({
        uri: "/lapi",
    });

    const client = new ApolloClient({
        link: ApolloLink.from([
            retryLink,
            errorLink,
            restLink,
            graphqlBatching ? batchLink() : httpLink(),
        ]),
        cache: createApolloMemoryCache().restore(
            (window as any).__APOLLO_STATE__,
        ) as any,
        connectToDevTools: !__ENVIRONMENT__.production,
    });
    const store = ReduxStoreCreator.createStore(preloadedState, true);

    void loadableReady(() => {
        return ReactDOM[module && module.hot ? "render" : "hydrate"](
            <BrowserRouter>
                <ApolloProvider client={client}>
                    {/* <React.StrictMode> */}
                    <ViewsRoot
                        store={store}
                        projectNameInTitle={location.hostname}
                    />
                    {/* </React.StrictMode> */}
                </ApolloProvider>
            </BrowserRouter>,
            document.getElementById("root"),
        );
    });
};
