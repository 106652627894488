import {
    PlayersReportFilter,
    User_user,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { getCommonTableTranslator } from "../../services/get-commot-table-translator";

interface ITableData {
    user?: User_user;
    values: PlayersReportFilter;
    reportFormat: string;
    culture: string;
}

export const tableData = (
    { user, values, reportFormat, culture }: ITableData,
    __: TFunction,
) => {
    const {
        endPeriod,
        startPeriod,
        currencyId,
        playerId,
        campaign,
        siteId,
        mediaId,
        withoutDepositsOnly,
        onlyNewPlayers,
    } = values;

    if (user) {
        return {
            reportFormat,
            translator: {
                campaign: {
                    header: __("Кампания"),
                    value: (campaign && campaign.name) || __("Отсутствует"),
                },
                onlyNewPlayers: {
                    header: __("Только новые игроки"),
                    value: onlyNewPlayers ? __("Да") : __("Нет"),
                },
                onlyPlayersWithoutDeposits: {
                    header: __("Только игроки без депозитов"),
                    value: withoutDepositsOnly ? __("Да") : __("Нет"),
                },
                playerId: {
                    header: __("ID игрока"),
                    value: playerId || __("Отсутствует"),
                },
                siteId: {
                    header: __("ID сайта"),
                    value: siteId || __("Отсутствует"),
                },
                mediaId: {
                    header: __("ID медиа"),
                    value: mediaId || __("Отсутствует"),
                },
                reportType: __("Отчет по игрокам"),
                ...getCommonTableTranslator(
                    { culture, currencyId, endPeriod, startPeriod, user },
                    __,
                ),
                columns: {
                    column1: __("ID сайта"),
                    column2: __("Сайт"),
                    column3: __("ID медиа"),
                    column4: __("SubId"),
                    column5: __("ClickId"),
                    column6: __("ID игрока"),
                    column7: __("Дата регистрации"),
                    column8: __("Страна"),
                    column9: __("Сумма депозитов"),
                    column10: __("Сумма ставок"),
                    column11: __("Сумма бонусов"),
                    column12: __("Доход компании (общий)"),
                    column13: __("RS"),
                    column14: __("CPA"),
                    column15: __("Сумма комиссий"),
                    column16: __("Hold time"),
                    column17: __("Заблокирован"),
                },
            },
        };
    }
};
