import { Copy } from "@styled-icons/icomoon/Copy/Copy";
import { useCallback } from "react";
import * as ReactCopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BtnColor } from "styled/button";
import { ButtonColoredWithIcon } from "styled/button";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    text: string;
}

export const CopyToClipboard = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const onCopy = useOnCopy();

    return (
        <ReactCopyToClipboard text={props.text || ""} onCopy={onCopy}>
            <ButtonColoredWithIcon
                btnColor={BtnColor.Faded}
                onClick={e => e.preventDefault()}
                title={__("Копировать")}
            >
                <Copy size={15} />
            </ButtonColoredWithIcon>
        </ReactCopyToClipboard>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

const useOnCopy = () => {
    const [__] = useTranslation();
    return useCallback(() => toast.success(__("Скопировано")), [__]);
};
