import { TFunction } from "i18next";
import { uniqueId } from "lodash";
import { getPartnerStatus } from "services/project-utils";
import { IReactTableColumn } from "components/react-table/column";
import { Checkbox } from "components/formik/universal-field/fields/checkbox";

interface GetColumnsParams<T> {
    __: TFunction;
    onChange: (data: T) => void;
    onChangeAll: (data: T[]) => void;
    defaultSelected?: boolean;
    allColumns?: boolean;
}

export const getColumns = <T extends unknown>({
    __,
    onChange,
    onChangeAll,
    defaultSelected,
    allColumns = true,
}: GetColumnsParams<T>): IReactTableColumn[] => {
    const columns: IReactTableColumn[] = [
        {
            accessor: "Control checkbox",
            minWidth: 40,
            show: false,
            sortable: false,
            disabled: true,
            Header: ({ data }) => {
                const id = React.useRef(`row-selection-all-${uniqueId()}`);
                return (
                    <Checkbox
                        id={id.current}
                        type="checkbox"
                        checked={!!(data.length && defaultSelected)}
                        onChange={() => onChangeAll(data)}
                    />
                );
            },
            Cell: ({ original }: { original: T }) => {
                const id = React.useRef(`row-selection-${uniqueId()}`);
                return (
                    <div>
                        <Checkbox
                            id={id.current}
                            type="checkbox"
                            checked={!!defaultSelected}
                            onChange={() => onChange(original)}
                        />
                    </div>
                );
            },
        },
        {
            Header: __("ID партнера"),
            accessor: "id",
            disabled: true,
            minWidth: 80,
        },
        {
            Header: __("Имя пользователя"),
            accessor: "name",
            minWidth: 200,
        },
        {
            Header: __("Email"),
            accessor: "email",
            disabled: true,
            minWidth: 120,
        },
    ];

    if (!allColumns) {
        return columns;
    }

    return columns.concat([
        {
            Header: __("Страна"),
            accessor: "country",
            minWidth: 120,
        },
        {
            Header: __("Сайт"),
            accessor: "site",
            minWidth: 120,
        },
        {
            Header: __("Дата регистрации"),
            accessor: "registrationDate",
            dataType: "date",
            minWidth: 120,
        },
        {
            Header: __("Статус"),
            accessor: "status",
            minWidth: 120,
            Cell: ({ value }) => getPartnerStatus(__, value),
        },
    ]);
};
