import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "components/formik";
import {} from "components/filters/fields/country";
import { AdvertisingCompany } from ".";

interface SelectOption {
    value: number;
    label: string;
}

interface IUseSearchCompany {
    company?: AdvertisingCompany | null;
}

export const useSearchCompany = ({ company }: IUseSearchCompany) => {
    const [__] = useTranslation();

    const ERROR_COMPANY_NOT_FOUND = __("Данное сочетание не найдено");

    const [companiesOptions, setCompaniesOptions] = useState<SelectOption[]>();

    const { setFieldValue, setFieldError } = useFormikContext<{
        company: string;
        search?: string;
    }>();

    const getOptioinsCompanies = useCallback(
        (currentCompaniesList?: AdvertisingCompany[]) => {
            const options = (currentCompaniesList || []).map(
                currentCompany => ({
                    value: currentCompany.id,
                    label: currentCompany.name,
                }),
            );

            const error =
                options?.length === 0 ? ERROR_COMPANY_NOT_FOUND : undefined;
            setFieldError("search", error);

            setCompaniesOptions(options);
        },
        [ERROR_COMPANY_NOT_FOUND, setFieldError],
    );

    useEffect(() => {
        if (company) {
            setFieldValue("company", company.id);
            getOptioinsCompanies([company]);
        }
    }, [setFieldValue, company, getOptioinsCompanies]);

    return { companiesOptions, getOptioinsCompanies };
};
