import { TFunction } from "i18next";
import { getDescriptionDate, IDescriptionFoo } from "../comission-description";
import {
    CalculationType,
    GetCommissionList_authorized_partner_comissionStructures_description_RefDescription,
} from "gql/types/operation-result-types";

export const getRefDescription = (
    structureList: IDescriptionFoo,
    __: TFunction,
) => {
    const currentComission = g(
        structureList,
        "description",
    ) as GetCommissionList_authorized_partner_comissionStructures_description_RefDescription;

    const {
        levels,
        negativeComission,
        dateStart,
        dateEnd,
        calculationMethodId,
    } = currentComission;

    const NegativeComission = format(
        __("Отрицательная комиссия: {comission}"),
        {
            comission: negativeComission ? __("Да") : __("Нет"),
        },
    );

    let strTypeCalculationMethod = "";
    if (calculationMethodId === CalculationType.DEPOSIT_AMOUNT) {
        strTypeCalculationMethod = __("Сумма депозитов");
    } else if (calculationMethodId === CalculationType.BETS_AMOUNT) {
        strTypeCalculationMethod = __("Сумма ставок");
    }

    const TypeCalculationMethod = strTypeCalculationMethod
        ? format(__("Тип расчета MLM: {type}"), {
              type: strTypeCalculationMethod,
          }) + "; "
        : "";

    const ComissionLevels = (levels || [])
        .map(item =>
            item
                ? format(__("Уровень {comissionLevels}"), {
                      comissionLevels: `${item.order}|${item.comissionPercent}%`,
                  })
                : "",
        )
        .join(" ");

    const date = getDescriptionDate(dateStart, dateEnd);

    return `${ComissionLevels}; ${TypeCalculationMethod}${NegativeComission}; ${date}`;
};
