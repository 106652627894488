import { Access } from "components/check-roles/access";
import { auth } from "components/check-roles/rules";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FooterText } from "components/footer/styled";

export const SendManagement = React.memo(() => {
    const [__] = useTranslation();
    const { pathname } = useLocation();
    const [beforeHighlight, highlightedText] = __(
        "Если у вас возникли трудности с работой сервиса, можете сообщить нам через {highlight}форму{highlight}",
    ).split("{highlight}");

    return pathname !== "/partner/sendmail" ? (
        <Access guards={[auth]}>
            <FooterText>
                {beforeHighlight}
                <LocalizedNavLinkWithOptionalBackground
                    to="/partner/sendmail"
                    exact={true}
                >
                    {highlightedText}
                </LocalizedNavLinkWithOptionalBackground>
            </FooterText>
        </Access>
    ) : null;
});
