import { TFunction } from "i18next";
import { LocalizedLink } from "@webx-react/i18n";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { getCurrencyById } from "services/project-utils";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { IReactTableColumn } from "components/react-table/column";
import { CellWithButtons } from "components/table-item";

export const getColumns = (__: TFunction): IReactTableColumn[] => [
    {
        Header: __("ID группы"),
        accessor: "id",
        disabled: true,
        minWidth: 80,
        Cell: ({ value }) => (
            <CellWithButtons
                button={
                    <ButtonColoredWithIcon
                        btnColor={BtnColor.Faded}
                        title={__("Редактировать")}
                        type="button"
                    >
                        <LocalizedLink
                            className="no-indent"
                            to={`/agent/main/partners-groups/edit/${value}`}
                        >
                            <EditOutline size={15} />
                        </LocalizedLink>
                    </ButtonColoredWithIcon>
                }
                link={false}
                text={value}
            />
        ),
    },
    {
        Header: __("Валюта"),
        accessor: "currencyId",
        minWidth: 80,
        Cell: ({ original }) => getCurrencyById(original.currencyId),
    },
    {
        Header: __("Название"),
        accessor: "name",
        disabled: true,
        minWidth: 220,
    },
    {
        Header: __("Участники"),
        accessor: "countOfPartners",
        minWidth: 120,
    },
];
