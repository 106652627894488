import { css } from "styled-components";

const fontsPath = "../assets/fonts/roboto/";

export const RobotoFonts = css`
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Roboto-Light.woff2") format("woff2");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Roboto-regular.woff2") format("woff2");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Roboto-Medium.woff2") format("woff2");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Roboto-Bold.woff2") format("woff2");
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}Robotomedium.woff2") format("woff2");
        font-weight: 500;
        font-style: normal;
    }
`;
