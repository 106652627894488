import styled from "styled-components";
import { ButtonColored } from "../../../../../styled/button";

export const StyledReactTableCell = styled.div`
    ${ButtonColored} {
        margin: 5px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
