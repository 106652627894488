import { useOnClickOutside } from "hooks/use-on-click-outside";
import { Action } from "redux";

interface IProps {
    refToAreaToSurround: React.MutableRefObject<null>;
    onClick: () => Action;
}

export const CloseSurroundingArea = (props: IProps) => {
    useOnClickOutside(props.refToAreaToSurround, () => props.onClick());
    return null;
};
