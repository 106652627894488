import { ReactTable } from "components/react-table";
import { useColumns } from "./use-columns";
import { useQuery } from "@apollo/client";
import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    PartnerPromocodes,
    PartnerPromocodesVariables,
    PartnerSitesList_authorized_manager_main_sites,
} from "gql/types/operation-result-types";
import PARTNER_PROMOCODES from "./query.gql";
import { AddPromocodeForm } from "../add-promocode-form";
import { useContext } from "react";
import { PickCurrentLineContext } from "components/get-active-link-context";

interface AdvertisingPaymentsReportTableProps {
    submittedValues: {
        currency?: GetCurrencies_authorized_partnerAndManager_data_currencies | null;
        site?: PartnerSitesList_authorized_manager_main_sites | null;
        promoCode?: string;
    };
    partnerId: number;
    resetFormPromocodes?: () => void;
}

export const PromocodesReportTable = ({
    submittedValues,
    partnerId,
    resetFormPromocodes,
}: AdvertisingPaymentsReportTableProps) => {
    const columns = useColumns();
    const { setColumnData } = useContext(PickCurrentLineContext);

    const queryResult = useQuery<PartnerPromocodes, PartnerPromocodesVariables>(
        PARTNER_PROMOCODES,
        {
            variables: {
                filter: {
                    partnerId,
                    currencyId: Number(submittedValues.currency?.id),
                    siteId: Number(submittedValues.site?.id),
                    promoCode: submittedValues.promoCode,
                },
            },
            notifyOnNetworkStatusChange: true,
        },
    );

    return (
        <>
            <AddPromocodeForm
                partnerId={partnerId}
                onLoaded={(id?: number) => {
                    void queryResult.refetch({ filter: { partnerId } });
                    setColumnData({
                        name: "id",
                        id,
                        tableName: "partner-promocodes",
                    });
                    if (resetFormPromocodes) {
                        resetFormPromocodes();
                    }
                }}
            />
            <ReactTable
                defaultSorted={[
                    {
                        id: "id",
                        desc: true,
                    },
                ]}
                columns={columns}
                loading={queryResult.loading}
                data={
                    queryResult.data?.authorized.manager.main.partnerPromocodes
                        .promocodes || []
                }
                dataLength={
                    queryResult.data?.authorized.manager.main.partnerPromocodes
                        .rowsCount
                }
                tableName="partner-promocodes"
            />
        </>
    );
};
