import { CountryField } from "components/filters/fields/country";
import { LanguageField } from "components/filters/fields/language";
import { MessengerField } from "components/filters/fields/messenger";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Field, Form } from "components/formik";
import { PartnerAccount } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import { useInitialValues } from "./hooks/use-initial-values";
import { useOnSubmit } from "./hooks/use-on-submit";
import { LabelOneLine } from "../../../styled";
import { RowTwoColumnsParagraph } from "styled/layouts";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import {
    _1XBIT_AFFILATES,
    _1XBIT_PARTNERS,
} from "../../../../../../../../server/partner-programs";
import { FormGroup, FormGroupCheckbox } from "styled/layouts";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { useValidationSchema } from "./hooks/use-validation-schema";
import { MessengerLoginField } from "components/filters/fields/messengerLoginField";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    partnerAccountData?: PartnerAccount;
}

export const EditForm = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    const partnerAccount =
        props.partnerAccountData?.authorized.partnerAccount.mainAccountData;

    const initialValues = useInitialValues(partnerAccount);
    const validationSchema = useValidationSchema();
    const onSubmit = useOnSubmit(validationSchema);

    return (
        <FormikConsoleErrors<typeof initialValues>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            {() => (
                <Form>
                    <Title>{__("Контактная информация")}</Title>
                    <RowTwoColumnsParagraph>
                        <FormGroup>
                            <Field
                                name="firstname"
                                type="text"
                                label={`${__("Имя")} *`}
                                labelComponent={LabelOneLine}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                name="surname"
                                type="text"
                                label={`${__("Фамилия")} *`}
                                labelComponent={LabelOneLine}
                                disabled
                            />
                        </FormGroup>
                    </RowTwoColumnsParagraph>

                    {partnersProgramId !== _1XBIT_AFFILATES &&
                        partnersProgramId !== _1XBIT_PARTNERS && (
                            <>
                                <RowTwoColumnsParagraph formGroupsMaxWidth="99%">
                                    <FormGroup>
                                        <Field
                                            name="phone"
                                            type="text"
                                            label={__("Номер телефона")}
                                            labelComponent={LabelOneLine}
                                            disabled
                                        />
                                    </FormGroup>
                                </RowTwoColumnsParagraph>
                                <RowTwoColumnsParagraph>
                                    <FormGroup>
                                        <MessengerField isDisabled />
                                    </FormGroup>
                                    <FormGroup>
                                        <MessengerLoginField disabled />
                                    </FormGroup>
                                </RowTwoColumnsParagraph>
                            </>
                        )}
                    <Paragraph>
                        {__(
                            "для редактирования контактной информации свяжитесь с Вашим менеджером",
                        )}
                    </Paragraph>
                    <RowTwoColumnsParagraph>
                        <FormGroup>
                            <CountryField
                                name="country"
                                key="country"
                                selectFirst={false}
                                isClearable={false}
                                labelComponent={
                                    LabelOneLine as React.FC<
                                        React.LabelHTMLAttributes<HTMLElement>
                                    >
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <LanguageField
                                name="language"
                                key="language"
                                label={__("Язык для уведомлений")}
                                selectFirst={false}
                                isClearable={false}
                                labelComponent={
                                    LabelOneLine as React.FC<
                                        React.LabelHTMLAttributes<HTMLElement>
                                    >
                                }
                            />
                        </FormGroup>
                    </RowTwoColumnsParagraph>
                    {partnerAccount?.showGetChangeDomainEmails && (
                        <FormGroupCheckbox>
                            <Field
                                name="getChangeDomainEmails"
                                type="checkbox"
                                label={__("Уведомления о смене домена")}
                            />
                        </FormGroupCheckbox>
                    )}
                    <SubmitButton>{__("Сохранить изменения")}</SubmitButton>
                </Form>
            )}
        </FormikConsoleErrors>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
