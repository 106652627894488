import {
    GetCountries_countries,
    GetLanguages_languages,
    GetMessengers_messengers,
    PartnerAccount_authorized_partnerAccount_mainAccountData,
} from "gql/types/operation-result-types";
import { useCountries } from "hooks/query/use-countries";
import { useGetLanguages } from "hooks/query/use-language";
import { useMessengers } from "hooks/query/use-messengers";

const defaultInitialValues = {
    firstname: "",
    surname: "",
    phone: null as null | string,
    messenger: null as null | GetMessengers_messengers,
    messengerLogin: "",
    country: null as GetCountries_countries | null,
    language: null as GetLanguages_languages | null,
    getChangeDomainEmails: null as null | boolean,
};

export type IInitialValues = typeof defaultInitialValues;

export function useInitialValues(
    partnerAccountData:
        | PartnerAccount_authorized_partnerAccount_mainAccountData
        | null
        | undefined,
) {
    const { languages } = useGetLanguages();
    const { countries } = useCountries();
    const { messengers } = useMessengers();

    if (!partnerAccountData) {
        return defaultInitialValues;
    }

    const country =
        countries &&
        countries.find(cntr => cntr.id === partnerAccountData.countryId);
    const language =
        languages &&
        languages.find(lg => lg.id === partnerAccountData.languageId);
    const foundMessenger =
        messengers &&
        messengers.find(ms => ms.id === partnerAccountData.messenger?.id);

    const initialValues: IInitialValues = {
        firstname: partnerAccountData.firstname,
        surname: partnerAccountData.surname,
        phone: partnerAccountData.phone,
        getChangeDomainEmails: partnerAccountData.getChangeDomainEmails,
        country: country ? country : null,
        language: language ? language : null,
        messenger: foundMessenger ? foundMessenger : null,
        messengerLogin: partnerAccountData.messenger
            ? partnerAccountData.messenger.login
            : "",
    };

    return initialValues;
}
