export const mergeSelectorData = (
    mockData: Array<{ value: string; label: string }>,
    originalData: Array<{ value: any; label: string }>,
): any => {
    return originalData
        .map(original => {
            const result = mockData.find(mock => {
                return (
                    Number(mock.value) ===
                    Number(
                        original.value.id ? original.value.id : original.value,
                    )
                );
            });

            if (typeof original.value === "object") {
                const label = result?.label || original.value.name;
                return { ...result, label, value: original.value };
            }

            return result;
        })
        .filter(Boolean);
};
