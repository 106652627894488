import { AdminWrapperEdit, FormGroup } from "styled/layouts";
import { Field } from "components/formik";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { AdversitingAction } from "../../../view-edit-steps";

export const DealType = ({
    adversitingAction,
}: {
    adversitingAction?: AdversitingAction;
}) => {
    const [__] = useTranslation();

    return (
        <AdminWrapperEdit>
            <FormGroup>
                <Field
                    name="dealType"
                    type="text"
                    label={__("Тип сделки")}
                    disabled
                />
            </FormGroup>
            {adversitingAction !== AdversitingAction.View && (
                <SubmitButton>{__("Сохранить")}</SubmitButton>
            )}
        </AdminWrapperEdit>
    );
};
