import { cover } from "polished";
import styled, { FlattenSimpleInterpolation } from "styled-components";
import { LocalizedLink } from "components/language-provider";

export const CookiesToast = styled.div<{ bgColor?: string; radius?: string }>`
    &::before {
        content: "";
        background-color: ${props =>
            props.bgColor || props.theme.CookieNotificationBg};
        ${cover()};
        z-index: 0;
        border-radius: ${props => props.radius || 0};
    }
`;
export const CookiesToastContent = styled.div`
    position: relative;
    z-index: 1;
    color: ${props => props.theme.textColor};
    line-height: 1.43;
`;

export const CookieMoreInfo = styled(LocalizedLink)<{ linkColor?: string }>`
    display: block;
    text-decoration: underline;
    ${props => {
        return props.linkColor ? `color: ${props.linkColor}` : "";
    }}
`;

export const CookieOk = styled.button<{
    btnColor?: string;
    btnCSS?: FlattenSimpleInterpolation;
}>`
    display: block;
    background: ${props => props.btnColor || props.theme.accentColor};
    padding: 12px;
    margin-top: 10px;
    width: 100%;
    border-radius: ${props => props.theme.buttons.default.borderRadius};
    color: ${props => props.theme.buttons.default.color};
    ${props => props.btnCSS || ""}
`;
