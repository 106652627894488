import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";
import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_stylesTheme } from "gql/types/operation-result-types";

import {
    btn,
    btnHover,
    btnTextAndPossiblyIcon,
    Button,
    LinkButton,
} from "./internal-buttons/buttons";

interface IButtonWithGradient {
    buttonGradient?: string;
}

export enum BtnColor {
    Special = 1,
    Extra,
    Faded,
    Gloomy,
}

export interface IProps {
    disabled?: boolean;
    btnColor?: BtnColor;
    theme: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_stylesTheme;
}

export interface IButtonColoredProps extends IProps {
    minWidth?: number;
    width?: string;
    size?: string;
}

// кнопка с надпистью и (возможно) с иконкой
export const ButtonColored = styled(Button)<IButtonColoredProps>`
    ${props => (props.width ? `width: ${props.width};` : "")}
    min-width: ${props => props.minWidth && `${props.minWidth}px`};
    font-size: ${({ size }) => size && size};
    ${btnTextAndPossiblyIcon}
    &:active,
    &:hover {
        ${btnHover}
    }
`;

// кнопка с иконкой (только с иконкой без текста)
export const ButtonColoredWithIcon = styled(Button)`
    /* max-width: 25px; */
    ${btn}
    &:active,
    &:hover {
        ${btnHover}
    }
    padding: 5px;
    margin-left: 2px;
    line-height: 0;
    border: 0;
    & svg {
        margin: 0;
        /* исправляет отображение title на кнопке в MS Edge */
        pointer-events: none;
    }
`;

// TODO: иногда исппользуется в 19 и при изменении стилей кнопки.
// Перейти на использование другого компонента и спрятать его
export const ButtonStyled = styled(Button)<IButtonWithGradient>`
    ${LinkButton};

    ${props =>
        props.buttonGradient &&
        css`
            border-radius: 36px;
            background-image: ${props.buttonGradient};
            border: 0;

            & svg {
                display: none;
            }

            &:hover,
            :active {
                box-shadow: rgba(251, 106, 106, 0.8) 0px 0px 20px;
            }
        `}
`;

// специфический контейнер, для ссылок? не должен быть тут
export const ButtonToolbar = styled.div`
    margin: 15px 0 0;
    button,
    a {
        ${rtl`margin-left: 16px;`};
        &:first-of-type {
            ${rtl`margin-left: 0;`};
        }
    }
`;
