import { ReactTable } from "components/react-table";
import { WrapBlock } from "./wrap-block/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { useComission } from "./hooks/use-comission";
import {
    getComissionDescription,
    IDescriptionFoo,
} from "./services/comission-description";
import { IReactTableColumn } from "components/react-table/column/";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const ComissionStructure = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { comissionList, loading } = useComission();

    const columns: IReactTableColumn[] = [
        {
            Header: __("Валюта"),
            accessor: "currency",
            disabled: true,
            minWidth: 80,
        },
        {
            Header: __("Структура комиссий"),
            accessor: "comissionStructureType",
            minWidth: 80,
        },
        {
            Header: __("Название комиссионной группы"),
            accessor: "comissionGroupName",
            disabled: true,
            minWidth: 140,
        },
        {
            Header: __("Дата начала"),
            accessor: "comissionGroupDateStart",
            dataType: "date",
            minWidth: 80,
        },
        {
            Header: __("Описание"),
            accessor: "description",
            Cell: cellInfo => {
                return (
                    <WrapBlock>
                        {getComissionDescription(
                            cellInfo.original as IDescriptionFoo,
                            __,
                        )}
                    </WrapBlock>
                );
            },
            minWidth: 350,
        },
        {
            Header: __("Дата окончания"),
            accessor: "comissionGroupDateEnd",
            dataType: "date",
            minWidth: 80,
        },
    ];

    return (
        <AdminWrapper>
            <ReactTable
                tableName="web-site"
                columns={columns}
                data={comissionList}
                loading={loading}
            />
        </AdminWrapper>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
