import { AdminWrapper } from "styled/layouts";
import { ReactTable } from "components/react-table";
import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import { AddBonusForm } from "./add-bonus-form";
import { useQuery } from "@apollo/client";
import {
    PartnerBonuses,
    PartnerBonusesVariables,
} from "gql/types/operation-result-types";
import PARTNER_BONUSES from "./query.gql";
import { useContext } from "react";
import { PickCurrentLineContext } from "components/get-active-link-context";

export const Bonuses = ({ partnerId }: { partnerId: number }) => {
    const [__] = useTranslation();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const queryResult = useQuery<PartnerBonuses, PartnerBonusesVariables>(
        PARTNER_BONUSES,
        {
            variables: { partnerId },
        },
    );

    const columns: IReactTableColumn[] = [
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Сумма"),
            accessor: "amount",
            minWidth: 80,
        },
        {
            Header: __("Период"),
            accessor: "period",
            minWidth: 80,
        },
        {
            Header: __("Описание"),
            accessor: "description",
            minWidth: 80,
        },
        {
            Header: __("Начислил"),
            accessor: "whoAdded",
            minWidth: 80,
        },
        {
            Header: __("Дата"),
            accessor: "date",
            minWidth: 80,
            dataType: "dateWithTime",
        },
    ];

    return (
        <AdminWrapper>
            <AddBonusForm
                partnerId={partnerId}
                onLoaded={(id: number) => {
                    void queryResult.refetch({ partnerId });
                    setColumnData({
                        name: "id",
                        id,
                        tableName: "partner-bonuses",
                    });
                }}
            />
            <ReactTable
                defaultSorted={[
                    {
                        id: "date",
                        desc: true,
                    },
                ]}
                columns={columns}
                loading={queryResult.loading}
                data={
                    queryResult.data?.authorized.manager.main.partnerBonuses
                        .bonuses || []
                }
                tableName="partner-bonuses"
            />
        </AdminWrapper>
    );
};
