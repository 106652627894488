import { FeedbackForm } from "components/feedback-form";
import { SendMessageContainer } from "components/feedback-form/styled";
import {
    StyledError,
    SuccessText,
} from "components/formik/universal-field/styled";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { FormikHelpers as FormikActions } from "components/formik";
import { SendMail, SendMailVariables } from "gql/types/operation-result-types";
import { useTranslationList } from "hooks/use-translations-list";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import * as yup from "yup";
import SEND_MAIL from "./gql/send-mail.gql";
import { useSendMailMutation } from "./hooks/use-send-mail-mutation";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const SendManagement = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const {
        FIELD_SHOULD_BE_FILLED,
        UP_TO_MAX_CHARACTERS,
        AT_LEAST_MIN_CHARACTERS,
    } = useTranslationList();

    const [mutation] = useMutation<SendMail, SendMailVariables>(SEND_MAIL);

    const [mailResponse, setMailResponse] = useState({
        error: null,
        message: "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const sendMailMutation = useCallback(useSendMailMutation(), []);
    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                message: yup
                    .string()
                    .required(FIELD_SHOULD_BE_FILLED)
                    .min(20, AT_LEAST_MIN_CHARACTERS)
                    .max(250, UP_TO_MAX_CHARACTERS),
            }),
        [FIELD_SHOULD_BE_FILLED, AT_LEAST_MIN_CHARACTERS, UP_TO_MAX_CHARACTERS],
    );

    return (
        <SendMessageContainer>
            <AdminWrapper>
                <p>
                    {__(
                        "Этот инструмент создан специально для того, чтобы каждый партнер имел возможность напрямую контактировать с руководством компании, и мог получить обратную связь. Здесь вы можете написать о любых проблемах функционирования системы, изложить претензии к работе наших менеджеров и т.д.",
                    )}
                </p>
                <p>
                    {__(
                        "Информация будет направлена непосредственно руководству компании.",
                    )}
                </p>
                <p>
                    {__(
                        "По рабочим вопросам вы можете связаться с менеджером через ",
                    )}
                    <LocalizedNavLinkWithOptionalBackground to="/partner/contacts">
                        {__("контактные данные")}
                    </LocalizedNavLinkWithOptionalBackground>
                </p>
            </AdminWrapper>
            <AdminWrapper>
                <FeedbackForm
                    validationSchema={validationSchema}
                    initialValues={{ message: "" }}
                    onSubmit={(
                        value: { message: string },
                        actions: FormikActions<Record<string, unknown>>,
                    ) =>
                        sendMailMutation(
                            mutation,
                            setMailResponse,
                            value,
                            actions,
                            __,
                        )
                    }
                />

                {mailResponse.error ? (
                    <StyledError>{mailResponse.message}</StyledError>
                ) : (
                    <SuccessText>{mailResponse.message}</SuccessText>
                )}
            </AdminWrapper>
        </SendMessageContainer>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
