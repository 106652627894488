import { GetRegistrationSources } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";
import _ from "lodash";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";
import { Field } from "components/formik";
import { useTranslation } from "react-i18next";

const GET_REGISTRATION_SOURCES = gql`
    query GetRegistrationSources {
        registrationSources {
            id
            name
        }
    }
`;

export const RegistrationSourcesField = (props: ISelectFild) => {
    const { name, ...rest } = props;
    const [__] = useTranslation();
    const { data, loading } = useQuery<GetRegistrationSources>(
        GET_REGISTRATION_SOURCES,
        {
            ssr: true,
        },
    );

    const options = _.map(g(data, "registrationSources"), regSource => ({
        value: regSource as any,
        label: regSource.name,
    }));
    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={name}
            label={__("Источник регистрации")}
            type="react-select"
            options={options}
            isLoading={loading}
            minWidth={200}
            {...rest}
        />
    );
};
