import GET_SITES from "../../../../gql/get-partner-sites.gql";
import { useQuery } from "@apollo/client";
import {
    GetPartnerSites,
    GetPartnerSitesParams,
    GetPartnerSitesVariables,
} from "gql/types/operation-result-types";

export const usePartnerSites = ({
    isVisible,
    partnerId,
    pageNumber,
    countOnPage,
    sorted,
}: GetPartnerSitesParams) => {
    const queryResult = useQuery<GetPartnerSites, GetPartnerSitesVariables>(
        GET_SITES,
        {
            ssr: true,
            variables: {
                query: {
                    isVisible,
                    partnerId,
                    pageNumber,
                    countOnPage,
                    sorted,
                },
            },
        },
    );

    const sitesList = queryResult.data?.authorized.manager.main.getPartnerSites;
    return {
        sitesList,
        loading: queryResult.loading,
        error: queryResult.error,
    };
};
