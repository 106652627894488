import { GetPostback_authorized_manager_main_postback } from "gql/types/operation-result-types";

export const getInitialValues = (
    postbackId: number,
    postback?: GetPostback_authorized_manager_main_postback,
) => {
    return {
        ...postback,
        id: postbackId,
        campaignId: postback?.campaignId?.toString(),
        childGuid: postback?.childGuid?.toString(),
        postbackTypeId: postback?.postbackTypeId?.toString(),
        siteId: postback?.siteId?.toString(),
        statusId: postback?.statusId?.toString(),
        newStatusId: postback?.statusId?.toString(),
        typeRequestId: postback?.typeRequestId?.toString(),
    };
};
