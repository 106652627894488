import { successHandler, SuccessTypes } from "services/success-handler";
import { errorHandler } from "services/error-handler";
import { PostbacksForm } from "../form";
import { usePostback } from "../hooks/use-postback";
import { MutationFunction } from "@apollo/client";
import {
    UpdatePostback as UpdatePostbackType,
    UpdatePostbackVariables,
} from "gql/types/operation-result-types";
import { useUpdate } from "../hooks/use-update";
import { IPostbackOutputValues } from "../services/form-helper";
import { transformFromFormikToRequest } from "../services/form-helper";
import { useTranslation } from "react-i18next";
import { Title } from "components/title";
import { useContext } from "react";
import {
    PickCurrentLineContext,
    SetColumnData,
} from "components/get-active-link-context";
import { useLocalizedAbsolutePush } from "hooks/use-localised-absolute-push";

interface IProps {
    match: {
        params: {
            id: string;
        };
    };
}

export const UpdatePostback = (props: IProps) => {
    const id = parseInt(props.match.params.id);
    const { updatePostback } = useUpdate();
    const postbackData = usePostback(id);
    const [__] = useTranslation();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const push = useLocalizedAbsolutePush();

    if (!postbackData.data) {
        return <Title>{__("Загрузка данных")}</Title>;
    }
    const { __typename, ...initialValues } = {
        ...postbackData.data,
        site: {
            id: postbackData.data.siteId,
        },
        campaign: postbackData.data.campaignId
            ? {
                  id: postbackData.data.campaignId.toString(),
              }
            : null,
    };
    return (
        <PostbacksForm
            id={id}
            initialValues={initialValues}
            onSubmit={handleSubmit(updatePostback, push, id, setColumnData)}
        />
    );
};

const handleSubmit =
    (
        mutation: MutationFunction<UpdatePostbackType, UpdatePostbackVariables>,
        push: ReturnType<typeof useLocalizedAbsolutePush>,
        id: number,
        setColumnData: SetColumnData,
    ) =>
    async (state: IPostbackOutputValues) => {
        try {
            const filter = transformFromFormikToRequest(state);
            await mutation({
                variables: { id, filter },
            });
            setColumnData({ name: "id", id, tableName: "Postbacks" });
            push("/partner/marketing-tools/postbacks");
            successHandler(SuccessTypes.UPDATE_POSTBACK, id);
        } catch (e: any) {
            void errorHandler(e);
            // пробрасываем ошибку для дальнейшей обработки.
            throw e;
        }
    };
