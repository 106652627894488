import { useTranslation } from "react-i18next";
import { AdminWrapper, OnOppositeSides } from "styled/layouts";
import { Title } from "services/styled-components/typogoraphy";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form } from "components/formik";
import { FormDirectionRow, FormGroup } from "styled/layouts";
import { TablePeriodField } from "components/filters/fields/table-period";
import { CurrencyField } from "components/filters/fields/currency";
import { ShortTimePeriods } from "gql/types/operation-result-types";
import { TopMarketingToolsTable } from "./top-marketing-tools-table";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";

export const TopMarketingTools = () => {
    const [__] = useTranslation();

    return (
        <AdminWrapper>
            <FormikConsoleErrors
                initialValues={{
                    shortPeriod: ShortTimePeriods.TODAY as ShortTimePeriods,
                    currency:
                        null as GetCurrencies_authorized_partnerAndManager_data_currencies | null,
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={() => {}}
            >
                {formikProps => (
                    <>
                        <OnOppositeSides>
                            <Title>
                                {__("Топ маркетинговых инструментов")}
                            </Title>
                            <Form>
                                <FormDirectionRow>
                                    <FormGroup>
                                        <TablePeriodField />
                                    </FormGroup>
                                    <FormGroup>
                                        <CurrencyField
                                            name={"currency"}
                                            selectFirst={true}
                                        />
                                    </FormGroup>
                                </FormDirectionRow>
                            </Form>
                        </OnOppositeSides>
                        <TopMarketingToolsTable
                            currency={formikProps.values.currency}
                            shortPeriod={formikProps.values.shortPeriod}
                        />
                    </>
                )}
            </FormikConsoleErrors>
        </AdminWrapper>
    );
};
