import {
    DeletePostback,
    DeletePostbackVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import DELETE_PARTNER_POSTBACK from "../gql/delete.gql";
import POSTBACKS from "../gql/postbacks.gql";

export const useDelete = (id: number) => {
    const [mutation] = useMutation<DeletePostback, DeletePostbackVariables>(
        DELETE_PARTNER_POSTBACK,
        {
            variables: { id },
            refetchQueries: [{ query: POSTBACKS }],
            awaitRefetchQueries: true,
        },
    );

    return {
        mutation,
    };
};
