import { useQuery } from "@apollo/client";
import {
    PartnerCommissionGroupsVariables,
    PartnerCommissionGroups,
} from "gql/types/operation-result-types";
import PARTNER_COMMISSION_GROUPS from "./query.gql";
import { AdminWrapper } from "styled/layouts";
import { ReactTable } from "components/react-table";
import { useColumns } from "./use-column";
import { AddGroupForm } from "./add-group-form";
import { EditGroupForm } from "./edit-group-form";
import { useContext, useEffect, useRef, useState } from "react";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { DeleteComissionGroupModal } from "./delete-group-modal";

export interface SetComissionGroupIdToDeleteProps {
    setComissionGroupIdToDelete: (comissionGroupId: number) => void;
}

export const CommissionGroup = ({ partnerId }: { partnerId: number }) => {
    const [idEditingGroup, setIdEditingGroup] = useState<number>();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const comissionGroupDeleteComponentRef =
        useRef<SetComissionGroupIdToDeleteProps>(null);
    const { data, loading, refetch } = useQuery<
        PartnerCommissionGroups,
        PartnerCommissionGroupsVariables
    >(PARTNER_COMMISSION_GROUPS, {
        variables: { partnerId },
    });
    const commissionGroups =
        data?.authorized.manager.main.partnerCommissionGroups.commissionGroups;
    const columns = useColumns({
        idEditingGroup,
        onClickEdit: id => {
            setIdEditingGroup(id);
            setColumnData({
                name: "commissionGroupId",
                id,
                tableName: "commission-groups-table",
            });
        },
        comissionGroupDeleteComponentRef,
    });
    const currentEditingGroup = (commissionGroups || []).find(
        item => item.commissionGroupId === idEditingGroup,
    );

    useEffect(() => {
        setColumnData({
            name: "commissionGroupId",
            id: idEditingGroup,
            tableName: "commission-groups-table",
        });
    }, [idEditingGroup, setColumnData]);

    return (
        <>
            <AdminWrapper>
                {!currentEditingGroup ? (
                    <AddGroupForm partnerId={partnerId} />
                ) : (
                    <EditGroupForm
                        partnerId={partnerId}
                        currentEditingGroup={currentEditingGroup}
                        setIdEditingGroup={setIdEditingGroup}
                    />
                )}
                <ReactTable
                    columns={columns}
                    loading={loading}
                    data={commissionGroups || []}
                    tableName="commission-groups-table"
                />
            </AdminWrapper>
            <DeleteComissionGroupModal
                ref={comissionGroupDeleteComponentRef}
                updateComissionGroups={refetch}
            />
        </>
    );
};
