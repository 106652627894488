class DomUtilsClass {
    public animate = (
        elem: any,
        style: string,
        unit: string,
        from: number,
        to: number,
        time: number,
        prop: boolean,
    ) => {
        if (!elem) {
            return;
        }

        const maxScrollTop =
            document.documentElement.scrollHeight - window.innerHeight;
        to = Math.min(to, maxScrollTop);

        const start = new Date().getTime();
        const timer = setInterval(() => {
            const step = Math.min(1, (new Date().getTime() - start) / time);
            const value = from + step * (to - from) + unit;
            if (prop) {
                elem[style] = value;
            } else {
                elem.style[style] = value;
            }
            if (step === 1) {
                clearInterval(timer);
            }
        }, 25);

        if (prop) {
            elem[style] = from + unit;
        } else {
            elem.style[style] = from + unit;
        }
    };
}

export const DomUtils = new DomUtilsClass();
