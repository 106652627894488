import {
    inputColor,
    backgroundColor0,
} from "services/styled-components/themes";
import { internalPages } from "services/styled-components/typogoraphy";
import styled, { css } from "styled-components";
import { RelativeBlock } from "../../../views/partner-manager/media-preview-with-modal/clicks-shield-with-cursor/styled";
import { ModalSize } from "./modal-size";
import { ModalStyle } from "./modal-style";
import { rtl } from "services/styled-components/rtl";

export const StyledRootWrapper = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    ${rtl`left: 0;`};
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    color: ${props => props.theme.bodyColor};
    outline: 0 !important;
    backface-visibility: hidden;
    &:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    background-color: rgba(11, 11, 11, 0.75);
    opacity: 0;
    transition: all 0.5s;
`;

export const StyledWrapper = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    top: 0;
    ${rtl`left: 0;`};
    width: 100%;
    min-height: 100%;
    z-index: 1045;
    outline: 0;
    overflow: auto;
    white-space: normal;
    box-sizing: border-box;
    text-align: center;
    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 0;
    }
`;

export const StyledClose = styled.div`
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #888888;
    border-radius: 50%;
    top: 5px;
    ${rtl`right: 5px;`};
    z-index: 2 !important;
    cursor: pointer;
    user-select: none;
    &:hover {
        background: #eeeeee;
    }
`;

interface IInnerProps {
    modalSize: ModalSize;
    modalStyle: ModalStyle;
    customMaxWidth?: number;
}
export const StyledInner = styled.div<IInnerProps>`
    background: #fff;
    padding: 25px;
    display: inline-block;
    vertical-align: middle;
    ${rtl`text-align: left;`};
    max-width: 800px;
    margin: 10px;
    position: relative;
    border-radius: 3px;
    opacity: 0;
    transition: all 0.5s;
    transform: translateX(-100px);
    font-family: "Ubuntu", Helvetica, Arial, sans-serif;
    z-index: 50;

    .post.block.clear {
        overflow: none;
        width: auto !important;
        background-repeat: no-repeat !important;
        background-position: initial !important;
    }

    ${props => {
        switch (props.modalStyle) {
            case ModalStyle.Primary:
                return css`
                    color: ${inputColor};
                    background-color: ${backgroundColor0};
                    border-color: ${props.theme.textColor};
                `;
            case ModalStyle.Success:
                return css`
                    color: #155724;
                    background-color: #d4edda;
                    border-color: #c3e6cb;
                `;
            case ModalStyle.Danger:
                return css`
                    color: #721c24;
                    background-color: #f8d7da;
                    border-color: #f5c6cb;
                `;
            case ModalStyle.Full:
                return css`
                    background: transparent;
                    margin: auto;
                    ${StyledClose} {
                        color: #fff;
                        text-shadow: 0 1px 0 #000;
                    }
                    ${RelativeBlock} {
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 1920px;
                        max-height: 100%;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-content: center;
                        > img {
                            max-width: 100%;
                            height: auto !important;
                            object-fit: cover;
                            margin: auto;
                        }
                        > iframe,
                        > video {
                            margin: auto;
                            max-height: 100% !important;
                            max-width: 100% !important;
                        }
                    }
                `;
        }
    }};
    ${props => {
        switch (props.modalSize) {
            case ModalSize.Small:
                return css`
                    max-width: 350px;
                `;
            case ModalSize.Medium:
                return css`
                    max-width: 550px;
                `;
            case ModalSize.Default:
                return css`
                    max-width: 800px;
                `;
            case ModalSize.Large:
                return css`
                    max-width: 1200px;
                `;
            case ModalSize.Custom:
                return css`
                    max-width: ${props.customMaxWidth
                        ? props.customMaxWidth + 80
                        : 550}px;
                `;
            case ModalSize.Full:
                return css`
                    max-width: 100%;
                    height: 100vh;
                `;
        }
    }};
    ${internalPages}
`;
