import {
    ApolloClient,
    MutationFunction,
    useApolloClient,
    useMutation,
} from "@apollo/client";
import {
    SignInWithTwoFA,
    SignInWithTwoFAVariables,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import SIGN_IN_WITH_2FA from "gql/mutations/sign-in-2fa.gql";
import { useMemo } from "react";
import { useTranslationList } from "../../use-translations-list";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import * as yup from "yup";
import { ErrorType } from "../../../../server/errors/error-type";
import { SignInStage } from "../stage/use-stage";
import { InvalidCSRFToken } from "services/long-running-issues/invalid-csrf-token";
import { useSignInLikeAPartner } from "../login/use-sign-in-like-a-partner";

export interface ITwoFACode {
    code: string;
}

export const use2faValidationSchema = () => {
    const { FIELD_SHOULD_BE_FILLED, CONTAINS_INVALID_CHARACTERS } =
        useTranslationList();
    return useMemo(
        () =>
            yup.object().shape({
                code: yup
                    .string()
                    .required(FIELD_SHOULD_BE_FILLED)
                    .matches(
                        /^([0-9]{6}|[0-9a-z]{8})$/,
                        CONTAINS_INVALID_CHARACTERS,
                    ),
            }),
        [CONTAINS_INVALID_CHARACTERS, FIELD_SHOULD_BE_FILLED],
    );
};

const initialValues: ITwoFACode = { code: "" };
export const use2faInitialValues = () => {
    return initialValues;
};

export const use2fa = (
    setStage: (stage: SignInStage) => void,
    partnerId?: number,
) => {
    const [__] = useTranslation();
    const { SIGN_IN_LIKE_A_PARTNER_ERROR } = useTranslationList();
    const client = useApolloClient();
    const [singInWithTwoFaMutation] = useMutation<
        SignInWithTwoFA,
        SignInWithTwoFAVariables
    >(SIGN_IN_WITH_2FA);

    const validationSchema = use2faValidationSchema();
    const { signInLikeAPartner } = useSignInLikeAPartner();

    const onSubmit = useMemo(() => {
        const s = createOnSubmit(
            singInWithTwoFaMutation,
            signInLikeAPartner,
            __,
            client,
            setStage,
            SIGN_IN_LIKE_A_PARTNER_ERROR,
            partnerId,
        );
        return onSubmitWrapper(async (values: ITwoFACode) => {
            await s(values.code);
        }, validationSchema);
    }, [
        __,
        client,
        singInWithTwoFaMutation,
        signInLikeAPartner,
        setStage,
        validationSchema,
        partnerId,
        SIGN_IN_LIKE_A_PARTNER_ERROR,
    ]);

    return { validationSchema, initialValues, onSubmit };
};

const createOnSubmit =
    (
        singInWithTwoFaMutation: MutationFunction<
            SignInWithTwoFA,
            SignInWithTwoFAVariables
        >,
        signInLikeAPartner: ReturnType<
            typeof useSignInLikeAPartner
        >["signInLikeAPartner"],
        __: TFunction,
        client: ApolloClient<unknown>,
        setStage: (stage: SignInStage) => void,
        SIGN_IN_LIKE_A_PARTNER_ERROR: () => string,
        partnerId?: number,
    ) =>
    async (code: string) => {
        try {
            const data = await singInWithTwoFaMutation({
                variables: { code },
            });
            if (partnerId) {
                void InvalidCSRFToken.logCSRFTokenSecurly(
                    "Setting csrf token before signInLikeAPartner at 2fa",
                    data.data?.authorization.signInWithTwoFA.csrf,
                );

                await signInLikeAPartner(
                    partnerId,
                    data.data?.authorization.signInWithTwoFA.csrf,
                );
            }
            setStage(SignInStage.SUCCESS);
        } catch (e: any) {
            void errorHandler(e, error => {
                switch (error.data.code) {
                    case ErrorType.SIGN_IN_LIKE_A_PARTNER_ERROR:
                        return SIGN_IN_LIKE_A_PARTNER_ERROR();
                    default:
                        return __("Ошибка");
                }
            });
            setStage(SignInStage.TWO_FA_ERROR);
        }
    };
