import { UniversalForm } from "components/filters";
import React from "react";
import { useTranslation } from "react-i18next";
import {
    IBaseFieldProps,
    defaultInputFieldProps,
} from "components/filters/services/get-filter-fields";
import { Field } from "components/formik";
import { useObjectValidations } from "validation/validaion";
import * as yup from "yup";
import { StatusField } from "./status-field";
import { FinantialPlanStatus } from "gql/types/operation-result-types";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { FinPlanReportTable } from "./report-table";

export const FinPlan = () => {
    const [__] = useTranslation();
    const { nullableInterger } = useObjectValidations();

    return (
        <UniversalForm
            fields={["period", "partnerId", "currencyAdversitingPayments"]}
            additionalFields={{
                planId: {
                    initialValue: null as number | null,
                    component: (props: IBaseFieldProps) => (
                        <Field
                            {...defaultInputFieldProps(props)}
                            label={__("ID плана")}
                        />
                    ),
                    validationSchema: nullableInterger,
                },
                planName: {
                    initialValue: "",
                    component: (props: IBaseFieldProps) => (
                        <Field
                            {...defaultInputFieldProps(props)}
                            label={__("Название")}
                        />
                    ),
                    validationSchema: yup.string(),
                },
                finplanStatus: {
                    initialValue: null as FinantialPlanStatus | null,
                    component: () => <StatusField />,
                },
            }}
            submitButtonName={__("Поиск")}
        >
            {({ form, submittedValues, actualValues }) => {
                return (
                    <>
                        {form}
                        <FirstLoadTable
                            actualValues={actualValues}
                            submittedValues={submittedValues}
                        >
                            {values => (
                                <FinPlanReportTable submittedValues={values} />
                            )}
                        </FirstLoadTable>
                    </>
                );
            }}
        </UniversalForm>
    );
};
