import { CurrencyCell } from "components/react-table/currency-cell";
import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetQuickReport,
    GetQuickReportVariables,
    GetQuickReport_authorized_partner_reports_quickReport_total,
    ManagerQuickReport,
    ManagerQuickReportVariables,
    ManagerQuickReport_authorized_manager_reports_quickReport_total,
} from "gql/types/operation-result-types";
import { QueryResult } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
    RegularText,
    TableWraperQuickReport,
    TableQuickReport,
} from "./styled";
import { LoadingText } from "components/react-table/loading-text";
import { SitePartitionType } from "views/site-patrition-wrapper";

interface IProps {
    tableData:
        | ManagerQuickReport_authorized_manager_reports_quickReport_total
        | GetQuickReport_authorized_partner_reports_quickReport_total
        | null
        | undefined;
    currency?: GetCurrencies_authorized_partnerAndManager_data_currencies;
    queryResult:
        | QueryResult<GetQuickReport, GetQuickReportVariables>
        | QueryResult<ManagerQuickReport, ManagerQuickReportVariables>;
    sitePartition?: SitePartitionType;
}

export const QuickReportTable = ({
    tableData,
    currency,
    queryResult,
    sitePartition,
}: IProps) => {
    const [__] = useTranslation();

    return (
        <>
            {queryResult.loading && (
                <RegularText>
                    <LoadingText fastLoading={true} />
                </RegularText>
            )}
            {!queryResult.loading && (
                <TableWraperQuickReport>
                    <TableQuickReport types={["striped", "bordered"]}>
                        <tbody>
                            <QuickTableItem
                                title={__("Показы")}
                                value={tableData?.countOfViews}
                            />
                            <QuickTableItem
                                title={__("Клики")}
                                value={tableData?.countOfClicks}
                            />
                            <QuickTableItem
                                title={__("Прямые ссылки")}
                                value={tableData?.countOfDirectLinks}
                            />
                            <QuickTableItem
                                title={__("Клики/показы")}
                                value={tableData?.CTR}
                                prefix="%"
                            />
                            <QuickTableItem
                                title={__("Регистрации")}
                                value={tableData?.countOfRegistrations}
                            />
                            <QuickTableItem
                                title={__("Соотношение регистрации/клики")}
                                value={tableData?.registrationsClicksRatio}
                                prefix="%"
                            />
                            <QuickTableItem
                                title={__("Регистрации с депозитом")}
                                value={
                                    tableData?.countOfRegistrationsWithDeposits
                                }
                            />
                            <QuickTableItem
                                title={__(
                                    "Соотношение регистрации с депозитом/регистрации",
                                )}
                                value={
                                    tableData?.registrationsWithDepositsRegistrationsRatio
                                }
                                prefix="%"
                            />
                            <QuickTableItem
                                title={__("Сумма новых депозитов")}
                                value={tableData?.newDepositsSum}
                                currency={currency?.name}
                            />
                            <QuickTableItem
                                title={__("Новые аккаунты с депозитами")}
                                value={tableData?.newDepositors}
                            />
                            <QuickTableItem
                                title={__("Аккаунты с депозитами")}
                                value={tableData?.countOfAccountsWithDeposits}
                            />
                            <QuickTableItem
                                title={__("Сумма депозитов")}
                                value={tableData?.depositAmount}
                                currency={currency?.name}
                            />
                            <QuickTableItem
                                title={__("Доход")}
                                value={tableData?.profit}
                                currency={currency?.name}
                            />
                            {sitePartition === SitePartitionType.Manager && (
                                <>
                                    <QuickTableItem
                                        title={__("Комиссии платежных систем")}
                                        value={
                                            (
                                                tableData as
                                                    | ManagerQuickReport_authorized_manager_reports_quickReport_total
                                                    | null
                                                    | undefined
                                            )?.paymentSystemFees
                                        }
                                        currency={currency?.name}
                                    />
                                </>
                            )}
                            <QuickTableItem
                                title={__("Количество депозитов")}
                                value={tableData?.countOfDeposits}
                            />
                            <QuickTableItem
                                title={__("Активные игроки")}
                                value={tableData?.countOfActivePlayers}
                            />
                            <QuickTableItem
                                title={__("Средний доход с игрока")}
                                value={tableData?.averageProfitPlayers}
                                currency={currency?.name}
                            />
                            <QuickTableItem
                                title={__("Сумма бонусов")}
                                value={tableData?.bonusAmount}
                                currency={currency?.name}
                            />
                            <QuickTableItem
                                title={__("Сумма комиссий RS")}
                                value={tableData?.comissionRS}
                                currency={currency?.name}
                            />
                            <QuickTableItem
                                title={__("CPA")}
                                value={tableData?.CPA}
                                currency={currency?.name}
                            />
                            <QuickTableItem
                                title={__("Реферальная комиссия")}
                                value={tableData?.referalComission}
                                currency={currency?.name}
                            />
                            <QuickTableItem
                                title={__("Суммарная комиссия")}
                                value={tableData?.comissionAmount}
                                currency={currency?.name}
                            />
                        </tbody>
                    </TableQuickReport>
                </TableWraperQuickReport>
            )}
        </>
    );
};

interface IQuickTableItem {
    title: string;
    value?: string | number | null;
    currency?: string | "RUB" | "USD" | "BTC";
    prefix?: string;
}

export const QuickTableItem = ({
    title,
    value,
    prefix,
    currency,
}: IQuickTableItem) => {
    return (
        <tr>
            <td>{title}</td>
            <td>
                {currency ? (
                    <CurrencyCell currency={currency} value={value} />
                ) : (
                    <>
                        {value && (
                            <>
                                {value}&nbsp;{prefix}
                            </>
                        )}
                    </>
                )}
            </td>
        </tr>
    );
};
