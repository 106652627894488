import { Field } from "components/formik";
import { useTranslation } from "react-i18next";
import { FormGroup } from "styled/layouts";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const HideInputsBlock = React.memo((props: IProps) => {
    const [__] = useTranslation();

    return (
        <>
            <FormGroup>
                <Field
                    name="minSum"
                    type="text"
                    autoComplete="on"
                    label={__("Минимальная сумма депозита")}
                    placeholder="0.00"
                />
            </FormGroup>
            <FormGroup>
                <Field
                    name="minSumBet"
                    type="text"
                    autoComplete="on"
                    label={__("Общая сумма ставок")}
                    placeholder="0.00"
                />
            </FormGroup>
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
