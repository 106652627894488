import { MobileMenuUserToggleSC } from "./styled";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Action, compose } from "redux";
import { StatesActionCreators } from "services/redux/root/states/actions";
import { statesSelectors } from "services/redux/root/states/selectors";
import { UserCircle } from "@styled-icons/fa-solid/UserCircle/UserCircle";

interface IProps extends RouteComponentProps {
    isProfileMenuClicked: boolean;
    setIsProfileMenuClicked: (visibility: boolean) => Action;
}

export const MobileMenuUserToggle = compose(
    withRouter,
    connect(
        state => ({
            isProfileMenuClicked:
                statesSelectors.getIsProfileMenuClicked(state),
        }),
        dispatch => ({
            setIsProfileMenuClicked: (visibility: boolean) =>
                dispatch(
                    StatesActionCreators.setIsProfileMenuClicked(visibility),
                ),
        }),
    ),
)(
    React.memo((props: IProps) => {
        return (
            <MobileMenuUserToggleSC
                onClick={() =>
                    props.setIsProfileMenuClicked(!props.isProfileMenuClicked)
                }
            >
                <UserCircle size={26} />
            </MobileMenuUserToggleSC>
        );
    }),
);
