import { SealItemImg } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    SealImg: string;
    SealLink: string;
}

export const SealItems = React.memo((props: IProps) => {
    return (
        <a href={props.SealLink} target="_blank" rel="noopener noreferrer">
            <SealItemImg>
                <img src={props.SealImg} alt="" />
            </SealItemImg>
        </a>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
