import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
    GenerateAdversitingPaymentsPlans,
    GenerateAdversitingPaymentsPlansVariables,
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
    PaymentPeriod,
} from "gql/types/operation-result-types";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { Form, Field } from "components/formik";
import {
    FormDirectionRow,
    FormGroup,
    FormGroupCheckbox,
    FormGroupButton,
} from "styled/layouts";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { initialValues } from "./initial-values";
import { useGetValiadationSchema } from "./use-get-validation-schema";
import GENERATE_ADVERTIZING_PAYMENTS_PLANS from "./generate.gql";
import { PaymentPeriodField } from "./payment-period-field";

export const GenerateForm = ({
    setPaymentPlans,
    planId,
}: {
    setPaymentPlans: (
        paymentsPlans: // eslint-disable-next-line max-len
        | GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans[]
            | undefined,
    ) => void;
    planId: number;
}) => {
    const [__] = useTranslation();
    const getValiadationSchema = useGetValiadationSchema();

    const [mutation] = useMutation<
        GenerateAdversitingPaymentsPlans,
        GenerateAdversitingPaymentsPlansVariables
    >(GENERATE_ADVERTIZING_PAYMENTS_PLANS);

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={() =>
                yup.lazy((values: any) => {
                    return getValiadationSchema(values);
                })
            }
            onSubmit={onSubmitWrapper(async values => {
                try {
                    const result = await mutation({
                        variables: {
                            description: {
                                planId,
                                ...values,
                                secondPaymentMonthDay:
                                    values.paymentPeriod !==
                                    PaymentPeriod.TwicePerMonth
                                        ? null
                                        : values.secondPaymentMonthDay,
                            },
                        },
                    });
                    const paymentsPlans =
                        result.data?.authorized.manager.advertising
                            .finantialPlans.generateAdversitingPaymentsPlans;
                    setPaymentPlans(paymentsPlans);
                } catch (ex: any) {
                    await errorHandler(ex, error => {
                        switch (error.data.code) {
                            // валидационные ошибки надо пробрасывать дальше
                            // так как ими занимается общий код в onSubmitWrapper
                            // TODO: сделать проброс общим внутри errorHandler?
                            case ErrorType.VALIDATION_ERROR:
                                throw ex;
                        }
                    });
                }
            }, getValiadationSchema())}
        >
            {({ values }) => (
                <>
                    <Form>
                        <FormDirectionRow>
                            <FormGroup>
                                <PaymentPeriodField />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    name="firstPaymentMonthDay"
                                    type="text"
                                    label={`${__("День платежа")} *`}
                                />
                            </FormGroup>
                            {values.paymentPeriod ===
                                PaymentPeriod.TwicePerMonth && (
                                <FormGroup>
                                    <Field
                                        name="secondPaymentMonthDay"
                                        type="text"
                                        label={`${__("День платежа 2")} *`}
                                    />
                                </FormGroup>
                            )}
                            <FormGroupCheckbox>
                                <FakeLabel />
                                <Field
                                    name="createPaymentsAutomaticaly"
                                    type="checkbox"
                                    label={__(
                                        "Создавать выплаты автоматически",
                                    )}
                                />
                            </FormGroupCheckbox>
                            <FormGroupButton>
                                <FakeLabel />
                                <SubmitButton>
                                    {__("Сгенерировать план")}
                                </SubmitButton>
                            </FormGroupButton>
                        </FormDirectionRow>
                    </Form>
                </>
            )}
        </FormikConsoleErrors>
    );
};
