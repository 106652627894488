import { useTranslation } from "react-i18next";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { PromocodesReportTable } from "./report-table";
import * as yup from "yup";
import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    PartnerSitesList_authorized_manager_main_sites,
} from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";
import { SiteField } from "../../fields/site";
import { CurrencyField } from "components/filters/fields";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { useState } from "react";
import { Form } from "components/formik";
import { FormDirectionRow, FormGroup, FormGroupButton } from "styled/layouts";
import { Field } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";

const initialValues = {
    currency: null,
    site: null,
    promoCode: "",
};

const validationSchema = yup.object({
    currency: yup
        .object<GetCurrencies_authorized_partnerAndManager_data_currencies>()
        .nullable(),
    site: yup
        .object<PartnerSitesList_authorized_manager_main_sites>()
        .nullable(),
    promoCode: yup.string(),
});

export const Promocodes = ({ partnerId }: { partnerId: number }) => {
    const [__] = useTranslation();
    const [submittedValues, setSubmittedValues] =
        useState<typeof initialValues>();

    return (
        <AdminWrapper>
            <FormikConsoleErrors
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async values => setSubmittedValues(values)}
            >
                {({ values: actualValues, resetForm }) => {
                    return (
                        <>
                            <Form>
                                <FormDirectionRow>
                                    <FormGroup>
                                        <CurrencyField
                                            name="currency"
                                            label={__("Валюта")}
                                            isClearable={false}
                                            partnerId={partnerId}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field
                                            name="promoCode"
                                            type="text"
                                            label={__("Промокод")}
                                            autoComplete="on"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <SiteField
                                            name="site"
                                            partnerId={partnerId}
                                        />
                                    </FormGroup>
                                    <FormGroupButton>
                                        <FakeLabel />
                                        <SubmitButton>
                                            {__("Поиск")}
                                        </SubmitButton>
                                    </FormGroupButton>
                                </FormDirectionRow>
                            </Form>
                            <FirstLoadTable
                                actualValues={actualValues}
                                submittedValues={submittedValues}
                            >
                                {values => (
                                    <PromocodesReportTable
                                        submittedValues={values}
                                        partnerId={partnerId}
                                        resetFormPromocodes={resetForm}
                                    />
                                )}
                            </FirstLoadTable>
                        </>
                    );
                }}
            </FormikConsoleErrors>
        </AdminWrapper>
    );
};
