import { useHistory } from "react-router-dom";
import { useLanguage } from "components/language-provider";

export function useLocalizedAbsolutePush() {
    const {
        language: { urlPrefix },
    } = useLanguage();
    const history = useHistory();

    function push(absolutePath: string) {
        let path = absolutePath;
        if (urlPrefix) {
            path = `/${urlPrefix}${path}`;
        }

        history.push(path);
    }

    return push;
}
