import { UniversalForm } from "components/filters";
import { useTranslation } from "react-i18next";
import { FullReportEmptyTable } from "./empty-table";
import { ReportTableWithQuery } from "views/partner-manager/report-table-with-query";
import GET_MANAGER_FULL_REPORT from "./query.gql";
import { generalQueryParams } from "./general-query-params";

interface IInjectedProps {}
interface IProps extends IInjectedProps {}

export const FullReport = React.memo((props: IProps) => {
    const [__] = useTranslation();
    return (
        <UniversalForm
            fields={[
                "currency",
                "commisionGroup",
                "partnersGroup",
                "partnerId",
                "period",
                "registrationSource",
            ]}
            submitButtonName={__("Сгенерировать отчет")}
        >
            {({ form, submittedValues, actualValues }) => {
                return (
                    <ReportTableWithQuery
                        actualValues={actualValues}
                        submittedValues={submittedValues}
                        form={form}
                        QUERY={GET_MANAGER_FULL_REPORT}
                        emptyTable={FullReportEmptyTable}
                        transfromToQueryParams={generalQueryParams}
                    />
                );
            }}
        </UniversalForm>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
