import { useDefinedContext } from "hooks/use-context-exist";
import { useLocation } from "react-router";
import { Helmet, HelmetsOpenedVisor } from "react-helmet-with-visor";
import { PartnersProgramGeneralInformationContext } from "views";
import { NoScriptBodyInjector } from "components/no-script-body-injector";
import { useTranslation } from "react-i18next";
import urljoin from "url-join";
import { languageListByPartner } from "services/i18n/language-list";
import {
    VIVATBET_PARTNERS,
    BIZBET_PARTNERS,
    PARIPESA_PARTNERS,
} from "server/partner-programs";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    // TODO: пройтись по цепочке и удалить projectName, нигде не задается.
    projectName?: string;
    titleText?: string;
    metaText?: string;
}

export const RootHelmetBodyInjector = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const pathname = useLocation().pathname;
    const {
        partnerProgramCompanyName,
        agentSiteInformation,
        landingMainPage,
        siteAddress,
        seoDomain,
        partnersProgramId,
    } = useDefinedContext(PartnersProgramGeneralInformationContext);
    const faviconPath = "/config-files/favicon";
    let titleText = props.projectName
        ? props.projectName
        : format(
              __("{PartnerCompanyName} | Партнерская программа {CompanyName}"),
              {
                  PartnerCompanyName: partnerProgramCompanyName,
                  CompanyName: agentSiteInformation.companyName,
              },
          );
    let metaText = format(
        __(
            "Партнерская программа {PartnerCompanyName}. Зарабатывай на спорте вместе с {CompanyName} по реферальной программе до {Comission}.",
        ),
        {
            PartnerCompanyName: partnerProgramCompanyName,
            CompanyName: agentSiteInformation.companyName,
            Comission: landingMainPage?.comission,
        },
    );
    if (props.titleText) {
        titleText = props.titleText;
    }
    if (props.metaText) {
        metaText = props.metaText;
    }

    const getHreflangLinks = languageListByPartner(partnersProgramId)
        .getAll()
        .map(language => (
            <link
                key={language.code}
                rel="alternate"
                href={`${siteAddress}${
                    language.urlPrefix && `${language.urlPrefix}/`
                }`}
                hrefLang={language.code}
            />
        ));

    const robotsNoIndex = partnersProgramId === VIVATBET_PARTNERS;
    const canonicalAddress = seoDomain ? `https://${seoDomain}` : siteAddress;
    return (
        <>
            <Helmet defaultTitle={titleText}>
                <meta property="og:title" content={titleText} />
                <meta name="description" content={metaText} />
                <meta property="og:description" content={metaText} />
                {robotsNoIndex && <meta name="robots" content="noindex" />}
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${faviconPath}/apple-touch-icon.png`}
                />
                <link
                    rel="canonical"
                    href={urljoin(canonicalAddress, pathname)}
                />
                <link rel="shortcut icon" href="/favicon.ico" />
                {(partnersProgramId === VIVATBET_PARTNERS ||
                    partnersProgramId === BIZBET_PARTNERS ||
                    partnersProgramId === PARIPESA_PARTNERS) &&
                    getHreflangLinks}
            </Helmet>
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

export const RootHelmetAndScriptsBodyInjector = React.memo((props: IProps) => {
    const { seoScriptsAndTags } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <>
            <RootHelmetBodyInjector {...props} />
            <Helmet>
                <HelmetsOpenedVisor>
                    {seoScriptsAndTags.verificationTags}
                </HelmetsOpenedVisor>
                <HelmetsOpenedVisor>
                    {seoScriptsAndTags.trackingScripts}
                </HelmetsOpenedVisor>
            </Helmet>

            <NoScriptBodyInjector
                noscript={seoScriptsAndTags.trackingNoScripts}
            />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
