import styled from "styled-components";
import { media } from "services/styled-components/variables";

export const ContainerMedia = styled.div`
    display: flex;
    gap: 0 1.87em;

    ${media.smMax`
        flex-wrap: wrap;
    `}
`;
