import { GetCurrenciesAdversitingPayments } from "gql/types/operation-result-types";
import { gql, useQuery } from "@apollo/client";

export const GET_CURRENCIES_ADVERSITING_PAYMENTS = gql`
    query GetCurrenciesAdversitingPayments {
        authorized {
            manager {
                advertising {
                    advertisingPayments {
                        currencyAdvertisingPayments {
                            id
                            name
                            baseRate
                            round
                        }
                    }
                }
            }
        }
    }
`;

export const useCurrencyAdversitingPayments = () => {
    const { data, loading } = useQuery<GetCurrenciesAdversitingPayments>(
        GET_CURRENCIES_ADVERSITING_PAYMENTS,
        {
            ssr: true,
        },
    );

    const currencyList =
        data?.authorized.manager.advertising.advertisingPayments
            .currencyAdvertisingPayments;

    return { data: currencyList, loading };
};
