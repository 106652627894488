import styled, { css } from "styled-components";
import { backgroundColor0 } from "services/styled-components/themes";
import { media } from "services/styled-components/variables";

export const WrapperSteps = styled.div`
    z-index: 1;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    &::after {
        content: "";
        position: absolute;
        width: 100%;
        border-bottom: 2px dashed currentColor;
    }
`;

export const StepItem = styled.div`
    z-index: 5;
    display: flex;
    flex: 1 0 auto;

    &:last-child {
        background-color: ${backgroundColor0};
    }
`;

export const StepCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 0.5em;
    font-size: 1.25em;
    border-radius: 50%;
    border: 2px solid currentColor;

    ${media.mdMax`
        margin-right: 0;
    `}
`;

export const StepTitle = styled.div`
    max-width: 180px;
    text-transform: uppercase;

    ${media.mdMax`
        display: none;
    `}
`;

export const StepItemContent = styled.div<{
    active?: boolean;
    completed?: boolean;
}>`
    display: flex;
    align-items: center;
    padding-left: 0.625em;
    padding-right: 0.625em;
    background-color: ${backgroundColor0};
    ${props =>
        props.active &&
        css`
            color: ${props.theme.accentColor};
        `};
    ${props =>
        props.completed &&
        css`
            color: ${props.theme.accentColor};
            ${StepCircle} {
                color: ${backgroundColor0};
                background-color: ${props.theme.accentColor};
            }
            ${StepTitle} {
                text-decoration: underline;
            }
        `};
`;
