import { GetPartnersProgramGeneralInfo } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_PARTNERS_PROGRAM_GENERAL_INFO = gql`
    query GetPartnersProgramGeneralInfo {
        partnersProgram {
            generalInformation {
                partnersProgramId
                partnerProgramCompanyName
                siteAddress
                email
                skype
                telegramm
                facebook
                instagram
                twitter
                youtube
                whatsApp
                linkedin
                viber
                androidAppLinks
                telegramBot
                IOSAppLinks
                copyrightPeriodStart
                agentSiteInformation {
                    companyName
                    siteAddress
                    emailSupport
                }
                seoDomain
                seoScriptsAndTags {
                    verificationTags
                    successfullLoginScript
                    unsuccessfullLoginScript
                    successfullRegistrationScript
                    unsuccessfullRegistrationScript
                    trackingScripts
                    trackingNoScripts
                }
                permissions {
                    postbacks4
                    paymentsAndInvoices
                    paymentHistory
                    postbacks
                    tokensForGetingActualDomain
                    customPromocode
                    advertising
                    editIntroducedByField
                    rights
                }
                visibility {
                    hiddenPages
                    showingNews {
                        showNews
                    }
                    becomeAnAffiliate
                }
                landingMainPage {
                    importantPartnerTitle
                    currencyName
                    welcomeInfo {
                        title
                        signUpBtnText
                        list {
                            listItem {
                                message
                                formatKey
                            }
                        }
                    }
                    comission
                    termsIcons {
                        iconFamily
                        icon
                        title
                        annotation
                        size
                    }
                    capabilities {
                        capabilityNumber
                        capabilityTitle
                    }
                    partnersSeals {
                        imageAdress
                        ref
                    }
                    partnersLogos {
                        partnerBG
                        partnerImg
                        partnerLink
                        secondBG
                    }
                }
                partnerInfo {
                    minimalPaymentWeekly
                    minimalPaymentMonthly
                }
                buttons {
                    default {
                        borderRadius
                        color
                    }
                    welcomeButton {
                        background
                        iconColor
                        backgroundOnHover1
                        backgroundOnHover2
                    }
                }
                stylesTheme {
                    backgroundColor
                    frontBackgroundColor
                    secondBorderColor
                    additionalColor1
                    additionalColor2
                    additionalColor3
                    additionalColor4
                    additionalColor5
                    landingHeaderColor
                    backBackgroundColor
                    bodyColor
                    languageSwitcherBG
                    authFormColor
                    CookieNotificationBg
                    landingTextColor
                    textColor
                    mutedTextColor
                    authTextColor
                    accentColor
                    accentHoverColor
                    adminTitleColor
                    primaryColor
                    activeColor
                    asideMenuItemColor
                    clientSideStyles {
                        headerBgColor
                    }
                }
            }
        }
    }
`;

export const usePartnersProgramGeneralInformation = () => {
    const { data, loading, error } = useQuery<GetPartnersProgramGeneralInfo>(
        GET_PARTNERS_PROGRAM_GENERAL_INFO,
        { ssr: true },
    );

    return {
        data: g(data, "partnersProgram", "generalInformation"),
        loading,
        error,
    };
};
