/* eslint-disable no-console */
import GET_USER from "gql/queries/user.gql";
import { User } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import { useRef } from "react";

// функция вызывается часто и пердставляет юзера, поэтому решено было его использовать как функу которая приводит
// к сетевому вызову (иначе когда все закешировано, вызовы прекращеются и токен может устареть)
// TODO: cache-and-network все равно не работает как нужно,
// и когда приходит время запроса все равно мы его дожидаемся  а не берем быстро из кеша
// TODO: более стабильно, будет разместить где-то в настройках ApolloClient чтобы все вызовы к графюэлю
// шли через эту логику (60-sec-сache), т.к. useUser может не находиться в нужных местах
// чтобы обеспечить постоянные запросы к серверу
export function useUser(timeCahe = false) {
    const updateTokenSwitcher = useGetUpdateTokenSwitcher(timeCahe);
    const userQuery = useQuery<User>(GET_USER, {
        ssr: true,
        fetchPolicy: timeCahe ? "cache-and-network" : "cache-first",
        variables: { updateTokenSwitcher },
    });

    return {
        user: g(userQuery, "data", "user") || undefined,
        loading: userQuery.loading,
        userQuery,
        roles: g(userQuery.data, "user", "roles"),
        hiddenColumns: g(userQuery, "data", "user", "hiddenColumns"),
    };
}

// cache-and-network не приводит к вызову при каждом рендере сам по себе (только при маунтинге)
// приходится придумывать параметр
function useGetUpdateTokenSwitcher(timeCahe: boolean) {
    const updateTokenSwitcher = useRef(0);
    const throttleIntervalMs = 60000;
    const lastTime = useRef(Date.now());
    if (!timeCahe) {
        return 1;
    }
    if (Date.now() - lastTime.current > throttleIntervalMs) {
        updateTokenSwitcher.current = updateTokenSwitcher.current ? 0 : 1;
        lastTime.current = Date.now();
    }
    return updateTokenSwitcher.current;
}
