import { Tabs } from "components/tabs";
import { Tab } from "components/tabs/components/tab";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { SitesTab } from "views/partner/main/sites/tabs";

interface SiteListProps {
    partnerId?: number;
    chooseSite?: (siteId: number) => void;
}

export const SiteList = ({ partnerId, chooseSite }: SiteListProps) => {
    const [__] = useTranslation();

    return (
        <AdminWrapper>
            {partnerId && (
                <Tabs name="choosedSite" default={0}>
                    <Tab title={__("Активные")} key={0}>
                        <SitesTab
                            hidden={false}
                            partnerId={partnerId}
                            chooseSite={chooseSite}
                        />
                    </Tab>
                    <Tab title={__("Скрытые")} key={1}>
                        <SitesTab
                            hidden={true}
                            partnerId={partnerId}
                            chooseSite={chooseSite}
                        />
                    </Tab>
                </Tabs>
            )}
        </AdminWrapper>
    );
};
