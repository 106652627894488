import moment from "moment";
import { PartnerGroups_authorized_manager_main_partnerGroups_data } from "gql/types/operation-result-types";
import { TFormValues } from "./use-validation-schema";

export const getInitialValues = ({
    dateStart,
    dateEnd,
}: PartnerGroups_authorized_manager_main_partnerGroups_data): TFormValues => {
    return {
        dateStart: moment(dateStart),
        dateEnd: dateEnd ? moment(dateEnd) : null,
        endless: true,
    };
};
