import { useCallback, useEffect, useState } from "react";

export const useResize = (): {
    currentWidth: number;
    getVisibility: (media: number) => boolean;
} => {
    const [currentWidth, setCurrentWidth] = useState(
        globalThis.innerWidth || 1500,
    );
    useEffect(() => {
        setCurrentWidth(window.innerWidth);
        const handleResizeCallback = () => setCurrentWidth(window.innerWidth);

        window.addEventListener("resize", handleResizeCallback);
        return () => window.removeEventListener("resize", handleResizeCallback);
    }, []);

    const getVisibility = useCallback(
        (media: number) => (currentWidth < media ? false : true),
        [currentWidth],
    );

    return { currentWidth, getVisibility };
};
