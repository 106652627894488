import { ShortTimePeriods } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { Field } from "components/formik";

export const TablePeriodField = React.memo(() => {
    const [__] = useTranslation();

    const options = [
        { value: ShortTimePeriods.TODAY, label: __("Сегодня") },
        { value: ShortTimePeriods.YESTERDAY, label: __("Вчера") },
        { value: ShortTimePeriods.THIS_MONTH, label: __("Текущий месяц") },
        { value: ShortTimePeriods.LAST_MONTH, label: __("Прошлый месяц") },
    ];

    return (
        <Field
            name="shortPeriod"
            type="react-select"
            options={options}
            isLoading={false}
            selectFirst={true}
            isClearable={false}
            minWidth={200}
        />
    );
}) as React.ComponentType;
