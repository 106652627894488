import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import { CellWithButtons } from "components/table-item";
import { ToogleShowLink } from "../toggle-show-link";

export const useColumns = (): IReactTableColumn[] => {
    const [__] = useTranslation();

    return [
        {
            Header: __("№"),
            accessor: "linkId",
            minWidth: 50,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            disabled: true,
            minWidth: 120,
        },
        {
            Header: __("Состояние"),
            accessor: "hidden",
            disabled: true,
            minWidth: 90,
            Cell: ({ original: { linkId, hidden, siteId } }) => (
                <ToogleShowLink
                    linkId={linkId}
                    hidden={hidden}
                    siteId={siteId}
                />
            ),
        },
        {
            Header: __("Целевая страница"),
            accessor: "landingPage",
            minWidth: 90,
        },
        {
            Header: __("SubID"),
            accessor: "subId",
            minWidth: 60,
        },
        {
            Header: __("Кампания"),
            accessor: "campaignName",
            minWidth: 90,
        },
        {
            Header: __("Готовая ссылка"),
            accessor: "finishedLink",
            minWidth: 160,
            Cell: cellInfo => (
                <CellWithButtons link={true} text={cellInfo.value} />
            ),
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 50,
        },
    ];
};
