import { css } from "styled-components";
const fontsPath = "../assets/fonts/quantico/";

export const QuanticoFonts = css`
    @font-face {
        font-family: "Quantico";
        font-display: swap;
        src: url("${fontsPath}Quantico-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Quantico";
        font-display: swap;
        src: url("${fontsPath}Quantico-Bold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Quantico";
        font-display: swap;
        src: url("${fontsPath}Quantico-Italic.ttf") format("truetype");
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: "Quantico";
        font-display: swap;
        src: url("${fontsPath}Quantico-BoldItalic.ttf") format("truetype");
        font-weight: 700;
        font-style: italic;
    }
`;
