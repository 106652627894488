import { Postbacks } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import POSTBACKS from "../gql/postbacks.gql";

export const usePostbacks = () => {
    const queryResult = useQuery<Postbacks>(POSTBACKS, {
        ssr: true,
    });
    const data = queryResult.data?.authorized.partner.postback.postbacks;

    return {
        data,
        loading: queryResult.loading,
    };
};
