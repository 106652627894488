import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";

export const CloseButton = styled.button<{ orientation: "right" | "left" }>`
    ${props =>
        css`
            position: absolute;
            ${props.orientation === "right"
                ? css`
                      ${rtl`
                        right: 0;
                        transform: translateX(100%);
                      `};
                  `
                : css`
                      ${rtl`
                    right: 100%;
                    transform: translateX(0);
                `};
                  `}
            top: 10px;
            width: 50px;
            height: 50px;
            color: ${props.theme.textColor};
        `}
`;
