import { MutationFunction } from "@apollo/client";
import { TFunction } from "react-i18next";
import { toast } from "react-toastify";
import { pickBy } from "lodash";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { AllUniversalOutputValues } from "components/filters/types";
import {
    CampaignStatus,
    CampaignsFilter,
    UpdateCampaignStatus,
    UpdateCampaignStatusVariables,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetLanguages_languages,
} from "gql/types/operation-result-types";

type CampaignsFilterSubmitValues = Pick<
    AllUniversalOutputValues,
    "campaignId" | "campaignName" | "campaignStatus"
> & {
    currency?: GetCurrencies_authorized_partnerAndManager_data_currencies;
    language?: GetLanguages_languages;
};

export const getCampaignsFilter = (
    values?: CampaignsFilterSubmitValues,
): CampaignsFilter =>
    pickBy({
        id: values?.campaignId,
        name: values?.campaignName,
        currencyId: Number(values?.currency?.id),
        languageId: values?.language?.id,
        status: values?.campaignStatus,
    });

export const getStatusOptions = (__: TFunction) => [
    {
        value: CampaignStatus.INACTIVE,
        label: __("Деактивировать"),
    },
    {
        value: CampaignStatus.ACTIVE,
        label: __("Активировать"),
    },
    {
        value: CampaignStatus.DELETED,
        label: __("Удалить"),
    },
];

export const updateStatus = async (
    ids: Set<number>,
    status: CampaignStatus | null,
    mutation: MutationFunction<
        UpdateCampaignStatus,
        UpdateCampaignStatusVariables
    >,
    __: TFunction,
) => {
    if (ids.size === 0) {
        toast.error(__("Запись не выбрана"));
        return;
    } else if (!status) {
        toast.error(__("Статус не выбран"));
        return;
    }

    try {
        await mutation({
            variables: {
                status,
                ids: Array.from(ids),
            },
        });
        toast.success(__("Статус успешно изменен"));
    } catch (ex: any) {
        void errorHandler(ex, error => {
            switch (error.data.code) {
                case ErrorType.IMPOSIBLE_STATUS_CHANGING:
                    return __("Невозможно применить статус");
            }
        });
    }
};
