import { PartnerProgramRegistrationSources } from "gql/types/operation-result-types";
import { ISelectFild } from "../select-filed-interface";
import { useTranslation } from "react-i18next";
import { useQuery, gql } from "@apollo/client";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { Field } from "components/formik";

const PARTNER_PROGRAM_REGISTRATION_SOURCES = gql`
    query PartnerProgramRegistrationSources {
        authorized {
            manager {
                data {
                    partnerProgramRegistrationSource {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const PartnerProgramRegistrationSourceField = (props: ISelectFild) => {
    const { name, ...rest } = props;
    const [__] = useTranslation();
    const { data, loading } = useQuery<PartnerProgramRegistrationSources>(
        PARTNER_PROGRAM_REGISTRATION_SOURCES,
        {
            ssr: true,
        },
    );

    const options =
        data?.authorized.manager.data.partnerProgramRegistrationSource.map(
            regSource => ({
                value: regSource as any,
                label: regSource.name,
            }),
        ) || [];
    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={name}
            label={__("Источник регистрации")}
            type="react-select"
            options={options}
            isLoading={loading}
            minWidth={200}
            {...rest}
        />
    );
};
