import {
    IReactTableColumn,
    IReportCellInfo,
} from "components/react-table/column";
import { useGetSites } from "hooks/query/use-get-sites";
import { useTranslation } from "react-i18next";
import { HideSiteComponent } from "../hide-site/";
import { ReactTable } from "components/react-table";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { CheckSquare } from "@styled-icons/boxicons-regular/CheckSquare/CheckSquare";
import { CellWithButtons } from "components/table-item";

interface IProps {
    hidden: boolean;
    partnerId?: number;
    chooseSite?: (siteId: number) => void;
}

export const SitesTab = React.memo((props: IProps) => {
    const { hidden, partnerId, chooseSite } = props;
    const { sitesList, loading } = useGetSites({
        hidden,
        partnerId,
    });
    const [__] = useTranslation();

    const columns: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "id",
            disabled: true,
            minWidth: 50,
            Cell: cellInfo => {
                if (!chooseSite) {
                    return cellInfo.value;
                }
                const handleClick = () => chooseSite(cellInfo.value);
                const button = (
                    <ButtonColoredWithIcon
                        onClick={handleClick}
                        btnColor={BtnColor.Faded}
                    >
                        <CheckSquare size={15} />
                    </ButtonColoredWithIcon>
                );
                return (
                    <CellWithButtons
                        button={button}
                        link={false}
                        text={cellInfo.value}
                    />
                );
            },
        },
        {
            Header: __("Сайт"),
            accessor: "name",
            disabled: true,
            minWidth: 120,
        },
        ...(partnerId === undefined
            ? [
                  {
                      Header: __("Состояние"),
                      accessor: "hidden",
                      disabled: true,
                      minWidth: 100,
                      Cell: ({ original }: IReportCellInfo) => (
                          <HideSiteComponent
                              siteId={original.id}
                              hidden={original.hidden}
                          />
                      ),
                  },
              ]
            : []),
    ];

    return (
        <ReactTable
            tableName="web-site"
            columns={columns}
            data={sitesList}
            loading={loading}
            key={`sitesList_hidden_${hidden}`}
        />
    );
}) as React.ComponentType<IProps>;
