import { OnOppositeSides } from "styled/layouts";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { Form } from "components/formik";
import { FormDirectionRow, FormGroup } from "styled/layouts";
import { CurrencyField } from "components/filters/fields/currency";
import { MockPeriodField } from "components/filters/fields/mock-period";
import { StatisticsChart } from "./statistics-chart";

export const ManagerStatisticsChart = () => {
    const [__] = useTranslation();

    return (
        <FormikConsoleErrors
            initialValues={{
                mockPeriod: __("7 дней"),
                currency: undefined as
                    | GetCurrencies_authorized_partnerAndManager_data_currencies
                    | undefined,
            }}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
        >
            {formikProps => (
                <>
                    <OnOppositeSides>
                        <Title>
                            {__("Быстрая статистика в виде графиков")}
                        </Title>
                        <Form>
                            <FormDirectionRow>
                                <FormGroup>
                                    <MockPeriodField name={"mockPeriod"} />
                                </FormGroup>
                                <FormGroup>
                                    <CurrencyField
                                        name={"currency"}
                                        selectFirst={true}
                                    />
                                </FormGroup>
                            </FormDirectionRow>
                        </Form>
                    </OnOppositeSides>
                    <StatisticsChart
                        currency={formikProps.values.currency}
                        mockPeriod={formikProps.values.mockPeriod}
                        loadCurrency={!formikProps.values.currency}
                    />
                </>
            )}
        </FormikConsoleErrors>
    );
};
