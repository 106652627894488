import { useMessengersInForms } from "hooks/use-messenger-login-validation";
import { Field, useFormikContext } from "components/formik";
import { GetMessengers_messengers } from "gql/types/operation-result-types";
import { LabelOneLine } from "views/partner/main/profile/styled";

interface IProps {
    required?: boolean;
    disabled?: boolean;
}

export const MessengerLoginField = ({ required, disabled }: IProps) => {
    const { getMessengerLoginValidation, getPlaceholder, getLabel } =
        useMessengersInForms();

    const { values } = useFormikContext<{
        messenger?: GetMessengers_messengers;
    }>();

    const { messenger } = values;

    const labelPrefix = required ? " *" : "";

    return (
        <Field
            name="messengerLogin"
            type="text"
            label={`${getLabel(messenger?.id)}${labelPrefix}`}
            labelComponent={LabelOneLine}
            validate={messengerLogin =>
                !disabled
                    ? getMessengerLoginValidation(messenger?.id, messengerLogin)
                    : undefined
            }
            disabled={!messenger || disabled}
            placeholder={getPlaceholder(messenger?.id)}
        />
    );
};
