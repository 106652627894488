import { toast } from "react-toastify";
import { ArrowAltCircleDown } from "@styled-icons/fa-regular/ArrowAltCircleDown";
import { FileWrapper, FileInput, FileInputLabel } from "./styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "../../../../../../server/errors/error-type";
import { uploadFile } from "views/manager/advertising/components/add-file";

export const UploadMediaFile = () => {
    const uploadMediaRef = React.useRef<HTMLInputElement>(null);
    const [__] = useTranslation();

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.currentTarget.files) {
            return;
        }

        try {
            await uploadFile(
                "partner/uploadcustomfile",
                event.currentTarget.files[0],
            );
            toast.success(
                __(
                    "Ваш промо-материал успешно загружен и в настоящее время проходит модерацию.",
                ),
            );
            if (uploadMediaRef.current) {
                uploadMediaRef.current.value = "";
            }
        } catch (e: any) {
            void errorHandler(e, error => {
                switch (error.data.code) {
                    case ErrorType.LIMIT_OF_MEDIA_UPLOADS:
                        toast.error(
                            __(
                                "Вы исчерпали дневной лимит загрузки промо-материалов. Повторите попытку завтра.",
                            ),
                        );
                        break;
                    case ErrorType.FILE_ALREADY_EXISTS:
                        toast.error(__("Файл с таким именем уже существует."));
                        break;
                }
            });
        }
    };

    return (
        <FileWrapper>
            <FileInput
                type="file"
                name="file"
                id="file"
                accept="image/jpeg, image/jpg, image/png, image/gif"
                onChange={onChange}
                ref={uploadMediaRef}
            />
            <FileInputLabel htmlFor="file">
                <ArrowAltCircleDown size={30} />
            </FileInputLabel>
        </FileWrapper>
    );
};
