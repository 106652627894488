import { css } from "styled-components";

const fontsPath = "../assets/fonts/halvar-breitschrift/";

export const HalvarBreitschriftFonts = css`
    @font-face {
        font-family: "Halvar Breitschrift";
        src: url("${fontsPath}HalvarBreit-Lt.eot");
        src: url("${fontsPath}HalvarBreit-Lt.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}HalvarBreit-Lt.woff") format("woff"),
            url("${fontsPath}HalvarBreit-Lt.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Halvar Breitschrift";
        src: url("${fontsPath}HalvarBreit-Md.eot");
        src: url("${fontsPath}HalvarBreit-Md.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}HalvarBreit-Md.woff") format("woff"),
            url("${fontsPath}HalvarBreit-Md.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Halvar Breitschrift";
        src: url("${fontsPath}HalvarBreit-XBd.eot");
        src: url("${fontsPath}HalvarBreit-XBd.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}HalvarBreit-XBd.woff") format("woff"),
            url("${fontsPath}HalvarBreit-XBd.ttf") format("truetype");
        font-weight: 800;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Halvar Breitschrift";
        src: url("${fontsPath}HalvarBreit-Bd.eot");
        src: url("${fontsPath}HalvarBreit-Bd.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}HalvarBreit-Bd.woff") format("woff"),
            url("${fontsPath}HalvarBreit-Bd.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Halvar Breitschrift";
        src: url("${fontsPath}HalvarBreit-Rg.eot");
        src: url("${fontsPath}HalvarBreit-Rg.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}HalvarBreit-Rg.woff") format("woff"),
            url("${fontsPath}HalvarBreit-Rg.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;
