import { usePartnersProgramGeneralInformation } from "hooks/use-snippet";
import React from "react";
import { PartnersProgramGeneralInformationContext } from "views";

export const GeneralInfromationContextProvider: React.FC<{
    children?: React.ReactNode | undefined;
}> = ({ children }) => {
    const { data } = usePartnersProgramGeneralInformation();
    // мы не должны ничего отображать, пока не завершиться загрузка GeneralInformation,
    // т.к.она используется на многих страницах.
    // как бы вообще избавиться от этого условия

    if (!data) {
        return null;
    }

    return (
        <PartnersProgramGeneralInformationContext.Provider value={data}>
            {children}
        </PartnersProgramGeneralInformationContext.Provider>
    );
};
