import { useTranslation } from "react-i18next";
import { RouteComponentProps, Switch } from "react-router-dom";
import urljoin from "url-join";
import { PageRoute } from "components/check-roles/guard";
import { ModeratePostback } from "./moderate";
import { PostbacksForm } from "./postbacks-form";

interface IProps extends RouteComponentProps<{ url: string }> {}

export const Postbacks = (props: IProps) => {
    const { url } = props.match;
    const [__] = useTranslation();

    return (
        <Switch>
            <PageRoute
                path={urljoin(url, "/moderate/:id")}
                component={ModeratePostback}
                pageTitle="Postbacks"
                additionalPageTitle={`${__("Модерация")} Postbacks`}
            />
            <PageRoute
                path={urljoin(url, "/")}
                component={PostbacksForm}
                pageTitle={"Postbacks"}
            />
        </Switch>
    );
};
