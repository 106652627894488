import { Field } from "components/formik";
import { usePostbackLists } from "../../hooks/use-postback-lists";
import { useTranslation } from "react-i18next";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    name: string;
    selectFirst?: boolean;
    id: number | null;
}

export const ChildrenField = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { data, loading } = usePostbackLists(props.id);

    return (
        <Field
            name={props.name}
            label={__("Следующий в цепочке")}
            type="react-select"
            options={(data?.children || []).map(
                child =>
                    ({
                        value: child.id,
                        label: child.name,
                    } as any),
            )}
            isLoading={loading}
            isClearable={true}
            selectFirst={props.selectFirst}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
