import { useTranslationList } from "hooks/use-translations-list";
import * as yup from "yup";

export const useValidationSchema = () => {
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();

    const paymentSystem = yup.object({
        id: yup.number().nullable().required(FIELD_SHOULD_BE_FILLED),
        fields: yup.array().of(
            yup
                .object({
                    id: yup.string().required(FIELD_SHOULD_BE_FILLED),
                    value: yup.string(),
                })
                .required(),
        ),
    });

    const validationSchema = yup.object().shape({
        oldPaymentSystem: paymentSystem.nullable(),
        paymentSystem: paymentSystem
            .nullable()
            .required(FIELD_SHOULD_BE_FILLED),
    });

    return validationSchema;
};
