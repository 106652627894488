import { UniversalForm } from "components/filters";
import { AllUniversalOutputValues } from "components/filters/types";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { ReactTable } from "components/react-table";
import { LoadingText } from "components/react-table/loading-text";
import { CellWithButtons } from "components/table-item";
import {
    AddPromocode,
    AddPromocodeVariables,
} from "gql/types/operation-result-types";
import * as _ from "lodash";
import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { MutationFunction } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { successHandler, SuccessTypes } from "services/success-handler";
import { AdminWrapper, AdminWrapperContainer } from "styled/layouts";
import { useAddPropmocode } from "./hooks/use-add-propmocode";
import { usePartnerPromocodes } from "./hooks/use-partner-propmocodes";
import { Title } from "services/styled-components/typogoraphy";
import { useDefinedContext } from "hooks/use-context-exist";
import { IReactTableColumn } from "components/react-table/column/";
import { ErrorType } from "server/errors/error-type";
import { TFunction } from "i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { _1XSLOT_PARTNERS, PARTNERS_1XSLOT } from "server/partner-programs";

const universalFormParams = {
    directLink: true,
};

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Promocodes = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const [promoID, setPromoID] = useState(null);
    useEffect(() => {
        if (promoID) {
            setColumnData({ name: "I", id: promoID, tableName: "promocodes" });
        }
    }, [promoID, setColumnData]);

    const { data, loading } = usePartnerPromocodes();
    const { mutation } = useAddPropmocode();
    const { permissions, partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    const fields: Array<"website" | "currency" | "campaign" | "promoCode"> = [
        "website",
        "currency",
        "campaign",
    ];
    if (permissions && permissions.customPromocode) {
        fields.push("promoCode");
    }

    const columns: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "I",
            disabled: true,
            minWidth: 80,
        },
        {
            Header: __("Сайт"),
            accessor: "S",
            disabled: true,
            minWidth: 200,
        },
        {
            Header: __("Валюта"),
            accessor: "M",
            minWidth: 80,
        },
        {
            Header: __("Промокод"),
            accessor: "PC",
            minWidth: 130,
        },
        {
            Header: "BTAG",
            accessor: "BT",
            Cell: cellInfo => (
                <CellWithButtons link={false} text={cellInfo.value} />
            ),
            minWidth: 200,
        },
    ];

    const aboutBonusConditionsText =
        partnersProgramId === _1XSLOT_PARTNERS ||
        partnersProgramId === PARTNERS_1XSLOT
            ? __(
                  "Увеличение максимального размера приветственного бонуса на первый депозит с 300EUR+30FS до 450EUR+30FS. Если вам нужен промокод на бездепозитные фриспины, пожалуйста, обратитесь к своему менеджеру. Количество промокодов ограничено.",
              )
            : __(
                  "Условия начисления бонусов при регистрации по промо-коду можно уточнить у менеджера.",
              );

    return (
        <div>
            <UniversalForm
                fields={fields}
                params={universalFormParams}
                submitButtonName={__("Сгенерировать промо-код")}
                onSubmit={async submittedValues =>
                    createPromoCode(mutation, submittedValues, setPromoID, __)
                }
            >
                {({ form }) => form}
            </UniversalForm>

            <AdminWrapper>
                <ReactTable
                    tableName="promocodes"
                    defaultSorted={[
                        {
                            id: "I",
                            desc: false,
                        },
                    ]}
                    columns={columns}
                    data={data}
                    loading={loading}
                    loadingText={<LoadingText fastLoading={true} />}
                />
            </AdminWrapper>
            <AdminWrapperContainer>
                <AdminWrapper>
                    <Title>{__("Зачем нужен промо-код?")}</Title>
                    <p>
                        {__(
                            "Пользователь может ввести промо-код при регистрации на сайте, что позволяет автоматически связать его с вами.",
                        )}
                    </p>
                    <p>
                        {__(
                            "В этом случае для нового клиента нет необходимости в переходе на сайт по партнерской ссылке.",
                        )}
                    </p>
                    <Title>{__("Как получить промо-код?")}</Title>
                    <p>
                        {__(
                            "Выберите валюту и кампанию и нажмите «Сгенерировать промо-код». Вы можете сгенерировать несколько промо-кодов. Если вы хотите персональный промо-код — отправьте заявку в службу поддержки.",
                        )}
                    </p>
                </AdminWrapper>
                <AdminWrapper containslist={true}>
                    <Title>{__("Бонус при регистрации по промо-коду")}</Title>
                    <p>{aboutBonusConditionsText}</p>
                    <Title>{__("Преимущества промо-кодов")}</Title>
                    <ul>
                        <li>
                            {__(
                                "промо-код можно использовать там, где нет возможности размещать реферальные ссылки и рекламировать товары/услуги (На фото инстаграм, на видео, в оффлайн рекламе и т.д.)",
                            )}
                        </li>
                        <li>
                            {__(
                                "при регистрации по промо-коду пользователь получает увеличенный бонус, поэтому заинтересован в его использовании.",
                            )}
                        </li>
                        <li>
                            {__(
                                "срок действия промо-кода не ограничен. Привлеченный игрок может передавать его своим друзьям и знакомым. Чем больше игроков, тем выше ваш доход.",
                            )}
                        </li>
                    </ul>
                </AdminWrapper>
            </AdminWrapperContainer>
        </div>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

const createPromoCode = async (
    mutate: MutationFunction<AddPromocode, AddPromocodeVariables>,
    filtres: Pick<
        AllUniversalOutputValues,
        "website" | "currency" | "campaign" | "promoCode"
    >,
    setPromoID: Dispatch<SetStateAction<any>>,
    __: TFunction,
) => {
    try {
        const result = await mutate({
            variables: {
                siteId: filtres.website.id,
                merchantId: _.parseInt(filtres.currency.id),
                campaignId: _.parseInt(filtres.campaign.id),
                promoCode: filtres.promoCode,
            },
        });

        if (result && result.data) {
            const id = g(
                result,
                "data",
                "authorized",
                "partner",
                "addPartnerPromoCode",
                "I",
            );
            setPromoID(id);
            successHandler(SuccessTypes.CREATE_PROMO_CODE, id);
        }
    } catch (e: any) {
        void errorHandler(e, error => {
            switch (error.data.code) {
                case ErrorType.LIMIT_OF_PROMOCODES:
                    return __(
                        "Вы использовали свой лимит промо-кодов. Чтобы запросить дополнительные промо-коды, обратитесь в службу поддержки.",
                    );
                case ErrorType.PROMOCODE_ALREADY_EXISTS:
                    return __("Промокод с таким названием уже создан.");
            }
        });

        throw e;
    }
};
