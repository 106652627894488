import styled from "styled-components";
import { media } from "services/styled-components/variables";

export const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${media.md`
        margin-bottom: 1rem;
        flex-direction: row;

        > div {
            width: calc((100% - 3rem) / 4);
        }
    `}
`;
