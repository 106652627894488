import ReactPlaceholder from "react-placeholder";
import { StyledQRCode } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    text: string;
    scale?: number;
}

export const QrCode = React.memo((props: IProps) => {
    const scale = props.scale || 5;
    return (
        <StyledQRCode scale={scale}>
            <ReactPlaceholder
                ready={!!props.text}
                type="rect"
                showLoadingAnimation={true}
            >
                <canvas
                    ref={elem => {
                        if (elem) {
                            setTimeout(async () => {
                                const opts: any = {
                                    scale,
                                    margin: 0,
                                };
                                (await import("qrcode")).toCanvas(
                                    elem,
                                    props.text,
                                    opts,
                                    e => {
                                        if (e) {
                                            log.error(
                                                "QR code canvas error",
                                                e,
                                            );
                                        }
                                    },
                                );
                            });
                        }
                    }}
                />
            </ReactPlaceholder>
        </StyledQRCode>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
