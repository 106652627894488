import {
    MarketingToolsReportFilter,
    User_user,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { getCommonTableTranslator } from "../../services/get-commot-table-translator";
import { getCurrencyById } from "services/project-utils";

interface ITableData {
    user?: User_user;
    values: MarketingToolsReportFilter;
    reportFormat: string;
    culture: string;
}

export const tableData = (
    { user, values, reportFormat, culture }: ITableData,
    __: TFunction,
) => {
    const {
        endPeriod,
        startPeriod,
        currencyId,
        siteId,
        subId,
        marketingToolId,
        marketingTool,
    } = values;

    if (user) {
        return {
            reportFormat,
            translator: {
                mediaType: {
                    header: __("Тип медиа"),
                    value:
                        (marketingTool && marketingTool.name) ||
                        __("Отсутствует"),
                },
                siteId: {
                    header: __("ID сайта"),
                    value: siteId || __("Отсутствует"),
                },
                mediaId: {
                    header: __("ID медиа"),
                    value: marketingToolId || __("Отсутствует"),
                },
                subId: {
                    header: __("SubId"),
                    value: subId || __("Отсутствует"),
                },
                reportType: __("Oтчет по промоматериалам"),
                ...getCommonTableTranslator(
                    { culture, currencyId, endPeriod, startPeriod, user },
                    __,
                ),
                currency: {
                    header: __("Валюта"),
                    value: getCurrencyById(currencyId),
                },
                columns: {
                    column1: __("ID медиа"),
                    column2: __("Имя промо"),
                    column3: __("ID сайта"),
                    column4: __("Сайт"),
                    column5: __("SubID"),
                    column6: __("Тип медиа"),
                    column7: __("Показы"),
                    column8: __("Клики"),
                    column9: __("Прямые ссылки"),
                    column10: "CTR",
                    column11: __("Регистрации"),
                    column12: __("Соотношение регистрации/клики"),
                    column13: __("Аккаунты с депозитами"),
                    column14: __("Регистрации с депозитом"),
                    column15: __("Новые аккаунты с депозитами"),
                    column16: __(
                        "Соотношение регистрации с депозитом/регистрации",
                    ),
                    column17: __("Сумма депозитов"),
                    column18: __("Сумма бонусов"),
                    column19: __("Доход компании (общий)"),
                    column20: __("RS"),
                    column21: __("CPA"),
                    column22: __(
                        "Коэффициент отношения количества регистраций к установкам",
                    ),
                    /*
                        Тут должно быть еще поле "сумма комиссий", которое присутствует в exel отчете.
                    */
                    column23: __("Уникальные установки"),
                },
            },
        };
    }
};
