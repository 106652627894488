import { Tabs } from "components/tabs";
import { Tab } from "components/tabs/components/tab";
import {
    GetOrdersAndPayments,
    GetOrdersAndPaymentsVariables,
} from "gql/types/operation-result-types";
import * as _ from "lodash";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { ApplicationStatus } from "../pages/application-status";
import { HistoryPayments } from "../pages/history-payment";
import GET_ORDERS_AND_PAYMENTS from "../query.gql";
import { makeUtcCustomPeriod } from "services/project-utils";
import { AllUniversalOutputValues } from "components/filters/types";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    values: Pick<AllUniversalOutputValues, "currency" | "period">;
}

export const ReportTabs = React.memo(({ values }: IProps) => {
    const { currency, period } = values;

    const [__] = useTranslation();

    const { start, end } = makeUtcCustomPeriod(period.start, period.end);

    const queryResult = useQuery<
        GetOrdersAndPayments,
        GetOrdersAndPaymentsVariables
    >(GET_ORDERS_AND_PAYMENTS, {
        variables: {
            filter: {
                currencyId: _.parseInt(currency.id),
                startPeriod: (start && start.toISOString()) || "",
                endPeriod: (end && end.toISOString()) || "",
            },
        },
        skip: !currency,
    });

    return (
        <div>
            <Tabs name="history" default={0}>
                <Tab title={__("Статус заявок")} key={1}>
                    <ApplicationStatus
                        currency={currency}
                        queryResult={queryResult}
                        values={values}
                    />
                </Tab>
                <Tab title={__("История выплат")} key={2}>
                    <HistoryPayments
                        currency={currency}
                        queryResult={queryResult}
                        values={values}
                    />
                </Tab>
            </Tabs>
        </div>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
