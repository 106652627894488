import { UniversalForm } from "components/filters";
import { PartnerListTable } from "./partner-list-table";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { useObjectValidations } from "validation/validaion";
import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetLanguages_languages,
    PartnerListType,
    PartnerStatus,
} from "gql/types/operation-result-types";
import { AllUniversalOutputValues } from "components/filters/types";
import { IBaseFieldProps } from "components/filters/services/get-filter-fields";
import { StatusField } from "./status-field";
interface PartnerListProps {
    partnerListType: PartnerListType;
    choosePartner?: (partnerId: number) => void;
}
export const PartnerList = React.memo(
    ({ partnerListType, choosePartner }: PartnerListProps) => {
        const [__] = useTranslation();
        const { objectRule, enumRule } = useObjectValidations();

        const getFields = (type: PartnerListType) => {
            const baseFields: Array<keyof AllUniversalOutputValues> = [
                "currency",
                "partnersGroup",
                "language",
            ];
            const fields = {
                [PartnerListType.AdvertisingPayments]: baseFields.concat([
                    "commisionGroup",
                    "websiteId",
                    "partnerProgramRegistrationSource",
                ]),
                [PartnerListType.PartnerList]: baseFields.concat([
                    "commisionGroup",
                    "websiteId",
                    "promoCode",
                    "partnerProgramRegistrationSource",
                ]),
                [PartnerListType.FinPlan]: baseFields,
            };

            return fields[type];
        };

        const additionalFields =
            partnerListType === PartnerListType.PartnerList
                ? {
                      partnerStatus: {
                          initialValue: null as PartnerStatus | null,
                          component: (props: IBaseFieldProps) => (
                              <StatusField {...props} />
                          ),
                          validationSchema: enumRule<PartnerStatus>(),
                      },
                  }
                : undefined;

        return (
            <AdminWrapper>
                <UniversalForm
                    fields={getFields(partnerListType)}
                    uniqueValidation={{
                        language: objectRule<GetLanguages_languages>(),
                        currency:
                            objectRule<GetCurrencies_authorized_partnerAndManager_data_currencies>(),
                    }}
                    additionalFields={additionalFields}
                    submitButtonName={__("Поиск")}
                >
                    {({ form, actualValues, submittedValues }) => {
                        return (
                            <>
                                {form}
                                <FirstLoadTable
                                    actualValues={actualValues}
                                    submittedValues={submittedValues}
                                >
                                    {values => {
                                        return (
                                            <PartnerListTable
                                                values={{
                                                    ...values,
                                                    ...(partnerListType ===
                                                        PartnerListType.AdvertisingPayments && {
                                                        partnerStatus:
                                                            PartnerStatus.ACTIVE,
                                                    }),
                                                }}
                                                partnerListType={
                                                    partnerListType
                                                }
                                                choosePartner={choosePartner}
                                            />
                                        );
                                    }}
                                </FirstLoadTable>
                            </>
                        );
                    }}
                </UniversalForm>
            </AdminWrapper>
        );
    },
);
