import { PageRoute } from "components/check-roles/guard";
import { NotFoundHandler } from "components/not-found-handler";
import { useTranslation } from "react-i18next";
import { Switch, useRouteMatch } from "react-router-dom";
import urljoin from "url-join";
import { Home } from "./main/home";
import { ScrollToTop } from "components/scroll-to-top";
import { useUser } from "hooks/query/use-user";
import { adAgent, manager } from "components/check-roles/rules";
import { LocalizedRedirect } from "components/language-provider";
import { Loader } from "components/components-common/loaders/cub";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { Reports } from "./reports";
import { Marketing } from "./marketing";
import { Advertising } from "./advertising";
import { Main } from "./main";
import { Content } from "./settings/content";
import { UserList } from "./settings/user-list";
import { Payments } from "./payments";
import { PlayerActivityReport } from "./reports/player-activity-report";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const ManagerPartition = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { url } = useRouteMatch();
    const { stylesTheme } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const { user, loading } = useUser(true);
    if (loading) {
        return (
            <Loader
                cubesColor={stylesTheme.accentColor}
                bgColor={stylesTheme.backgroundColor}
            />
        );
    }
    if (!user || !(adAgent({ user }) || manager({ user }))) {
        return <LocalizedRedirect to="/" />;
    }

    return (
        <ScrollToTop>
            <Switch>
                <PageRoute
                    path={urljoin(url)}
                    component={Home}
                    pageTitle={__("Главная")}
                />
                <PageRoute
                    path={urljoin(url, "/advertising")}
                    exact={false}
                    component={Advertising}
                />
                <PageRoute
                    path={urljoin(url, "reports/player-activity")}
                    component={PlayerActivityReport}
                    pageTitle={__("Отчет активности")}
                />

                {(__ENVIRONMENT__.production &&
                    user?.id === "76cb7535-046f-4c2c-a9ad-75fa85768ede") ||
                    (!__ENVIRONMENT__.production && (
                        <>
                            <PageRoute
                                exact={false}
                                path={urljoin(url, "reports")}
                                component={Reports}
                            />
                            <PageRoute
                                exact={false}
                                path={urljoin(url, "main")}
                                component={Main}
                            />
                            <PageRoute
                                exact={false}
                                path={urljoin(url, "marketing")}
                                component={Marketing}
                            />
                            <PageRoute
                                exact={false}
                                path={urljoin(url, "payments")}
                                component={Payments}
                            />
                            <PageRoute
                                path={urljoin(url, "settings/content")}
                                component={Content}
                                pageTitle={__("Редактировать тексты")}
                            />
                            <PageRoute
                                path={urljoin(url, "settings/user-list")}
                                component={UserList}
                                pageTitle={__("Список пользователей")}
                            />
                        </>
                    ))}

                <PageRoute component={NotFoundHandler} path="*" />
            </Switch>
        </ScrollToTop>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
