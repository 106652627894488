import {
    CreatePostback,
    CreatePostbackVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import CREATE_POSTBACK from "../gql/create.gql";
import POSTBACKS from "../gql/postbacks.gql";

export const useCreate = () => {
    const [createPostbackMutation] = useMutation<
        CreatePostback,
        CreatePostbackVariables
    >(CREATE_POSTBACK, {
        refetchQueries: [{ query: POSTBACKS }],
        awaitRefetchQueries: true,
    });

    // Вытаскивает id из результата запроса
    const createPostback = async (variables: CreatePostbackVariables) => {
        const { data } = await createPostbackMutation({
            variables,
        });
        const id = data?.authorized.partner?.postback.createPostback;
        return id;
    };

    return { createPostback };
};
