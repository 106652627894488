import { GetCommissionList_authorized_partner_comissionStructures_description } from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import moment from "moment";
import { getCpaDescription } from "./comission-descriptions/cpa";
import { getRefDescription } from "./comission-descriptions/referal";
import { getRevenueDescription } from "./comission-descriptions/revenue";
import { Variables } from "services/variables";

const getComissionDescription = (
    structureList: IDescriptionFoo,
    __: TFunction,
) => {
    switch (structureList.comissionStructureType) {
        case "Refferal":
            return getRefDescription(structureList, __);
        case "Revenue Share":
            return getRevenueDescription(structureList, __);
        case "CPA":
            return getCpaDescription(structureList, __);
        default:
            return null;
    }
};

const transFormDate = (transformDate: string | null, dateWithTime = false) => {
    if (!transformDate) {
        return null;
    }
    const momentDate = moment(transformDate);

    if (momentDate.year() === 1) {
        log.error(transformDate, "api returned invalid date");
        // иногда апи возращает "0001-01-01T00:00:00"
        // это инвалидная дата. На её месте АПИ должен возращать null.
        // Ждём когда АПИ это поправит и затем сносим этот код.
        return null;
    }

    return momentDate.format(
        dateWithTime
            ? Variables.dateFormat.minutes
            : Variables.dateFormat.dateOnly,
    );
};

const getDescriptionDate = (
    dateStart: string | null,
    dateEnd: string | null,
) => {
    return `(${dateStart ? transFormDate(dateStart) : ""}${
        dateEnd ? ` - ${transFormDate(dateEnd)}` : ""
    })`;
};

export interface IDescriptionFoo {
    description: GetCommissionList_authorized_partner_comissionStructures_description;
    comissionStructureType: "Refferal" | "Revenue Share" | "CPA";
}

export { transFormDate, getComissionDescription, getDescriptionDate };
