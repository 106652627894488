import { SignOutAlt } from "@styled-icons/fa-solid/SignOutAlt/SignOutAlt";
import { Utils } from "@webx/utils";
import SIGN_OUT_BACK_TO_MANAGER from "gql/mutations/sign-out-back-to-manager.gql";
import { SignOutBackToManager } from "gql/types/operation-result-types";
import { useMutation, MutationFunction } from "@apollo/client";
import { errorHandler } from "services/error-handler";
import { addressOfPartnersv1 } from "../profile-helpers";
import { useTranslation } from "react-i18next";

interface IProps {
    className?: string;
}

export const SignOutManager = (props: IProps) => {
    const [signOutBackToManager] = useMutation<SignOutBackToManager>(
        SIGN_OUT_BACK_TO_MANAGER,
    );
    const [__] = useTranslation();
    return (
        <span
            className={props.className}
            onClick={onSignOutBackToManager(signOutBackToManager)}
        >
            <SignOutAlt size={16} />
            {__("Выйти из партнерского интерфейса")}
        </span>
    );
};

const onSignOutBackToManager =
    (mutate: MutationFunction<SignOutBackToManager>) => async () => {
        try {
            await mutate();
            if (Utils.browser.hasWindow()) {
                window.location.assign(`${addressOfPartnersv1()}/partners`);
            }
        } catch (e: any) {
            void errorHandler(e);
        }
    };
