import { AdvertisementStatus } from "gql/types/operation-result-types";

export interface ShowEditButtonProps {
    statusName: AdvertisementStatus;
}

export const showEditButton = ({ statusName }: ShowEditButtonProps) => {
    return (
        statusName === AdvertisementStatus.NEW ||
        statusName === AdvertisementStatus.CREATED ||
        statusName === AdvertisementStatus.NEEDS_IMPROVEMENT
    );
};
