import { INotificationContentProps } from "../../.";
import { Img } from "../../banner/styled";
import { BannerPopupData } from "../../banner/banner-popup-data";

interface IProps extends INotificationContentProps {
    data: BannerPopupData;
}

export const PartnerBanner = ({ data }: IProps) => {
    const countryCode = data.data.currentGeoCountryCode || "";

    return (
        <Img
            src={`assets/images/banner-modal/4_1/${countryCode}_october_challenge.jpg`}
            loading="lazy"
        />
    );
};
