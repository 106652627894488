const closestTag = {
    "{": "}",
    "[": "]",
};

export const Tag = ({
    tag,
    children,
    tabs,
}: {
    tag: "{" | "[";
    tabs: number;
    children: React.ReactNode;
}) => {
    return (
        <>
            <span className="open-tag">{tag}</span>
            <div
                style={{
                    paddingLeft: `${tabs * 15}px`,
                }}
            >
                {children}
            </div>
            <span>{closestTag[tag]}</span>
        </>
    );
};
