import { User } from "@styled-icons/fa-solid/User/User";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useTranslation } from "react-i18next";

interface IProps {
    className?: string;
}

export const LinkToUserProfile = (props: IProps) => {
    const [__] = useTranslation();
    return (
        <LocalizedNavLinkWithOptionalBackground
            className={props.className}
            exact={true}
            to="/partner/user-profile"
        >
            <User size={16} /> {__("Профиль")}
        </LocalizedNavLinkWithOptionalBackground>
    );
};
