import styled, { css } from "styled-components";
import { successColor } from "./themes";
import { hiddenFunc, media } from "./variables";

export const Typogoraphy = css`
    html {
        font-size: 1.8vmin;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: 0;
    }

    h1 {
        font-size: 1.5em;
        padding: 1.45833em 0;
    }

    h2 {
        font-size: 1.33333em;
        padding: 1.25em 0;
    }

    h3 {
        font-size: 1.16667em;
        padding: 1.51786em 0;
    }

    h4,
    h5,
    h6 {
        font-size: 1.16667em;
        padding: 1.51786em 0;
    }

    p {
        margin-bottom: 1em;
        a,
        a:active,
        a:link,
        a:visited {
            color: ${props => props.theme.activeColor};
            &:hover {
                text-decoration: underline;
            }
        }
    }
`;
export const internalPages = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 1.17em;
        font-weight: 700;
        padding-top: 1em;
        padding-bottom: 1em;
    }
    h1:first-child {
        font-size: 2em;
    }

    b,
    strong {
        font-weight: bolder;
    }
    i,
    cite,
    em,
    var,
    address,
    dfn {
        font-style: italic;
    }
    ul,
    ol {
        padding: 0 0 0 1em;
        margin: 0 0 1em 1.2em;
    }
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
    /* stylelint-disable */
    a {
        color: ${props => props.theme.accentColor};
        text-decoration: underline;
    }
    font[color] a {
        color: inherit;
    }
`;
export type Align = "center" | "right" | "left";

interface IProps {
    align?: Align;
    inOneLine?: boolean;
}

const getAlign = (props: IProps) => {
    if (props.align) {
        return `text-align: ${props.align}`;
    }
};

// нужен для тектовых надписей (например в формах), но не для филдов форм
export const Paragraph = styled.div<IProps>`
    margin-bottom: 1em;
    ${props => getAlign(props)};
    ${props =>
        props.inOneLine &&
        css`
            ${media.smMax`
                > * {
                    margin-bottom: 1em !important;
                    &:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            `}
            ${media.sm`
                display: flex;
                > * {
                    margin-left: 1em !important;
                    &:first-child {
                        margin-left: 0 !important;
                    }
                }
            `}
        `};
`;

export const SpanSC = styled.span`
    ${hiddenFunc}
`;
export const InlineBlock = styled.div`
    ${hiddenFunc}
    display: inline-block;
    > span {
        padding: 0.875em 1px;
        display: inline-block;
        vertical-align: bottom;
    }
`;
export const Special = styled.span`
    color: ${successColor};
`;
export const Title = styled.div`
    font-size: 1.25em;
    color: ${props => props.theme.adminTitleColor};
    display: flex;
    flex-direction: column;
    line-height: 1.2;
    margin-bottom: 0.625em;
`;

export const Ul = styled.ul`
    list-style-type: disc;
    list-style-position: inside;
`;

export const lineClamp = (lines: number) => css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
    overflow: hidden;
`;
