import { useTranslation } from "react-i18next";
import { Paragraph } from "services/styled-components/typogoraphy";
import { BtnColor, ButtonColored } from "styled/button";
import { Modal } from "components/components-common/modal";
import { FormDirectionRow, FormGroupButton, FormGroup } from "styled/layouts";
import { useModalState } from "hooks/use-modal-state";
import { Field } from "components/formik";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form } from "components/formik";
import { useState } from "react";
import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { SubmitValuesWithReset } from "../../payment-plans-operations/payment-plan-form";

interface IProps {
    modalProps: ReturnType<typeof useModalState>["modalProps"];
    close: () => void;
    onSubmit: ({
        submittedPaymentPlanValues,
        resetForm,
    }: SubmitValuesWithReset) => Promise<void>;
}

function createSubmittedValues<T>(submittedValues: T) {
    let state = submittedValues;

    const setValues = (values: T) => {
        state = values;
    };

    const getValues = (): T => {
        return state;
    };

    return { setValues, getValues };
}

export const submitValues = createSubmittedValues({} as SubmitValuesWithReset);

export const AddOrEditPaymentPlanModal = ({
    modalProps,
    close,
    onSubmit,
}: IProps) => {
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const [isDisabledReason, setIsDisabledReason] = useState(false);

    return (
        <Modal {...modalProps}>
            <Paragraph>
                {__("Сумма платежей превышает сумму контракта.")}
            </Paragraph>
            <Paragraph>{__("Внести изменения в план?")}</Paragraph>
            {isDisabledReason ? (
                <FormikConsoleErrors
                    initialValues={{ reason: "" }}
                    validationSchema={() =>
                        yup.object().shape({
                            reason: yup
                                .string()
                                .required(FIELD_SHOULD_BE_FILLED),
                        })
                    }
                    onSubmit={async values => {
                        const { submittedPaymentPlanValues, resetForm } =
                            submitValues.getValues();
                        const valuesWithReason = {
                            ...submittedPaymentPlanValues,
                            reason: values.reason,
                        };

                        void onSubmit({
                            submittedPaymentPlanValues: valuesWithReason,
                            resetForm,
                        });
                        close();
                    }}
                >
                    <Form>
                        <FormGroup>
                            <Field
                                name="reason"
                                type="text"
                                label={__("Причина")}
                            />
                        </FormGroup>
                        <FormDirectionRow>
                            <FormGroupButton>
                                <SubmitButton>{__("Сохранить")}</SubmitButton>
                            </FormGroupButton>
                            <FormGroupButton>
                                <ButtonColored
                                    btnColor={BtnColor.Faded}
                                    onClick={() => {
                                        close();
                                        setIsDisabledReason(false);
                                    }}
                                    type="button"
                                >
                                    {__("Отмена")}
                                </ButtonColored>
                            </FormGroupButton>
                        </FormDirectionRow>
                    </Form>
                </FormikConsoleErrors>
            ) : (
                <FormDirectionRow>
                    <FormGroupButton>
                        <ButtonColored
                            type="button"
                            onClick={() => setIsDisabledReason(true)}
                        >
                            {__("Да")}
                        </ButtonColored>
                    </FormGroupButton>
                    <FormGroupButton>
                        <ButtonColored
                            btnColor={BtnColor.Faded}
                            onClick={close}
                            type="button"
                        >
                            {__("Нет")}
                        </ButtonColored>
                    </FormGroupButton>
                </FormDirectionRow>
            )}
        </Modal>
    );
};
