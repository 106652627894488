import React from "react";
import { Roller } from "components/loaders/roller";
import { ExportVariant } from "../../../styled";
import { ButtonFlex } from "components/react-table/table-select-variables/export/components/button-flex";

interface IProps {
    exportData: () => void;
    saving: boolean;
    caption: string;
}

export const ExportButton = React.memo((props: IProps) => {
    const { exportData, saving, caption } = props;

    return (
        <ExportVariant>
            <ButtonFlex disabled={saving} onClick={exportData} type="button">
                <>
                    {caption}
                    {saving && <Roller />}
                </>
            </ButtonFlex>
        </ExportVariant>
    );
});
