import { css } from "styled-components";
import flagsSpriteSrc from "./images/custom-flags/flags-sprite.svg";

export const CustomLanguage = css`
    white-space: nowrap;

    &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        background-image: url("${flagsSpriteSrc}");
        background-repeat: no-repeat;
    }

    &[data-country="FI"]::before {
        background-position: -360px -24px;
    }

    &[data-country="FR"]::before {
        background-position: -388px -24px;
    }

    &[data-country="HR"]::before {
        background-position: -500px -24px;
    }

    &[data-country="JP"]::before {
        background-position: -640px -24px;
    }

    &[data-country="RU"]::before {
        background-position: -220px -52px;
    }

    &[data-country="UA"]::before {
        background-position: -416px -52px;
    }

    &[data-country="AZ"]::before {
        background-position: -52px -24px;
    }

    &[data-country="BG"]::before {
        background-position: -108px -24px;
    }

    &[data-country="BR"]::before {
        background-position: -136px -24px;
    }

    &[data-country="HU"]::before {
        background-position: -528px -24px;
    }

    &[data-country="DE"]::before {
        background-position: -220px -24px;
    }

    &[data-country="TR"]::before {
        background-position: -360px -52px;
    }

    &[data-country="ID"]::before {
        background-position: -556px -24px;
    }

    &[data-country="ES"]::before {
        background-position: -304px -24px;
    }

    &[data-country="IT"]::before {
        background-position: -612px -24px;
    }

    &[data-country="CN"]::before {
        background-position: -164px -24px;
    }

    &[data-country="LV"]::before {
        background-position: -780px -24px;
    }

    &[data-country="LT"]::before {
        background-position: -752px -24px;
    }

    &[data-country="MK"]::before {
        background-position: -808px -24px;
    }

    &[data-country="MN"]::before {
        background-position: -864px -24px;
    }

    &[data-country="NL"]::before {
        background-position: -24px -52px;
    }

    &[data-country="PL"]::before {
        background-position: -108px -52px;
    }

    &[data-country="PT"]::before {
        background-position: -136px -52px;
    }

    &[data-country="RO"]::before {
        background-position: -164px -52px;
    }

    &[data-country="SK"]::before {
        background-position: -276px -52px;
    }

    &[data-country="TW"]::before {
        background-position: -388px -52px;
    }

    &[data-country="TH"]::before {
        background-position: -332px -52px;
    }

    &[data-country="UZ"]::before {
        background-position: -472px -52px;
    }

    &[data-country="MY"]::before {
        background-position: -892px -24px;
    }

    &[data-country="GB"]::before {
        background-position: -276px -24px;
    }

    &[data-country="VN"]::before {
        background-position: -500px -52px;
    }

    &[data-country="DK"]::before {
        background-position: -248px -24px;
    }

    &[data-country="EE"]::before {
        background-position: -332px -24px;
    }

    &[data-country="NO"]::before {
        background-position: -52px -52px;
    }

    &[data-country="SE"]::before {
        background-position: -248px -52px;
    }

    &[data-country="CZ"]::before {
        background-position: -192px -24px;
    }

    &[data-country="GR"]::before {
        background-position: -416px -24px;
    }

    &[data-country="IL"]::before {
        background-position: -444px -24px;
    }

    &[data-country="AE"]::before {
        background-position: -24px -24px;
    }

    &[data-country="PK"]::before {
        background-position: -444px -52px;
    }

    &[data-country="IR"]::before {
        background-position: -584px -24px;
    }

    &[data-country="IN"]::before {
        background-position: -472px -24px;
    }

    &[data-country="BD"]::before {
        background-position: -80px -24px;
    }

    &[data-country="KH"]::before {
        background-position: -668px -24px;
    }

    &[data-country="KR"]::before {
        background-position: -696px -24px;
    }

    &[data-country="RS"]::before {
        background-position: -192px -52px;
    }

    &[data-country="KZ"]::before {
        background-position: -724px -24px;
    }

    &[data-country="MM"]::before {
        background-position: -836px -24px;
    }

    &[data-country="PH"]::before {
        background-position: -80px -52px;
    }

    &[data-country="SO"]::before {
        background-position: -304px -52px;
    }

    &[data-country="KG"]::before {
        background-position: -556px -52px;
    }

    &[data-country="TJ"]::before {
        background-position: -584px -24px;
    }
`;
