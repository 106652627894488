import _ from "lodash";
import { GetLinks } from "gql/types/operation-result-types";
import { useGetSites } from "hooks/query/use-get-sites";
import { useQuery } from "@apollo/client";
import GET_PARTNER_LINKS from "../gql/get-partner-links.gql";

export const usePartnerLinks = (hidden: boolean) => {
    const queryResult = useQuery<GetLinks>(GET_PARTNER_LINKS, {
        variables: { hidden },
        ssr: true,
    });

    const { sitesList: sites } = useGetSites();

    let links = g(
        queryResult.data,
        "authorized",
        "partner",
        "partnerLinks",
        "getPartnerLinks",
    );

    // скрыть ссылки, сайты которых - скрыты
    if (sites && links && !hidden) {
        // сгруппировать сайты и ссылки
        const linksSitesGroups = _.groupBy(links, "siteId");
        const linksAndSites = _.map(sites, site => ({
            site,
            links: linksSitesGroups[site.id] || [],
        }));
        // заменить ссылки на ссылки с измененной видимостью
        _.forEach(linksAndSites, siteAndLinks => {
            if (siteAndLinks.site.hidden && siteAndLinks.links) {
                siteAndLinks.links = _.cloneDeep(siteAndLinks.links);
                siteAndLinks.links.forEach(link => {
                    link.hidden = true;
                });
            }
        });
        // разгрупировать обратно в сайты
        links = _.flatten(_.map(linksAndSites, lnkSts => lnkSts.links));
    }

    links = _.filter(links, link => link.hidden === hidden);

    return { data: links, loading: queryResult.loading, queryResult };
};
