import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { useMutation } from "@apollo/client";
import { BtnColor, ButtonColored } from "styled/button";
import { Modal } from "components/components-common/modal";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { useModalState } from "hooks/use-modal-state";
import { errorHandler } from "services/error-handler";
import { toast } from "react-toastify";
import { ErrorType } from "server/errors/error-type";
import RESET_PARTNER_PASSWORD from "./reset-partner-password.gql";
import { useTranslationList } from "hooks/use-translations-list";
import {
    ResetPartnerPassword,
    ResetPartnerPasswordVariables,
} from "gql/types/operation-result-types";

interface IProps {
    modalProps: ReturnType<typeof useModalState>["modalProps"];
    close: () => void;
    partnerId: number;
}

export const ResetPasswordWithModal = ({
    modalProps,
    close,
    partnerId,
}: IProps) => {
    const [__] = useTranslation();
    const {
        PASSWORD_RESET_MESSAGE_SENT,
        PASSWORD_RESET_MESSAGE_NO_SENT,
        FAILED_RESET_PASSWORD,
    } = useTranslationList();

    const [mutation, { loading }] = useMutation<
        ResetPartnerPassword,
        ResetPartnerPasswordVariables
    >(RESET_PARTNER_PASSWORD);

    return (
        <Modal {...modalProps}>
            <Title>{__("Вы уверены, что хотите сбросить пароль ?")}</Title>
            <FormDirectionRow>
                <FormGroupButton>
                    <ButtonColored
                        type="button"
                        disabled={loading}
                        onClick={async () => {
                            try {
                                await mutation({
                                    variables: {
                                        partnerId,
                                    },
                                });
                                close();
                                toast.success(PASSWORD_RESET_MESSAGE_SENT());
                            } catch (ex: any) {
                                void errorHandler(ex, error => {
                                    switch (error.data.code) {
                                        case ErrorType.PASSWORD_RESET_MESSAGE_NO_SEND:
                                            toast.error(
                                                PASSWORD_RESET_MESSAGE_NO_SENT(),
                                            );
                                            break;
                                        case ErrorType.PASSWORD_RESET_ERROR:
                                            toast.error(
                                                FAILED_RESET_PASSWORD(),
                                            );
                                            break;
                                    }
                                });
                            }
                        }}
                    >
                        OK
                    </ButtonColored>
                </FormGroupButton>
                <FormGroupButton>
                    <ButtonColored
                        btnColor={BtnColor.Faded}
                        onClick={close}
                        type="button"
                    >
                        {__("Отмена")}
                    </ButtonColored>
                </FormGroupButton>
            </FormDirectionRow>
        </Modal>
    );
};
