import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import { CurrencyCell } from "components/react-table/currency-cell";

export const useStatisticColumns = () => {
    const [__] = useTranslation();

    const columns: IReactTableColumn[] = [
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            disabled: true,
            minWidth: 80,
        },
        {
            Header: __("Показы"),
            minWidth: 90,
            accessor: "countOfViews",
            disabled: true,
        },
        {
            Header: __("Клики"),
            minWidth: 90,
            accessor: "countOfClicks",
        },
        {
            Header: __("Прямые ссылки"),
            accessor: "countOfDirectLinks",
            minWidth: 90,
        },
        {
            Header: __("Регистрации"),
            accessor: "countOfRegistrations",
            minWidth: 90,
        },
        {
            Header: __("Новые аккаунты с депозитами"),
            accessor: "countOfNewDepositors",
            minWidth: 90,
        },
        {
            Header: __("Доход компании (общий)"),
            accessor: "profit",
            minWidth: 90,
            Cell: ({ original }: any) => (
                <CurrencyCell
                    value={original.profit}
                    currency={original.currencyName}
                />
            ),
        },
        {
            Header: __("RS"),
            accessor: "comissionRS",
            minWidth: 90,
            Cell: ({ original }: any) => (
                <CurrencyCell
                    value={original.comissionRS}
                    currency={original.currencyName}
                />
            ),
        },
        {
            Header: __("CPA"),
            accessor: "comissionCPA",
            minWidth: 90,
            Cell: ({ original }: any) => (
                <CurrencyCell
                    value={original.comissionCPA}
                    currency={original.currencyName}
                />
            ),
        },
        {
            Header: __("Сумма комиссий"),
            accessor: "comissionAmount",
            minWidth: 90,
            Cell: ({ original }: any) => (
                <CurrencyCell
                    value={original.comissionAmount}
                    currency={original.currencyName}
                />
            ),
        },
    ];
    return columns;
};
