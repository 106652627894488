import { LabelComponent } from "components/formik/universal-field";
import { Field, FormikHandlers } from "components/formik";
import { useCountriesWithTranslations } from "hooks/query/query-with-translations/use-countries-with-translations";
import { useTranslation } from "react-i18next";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

interface IInjectedProps {}

interface IProps extends IInjectedProps, ISelectFild {
    labelComponent?: LabelComponent;
    fieldOnChange?: FormikHandlers["handleChange"];
    validate?: (value: any) => string | Promise<any> | undefined;
    isDisabled?: boolean;
}

export const CountryField = React.memo((props: IProps) => {
    const { name, label, ...rest } = props;
    const [__] = useTranslation();
    const { countries, loading } = useCountriesWithTranslations();

    useIsSelectOptionsLoading(name, loading, countries, props.loadingContext);

    return (
        <Field
            name={name}
            label={label || __("Страна")}
            labelComponent={props.labelComponent}
            type="react-select"
            options={countries}
            isLoading={loading}
            {...rest}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
