import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions } from "gql/types/operation-result-types";
import { AdvertisementStatus, Rights } from "gql/types/operation-result-types";
import { AdversitingAction } from "views/manager/advertising/adversiting-payments/view-edit-steps";
import { StatusField } from "../../../status-field";

interface IProps {
    initialStatus?: AdvertisementStatus | null;
    disabled: boolean;
    adversitingAction?: AdversitingAction;
}

export const StatusFormField = (props: IProps) => {
    function getSpecilaOrderOptions(
        permissions: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions,
    ) {
        if (props.adversitingAction === AdversitingAction.View) {
            return [];
        }

        if (
            props.initialStatus === AdvertisementStatus.NEW &&
            permissions.rights.includes(Rights.CREATE_ADVERSITING_PAYMENT)
        ) {
            return [AdvertisementStatus.NEW, AdvertisementStatus.CREATED];
        } else if (props.initialStatus === AdvertisementStatus.CREATED) {
            if (permissions.rights.includes(Rights.EDIT_ADVERSITING_PAYMENT)) {
                return [
                    AdvertisementStatus.APPROVED,
                    AdvertisementStatus.NEEDS_IMPROVEMENT,
                    AdvertisementStatus.ARCHIVE,
                ];
            } else {
                // permissions.rights.includes(Rights.CREATE_ADVERSITING_PAYMENT)
                return [AdvertisementStatus.ARCHIVE];
            }
        } else {
            // props.initialStatus === AdvertisementStatus.NEEDS_IMPROVEMENT
            if (permissions.rights.includes(Rights.EDIT_ADVERSITING_PAYMENT)) {
                return [
                    AdvertisementStatus.APPROVED,
                    AdvertisementStatus.ARCHIVE,
                    AdvertisementStatus.CREATED,
                ];
            } else {
                // permissions.rights.includes(Rights.CREATE_ADVERSITING_PAYMENT)
                return [AdvertisementStatus.CREATED];
            }
        }
    }

    return (
        <StatusField
            name="statusAdvertisingPayments"
            disabled={props.disabled}
            isClearable={false}
            forChangingStatus
            initialStatus={props.initialStatus}
            getSpecilaOrderOptions={getSpecilaOrderOptions}
        />
    );
};
