import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import { errorHandler } from "services/error-handler";
import { useMutation } from "@apollo/client";
import {
    GenerateAdvertisingPaymentsFromFile,
    GenerateAdvertisingPaymentsFromFileVariables,
} from "gql/types/operation-result-types";
import { BtnColor, ButtonColored } from "styled/button";
import { Modal } from "components/components-common/modal";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import GENERATE_ADVERTISING_PAYMENTS_FROM_FILE from "./mutation.gql";
import { useModalState } from "hooks/use-modal-state";

interface IProps {
    modalProps: ReturnType<typeof useModalState>["modalProps"];
    close: () => void;
    fileId: number;
}

export const GeneratePaymentsWithModal = ({
    modalProps,
    close,
    fileId,
}: IProps) => {
    const [__] = useTranslation();
    const history = useHistory();

    const [mutation] = useMutation<
        GenerateAdvertisingPaymentsFromFile,
        GenerateAdvertisingPaymentsFromFileVariables
    >(GENERATE_ADVERTISING_PAYMENTS_FROM_FILE);

    return (
        <Modal {...modalProps}>
            <Title>{__("Создать рекламные выплаты?")}</Title>
            <Paragraph>
                {__("Платежи, содержащие ошибки не будут созданы")}
            </Paragraph>
            <FormDirectionRow>
                <FormGroupButton>
                    <ButtonColored
                        type="button"
                        onClick={async () => {
                            try {
                                await mutation({
                                    variables: {
                                        fileId,
                                    },
                                });
                                close();
                                history.goBack();
                            } catch (ex: any) {
                                void errorHandler(ex);
                            }
                        }}
                    >
                        OK
                    </ButtonColored>
                </FormGroupButton>
                <FormGroupButton>
                    <ButtonColored
                        btnColor={BtnColor.Faded}
                        onClick={close}
                        type="button"
                    >
                        {__("Отмена")}
                    </ButtonColored>
                </FormGroupButton>
            </FormDirectionRow>
        </Modal>
    );
};
