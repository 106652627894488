import { UniversalForm } from "components/filters";
import { useTranslation } from "react-i18next";
import { AdvertisingPaymentsReportTable } from "./report-table";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import {
    IBaseFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";
import { Period } from "components/filters/fields/period";
import { useObjectValidations } from "validation/validaion";
import * as moment from "moment";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { AdvertisementStatus, Rights } from "gql/types/operation-result-types";
import { StatusFilterField } from "./report-table/status-filter-field";
import { AdvertisingPayment } from "./report-table/report-table-columns";

interface AdvertisingPaymentsProps {
    onChoosePayment?: (advPayment: AdvertisingPayment) => void;
}

export const AdvertisingPayments = ({
    onChoosePayment,
}: AdvertisingPaymentsProps) => {
    const [__] = useTranslation();
    const { objectRequiredRule, enumRule } = useObjectValidations();
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    if (!permissions) {
        return <></>;
    }
    let initialPeriod = {
        start: moment().startOf("day"),
        end: moment().endOf("day"),
    };
    if (
        (permissions.rights.includes(Rights.CREATE_ADVERSITING_PAYMENT) &&
            permissions.rights.includes(Rights.EDIT_ADVERSITING_PAYMENT)) ||
        permissions.rights.includes(Rights.EDIT_ADVERSITING_PAYMENT)
    ) {
        initialPeriod = {
            start: moment().subtract(1, "month").startOf("day"),
            end: moment().endOf("day"),
        };
    }

    return (
        <UniversalForm
            fields={["whoCreated", "currencyAdversitingPayments"]}
            additionalFields={{
                statusAdvertisingPayments: {
                    initialValue: null as AdvertisementStatus | null,
                    component: (props: IBaseFieldProps) => (
                        <StatusFilterField {...props} />
                    ),
                    validationSchema: enumRule<AdvertisementStatus>(),
                },
                periodCustom: {
                    initialValue: {
                        interval: "CUSTOM",
                        ...initialPeriod,
                    },
                    component: (props: IBaseFieldProps) => (
                        <Period {...selectFieldsProps(props)} />
                    ),
                    validationSchema: objectRequiredRule<{
                        start: import("moment").Moment | null;
                        end: import("moment").Moment | null;
                    }>(),
                },
            }}
            submitButtonName={__("Поиск")}
        >
            {({ form, submittedValues, actualValues }) => {
                return (
                    <>
                        {form}
                        <FirstLoadTable
                            actualValues={actualValues}
                            submittedValues={submittedValues}
                        >
                            {values => (
                                <AdvertisingPaymentsReportTable
                                    submittedValues={values}
                                    onChoosePayment={onChoosePayment}
                                />
                            )}
                        </FirstLoadTable>
                    </>
                );
            }}
        </UniversalForm>
    );
};
