import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { FormGroup } from "styled/layouts";

export const PostbackColumn = styled(FormGroup)`
    gap: 16px;

    ${media.sm`
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    `}
`;
