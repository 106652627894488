import { defaultDataIdFromObject, InMemoryCache } from "@apollo/client";

export const createApolloMemoryCache = () =>
    new InMemoryCache({
        dataIdFromObject: result => {
            const { __typename, Id } = result;

            if (__typename && Id !== undefined) {
                return `${__typename}:${Id}`;
            }

            return defaultDataIdFromObject(result);
        },
        possibleTypes: {
            Description: [
                "RevenueShareDescription",
                "CPADescription",
                "RefDescription",
            ],
        },
        typePolicies: {
            PartnersProgramQuery: {
                merge: true,
            },
            NewsQuery: {
                merge: true,
            },
            AuthorizedQuery: {
                merge: true,
            },
            PartnerQuery: {
                merge: true,
            },
            PartnerDataType: {
                merge: true,
            },
            MainPartnerStatistic: {
                merge: true,
            },
            PartnerLinksQuery: {
                merge: true,
            },
            PartnerReportsQuery: {
                merge: true,
            },
            PartnerAccountQuery: {
                merge: true,
            },
            ManagerQuery: {
                merge: true,
            },
            ManagerDataType: {
                merge: true,
            },
            ManagerMainQuery: {
                merge: true,
            },
            MainManagerStatistic: {
                merge: true,
            },
            ManagerReportsQuery: {
                merge: true,
            },
            ManagerAdvertisingQuery: {
                merge: true,
            },
            ManagerAdvertisingPayments: {
                merge: true,
            },
            ManagerFinantialPlans: {
                merge: true,
            },
            PartnerAndManagerDataType: {
                merge: true,
            },
            ManagerPaymentsQuery: {
                merge: true,
            },
        },
    });
