import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocalizedAbsolutePush } from "hooks/use-localised-absolute-push";
import { getCurrencyById } from "services/project-utils";
import { FormGroup, FormGroupButton } from "styled/layouts";
import { Field, Form } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { useTranslationList } from "hooks/use-translations-list";
import {
    EditPartnerGroup as IEditPartnerGroup,
    EditPartnerGroupVariables,
    PartnerGroup,
    PartnerGroupVariables,
} from "gql/types/operation-result-types";
import {
    FormWrap,
    TablesContainer,
    PartnersFilter,
    AvailablePartners,
} from "views/manager/main/partners-groups/common";
import { errorHandler } from "services/error-handler";
import EDIT_PARTNER_GROUP from "./edit-partner-group.gql";
import GET_PARTNER_GROUP from "./get-partner-group.gql";
import { FormValues } from "./models";
import { GroupPartners } from "./group-partners";
import { ChangesTable } from "./changes-table";

const initialValues: FormValues = {
    name: "",
    addedPartners: [],
    removedPartners: [],
};

export const EditPartnerGroup = () => {
    const { id } = useParams<{ id: string }>();
    const [__] = useTranslation();
    const [editPartnerGroup] = useMutation<
        IEditPartnerGroup,
        EditPartnerGroupVariables
    >(EDIT_PARTNER_GROUP);
    const { data, loading } = useQuery<PartnerGroup, PartnerGroupVariables>(
        GET_PARTNER_GROUP,
        { variables: { id: Number(id) } },
    );
    const push = useLocalizedAbsolutePush();
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();

    if (loading) {
        return null;
    }

    const partnerGroup = data?.authorized.manager.main.partnerGroup;

    return (
        <FormikConsoleErrors
            initialValues={{ ...initialValues, name: partnerGroup?.name }}
            validate={values => {
                if (!values.name) {
                    return { name: FIELD_SHOULD_BE_FILLED() };
                }
            }}
            onSubmit={async values => {
                try {
                    if (!partnerGroup || !values.name) {
                        return;
                    }

                    await editPartnerGroup({
                        variables: {
                            data: {
                                id: Number(id),
                                currencyId: partnerGroup?.currencyId,
                                name: values.name,
                                addedIds: values.addedPartners.map(
                                    partner => partner.id,
                                ),
                                removedIds: values.removedPartners.map(
                                    partner => partner.id,
                                ),
                            },
                        },
                    });

                    toast.success(__("Партнерская группа отредактирована"));
                    push("/agent/main/partners-groups");
                } catch (error: any) {
                    void errorHandler(error);
                }
            }}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <FormWrap>
                        <FormGroup>
                            Валюта: {getCurrencyById(partnerGroup?.currencyId)}
                        </FormGroup>
                        <FormGroup>
                            <Field
                                name="name"
                                type="text"
                                label={__("Имя группы")}
                            />
                        </FormGroup>
                    </FormWrap>

                    <PartnersFilter
                        tableName={__("Доступные партнеры")}
                        withoutGroupsFilter
                    >
                        {({ submittedValues, showPartnersWithoutGroup }) => (
                            <AvailablePartners
                                fieldName="addedPartners"
                                selectedPartners={values.addedPartners}
                                currencyId={partnerGroup?.currencyId}
                                filter={submittedValues}
                                partnerGroupId={Number(id)}
                                showPartnersWithoutGroup={
                                    showPartnersWithoutGroup
                                }
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </PartnersFilter>

                    <PartnersFilter tableName={__("Список участников")}>
                        {({ submittedValues }) => (
                            <GroupPartners
                                fieldName="removedPartners"
                                partnerGroupId={Number(id)}
                                selectedPartners={values.removedPartners}
                                filter={submittedValues}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </PartnersFilter>

                    <TablesContainer>
                        <ChangesTable
                            tableName={__("Партнеры к добавлению")}
                            fieldName="addedPartners"
                            selectedPartners={values.addedPartners}
                            setFieldValue={setFieldValue}
                        />
                        <ChangesTable
                            tableName={__("Партнеры к удалению")}
                            fieldName="removedPartners"
                            selectedPartners={values.removedPartners}
                            setFieldValue={setFieldValue}
                        />
                    </TablesContainer>

                    <FormGroupButton>
                        <SubmitButton>
                            {__("Редактировать группу")}
                        </SubmitButton>
                    </FormGroupButton>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
