import { createSelector } from "reselect";
import { IStates } from "./reducer";

const statesRoot = (state: any) => state.states as IStates;

const getLastNotFoundPathname = createSelector(
    statesRoot,
    states => states.lastNotFoundPathname,
);

const getIsMenuBurgerClickedOnLanding = createSelector(
    statesRoot,
    states => states.isMobileMenuShow,
);

const getIsProfileMenuClicked = createSelector(
    statesRoot,
    states => states.isProfileMenuClicked,
);

const getIsMainMenuClicked = createSelector(
    statesRoot,
    states => states.isMainMenuClicked,
);

const getStatisticLastParams = createSelector(
    statesRoot,
    states => states.statisticLastParams,
);

export const statesSelectors = {
    getLastNotFoundPathname,
    getIsMenuBurgerClickedOnLanding,
    getIsProfileMenuClicked,
    getIsMainMenuClicked,
    getStatisticLastParams,
};
