import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
    GetAdvertisementPayment,
    GetAdvertisementPaymentVariables,
} from "gql/types/operation-result-types";
import GET_ADVERTISEMENT_PAYMENT from "../gql/get-payment.gql";

export const useAdvertisementPayment = (id: number) => {
    const queryResult = useQuery<
        GetAdvertisementPayment,
        GetAdvertisementPaymentVariables
    >(GET_ADVERTISEMENT_PAYMENT, {
        variables: {
            id,
        },
        ssr: true,
    });
    const payment =
        queryResult.data?.authorized.manager.advertising.advertisingPayments
            .advertisingPayment;

    const [siteId, setSiteId] = useState(
        payment?.siteId === undefined ? null : payment?.siteId,
    );
    const partnerId = payment?.partnerId;

    // поскольку useState асинхронный, то будет одновременно происходить
    // с запросом для получения выплаты и сразу выплата будет не найдена
    useEffect(() => {
        if (payment) {
            setSiteId(payment.siteId);
        }
    }, [payment]);

    return { payment, siteId, setSiteId, partnerId };
};
