import {
    GetCurrencies,
    GetCurrenciesVariables,
} from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

export const GET_CURRENCIES = gql`
    query GetCurrencies($partnerId: Int) {
        authorized {
            partnerAndManager {
                data {
                    currencies(partnerId: $partnerId) {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const useCurrency = (partnerId?: number) => {
    const { data, loading } = useQuery<GetCurrencies, GetCurrenciesVariables>(
        GET_CURRENCIES,
        {
            ssr: true,
            variables: {
                partnerId,
            },
        },
    );

    const currencyList = data?.authorized.partnerAndManager.data.currencies;

    return { data: currencyList, loading };
};
