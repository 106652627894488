import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    PartnerInformation,
} from "gql/types/operation-result-types";
import { INotificationPopupState } from "../../notification-popup";
import { PopupType } from "../popup-id";
import { PopupData } from "../popups-data";

interface IReferencePopupDataProps {
    adAgentId?: string | string[];
    disallowedAdAgentIds?: string[];
    disallowedAffiliateIds?: string[];
    id: number;
    type: PopupType;
    linkInPopup?: string;
    currency: GetCurrencies_authorized_partnerAndManager_data_currencies;
    title: string;
    description: string;
    infoText: string;
}

export class ReferencePopupData extends PopupData {
    constructor(public props: IReferencePopupDataProps) {
        super(
            props.id,
            props.type,
            undefined,
            props.linkInPopup,
            props.currency,
        );
    }

    public show(
        information: INotificationPopupState & PartnerInformation,
    ): boolean {
        const { user, partnerAndManager } = information.authorized;
        const { disallowedAffiliateIds, disallowedAdAgentIds, adAgentId } =
            this.props;

        const isCurrencyMatch = partnerAndManager.data.currencies.some(
            cur => cur.id === this.currency?.id,
        );

        const isLinkDifferent =
            information.extraData.linkInPopup !== this.linkInPopup;

        const isDisallowedAdAgent = disallowedAdAgentIds?.includes(
            user.adAgentId,
        );

        const isDisallowedAffiliate = disallowedAffiliateIds?.includes(
            user.affiliateId,
        );

        const isAdAgentMatch =
            !adAgentId ||
            user.adAgentId === adAgentId ||
            (Array.isArray(adAgentId) &&
                adAgentId.includes(user.adAgentId || ""));

        const shouldShowPopup =
            isCurrencyMatch &&
            isLinkDifferent &&
            isAdAgentMatch &&
            !isDisallowedAdAgent &&
            !isDisallowedAffiliate &&
            !information.clickedDontShowAgain;

        return shouldShowPopup;
    }
}
