import styled from "styled-components";
import { media } from "services/styled-components/variables";

// TODO: нужно научить пробрасывать UniversalField marginBottom
// или избавиться от UniversalField.
export const FakeLabelSC = styled.label`
    margin-bottom: 5px;

    ${media.smMax`
        display: none;
    `}
`;
