import {
    FinantialPlanStatus,
    OrderStatus,
    PartnerInvoiceStatus,
    PartnerInvoiceType,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import {
    AdvertisementStatus,
    CampaignStatus,
    PartnerStatus,
} from "gql/types/operation-result-types";
import { Language } from "@webx-react/i18n";

export const getOrderStatusTranslation = (status: string, __: TFunction) => {
    switch (status) {
        case OrderStatus.PAID:
            return __("Оплачен");
        case OrderStatus.FOR_NEXT_PERIOD:
            return __("Перенесен на следующий период");
        case OrderStatus.AWAITING_PAYMENT:
            return __("В ожидании оплаты");
        case OrderStatus.PENDING:
            return __("В обработке");
        default:
            return __("Неизвестный статус");
    }
};

export function usePartnerStatuses() {
    const [__] = useTranslation();
    return new Map([
        [PartnerStatus.INACTIVE, __("Не активный")],
        [PartnerStatus.ACTIVE, __("Активный")],
        [PartnerStatus.DECLINED, __("Отклонен")],
        [PartnerStatus.ON_REVIEW, __("Проверка")],
    ]);
}

export function getStatusName(
    status: AdvertisementStatus | null | undefined,
    __: TFunction,
) {
    const statusMap = new Map([
        [AdvertisementStatus.CREATED, __("Создан")],
        [AdvertisementStatus.APPROVED, __("Одобрен")],
        [AdvertisementStatus.SENT, __("Отправлен")],
        [AdvertisementStatus.NEEDS_IMPROVEMENT, __("Требуется доработка")],
        [AdvertisementStatus.SUCCESS, __("Успех")],
        [AdvertisementStatus.DECLINED, __("Отказ")],
        [AdvertisementStatus.PARTIAL_PAYOUT, __("Частичная оплата")],
        [AdvertisementStatus.CANCELED, __("Отменен")],
        [AdvertisementStatus.ARCHIVE, __("Архив")],
        [AdvertisementStatus.NEW, __("Новый")],
    ]);
    return (status && statusMap.get(status)) || "";
}

export function getInvoiceTypeName(
    type: PartnerInvoiceType | null | undefined,
    __: TFunction,
) {
    if (!type) {
        return "";
    }
    const typeMap = new Map([
        [PartnerInvoiceType.NO_INVOICE, __("Без запроса")],
        [PartnerInvoiceType.AUTO, __("Автоматический")],
        [PartnerInvoiceType.MANUAL, __("Ручной")],
    ]);
    return typeMap.get(type) || "";
}

export function getInvoiceStatusName(
    status: PartnerInvoiceStatus | null | undefined,
    __: TFunction,
) {
    if (!status) {
        return "";
    }
    const statusMap = new Map([
        [PartnerInvoiceStatus.UNPAID, __("Не оплачен")],
        [PartnerInvoiceStatus.PAID, __("Оплачен")],
        [PartnerInvoiceStatus.POSTPONED, __("Перенесен")],
        [PartnerInvoiceStatus.APPROVED, __("Одобрен")],
        [PartnerInvoiceStatus.PROCESSING, __("В обработке")],
    ]);
    return statusMap.get(status) || "";
}

export const getCurrencyById = (currencyId?: number | null) => {
    switch (currencyId) {
        case 3:
            return "RUB";
        case 6:
            return "USD";
        case 9:
            return "mBTC";
        case 10:
            return "UAH";
        default:
            return "USD";
    }
};

/* именно такой формат использует v1 для фильтров (v1 запрашивает только день, месяц и год) */
export const makeUtcCustomPeriod = (
    start: import("moment").Moment | null | undefined,
    end: import("moment").Moment | null | undefined,
) => {
    return {
        start: convertDataTimeToData(start),
        end: convertDataTimeToData(end),
    };
};

// Формик берёт день включительно. v1 нет.
const convertDataTimeToData = (
    dataTime: import("moment").Moment | null | undefined,
) => {
    if (!dataTime) {
        return dataTime;
    }
    dataTime = dataTime.clone();
    // Формик берёт день включительно. v1 нет.
    dataTime.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    dataTime.utc(true);
    return dataTime;
};

// doesn't guarantee collision free
export const getSimpleHashCode = (value: string) => {
    let hash = 0;
    if (value.length === 0) {
        return hash;
    }
    for (let i = 0; i < value.length; i++) {
        const chr = value.charCodeAt(i);
        // tslint:disable-next-line:no-bitwise
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr;
        // tslint:disable-next-line:no-bitwise
        // eslint-disable-next-line no-bitwise
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
};

//  from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export const getHashCode = async (value: string) => {
    const msgUint8 = new TextEncoder().encode(value);
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map(b => b.toString(16).padStart(2, "0"))
        .join("");
    return hashHex;
};

export const formatCurrency = (currency: number | null) => {
    if (currency === null) {
        return currency;
    }

    // в ISO формате максимальная точность для всех
    // известных валют в мире составляет 3 знака после запятой.
    // Но мы возьмём с запасом (на всякий случай). Будет 6 знаков.
    return (Math.round(currency * 1000000) / 1000000).toLocaleString(
        undefined,
        { minimumFractionDigits: 2 },
    );
};

export function formatTGName(telegramLink: string) {
    return telegramLink.split("/").reverse()[0];
}

export const getCampaignStatus = (__: TFunction, status: CampaignStatus) => {
    switch (status) {
        case CampaignStatus.INACTIVE:
            return __("Не активный");
        case CampaignStatus.ACTIVE:
            return __("Активный");
        default:
            return "";
    }
};

export const getPartnerStatus = (__: TFunction, status: PartnerStatus) => {
    const statuses = {
        [PartnerStatus.INACTIVE]: __("Не активный"),
        [PartnerStatus.ACTIVE]: __("Активный"),
        [PartnerStatus.DECLINED]: __("Отклонен"),
        [PartnerStatus.ON_REVIEW]: __("Проверка"),
        default: __("Неизвестный статус"),
    };

    return statuses[status] || statuses.default;
};

export function isRightToLeftLanguage(lang: Language) {
    return ["ae", "he", "ur"].includes(lang.shortName.toLowerCase());
}

export function hexToRgb(hex: string, opacity?: number): string {
    hex = hex.replace("#", "");

    if (hex.length !== 3 && hex.length !== 6) {
        throw new Error("[hexToRgb]: wrong hex format");
    }

    if (opacity && (opacity < 0 || opacity > 1)) {
        throw new Error("[hexToRgb]: opacity is out of [0, 1] range");
    }

    hex =
        hex.length === 3
            ? hex
                  .split("")
                  .map(item => `${item}${item}`)
                  .join("")
            : hex;

    const rgb = [
        parseInt(hex.slice(0, 2), 16),
        parseInt(hex.slice(2, 4), 16),
        parseInt(hex.slice(4), 16),
    ];

    if (opacity) {
        rgb.push(opacity);
    }

    return opacity ? `rgba(${rgb.join(",")})` : `rgb(${rgb.join(",")})`;
}

export const getClassNameById = (__: TFunction, id?: number) => {
    const classNamesMap = new Map([
        [1, __("Новый игрок (на рассмотрении)")],
        [2, __("Вилочник")],
        [3, __("Плюсовой игрок")],
        [4, __("Минусовой игрок – папан")],
        [5, __("Игрок, обладающий информацией")],
        [6, __("Обычный игрок")],
        [7, __("Опереженец")],
        [8, __("Коридорщик")],
        [9, __("Мошенник по платежам")],
        [10, __("Совсем новый")],
        [11, __("Бонусник")],
        [12, __("Косяк")],
        [13, __("VIP")],
        [14, __("Рассылка")],
        [15, __("Игровая зависимость")],
        [16, __("Махинации на кассах")],
        [17, __("Фейк для пополнений слотов")],
        [18, __("МЕГАплюсовой игрок")],
        [19, __("Вилочник (подозрение)")],
        [20, __("Игрок, обладающий информацией (подозрение)")],
        [21, __("Опережение (подозрение)")],
        [22, __("Партнёр")],
        [23, __("Агент")],
        [24, __("догер regions")],
        [25, __("Демо")],
        [26, __("Слежка regions")],
        [27, __("Игрок на СЛЕЖКЕ")],
        [28, __("ФРОД от партнера")],
        [29, __("Массовая регистрация/сторонее ПО")],
        [30, __("Аккаунт продан")],
        [31, __("Плохой игрок")],
        [32, __("Паспорт из интернета")],
        [33, __("Статист")],
        [34, __("Порезан по просьбе партнера")],
        [35, __("Решение букмекера")],
        [36, __("VIP с догами")],
    ]);

    return id ? classNamesMap.get(id) : null;
};

export const getVerifiStatTextById = (__: TFunction, id?: number) => {
    const verifiStatTextsMap = new Map([
        [0, __("Не проходил")],
        [1, __("Верификация пройдена")],
        [2, __("Запрос документов (казино закрыто)")],
        [3, __("Верификация данных")],
        [4, __("Видеоконференция")],
        [5, __("Запрос карты")],
        [6, __("Анкета")],
        [7, __("Проверка документов партнером")],
        [8, __("Автозапрос доков по сроку давности")],
        [9, __("Запрос документов (казино открыто)")],
    ]);

    return id || id === 0 ? verifiStatTextsMap.get(id) : null;
};

export const getFinplanStatusNameEnum = (statusName: string) => {
    const statusMap = new Map([
        ["Archived", FinantialPlanStatus.ARCHIVE],
        ["Created", FinantialPlanStatus.CREATED],
        ["In progress", FinantialPlanStatus.PAYING],
        ["Intermediate", FinantialPlanStatus.INTERMIDIATE],
        ["Paid", FinantialPlanStatus.PAID],
        ["Approved", FinantialPlanStatus.APPROVED],
        ["Revision required", FinantialPlanStatus.REVISION_REQUIRED],
    ]);

    return statusMap.get(statusName) as FinantialPlanStatus;
};

export const getFinplanStatusWithTranslation = (
    status: FinantialPlanStatus,
    __: TFunction,
) => {
    const statusMap = new Map([
        [FinantialPlanStatus.ARCHIVE, __("Архив")],
        [FinantialPlanStatus.CREATED, __("Создан")],
        [FinantialPlanStatus.PAYING, __("Выплачивается")],
        [FinantialPlanStatus.INTERMIDIATE, __("Черновик")],
        [FinantialPlanStatus.PAID, __("Оплачен")],
        [FinantialPlanStatus.APPROVED, __("Одобрен")],
        [FinantialPlanStatus.REVISION_REQUIRED, __("Требуется доработка")],
    ]);

    return statusMap.get(status);
};
