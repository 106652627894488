import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { phoneRegex } from "services/validators";
import { idNameObject, useObjectValidations } from "validation/validaion";

export function useValidationSchema() {
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT } = useTranslationList();
    const { messengerRules } = useObjectValidations();

    const { messenger, messengerLogin } = messengerRules();

    return yup.object({
        firstname: yup.string().required(FIELD_SHOULD_BE_FILLED),
        surname: yup.string().required(FIELD_SHOULD_BE_FILLED),
        phone: yup.string<string | null>().matches(phoneRegex, INVALID_FORMAT),
        messenger,
        messengerLogin,
        country: idNameObject().required(FIELD_SHOULD_BE_FILLED),
        language: idNameObject().required(FIELD_SHOULD_BE_FILLED),
        getChangeDomainEmails: yup
            .boolean<boolean | null>()
            .required(FIELD_SHOULD_BE_FILLED),
    });
}

export type ValidationSchemaFunc = typeof useValidationSchema;
