import { ContentLoader } from "components/components-common/content-loader";
import { useUser } from "hooks/query/use-user";
import { useUserContacts } from "./hooks/use-user-contacts";
import { Code } from "react-content-loader";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const ContactsInformation = React.memo((props: IProps) => {
    const { user } = useUser();
    const { contactList, loading } = useUserContacts(user);

    return (
        <ContentLoader component={Code} loading={loading}>
            <div
                dangerouslySetInnerHTML={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    __html: contactList || "",
                }}
            />
        </ContentLoader>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
