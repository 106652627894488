import styled from "styled-components";
import { css } from "styled-components";

export const CurrencyCellSC = styled.div<{ value: number }>`
    ${props =>
        props.value < 0 &&
        css`
            color: red;
        `}
`;
