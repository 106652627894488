import { css } from "styled-components";

const fontsPath = "../assets/fonts/gilroy/";

export const GilroyFonts = css`
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-Thin.otf") format("truetype");
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-UltraLight.otf") format("truetype");
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-Light.otf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-Regular.otf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-Medium.otf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-SemiBold.otf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-Bold.otf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-ExtraBold.otf") format("truetype");
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: "Gilroy";
        src: url("${fontsPath}Gilroy-Black.otf") format("truetype");
        font-weight: 900;
        font-style: normal;
    }
`;
