import { ISelectFild } from "../select-filed-interface";
import { Field, FormikHandlers } from "components/formik";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { useMemo } from "react";
import _ from "lodash";
import { useCurrencyAdversitingPayments } from "hooks/query/use-currency-adversiting-payments";
import { useTranslation } from "react-i18next";

interface IProps extends ISelectFild {
    fieldOnChange?: FormikHandlers["handleChange"];
}

export const CurrencyAdversitingPaymentsField = React.memo((props: IProps) => {
    const { name, disabled, ...rest } = props;
    const { data, loading } = useCurrencyAdversitingPayments();
    const [__] = useTranslation();

    const options = useMemo(() => {
        return _.map(data, c => ({
            value: c as any,
            label: c.name,
            isDisabled: false,
        }));
    }, [data]);

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            as="select"
            name={props.name}
            label={props.label}
            placeholder={__("Все")}
            type="react-select"
            options={options}
            isLoading={loading}
            isDisabled={disabled || loading}
            minWidth={200}
            {...rest}
        />
    );
}) as React.ComponentType<IProps>;
