import * as yup from "yup";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";
import { useObjectValidations } from "validation/validaion";
import { useTranslationList } from "hooks/use-translations-list";

export const useValidationSchema = () => {
    const { objectRule, urlSchema } = useObjectValidations();
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT } = useTranslationList();

    return yup.object({
        currency:
            objectRule<GetCurrencies_authorized_partnerAndManager_data_currencies>().required(
                FIELD_SHOULD_BE_FILLED,
            ),
        resource: yup
            .string()
            .matches(/^[a-zA-Z0-9]{1,50}$/, INVALID_FORMAT)
            .required(FIELD_SHOULD_BE_FILLED),
        hostName: urlSchema,
        autoUpdate: yup.boolean(),
        autoEmailSending: yup.boolean(),
    });
};
