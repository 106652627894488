import { Field } from "components/formik";
import { PartnerInvoiceType } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { getInvoiceTypeName } from "services/project-utils";

export const InvoiceTypeField = () => {
    const [__] = useTranslation();

    const options = Object.keys(PartnerInvoiceType).map(type => {
        const currentType = type as PartnerInvoiceType;
        return {
            value: currentType,
            label: getInvoiceTypeName(currentType, __),
        };
    });

    return (
        <Field
            name="invoiceType"
            key="invoiceType"
            type="react-select"
            options={options}
            isLoading={false}
            selectFirst={false}
            label={__("Тип инвойса")}
            minWidth={200}
        />
    );
};
