import moment from "moment";
import { TFormValues } from "./use-validation-schema";

export const initialValues: Partial<TFormValues> = {
    endless: true,
    period: {
        start: moment().startOf("month"),
        end: null,
    },
};
