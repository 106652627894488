import { useTranslation } from "react-i18next";
import { UniversalForm } from "components/filters";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { PaymentHistoryReportTable } from "./report-table";
import { AdminWrapper } from "styled/layouts";

export const PaymentHistory = () => {
    const [__] = useTranslation();

    return (
        <UniversalForm
            fields={[
                "period",
                "currency",
                "commisionGroup",
                "partnersGroup",
                "partnerId",
            ]}
            submitButtonName={__("Сгенерировать отчет")}
        >
            {({ form, submittedValues, actualValues }) => {
                return (
                    <AdminWrapper>
                        {form}
                        <FirstLoadTable
                            actualValues={actualValues}
                            submittedValues={submittedValues}
                        >
                            {values => (
                                <PaymentHistoryReportTable
                                    submittedValues={values}
                                />
                            )}
                        </FirstLoadTable>
                    </AdminWrapper>
                );
            }}
        </UniversalForm>
    );
};
