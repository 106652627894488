import { css } from "styled-components";

export const ReactSelectSC = css`
    /* stylelint-disable */

    .react-select__control {
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        height: 1px;
        outline: 0 !important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;
    }

    .react-select__control:hover {
        border-color: hsl(0, 0%, 70%);
    }

    .react-select__value-container {
        align-items: center;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        padding: 2px 0.8em;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;

        & > div + input {
            height: 0;
            border: 0;
        }
    }

    .react-select__placeholder {
        color: hsl(0, 0%, 50%);
        margin-left: 2px;
        margin-right: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: border-box;
    }

    .react-select__placeholder + div {
        margin: 2px;
        padding-bottom: 2px;
        padding-top: 2px;
        visibility: visible;
        color: hsl(0, 0%, 20%);
        box-sizing: border-box;
    }

    .react-select__indicators {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-shrink: 0;
        box-sizing: border-box;
    }

    .react-select__indicator-separator {
        align-self: stretch;
        background-color: hsl(0, 0%, 80%);
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }

    .react-select__indicator {
        color: hsl(0, 0%, 80%);
        display: flex;
        padding: 8px;
        transition: color 150ms;
        box-sizing: border-box;
    }

    .react-select__indicator:hover {
        color: hsl(0, 0%, 60%);
    }

    .react-select__indicator > svg {
        display: inline-block;
        fill: currentColor;
        line-height: 1;
        stroke: currentColor;
        stroke-width: 0;
    }
`;
