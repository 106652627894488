import { gql, useMutation } from "@apollo/client";
import {
    SignInLikePartnerWithSignOutIfNeeded,
    SignInLikePartnerWithSignOutIfNeededVariables,
} from "gql/types/operation-result-types";

export function useSignInLikeAPartner() {
    const SIGN_IN_LIKE_PARTNER_WITH_SIGN_OUT_IF_NEEDED = gql`
        mutation SignInLikePartnerWithSignOutIfNeeded($partnerId: Int!) {
            authorized {
                signInLikeAPartnerWithSignOutIfNeeded(partnerId: $partnerId) {
                    id
                }
            }
        }
    `;

    const [signInLikeAPartnerWithSignOutIfNeededMutation, { loading }] =
        useMutation<
            SignInLikePartnerWithSignOutIfNeeded,
            SignInLikePartnerWithSignOutIfNeededVariables
        >(SIGN_IN_LIKE_PARTNER_WITH_SIGN_OUT_IF_NEEDED);

    return {
        signInLikeAPartner: async (partnerId: number, xCsrfToken?: string) =>
            await signInLikeAPartnerWithSignOutIfNeededMutation({
                variables: { partnerId },
                context: xCsrfToken
                    ? {
                          headers: {
                              // Обычно токен берётся из юзера перед отправкой
                              // запроса на сервер (httpLink и batchLink)
                              // но в этой случае мы делаем auhorized операцию до того
                              // как юзер был записан в кэш. Поэтому мы должны передать токе явно.
                              "x-csrf-token": xCsrfToken,
                          },
                      }
                    : undefined,
            }),
        loading,
    };
}
