import { Field } from "components/formik";
import { useTranslation } from "react-i18next";
import { useMessengersInForms } from "hooks/use-messenger-login-validation";

interface MessengerFieldProps {
    required?: boolean;
    isDisabled?: boolean;
}

export const MessengerField = ({
    isDisabled,
    required,
}: MessengerFieldProps) => {
    const { loading, messengerOptions } = useMessengersInForms(isDisabled);
    const [__] = useTranslation();

    const labelPrefix = required ? " *" : "";

    return (
        <Field
            name="messenger"
            label={`${__("Мессенджер")}${labelPrefix}`}
            type="react-select"
            options={messengerOptions}
            isLoading={loading}
            isClearable={required ? false : true}
            isDisabled={loading || isDisabled}
        />
    );
};
