import { useCallback, useState } from "react";
import { Tabs } from "components/tabs";
import { Tab } from "components/tabs/components/tab";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { AvailableTab } from "./available-tab";
import { PartnerLinksFilter } from "./filter";
import { HiddenTab } from "./hidden-tab";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { COINPLAY_PARTNERS } from "server/partner-programs";

const DEFAULT_LINKS_TAB = 0;
const HIDDEN_LINKS_TAB = 1;

export const PartnerLinks = () => {
    const [__] = useTranslation();
    const { partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [selectedTab, setSelectedTab] = useState(DEFAULT_LINKS_TAB);

    const isCoinplayPartners = COINPLAY_PARTNERS === partnersProgramId;

    const goToActiveLinks = useCallback(() => {
        setSelectedTab(DEFAULT_LINKS_TAB);
    }, []);

    const goToHiddenLinks = useCallback(() => {
        setSelectedTab(HIDDEN_LINKS_TAB);
    }, []);

    return (
        <>
            <PartnerLinksFilter goToActiveLinks={goToActiveLinks} />
            {isCoinplayPartners && (
                <p>
                    <LocalizedNavLinkWithOptionalBackground to="/partner/marketing-tools/links-guide">
                        {__("Как создать партнерскую ссылку")}
                    </LocalizedNavLinkWithOptionalBackground>
                </p>
            )}

            <AdminWrapper>
                <Tabs
                    name="partnerLink"
                    default={DEFAULT_LINKS_TAB}
                    selected={selectedTab}
                >
                    <Tab
                        title={__("Созданные ссылки")}
                        action={goToActiveLinks}
                        key={DEFAULT_LINKS_TAB}
                    >
                        <AvailableTab />
                    </Tab>
                    <Tab
                        title={__("Скрытые ссылки")}
                        action={goToHiddenLinks}
                        key={HIDDEN_LINKS_TAB}
                    >
                        <HiddenTab />
                    </Tab>
                </Tabs>
            </AdminWrapper>
        </>
    );
};
