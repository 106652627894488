import { useValidationRules } from "hooks/sign-up/use-validation-rules";
import { useTranslationList } from "hooks/use-translations-list";
import { createWebsiteRule } from "services/validators";
import { idNameObject, useObjectValidations } from "validation/validaion";
import * as yup from "yup";

export const useValidationSchema = () => {
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT } = useTranslationList();
    const { messengerRules } = useObjectValidations();

    const { messenger, messengerLogin } = messengerRules();
    const {
        login,
        password,
        confirmPassword,
        firstname,
        surname,
        email,
        phone,
        country,
        language,
    } = useValidationRules();

    const validationSchema = yup.object().shape({
        login,
        password,
        confirmPassword,
        firstname,
        surname,
        email,
        messenger,
        messengerLogin,
        phone,
        website: createWebsiteRule(INVALID_FORMAT).nullable().trim(),
        country,
        language,
        currency: idNameObject().nullable().required(FIELD_SHOULD_BE_FILLED),
        message: yup.string(),
        commisionGroup: idNameObject().nullable(),
        partnersGroup: idNameObject().nullable(),
    });

    return validationSchema;
};
