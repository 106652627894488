import { Export } from "./export";
import {
    GetOrdersAndPayments,
    GetOrdersAndPaymentsVariables,
    ReportStatus,
} from "gql/types/operation-result-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReportReactTable } from "components/react-table/report-react-table";
import { IReactTableColumn } from "components/react-table/column/";
import { QueryResult } from "@apollo/client";
import { AllUniversalOutputValues } from "components/filters/types";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    currency: {
        name: string;
    };
    values: Pick<AllUniversalOutputValues, "currency" | "period">;
    queryResult: QueryResult<
        GetOrdersAndPayments,
        GetOrdersAndPaymentsVariables
    >;
}

export const HistoryPayments = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { currency, queryResult, values } = props;

    const data =
        queryResult.data?.authorized.partner.ordersAndPayments.payments || [];

    const columns: IReactTableColumn[] = [
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            disabled: true,
            minWidth: 80,
        },
        {
            Header: __("Период"),
            accessor: "periodFromMondey",
            minWidth: 90,
        },
        {
            Header: __("Общая комиссия"),
            accessor: "totalComission",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 90,
        },
        {
            Header: __("Выплачено"),
            accessor: "paid",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 90,
        },
        {
            Header: __("Сумма бонусов"),
            accessor: "bonusAmount",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 90,
        },
        {
            Header: __("Доход"),
            accessor: "profit",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 90,
        },
        {
            Header: __("Перенос"),
            accessor: "carryover",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 90,
        },
        {
            Header: __("Дата выплаты"),
            accessor: "paymentDate",
            minWidth: 90,
        },
        {
            Header: __("Доступно к выводу"),
            accessor: "availableToWithdraw",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 90,
        },
        {
            Header: __("Сумма холда"),
            accessor: "holdAmount",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 90,
        },
    ];

    const exportComponent = (
        <Export
            exportParams={{
                columns,
                data,
                filters: values,
                filename: __("История выплат"),
            }}
        />
    );

    const sumPaid = data.reduce((sum, item) => (sum += item.paid), 0);
    const total = {
        currencyName: null,
        periodFromMondey: null,
        totalComission: null,
        paid: sumPaid,
        bonusAmount: null,
        profit: null,
        carryover: null,
        paymentDate: null,
        availableToWithdraw: null,
        holdAmount: null,
    };

    return (
        <ReportReactTable
            exportComponent={exportComponent}
            columns={columns}
            queryResult={queryResult}
            data={data}
            total={total}
            reportStatus={ReportStatus.SUCCESS}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
