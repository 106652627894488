import { useTranslation } from "react-i18next";
import * as moment from "moment";
import * as yup from "yup";
import { AdminWrapper } from "styled/layouts";
import { Field } from "components/formik";
import { useObjectValidations } from "validation/validaion";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { AdvertisementPaymentsFilesTable } from "./report-table";
import { Period } from "components/filters/fields/period";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form } from "components/formik";
import { FormDirectionRow, FormGroup, FormGroupButton } from "styled/layouts";
import { useState } from "react";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";

export const PaymentFilesTable = ({
    chooseFile,
}: {
    chooseFile: (fileId: number) => void;
}) => {
    const [__] = useTranslation();
    const { nullableInterger, objectRequiredRule } = useObjectValidations();
    const initialValues = {
        periodCustom: {
            interval: "CURRENT_MONTH",
            start: moment().startOf("month"),
            end: moment().endOf("month"),
        },
        fileId: null as number | null | undefined,
        fileName: "",
    };
    const validationSchema = yup.object({
        periodCustom: objectRequiredRule<{
            interval: string;
            start: import("moment").Moment;
            end: import("moment").Moment;
        }>(),
        fileId: nullableInterger,
        fileName: yup.string(),
    });
    const [submittedValues, setSubmittedValues] =
        useState<typeof initialValues>();

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async values => {
                setSubmittedValues(values);
            }, validationSchema)}
        >
            {({ values: actualValues }) => {
                return (
                    <>
                        <Form>
                            <FormDirectionRow>
                                <Period
                                    name="periodCustom"
                                    fakeSwapLabel={<FakeLabel />}
                                />
                                <FormGroup>
                                    <Field
                                        name="fileId"
                                        type="text"
                                        label="ID"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name="fileName"
                                        type="text"
                                        label={__("Имя файла")}
                                        style={{ minWidth: "200px" }}
                                    />
                                </FormGroup>
                                <FormGroupButton>
                                    <FakeLabel />
                                    <SubmitButton>{__("Найти")}</SubmitButton>
                                </FormGroupButton>
                            </FormDirectionRow>
                        </Form>
                        <AdminWrapper>
                            <FirstLoadTable
                                actualValues={actualValues}
                                submittedValues={submittedValues}
                            >
                                {values => (
                                    <AdvertisementPaymentsFilesTable
                                        submittedValues={values}
                                        chooseFile={chooseFile}
                                    />
                                )}
                            </FirstLoadTable>
                        </AdminWrapper>
                    </>
                );
            }}
        </FormikConsoleErrors>
    );
};
