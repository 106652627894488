import { Field } from "components/formik";
import { FormGroup, RowTwoColumnsParagraph } from "styled/layouts";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    values: {
        isClickIdPostbackParameter: boolean;
        isPartnerIdPostbackParameter: boolean;
        isTransactionPostbackParameter: boolean;
        isSiteIdPostbackParameter: boolean;
        isOtherPostbackParameter: boolean;
        isSumdepPostbackParameter: boolean;
    };
}

export const CheckboxBlock = React.memo(({ values }: IProps) => {
    return (
        <>
            <RowTwoColumnsParagraph>
                <FormGroup>
                    <Field
                        name="isClickIdPostbackParameter"
                        type="checkbox"
                        autoComplete="on"
                        label="{{click_id}}"
                    />
                    <Field
                        name="clickIdPostbackParameter"
                        type="text"
                        autoComplete="on"
                        isValid={values.isClickIdPostbackParameter}
                        disabled={!values.isClickIdPostbackParameter}
                    />
                </FormGroup>
                <FormGroup>
                    <Field
                        name="isSiteIdPostbackParameter"
                        type="checkbox"
                        autoComplete="on"
                        label="{{site_id}}"
                    />
                    <Field
                        name="siteIdPostbackParameter"
                        type="text"
                        autoComplete="on"
                        isValid={values.isSiteIdPostbackParameter}
                        disabled={!values.isSiteIdPostbackParameter}
                    />
                </FormGroup>
            </RowTwoColumnsParagraph>
            <RowTwoColumnsParagraph>
                <FormGroup>
                    <Field
                        name="isPartnerIdPostbackParameter"
                        type="checkbox"
                        autoComplete="on"
                        label="{{partner_id}}"
                    />
                    <Field
                        name="partnerIdPostbackParameter"
                        type="text"
                        autoComplete="on"
                        isValid={values.isPartnerIdPostbackParameter}
                        disabled={!values.isPartnerIdPostbackParameter}
                    />
                </FormGroup>
                <FormGroup>
                    <Field
                        name="isOtherPostbackParameter"
                        type="checkbox"
                        autoComplete="on"
                        label="{{other}}"
                    />
                    <Field
                        name="otherPostbackParameter"
                        type="text"
                        autoComplete="on"
                        isValid={values.isOtherPostbackParameter}
                        disabled={!values.isOtherPostbackParameter}
                    />
                </FormGroup>
            </RowTwoColumnsParagraph>
            <RowTwoColumnsParagraph>
                <FormGroup>
                    <Field
                        name="isTransactionPostbackParameter"
                        type="checkbox"
                        autoComplete="on"
                        label="{{transaction}}"
                    />
                    <Field
                        name="transactionPostbackParameter"
                        type="text"
                        autoComplete="on"
                        isValid={values.isTransactionPostbackParameter}
                        disabled={!values.isTransactionPostbackParameter}
                    />
                </FormGroup>
                <FormGroup>
                    <Field
                        name="isSumdepPostbackParameter"
                        type="checkbox"
                        autoComplete="on"
                        label="{{sumdep}}"
                    />
                    <Field
                        name="sumdepPostbackParameter"
                        type="text"
                        autoComplete="on"
                        isValid={values.isSumdepPostbackParameter}
                        disabled={!values.isSumdepPostbackParameter}
                    />
                </FormGroup>
            </RowTwoColumnsParagraph>
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
