import styled from "styled-components";
import { LabelComponent } from "components/formik/universal-field";

// TODO поменять типизацию для label
export const LabelOneLine = styled.label.attrs(props => ({
    title: props.children,
}))`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
` as LabelComponent;
