import { AdminWrapperEdit, FormDirectionRow, FormGroup } from "styled/layouts";
import { Field, FieldArray } from "components/formik";
import { useTranslation } from "react-i18next";
import { CurrencyAdversitingPaymentsField } from "components/filters/fields/currency-adversiting-payments";
import {
    AdvertisementStatus,
    ContractPlacement,
    GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments,
} from "gql/types/operation-result-types";
import { StatusFormField } from "./status-field";
import { AdversitingAction } from "../../../view-edit-steps";
import { placementOptions } from "../../placement-options";
import { convertToAmountRUB } from "../convert-to-amount-rub";

export const CommonInfo = ({
    values,
    setFieldValue,
    adversitingAction,
    initialStatus,
}: {
    values: {
        // eslint-disable-next-line max-len
        currencyAdversitingPayments: GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments | null;
        amountRequest: number | null;
        contractPlacements?: ContractPlacement[];
    };
    setFieldValue: (field: string, value: any) => void;
    loading?: boolean;
    adversitingAction?: AdversitingAction;
    initialStatus?: AdvertisementStatus | null;
}) => {
    const [__] = useTranslation();

    const setAmountRequestRUBFieldValue = (
        amountRequest: string | number,
        // eslint-disable-next-line max-len
        currency?: GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments | null,
    ) => {
        const valueRUB = convertToAmountRUB(amountRequest, currency);
        setFieldValue("amountRequestRUB", valueRUB);
    };

    const isDisabledField = adversitingAction === AdversitingAction.View;

    return (
        <AdminWrapperEdit>
            <FormGroup>
                <Field
                    name="partnerId"
                    type="text"
                    label={__("ID партнера")}
                    disabled
                />
            </FormGroup>
            <FormGroup>
                <Field
                    name="siteId"
                    type="text"
                    label={__("ID сайта")}
                    disabled
                />
            </FormGroup>
            <FormGroup>
                <CurrencyAdversitingPaymentsField
                    name="currencyAdversitingPayments"
                    label={__("Валюта")}
                    disabled={isDisabledField}
                    isClearable={false}
                    fieldOnChange={(e: any) => {
                        const selectedCurrency = e.target?.value;
                        if (values.amountRequest) {
                            setAmountRequestRUBFieldValue(
                                values.amountRequest,
                                selectedCurrency,
                            );
                        }
                    }}
                />
            </FormGroup>
            <FormDirectionRow stretch>
                <FormGroup>
                    <Field
                        name="amountRequest"
                        type="text"
                        label={__("Сумма к оплате")}
                        disabled={isDisabledField}
                        onChange={e => {
                            const value = e.target.value;
                            setFieldValue("amountRequest", value);
                            setAmountRequestRUBFieldValue(
                                value,
                                values.currencyAdversitingPayments,
                            );
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Field
                        name="amountRequestRUB"
                        type="text"
                        label={__("Сумма к оплате, RUB")}
                        disabled
                    />
                </FormGroup>
            </FormDirectionRow>
            {(values.contractPlacements || []).length > 0 && (
                <FieldArray name="contractPlacements">
                    {() =>
                        values.contractPlacements?.map((_, index) => (
                            <FormDirectionRow key={index} stretch>
                                <FormGroup>
                                    <Field
                                        name={`contractPlacements.${index}.type`}
                                        type="react-select"
                                        label={__("Тип размещения")}
                                        options={placementOptions}
                                        selectFirst
                                        isClearable={false}
                                        minWidth={100}
                                        isDisabled
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name={`contractPlacements.${index}.sum`}
                                        type="text"
                                        label={__("Cумма")}
                                        style={{ minWidth: "100px" }}
                                        disabled={isDisabledField}
                                    />
                                </FormGroup>
                            </FormDirectionRow>
                        ))
                    }
                </FieldArray>
            )}

            <FormGroup>
                {adversitingAction ? (
                    <StatusFormField
                        disabled={isDisabledField}
                        initialStatus={initialStatus}
                    />
                ) : (
                    <Field
                        name="statusAdvertisingPayments"
                        label={__("Статус")}
                        type="react-select"
                        options={[
                            {
                                value: AdvertisementStatus.NEW,
                                label: __("Новый"),
                            },
                        ]}
                        minWidth={200}
                        isClearable={false}
                    />
                )}
            </FormGroup>
            <FormGroup>
                <Field
                    name="comments"
                    type="textarea"
                    label={__("Комментарий")}
                    rows={6}
                    autoComplete="on"
                    disabled={isDisabledField}
                />
            </FormGroup>
        </AdminWrapperEdit>
    );
};
