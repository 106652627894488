import { Field, Form } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { useTranslation } from "react-i18next";
import { FormGroup } from "styled/layouts";
import { FormGroupButton } from "styled/layouts";
import { usePaymentSystemsWithFieldsAndTranslations } from "hooks/query/query-with-translations/use-payment-systems-with-translations";
import { PaymentInfo } from "views/manager/advertising/components/payment-info";

export const PaymentInfoForm = () => {
    const [__] = useTranslation();

    const { paymentSystemsOptions } =
        usePaymentSystemsWithFieldsAndTranslations(true);

    return (
        <Form>
            <FormGroup>
                <Field
                    name="oldPaymentSystem"
                    type="react-select"
                    label={__("Предпочитаемый метод оплаты")}
                    options={paymentSystemsOptions}
                    isDisabled
                />
            </FormGroup>
            <PaymentInfo
                paymentSystemLabel={__("Новый предпочитаемый метод оплаты")}
                options={{
                    hideCountry: true,
                    hideFileFields: true,
                }}
            />
            <FormGroupButton hasExtraMargin>
                <SubmitButton>{__("Сохранить изменения")}</SubmitButton>
            </FormGroupButton>
        </Form>
    );
};
