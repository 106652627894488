import {
    Partner_authorized_manager_main_partner,
    Partner_authorized_manager_main_partner_payment,
} from "gql/types/operation-result-types";

const initialValues = {
    oldPaymentSystem:
        null as Partner_authorized_manager_main_partner_payment | null,
    paymentSystem:
        null as Partner_authorized_manager_main_partner_payment | null,
    company: null as number | null,
};

export function useInitialValues(
    partnerId: number,
    data?: Partner_authorized_manager_main_partner,
) {
    return {
        ...initialValues,
        oldPaymentSystem: data?.payment || null,
        partnerId,
    };
}
