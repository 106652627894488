import {
    FinantialPlanStatus,
    GetCountries_countries,
    GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments,
    GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_paymentMethod,
    PlacementType,
} from "gql/types/operation-result-types";
import * as yup from "yup";
import { useObjectValidations } from "validation/validaion";
import { useTranslationList } from "hooks/use-translations-list";
import { IPlacement } from "../add-fin-plan-steps/add-contract/add-placement";
import { phoneRegex } from "services/validators";

export function useInitilaValuesAndValidation() {
    const initialValues = {
        contractNumber: "",
        contractName: "",
        contractDuration: {
            interval: "CUSTOM",
            start: null as import("moment").Moment | null,
            end: null as import("moment").Moment | null,
        },
        contractCountry: null as null | GetCountries_countries,
        paymentSystem:
            null as GetPlan_authorized_manager_advertising_finantialPlans_plan_contract_paymentMethod | null,
        contactFullName: "",
        phone: "",
        email: "",
        currencyAdversitingPayments:
            // eslint-disable-next-line max-len
            null as null | GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments,
        contractSumm: null as null | number,
        filesContent: [{ name: "", path: "" }],
        contractPlacements: [] as IPlacement[],
        message: "",
        company: null as number | null,
    };

    const {
        objectRule,
        objectRequiredRule,
        email,
        enumRuleRequired,
        pointNumber,
        nullableInterger,
        momentSchema,
    } = useObjectValidations();
    const {
        INVALID_FORMAT,
        UP_TO_MAX_CHARACTERS,
        FIELD_SHOULD_BE_FILLED,
        FIELD_SHOULD_BE_ΕΜPΤΥ,
    } = useTranslationList();

    function name(maxCharacters: number) {
        return yup
            .string()
            .max(maxCharacters, UP_TO_MAX_CHARACTERS)
            .matches(/^[a-zA-Zа-яА-Я\s_.,]+$/, INVALID_FORMAT);
    }

    function numberishText(maxCharacters: number) {
        return yup
            .string()
            .max(maxCharacters, UP_TO_MAX_CHARACTERS)
            .matches(/^[a-zA-Zа-яА-Я\d\s\\/\-,.()№#;*:^_]+$/, INVALID_FORMAT);
    }

    const validationSchema = yup.object().shape({
        contractNumber: numberishText(50).required(FIELD_SHOULD_BE_FILLED),
        contractName: numberishText(255).required(FIELD_SHOULD_BE_FILLED),
        contractDuration: yup
            .object({
                start: momentSchema.required(FIELD_SHOULD_BE_FILLED),
                end: momentSchema.required(FIELD_SHOULD_BE_FILLED),
            })
            .required(FIELD_SHOULD_BE_FILLED),
        country: objectRule<GetCountries_countries>().nullable(),
        contractCountry: objectRule<GetCountries_countries>().nullable(),
        paymentSystem: yup
            .object({
                id: yup.number().nullable().required(FIELD_SHOULD_BE_FILLED),
                fields: yup.array().of(
                    yup
                        .object({
                            id: yup.string().required(FIELD_SHOULD_BE_FILLED),
                            value: yup.string(),
                        })
                        .required(),
                ),
            })
            .nullable()
            .required(FIELD_SHOULD_BE_FILLED),
        contactFullName: name(255),
        phone: yup.string().matches(phoneRegex, INVALID_FORMAT),
        email: email(),
        currencyAdversitingPayments:
            // eslint-disable-next-line max-len
            objectRequiredRule<GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments>(),
        contractSumm: pointNumber(),
        // validate array of objects contractPlacements
        contractPlacements: yup.array().of(
            yup
                .object({
                    type: enumRuleRequired<PlacementType>(),
                    typeName: yup.string().when("type", {
                        is: (type: PlacementType) =>
                            type === PlacementType.OTHER,
                        then: name(50).required(FIELD_SHOULD_BE_FILLED),
                        // otherwise field should be empty
                        otherwise: yup.string().test({
                            test: value => !value,
                            message: FIELD_SHOULD_BE_ΕΜPΤΥ,
                        }),
                    }),
                    sum: pointNumber(),
                    percentSum: yup.boolean().required(),
                })
                .required(),
        ),
        message: numberishText(1023),
        company: nullableInterger,
        finplanContractStatus: enumRuleRequired<FinantialPlanStatus>(),
    });

    return {
        initialValues,
        validationSchema,
    };
}
