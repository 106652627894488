import { RefObject } from "react";
import StateManager from "react-select";
import { FormikHandlers, FormikState } from "components/formik";
import _ from "lodash";

export interface IProps {
    options: Options;
    selectFirst?: boolean;
    isLoading?: boolean;
    form: FormikState<unknown>;
    // вызывается при "начале" изменения значения, и определяет как оно будет изменено (?)
    onChange: FormikHandlers["handleChange"];
    // вызывается по-факту, после изменения значения в селекте
    fieldOnChange?: FormikHandlers["handleChange"];
    name: string;
    id: string;
    value: { id: string; name: string };
    isSearchable?: boolean;
    isClearable?: boolean;
    innerRef: RefObject<StateManager>;
}

type Options = Array<{
    value: unknown;
    label: FunctionStringCallback;
    isDisabled: boolean;
}>;

interface IChangeOption {
    onChange: FormikHandlers["handleChange"];
    fieldOnChange?: FormikHandlers["handleChange"];
    name: string;
    selectedOption: SelectedOption;
}

export type SelectedOption =
    | {
          value: unknown;
          label: FunctionStringCallback;
          isDisabled: boolean;
      }
    | undefined;

export const useSelect = ({
    options,
    value,
    onChange,
    fieldOnChange,
    name,
    selectFirst,
}: IProps) => {
    const selectedOption = React.useMemo(() => {
        let curSelectedOption = _.find(options, { value });

        // Если не нашли, пробуем искать другим способом.
        // Подходит в случаях когда value - объект.
        // Судя по типам, value - всегда объект, но на самом деле это не так,
        // есть еще несоклько мест где в value стоит просто число - id .
        // Проблема с обычным find в том, что если value меняется в процессе
        // редактирования формы (случай с редактированием поля платежного метода),
        // то обычный файнд больше его не находит и поле селекта сбрасывается.
        if (curSelectedOption === undefined && value && value.id) {
            curSelectedOption = _.find(options, { value: { id: value.id } });
        }

        if (selectFirst && !curSelectedOption) {
            curSelectedOption = _.first(options);
            if (curSelectedOption) {
                setTimeout(
                    () =>
                        changeOption({
                            name,
                            selectedOption: curSelectedOption,
                            onChange,
                            fieldOnChange,
                        }),
                    1,
                );
            }
        }

        return curSelectedOption;
    }, [options, value, selectFirst, name, onChange, fieldOnChange]);

    return [options, selectedOption];
};

export const changeOption = ({
    onChange,
    fieldOnChange,
    selectedOption: selectValue,
    name,
}: IChangeOption) => {
    const e = {
        target: {
            value: selectValue ? selectValue.value : undefined,
            name,
        },
    };
    if (typeof fieldOnChange === "function") {
        fieldOnChange(e);
    }
    return onChange(e);
};
