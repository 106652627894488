import * as yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Title } from "services/styled-components/typogoraphy";
import { useTranslationList } from "hooks/use-translations-list";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { Field, Form } from "components/formik";
import { FormDirectionRow, FormGroup, FormGroupButton } from "styled/layouts";
import {
    AddFile,
    getFileNameWithoutExtention,
    uploadFile,
} from "views/manager/advertising/components/add-file";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";
import { useParams } from "react-router-dom";
import { TPathsToUpload } from "server/controllers/api/files-api/upload-file-general";
import { useObjectValidations } from "validation/validaion";
interface IAddPaymentFile {
    onLoaded: () => void;
}

export const AddPaymentFile = ({ onLoaded }: IAddPaymentFile) => {
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT } = useTranslationList();
    const { fileRule } = useObjectValidations();

    const accept = ".pdf, image/jpeg, image/jpg, image/png";
    const validationSchema = yup.object().shape({
        fileName: yup
            .string()
            .required(FIELD_SHOULD_BE_FILLED)
            .matches(/^[a-zA-ZА-Яа-я0-9 _\-()]{1,30}$/, INVALID_FORMAT),
        file: fileRule(accept),
    });
    const { id } = useParams<{ id: string }>();

    return (
        <>
            <Title>{__("Добавить платежный файл")}</Title>
            <FormikConsoleErrors
                initialValues={{
                    fileName: "",
                    file: null,
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmitWrapper(async values => {
                    try {
                        if (values.file) {
                            await uploadFile(
                                `partners/uploadpaymentfile/${id}` as TPathsToUpload,
                                values.file,
                                {
                                    Name: values.fileName,
                                },
                            );
                            toast.success(__("Файл успешно загружен"));
                            onLoaded();
                        }
                    } catch (e: any) {
                        void errorHandler(e, error => {
                            switch (error.data.code) {
                                case ErrorType.INVALID_FILE_STRUCTURE:
                                    toast.error(
                                        __("Неверная структура файла."),
                                    );
                                    break;
                            }
                        });
                    }
                }, validationSchema)}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <FormDirectionRow>
                            <FormGroup>
                                <Field
                                    name="fileName"
                                    type="text"
                                    label={__("Имя файла")}
                                    style={{ minWidth: "200px" }}
                                />
                            </FormGroup>
                            <AddFile
                                name="file"
                                values={values}
                                setFieldValue={(field, value) => {
                                    setFieldValue(field, value);
                                    if (value) {
                                        setFieldValue(
                                            "fileName",
                                            getFileNameWithoutExtention(
                                                (value as File).name,
                                            ),
                                        );
                                    }
                                }}
                                accept={accept}
                                buttonType="clean"
                                placeholder={__(
                                    "*.jpeg, *.jpg, *.png или *.pdf",
                                )}
                                stylesFileField={{ minWidth: "260px" }}
                            />
                            <FormGroupButton>
                                <FakeLabel />
                                <SubmitButton>{__("Добавить")}</SubmitButton>
                            </FormGroupButton>
                        </FormDirectionRow>
                    </Form>
                )}
            </FormikConsoleErrors>
        </>
    );
};
