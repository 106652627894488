import { ReactNode, useState, memo } from "react";
import { useTranslation, TFunction } from "react-i18next";
import { useObjectValidations } from "validation/validaion";
import { GetCountries_countries } from "gql/types/operation-result-types";
import { Title } from "services/styled-components/typogoraphy";
import { Field } from "components/formik";
import {
    IBaseFieldProps,
    defaultInputFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";
import { UniversalForm } from "components/filters";
import { UniqueValidation, SubmittedValueType } from "components/filters/types";
import { CountryField } from "components/filters/fields/country";
import { Checkbox } from "components/formik/universal-field/fields/checkbox";
import { FormGroupCheckbox } from "styled/layouts";
import { TableWrap } from "views/manager/main/partners-groups/common";

const getAdditionalFields = (
    __: TFunction,
    objectValidations: ReturnType<typeof useObjectValidations>,
) => ({
    partnerId: {
        initialValue: null,
        component: (props: IBaseFieldProps) => (
            <Field
                {...defaultInputFieldProps(props)}
                label={__("ID партнера")}
                placeholder={__("1, 2, 3")}
            />
        ),
        validationSchema: objectValidations.nullableInterger,
    },
    country: {
        initialValue: undefined,
        component: (props: IBaseFieldProps) => (
            <CountryField {...selectFieldsProps(props)} isClearable />
        ),
        validationSchema:
            objectValidations.objectRule<GetCountries_countries>(),
    },
});

interface IChildrenProps {
    submittedValues?: SubmittedValueType<
        never,
        UniqueValidation<never>,
        ReturnType<typeof getAdditionalFields>
    >;
    showPartnersWithoutGroup: boolean;
}

interface IProps {
    tableName?: string;
    withoutGroupsFilter?: boolean;
    children: (props: IChildrenProps) => ReactNode;
}

const PartnersFilterComponent = ({
    tableName,
    withoutGroupsFilter,
    children,
}: IProps) => {
    const [__] = useTranslation();
    const objectValidations = useObjectValidations();
    const [showPartnersWithoutGroup, setShowPartnersWithoutGroup] =
        useState(false);

    return (
        <TableWrap>
            <UniversalForm
                fields={[]}
                additionalFields={getAdditionalFields(__, objectValidations)}
                submitButtonName={__("Поиск")}
            >
                {({ form, submittedValues }) => (
                    <>
                        <Title>{tableName}</Title>
                        {withoutGroupsFilter && (
                            <FormGroupCheckbox>
                                <Checkbox
                                    id="showPartnersWithoutGroup"
                                    type="checkbox"
                                    checked={showPartnersWithoutGroup}
                                    label={__("Только партнеры без группы")}
                                    onChange={e =>
                                        setShowPartnersWithoutGroup(
                                            e.target.checked,
                                        )
                                    }
                                />
                            </FormGroupCheckbox>
                        )}
                        {form}
                        {children({
                            submittedValues,
                            showPartnersWithoutGroup,
                        })}
                    </>
                )}
            </UniversalForm>
        </TableWrap>
    );
};

export const PartnersFilter = memo(PartnersFilterComponent);
