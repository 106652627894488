import { useUser } from "./query/use-user";
import { browserLoglevelRemote } from "@webx/loglevel-remote/lib/browser-loglevel-remote";
import { useEffect } from "react";
import { usePartnersProgram } from "./use-partners-program-id";

export function useAddUserDataToLog() {
    const partnersProgramId = usePartnersProgram()?.programId;
    const { user } = useUser();

    useEffect(() => {
        browserLoglevelRemote.setPersistentArgs({
            partnersProgramId: partnersProgramId || null,
            id: user?.id || null,
            affiliateId: user?.affiliateId || null,
        });

        return () => {
            browserLoglevelRemote.unsetPersistentArgs(
                "partnersProgramId",
                "id",
                "affiliateId",
            );
        };
    }, [partnersProgramId, user]);
}
