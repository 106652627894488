import { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { PickCurrentLineContext } from "components/get-active-link-context";
import {
    ReferralDomains as IReferralDomains,
    ReferralDomainsVariables,
    ReferralDomains_authorized_manager_main_referralDomains_data,
    Partner,
    PartnerVariables,
} from "gql/types/operation-result-types";
import { ReactTable } from "components/react-table";
import { AdminWrapper } from "styled/layouts";
import { DeleteDomainModal } from "./delete-domain-modal";
import { AddOrEditDomainForm } from "./add-or-edit-domain-form";
import { useColumns } from "./use-columns";
import GET_PARTNER from "views/manager/main/partner-list/edit-partner/gql/get-partner.gql";
import REFERRAL_DOMAINS from "./referral-domains.gql";

export const ReferralDomains = () => {
    const { params } = useRouteMatch<{ id: string }>();
    const partnerId = Number(params.id);
    const [currencyNameToDelete, setCurrencyNameToDelete] = useState<
        string | null
    >(null);
    const [domainToEdit, setDomainToEdit] =
        useState<ReferralDomains_authorized_manager_main_referralDomains_data | null>(
            null,
        );
    const { setColumnData } = useContext(PickCurrentLineContext);
    const externalPaginationAndSorting = usePaginationAndSorting();
    const { data, loading } = useQuery<
        IReferralDomains,
        ReferralDomainsVariables
    >(REFERRAL_DOMAINS, {
        variables: {
            params: {
                ...externalPaginationAndSorting.state,
                id: partnerId,
            },
        },
    });
    const { data: partnerData } = useQuery<Partner, PartnerVariables>(
        GET_PARTNER,
        {
            variables: {
                id: partnerId,
            },
        },
    );
    const partner = partnerData?.authorized.manager.main.partner;
    const columns = useColumns({
        disabled: !!(currencyNameToDelete || domainToEdit),
        onEdit: domain => setDomainToEdit(domain),
        onDelete: currencyName => setCurrencyNameToDelete(currencyName),
    });
    const tableData = data?.authorized.manager.main.referralDomains;
    const formShown =
        domainToEdit ||
        (tableData &&
            partner?.currencies &&
            tableData.totalCount < partner.currencies.length);

    useEffect(
        () => () => {
            // сбрасываем после редактирования в AddOrEditDomainForm
            setColumnData({
                name: "",
                id: null,
                tableName: "",
            });
        },
        [setColumnData],
    );

    return (
        <AdminWrapper>
            {formShown && (
                <AddOrEditDomainForm
                    domain={domainToEdit}
                    paginationAndSortingState={
                        externalPaginationAndSorting.state
                    }
                    resetDomain={() => setDomainToEdit(null)}
                />
            )}
            <ReactTable
                tableName="referral-domains"
                columns={columns}
                data={tableData?.data || []}
                dataLength={tableData?.totalCount}
                externalPaginationAndSorting={externalPaginationAndSorting}
                loading={loading}
            />
            <DeleteDomainModal
                paginationAndSortingState={externalPaginationAndSorting.state}
                currencyNameToDelete={currencyNameToDelete}
                onClose={() => setCurrencyNameToDelete(null)}
            />
        </AdminWrapper>
    );
};
