import {
    GetCountries_countries,
    GetLanguages_languages,
    GetMessengers_messengers,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetCommissionGroups_authorized_manager_main_commisionGroups,
    GetPartnersGroups_authorized_manager_main_partnersGroups,
} from "gql/types/operation-result-types";

const initialValues = {
    login: "",
    password: "",
    confirmPassword: "",
    website: "",
    language: null as null | GetLanguages_languages,
    firstname: "",
    surname: "",
    email: "",
    messenger: null as null | GetMessengers_messengers,
    messengerLogin: "",
    country: null as null | GetCountries_countries,
    phone: "",
    currency:
        null as null | GetCurrencies_authorized_partnerAndManager_data_currencies,
    message: "",
    commisionGroup:
        null as null | GetCommissionGroups_authorized_manager_main_commisionGroups,
    partnersGroup:
        null as null | GetPartnersGroups_authorized_manager_main_partnersGroups,
};

export function useInitialValues() {
    return initialValues;
}
