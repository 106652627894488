import { Postback, PostbackVariables } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import POSTBACK from "../gql/postback.gql";

export const usePostback = (id: number) => {
    const queryResult = useQuery<Postback, PostbackVariables>(POSTBACK, {
        ssr: true,
        fetchPolicy: "network-only",
        variables: { id },
    });
    const data = queryResult.data?.authorized.partner.postback.postback;
    return { data, error: queryResult.error };
};
