import { Field } from "components/formik";
import { useHowDidYouKnowWithTranslations } from "hooks/query/query-with-translations/use-how-did-you-know-with-translations";
import { useTranslation } from "react-i18next";
import { ISelectFild } from "../select-filed-interface";

export const HowDidYouKnowField = React.memo((props: ISelectFild) => {
    const [__] = useTranslation();
    const { ...rest } = props;
    const { howDidYouKnowList, loading } = useHowDidYouKnowWithTranslations();

    return (
        <Field
            label={__("Как вы узнали о нас?")}
            type="react-select"
            options={howDidYouKnowList}
            isLoading={loading}
            {...rest}
        />
    );
}) as React.ComponentType<ISelectFild>;
