/* eslint-disable no-nested-ternary */
import { ContentLoader } from "components/components-common/content-loader";
import { TwoFAForm } from "components/two-fa-form";
import {
    DeactivateTwoFA,
    DeactivateTwoFAVariables,
    GetAuthState,
} from "gql/types/operation-result-types";
import { useMemo, useState } from "react";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { Title } from "services/styled-components/typogoraphy";
import { ButtonColored } from "styled/button";
import { AdminWrapperEdit } from "styled/layouts";
import GET_AUTH_STATE from "./gql/get-auth-state.gql";
import { LocalizedNavLink } from "components/language-provider";
import { List } from "react-content-loader";
import {
    ITwoFACode,
    use2faValidationSchema,
    use2faInitialValues,
} from "hooks/sign-in/2fa/use-2fa-submit";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { NoData } from "components/no-data";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    basePath: string;
}

const DEACTIVATE_TWO_FA = gql`
    mutation DeactivateTwoFA($code: String!) {
        authorized {
            partnerAccount {
                twoFA {
                    deactivate(code: $code)
                }
            }
        }
    }
`;

export const TwoFactorAuth = ((props: IProps) => {
    const [__] = useTranslation();
    const { data, refetch, loading } = useQuery<GetAuthState>(GET_AUTH_STATE);
    const [tryingTurnOffTwoFa, setTryingTurnOffTwoFa] = useState(false);
    const [mutation] = useMutation<DeactivateTwoFA, DeactivateTwoFAVariables>(
        DEACTIVATE_TWO_FA,
    );

    const state = g(data, "authorized", "partnerAccount", "twoFA", "state");

    const validationSchema = use2faValidationSchema();
    const initialValues = use2faInitialValues();

    const onSubmit = useMemo(() => {
        return onSubmitWrapper(async (values: ITwoFACode) => {
            try {
                await mutation({
                    variables: { code: values.code },
                });
                await refetch();
                setTryingTurnOffTwoFa(false);
            } catch (e: any) {
                void errorHandler(e);
            }
        }, validationSchema);
    }, [mutation, refetch, validationSchema]);

    if (!state) {
        return <NoData />;
    }

    return (
        <AdminWrapperEdit>
            <ContentLoader component={List} loading={loading}>
                <Title>{__("Управление двухфакторной аутентификацией")}</Title>
                <p>
                    {format(__("Google Authenticator включен: {result}"), {
                        result: state.enabled ? __("Да") : __("Нет"),
                    })}
                </p>
                {state.enable2FAChanging ? (
                    state.enabled && !tryingTurnOffTwoFa ? (
                        <ButtonColored
                            onClick={() => setTryingTurnOffTwoFa(true)}
                        >
                            {__("Выключить")}
                        </ButtonColored>
                    ) : tryingTurnOffTwoFa ? (
                        <TwoFAForm
                            validationSchema={validationSchema}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                            submitText={__("Выключить")}
                        />
                    ) : (
                        <LocalizedNavLink to="/partner/user-profile/two-fa-activation">
                            <ButtonColored>{__("Включить")}</ButtonColored>
                        </LocalizedNavLink>
                    )
                ) : (
                    <>
                        <p>
                            {__(
                                "Вы находитесь в режиме просмотра данных партнера и не можете изменить его поле 2FA настроек.",
                            )}
                            {__(
                                "Только партнер может изменять поле настройки 2FA (собственные настройки 2FA для аккаунта).",
                            )}
                        </p>
                    </>
                )}
            </ContentLoader>
        </AdminWrapperEdit>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
