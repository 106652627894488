import { useTranslation } from "react-i18next";
import GET_POSTBACKS from "../gql/postbacks.gql";
import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetPostbacks,
    GetPostbacksVariables,
    PostbackStatus,
    SortedPostbacksType,
} from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { ReactTable } from "components/react-table";
import { useRef } from "react";
import { Search } from "components/search";
import { AllUniversalOutputValues } from "components/filters/types";
import { getPostbacksColumns } from "./get-postbacks-columns";

type SubmitValues = Pick<
    AllUniversalOutputValues,
    "partnersGroup" | "websiteId"
> & {
    typeNameId: number | null;
    status: PostbackStatus | null;
    currency?: GetCurrencies_authorized_partnerAndManager_data_currencies;
};
interface IPostbacksTable {
    values: SubmitValues;
}

export const PostbacksTable = ({ values }: IPostbacksTable) => {
    const [__] = useTranslation();
    const externalPaginationAndSorting = usePaginationAndSorting();
    const columns = getPostbacksColumns(__);
    const searchValueRef = useRef("");

    const variables = {
        filter: {
            currencyId: values.currency?.id,
            siteId: values.websiteId,
            partnersGroupId: values.partnersGroup?.id,
            typeNameId: values.typeNameId,
            status: values.status,
        },
        params: {
            ...externalPaginationAndSorting.state,
            sorted: externalPaginationAndSorting.state
                .sorted as unknown as SortedPostbacksType,
            search: searchValueRef.current,
        },
    };

    const queryResult = useQuery<GetPostbacks, GetPostbacksVariables>(
        GET_POSTBACKS,
        {
            ssr: true,
            variables,
            // иначе в таблице не будет отображаться загрузка при рефетче
            notifyOnNetworkStatusChange: true,
        },
    );

    const postbacks = queryResult.data?.authorized.manager.main.postbacks;

    return (
        <ReactTable
            columns={columns}
            data={postbacks?.data}
            dataLength={postbacks?.totalCount}
            externalPaginationAndSorting={externalPaginationAndSorting}
            shownColumnsByDefault={[
                "id",
                "partnerId",
                "campaignId",
                "siteName",
                "url",
                "typeName",
                "statusName",
                "comment",
            ]}
            loading={queryResult.loading}
            searchComponent={
                <Search
                    onTextChanges={text => {
                        searchValueRef.current = text;
                    }}
                    onApply={async () => {
                        externalPaginationAndSorting.actions.setPagenum(1);
                    }}
                />
            }
        />
    );
};
