import { useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import omit from "lodash/omit";
import {
    AddReferralDomain,
    AddReferralDomainVariables,
    UpdateReferralDomain,
    UpdateReferralDomainVariables,
    ReferralDomains_authorized_manager_main_referralDomains_data,
} from "gql/types/operation-result-types";
import { IState as IPaginationAndSortingState } from "hooks/use-pagination";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { FormGroupCheckbox, FormGroupButton, FormGroup } from "styled/layouts";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form, Field } from "components/formik";
import { FakeLabel } from "components/filters/form";
import { CurrencyField } from "components/filters/fields";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { ButtonStyled } from "styled/button";
import { useValidationSchema } from "./use-validation-schema";
import { useInitialValues } from "./use-initial-values";
import { FormRow } from "./styled";
import REFERRAL_DOMAINS from "../referral-domains.gql";
import ADD_REFERRAL_DOMAIN from "./add-referral-domain.gql";
import UPDATE_REFERRAL_DOMAIN from "./update-referral-domain.gql";

interface IProps {
    domain: ReferralDomains_authorized_manager_main_referralDomains_data | null;
    paginationAndSortingState: IPaginationAndSortingState;
    resetDomain: () => void;
}

export const AddOrEditDomainForm = ({
    domain,
    paginationAndSortingState,
    resetDomain,
}: IProps) => {
    const { params } = useRouteMatch<{ id: string }>();
    const partnerId = Number(params.id);
    const [__] = useTranslation();
    const validationSchema = useValidationSchema();
    const [addDomain] = useMutation<
        AddReferralDomain,
        AddReferralDomainVariables
    >(ADD_REFERRAL_DOMAIN);
    const [updateDomain] = useMutation<
        UpdateReferralDomain,
        UpdateReferralDomainVariables
    >(UPDATE_REFERRAL_DOMAIN);
    const { initialValues, loading } = useInitialValues(domain);
    const { setColumnData } = useContext(PickCurrentLineContext);

    if (loading) {
        return null;
    }

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async values => {
                const mutate = domain ? updateDomain : addDomain;

                await mutate({
                    variables: {
                        data: {
                            ...omit(values, "currency"),
                            partnerId,
                            currencyId: Number(values.currency?.id),
                        },
                    },
                    refetchQueries: [
                        {
                            query: REFERRAL_DOMAINS,
                            variables: {
                                params: {
                                    ...paginationAndSortingState,
                                    id: partnerId,
                                },
                            },
                        },
                    ],
                    onError: () => {
                        toast.error(
                            domain
                                ? __("Настройки домена не обновлены")
                                : __("Домен партнерской ссылки не создан"),
                        );
                    },
                    onCompleted: () => {
                        setColumnData({
                            name: "id",
                            id: domain?.id,
                            tableName: "referral-domains",
                        });
                        resetDomain();
                    },
                });
            }}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FormRow>
                        <CurrencyField
                            name="currency"
                            label={__("Валюта")}
                            isClearable={false}
                            partnerId={partnerId}
                        />
                        <FormGroup>
                            <Field
                                name="resource"
                                type="text"
                                label={__("Ресурс")}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <div>
                            <FormGroupCheckbox>
                                <Field
                                    name="autoEmailSending"
                                    type="checkbox"
                                    label={__("Автоматическая рассылка")}
                                />
                            </FormGroupCheckbox>
                            <Field
                                name="autoUpdate"
                                type="checkbox"
                                label={__("Автоматическое обновление")}
                            />
                        </div>
                        <Field name="hostName" type="text" label="Host name" />
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>
                                {domain ? __("Редактировать") : __("Создать")}
                            </SubmitButton>
                        </FormGroupButton>
                        <FormGroupButton>
                            <FakeLabel />
                            {domain && (
                                <ButtonStyled
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={resetDomain}
                                >
                                    {__("Отмена")}
                                </ButtonStyled>
                            )}
                        </FormGroupButton>
                    </FormRow>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
