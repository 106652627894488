import _ from "lodash";

export const getOriginalData = <T = number>(
    arr?: Array<{ id: T; name: string }>,
): Array<{ value: T; label: string }> =>
    _.map(arr, selector => ({
        value: selector.id,
        label: selector.name,
    }));

export const getOriginalDataForObject = <T = number>(
    arr?: Array<{ id: T; name: string }>,
) =>
    _.map(arr, selector => ({
        value: selector,
        label: selector.name,
    }));
