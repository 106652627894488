import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetPartnerGraph,
    GetPartnerGraphVariables,
} from "gql/types/operation-result-types";
import { usePooling } from "hooks/use-pooling";
import { useQuery } from "@apollo/client";
import GET_PARTNER_GRAPH from "../gql/get-partner-graph.gql";

export const usePartnerChart = (
    mockPeriod: string,
    currency:
        | GetCurrencies_authorized_partnerAndManager_data_currencies
        | undefined,
) => {
    const id = g(currency, "id");
    const queryResult = useQuery<GetPartnerGraph, GetPartnerGraphVariables>(
        GET_PARTNER_GRAPH,
        {
            variables: {
                currencyId: Number(id),
                period: Number(mockPeriod),
            },
            skip: !id,
            fetchPolicy: "network-only",
            ssr: false,
        },
    );

    const data = g(
        queryResult.data,
        "authorized",
        "partner",
        "mainStatistic",
        "graph",
        "data",
    );

    const status = g(
        queryResult.data,
        "authorized",
        "partner",
        "mainStatistic",
        "graph",
        "status",
    );

    usePooling(queryResult, status);

    return {
        data,
        loading: queryResult.loading,
        queryResult,
    };
};
