import { User_user } from "gql/types/operation-result-types";
import { useUser } from "hooks/query/use-user";

/**
 * логика проверки может содержаться не только во вьюхе, но и в коде,
 * для удобства сделать хук
 */
export const usePermissions = () => {
    const { user } = useUser();

    const checkPermissions = React.useCallback(
        (guards: Array<(context: { user?: User_user }) => boolean>) => {
            return guards.every(item => item({ user }));
        },
        [user],
    );

    return { checkPermissions };
};
