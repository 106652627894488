import { InfoPageWrapper, AdminWrapper } from "styled/layouts";
import { HowToCreateARef } from "./how-to-create-a-ref";
import { RefForSpecificPage } from "./ref-for-specific-page";

export const PartnerLinksGuide = () => {
    return (
        <AdminWrapper width="50%">
            <InfoPageWrapper>
                <HowToCreateARef />
                <RefForSpecificPage />
            </InfoPageWrapper>
        </AdminWrapper>
    );
};
