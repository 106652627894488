import { useQuery, gql } from "@apollo/client";
import { GetPartnersProgram } from "gql/types/operation-result-types";

const GET_PARTNERS_PROGRAM = gql`
    query GetPartnersProgram {
        partnersProgram {
            partnersProgram {
                programId
                showLanding
            }
        }
    }
`;

export const usePartnersProgram = () => {
    const { data } = useQuery<GetPartnersProgram>(GET_PARTNERS_PROGRAM, {
        ssr: true,
    });

    return data?.partnersProgram.partnersProgram;
};
