import { LanguageSwitcher } from "components/components-common/language/language-switcher";
import {
    LanguageActive,
    LanguageList,
} from "components/components-common/language/language-switcher/styled";
import styled, { css } from "styled-components";
import { LinkToUserProfile } from "./link-to-user-profile";
import { SignOutComponent } from "./sign-out";
import { rtl } from "services/styled-components/rtl";
import { SignOutManager } from "./sign-out-manager";

export const AsideLinkItemSC = css`
    padding: 15px 5px 15px 0;
    color: ${props => props.theme.textColor};
    font-size: 16px;
    display: flex;
    align-items: center;
    background: ${props => props.theme.asideMenuItemColor};
    border-top: 1px solid ${props => props.theme.backgroundColor};
    &:link,
    &:visited {
        color: ${props => props.theme.textColor};
    }
    &:active {
        color: ${props => props.theme.activeColor};
    }
    & svg {
        width: 50px;
        flex-shrink: 0;
    }
`;

export const LinkProfile = styled(LinkToUserProfile)`
    ${AsideLinkItemSC}
`;

export const SignOut = styled(SignOutComponent)`
    ${AsideLinkItemSC}
`;

export const SignOutFromPartnerInterface = styled(SignOutManager)`
    ${AsideLinkItemSC}
`;

export const AsideLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AsideLanguageSwitcher = styled(LanguageSwitcher)`
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    background: ${props => props.theme.asideMenuItemColor};
    border-top: 1px solid ${props => props.theme.backgroundColor};
    ${LanguageList} {
        position: relative;
        width: 100%;
        top: auto;
        right: auto;
        left: auto;
        & li {
            width: calc(100% / 3);
        }
    }
    ${LanguageActive} {
        ${rtl`padding-left: 15px;`};
        &:after {
            ${rtl`margin-left: 15px;`};
        }
    }
`;
