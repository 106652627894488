import { ReportStatus } from "gql/types/operation-result-types";
import { QueryResult } from "@apollo/client";

export const usePooling = <ReportType, ReportVariablesType>(
    queryResult?: QueryResult<ReportType, ReportVariablesType>,
    status?: ReportStatus,
    time?: number,
) => {
    if (!queryResult) {
        return;
    }
    const { startPolling, stopPolling, loading } = queryResult;
    if (loading) {
        queryResult.loading = true;
        return;
    }

    if (status) {
        if (status === ReportStatus.PENDING) {
            queryResult.loading = true;
            startPolling(time ? time : 2000);
        } else {
            queryResult.loading = false;
            stopPolling();
        }
    }
    if (queryResult.error) {
        queryResult.stopPolling();
        queryResult.loading = false;
    }
};
