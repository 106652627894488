import { useCallback } from "react";
import { SetFieldValue } from "components/formik";

export interface IListItem {
    id: number | string;
}

interface IUseControlCheckboxHandlersParams {
    list: IListItem[];
    fieldName: string;
    setFieldValue: SetFieldValue;
}

export const useControlCheckboxHandlers = ({
    list,
    fieldName,
    setFieldValue,
}: IUseControlCheckboxHandlersParams) => {
    const onAddAll = useCallback(
        (data: IListItem[]) => {
            const listIds = list.map(({ id }) => id);
            setFieldValue(fieldName, [
                ...list,
                ...data.filter(({ id }) => !listIds.includes(id)),
            ]);
        },
        [fieldName, list, setFieldValue],
    );
    const onAdd = useCallback(
        (data: unknown) => setFieldValue(fieldName, [...list, data]),
        [fieldName, list, setFieldValue],
    );

    const onRemoveAll = useCallback(
        () => setFieldValue(fieldName, []),
        [fieldName, setFieldValue],
    );
    const onRemove = useCallback(
        (data: IListItem) => {
            const newPartners = list.filter(partner => partner.id !== data.id);
            setFieldValue(fieldName, newPartners);
        },
        [fieldName, list, setFieldValue],
    );

    return { onAdd, onAddAll, onRemove, onRemoveAll };
};
