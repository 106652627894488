import { GetMessengers } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_MESSENGERS = gql`
    query GetMessengers {
        messengers {
            id
            name
            validationTemplate
            isEnabled
            placeholder
        }
    }
`;

export const useMessengers = () => {
    const messengersList = useQuery<GetMessengers>(GET_MESSENGERS, {
        ssr: true,
    });

    const messengers = messengersList.data?.messengers;

    return {
        messengers,
        loading: messengersList.loading,
        error: messengersList.error,
    };
};
