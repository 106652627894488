import { Field, useFormikContext } from "components/formik";
import { useCommissionGroups } from "hooks/query/use-commission-groups";
import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

export const ComissionGroupField = React.memo((props: ISelectFild) => {
    const { name, ...rest } = props;
    const [__] = useTranslation();
    const { data, loading } = useCommissionGroups();
    const { values, status } = useFormikContext();
    const currency = (values as any).currency;

    const options = useMemo(() => {
        return _.map(data, commissionGroup => {
            return {
                value: commissionGroup,
                label: commissionGroup.name,
                isDisabled: currency
                    ? currency?.name !== commissionGroup?.currency
                    : false,
            };
        });
    }, [data, currency]);

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={props.name}
            label={__("Комиссионная группа")}
            type="react-select"
            options={options}
            isLoading={loading}
            isDisabled={loading}
            blink={status?.commisionGroupBlink}
            minWidth={300}
            {...rest}
        />
    );
}) as React.ComponentType<ISelectFild>;
