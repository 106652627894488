import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useObjectValidations } from "validation/validaion";
import {
    IBaseFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";
import { UniversalForm } from "components/filters";
import { CurrencyField } from "components/filters/fields/currency";
import { LanguageField } from "components/filters/fields/language";
import GET_CAMPAIGNS from "./gql/get-campaigns.gql";
import {
    Campaigns as TCampaigns,
    CampaignsVariables,
    GetLanguages_languages,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
} from "gql/types/operation-result-types";
import { CampaignsTable } from "./campaigns-table";
import { getCampaignsFilter } from "./helpers";

export const Campaigns = () => {
    const [__] = useTranslation();
    const { objectRule } = useObjectValidations();
    const [queryCount, setQueryCount] = useState(0);
    const queryResult = useQuery<TCampaigns, CampaignsVariables>(
        GET_CAMPAIGNS,
        {
            variables: {},
            ssr: true,
            onCompleted: () =>
                setQueryCount(curQueryCount => curQueryCount + 1),
        },
    );

    return (
        <UniversalForm
            fields={["campaignId", "campaignName", "campaignStatus"]}
            additionalFields={{
                currency: {
                    initialValue: undefined,
                    component: (props: IBaseFieldProps) => (
                        <CurrencyField
                            {...selectFieldsProps(props)}
                            placeholder={__("Все")}
                            label={__("Валюта")}
                        />
                    ),
                    validationSchema:
                        objectRule<GetCurrencies_authorized_partnerAndManager_data_currencies>(),
                },
                language: {
                    initialValue: undefined,
                    component: (props: IBaseFieldProps) => (
                        <LanguageField
                            {...selectFieldsProps(props)}
                            placeholder={__("Все")}
                        />
                    ),
                    validationSchema: objectRule<GetLanguages_languages>(),
                },
            }}
            submitButtonName={__("Поиск")}
            onSubmit={async values => {
                await queryResult.refetch({
                    filter: getCampaignsFilter(values),
                });
            }}
        >
            {({ form, submittedValues }) => (
                <>
                    {form}
                    <CampaignsTable
                        campaignsQueryResult={queryResult}
                        filter={getCampaignsFilter(submittedValues)}
                        resultHash={queryCount.toString()}
                    />
                </>
            )}
        </UniversalForm>
    );
};
