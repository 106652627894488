import styled from "styled-components";

export const FileWrapper = styled.span`
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: fit-content;
    margin-bottom: 20px;
`;

export const FileInput = styled.input.attrs({
    type: "file",
})`
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
`;

export const FileInputLabel = styled.label`
    display: flex;
    position: relative;
    cursor: pointer;
    opacity: 0.2;
    transition: opacity 0.1s ease-in;

    &:hover,
    &:active {
        opacity: 0.8;
    }
`;
