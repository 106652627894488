import { FormikContextType } from "components/formik";
import { useTranslation } from "react-i18next";
import {
    changeOption,
    IMultiSelectProps,
    useSelect,
    SelectedOption,
} from "./hooks/use-select";
import { SelectSC } from "../select/styled";

interface IInjectedProps {
    formik: FormikContextType<any>;
}

export const MultiSelect = React.memo((props: IMultiSelectProps) => {
    const [__] = useTranslation();
    const {
        onChange,
        fieldOnChange,
        selectFirst,
        isLoading,
        isSearchable = true,
        isClearable = true,
        name,
        ...rest
    } = props;

    const [options, selectedOptions] = useSelect({
        ...rest,
        onChange,
        fieldOnChange,
        selectFirst,
        name,
    });

    return (
        <SelectSC
            classNamePrefix="react-select"
            placeholder={__("Выберите...")}
            {...rest}
            options={options}
            value={selectedOptions || []}
            onChange={(selected: SelectedOption[]) => {
                return changeOption({
                    selectedOptions: selected,
                    fieldOnChange,
                    onChange,
                    name,
                });
            }}
            isSearchable={isSearchable}
            isClearable={isClearable}
            menuPlacement="auto"
            isMulti
        />
    );
}) as React.ComponentType<Subtract<IMultiSelectProps, IInjectedProps>>;
