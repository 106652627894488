import { useMutation, useQuery } from "@apollo/client";
import {
    GetPostback,
    GetPostbackVariables,
    ModeratePostback as ModeratePostbackData,
    ModeratePostbackVariables,
} from "gql/types/operation-result-types";
import GET_POSTBACK from "./gql/get-postback.gql";
import MODERATE_POSTBACK from "./gql/moderate-postback.gql";
import { useRouteMatch } from "react-router-dom";
import { getInitialValues } from "./get-initial-values";
import { useValidationSchema } from "./hooks/use-validation-schema";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { SuccessTypes, successHandler } from "services/success-handler";
import { errorHandler } from "services/error-handler";
import { ModeratePostbackForm } from "./moderate-postback-form";
import { List } from "react-content-loader";
import { ContentLoader } from "components/components-common/content-loader";

interface IMatchParams {
    id: string;
}

export const ModeratePostback = () => {
    const match = useRouteMatch<IMatchParams>();

    const [moderate, { loading: moderateLoading }] = useMutation<
        ModeratePostbackData,
        ModeratePostbackVariables
    >(MODERATE_POSTBACK);

    const postbackId = Number(match.params.id);

    const { data, loading } = useQuery<GetPostback, GetPostbackVariables>(
        GET_POSTBACK,
        {
            variables: {
                postbackId,
            },
        },
    );
    const postback = data?.authorized.manager.main.postback;

    const initialValues = getInitialValues(postbackId, postback);
    const validationSchema = useValidationSchema();

    return (
        <ContentLoader component={List} loading={loading || moderateLoading}>
            <FormikConsoleErrors
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitWrapper(async values => {
                    try {
                        await moderate({
                            variables: {
                                filter: {
                                    id: Number(values.id),
                                    guid: values.guid || "",
                                    partnerId: Number(values.partnerId),
                                    campaignId: Number(values.campaignId),
                                    statusId: Number(values.newStatusId),
                                    siteId: Number(values.siteId),
                                    internalId: Number(values.internalId),
                                    typeRequestId: Number(values.typeRequestId),
                                    postbackTypeId: Number(
                                        values.postbackTypeId,
                                    ),
                                    variable: values.variable,
                                    minSum: values.minSum,
                                    minSumBet: values.minSumBet,
                                    url: values.url,
                                    staticParameters: values.staticParameters,
                                    isClickIdPostbackParameter:
                                        values.isClickIdPostbackParameter,
                                    clickIdPostbackParameter:
                                        values.clickIdPostbackParameter,
                                    isPartnerIdPostbackParameter:
                                        values.isPartnerIdPostbackParameter,
                                    partnerIdPostbackParameter:
                                        values.partnerIdPostbackParameter,
                                    isTransaction: values.isTransaction,
                                    transaction: values.transaction,
                                    isSiteIdPostbackParameter:
                                        values.isSiteIdPostbackParameter,
                                    siteIdPostbackParameter:
                                        values.siteIdPostbackParameter,
                                    isOtherPostbackParameter:
                                        values.isOtherPostbackParameter,
                                    otherPostbackParameter:
                                        values.otherPostbackParameter,
                                    isSumdepPostbackParameter:
                                        values.isSumdepPostbackParameter,
                                    sumdepPostbackParameter:
                                        values.sumdepPostbackParameter,
                                    childGuid: values.childGuid,
                                },
                            },
                            refetchQueries: [
                                {
                                    query: GET_POSTBACK,
                                    variables: {
                                        postbackId,
                                    },
                                },
                            ],
                        });

                        return successHandler(
                            SuccessTypes.MODERATE_POSTBACK,
                            match.params.id,
                        );
                    } catch (error: any) {
                        void errorHandler(error);
                        throw error;
                    }
                }, validationSchema)}
            >
                {() => <ModeratePostbackForm />}
            </FormikConsoleErrors>
        </ContentLoader>
    );
};
