import { Modal } from "components/components-common/modal";
import { ModalSize } from "components/components-common/modal/modal-size";
import { ModalStyle } from "components/components-common/modal/modal-style";
import { useModalState } from "hooks/use-modal-state";
import { CSSProperties } from "react";
import { ClicksShieldWithCursor } from "./clicks-shield-with-cursor";
import { useResize } from "hooks/use-resize";
import { sizes } from "services/styled-components/variables";

interface IMediaInjectedProps {}

interface IMediaProps extends IMediaInjectedProps {
    media: {
        width?: number;
        height?: number;
        mediaType: { id: string; name: string };
        preview: string;
    };
    previewInsideTable: boolean;
    previewWidth?: number | string;
}

export const useMediaPreviewWidth = () => {
    const { getVisibility } = useResize();
    let previewWidth = 144;
    if (getVisibility(sizes.lg)) {
        previewWidth = 400;
    } else if (getVisibility(sizes.sm)) {
        previewWidth = 300;
    }
    return previewWidth;
};

// рекурсивный медиа превью, в том смысле, что когда на него кликают
// появляется попап с тем же рекурсивно вложенным превью
export const MediaPreviewWithModal = React.memo((props: IMediaProps) => {
    const { modalProps, open } = useModalState({ size: ModalSize.Large });

    return (
        <>
            {/* если кликнули на превью то оно должно появиться в попапе (если оно уже в попапе, то не нужно)*/}
            {(() => {
                if (props.previewInsideTable) {
                    return (
                        <Modal
                            {...modalProps}
                            modalSize={ModalSize.Full}
                            modalStyle={ModalStyle.Full}
                        >
                            <MediaPreviewWithModal
                                {...props}
                                previewInsideTable={false}
                            />
                        </Modal>
                    );
                }
            })()}
            {
                // однако превью в любом случае должно отрендерится в ячейке таблицы или в
                // попапе
            }
            <ClicksShieldWithCursor onClick={open}>
                {(() => {
                    // == ресайзинг превью
                    let tablePreviewStyle: CSSProperties;
                    // если мы внутри таблицы - задаем максимальные размеры
                    if (props.previewInsideTable) {
                        tablePreviewStyle = {
                            maxHeight: 118,
                            maxWidth: props.previewWidth || 612,
                        };
                    }
                    // в противном случае(в попапе), берем размеры из инфы о медиа
                    else {
                        tablePreviewStyle = {
                            height: props.media.height || "100%",
                            width: props.media.width || "100%",
                        };
                    }
                    // ====

                    // "Gif/Jpeg/Png" || "Wallpaper"
                    if (
                        props.media.mediaType.id === "6" ||
                        props.media.mediaType.id === "7"
                    ) {
                        return (
                            <img
                                src={props.media.preview}
                                style={tablePreviewStyle}
                            />
                        );
                    }
                    // "HTML 5"
                    else if (props.media.mediaType.id === "9") {
                        return (
                            <iframe
                                src={props.media.preview}
                                style={tablePreviewStyle}
                                scrolling={"no"}
                            />
                        );
                    }
                    // может быть "Flash", но не встречалось
                    else {
                        return <></>;
                    }
                })()}
            </ClicksShieldWithCursor>
        </>
    );
}) as React.ComponentType<Subtract<IMediaProps, IMediaInjectedProps>>;
