import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import omit from "lodash/omit";
import moment from "moment";
import {
    AddPartnerPartnerGroup,
    AddPartnerPartnerGroupVariables,
} from "gql/types/operation-result-types";
import { IState as IPaginationAndSortingState } from "hooks/use-pagination";
import {
    FormDirectionRow,
    FormDirectionColumn,
    FormGroup,
    FormGroupCheckbox,
    FormGroupButton,
} from "styled/layouts";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form, Field } from "components/formik";
import { FakeLabel } from "components/filters/form";
import { CurrencyField } from "components/filters/fields";
import { PartnerGroupField } from "components/filters/fields/partner-group";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Period } from "components/filters/fields/period";
import { SiteField } from "views/manager/main/partner-list/edit-partner/fields/site";
import { useValidationSchema, TFormValues } from "./use-validation-schema";
import GET_PARTNER_GROUPS from "../get-partner-groups.gql";
import ADD_PARTNER_GROUP from "./add-partner-group.gql";
import { initialValues } from "./initial-values";

interface IProps {
    paginationAndSortingState: IPaginationAndSortingState;
}

export const AddPartnerGroupForm = ({ paginationAndSortingState }: IProps) => {
    const { params } = useRouteMatch<{ id: string }>();
    const partnerId = Number(params.id);
    const [__] = useTranslation();
    const validationSchema = useValidationSchema();
    const [addGroup] = useMutation<
        AddPartnerPartnerGroup,
        AddPartnerPartnerGroupVariables
    >(ADD_PARTNER_GROUP);

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={async (values: TFormValues) => {
                await addGroup({
                    variables: {
                        data: {
                            ...omit(values, [
                                "period",
                                "partnersGroup",
                                "currency",
                                "site",
                            ]),
                            partnerId,
                            partnerGroupId: values.partnersGroup.id,
                            currencyId: Number(values.currency?.id),
                            dateStart: (
                                values.period.start as moment.Moment
                            ).toISOString(),
                            dateEnd: values.period.end?.toISOString(),
                            siteId: values.site?.id,
                        },
                    },
                    refetchQueries: [
                        {
                            query: GET_PARTNER_GROUPS,
                            variables: {
                                params: {
                                    ...paginationAndSortingState,
                                    id: partnerId,
                                },
                            },
                        },
                    ],
                    onCompleted: () => toast.success(__("Группа добавлена")),
                    onError: () => toast.error(__("Группа не добавлена")),
                });
            }}
        >
            {({ setFieldValue, values }) => (
                <Form>
                    <FormDirectionRow>
                        <FormDirectionColumn>
                            <Period
                                name="period"
                                showPeriods={false}
                                label={__("Период")}
                                disabled={values.endless && "endDate"}
                            />
                            <FormGroupCheckbox>
                                <Field
                                    name="endless"
                                    type="checkbox"
                                    label={__("Бессрочная группа")}
                                    onChange={() => {
                                        setFieldValue(
                                            "endless",
                                            !values.endless,
                                        );
                                        setFieldValue(
                                            "period.end",
                                            values.endless
                                                ? moment().endOf("month")
                                                : null,
                                        );
                                    }}
                                />
                            </FormGroupCheckbox>
                        </FormDirectionColumn>
                        <FormGroup>
                            <CurrencyField
                                name="currency"
                                label={__("Валюта")}
                                partnerId={partnerId}
                                isClearable={false}
                                selectFirst
                            />
                        </FormGroup>
                        <FormGroup>
                            <PartnerGroupField name="partnersGroup" />
                        </FormGroup>
                        <FormGroup>
                            <SiteField
                                name="site"
                                partnerId={partnerId}
                                placeholder={__("Все сайты")}
                            />
                        </FormGroup>
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>{__("Добавить")}</SubmitButton>
                        </FormGroupButton>
                    </FormDirectionRow>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
