import { css } from "styled-components";

const fontsPath = "../assets/fonts/helvetica-neue-cyr/";

export const HelveticaNeueCyrFonts = css`
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-thin.ttf") format("truetype");
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-thin-italic.ttf")
            format("truetype");
        font-weight: 100;
        font-style: italic;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-ultra-light.ttf")
            format("truetype");
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-ultra-light-italic.ttf")
            format("truetype");
        font-weight: 200;
        font-style: italic;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-light-italic.ttf")
            format("truetype");
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-roman.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-italic.ttf") format("truetype");
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-medium-italic.ttf")
            format("truetype");
        font-weight: 500;
        font-style: italic;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-bold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-bold-italic.ttf")
            format("truetype");
        font-weight: 700;
        font-style: italic;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-heavy.ttf") format("truetype");
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-heavy-italic.ttf")
            format("truetype");
        font-weight: 800;
        font-style: italic;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-black.ttf") format("truetype");
        font-weight: 900;
        font-style: normal;
    }
    @font-face {
        font-family: "Helvetica Neue Cyr";
        src: url("${fontsPath}helvetica-neue-cyr-black-italic.ttf")
            format("truetype");
        font-weight: 900;
        font-style: italic;
    }
`;
