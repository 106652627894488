import { UniversalField } from "components/formik/universal-field";
import { Field, useFormikContext } from "components/formik";
import { useCurrency } from "hooks/query/use-currency";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

interface IProps extends ISelectFild {
    partnerId?: number;
}

const resetAnotherField = (
    field: string,
    currency: number,
    selectedCurrencyId: number,
    setFieldValue: (field: string, value: any) => void,
    addBlinkStatus: any,
    resetBlinkStatus: any,
    infoText: string,
) => {
    if (selectedCurrencyId && currency === selectedCurrencyId) {
        return;
    }
    setFieldValue(field, undefined);
    addBlinkStatus[`${field}Blink`] = 500;
    resetBlinkStatus[`${field}Blink`] = undefined;
    toast.info(infoText);
};

export const CurrencyField = React.memo((props: IProps) => {
    const { name, partnerId, ...rest } = props;
    const [__] = useTranslation();
    const { data, loading } = useCurrency(partnerId);
    const { values, setFieldValue, setStatus, status } = useFormikContext();
    const campaign = (values as any).campaign;
    const commisionGroup = (values as any).commisionGroup;
    const partnersGroup = (values as any).partnersGroup;
    const fieldOnChange = useCallback(
        (e: any) => {
            const selectedCurrencyId = parseInt(e.target?.value?.id);
            const addBlinkStatus = { ...status };
            const resetBlinkStatus = { ...status };
            if (campaign) {
                const currency = campaign.currency;
                resetAnotherField(
                    "campaign",
                    currency,
                    selectedCurrencyId,
                    setFieldValue,
                    addBlinkStatus,
                    resetBlinkStatus,
                    __(
                        "Валюта не соответствует кампании! Пожалуйста, выберите кампанию заново.",
                    ),
                );
            }
            if (commisionGroup) {
                const currency = commisionGroup.currency;
                resetAnotherField(
                    "commisionGroup",
                    currency,
                    selectedCurrencyId,
                    setFieldValue,
                    addBlinkStatus,
                    resetBlinkStatus,
                    __(
                        "Валюта не соответствует комиссионной группе! Пожалуйста, выберите комиссионную группу заново.",
                    ),
                );
            }
            if (partnersGroup) {
                const currency = partnersGroup.currency;
                resetAnotherField(
                    "partnersGroup",
                    currency,
                    selectedCurrencyId,
                    setFieldValue,
                    addBlinkStatus,
                    resetBlinkStatus,
                    __(
                        "Валюта не соответствует партнерской группе! Пожалуйста, выберите партнерскую группу заново.",
                    ),
                );
            }
            setStatus(addBlinkStatus);
            setTimeout(() => setStatus(resetBlinkStatus), 500);
        },
        [
            __,
            campaign,
            commisionGroup,
            partnersGroup,
            setFieldValue,
            setStatus,
            status,
        ],
    );

    const options = useMemo(() => {
        return _.map(data, c => ({
            value: c as any,
            label: c.name,
        }));
    }, [data]);

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            as="select"
            name={props.name}
            type="react-select"
            component={UniversalField}
            options={options}
            isLoading={loading}
            fieldOnChange={fieldOnChange}
            minWidth={170}
            {...rest}
        />
    );
});
