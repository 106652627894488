import { Field } from "components/formik";
import { PaymentPeriod } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";

export function PaymentPeriodField() {
    const [__] = useTranslation();

    const options = [
        { value: PaymentPeriod.OnesPerMonth, label: __("1 раз в месяц") },
        { value: PaymentPeriod.TwicePerMonth, label: __("2 раза в месяц") },
        { value: PaymentPeriod.OnesPer20Days, label: __("1 раз в 20 дней") },
        { value: PaymentPeriod.OnesPer3Months, label: __("1 раз в 3 мес.") },
        { value: PaymentPeriod.OnesPer4Months, label: __("1 раз в 4 мес.") },
        { value: PaymentPeriod.OnesPer6Months, label: __("1 раз в 6 мес.") },
    ];

    return (
        <Field
            name={"paymentPeriod"}
            label={`${__("Варианты платежей")} *`}
            type="react-select"
            options={options}
            isSearchable={false}
            isClearable={false}
        />
    );
}
