import { css } from "styled-components";

const fontsPath = "../assets/fonts/inter/";

export const InterFonts = css`
    @font-face {
        font-family: "Inter";
        font-display: swap;
        src: url("${fontsPath}Iner-Black.otf") format("opentype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Inter";
        src: url("${fontsPath}Inter-Bold.otf") format("opentype");
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "Inter";
        src: url("${fontsPath}Inter-Medium.otf") format("opentype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Inter";
        src: url("${fontsPath}Inter-SemiBold.otf") format("opentype");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Inter";
        font-display: swap;
        src: url("${fontsPath}Inter-ExtraBold.otf") format("opentype");
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: "Inter";
        font-display: swap;
        src: url("${fontsPath}Inter-regular.otf") format("opentype");
        font-weight: 400;
        font-style: normal;
    }
`;
