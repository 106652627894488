import { FeedbackForm } from "components/feedback-form";
import { SendMessageContainer } from "components/feedback-form/styled";
import {
    StyledError,
    SuccessText,
} from "components/formik/universal-field/styled";
import { FormikHelpers as FormikActions } from "components/formik";
import {
    CreateFeedback,
    CreateFeedbackVariables,
} from "gql/types/operation-result-types";
import { useTranslationList } from "hooks/use-translations-list";
import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import * as yup from "yup";
import CREATE_FEEDBACK from "./gql/create-feedback.gql";
import { createFeedbackMutation } from "./hooks/use-feedback-mutation";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Feedback = React.memo((props: IProps) => {
    const [__] = useTranslation();

    const [mutation] = useMutation<CreateFeedback, CreateFeedbackVariables>(
        CREATE_FEEDBACK,
    );

    const [feedbackResponse, setFeedbackResponse] = useState({
        error: null,
        message: "",
    });
    const {
        FIELD_SHOULD_BE_FILLED,
        UP_TO_MAX_CHARACTERS,
        AT_LEAST_MIN_CHARACTERS,
    } = useTranslationList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const createFeedbackMutationCallback = useCallback(
        createFeedbackMutation(),
        [],
    );

    const validationSchema = useMemo(
        () =>
            yup.object().shape({
                message: yup
                    .string()
                    .required(FIELD_SHOULD_BE_FILLED)
                    .min(20, AT_LEAST_MIN_CHARACTERS)
                    .max(250, UP_TO_MAX_CHARACTERS),
            }),
        [FIELD_SHOULD_BE_FILLED, AT_LEAST_MIN_CHARACTERS, UP_TO_MAX_CHARACTERS],
    );

    return (
        <SendMessageContainer>
            <AdminWrapper>
                <FeedbackForm
                    validationSchema={validationSchema}
                    initialValues={{ message: "" }}
                    onSubmit={(
                        value: { message: string },
                        actions: FormikActions<Record<string, unknown>>,
                    ) =>
                        createFeedbackMutationCallback(
                            mutation,
                            setFeedbackResponse,
                            value,
                            actions,
                            __,
                        )
                    }
                />
                {feedbackResponse.error ? (
                    <StyledError>{feedbackResponse.message}</StyledError>
                ) : (
                    <SuccessText>{feedbackResponse.message}</SuccessText>
                )}
            </AdminWrapper>
        </SendMessageContainer>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
