import { css } from "styled-components";

const fontsPath = "../assets/fonts/open-sans/";

export const OpenSansFonts = css`
    @font-face {
        font-family: "Open Sans";
        src: url("${fontsPath}OpenSans-Bold.woff2") format("woff2"),
            url("${fontsPath}OpenSans-Bold.woff") format("woff");
        font-weight: 700;
        font-style: normal;
    }
`;
