import { GetCommissionList } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import GET_COMISSION_LIST from "../gql/get-comission.gql";

export const useComission = () => {
    const queryResult = useQuery<GetCommissionList>(GET_COMISSION_LIST, {
        ssr: true,
        variables: {},
    });

    const comissionList = g(
        queryResult.data,
        "authorized",
        "partner",
        "comissionStructures",
    );

    return {
        comissionList,
        loading: queryResult.loading,
        error: queryResult.error,
    };
};
