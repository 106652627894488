import React from "react";
import { ExportButton as _ExportButton } from "components/react-table/table-select-variables/export/components/export-button";
import { useSaveSubpartnerReport } from "../../hooks/use-save-subpartner-report";
import { SubpartnerReportFilter } from "gql/types/operation-result-types";

interface IProps {
    filters: SubpartnerReportFilter;
    button: {
        format: string;
        caption: string;
    };
}

export const ExportButton = React.memo((props: IProps) => {
    const {
        button: { format, caption },
        filters,
    } = props;
    const { saving, save } = useSaveSubpartnerReport(filters, format);

    return (
        <_ExportButton
            key={format}
            exportData={save}
            saving={saving}
            caption={caption}
        />
    );
});
