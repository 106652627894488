import { useContext, useEffect, useState } from "react";
import { UniversalForm } from "components/filters";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { useTranslation } from "react-i18next";
import { CreatedPartnerLink } from "../created-link";
import { useCreatePartnerLink } from "../hooks/use-create-partner-link";
import { useObjectValidations } from "validation/validaion";
import { GetCampaigns_authorized_partnerAndManager_data_campaigns } from "gql/types/operation-result-types";
import { useTranslationList } from "hooks/use-translations-list";
import { TFilters, createPartnerLink } from "./create-link";
import { Field } from "components/formik";
import {
    IBaseFieldProps,
    defaultInputFieldProps,
} from "components/filters/services/get-filter-fields";
import { useDownloadApk } from "../hooks/use-download-apk";
import { LoaderSpinner } from "components/loader-spinner";
import { useValidationSchema } from "components/filters/hooks/use-validation-schema";

interface IPartnerLinksFilter {
    goToActiveLinks: () => void;
}

const universalFormParams = {
    directLink: true,
};

export const PARTNER_LINKS_TABLE_NAME = "partner-links";

export const PartnerLinksFilter = ({
    goToActiveLinks,
}: IPartnerLinksFilter) => {
    const [__] = useTranslation();
    const [hasApk, setHasApk] = useState(false);
    const [currentCurrencyId, setCurrentCurrencyId] = useState<string>();
    const [isMainPage, setIsMainPage] = useState(false);
    const { setColumnData } = useContext(PickCurrentLineContext);
    const { loading, mutation, currentLink } = useCreatePartnerLink();
    const { downloadApk, loading: loadingApk } = useDownloadApk();

    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const { objectRule } = useObjectValidations();
    const { landing, subId } = useValidationSchema();

    useEffect(() => {
        if (currentLink) {
            goToActiveLinks();
            setColumnData({
                name: "linkId",
                id: currentLink.id,
                tableName: PARTNER_LINKS_TABLE_NAME,
            });
        }
    }, [currentLink, setColumnData, goToActiveLinks]);

    return (
        <>
            <UniversalForm
                fields={["website", "currency", "campaign"]}
                additionalFields={{
                    landing: {
                        initialValue: "",
                        component: (props: IBaseFieldProps) => {
                            return hasApk || isMainPage ? (
                                <></>
                            ) : (
                                <Field
                                    {...defaultInputFieldProps(props)}
                                    label={__("Целевая страница")}
                                    placeholder="/live"
                                    style={{ minWidth: "200px" }}
                                />
                            );
                        },
                        validationSchema: landing,
                    },
                    subId: {
                        initialValue: "",
                        component: (props: IBaseFieldProps) => {
                            return hasApk ? (
                                <></>
                            ) : (
                                <Field
                                    {...defaultInputFieldProps(props)}
                                    label={__("Sub ID")}
                                    style={{ minWidth: "150px" }}
                                />
                            );
                        },
                        validationSchema: subId,
                    },
                }}
                submitButtonName={
                    hasApk ? __("Скачать АПК") : __("Сгенерировать ссылку")
                }
                params={universalFormParams}
                uniqueValidation={{
                    campaign:
                        objectRule<GetCampaigns_authorized_partnerAndManager_data_campaigns>()
                            .nullable()
                            .required(FIELD_SHOULD_BE_FILLED)
                            .test({
                                message: __(
                                    "Валюта не соответствует кампании! Пожалуйста, выберите кампанию заново.",
                                ),
                                test(campaign) {
                                    return (
                                        campaign?.currency?.toString() ===
                                        currentCurrencyId
                                    );
                                },
                            }),
                }}
                onSubmit={async (submittedValues: TFilters) => {
                    if (!hasApk) {
                        return createPartnerLink(mutation, submittedValues);
                    }

                    void downloadApk(submittedValues);
                }}
            >
                {({ form, actualValues }) => {
                    setCurrentCurrencyId(actualValues?.currency?.id);
                    setHasApk(prev =>
                        prev !== actualValues?.campaign?.hasApk
                            ? Boolean(actualValues?.campaign?.hasApk)
                            : prev,
                    );
                    setIsMainPage(
                        Boolean(actualValues?.campaign?.onlyMainPage),
                    );

                    return form;
                }}
            </UniversalForm>
            {!loading && <CreatedPartnerLink currentLink={currentLink} />}
            <LoaderSpinner loaded={loadingApk}>
                {__("Идет загрузка APK...")}
            </LoaderSpinner>
        </>
    );
};
