import {
    IBaseFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";
import {
    AdvertisementStatus,
    GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions,
    Rights,
} from "gql/types/operation-result-types";
import { StatusField } from "../../components/status-field";

export const StatusFilterField = (props: IBaseFieldProps) => {
    function getSpecilaOrderOptions(
        permissions: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions,
    ) {
        const allStatuses = [
            AdvertisementStatus.NEW,
            AdvertisementStatus.CREATED,
            AdvertisementStatus.APPROVED,
            AdvertisementStatus.SENT,
            AdvertisementStatus.NEEDS_IMPROVEMENT,
            AdvertisementStatus.SUCCESS,
            AdvertisementStatus.DECLINED,
            AdvertisementStatus.PARTIAL_PAYOUT,
            AdvertisementStatus.CANCELED,
            AdvertisementStatus.ARCHIVE,
        ];

        if (permissions.rights.includes(Rights.CREATE_ADVERSITING_PAYMENT)) {
            return allStatuses;
        } else {
            // permissions.rights.includes(Rights.EDIT_ADVERSITING_PAYMENT)
            return allStatuses.filter(
                status => status !== AdvertisementStatus.NEW,
            );
        }
    }

    return (
        <StatusField
            {...selectFieldsProps(props)}
            getSpecilaOrderOptions={getSpecilaOrderOptions}
        />
    );
};
