import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, useFormikContext } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { CurrencyField } from "components/filters/fields";
import { FormGroup, FormGroupButton } from "styled/layouts";
import { FormWrap } from "views/manager/main/partners-groups/common";
import { FormValues } from "./models";

export const AddPartnerGroupForm: FC = ({ children }) => {
    const [__] = useTranslation();
    const { values, setFieldValue } = useFormikContext<FormValues>();

    // очищаем список участников при изменении валюты
    // сабмитить группу с одной валютой и партнерами, отфильтрованными по другой все равно нельзя
    useEffect(() => {
        if (values.currency) {
            setFieldValue("partners", []);
        }
    }, [setFieldValue, values.currency]);

    return (
        <Form>
            <FormWrap>
                <FormGroup>
                    <Field name="name" type="text" label={__("Имя группы")} />
                </FormGroup>
                <FormGroup>
                    <CurrencyField
                        name="currency"
                        label={__("Валюта")}
                        placeholder={__("Все")}
                        isClearable
                    />
                </FormGroup>
            </FormWrap>
            {children}
            <FormGroupButton>
                <SubmitButton>{__("Создать группу")}</SubmitButton>
            </FormGroupButton>
        </Form>
    );
};
