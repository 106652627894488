import { ShortTimePeriods } from "gql/types/operation-result-types";
import { createAction } from "redux-actions";
import { IMainInfoAndGraph } from "./reducer";

const prefix = "STATES";
export const LAST_NOT_FOUND_PATHNAME_SET = `${prefix}.NOT_FOUND_PATHNAME_SET`;
export const MOBILE_MENU_SHOW_SET = `${prefix}.MOBILE_MENU_SHOW_SET`;
export const PROFILE_MENU_CLICKED_SET = `${prefix}.PROFILE_MENU_CLICKED_SET`;
export const MAIN_MENU_CLICKED_SET = `${prefix}.MAIN_MENU_CLICKED_SET`;
export const STATISTIC_LAST_PARAMS_MAININFO_AND_GRAPH = `${prefix}.STATISTIC_LAST_PARAMS_MAININFO_AND_GRAPH`;
export const STATISTIC_LAST_PARAMS_QUICK = `${prefix}.STATISTIC_LAST_PARAMS_QUICK`;

export const StatesActionCreators = {
    setLastNotFoundPathname: createAction(
        LAST_NOT_FOUND_PATHNAME_SET,
        (pathname: string) => pathname,
    ),
    setIsMenuBurgerClickedOnLanding: createAction(
        MOBILE_MENU_SHOW_SET,
        (visibility: boolean) => visibility,
    ),
    setIsProfileMenuClicked: createAction(
        PROFILE_MENU_CLICKED_SET,
        (visibility: boolean) => visibility,
    ),
    setIsMainMenuClicked: createAction(
        MAIN_MENU_CLICKED_SET,
        (visibility: boolean) => visibility,
    ),
    setStatisticLastParamsMainInfoAndGraph: createAction(
        STATISTIC_LAST_PARAMS_MAININFO_AND_GRAPH,
        (statisticLastParams: IMainInfoAndGraph) => statisticLastParams,
    ),
    setStatisticLastParamsQuick: createAction(
        STATISTIC_LAST_PARAMS_QUICK,
        (statisticLastParams: ShortTimePeriods) => statisticLastParams,
    ),
};
