/* eslint-disable no-console */
import { UniversalForm } from "components/filters";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { AddSite, AddSiteVariables } from "gql/types/operation-result-types";
import { useTranslationList } from "hooks/use-translations-list";
import _ from "lodash";
import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from "react";
import { MutationFunction } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { successHandler, SuccessTypes } from "services/success-handler";
import { AdminWrapper } from "styled/layouts";
import { useAddSite } from "./hooks/use-add-site";
import { createWebsiteRule } from "services/validators";
import { ErrorType } from "../../../../../server/errors/error-type";
import { TFunction } from "i18next";
import { SitesTab } from "./tabs";
import { Tabs } from "components/tabs";
import { Tab } from "components/tabs/components/tab";
import {
    _1XSLOT_PARTNERS,
    PARTNERS_1XSLOT,
} from "../../../../../server/partner-programs";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { FilterKeys } from "components/filters/types";

interface ISiteIndexFilters {
    websiteName: string;
    siteCategory: string;
    language: {
        id: number;
    };
}

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const AddSiteIndex = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT } = useTranslationList();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const [newId, setNewId] = useState(null);
    const { addSite } = useAddSite();
    useEffect(() => {
        if (newId) {
            setColumnData({ name: "id", id: newId, tableName: "web-site" });
        }
    }, [newId, setColumnData]);
    const { partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    const {
        fields,
        createFilters,
    }: {
        fields: FilterKeys[];
        createFilters: (filters: ISiteIndexFilters) => ISiteIndexFilters;
    } =
        partnersProgramId === _1XSLOT_PARTNERS ||
        partnersProgramId === PARTNERS_1XSLOT
            ? {
                  fields: ["websiteName", "language"],
                  createFilters: filters => {
                      return {
                          ...filters,
                          siteCategory: "21",
                      };
                  },
              }
            : {
                  fields: ["websiteName", "siteCategory", "language"],
                  createFilters: filters => {
                      return filters;
                  },
              };

    return (
        <UniversalForm
            fields={fields}
            uniqueValidation={{
                websiteName: createWebsiteRule(INVALID_FORMAT).required(
                    FIELD_SHOULD_BE_FILLED,
                ),
            }}
            submitButtonName={__("Добавить сайт")}
            onSubmit={async submittedValues =>
                onSubmitFunc(
                    addSite,
                    createFilters(
                        submittedValues as unknown as ISiteIndexFilters,
                    ),
                    setNewId,
                    __,
                )
            }
        >
            {({ form }) => (
                <>
                    {form}
                    <AdminWrapper>
                        <Tabs name="sites" default={0}>
                            <Tab title={__("Добавленные сайты")} key={1}>
                                <SitesTab hidden={false} />
                            </Tab>
                            <Tab title={__("Скрытые сайты")} key={2}>
                                <SitesTab hidden={true} />
                            </Tab>
                        </Tabs>
                    </AdminWrapper>
                </>
            )}
        </UniversalForm>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

const onSubmitFunc = async (
    mutate: MutationFunction<AddSite, AddSiteVariables>,
    filters: ISiteIndexFilters,
    setNewId: Dispatch<SetStateAction<any>>,
    __: TFunction,
) => {
    const { websiteName, siteCategory, language } = filters;
    try {
        const { data } = await mutate({
            variables: {
                site: {
                    name: websiteName,
                    siteCategoryId: _.parseInt(siteCategory),
                    languageId: language && language.id,
                },
            },
        });

        setNewId(g(data, "authorized", "partner", "addPartnerSite", "id"));
        successHandler(SuccessTypes.CREATE_SITE, websiteName);
    } catch (e: any) {
        void errorHandler(e, error => {
            switch (error.data.code) {
                case ErrorType.SITE_ALREADY_EXISTS:
                    return __("Сайт уже существует");
            }
        });

        throw e;
    }
};
