import { Field } from "components/formik";
import { PostbackStatus } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";

export const StatusField = () => {
    const [__] = useTranslation();

    const options = [
        { value: PostbackStatus.CREATED, label: __("Создан") },
        { value: PostbackStatus.REJECTED, label: __("Отклонен") },
        { value: PostbackStatus.ACTIVE, label: __("Активный") },
        {
            value: PostbackStatus.REPEATED_MODERATION,
            label: __("Повторная модерация"),
        },
        { value: PostbackStatus.DELETED, label: __("Удален") },
    ];

    return (
        <Field
            name="status"
            key="status"
            type="react-select"
            options={options}
            isLoading={false}
            selectFirst={false}
            label={__("Статус")}
            minWidth={200}
        />
    );
};
