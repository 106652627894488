import { HeaderItemSC } from "components/header/styled";
import styled, { css } from "styled-components";

interface IIsAbleToHide {
    isAbleToHide?: boolean;
}

export const HeaderItem = styled.div`
    ${HeaderItemSC}
`;

export const AppLink = styled.a<IIsAbleToHide>`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & svg {
        width: 50px;
    }

    ${props =>
        props.isAbleToHide &&
        css`
            @media (max-width: 1200px) {
                flex-direction: column;
            }
        `}
`;

export const AppTitle = styled.p<IIsAbleToHide>`
    font-size: 13px;
    margin-bottom: 0;
    color: #fff;
    margin-left: 0px;
    transition: all 0.3s;
    &:hover {
        color: #78c257;
    }

    ${props =>
        props.isAbleToHide &&
        css`
            @media (max-width: 1200px) {
                font-size: 8px;
            }
        `}
`;
