import moment from "moment";
import { Periods } from "../fields/period";

export const getDatesByPeriod = (period: Periods) => {
    let start: moment.Moment | null = moment();
    let end: moment.Moment | null = moment();

    switch (period) {
        case Periods.TODAY:
            start = moment().startOf("day");
            end = moment().endOf("day");
            break;
        case Periods.YESTERDAY:
            start = moment().subtract(1, "day").startOf("day");
            end = moment().subtract(1, "day").endOf("day");
            break;
        case Periods.CURRENT_MONTH:
            start = moment().startOf("month");
            end = moment().endOf("month");
            break;
        case Periods.PREVIOUS_MONTH:
            start = moment().subtract(1, "month").startOf("month");
            end = moment().subtract(1, "month").endOf("month");
            break;
        case Periods.CURRENT_YEAR:
            start = moment().startOf("year");
            end = moment().endOf("year");
            break;
        case Periods.PREVIOUS_YEAR:
            start = moment().subtract(1, "year").startOf("year");
            end = moment().subtract(1, "year").endOf("year");
            break;
        case Periods.CUSTOM:
        default:
            start = null;
            end = null;
            break;
    }

    return { start, end };
};
