import { ReactTable } from "components/react-table";
import { IReactTableColumn } from "components/react-table/column/";
import { useTranslation } from "react-i18next";
import { GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_logs } from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";

export const LogPaymentTable = ({
    logs,
}: {
    logs: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment_logs[];
}) => {
    const [__] = useTranslation();

    const columns: IReactTableColumn[] = [
        {
            Header: __("ID транзакции"),
            accessor: "transactionId",
            minWidth: 80,
        },
        {
            Header: __("ID транзакции в ПС"),
            accessor: "transactionPaySysId",
            minWidth: 80,
        },
        {
            Header: __("Статус"),
            accessor: "statusName",
            minWidth: 80,
        },
        {
            Header: __("Обновление"), // Имя пользователя кто обновил
            accessor: "updatedBy",
            minWidth: 80,
        },
        {
            Header: __("Комментарий"),
            accessor: "comments",
            minWidth: 80,
        },
        {
            Header: __("Дата"),
            accessor: "dateOfCreation",
            minWidth: 80,
            dataType: "date",
        },
    ];

    return (
        <AdminWrapper>
            <ReactTable columns={columns} data={logs} showPagination={false} />
        </AdminWrapper>
    );
};
