import styled from "styled-components";

export const APIDescriptionSC = styled.div`
    strong {
        font-weight: 700;
    }
    li {
        &:not(:last-of-type) {
            margin-bottom: 5px;
        }
    }
    ul {
        list-style-type: none;
    }
    p {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 25px;
        font-size: 0.9em;
    }
`;

export const PreTag = styled.pre`
    overflow: auto;
    word-wrap: normal;
    margin: 0;
    display: block;
    padding: 0.5em;
    background: rgb(247, 247, 247);
    color: black;
    font-size: 85%;
    line-height: 1.6;
    border-radius: 3px;
`;

export const CodeTag = styled.pre`
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    border: 0;
    .code-attr {
        color: #795da3;
    }

    .code-string {
        color: #df5000;
    }

    .open-tag {
        padding-left: 10px;
    }

    .open-tag:first-child {
        padding-left: 0;
    }
`;
