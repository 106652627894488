/* eslint-disable no-console */
import { useUser } from "hooks/query/use-user";
import { useForbiddenCountries } from "hooks/use-forbidden-countries";
import { RouteComponentProps } from "react-router";
import { WrapperContainer } from "styled/layouts";
import { EditAccount } from "./components/edit-account";
import { PaymentsData, PaymentDatasPages } from "./components/paymants-data";
import { ChangePassword } from "./components/change-password/change-password-form";
import { Subscriptions } from "./components/subscribtions";
import { TwoFactorAuth } from "./components/two-factor-auth";
import { ContactManagerInfo } from "./components/contact-manager-info";
import { UserName } from "./components/user-name";
import { ConfirmEmail } from "./components/confirm-email";
import PARTNER_ACCOUNT from "./partner-account.gql";
import { useQuery } from "@apollo/client";
import { PartnerAccount } from "gql/types/operation-result-types";
import {
    _1XBET_PARTNERS,
    BIZBET_PARTNERS,
    VIVATBET_PARTNERS,
    PRO1BET_AFFILIATES,
    MELBET_AFFILIATES,
    MELBET_PARTNERS,
} from "server/partner-programs";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import {
    CountryId,
    LATAM_COUNTIRES_MELBET,
    LATAM_COUNTIRES_1XBET,
    getCountryCodesById,
} from "libs/country-id";
import { useCountries } from "hooks/query/use-countries";

interface IInjectedProps extends RouteComponentProps {}

interface IProps extends IInjectedProps {}

export const Edit = React.memo((props: IProps) => {
    const { user } = useUser();
    const { data, loading } = useQuery<PartnerAccount>(PARTNER_ACCOUNT, {
        ssr: true,
    });

    const { partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    const partnerAccount = data?.authorized.partnerAccount.mainAccountData;
    const { countryCodes, loading: countriesLoading } = useCountries();

    const showFor1XBet =
        (useForbiddenCountries(
            getCountryCodesById(countryCodes, [
                CountryId.Uzbekistan,
                CountryId.India,
                CountryId.Bangladesh,
                CountryId.Turkey,
                ...LATAM_COUNTIRES_1XBET,
            ]),
        ) ||
            (partnerAccount?.countryId &&
                [
                    CountryId.Uzbekistan,
                    CountryId.India,
                    CountryId.Bangladesh,
                    CountryId.Turkey,
                ].includes(partnerAccount.countryId)) ||
            LATAM_COUNTIRES_1XBET.some(
                countryId => countryId === partnerAccount?.countryId,
            )) &&
        partnersProgramId === _1XBET_PARTNERS;
    const showForMelbet =
        (useForbiddenCountries(
            getCountryCodesById(countryCodes, [
                CountryId.Bangladesh,
                CountryId.Turkey,
                CountryId.Brazil,
                ...LATAM_COUNTIRES_MELBET,
            ]),
        ) ||
            (partnerAccount?.countryId &&
                [CountryId.Bangladesh, CountryId.Turkey].includes(
                    partnerAccount.countryId,
                )) ||
            LATAM_COUNTIRES_MELBET.some(
                countryId => countryId === partnerAccount?.countryId,
            )) &&
        partnersProgramId === MELBET_AFFILIATES;
    const showForOtherPrograms = [
        BIZBET_PARTNERS,
        VIVATBET_PARTNERS,
        PRO1BET_AFFILIATES,
        MELBET_PARTNERS,
    ].includes(partnersProgramId);
    const isConfirmEmailShown =
        showFor1XBet || showForMelbet || showForOtherPrograms;

    return (
        <>
            <UserName user={user} />
            <WrapperContainer>
                <EditAccount
                    data={data}
                    loading={loading || countriesLoading}
                />
                <PaymentsData
                    paymentSystem={partnerAccount?.paymentSystem}
                    loading={loading || countriesLoading}
                    page={PaymentDatasPages.PartnerAccount}
                />
                <ChangePassword />
            </WrapperContainer>
            <WrapperContainer>
                <Subscriptions />
                <TwoFactorAuth basePath={props.match.url} />
                <ContactManagerInfo />
                {isConfirmEmailShown && (
                    <ConfirmEmail
                        email={partnerAccount?.email}
                        isEmailConfirmed={!!partnerAccount?.emailConfirmed}
                        isLoading={loading || countriesLoading}
                    />
                )}
            </WrapperContainer>
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
