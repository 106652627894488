import _ from "lodash";
import { InputHTMLAttributes, ReactChild, ReactNode, useState } from "react";
import { Select } from "./fields/select";
import { StyledError, StyledUniversalField } from "./styled";
import loadable from "@loadable/component";
import { Field as FormikField, FieldProps, getIn } from "formik";
import { Checkbox } from "./fields/checkbox";
import { DatePicker } from "./fields/single-date-picker";
import { MultiSelect } from "./fields/multi-select";

interface IInjectedProps {}

interface IDefaultType {
    id?: string;
    label?: string;
    children?: ReactNode;
    prefixComponent?: ReactChild;
    isValid?: boolean;
    validate?: (value: any) => undefined | string | Promise<any>;
}

interface ITextProps
    extends IDefaultType,
        InputHTMLAttributes<HTMLInputElement> {
    type: "text" | "email" | "checkbox" | "radio" | "textarea";
    autoComplete?: "on" | "off";
    autoCapitalize?: "on" | "off";
    autoCorrect?: "on" | "off";
    children?: ReactNode;
    rows?: number;
}

interface ICheckboxProps
    extends IDefaultType,
        InputHTMLAttributes<HTMLInputElement> {
    type: "checkbox" | "radio";
    children?: ReactNode;
}

export interface IPasswordProps
    extends IDefaultType,
        InputHTMLAttributes<HTMLInputElement> {
    type: "password";
    showPasswordStrength?: boolean;
    children?: ReactNode;
}

type SelectProps = import("react-select/lib/Select").Props;

interface IReactSelectProps extends IDefaultType, SelectProps {
    type: "react-select";
    // TODO исправить типизация для options
    options?: any[];
    selectFirst?: boolean;
}

interface IReactMultiSelectProps extends IDefaultType, SelectProps {
    type: "react-multi-select";
    // TODO исправить типизация для options
    options?: any[];
    selectFirst?: boolean;
}

type IDateRangeProps = import("./fields/date-range-picker").IDateRangeProps;
type IDateRangePickerProps = IDefaultType &
    IDateRangeProps & {
        type: "date-range-picker";
    };

interface IDatePicker extends IDefaultType {
    type: "date-single-picker";
}

export type IMegredUniversalFieldProps =
    | ITextProps
    | IPasswordProps
    | IReactSelectProps
    | IReactMultiSelectProps
    | ICheckboxProps
    | IDateRangePickerProps
    | IDatePicker;

export type LabelComponent = React.FC<React.LabelHTMLAttributes<HTMLElement>>;

interface IProps extends IInjectedProps {
    isClearable?: boolean;
    blink?: number;
    fakeSwapLabel?: JSX.Element;
    labelComponent?: LabelComponent;
    className?: string;
    showErrorOnChange?: boolean;
}

const DateRangePicker = loadable(
    async () => await import("./fields/date-range-picker"),
    {
        resolveComponent: components => components.DateRangePicker,
    },
);
// const DateRangePicker = ReactLoadable({
//     loader: async () =>
//         (await import("./fields/date-range-picker")).DateRangePicker,
//     loading: () => null,
//     modules: ["./fields/date-range-picker"],
//     webpack: () => [(require as any).resolveWeak("./fields/date-range-picker")],
// });

export const UniversalField = createUniversalField();

export type IPropsField = IProps &
    IMegredUniversalFieldProps & {
        name: string;
        onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    };

export const Field = (props: IPropsField) => (
    <FormikField
        autoComplete="off"
        autoCapitalize="off"
        autoCorrect="off"
        component={UniversalField}
        {...props}
    />
);

export function createUniversalField(
    UniversalFieldSC: typeof StyledUniversalField = StyledUniversalField,
) {
    const _UniversalField = React.memo(
        (props: IProps & IMegredUniversalFieldProps & FieldProps) => {
            const {
                className,
                field,
                form,
                type,
                children,
                prefixComponent,
                isValid,
                isClearable,
                fakeSwapLabel,
                labelComponent: LabelComponent,
                showErrorOnChange,
                ...rest
            } = props;

            const touchedStructure = getIn(form.touched, field.name);
            const errorStructure = getIn(form.errors, field.name);
            // мы учитываем только ошибки, когда надо показывать ошибку на изменение или
            // когда форма хотя бы была показанна. Чтобы не показывать пользователю ошибку когда он еще ничего не делал.
            const errorStructureToHadle =
                (showErrorOnChange || form.submitCount > 0) && errorStructure;
            let showError = true;

            let component: string | JSX.Element = "input";
            let label = props.label;

            const [id] = useState(
                () =>
                    rest.id ||
                    `${field.name}${_.keys(form.initialValues).length}`,
            );
            let checked;
            switch (type) {
                case "react-select":
                    component = (
                        <Select
                            {...field}
                            {...(rest as any)}
                            form={form}
                            isClearable={isClearable}
                            inputId={id}
                        />
                    );
                    break;
                case "react-multi-select":
                    component = (
                        <MultiSelect
                            {...field}
                            {...(rest as any)}
                            form={form}
                            isClearable={isClearable}
                            inputId={id}
                        />
                    );
                    break;
                case "date-range-picker":
                    component = (
                        <DateRangePicker
                            {...field}
                            {...(rest as any)}
                            id={id}
                        />
                    );
                    break;
                case "date-single-picker":
                    component = (
                        <DatePicker {...field} {...(rest as any)} id={id} />
                    );
                    break;
                case "checkbox":
                case "radio":
                    component = (
                        <Checkbox
                            id={id}
                            type={type}
                            label={label}
                            checked={form.values[field.name]}
                            {...field}
                            {...(rest as any)}
                        />
                    );
                    showError = false;
                    label = undefined;
                    break;
                case "textarea":
                    component = "textarea";
                    break;
            }
            const elem = _.isString(component)
                ? React.createElement(
                      component,
                      {
                          checked,
                          ...field,
                          type,

                          ...rest,
                          id,
                          value:
                              component === "input" && field.value === undefined
                                  ? ""
                                  : field.value,
                          size: 1,
                          // не удалось выделить в отдельный компонент, т.к. проблемы с типизацией
                          className: "defaultWidthField",
                      },
                      component !== "input" ? children : undefined,
                  )
                : component;

            const labelComponent =
                label &&
                (LabelComponent ? (
                    <LabelComponent htmlFor={id}>{label}</LabelComponent>
                ) : (
                    <label htmlFor={id}>{label}</label>
                ));
            return (
                <UniversalFieldSC
                    blink={rest.blink}
                    hasError={!!errorStructureToHadle}
                    isValid={isValid}
                    className={
                        type === "date-range-picker" ? "range" : className
                    }
                    data-error={
                        !errorStructure ? "" : errorStructure.toString()
                    }
                    data-touched={!!touchedStructure}
                    data-hide-error={!!errorStructureToHadle}
                >
                    {labelComponent || fakeSwapLabel}
                    {prefixComponent}
                    {elem}
                    {component === "input" && children}
                    {props.type === "password" &&
                        props.showPasswordStrength &&
                        "Сила пароля"}
                    {errorStructureToHadle && showError && (
                        <StyledError>
                            {extractError(errorStructureToHadle)}
                        </StyledError>
                    )}
                </UniversalFieldSC>
            );
        },
    ) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

    function extractError(errorStructure: any) {
        if (typeof errorStructure === "string") {
            return errorStructure;
        } else if (Array.isArray(errorStructure)) {
            return errorStructure
                .filter(el => typeof el === "string")
                .join("\n");
        }
    }

    return _UniversalField;
}
