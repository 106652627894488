import { css } from "styled-components";

const fontsPath = "../assets/fonts/ubuntu/";

export const UbuntuFonts = css`
    @font-face {
        font-family: "Ubuntu";
        font-display: swap;
        src: url("${fontsPath}Ubuntu-Regular.eot");
        src: local("Ubuntu Regular"), local("Ubuntu-Regular"),
            url("${fontsPath}Ubuntu-Regular.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Ubuntu-Regular.woff") format("woff"),
            url("${fontsPath}Ubuntu-Regular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Ubuntu";
        font-display: swap;
        src: url("${fontsPath}Ubuntu-Italic.eot");
        src: local("Ubuntu Italic"), local("Ubuntu-Italic"),
            url("${fontsPath}Ubuntu-Italic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Ubuntu-Italic.woff") format("woff"),
            url("${fontsPath}Ubuntu-Italic.ttf") format("truetype");
        font-weight: normal;
        font-style: italic;
    }
    @font-face {
        font-family: "Ubuntu";
        font-display: swap;
        src: url("${fontsPath}Ubuntu-Bold.eot");
        src: local("Ubuntu Bold"), local("Ubuntu-Bold"),
            url("${fontsPath}Ubuntu-Bold.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Ubuntu-Bold.woff") format("woff"),
            url("${fontsPath}Ubuntu-Bold.ttf") format("truetype");
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "Ubuntu";
        font-display: swap;
        src: url("${fontsPath}Ubuntu-BoldItalic.eot");
        src: local("Ubuntu Bold Italic"), local("Ubuntu-BoldItalic"),
            url("${fontsPath}Ubuntu-BoldItalic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Ubuntu-BoldItalic.woff") format("woff"),
            url("${fontsPath}Ubuntu-BoldItalic.ttf") format("truetype");
        font-weight: bold;
        font-style: italic;
    }
    @font-face {
        font-family: "Ubuntu";
        font-display: swap;
        src: url("${fontsPath}Ubuntu-Light.eot");
        src: local("Ubuntu Light"), local("Ubuntu-Light"),
            url("${fontsPath}Ubuntu-Light.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Ubuntu-Light.woff") format("woff"),
            url("${fontsPath}Ubuntu-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Ubuntu";
        font-display: swap;
        src: url("${fontsPath}Ubuntu-LightItalic.eot");
        src: local("Ubuntu Light Italic"), local("Ubuntu-LightItalic"),
            url("${fontsPath}Ubuntu-LightItalic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Ubuntu-LightItalic.woff") format("woff"),
            url("${fontsPath}Ubuntu-LightItalic.ttf") format("truetype");
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: "Ubuntu";
        font-display: swap;
        src: url("${fontsPath}Ubuntu-Medium.eot");
        src: local("Ubuntu Medium"), local("Ubuntu-Medium"),
            url("${fontsPath}Ubuntu-Medium.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Ubuntu-Medium.woff") format("woff"),
            url("${fontsPath}Ubuntu-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Ubuntu";
        font-display: swap;
        src: url("${fontsPath}Ubuntu-MediumItalic.eot");
        src: local("Ubuntu Medium Italic"), local("Ubuntu-MediumItalic"),
            url("${fontsPath}Ubuntu-MediumItalic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}Ubuntu-MediumItalic.woff") format("woff"),
            url("${fontsPath}Ubuntu-MediumItalic.ttf") format("truetype");
        font-weight: 500;
        font-style: italic;
    }
`;
