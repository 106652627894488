import { LoadingText } from "components/react-table/loading-text";
import {
    GetMarketingToolsReport,
    GetMarketingToolsReportVariables,
} from "gql/types/operation-result-types";
import { ReportReactTable } from "components/react-table/report-react-table";
import { Export } from "./export";
import { IReactTableColumn } from "components/react-table/column/";
import { useTranslation } from "react-i18next";
import { SubmitValues } from "../general-query-params";
import { QueryResult } from "@apollo/client";
import { generalQueryParams } from "../general-query-params";
import { ITablePagination } from "hooks/use-pagination";

interface IProps {
    actualValues?: SubmitValues;
    queryResult?: QueryResult<
        GetMarketingToolsReport,
        GetMarketingToolsReportVariables
    >;
    externalPaginationAndSorting: ITablePagination;
}

export const MarketingToolsReportEmptyTable = ({
    actualValues,
    queryResult,
    externalPaginationAndSorting,
}: IProps) => {
    const [__] = useTranslation();
    const currency = actualValues?.currency?.name || "";

    /*
        ВНИМАНИЕ!
        Конфигурация minWidth была подобрана с учётом комфорта юзера по запросу менеджера Евгения Веремьева.
        Пожалуйста, не меняйте minWidth по собственному усмотрению. Сначала согласуйте с Евгением.
    */
    const columns: IReactTableColumn[] = [
        {
            Header: __("ID инструмента"),
            accessor: "marketingToolId",
            disabled: true,
            minWidth: 80,
        },
        {
            Header: __("Имя инструмента"),
            accessor: "marketingToolName",
            minWidth: 90,
        },
        {
            Header: __("SUBID"),
            accessor: "subId",
            minWidth: 80,
        },
        {
            Header: __("Тип инструмента"),
            accessor: "marketingToolTypeName",
            minWidth: 80,
        },
        {
            Header: __("ID сайта"),
            accessor: "siteId",
            minWidth: 80,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            minWidth: 120,
        },
        {
            Header: __("Просмотры"),
            accessor: "countOfViews",
            minWidth: 80,
        },
        {
            Header: __("Клики"),
            accessor: "countOfClicks",
            minWidth: 80,
        },
        {
            Header: __("Прямые ссылки"),
            accessor: "countOfDirectLinks",
            minWidth: 80,
        },
        {
            Header: "CTR",
            accessor: "clickThroughRate",
            dataType: "percent",
            minWidth: 80,
        },
        {
            Header: __("Регистрации"),
            accessor: "countOfRegistrations",
            minWidth: 80,
        },
        {
            Header: __("Соотношение регистрации/клики"),
            accessor: "registrationsClicksRatio",
            dataType: "percent",
            minWidth: 100,
        },
        {
            Header: __("Аккаунты с депозитами"),
            accessor: "countOfAccountsWithDeposits",
            minWidth: 80,
        },
        {
            Header: __("Регистрации с депозитами"),
            accessor: "countOfRegistrationsWithDeposits",
            minWidth: 80,
        },
        {
            Header: __("Новые аккаунты с депозитами"),
            accessor: "newDepositors",
            minWidth: 80,
        },
        {
            Header: __("Соотношение регистрации с депозитом/регистрации"),
            accessor: "registrationsWithDepositsRegistrationsRatio",
            dataType: "percent",
            minWidth: 100,
        },
        {
            Header: __("Сумма депозитов"),
            accessor: "depositAmount",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("Сумма бонусов"),
            accessor: "bonusAmount",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("Доход компании (общий)"),
            accessor: "companyProfit",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("RS"),
            accessor: "comissionRS",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("CPA"),
            accessor: "CPA",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("Сумма комиссий"),
            accessor: "comissionAmount",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        /*
            Тут должен быть еще "Коэффициент отношения количества регистраций к установкам",
            который присутствует в exel отчете.
        */
        {
            Header: __("Уникальные установки"),
            accessor: "uniqueInstallations",
            minWidth: 80,
        },
    ];

    return (
        <ReportReactTable
            exportComponent={
                actualValues && (
                    <Export filters={generalQueryParams(actualValues)} />
                )
            }
            reportName="Promo"
            columns={columns}
            shownColumnsByDefault={[
                "marketingToolId",
                "subId",
                "countOfRegistrations",
                "countOfAccountsWithDeposits",
                "depositAmount",
                "companyProfit",
                "comissionAmount",
            ]}
            queryResult={queryResult}
            externalPaginationAndSorting={externalPaginationAndSorting}
            data={
                queryResult?.data?.authorized.partner.reports
                    .marketingToolsReport.rows || []
            }
            total={
                queryResult?.data?.authorized.partner.reports
                    .marketingToolsReport.total
            }
            loadingText={<LoadingText fastLoading={false} />}
            reportStatus={
                queryResult?.data?.authorized.partner.reports
                    .marketingToolsReport.status
            }
            dataLength={
                queryResult?.data?.authorized.partner.reports
                    .marketingToolsReport.countonpage
            }
        />
    );
};
