import { SignOutAlt } from "@styled-icons/fa-solid/SignOutAlt/SignOutAlt";
import { Utils } from "@webx/utils";
import { anyRole, adAgent, manager } from "components/check-roles/rules";
import { usePermissions } from "components/check-roles/use-permissions";
import { SignOut } from "gql/types/operation-result-types";
import { MutationFunction, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { addressOfPartnersv1 } from "../profile-helpers";
import SIGN_OUT from "./mutations/sign-out.gql";

interface IProps {
    className?: string;
}

export const SignOutComponent = (props: IProps) => {
    const [__] = useTranslation();
    const [signOut] = useMutation<SignOut>(SIGN_OUT);
    const { checkPermissions } = usePermissions();
    return (
        <span
            className={props.className}
            onClick={onSignOutClick(
                signOut,
                checkPermissions([anyRole([adAgent, manager])]),
            )}
        >
            <SignOutAlt size={16} /> {__("Выход")}
        </span>
    );
};

const onSignOutClick =
    (mutate: MutationFunction<SignOut>, isItManager: boolean) => async () => {
        try {
            await mutate();
            if (Utils.browser.hasWindow()) {
                // if менеджер, то редикректить на v1
                if (isItManager) {
                    window.location.assign(`${addressOfPartnersv1()}/adagent`);
                } else {
                    window.location.assign("/");
                }
            }
        } catch (e: any) {
            void errorHandler(e);
        }
    };
