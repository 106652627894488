import { AllUniversalOutputValues } from "components/filters/types";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { usePartnerListColumns } from "views/partner-manager/partner-list-table";
import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetLanguages_languages,
    PartnerList,
    PartnerListType,
    PartnerListVariables,
    PartnerStatus,
    SortedPartnerListType,
} from "gql/types/operation-result-types";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { FormGroupButton } from "styled/layouts";
import { useQuery } from "@apollo/client";
import GET_PARTNER_LIST from "../gql/partner-list.gql";
import { ReactTable } from "components/react-table";
import { Search } from "components/search";
import { useRef } from "react";
import { FakeLabel } from "components/filters/form";
import { useTranslation } from "react-i18next";
import { Export } from "components/react-table/table-select-variables/export";
import { ExportButton } from "./export";

type SubmitValues = Pick<
    AllUniversalOutputValues,
    | "partnersGroup"
    | "commisionGroup"
    | "websiteId"
    | "promoCode"
    | "partnerProgramRegistrationSource"
> & {
    language?: GetLanguages_languages;
    currency?: GetCurrencies_authorized_partnerAndManager_data_currencies;
    partnerStatus: PartnerStatus | null;
};

interface PartnerListTableProps {
    values: SubmitValues;
    partnerListType: PartnerListType;
    choosePartner?: (partnerId: number) => void;
}

export const PartnerListTable = ({
    values,
    partnerListType,
    choosePartner,
}: PartnerListTableProps) => {
    const [__] = useTranslation();
    const externalPaginationAndSorting = usePaginationAndSorting();
    const columns = usePartnerListColumns({
        partnerListType,
        choosePartner,
    });
    const searchValueRef = useRef("");

    const variables = {
        filter: {
            currencyId: values.currency && parseInt(values.currency.id),
            partnersGroupId: values.partnersGroup?.id,
            commisionGroupId: values.commisionGroup?.id,
            languageId: values.language && values.language.id,
            siteId: values.websiteId,
            promoCode: values.promoCode,
            registrationSourceId: values.partnerProgramRegistrationSource?.id,
            statusId: values.partnerStatus,
            ...externalPaginationAndSorting.state,
            pageNumber: externalPaginationAndSorting.state.pageNumber || 1,
            sorted: externalPaginationAndSorting.state
                .sorted as SortedPartnerListType,
            search: searchValueRef.current,
        },
        type: partnerListType,
    };

    const queryResult = useQuery<PartnerList, PartnerListVariables>(
        GET_PARTNER_LIST,
        {
            ssr: true,
            variables,
            // иначе в таблице не будет отображаться загрузка при рефетче
            notifyOnNetworkStatusChange: true,
        },
    );

    const partnersList = queryResult.data?.authorized.manager.main.partnerList;

    const exportComponent = (
        <Export
            ExportButton={ExportButton}
            filters={{
                currency: values.currency,
                partnersGroup: values.partnersGroup,
                commisionGroup: values.commisionGroup,
                language: values.language,
                siteId: values.websiteId,
                promoCode: values.promoCode,
                registrationSource: values.partnerProgramRegistrationSource,
                statusId: values.partnerStatus,
            }}
        />
    );
    const addPartnerButton = (
        <FormGroupButton key={1}>
            <FakeLabel />
            <LocalizedNavLinkWithOptionalBackground
                showBackground
                to="/agent/main/partner-list/add"
                exact
            >
                {__("Добавить партнера")}
            </LocalizedNavLinkWithOptionalBackground>
        </FormGroupButton>
    );

    const linkButtons =
        partnerListType === PartnerListType.PartnerList
            ? [addPartnerButton]
            : [];

    return (
        <ReactTable
            columns={columns}
            shownColumnsByDefault={[
                "partnerId",
                "partnerName",
                "email",
                "country",
                "siteName",
                "registrationDate",
                "status",
            ]}
            externalPaginationAndSorting={externalPaginationAndSorting}
            data={partnersList?.partners}
            dataLength={partnersList?.rowsCount}
            loading={queryResult.loading}
            exportComponent={!choosePartner ? exportComponent : undefined}
            linkButtons={linkButtons}
            searchComponent={
                <Search
                    onTextChanges={text => {
                        searchValueRef.current = text;
                    }}
                    onApply={async () => {
                        externalPaginationAndSorting.actions.setPagenum(1);
                    }}
                />
            }
        />
    );
};
