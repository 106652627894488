import React from "react";
import { Dropdown } from "./components/dropdown";
import { exportButtonsByReportFormatDesc } from "./services";
import { ReportFormat } from "gql/types/enumReportFormat";

interface IExportButton {
    format: ReportFormat;
    caption: string;
    filters: unknown;
}

interface IProps {
    filters: unknown;
    ExportButton: React.FC<IExportButton>;
}

export const Export = React.memo((props: IProps) => {
    const { filters, ExportButton } = props;
    return (
        <Dropdown>
            {exportButtonsByReportFormatDesc.map((button, index) => (
                <ExportButton
                    key={`${button.format}_${index}`}
                    {...button}
                    filters={filters}
                />
            ))}
        </Dropdown>
    );
});
