import { useTranslation } from "react-i18next";
import { ReportReactTable } from "components/react-table/report-react-table";
import { ShortTimePeriods } from "gql/types/operation-result-types";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import MANAGER_TOP_PARTNERS from "../../gql/manager-top-partners.gql";
import {
    ManagerTopPartners,
    ManagerTopPartnersVariables,
} from "gql/types/operation-result-types";

interface IInjectedProps {
    currency: GetCurrencies_authorized_partnerAndManager_data_currencies | null;
    shortPeriod: ShortTimePeriods;
}

export const TopPartnersStatisticTable = ({
    currency,
    shortPeriod,
}: IInjectedProps) => {
    const [__] = useTranslation();
    const queryResult = useQuery<
        ManagerTopPartners,
        ManagerTopPartnersVariables
    >(MANAGER_TOP_PARTNERS, {
        variables: {
            currencyId: parseInt(currency?.id || "0"),
            period: shortPeriod,
        },
        ssr: true,
        skip: !currency,
    });
    const currencyName = currency?.name || "";
    return (
        <ReportReactTable
            showPagination={false}
            reportName="TopPartners"
            columns={[
                {
                    Header: __("ID партнера"),
                    minWidth: 80,
                    accessor: "partnerId",
                    disabled: true,
                },
                {
                    Header: __("Показы"),
                    minWidth: 90,
                    accessor: "countOfViews",
                    disabled: true,
                },
                {
                    Header: __("Клики"),
                    minWidth: 90,
                    accessor: "countOfClicks",
                },
                {
                    Header: __("Прямые ссылки"),
                    accessor: "countOfDirectLinks",
                    minWidth: 90,
                },
                {
                    Header: __("Регистрации"),
                    accessor: "countOfRegistrations",
                    minWidth: 90,
                },
                {
                    Header: __("Новые аккаунты с депозитами"),
                    accessor: "countOfNewDepositors",
                    minWidth: 90,
                },
                {
                    Header: __("Сумма депозитов"),
                    accessor: "depositAmount",
                    dataType: "currency",
                    currency: currencyName,
                    minWidth: 100,
                },
                {
                    Header: __("Доход компании (общий)"),
                    accessor: "profit",
                    minWidth: 90,
                    dataType: "currency",
                    currency: currencyName,
                },
                {
                    Header: __("Сумма комиссий"),
                    accessor: "comissionAmount",
                    minWidth: 90,
                    dataType: "currency",
                    currency: currencyName,
                },
            ]}
            queryResult={queryResult}
            data={
                queryResult.data?.authorized.manager.main.mainStatistic
                    .topPartners.rows
            }
            reportStatus={
                queryResult.data?.authorized.manager.main.mainStatistic
                    .topPartners.status
            }
            loadingText={__(
                "Отчёт формируется... Это может занять 15 минут и более",
            )}
        />
    );
};
