import { HelmetWrapper } from "components/helmet/helmet-wrapper";
import { LocalizedRedirect } from "components/language-provider";
import { useDefinedContext } from "hooks/use-context-exist";
import { useLanguage } from "components/language-provider";
import { Route, RouteComponentProps } from "react-router-dom";
import { PartnersProgramGeneralInformationContext } from "views";
import { useUser } from "hooks/query/use-user";

interface IProps {
    component:
        | React.ComponentType<RouteComponentProps>
        | React.ComponentType<any>;
    redirect?: string;
    pageTitle?: string;
    additionalPageTitle?: string;
    exact?: boolean;
    path: string;
    name?: string;
}

export const PageRoute = ({
    redirect = "/",
    exact = true,
    component: Component,
    pageTitle,
    additionalPageTitle,
    path,
}: IProps) => {
    // TODO: Если убрать пользователя и не проверять потом loading то
    // кидает много ошибок типа 20 нарушения в аполло.
    // Причина до конца не понятно, но видимо ожидание loading ведет к
    // неявной загрузке чего-то еще что необходимо. Разобраться позже.
    const { loading } = useUser();
    const {
        language: { urlPrefix },
    } = useLanguage();

    const pageName = React.useMemo(() => {
        // чтобы на следующем шаге не получить ""
        // в случае, например /partner/marketing-tools/postbacks/
        const trimedPath = trimEnd(path, "/");
        const str = trimedPath.split("/").pop();

        return !str || str === urlPrefix ? "landingMainPage" : str;
    }, [path, urlPrefix]);

    const {
        visibility: { hiddenPages },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    if (loading) {
        return null;
    }
    if (exact && hiddenPages.includes(pageName)) {
        return <LocalizedRedirect to={redirect} />;
    }

    return (
        <Route
            path={path}
            exact={exact}
            render={(props: RouteComponentProps) => (
                <HelmetWrapper
                    pageTitle={pageTitle}
                    additionalPageTitle={additionalPageTitle}
                >
                    <Component {...props} />
                </HelmetWrapper>
            )}
        />
    );
};

PageRoute.defaultProps = {
    exact: true,
};

const trimEnd = (str: string, symbol: string) => {
    const endSymbol = str.charAt(str.length - 1);
    if (endSymbol === symbol) {
        return str.substring(0, str.length - 1);
    }
    return str;
};
