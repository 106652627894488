import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import moment from "moment";

export const useValidationSchema = () => {
    const { FIELD_SHOULD_BE_FILLED, DATEEND_LESS_DATESTART } =
        useTranslationList();
    const { momentSchema } = useObjectValidations();

    return yup
        .object({
            dateStart: momentSchema.required(),
            dateEnd: momentSchema
                .when("endless", {
                    is: false,
                    then: (schema: typeof momentSchema) =>
                        schema.required(FIELD_SHOULD_BE_FILLED),
                })
                .when(
                    "dateStart",
                    (dateStart: moment.Moment, schema: typeof momentSchema) => {
                        return schema.test({
                            test: (dateEnd: moment.Moment) =>
                                moment.isMoment(dateEnd)
                                    ? dateEnd.isAfter(dateStart)
                                    : true,
                            message: DATEEND_LESS_DATESTART,
                        });
                    },
                ),
            endless: yup.boolean().required(),
        })
        .required();
};

export type TFormValues = yup.InferType<ReturnType<typeof useValidationSchema>>;
