import { useFormikContext } from "components/formik";
import { SetStateAction, useEffect, useState } from "react";
import { checkRequired } from "../services/check-required";
import { ObjectSchema } from "yup";
import { Dispatch } from "react";

export type ObjectSchemaOfFields = ObjectSchema<
    | {
          [x: string]: unknown;
      }
    | undefined
>;
export interface ILoadingContext {
    loadingFields: Record<string, boolean>;
    setLoadingFields: Dispatch<SetStateAction<Record<string, boolean>>>;
    validationSchema: ObjectSchemaOfFields;
}

export function useIsSelectOptionsLoading(
    name: string,
    loading: boolean,
    loadedOptions: unknown[],
    loadingContext?: ILoadingContext,
) {
    const { values } = useFormikContext<Record<string, unknown>>();

    useEffect(() => {
        if (loadingContext) {
            const isLoading = isFieldLoading(
                name,
                loading,
                loadedOptions,
                values,
                loadingContext.validationSchema,
            );
            if (loadingContext.loadingFields[name] !== isLoading) {
                loadingContext.setLoadingFields(loadingFields => ({
                    ...loadingFields,
                    [name]: isLoading,
                }));
            }
        }
    }, [loading, name, loadedOptions, values, loadingContext]);
}

export function useLoadingFields(
    fields: string[],
    validationSchema: ObjectSchemaOfFields,
) {
    const [actualValues, setActualValues] = useState<Record<string, unknown>>();
    const [loadingFields, setLoadingFields] = useState(() => {
        return fields.reduce((acc, curr) => {
            acc[curr] = checkRequired(validationSchema, curr) ? true : false;
            return acc;
        }, {} as Record<string, boolean>);
    });

    if (__ENVIRONMENT__.development) {
        // Очень легко забыть пробросить setLoadingFields новому полю.
        // В случае чего-нибудь в таком роде мы быстро отследим ошибку в деве.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
            const t = setTimeout(() => {
                if (!allFieldsLoaded(loadingFields)) {
                    log.warn(
                        "Error! 15s has passed, but not all fields yet has loaded!",
                        loadingFields,
                    );
                }
            }, 15000);

            return () => {
                clearTimeout(t);
            };
        }, [loadingFields]);
    }

    // проставить actualValues только тогда, когда все поля загрузились,
    // если они еще не были проставлены,
    // в противном случае просто проставить их
    // TODO: возможно проще просто проверить Object.values(loadingFields).every(loading => !loading)
    function checkLoadingAndSetActualValues(
        formikValues: Record<string, unknown>,
    ) {
        if ((!actualValues && allFieldsLoaded(loadingFields)) || actualValues) {
            setActualValues(formikValues);
        }
    }

    return {
        actualValues,
        loadingFields,
        setLoadingFields,
        checkLoadingAndSetActualValues,
    };
}

function allFieldsLoaded(loadingFields: Record<string, boolean>) {
    return Object.values(loadingFields).every(loading => !loading);
}

function isFieldLoading(
    name: string,
    loading: boolean,
    loadedOptions: unknown[],
    formikValues: Record<string, unknown>,
    validationSchema: ObjectSchemaOfFields,
) {
    if (!loading && loadedOptions.length && validationSchema) {
        if (checkRequired(validationSchema, name)) {
            return !formikValues[name];
        } else {
            return false;
        }
    }

    return loading;
}
