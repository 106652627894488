import styled from "styled-components";

export const TooltipArow = styled.div`
    opacity: 0;
    pointer-events: none;
    border: 6px solid transparent;
    border-bottom-color: #efefef;

    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -5px);
    z-index: 2;

    transition: opacity 0.1s ease-in;
`;

export const TooltipContent = styled.div`
    opacity: 0;
    pointer-events: none;
    background-color: #efefef;
    border-color: 1px solid #fefefe;
    padding: 4px;

    position: absolute;
    top: 100%;
    right: 0%;
    transform: translate(20px, 5px);
    z-index: 2;

    white-space: nowrap;
    font-size: 10px;
    transition: opacity 0.1s ease-in;
`;

export const TooltipSC = styled.div`
    position: relative;
    display: inline-flex;

    &:hover,
    &:active {
        ${TooltipArow},
        ${TooltipContent} {
            opacity: 1;
            pointer-events: initial;
        }
    }
`;
