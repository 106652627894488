import { UpdateStatistic } from "components/header/components/update-statistic";
import { adminBorderColor } from "services/styled-components/themes";
import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { ButtonColored } from "styled/button";

export const MainInfoSC = styled.div`
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: ${adminBorderColor};
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    ${media.xs`
        flex-direction: row;
    `}
`;
export const UpdateStatistics = styled(UpdateStatistic)`
    display: none;
    ${media.smMax`
        padding:0;
        margin: 10px 0;
        display: block;
        text-align: center;
        width: 100%;
        ${ButtonColored} {
            padding: 1em 2em;
        }
    `}
`;
