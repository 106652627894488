import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions } from "gql/types/operation-result-types";
import { AdvertisementStatus, Rights } from "gql/types/operation-result-types";
import { StatusField } from "../../components/status-field";

export const ChangeStatusField = () => {
    function getSpecilaOrderOptions(
        permissions: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions,
    ) {
        if (
            permissions.rights.includes(Rights.CREATE_ADVERSITING_PAYMENT) &&
            permissions.rights.includes(Rights.EDIT_ADVERSITING_PAYMENT)
        ) {
            return [
                AdvertisementStatus.CREATED,
                AdvertisementStatus.APPROVED,
                AdvertisementStatus.NEEDS_IMPROVEMENT,
                AdvertisementStatus.ARCHIVE,
            ];
        } else if (
            permissions.rights.includes(Rights.EDIT_ADVERSITING_PAYMENT)
        ) {
            return [
                AdvertisementStatus.APPROVED,
                AdvertisementStatus.NEEDS_IMPROVEMENT,
                AdvertisementStatus.ARCHIVE,
            ];
        } else {
            // permissions.rights.includes(Rights.CREATE_ADVERSITING_PAYMENT)
            return [AdvertisementStatus.CREATED, AdvertisementStatus.ARCHIVE];
        }
    }

    return (
        <StatusField
            getSpecilaOrderOptions={getSpecilaOrderOptions}
            forChangingStatus
            name="status"
        />
    );
};
