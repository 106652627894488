const postfix = "850f55bf";

class VariablesClass {
    public registration = {
        password: {
            minLength: 6,
        },
    };

    public cookies = {
        language: `lang_${postfix}`,
        themeMode: `theme.mode_${postfix}`,
        agreedToUseCookies: `agreedToUseCookies_${postfix}`,
        tag: "tag",
    };

    public reCaptcha = {
        siteKey: "6LcNnaofAAAAALDywUT7yCjLs1Rrfw80lKTX2RdH",
    };

    public cookiesEndlessOptions = {
        expires: (() => {
            const tenYears = new Date();
            tenYears.setFullYear(tenYears.getFullYear() + 10);
            return tenYears;
        })(),
        path: "/",
    };

    public dateFormat = {
        dateOnly: "YYYY-MM-DD",
        minutes: "YYYY-MM-DD HH:mm",
        seconds: "YYYY-MM-DD HH:mm:ss",
    };

    public pollInterval = {
        short: 1000,
        medium: 1000 * 10,
        long: 1000 * 30,
    };

    public fileStoragePath = __ENVIRONMENT__.development
        ? "https://test.affilateapiv2.service.ref-test.consul:11995"
        : "/file-storage";

    public localAddressOfPartnersv1 = "http://localhost:55849";
}

export const Variables = new VariablesClass();
