import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import { PartnerStatus } from "gql/types/operation-result-types";
import { transFormDate } from "views/partner/main/comission-structure/services/comission-description";
import { CellWithButtons } from "components/table-item";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { SetComissionGroupIdToDeleteProps } from "./index";

interface ColumnsProps {
    idEditingGroup: number | undefined;
    onClickEdit: (id: number) => void;
    comissionGroupDeleteComponentRef: React.MutableRefObject<
        SetComissionGroupIdToDeleteProps | undefined | null
    >;
}

export const useColumns = ({
    idEditingGroup,
    onClickEdit,
    comissionGroupDeleteComponentRef,
}: ColumnsProps) => {
    const [__] = useTranslation();

    const handleDeleteComissionGroup = (comissionGroupId: number) => {
        comissionGroupDeleteComponentRef.current?.setComissionGroupIdToDelete(
            comissionGroupId,
        );
    };

    const columns: IReactTableColumn[] = [
        {
            Header: __("ID группы"),
            accessor: "commissionGroupId",
            minWidth: 80,
            Cell: ({ original, value }) => {
                const button = (
                    <>
                        {original.status === PartnerStatus.ACTIVE && (
                            <ButtonColoredWithIcon
                                btnColor={BtnColor.Faded}
                                title={__("Редактировать")}
                                type="button"
                                onClick={() => onClickEdit(value)}
                                disabled={idEditingGroup ? true : false}
                            >
                                <EditOutline size={15} />
                            </ButtonColoredWithIcon>
                        )}
                        {original.status === PartnerStatus.INACTIVE && (
                            <ButtonColoredWithIcon
                                btnColor={BtnColor.Faded}
                                title={__("Удалить")}
                                type="button"
                                onClick={() =>
                                    handleDeleteComissionGroup(original.id)
                                }
                            >
                                <Delete size={15} />
                            </ButtonColoredWithIcon>
                        )}
                    </>
                );
                return (
                    <CellWithButtons
                        button={button}
                        link={false}
                        text={value}
                    />
                );
            },
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Название"),
            accessor: "commissionGroupName",
            minWidth: 80,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            minWidth: 80,
        },
        {
            Header: __("Начало"),
            accessor: "dateStart",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Окончание"),
            accessor: "dateEnd",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Добавлено/обновлено"),
            accessor: "dateAdd",
            minWidth: 80,
            Cell: ({ original }) => {
                const dateAdd = transFormDate(original.dateAdd);
                const dateUpdate = transFormDate(original.dateUpdate);
                if (dateAdd && dateUpdate) {
                    return `${dateAdd}/${dateUpdate}`;
                }
                return dateAdd || dateUpdate;
            },
        },
        {
            Header: __("Статус"),
            accessor: "status",
            minWidth: 80,
            Cell: ({ original }) => {
                if (original.status === PartnerStatus.ACTIVE) {
                    return __("Активная");
                } else if (original.status === PartnerStatus.INACTIVE) {
                    return __("На активации");
                } else {
                    return __("Закрыта");
                }
            },
        },
    ];

    return columns;
};
