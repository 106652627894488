import { AndroidIcon } from "./android-icon";
import { RouteComponentProps } from "react-router-dom";
import { AppLink, AppTitle } from "./styled";

interface IInjectedProps extends RouteComponentProps {}

interface IProps extends IInjectedProps {
    size: string;
    link: string;
    isAbleToHide?: boolean;
}

export const AndroidLogo = React.memo((props: IProps) => {
    return (
        <AppLink href={props.link} isAbleToHide={props.isAbleToHide}>
            <AndroidIcon
                color="#3ddc84"
                width={props.size}
                height={props.size}
            />
            <AppTitle isAbleToHide={props.isAbleToHide}>
                App for Android™
            </AppTitle>
        </AppLink>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
