import { useEffect } from "react";

export function useOnClickOutside(ref: any, handler: any, isOpen?: boolean) {
    useEffect(() => {
        const listener = (event: any) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        if (isOpen !== undefined) {
            if (isOpen) {
                document.addEventListener("mousedown", listener);
            } else {
                document.removeEventListener("mousedown", listener);
            }
        } else {
            document.addEventListener("mousedown", listener);
        }

        return () => {
            document.removeEventListener("mousedown", listener);
        };
    }, [ref, handler, isOpen]);
}
