import React from "react";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { useState } from "react";
import { useValidationSchema } from "./hooks/use-validation-schema";
import { fieldsList } from "./services/get-filter-fields";

import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { useLoadingFields } from "./hooks/use-is-select-iptions-loading";
import {
    AdditionalFieldsType,
    FilterKeys,
    IUniversalFormProps,
    UniqueValidation,
} from "./types";
import { defaultInitialValues } from "./hooks/use-initial-values";
import { mergeSettings } from "./services/merge-settings";
import { Form } from "./form";
import * as yup from "yup";

export const UniversalForm = <
    T extends FilterKeys,
    UniqueValidationType extends UniqueValidation<T>,
    AddFieldsType extends AdditionalFieldsType,
>(
    props: IUniversalFormProps<T, UniqueValidationType, AddFieldsType>,
) => {
    const {
        fields,
        onSubmit,
        children,
        uniqueValidation,
        additionalFields,
        params = {},
        submitButtonName,
    } = props;

    // инициилизировать начальные значения в которые будут заполняться все настройки формы
    const fieldsValidationSchema = useValidationSchema();

    // смержить все настроки универсальной формы
    const { initialValues, validationSchema, fieldsComponents } = mergeSettings(
        fields,
        defaultInitialValues,
        fieldsValidationSchema,
        uniqueValidation,
        fieldsList,
        additionalFields,
    );

    // завернуть валидацию в одну схему
    const validationSchemaObj = yup.object(validationSchema);

    // актуальные значения формика, но после того, как все необходимые поля загрузились
    const {
        actualValues,
        loadingFields,
        setLoadingFields,
        checkLoadingAndSetActualValues,
    } = useLoadingFields(fields, validationSchemaObj);
    // значения, после сабмита фомика
    const [submittedValues, setSubmittedValues] =
        useState<Record<string, unknown>>();

    return (
        <>
            <FormikConsoleErrors
                enableReinitialize
                initialStatus={{ params }}
                initialValues={initialValues}
                onSubmit={onSubmitWrapper(async (curValues, formikBag) => {
                    setSubmittedValues(curValues);
                    if (onSubmit) {
                        // типы входных параметров не сохраняются в ходе перобразований,
                        // поэтому - явное приведение
                        await onSubmit(curValues as any, formikBag);
                    }
                }, validationSchemaObj)}
                validationSchema={validationSchemaObj}
            >
                {formikProps => {
                    checkLoadingAndSetActualValues(formikProps.values);
                    return children({
                        // типы входных параметров не сохраняются в ходе перобразований,
                        // поэтому - явное приведение
                        submittedValues: submittedValues as any,
                        actualValues: actualValues as any,
                        form: Form({
                            submitButtonName,
                            fields: fieldsComponents,
                            validationSchema: validationSchemaObj,
                            loadingContext: {
                                validationSchema: validationSchemaObj,
                                loadingFields,
                                setLoadingFields,
                            },
                        }),
                    });
                }}
            </FormikConsoleErrors>
        </>
    );
};
