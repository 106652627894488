import React from "react";
import { UniversalForm } from "components/filters";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import * as yup from "yup";
import { MediaReportTable } from "./report-table";
import { useQuery } from "@apollo/client";
import {
    GetAuthState,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetMediaTypes_authorized_partner_data_mediaTypes,
    GetLanguages_languages,
    GetCampaigns_authorized_partnerAndManager_data_campaigns,
} from "gql/types/operation-result-types";
import GET_AUTH_STATE from "views/partner/main/profile/components/two-factor-auth/gql/get-auth-state.gql";
import { UploadMediaFile } from "./upload-media-file";
import { ContainerMedia } from "./styled";
import { useCurrency } from "hooks/query/use-currency";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { useObjectValidations } from "validation/validaion";

export const Media = React.memo(() => {
    const [__] = useTranslation();
    const { data } = useQuery<GetAuthState>(GET_AUTH_STATE);
    const dataCurrency = useCurrency();
    const { objectRule, objectRequiredRule, nullableInterger } =
        useObjectValidations();

    let currencyValidation =
        objectRule<GetCurrencies_authorized_partnerAndManager_data_currencies>();
    if (dataCurrency.data?.length === 1) {
        currencyValidation =
            objectRequiredRule<GetCurrencies_authorized_partnerAndManager_data_currencies>();
    }

    const twoFA = data?.authorized?.partnerAccount?.twoFA?.state?.enabled;

    return (
        <div>
            <AdminWrapper>
                <UniversalForm
                    fields={[
                        "currency",
                        "mediaType",
                        "language",
                        "mediaName",
                        "website",
                        "width",
                        "height",
                        "campaign",
                    ]}
                    submitButtonName={__("Поиск")}
                    uniqueValidation={{
                        mediaName: yup.string(),
                        width: nullableInterger,
                        height: nullableInterger,
                        currency: currencyValidation,
                        mediaType:
                            objectRule<GetMediaTypes_authorized_partner_data_mediaTypes>(),
                        language: objectRule<GetLanguages_languages>(),
                        campaign:
                            objectRule<GetCampaigns_authorized_partnerAndManager_data_campaigns>(),
                    }}
                >
                    {({ submittedValues, actualValues, form }) => {
                        return (
                            <>
                                <ContainerMedia>
                                    {form}
                                    {twoFA ? <UploadMediaFile /> : null}
                                </ContainerMedia>
                                <FirstLoadTable
                                    actualValues={actualValues}
                                    submittedValues={submittedValues}
                                >
                                    {values => (
                                        <MediaReportTable
                                            submittedValues={values}
                                        />
                                    )}
                                </FirstLoadTable>
                            </>
                        );
                    }}
                </UniversalForm>
            </AdminWrapper>
        </div>
    );
});
