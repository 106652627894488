import { DropMenuTitle } from "components/menu/main-menu/styled";
import { useState } from "react";

interface IProps {
    children: React.ReactNode;
}

export const MenuGroupTitleWrapper = (props: IProps) => {
    const [isOpenMenu, setIsOpenMenu] = useState(true);

    return (
        <DropMenuTitle
            onClick={e => {
                setIsOpenMenu(!isOpenMenu);
                e.stopPropagation();
            }}
            isOpenMenu={isOpenMenu}
        >
            <li>
                <a>{props.children}</a>
            </li>
        </DropMenuTitle>
    );
};
