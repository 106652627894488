import { QueryTable, QueryTableProps } from "./query-table";
import { ReactElement } from "react";
import { usePaginationAndSorting } from "hooks/use-pagination";

export const ReportTableWithQuery = <
    FormValuesType,
    QueryResultType,
    QueryFilterVariablesType,
    SortedType,
>(
    props: QueryTableProps<
        FormValuesType,
        QueryResultType,
        QueryFilterVariablesType,
        SortedType
    > & { submittedValues: FormValuesType | null; form: ReactElement },
) => {
    const externalPaginationAndSorting = usePaginationAndSorting();

    return (
        <>
            {props.form}
            {props.submittedValues ? (
                <QueryTable
                    submittedValues={props.submittedValues}
                    actualValues={props.actualValues}
                    emptyTable={props.emptyTable}
                    QUERY={props.QUERY}
                    transfromToQueryParams={props.transfromToQueryParams}
                    externalPaginationAndSorting={externalPaginationAndSorting}
                />
            ) : (
                React.createElement(props.emptyTable, {
                    actualValues: props.actualValues,
                    queryResult: undefined,
                    externalPaginationAndSorting,
                })
            )}
        </>
    );
};
