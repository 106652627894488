import * as _ from "lodash";
import { css } from "styled-components";

export const numberOfSlides = 4;

export const maxWidth = "1170px";

export const sizes = {
    xxs: 360,
    xs: 475, // depricated
    xlt: 480,
    lt: 642, // depricated
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1600, // depricated
    xxl: 1920,
};

export const media = getCustomMedia(sizes);

export function getCustomMedia(customSizes: typeof sizes): {
    xxs: any;
    xs: any;
    xlt: any;
    lt: any;
    sm: any;
    md: any;
    lg: any;
    xl: any;
    xxl: any;

    xxsMax: any;
    xsMax: any;
    xltMax: any;
    ltMax: any;
    smMax: any;
    mdMax: any;
    lgMax: any;
    xlMax: any;
    xxlMax: any;
    smMinmdMax: any;
    xsMinsmMax: any;
} {
    return Object.keys(customSizes).reduce((acc: any, label) => {
        acc[label] = (...args: any[]) => css`
            @media (min-width: ${(customSizes as any)[label] / 16}em) {
                ${(css as any)(...args)}
            }
        `;
        acc[`${label}Max`] = (...args: any[]) => css`
            @media (max-width: ${((customSizes as any)[label] - 1) / 16}em) {
                ${(css as any)(...args)}
            }
        `;
        acc[`${label}hMax`] = (...args: any[]) => css`
            @media (min-height: ${(customSizes as any)[
                    label
                ]}px) and (min-width: ${customSizes.md}px) {
                ${(css as any)(...args)}
            }
        `;
        Object.keys(customSizes).map(labelMax => {
            acc[`${label}Min${labelMax}Max`] = (...args: any[]) => css`
                @media (min-width: ${(customSizes as any)[label] /
                    16}em) and (max-width: ${((customSizes as any)[labelMax] -
                        1) /
                    16}em) {
                    ${(css as any)(...args)}
                }
            `;
        });

        return acc;
    }, {});
}

export const IE = (...args: any[]) => css`
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        ${(css as any)(...args)}
    }
`;

export const align = (columnAlignment: string[], tag: string) => css`
    ${_.map(
        // eslint-disable-next-line @typescript-eslint/unbound-method
        _.filter(columnAlignment, _.negate(_.isEmpty)),
        (textAlign: string, i) => css`
            ${tag}:nth-child(${i + 1}) {
                text-align: ${textAlign};
            }
        `,
    )}
`;

export const columns = (widthOfColumns: number[]) => css`
    ${_.map(
        widthOfColumns,
        (widthOfColumn, i) => css`
            &:nth-child(${i + 1}) {
                width: ${widthOfColumn}%;
            }
        `,
    )}
`;

interface IHidden {
    mediaVisibility?:
        | "xs"
        | "sm"
        | "md"
        | "lg"
        | "xsMax"
        | "smMax"
        | "mdMax"
        | "lgMax";
}

export const hiddenFunc = (props: IHidden) =>
    props.mediaVisibility &&
    css`
        ${media[props.mediaVisibility]`
            display: none !important;
        `}
    `;

export const centerBlock = css`
    margin-left: auto;
    margin-right: auto;
`;

export const pseudoElem = css`
    content: "";
    display: block;
`;

export const createResponsiveScaler = (
    viewWidthMin: number,
    viewWidthMax: number,
) => {
    return function responsiveScaler(sizeMin: number, sizeMax: number) {
        return `calc((100vw - ${viewWidthMin}px) / (${viewWidthMax} - ${viewWidthMin}) * (${sizeMax} - ${sizeMin}) + ${sizeMin}px);`;
    };
};

export const autoFilledContent = css`
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
    }
`;
