import styled from "styled-components";
import { rtl } from "services/styled-components/rtl";

export const UserTitle = styled.div`
    color: #000;
    font-weight: bold;
    margin-bottom: 0.625em;
    display: flex;
    span {
        color: ${props => props.theme.accentColor};
        ${rtl`margin-left: 10px`};
    }
`;
