import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";

export interface IProps {
    isProfileMenuClicked?: boolean;
}

// перед тем как что-то тут менять смотри коменты в MainMenuSC (mobile-main-menu)
export const AsideUserMenu = styled.aside<IProps>`
    position: fixed;
    height: 100%;
    width: 228px;
    transition: width 0.3s ease-in-out;
    font-size: 12px;
    background-color: ${props => props.theme.backBackgroundColor};
    flex: 0 1 auto;
    z-index: 10;

    ${media.smMax`
        ${rtl`
            right: auto;
            transform:translate(-230%, 0px);
        `};

        transition: all 0.3s ease-in-out;
    `}

    ${props =>
        props.isProfileMenuClicked &&
        css`
            ${media.smMax`
            transform:translate(0%, 0px);
            `}
        `}

     ${media.sm`
        display:none;
    `}
`;

export const AsideUserInfo = styled.div`
    padding: 15px;
    color: ${props => props.theme.textColor};
    font-size: 14px;
    box-sizing: border-box;
`;
