import { LogoImg, LogoImgSmall, LogoLink } from "components/logo/styled";
import { media, IE } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";
import { ContentColumn } from "styled/layouts";
import arrowBottomSmall from "assets/images/arrow-bottom-small.svg";
/* stylelint-disable no-descending-specificity */
export const LogoContainer = styled.div`
    background-color: ${props => props.theme.frontBackgroundColor};
    height: 76px;
    display: flex;
    justify-content: center;
    ${media.smMax`
        display: none;
    `}
`;

export const MenuTitle = styled.div`
    display: flex;
    align-items: center;
    min-height: 45px;
    ${IE`
        height: 1px;
    `}
    text-transform: uppercase;
    color: ${props => props.theme.textColor};
    border-top: 1px solid ${props => props.theme.frontBackgroundColor};
    transition: height 0.25s ease-in;
    span {
        display: block;
        ${rtl`padding:5px 15px;`};
        transition: all 0.25s ease-in;
    }
`;

export const MenuGroup = styled.ul`
    li {
        a {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 45px;
            /**Медиа запрос проверяет IE11 это или нет */
            @media screen and (-ms-high-contrast: active),
                (-ms-high-contrast: none) {
                height: 1px;
            }
            background-color: ${props => props.theme.frontBackgroundColor};
            border-top: 1px solid ${props => props.theme.backgroundColor};
            ${rtl`border-left: 2px solid transparent;`};
            color: ${props => props.theme.textColor};
            transition: color 0.25s ease, background-color 0.25s ease,
                border 0.25s ease;
            &:hover,
            &:active,
            &.active {
                background-color: ${props => props.theme.backgroundColor};
                border-top: 1px solid
                    ${props => props.theme.frontBackgroundColor};
            }
            &:active,
            &.active {
                ${props =>
                    rtl`border-left-color: ${props.theme.secondBorderColor};`};
            }
            svg {
                flex: 0 0 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            span {
                display: block;
                opacity: 1;
                transition: opacity 0.25s ease-in-out,
                    transform 0.25s ease-in-out;
                font-size: 15px;
            }
        }
    }
`;
export interface IProps {
    isMainMenuClicked?: boolean;
    menuScrollOffset?: number;
    isOpenMenu?: boolean;
}

export const DropMenuTitle = styled(MenuGroup)<IProps>`
    cursor: pointer;
    position: relative;
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        inset-inline-end: 15px;
        width: 8px;
        height: 5px;
        margin-top: -2px;
        background-image: url(${arrowBottomSmall});
        transition: all 0.3s ease;
        transform: rotate(-90deg);
    }
    & li a:hover {
        background: none;
    }

    ${props =>
        props.isOpenMenu &&
        css`
            background-color: ${props.theme.accentHoverColor};

            &::after {
                transform: rotate(0deg);
            }
        `}

    li a {
        padding-left: 2px;
        background-color: transparent;
        border-top: 1px solid ${props => props.theme.frontBackgroundColor};
        border-left: none;
        span {
            text-transform: uppercase;
            font-size: 1.1em;
        }
    }

    & + ${MenuGroup} {
        display: ${(props: IProps) => {
            return props.isOpenMenu ? "block" : "none";
        }};
    }
`;

export const MainMenuContentSC = (menuScrollOffset = 0) => css`
    width: 70px;
    ${MenuTitle} {
        height: 0;
        span {
            transform: translateX(-250px);
            opacity: 0;
        }
    }

    ${LogoLink} {
        ${LogoImg} {
            display: none;
        }
        ${LogoImgSmall} {
            display: block;
        }
    }
    ${MenuGroup} {
        li {
            a {
                position: relative;
                justify-content: center;
                padding-right: 6px;
                span {
                    display: none;
                    width: 0;
                    transition: all 0.3s;
                    /* transform: translateY(20px); */
                }
            }
            &:hover span {
                position: fixed;
                left: 60px;
                z-index: 20;
                display: block;
                min-width: 200px;
                width: auto;
                min-height: 45px;
                padding: 0 7px 0 17px;
                white-space: nowrap;
                line-height: 45px;
                color: ${props => props.theme.textColor};
                background-color: ${props => props.theme.backgroundColor};
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                transform: translate3d(0, -${menuScrollOffset}px, 0);
                ${menuScrollOffset ? "transition: none" : ""}
            }
        }
    }
`;

/*
    свойство "overflow" трогать нельзя, а иначе сломается вёрстка на первый реднер.
    можно отлючать overflow-x или overflow-y. в данном случае мы отлючаем overflow-x.
*/

export const AsideContentSC = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    .view {
        overflow-x: hidden !important;
        height: 100%;
    }
    .track-vertical {
        width: 4px;
    }
    .thumb-vertical {
        background-color: #aaa;
        opacity: 0.6;
    }
`;

export const MainMenuSC = styled.nav<IProps>`
    position: fixed;
    height: 100vh;
    width: 228px;
    transition: all 0.3s ease-in-out;
    font-size: 12px;
    background-color: ${props => props.theme.backgroundColor};
    flex: 0 1 auto;
    z-index: 11;

    & + ${ContentColumn} {
        flex: 1 1 auto;
    }

    ${props =>
        !props.isMainMenuClicked &&
        css`
            ${media.md`
                /* --- */
            `}
            ${media.smMinmdMax`
                ${MainMenuContentSC(props.menuScrollOffset)}
            `}
            ${media.smMax`
                top: -70px;
                ${rtl`
                    right: 0;
                    left: auto;
                    transform:translate(130%, 70px);
            `};
    `}
        `}

    ${props =>
        props.isMainMenuClicked &&
        css`
            ${media.md`
                ${MainMenuContentSC(props?.menuScrollOffset || 0)}
            `}

            ${media.smMinmdMax`
                /* --- */
            `}

            ${media.smMax`
                top: -70px;
                ${rtl`
                    right: 0;
                    left: auto;
                    transform:translate(130%, 70px);
                    width: 228px;
                    transform:translate(0%, 70px);
                `}
            `}
        `}
`;

export const HiddenMobileLastMenuElement = styled.div`
    height: 125px;
    display: none;

    ${media.smMax`
        display: flex;
    `}
`;
