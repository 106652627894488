import styled from "styled-components";

export const SubRowHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    border: 1px solid #efefef;
    padding: 5px;
`;

export const CellWrapper = styled.p<{ flexStart?: boolean }>`
    ${props => props.flexStart && "justify-content: flex-start;"}
    display: flex;
    min-width: 200px;
`;

export const SubRowCell = styled.div`
    justify-content: center;
    word-wrap: break-word;
    padding-left: 15px;
    padding-right: 15px;
    min-width: 100px;
    &:last-child {
        padding-right: 0;
    }
    & > * {
        display: block;
    }
`;

export const SubRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border: 1px solid #efefef;
    align-items: center;
    padding: 5px;
    & p {
        margin: 0;
    }
    & p:last-child {
        overflow-y: auto;
    }
`;
