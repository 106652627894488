import styled, { css, keyframes } from "styled-components";

interface ITypes {
    item1: string;
    item2: string;
    item3: string;
    item4: string;
}

export const Spiner = ({ color }: { color?: ColorTypes }) => {
    return (
        <SpinerBox color={color}>
            <SpinerItem setting="item1" />
            <SpinerItem setting="item2" />
            <SpinerItem setting="item3" />
        </SpinerBox>
    );
};

const types: ITypes = {
    item1: `
		animation-delay: -0.50s;
	`,
    item2: `
		animation-delay: -0.40s;
	`,
    item3: `
		animation-delay: -0.30s;
	`,
    item4: `
		animation-delay: -0.20s;
	`,
};

export type ColorTypes = "secondary";

const colorTypes = {
    secondary: css`
        background-color: #fff;
    `,
};

const bouncedelay = keyframes`
	0%, 80%, 100% { 
		transform: scale(0);
	} 40% { 
		transform: scale(1.0);
	}
`;

const SpinerBox = styled.div`
    margin: 0 auto;
    text-align: center;
    width: 100%;
    & > div {
        ${({ color }: { color?: ColorTypes }) => color && colorTypes[color]};
    }
`;

export const SpinerItem = styled.div<{ setting: keyof ITypes }>`
    width: 12px;
    height: 12px;
    background-color: ${props => props.theme.activeColor};
    border-radius: 100%;
    display: inline-block;
    margin-right: 10px;
    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
    ${({ setting }) => types[setting]}
`;
