import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { AdvertisingPaymentType } from "gql/types/operation-result-types";
import { useObjectValidations } from "validation/validaion";

export function useValidationSchema() {
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const { pointNumber, momentSchema, periodSchema } = useObjectValidations();

    const schema = yup.object({
        type: yup
            .string<AdvertisingPaymentType>()
            .required(FIELD_SHOULD_BE_FILLED),
        paymentPlanDuration: periodSchema,
        amount: pointNumber(),
        paymentDateTime: momentSchema.required(FIELD_SHOULD_BE_FILLED),
    });
    return schema;
}
