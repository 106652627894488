import * as yup from "yup";

export const URL_REGEXP =
    /^(http(s)?:\/\/)?[\w.-]+(\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/i;

// Вебсайт ни в коем случае нельзя записывать в lowercase.
// "https://www.youtube.com/channel/UCbQnK6bG2y-EBKD8JMrlOUg" !== "https://www.youtube.com/channel/ucbqnk6bg2y-ebkd8jmrloug"
export const createWebsiteRule = (errorMessage: () => string) =>
    yup
        .string()
        .matches(URL_REGEXP, errorMessage)
        .transform(function (value) {
            if (!this.isType(value) || value === undefined) {
                return null;
            }

            if (!/^(https?|ftp):\/\//.test(value)) {
                return `http://${value}`;
            }

            return value;
        });

export const phoneRegex = /^\+\d{5,15}$/;
export const passwordRegex = /^[\w#/&]{8,100}$/;

export const NAME_REGEXP =
    /^(?=.{1,50}(?<=\S)$)([\p{L}\u4E00-\u62FF\u6300-\u77FF\u7800-\u8CFF\u8D00-\u9FFF\u00A0\u3001\u3002\u060C\u06D4\u061B\u061F\u0387\u037E\u00A1\u00BF\u2019\u0027\u00AB\u00BB\u2013\uFF0C\uFF1B\uFF1A\uFF01\uFF0D\uFF3F\uFF03\uFF1A\uFF1F\uFF0B,;:!%\\.\\?-]+\s?)+$/gu;
