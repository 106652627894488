import _ from "lodash";
import { ReactNode, FC } from "react";
import { IContentLoaderProps } from "react-content-loader";
import { useTranslation } from "react-i18next";
import { Wrapper, ContentNoInformation } from "./styled";
import { NoData } from "components/no-data";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    loading: boolean;
    text?: string;
    times?: number;
    contentLoaderProps?: IContentLoaderProps;
    children?: ReactNode | ReactNode[];
    component?: FC;
    render?: (props: IContentLoaderProps) => JSX.Element;
}

// interface IContentLoaderRender extends IProps {
//     render: (props: IContentLoaderProps) => JSX.Element,
// }

// interface IContentLoaderComponent extends IProps {
//     component: FC,
// }

export const ContentLoader = React.memo((props: IProps) => {
    const [, i18n] = useTranslation();

    const { loading, text, times, children, contentLoaderProps } = props;

    if (loading) {
        const propsToInject = {
            rtl: i18n.dir() === "rtl",
        };

        return (
            <Wrapper>
                {_.times(times || 1, i => {
                    const p = Object.assign({}, propsToInject, { key: i });

                    if (props.component) {
                        return React.createElement(props.component, {
                            ...p,
                            ...contentLoaderProps,
                        });
                    } else if (props.render) {
                        return props.render(p);
                    }
                })}
            </Wrapper>
        );
    } else if (typeof text === "string" && !text.length) {
        return (
            <ContentNoInformation>
                <NoData />
            </ContentNoInformation>
        );
    }

    return <>{children}</>;
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
