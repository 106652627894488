import { PopupType } from "../popup-id";

export const usePopupOnceDay = (
    popupId: PopupType,
    showPopupOnceDay?: boolean,
) => {
    if (!showPopupOnceDay) {
        return { showPopupOnceDay: true };
    }

    const popupName = `popup_${popupId}`;
    const timestampFromStorage = localStorage.getItem(popupName);
    const today = new Date();
    const timestampToday = today.getTime();
    const timestampNextDay = today.setDate(today.getDate() + 1);
    if (!timestampFromStorage) {
        localStorage.setItem(popupName, timestampNextDay.toString());
        return { showPopupOnceDay: true };
    } else if (timestampToday > Number(timestampFromStorage)) {
        localStorage.setItem(popupName, timestampNextDay.toString());
        return { showPopupOnceDay: true };
    } else {
        return { showPopupOnceDay: false };
    }
};
