import { Preloader, PreloaderCube, Cube } from "./styled";

interface IProps {
    cubesColor: string;
    bgColor: string;
}
export const Loader = (props: IProps) => {
    const delays = [
        "-1.1s",
        "-1s",
        "-.9s",
        "-1.2s",
        "-1.1s",
        "-1s",
        "0",
        "-1.2s",
        "-1.1s",
    ];
    return (
        <Preloader color={props.bgColor}>
            <PreloaderCube>
                {delays.map((delay, i) => {
                    return (
                        <Cube
                            key={i}
                            color={props.cubesColor}
                            animationDelay={delay}
                        />
                    );
                })}
            </PreloaderCube>
        </Preloader>
    );
};
