import { UniversalForm } from "components/filters";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { useObjectValidations } from "validation/validaion";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";
import { TypeNameIdField } from "../fields/type-name-id-field";
import { StatusField } from "../fields/status-field";
import { PostbacksTable } from "../postbacks-table";

export const PostbacksForm = () => {
    const [__] = useTranslation();
    const { objectRule } = useObjectValidations();

    return (
        <AdminWrapper>
            <UniversalForm
                fields={["currency", "partnersGroup", "websiteId"]}
                uniqueValidation={{
                    currency:
                        objectRule<GetCurrencies_authorized_partnerAndManager_data_currencies>(),
                }}
                submitButtonName={__("Поиск")}
                additionalFields={{
                    typeNameId: {
                        initialValue: null,
                        component: () => <TypeNameIdField />,
                    },
                    status: {
                        initialValue: null,
                        component: () => <StatusField />,
                    },
                }}
            >
                {({ form, actualValues, submittedValues }) => {
                    return (
                        <>
                            {form}
                            <FirstLoadTable
                                actualValues={actualValues}
                                submittedValues={submittedValues}
                            >
                                {values => <PostbacksTable values={values} />}
                            </FirstLoadTable>
                        </>
                    );
                }}
            </UniversalForm>
        </AdminWrapper>
    );
};
