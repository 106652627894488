import { useMutation } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCurrency } from "hooks/query/use-currency";
import { useModalState } from "hooks/use-modal-state";
import { IState as IPaginationAndSortingState } from "hooks/use-pagination";
import { Paragraph } from "services/styled-components/typogoraphy";
import { Modal } from "components/components-common/modal";
import { BtnColor, ButtonColored } from "styled/button";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import {
    DeleteReferralDomain,
    DeleteReferralDomainVariables,
} from "gql/types/operation-result-types";
import DELETE_REFERRAL_DOMAIN from "./delete-referral-domain.gql";
import REFERRAL_DOMAINS from "../referral-domains.gql";

interface IProps {
    paginationAndSortingState: IPaginationAndSortingState;
    currencyNameToDelete: string | null;
    onClose: () => void;
}

export const DeleteDomainModal = ({
    paginationAndSortingState,
    currencyNameToDelete,
    onClose,
}: IProps) => {
    const { params } = useRouteMatch<{ id: string }>();
    const partnerId = Number(params.id);
    const [__] = useTranslation();
    const { data: currencies } = useCurrency();
    const [deleteDomain, { loading }] = useMutation<
        DeleteReferralDomain,
        DeleteReferralDomainVariables
    >(DELETE_REFERRAL_DOMAIN, {
        refetchQueries: [
            {
                query: REFERRAL_DOMAINS,
                variables: {
                    params: {
                        ...paginationAndSortingState,
                        id: partnerId,
                    },
                },
            },
        ],
    });
    const { modalProps } = useModalState();

    const handleAccept = () => {
        const currencyId = currencies?.find(
            currency => currency.name === currencyNameToDelete,
        )?.id;

        if (currencyId) {
            void deleteDomain({
                variables: {
                    data: {
                        partnerId,
                        currencyId: Number(currencyId),
                    },
                },
                onCompleted: onClose,
            });
        }
    };

    return (
        <Modal
            {...modalProps}
            onRequestClose={onClose}
            isOpen={!!currencyNameToDelete}
        >
            <Paragraph>{__("Удалить настройку домена?")}</Paragraph>
            <FormDirectionRow>
                <FormGroupButton>
                    <ButtonColored onClick={handleAccept} disabled={loading}>
                        OK
                    </ButtonColored>
                </FormGroupButton>
                <FormGroupButton>
                    <ButtonColored
                        btnColor={BtnColor.Faded}
                        onClick={onClose}
                        type="button"
                    >
                        {__("Отмена")}
                    </ButtonColored>
                </FormGroupButton>
            </FormDirectionRow>
        </Modal>
    );
};
