import { memo } from "react";
import { useTranslation } from "react-i18next";
import { ReactTable } from "components/react-table";
import { SetFieldValue } from "components/formik";
import { Title } from "services/styled-components/typogoraphy";
import {
    IListItem,
    getColumns,
    useControlCheckboxHandlers,
} from "views/manager/main/partners-groups/common";
import { AvailablePartners_authorized_manager_main_availablePartners_partners } from "gql/types/operation-result-types";

interface IProps {
    tableName: string;
    fieldName: string;
    selectedPartners: IListItem[];
    setFieldValue: SetFieldValue;
}

const ChangesTableComponent = ({
    tableName,
    fieldName,
    selectedPartners,
    setFieldValue,
}: IProps) => {
    const [__] = useTranslation();
    const { onRemove, onRemoveAll } = useControlCheckboxHandlers({
        list: selectedPartners,
        fieldName,
        setFieldValue,
    });
    const columns =
        getColumns<AvailablePartners_authorized_manager_main_availablePartners_partners>(
            {
                __,
                onChange: onRemove,
                onChangeAll: onRemoveAll,
                defaultSelected: true,
                allColumns: false,
            },
        );

    return (
        <div>
            <Title>{tableName}</Title>
            <ReactTable columns={columns} data={selectedPartners} />
        </div>
    );
};

export const ChangesTable = memo(ChangesTableComponent);
