import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { LocalizedLink } from "components/language-provider";
import { useTranslation } from "react-i18next";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { ViewShow } from "@styled-icons/zondicons/ViewShow";
import { Check2Square } from "@styled-icons/bootstrap/Check2Square";
import { DocumentDuplicate } from "@styled-icons/heroicons-outline/DocumentDuplicate";
import { useLazyQuery } from "@apollo/client";
import GET_ADVERTISEMENT_PAYMENT from "../../gql/get-payment.gql";
import {
    GetAdvertisementPayment,
    GetAdvertisementPaymentVariables,
} from "gql/types/operation-result-types";
import { useHistory } from "react-router-dom";

interface ButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
}

interface ButtonWithLinkProps {
    to: string;
}

export const ViewAdvertisingButton = ({ to }: ButtonWithLinkProps) => {
    const [__] = useTranslation();

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Просмотреть")}
            type="button"
        >
            <LocalizedLink className="no-indent" to={to}>
                <ViewShow size={15} />
            </LocalizedLink>
        </ButtonColoredWithIcon>
    );
};

export const EditAdvertisingButton = ({ to }: ButtonWithLinkProps) => {
    const [__] = useTranslation();

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Редактировать")}
            type="button"
        >
            <LocalizedLink className="no-indent" to={to}>
                <EditOutline size={15} />
            </LocalizedLink>
        </ButtonColoredWithIcon>
    );
};

export const OpenModalToConnectPaymentButton = ({ onClick }: ButtonProps) => {
    const [__] = useTranslation();

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Выбрать")}
            type="button"
            onClick={onClick}
        >
            <Check2Square size={15} />
        </ButtonColoredWithIcon>
    );
};

export const DublicateAdvertisingButoon = ({ id }: { id: number }) => {
    const [__] = useTranslation();
    const history = useHistory();

    const [getPayment, { loading }] = useLazyQuery<
        GetAdvertisementPayment,
        GetAdvertisementPaymentVariables
    >(GET_ADVERTISEMENT_PAYMENT);

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Дублировать")}
            type="button"
            onClick={async () => {
                const result = await getPayment({
                    variables: {
                        id,
                    },
                });
                const payment =
                    result.data?.authorized.manager.advertising
                        .advertisingPayments.advertisingPayment;
                history.push("payments/add", payment);
            }}
            disabled={loading}
        >
            <DocumentDuplicate size={15} />
        </ButtonColoredWithIcon>
    );
};
