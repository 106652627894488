import { useTranslation } from "react-i18next";
import { IReactTableColumn } from "components/react-table/column";
import { PartnerGroups_authorized_manager_main_partnerGroups_data } from "gql/types/operation-result-types";
import { CellWithButtons } from "components/table-item";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";

interface IUseColumnsParams {
    disabled: boolean;
    onEdit: (
        data: PartnerGroups_authorized_manager_main_partnerGroups_data,
    ) => void;
    onDelete: (id: number) => void;
}

export const useColumns = ({
    disabled,
    onEdit,
    onDelete,
}: IUseColumnsParams) => {
    const [__] = useTranslation();

    const columns: IReactTableColumn[] = [
        {
            Header: __("ID группы"),
            accessor: "id",
            disabled: true,
            minWidth: 140,
            Cell: ({
                original,
            }: {
                original: PartnerGroups_authorized_manager_main_partnerGroups_data;
            }) => {
                const button = (
                    <>
                        {original.status !== null && (
                            <ButtonColoredWithIcon
                                btnColor={BtnColor.Faded}
                                title={__("Редактировать")}
                                type="button"
                                disabled={disabled}
                                onClick={() => onEdit(original)}
                            >
                                <EditOutline size={15} />
                            </ButtonColoredWithIcon>
                        )}
                        {original.status === false && (
                            <ButtonColoredWithIcon
                                btnColor={BtnColor.Faded}
                                title={__("Удалить")}
                                type="button"
                                disabled={disabled}
                                onClick={() => onDelete(original.id)}
                            >
                                <Delete size={15} />
                            </ButtonColoredWithIcon>
                        )}
                    </>
                );
                return (
                    <CellWithButtons
                        button={button}
                        text={String(original.id)}
                    />
                );
            },
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Название"),
            accessor: "groupName",
            minWidth: 120,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            minWidth: 120,
            Cell: ({ value }) => value || __("Все сайты"),
        },
        {
            Header: __("Начало"),
            accessor: "dateStart",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Окончание"),
            accessor: "dateEnd",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Добавлено/Обновлено"),
            accessor: "whoAdded",
            minWidth: 120,
            Cell: ({
                original,
            }: {
                original: PartnerGroups_authorized_manager_main_partnerGroups_data;
            }) => {
                const { whoAdded, whoUpdated } = original;

                if (whoAdded && whoUpdated) {
                    return `${whoAdded}/${whoUpdated}`;
                }

                return whoAdded || whoUpdated;
            },
        },
        {
            Header: __("Статус"),
            accessor: "status",
            minWidth: 80,
            Cell: ({ value }) => {
                if (value === null) {
                    return __("Закрыта");
                }

                return value ? __("Активная") : __("На активации");
            },
        },
    ];

    return columns;
};
