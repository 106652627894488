import { ReactChild, useEffect } from "react";
import { useCookies } from "react-cookie";
import { toast, ToastOptions } from "react-toastify";
import { Variables } from "services/variables";
import { CookiesAgreementToastContent } from "./toast-content";
import { FlattenSimpleInterpolation } from "styled-components";

interface IProps {
    btnCSS?: FlattenSimpleInterpolation;
    btnColor?: string;
    linkColor?: string;
    bgColor?: string;
    options?: ToastOptions;
    content?: (onConfirm: () => void, onDecline: () => void) => ReactChild;
}

export const CookiesAgreementToast = React.memo((props: IProps) => {
    const { options, content, ...rest } = props;
    const [cookies, setCookie] = useCookies([
        Variables.cookies.agreedToUseCookies,
    ]);
    const agreedToUseCookies = cookies[Variables.cookies.agreedToUseCookies];

    const onSetCookieHandler = (id: number, value = true) => {
        setCookie(
            Variables.cookies.agreedToUseCookies,
            value,
            Variables.cookiesEndlessOptions,
        );
        toast.dismiss(id);
    };

    useEffect(() => {
        if (!agreedToUseCookies) {
            const toastId = toast.info(
                content ? (
                    content(
                        () => onSetCookieHandler(toastId),
                        () => onSetCookieHandler(toastId, false),
                    )
                ) : (
                    <CookiesAgreementToastContent
                        {...rest}
                        onConfrim={() => onSetCookieHandler(toastId)}
                    />
                ),
                {
                    autoClose: false,
                    position: "bottom-left",
                    closeOnClick: false,
                    draggable: false,
                    closeButton: false,
                    ...options,
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreedToUseCookies, setCookie]);

    return null;
});
