import { media } from "services/styled-components/variables";
import styled from "styled-components";

export const SealItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: space-between;
    align-items: center;
    max-width: 340px;
    a:not(:last-child) {
        margin-right: 20px;
    }
    ${media.mdMax`
        margin-bottom: 25px;
    `}
`;

export const SealItemImg = styled.div`
    width: 100%;
    height: 100%;
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    img {
        width: 100%;
        height: auto;
    }
`;
