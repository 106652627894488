import { getHashCode } from "services/project-utils";

export abstract class InvalidCSRFToken {
    // должен соответсвовать такой же на серверной стороне
    static solt = "52d766f6-5521-4d81-a332-bf26d1c6045c";

    static async logCSRFTokenSecurly(
        message: string,
        token: string | undefined,
    ) {
        const prefixMsg = "CSRF TOKEN LOG " + "Client Side: ";
        if (!token) {
            log.debug(prefixMsg + message, {
                CSRFTokenHash: "No token",
            });
        }
        try {
            const hashHex = getHashCode(token + InvalidCSRFToken.solt);
            log.debug(prefixMsg + message, {
                CSRF: hashHex,
            });
        } catch (er) {
            log.error(
                prefixMsg +
                    "Something went wrong at logCSRFTokenSecurly client",
                er,
            );
        }
    }
}
