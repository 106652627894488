import { Access } from "components/check-roles/access";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { AndroidLogo } from "components/android-app/android-logo";
import { _1XBET_PARTNERS } from "../../../../server/partner-programs";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const MenuFooter = React.memo((props: IProps) => {
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [__] = useTranslation();

    return (
        <ul role="navigation">
            <Access name="presentation">
                <li className="linkMobile">
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                        href={"/lapi/promotion/presentation.pdf"}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {__("Презентация")}
                    </a>
                </li>
            </Access>
            <Access name="contacts">
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/contacts"
                    >
                        {__("Контакты")}
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
            </Access>
            <Access name="news">
                <li>
                    {partnersGeneralInformation.partnersProgramId ===
                    _1XBET_PARTNERS ? (
                        <a href="https://1xbetaffiliates.net/blog/">
                            {__("Новости")}
                        </a>
                    ) : (
                        <LocalizedNavLinkWithOptionalBackground
                            exact={true}
                            to="/news"
                        >
                            {__("Новости")}
                        </LocalizedNavLinkWithOptionalBackground>
                    )}
                </li>
            </Access>

            <Access name="privacy">
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/privacy"
                    >
                        {__("Политика конфиденциальности")}
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
            </Access>
            <Access name="cookies">
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/cookies"
                    >
                        {__("Политика Cookie")}
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
            </Access>
            {partnersGeneralInformation.androidAppLinks && (
                <li className="android-item">
                    <AndroidLogo
                        size="30px"
                        link={partnersGeneralInformation.androidAppLinks}
                    />
                </li>
            )}
        </ul>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
