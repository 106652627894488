import { Img, LinkBanner } from "./styled";
import { INotificationContentProps } from "../../index";
import { BannerPopupData } from "../../banner/banner-popup-data";
import { CountryId } from "libs/country-id";
import { GetCountries_countries } from "gql/types/operation-result-types";

export const getTelegramSrcByCountryId = (
    countryCodes: Map<GetCountries_countries["id"], string>,
    currentGeoCountryCode: string | undefined,
) => {
    const [countryId] =
        [...countryCodes].find(([, code]) => code === currentGeoCountryCode) ||
        [];

    if (countryId) {
        const links: Partial<Record<CountryId, string>> = {
            [CountryId.Malaysia]: "https://t.me/janine_aff",
            [CountryId.Thailand]: "https://t.me/nadia_aff",
            [CountryId.Vietnam]: "https://t.me/linda_smm",
        };

        return links[countryId as CountryId] || null;
    }

    return null;
};

interface IProps extends INotificationContentProps {
    data: BannerPopupData;
}

export const TelegramBanner = ({ data }: IProps) => {
    const countryCode = data.data.currentGeoCountryCode || "";

    return (
        <LinkBanner href={data.data.src} target="__blank">
            <Img
                src={`assets/images/banner-modal/1_1/${countryCode}_telegram.png`}
                loading="lazy"
            />
        </LinkBanner>
    );
};
