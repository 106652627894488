import { Field } from "components/formik";
import { GetMediaTypes } from "gql/types/operation-result-types";
import _ from "lodash";
import { useQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

const GET_MEDIA_TYPES = gql`
    query GetMediaTypes {
        authorized {
            partner {
                data {
                    mediaTypes {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const MediaTypeField = React.memo((props: ISelectFild) => {
    const { name, ...rest } = props;
    const [__] = useTranslation();
    const { data, loading } = useQuery<GetMediaTypes>(GET_MEDIA_TYPES, {
        ssr: true,
    });

    const options = _.map(
        g(data, "authorized", "partner", "data", "mediaTypes"),
        mediaType => ({
            value: mediaType as any,
            label: mediaType.name,
        }),
    );

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={props.name}
            label={__("Тип медиа")}
            type="react-select"
            options={options}
            isLoading={loading}
            selectFirst={props.selectFirst}
            minWidth={200}
            {...rest}
        />
    );
}) as React.ComponentType<ISelectFild>;
