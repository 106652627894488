import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { usePartnersPayment } from "../hooks/use-partner-payment";
import { MainInfoItem } from "./main-info-item";
import { MainInfoItemTypeType } from "./main-info-item/styled";
import { MainInfoSC, UpdateStatistics } from "./styled";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    currency:
        | GetCurrencies_authorized_partnerAndManager_data_currencies
        | undefined;
    loadCurrency: boolean;
}

export const MainInfo = React.memo(({ currency, loadCurrency }: IProps) => {
    const [__] = useTranslation();

    const { loading, data, queryResult } = usePartnersPayment(currency);

    if (queryResult.error) {
        return <Title>{__("При получении данных произошла ошибка")}</Title>;
    }

    const currencyName = (currency && currency.name) || "";

    return (
        <MainInfoSC>
            <MainInfoItem
                itemType={MainInfoItemTypeType.IsAvailable}
                amount={data?.withdrawalAmount}
                period={__("доступно к выводу")}
                currency={currencyName}
                loading={loading || loadCurrency}
                hold={data?.commissionSummHold}
            />
            <MainInfoItem
                itemType={MainInfoItemTypeType.Yesterday}
                amount={data?.yesterdayPaymentAmount}
                period={__("вчера")}
                currency={currencyName}
                loading={loading || loadCurrency}
            />
            <MainInfoItem
                itemType={MainInfoItemTypeType.CurrentMonth}
                amount={data?.currentMonthPaymentAmount}
                period={__("текущий месяц")}
                currency={currencyName}
                loading={loading || loadCurrency}
            />
            <MainInfoItem
                itemType={MainInfoItemTypeType.ThirtyDays}
                amount={data?.monthPaymentAmount}
                period={__("30 дней")}
                currency={currencyName}
                loading={loading || loadCurrency}
            />
            <MainInfoItem
                itemType={MainInfoItemTypeType.Total}
                amount={data?.paymentAmount}
                period={__("всего")}
                currency={currencyName}
                loading={loading || loadCurrency}
            />
            <UpdateStatistics />
        </MainInfoSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
