import { Paragraph } from "services/styled-components/typogoraphy";
import { useTranslation } from "react-i18next";
import { Modal } from "components/components-common/modal";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { BtnColor, ButtonColored } from "styled/button";
import { useDelete } from "../../hooks/use-delete";
import { SuccessTypes, successHandler } from "services/success-handler";
import { errorHandler } from "services/error-handler";

interface IProps {
    id: number;
    close: () => void;
    modalProps: {
        isOpen: boolean;
        onRequestClose: () => void;
    };
}

export const DeletePostbackModal = ({ id, close, modalProps }: IProps) => {
    const [__] = useTranslation();

    const { mutation } = useDelete(id);

    const deletePostback = React.useCallback(async () => {
        try {
            await mutation();
            successHandler(SuccessTypes.DELETE_POSTBACK, id);
        } catch (e: any) {
            void errorHandler(e);
        }
    }, [id, mutation]);

    return (
        <Modal {...modalProps}>
            <Paragraph>{__("Удалить Postback?")}</Paragraph>
            <FormDirectionRow>
                <FormGroupButton>
                    <ButtonColored onClick={deletePostback}>OK</ButtonColored>
                </FormGroupButton>
                <FormGroupButton>
                    <ButtonColored
                        btnColor={BtnColor.Faded}
                        onClick={close}
                        type="button"
                    >
                        {__("Отмена")}
                    </ButtonColored>
                </FormGroupButton>
            </FormDirectionRow>
        </Modal>
    );
};
