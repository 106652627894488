import { ContentLoader } from "components/components-common/content-loader";
import { useTranslation } from "react-i18next";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import { AdminWrapperEdit } from "styled/layouts";
import { PaymentFields } from "./payment-fields";
import { List } from "react-content-loader";
import { LabelOneLine } from "../../styled";
import { Form, Field } from "components/formik";
import * as yup from "yup";
import { FormGroup } from "styled/layouts";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { PartnerAccount_authorized_partnerAccount_mainAccountData_paymentSystem } from "gql/types/operation-result-types";

interface IProps {
    paymentSystem:
        | PartnerAccount_authorized_partnerAccount_mainAccountData_paymentSystem
        | null
        | undefined;
    loading: boolean;
    page: PaymentDatasPages;
}

export enum PaymentDatasPages {
    ShowAdvertisingPayment,
    PartnerAccount,
}

export const PaymentsData = (props: IProps) => {
    const [__] = useTranslation();
    const paymentSystem = props.paymentSystem;

    const fieldsInitialValues =
        paymentSystem &&
        (paymentSystem.fields || []).reduce((acc, field) => {
            acc[field.id] = field.value || "";
            return acc;
        }, {} as { [key: string]: string });

    const initialValues = {
        ...fieldsInitialValues,
        paymentSystemName: paymentSystem ? paymentSystem.name : "",
    };

    const fieldsValidationSchema =
        paymentSystem &&
        (paymentSystem.fields || []).reduce((acc, field) => {
            acc[field.id] = yup.string();
            return acc;
        }, {} as { [key: string]: yup.StringSchema });

    const validationSchema = yup.object().shape({
        ...fieldsValidationSchema,
        paymentSystemName: yup.string(),
    });
    return (
        <AdminWrapperEdit>
            <ContentLoader component={List} loading={props.loading}>
                <FormikConsoleErrors
                    enableReinitialize
                    initialValues={initialValues}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onSubmit={() => {}}
                    validationSchema={validationSchema}
                >
                    <Form>
                        {props.page === PaymentDatasPages.PartnerAccount && (
                            <Title>{__("Платежные данные")}</Title>
                        )}

                        {paymentSystem ? (
                            <>
                                <FormGroup>
                                    <Field
                                        name="paymentSystemName"
                                        type="text"
                                        label={__(
                                            "Предпочитаемый способ оплаты:",
                                        )}
                                        labelComponent={LabelOneLine}
                                        disabled
                                    />
                                </FormGroup>

                                <PaymentFields
                                    fields={paymentSystem.fields || []}
                                />
                                {props.page ===
                                    PaymentDatasPages.PartnerAccount && (
                                    <Paragraph>
                                        {__(
                                            "* ­для изменения платежных данных, обратитесь в службу поддержки партнеров.",
                                        )}
                                    </Paragraph>
                                )}
                            </>
                        ) : (
                            <Paragraph>
                                {__(
                                    "Не удалось получить данные о платежной системе",
                                )}
                            </Paragraph>
                        )}
                    </Form>
                </FormikConsoleErrors>
            </ContentLoader>
        </AdminWrapperEdit>
    );
};
