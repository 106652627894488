import { exportButtonsDesc } from "components/react-table/table-select-variables/export/services/buttons";

import React from "react";
import { Dropdown } from "components/react-table/table-select-variables/export/components/dropdown";
import { ExportButton } from "./export-button";

import { IReactTableColumn } from "components/react-table/column/";
import { UserConsumer } from "components/user-consumer";

export interface IExportParams {
    filters?: unknown;
    columns: IReactTableColumn[];
    data: any;
    filename?: string;
}

interface IProps {
    exportParams: IExportParams;
}

export const Export = React.memo((props: IProps) => {
    return (
        <UserConsumer.Exists>
            {user => (
                <Dropdown>
                    {exportButtonsDesc.map(b => (
                        <ExportButton
                            key={b.format}
                            exportParams={props.exportParams}
                            user={user}
                            button={b}
                        />
                    ))}
                </Dropdown>
            )}
        </UserConsumer.Exists>
    );
});
