import { useTranslation } from "react-i18next";
import { ButtonColored } from "./button";
import { Row } from "./layouts";

interface IPropsIcon {
    text: string;
    color: any;
    icon: string;
    action?: any;
    loading?: boolean;
}
export const IconedButton = ({
    text,
    color,
    icon: Icon,
    action,
    loading,
}: IPropsIcon) => {
    const [__] = useTranslation();
    return (
        <ButtonColored
            disabled={loading}
            size="13px"
            btnColor={color}
            onClick={action}
        >
            <Row>
                <Icon />
                {__(text)}
            </Row>
        </ButtonColored>
    );
};
