import { Partner_authorized_manager_main_partner } from "gql/types/operation-result-types";
import { FormGroupCheckbox } from "styled/layouts";
import {
    Checkbox as CheckboxBase,
    CheckboxProps,
} from "components/formik/universal-field/fields/checkbox";

interface IProps extends Omit<CheckboxProps, "type" | "onChange"> {
    id: keyof Partner_authorized_manager_main_partner;
    onChange: (id: keyof Partner_authorized_manager_main_partner) => void;
}

export const Checkbox = (props: IProps) => (
    <FormGroupCheckbox>
        <CheckboxBase
            {...props}
            type="checkbox"
            onChange={() => props.onChange(props.id)}
        />
    </FormGroupCheckbox>
);
