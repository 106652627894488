import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Steps } from "components/steps";
import { BackNextButtons } from "components/steps/buttons";
import { ChooseSite } from "../components/choose-site";
import { Title } from "services/styled-components/typogoraphy";
import { ViewAndEditPayment } from "../components/view-and-edit-payment";
import { useAdvertisementPayment } from "./use-get-advertisement-payment";
import { useLocalizedAbsolutePush } from "hooks/use-localised-absolute-push";

export enum AdversitingAction {
    View = "view",
    Edit = "edit",
}

interface IInjectedProps {
    match: {
        params: {
            id: string | number;
        };
    };
}

interface IProps extends IInjectedProps {
    adversitingAction: AdversitingAction;
}

export const ViewAndEditPaymentSteps = (({
    match,
    adversitingAction,
}: IProps) => {
    const [__] = useTranslation();
    const push = useLocalizedAbsolutePush();
    const [step, setStep] = useState(2);

    const { payment, partnerId, siteId, setSiteId } = useAdvertisementPayment(
        Number(match.params.id),
    );

    if (!payment) {
        return <Title>{__("Загрузка данных")}</Title>;
    }

    const stepData: Array<{
        component: ReactNode;
        stepTitle: string;
    }> = [
        {
            component: (
                <Title>
                    {format(__("Выбранный партнер: {partnerId}"), {
                        partnerId,
                    })}
                </Title>
            ),
            stepTitle: __("Посмотреть партнера"),
        },
        {
            component: (
                <ChooseSite
                    partnerId={partnerId}
                    siteId={siteId}
                    chooseSite={currentSiteId => {
                        setSiteId(currentSiteId);
                        setStep(step + 1);
                    }}
                />
            ),
            stepTitle: __("Выбрать сайт"),
        },
        {
            component: (
                <ViewAndEditPayment
                    adversitingAction={adversitingAction}
                    payment={payment}
                    siteId={siteId}
                    onEdit={editedPayment => {
                        push(
                            `/agent/advertising/payments/view/${editedPayment.id}`,
                        );
                    }}
                />
            ),
            stepTitle: __("Редактировать платежные данные"),
        },
    ];

    return (
        <>
            <Steps
                titleText={__("Рекламная выплата:")}
                stepTitles={stepData.map(item => item.stepTitle)}
                currentStep={step}
            />
            {stepData[step].component}
            <BackNextButtons
                step={step}
                setStep={setStep}
                numberOfSteps={stepData.length}
                canGoNext={step !== 0 || partnerId !== undefined}
                canGoBack={adversitingAction === AdversitingAction.Edit}
            />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
