import styled from "styled-components";
import { ButtonStyled } from "styled/button";

export const FeedbackFormContainer = styled.div`
    max-width: 800px;
    width: 100%;
    & textarea {
        min-height: 200px;
    }
    ${ButtonStyled} {
        margin: 10px 0;
    }
`;

export const SendMessageContainer = styled.div`
    max-width: 800px;
    width: 100%;
    & textarea {
        min-height: 200px;
    }
    ${ButtonStyled} {
        margin: 10px 0;
    }
`;
