import { css } from "styled-components";
const fontsPath = "../assets/fonts/helvetica-neue/";

export const HelveticaNeueFonts = css`
    @font-face {
        font-family: "HelveticaNeue";
        font-display: swap;
        src: url("${fontsPath}HelveticaNeueLTW06-97BlkCnObl.woff")
            format("woff");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "HelveticaNeueLTPro";
        font-display: swap;
        src: url("${fontsPath}HelveticaNeueLTPro.woff") format("woff");
        font-weight: 900;
        font-style: italic;
    }
`;
