import {
    SubpartnerReportFilter,
    User_user,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { getCommonTableTranslator } from "../../services/get-commot-table-translator";
import moment from "moment";

interface ITableData {
    user?: User_user;
    values: SubpartnerReportFilter;
    reportFormat: string;
    culture: string;
}

export const tableData = (
    { user, values, reportFormat, culture }: ITableData,
    __: TFunction,
) => {
    const { currencyId, endPeriod, startPeriod, registrationDate } = values;

    if (user) {
        return {
            reportFormat,
            translator: {
                reportType: __("Отчет по суб-партнерам"),
                ...getCommonTableTranslator(
                    {
                        culture,
                        currencyId,
                        endPeriod,
                        startPeriod,
                        user,
                    },
                    __,
                ),
                dateRegistrationSubPartner: {
                    header: __("Дата регистрации суб-партнера"),
                    value: moment(registrationDate).locale(culture).format("L"),
                },
                columns: {
                    column1: __("ID суб-партнера"),
                    column2: __("ID сайта"),
                    column3: __("Название сайта"),
                    column15: __("Id сайта регистрации"),
                    column4: __("Уровень подчиненного партнера"),
                    column16: __("Дата регистрации суб-партнера"),
                    column5: __("% комиссии от уровня"),
                    column6: __("Показы (просмотры)"),
                    column7: __("Клики"),
                    column8: __("Прямые ссылки"),
                    column9: __("регистрации"),
                    column10: __(
                        "Новые аккаунты с депозитами (количество первых депозитов)",
                    ),
                    column11: __("Сумма внесенныхдепозитов"),
                    column12: __("Прибыль"),
                    column13: __("CPA"),
                    column14: __("Реферальная комиссия"),
                },
            },
        };
    }
};
