/* eslint-disable @typescript-eslint/ban-types */
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { createWebsiteRule } from "services/validators";
import {
    GetCampaigns_authorized_partnerAndManager_data_campaigns,
    GetCommissionGroups_authorized_manager_main_commisionGroups,
    GetCountries_countries,
    GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetLanguages_languages,
    GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes,
    GetMediaTypes_authorized_partner_data_mediaTypes,
    GetPartnersGroups_authorized_manager_main_partnersGroups_partnerGroups,
    GetRegistrationSources_registrationSources,
    PartnerProgramRegistrationSources_authorized_manager_data_partnerProgramRegistrationSource,
    PartnerSites_authorized_partnerAndManager_data_sites,
} from "gql/types/operation-result-types";

export const useValidationSchema = () => {
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED, INVALID_FORMAT } = useTranslationList();
    const { objectRequiredRule, objectRule, nullableInterger } =
        useObjectValidations();

    const stringRequiredRule = yup
        .string()
        .typeError(INVALID_FORMAT)
        .required(FIELD_SHOULD_BE_FILLED);

    const schemaObject = {
        currency:
            objectRequiredRule<GetCurrencies_authorized_partnerAndManager_data_currencies>(),
        campaign:
            objectRequiredRule<GetCampaigns_authorized_partnerAndManager_data_campaigns>(),
        period: objectRequiredRule<{
            start: import("moment").Moment | null;
            end: import("moment").Moment | null;
        }>(),
        website:
            objectRequiredRule<PartnerSites_authorized_partnerAndManager_data_sites>(),
        mediaType:
            objectRequiredRule<GetMediaTypes_authorized_partner_data_mediaTypes>(),
        marketingToolsType:
            objectRequiredRule<GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes>(),
        country: objectRequiredRule<GetCountries_countries>(),
        language: objectRequiredRule<GetLanguages_languages>(),
        commisionGroup:
            objectRule<GetCommissionGroups_authorized_manager_main_commisionGroups>(),
        partnersGroup:
            objectRule<GetPartnersGroups_authorized_manager_main_partnersGroups_partnerGroups>(),
        registrationSource:
            objectRule<GetRegistrationSources_registrationSources>(),
        partnerProgramRegistrationSource:
            objectRule<PartnerProgramRegistrationSources_authorized_manager_data_partnerProgramRegistrationSource>(),
        currencyAdversitingPayments:
            // eslint-disable-next-line max-len
            objectRule<GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments>(),
        marketingToolId: nullableInterger,
        width: nullableInterger,
        height: nullableInterger,
        playerId: nullableInterger,
        websiteId: nullableInterger,
        partnerId: nullableInterger,
        campaignId: nullableInterger,
        mockPeriod: stringRequiredRule,
        siteCategory: stringRequiredRule,
        onlyNewPlayers: yup.boolean().typeError(INVALID_FORMAT),
        withoutDepositsOnly: yup.boolean().typeError(INVALID_FORMAT),
        promoCode: yup
            .string()
            .matches(/^[a-zA-Z0-9]+$/)
            .test({
                message: __("Промокод не должен состоять только из нулей"),
                test(promocode: string) {
                    return !/^0+$/.test(promocode);
                },
            }),
        landing: yup
            .string()
            .matches(
                /^(\/[a-zа-яё]{2})?(\/[a-zа-яё0-9-%#$_]+)+(\?([a-zа-яё0-9-%#$]+=[a-zа-яё0-9-%#$]+&)*[a-zа-яё0-9-%#$]+=[a-zа-яё0-9-%#$]+)?$/i,
                __(
                    "Поле Целевая страница является относительным путем. Должно быть как '/page'",
                ),
            ),
        subId: yup
            .string()
            .matches(
                /^([0-9А-Яа-яa-zA-Z_%{}]*)?$/,
                __(
                    "Поле SubId должно содержать только буквы, цифры и символы ' _%{}'",
                ),
            ),
        websiteName: createWebsiteRule(INVALID_FORMAT),
    };

    return schemaObject;
};
