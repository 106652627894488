interface IInjectedProps {}

export interface ITabProps extends IInjectedProps {
    title?: string;
    counter?: number;
    action?: any;
}

export const Tab = React.memo((props: React.PropsWithChildren<ITabProps>) => {
    return <div />;
}) as React.ComponentType<
    Subtract<React.PropsWithChildren<ITabProps>, IInjectedProps>
>;
