import { Tabs } from "components/tabs";
import { Tab } from "components/tabs/components/tab";
import { useTranslation } from "react-i18next";
import { Profile } from "./profile";
import { Bonuses } from "./bonuses";
import { PartnerGroups } from "./partner-groups";
import { PaymentDetails } from "./payment-details";
import { CommissionGroup } from "./commission-group";
import { PartnerSites } from "./partner-sites";
import { ReferralDomains } from "./referral-domains";
import { Promocodes } from "./promocodes";

interface IProps {
    id: string;
}

export const EditPartnerTabs = ({ id }: IProps) => {
    const [__] = useTranslation();

    return (
        <Tabs name="editPartner" default={0}>
            <Tab title={__("Профиль партнера")} key={0}>
                <Profile />
            </Tab>
            <Tab title={__("Платежные данные")} key={1}>
                <PaymentDetails />
            </Tab>
            <Tab title={__("Бонусы")} key={2}>
                <Bonuses partnerId={Number(id)} />
            </Tab>
            <Tab title={__("Партнерская группа")} key={3}>
                <PartnerGroups />
            </Tab>
            <Tab title={__("Комиссионная группа")} key={4}>
                <CommissionGroup partnerId={Number(id)} />
            </Tab>
            <Tab title={__("Настройки домена")} key={5}>
                <ReferralDomains />
            </Tab>
            <Tab title={__("Партнерские сайты")} key={6}>
                <PartnerSites partnerId={Number(id)} />
            </Tab>
            <Tab title={__("Промокоды")} key={7}>
                <Promocodes partnerId={Number(id)} />
            </Tab>
        </Tabs>
    );
};
