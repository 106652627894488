import styled from "styled-components";
import { media } from "services/styled-components/variables";

export const MobileMenuUserToggleSC = styled.button`
    display: flex;
    color: ${props => props.theme.textColor};
    padding: 0 15px;
    align-items: center;
    ${media.sm`
        display: none;
    `}
`;
