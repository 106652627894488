import { media } from "services/styled-components/variables";
import styled from "styled-components";
import { Main, FormGroupButton } from "styled/layouts";

export const MainAdmin = styled(Main)`
    padding: 10px;
    color: #3a4753;

    /* ${FormGroupButton} {
        button,
        a {
            padding-top: 0.715em;
            padding-bottom: 0.715em;
            font-size: 0.875em;
            line-height: 16.1px;
        }
    } */

    ${media.mdMax`
        margin-top: 0px;
    `}
`;
