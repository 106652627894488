import { useTranslation } from "react-i18next";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";

export const RefForSpecificPage = () => {
    const [__] = useTranslation();
    const {
        agentSiteInformation: { companyName, siteAddress },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);
    return (
        <>
            <h2>
                <strong>
                    {format(
                        __(
                            "Как создать партнерскую ссылку, ведущую на конкретную страницу на сайте {companyName}",
                        ),
                        {
                            companyName,
                        },
                    )}
                </strong>
            </h2>
            <ol>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Выбери нужный сайт в поле \"Сайт\"",
                    )}
                </li>
                <li>
                    {__(
                        "Выбери валюту, в которой будут отображаться данные. Валюта по умолчанию - USD",
                    )}
                </li>
                <li>
                    {format(
                        __(
                            /* prettier-ignore */
                            "Выбери \"Кампанию\" {companyName}",
                        ),
                        {
                            companyName: `${companyName.toLowerCase()}.com`,
                        },
                    )}
                </li>
                <li>
                    <p>
                        {(() => {
                            const [beforeRef, ref, afterRef] = format(
                                __(
                                    /* prettier-ignore */
                                    "Добавь в поле \"Целевая страница\" часть ссылки, которая идет после {ref}{website}{ref}. Название можно посмотреть на сайте.",
                                ),
                                { website: siteAddress, ref: "{ref}" },
                            ).split("{ref}");
                            return (
                                <>
                                    {beforeRef}
                                    <a href={siteAddress}>{ref}</a>
                                    {afterRef}
                                </>
                            );
                        })()}
                    </p>
                    <p>
                        {__("Обязательно добавь слеш в начале и конце")}
                        <br />
                        {__(
                            "Например, для страницы Казино нужно добавить /slots/",
                        )}
                        <br />
                        {__(
                            "Например, для страницы Хоккей (линия) нужно добавить /line/ice-hockey/",
                        )}
                        <br />
                    </p>
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Добавь параметр Sub ID, если это необходимо. В поле \"Sub ID\" можно добавить любой маркер для трекинга (Например, размер баннера, название рекламной кампании, а также любой динамический параметр для отслеживания)",
                    )}
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Нажми на кнопку \"Сгенерировать ссылку\". Ссылка отобразится ниже.",
                    )}
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Скопируй ссылку, нажав на значок \"Копировать\" в поле \"Готовая ссылка\" справа.",
                    )}
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Неправильную ссылку можно скрыть, нажав на кнопку \"Скрыть\" в поле \"Состояние\"",
                    )}
                </li>
            </ol>
        </>
    );
};
