import { useContext } from "react";

// для того чтобы не проверять каждый раз на undefined
// в тех местах когда это точно будет не undefind
export function useDefinedContext<T>(context: React.Context<T | undefined>) {
    const value = useContext(context);
    if (!value) {
        throw new Error("Value must already exist here!");
    }
    return value;
}
