import { useTranslation } from "react-i18next";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { ButtonColored } from "styled/button";
import { SetStateAction } from "react";

interface IProps {
    step: number;
    numberOfSteps: number;
    canGoNext: boolean;
    canGoBack?: boolean;
    setStep: (value: SetStateAction<number>) => void;
}

export const BackNextButtons = ({
    step,
    numberOfSteps,
    canGoNext,
    canGoBack,
    setStep,
}: IProps) => {
    const [__] = useTranslation();

    return (
        <FormDirectionRow>
            {step > 0 && (
                <FormGroupButton>
                    <ButtonColored
                        onClick={() => setStep(step - 1)}
                        disabled={!canGoBack}
                    >
                        {__("Назад")}
                    </ButtonColored>
                </FormGroupButton>
            )}
            {step < numberOfSteps - 1 && (
                <FormGroupButton>
                    <ButtonColored
                        onClick={() => setStep(step + 1)}
                        disabled={!canGoNext}
                    >
                        {__("Далее")}
                    </ButtonColored>
                </FormGroupButton>
            )}
        </FormDirectionRow>
    );
};
