import { AdminWrapperEdit, WrapperContainer } from "styled/layouts";
import { DealType } from "../common-form-advertising/deal-type";
import { Title } from "services/styled-components/typogoraphy";
import { LogPaymentTable } from "./log-payment-table";
import GET_ADVERTISEMENT_PAYMENT from "../../gql/get-payment.gql";
import {
    GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment,
    UpdateAdvertisementPayment,
    UpdateAdvertisementPaymentParams,
    UpdateAdvertisementPaymentVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form } from "components/formik";
import { CommonInfo } from "../common-form-advertising/common-info";
import { PaymentInfo } from "views/manager/advertising/components/payment-info";
import { useValidationSchema } from "../common-form-advertising/validation-scheme";
import UPDATE_ADVERTISEMENT_PAYMENT from "./gql/update-payment.gql";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { AdversitingAction } from "../../view-edit-steps";
import { updatePayment } from "./helpers";
import { useTranslation } from "react-i18next";
import { useGetInitialValuesFromPayment } from "../common-form-advertising/get-initial-values-from-payment";

interface IProps {
    adversitingAction: AdversitingAction;
    payment: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment;
    siteId: number | null;
    onEdit: (payment: UpdateAdvertisementPaymentParams) => void;
}

export const ViewAndEditPayment = ({
    adversitingAction,
    payment,
    siteId,
    onEdit,
}: IProps) => {
    const [__] = useTranslation();
    const id = payment.id;

    const [mutation] = useMutation<
        UpdateAdvertisementPayment,
        UpdateAdvertisementPaymentVariables
    >(UPDATE_ADVERTISEMENT_PAYMENT, {
        refetchQueries: [
            {
                query: GET_ADVERTISEMENT_PAYMENT,
                variables: {
                    id,
                },
            },
        ],
        awaitRefetchQueries: true,
    });

    const validationSchema = useValidationSchema();
    const getInitialValues = useGetInitialValuesFromPayment({
        partnerId: payment.partnerId,
        siteId,
        statusAdvertisingPayments: payment.status,
        adversitingAction,
    });

    return (
        <>
            <Title>id: {id}</Title>
            <LogPaymentTable logs={payment.logs} />
            <FormikConsoleErrors
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={getInitialValues(payment)}
                onSubmit={onSubmitWrapper(async values => {
                    try {
                        const updatedPayment = await updatePayment({
                            mutation,
                            values,
                            id,
                        });
                        onEdit(updatedPayment);
                    } catch (ex: any) {
                        void errorHandler(ex, error => {
                            switch (error.data.code) {
                                case ErrorType.IMPOSIBLE_STATUS_CHANGING:
                                    return __("Невозможно применить статус");
                                default:
                                    return __(
                                        "Ошибка редактирования рекламной выплаты",
                                    );
                            }
                        });
                    }
                }, validationSchema)}
            >
                {({ values, setFieldValue, initialValues }) => {
                    return (
                        <Form>
                            <WrapperContainer>
                                <CommonInfo
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    adversitingAction={adversitingAction}
                                    initialStatus={
                                        initialValues.statusAdvertisingPayments
                                    }
                                />
                                <AdminWrapperEdit>
                                    <PaymentInfo
                                        disabled={
                                            adversitingAction ===
                                            AdversitingAction.View
                                        }
                                        advertisingCompany={
                                            payment.advertisingCompany
                                        }
                                    />
                                </AdminWrapperEdit>
                                <DealType
                                    adversitingAction={adversitingAction}
                                />
                            </WrapperContainer>
                        </Form>
                    );
                }}
            </FormikConsoleErrors>
        </>
    );
};
