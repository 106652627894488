import React, { ReactNode, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "hooks/use-on-click-outside";
import { ExportSC, ExportSelect, ExportVariants } from "../../../styled";

interface IProps {
    children: ReactNode[] | ReactNode;
}

export const Dropdown = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const [listOpened, setListOpened] = useState(false);
    const refExport = useRef(null);
    useOnClickOutside(refExport, () => setListOpened(false));

    return (
        <div>
            <ExportSC ref={refExport} opened={listOpened}>
                <ExportSelect
                    onClick={() => setListOpened(!listOpened)}
                    opened={listOpened}
                >
                    {__("Экспорт")}
                </ExportSelect>
                <ExportVariants>{props.children}</ExportVariants>
            </ExportSC>
        </div>
    );
});
