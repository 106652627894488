import { TFunction } from "i18next";

export const translationsList = (__: TFunction) => {
    // TODO: если не сделать это функуией то будет ошибка,
    // т.κ. __ функуия будет вызываться не из браузера.
    const PASSWORD_SYMBOLS = () =>
        __(
            "Длина 8 - 100. Должен содержать A-Z, a-z, 0-9, и допустимо _ , #, /, &",
        );
    return {
        FIELD_SHOULD_BE_FILLED: () => __("Поле должно быть заполнено"),
        FIELD_SHOULD_BE_ΕΜPΤΥ: () => __("Поле должно быть пустым"),
        // INVALID_PARAM: () => __("Поле не содержит верного параметра http://"),
        CONTAINS_INVALID_CHARACTERS: () => __("Содержит недопустимые символы"),
        INVALID_NUMBER: () =>
            __(
                "Поле должно быть числом и может содержать дробную часть из 2 цифр.",
            ),
        INVALID_FORMAT: () => __("Неверный формат"),
        INVALID_MIN_DEPOSIT_SUM: () =>
            __(
                "Минимальная сумма депозита и Общая сумма ставок не может быть равно нулю одновременно",
            ),
        INVALID_PASSWORD: () => PASSWORD_SYMBOLS(),
        WEAK_PASSWORD: () =>
            format("{week_password}. {password_symbols}", {
                week_password: __("Слабый пароль"),
                password_symbols: PASSWORD_SYMBOLS(),
            }),
        WEAK_PASSWORD_WITHOUT_HINT: () => __("Надежный пароль"),
        DOES_NOT_MATCH_WITH_PASSWORD: () => __("Не совпадает с паролем"),
        INVALID_TEMPORARY_CODE: () => __("Неверный временный код"),
        AT_LEAST_MIN_CHARACTERS: ({ min }: { min: number }) =>
            format(__("Не менее {min} символов"), { min: JSON.stringify(min) }),
        UP_TO_MAX_CHARACTERS: ({ max }: { max: number }) =>
            format(__("Не более {max} символов"), { max }),
        AT_LEAST_MIN: ({ min }: { min: number }) =>
            format(__("Не менее {min}"), { min }),
        UP_TO_MAX: ({ max }: { max: number }) =>
            format(__("Не более {max}"), { max }),
        SIGN_IN_LIKE_A_PARTNER_ERROR: () =>
            __(
                "Ошибка при авторизации от партнера. Вероятно, Вы не вышли из прошлого аккаунта менеджера в партнерском разделе сайта или Вы были не в режиме просмотра партнера. Вы будете разлогинены. После этого повторите, пожалуйста, еще раз.",
            ),
        IVALID_PARAMETERS_STRING: () =>
            __("Поле должно быть корректной строкой параметров запроса"),
        PASSWORD_RESET_MESSAGE_SENT: () =>
            __("Пароль сброшен и сообщение отправлено на почту"),
        EMAIL_SENT_TO_CHANGE_PASSWORD: () =>
            __("Выслано письмо о смене пароля"),
        PASSWORD_RESET_MESSAGE_NO_SENT: () =>
            __(
                "Пароль успешно сброшен, но сообщение не удалось отправить на почту",
            ),
        FAILED_RESET_PASSWORD: () => __("Не удалось сбросить пароль"),
        INVALID_FILE_EXTENSION: () => __("Некорректное расширение файла"),
        DATEEND_LESS_DATESTART: () => __("Дата окончания меньше даты начала"),
    };
};

export const justTranslations = (__: TFunction) => {
    // подписки в кабинете партнера
    __("Новости компании");
    __("Новости партнерской программы");
    __("Акции и промоматериалы");
    __("Обучающие материалы");
    __("Оповещения о новых доменах");
    __("Акции спортивные");
    __("Акции казино");
};
