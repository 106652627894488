import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { Description, PostbackWrapper } from "./styled";
import { Title } from "services/styled-components/typogoraphy";
import {
    useValidationSchema,
    IFormState,
    IPostbackOutputValues,
} from "../services/form-helper";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { Field, Form } from "components/formik";
import { FormGroup } from "styled/layouts";
import { WebsiteField } from "components/filters/fields/website";
import { CampaignField } from "components/filters/fields/campaign";
import { ChildrenField } from "./fields/children-field";
import { PostbackTypeField } from "./fields/postback-type";
import { HideInputsBlock } from "./fields/hide-inputs-block";
import { CheckboxBlock } from "./fields/checkbox-block";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { MethodField } from "./fields/method";
import { FormHelper } from "../services/form-helper";
import * as yup from "yup";

const initialStatus = {
    params: {
        directLink: true,
    },
};

interface IProps {
    initialValues: IFormState;
    onSubmit: (state: IPostbackOutputValues) => any;
    id: number | null;
}

export const PostbacksForm = (props: IProps) => {
    const [__] = useTranslation();
    const getValidation = useValidationSchema();

    const validationSchema = useMemo(
        () => {
            return getValidation();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <PostbackWrapper>
            <AdminWrapper>
                {props.initialValues.statusId && (
                    <Title>
                        {format(__("Текущий статус: {status}"), {
                            status: checkStatus(props.initialValues.statusId),
                        })}
                    </Title>
                )}
                <FormikConsoleErrors
                    initialStatus={initialStatus}
                    initialValues={props.initialValues}
                    validationSchema={() =>
                        // TODO: как это работает c lazy? Почему не работатет посто () => {} без lazy//
                        yup.lazy((values: any) => {
                            return getValidation(values);
                        })
                    }
                    onSubmit={onSubmitWrapper(props.onSubmit, validationSchema)}
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <>
                                <FormGroup>
                                    <WebsiteField
                                        name="site"
                                        selectFirst={true}
                                        isClearable={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <CampaignField
                                        name="campaign"
                                        selectFirst={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <MethodField
                                        id={props.id}
                                        name="typeRequest"
                                        selectFirst={true}
                                        isClearable={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <PostbackTypeField
                                        id={props.id}
                                        name="postbackType"
                                        setFieldValue={setFieldValue}
                                        selectFirst={true}
                                        isClearable={false}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name="variableNameInPartnerSystem"
                                        type="text"
                                        autoComplete="on"
                                        label={__(
                                            "Название события в вашей системе",
                                        )}
                                    />
                                </FormGroup>
                                {values.postbackType ===
                                    FormHelper.postbackBaselineType && (
                                    <HideInputsBlock />
                                )}
                                <FormGroup>
                                    <Field
                                        name="url"
                                        type="text"
                                        autoComplete="on"
                                        label={__("URL")}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name="staticParameters"
                                        type="text"
                                        autoComplete="on"
                                        label={__("Статические параметры")}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name="LFT"
                                        type="textarea"
                                        autoComplete="on"
                                        label={__(
                                            "Пример ссылки для размещения",
                                        )}
                                        prefixComponent={
                                            <Description>
                                                {__(
                                                    "эта ссылка используется для дальнейшей финализации в трекере",
                                                )}
                                            </Description>
                                        }
                                        disabled={true}
                                    />
                                </FormGroup>
                                <CheckboxBlock values={values} />
                                <FormGroup>
                                    <ChildrenField
                                        id={props.id}
                                        name="childId"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name="LFP"
                                        type="textarea"
                                        autoComplete="on"
                                        label={__("Пример ссылки postback")}
                                        disabled={true}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name="comment"
                                        type="textarea"
                                        label={__("Комментарий")}
                                        rows={5}
                                    />
                                </FormGroup>
                                <SubmitButton>
                                    {props.id
                                        ? __("Редактировать postback")
                                        : __("Создать postback")}
                                </SubmitButton>
                            </>
                        </Form>
                    )}
                </FormikConsoleErrors>
            </AdminWrapper>
        </PostbackWrapper>
    );
};

export const checkStatus = (status?: number) => {
    switch (status) {
        case 1:
            return "Created";
        case 3:
            return "Rejected";
        case 4:
            return "Active";
        case 6:
            return "Repeated moderation";
        case 10:
            return "Deleted";
        default:
            return "";
    }
};
