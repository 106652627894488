import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import {
    GetCommissionGroups_authorized_manager_main_commisionGroups,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    PartnerSites_authorized_partnerAndManager_data_sites,
} from "gql/types/operation-result-types";
import { Periods } from "components/filters/fields/period";

export function useValidationSchema() {
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const { momentSchema } = useObjectValidations();

    const validationSchema = yup.object({
        noLimitedGroup: yup.boolean(),
        groupDuration: yup
            .object({
                start: momentSchema.required(FIELD_SHOULD_BE_FILLED),
                end: momentSchema,
                interval: yup.string().oneOf(Object.values(Periods)),
            })
            .required(FIELD_SHOULD_BE_FILLED),
        commisionGroup: yup
            .object<GetCommissionGroups_authorized_manager_main_commisionGroups>()
            .nullable()
            .required(FIELD_SHOULD_BE_FILLED),
        currency: yup
            .object<GetCurrencies_authorized_partnerAndManager_data_currencies>()
            .nullable()
            .required(FIELD_SHOULD_BE_FILLED),
        site: yup
            .object<PartnerSites_authorized_partnerAndManager_data_sites>()
            .nullable(),
    });
    return validationSchema;
}
