import { Modal } from "components/components-common/modal";
import { ModalSize } from "components/components-common/modal/modal-size";
import { PartnerInformation } from "gql/types/operation-result-types";
import { useModalState } from "hooks/use-modal-state";
import { useEffect } from "react";
import { PopupType } from "./popup-id";
import { PopupData } from "./popups-data";
import { usePopupHistory } from "./hooks/use-popup-history";
import { BannerModal, ConfirmModal, CustomModal } from "./banner/styled";
import { InfoModal } from "./info-block/styled";
import { usePopupOnceDay } from "./hooks/use-popup-once-day";

export interface INotificationPopupState {
    countOfTimesWasShown: number;
    clickedDontShowAgain: boolean;
    extraData: Record<string, any>;
    showPopupOnceDay: boolean;
}

export interface INotificationContentProps {
    popupType: PopupType;
    onRequestClose: () => void;
    dontShowAgain: (dontShow: boolean) => void;
    data: PopupData;
}

interface IProps {
    id: number;
    modalData: PopupData;
    partnerInformation: PartnerInformation;
    size?: ModalSize;
    Component: React.ComponentType<INotificationContentProps> | null;
    onClose: () => void;
}

export const NotificationPopup = (props: IProps) => {
    const { Component, onClose, id, modalData, partnerInformation } = props;
    const modalState = useModalState({
        size: props.size || ModalSize.Large,
    });

    const updatedOnRequestClose = modalState.modalProps.onRequestClose;

    modalState.modalProps.onRequestClose = event => {
        if (modalData.canBeClosed) {
            if (onClose) {
                onClose();
            }
            updatedOnRequestClose(event);
        }
    };

    const { popupHistory, setPopupHistory } = usePopupHistory(id);

    const { showPopupOnceDay } = usePopupOnceDay(
        id,
        modalData.showPopupOnceDay,
    );

    const canShow = modalData.show({
        ...partnerInformation,
        ...popupHistory,
        showPopupOnceDay,
    });

    useEffect(() => {
        if (canShow && Component) {
            modalState.open();

            setPopupHistory(prevHistory => ({
                ...prevHistory,
                countOfTimesWasShown: popupHistory.countOfTimesWasShown + 1,
            }));

            if (modalData.type === PopupType.BlockReference) {
                setPopupHistory(prevHistory => ({
                    ...prevHistory,
                    extraData: {
                        ...prevHistory.extraData,
                        linkInPopup: modalData.linkInPopup,
                    },
                }));
            }
        } else {
            onClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveDontShowAgainNotification = (dontShowAgain: boolean) => {
        setPopupHistory({
            ...popupHistory,
            clickedDontShowAgain: dontShowAgain,
        });
    };

    if (!Component) {
        return null;
    }

    let ModalComponent = Modal;

    if (modalData.type === PopupType.Banner) {
        ModalComponent = BannerModal;
    } else if (modalData.type === PopupType.ConfirmEmail) {
        ModalComponent = ConfirmModal;
    } else if (modalData.type === PopupType.InfoBlock) {
        ModalComponent = InfoModal;
    } else if (modalData.type === PopupType.CustomBanner) {
        ModalComponent = CustomModal;
    }

    return (
        <ModalComponent
            {...modalState.modalProps}
            preventCloseWrapper={!modalData.canBeClosed}
        >
            <Component
                popupType={modalData.type}
                onRequestClose={modalState.modalProps.onRequestClose}
                dontShowAgain={saveDontShowAgainNotification}
                data={modalData}
            />
        </ModalComponent>
    );
};
