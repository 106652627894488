import { useRef } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { IReactTableColumn } from "components/react-table/column";
import { ReportReactTable } from "components/react-table/report-react-table";
import { Search } from "components/search";

import {
    GetPartnersUsers,
    GetPartnersUsersVariables,
    ReportStatus,
} from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";
import { usePaginationAndSorting } from "hooks/use-pagination";
import GET_PARTNERS_USERS from "../gql/user-list.gql";

export const UserListEmptyTable = () => {
    const [__] = useTranslation();
    const externalPaginationAndSorting = usePaginationAndSorting();
    const searchValueRef = useRef("");

    const queryResult = useQuery<GetPartnersUsers, GetPartnersUsersVariables>(
        GET_PARTNERS_USERS,
        {
            variables: {
                // по умолчанию бек возвращает максимум на страницу - 100 записей
                query: externalPaginationAndSorting.state,
                search: searchValueRef.current,
            },
            ssr: true,
        },
    );

    const partnersUsers =
        queryResult.data?.authorized.manager.settings.partnersUsers;

    const columns: IReactTableColumn[] = [
        {
            Header: __("Имя пользователя"),
            accessor: "name",
            minWidth: 80,
            disabled: true,
        },
        {
            Header: "Email",
            accessor: "email",
            minWidth: 160,
        },
    ];

    return (
        <AdminWrapper>
            <ReportReactTable
                columns={columns}
                externalPaginationAndSorting={externalPaginationAndSorting}
                queryResult={queryResult}
                data={partnersUsers?.data}
                dataLength={partnersUsers?.totalCount}
                reportStatus={ReportStatus.SUCCESS}
                loading={queryResult.loading}
                searchComponent={
                    <Search
                        onTextChanges={text => {
                            searchValueRef.current = text;
                        }}
                        onApply={async () => {
                            externalPaginationAndSorting.actions.setPagenum(1);
                        }}
                    />
                }
            />
        </AdminWrapper>
    );
};
