import { SpinerItem } from "components/loaders/spiner";
import styled from "styled-components";

export const SpinerWrapper = styled.div`
    border-radius: 3.2rem;
    display: flex;
    align-items: center;
    height: 100%;

    ${SpinerItem} {
        width: 2.2rem;
        height: 2.2rem;
        margin-right: 2rem;
    }
`;
