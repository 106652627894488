import { initialValues } from "./initial-values";
import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import { useTranslation } from "react-i18next";
import { PaymentPeriod } from "gql/types/operation-result-types";

export function useGetValiadationSchema() {
    const [__] = useTranslation();
    const { INVALID_FORMAT, FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const { nullableInterger, enumRuleRequired } = useObjectValidations();

    function getValiadationSchema(values?: {
        // unknown - т.к. сюда доходят даже строки из формы, которые еще не преобразовались в числа
        // но также могут доходить и начальные значения типа null или number
        [key in keyof typeof initialValues]: unknown;
    }) {
        const MIN_DAY_MESSAGE = __("Минимальный день платежа - 1");
        const MAX_DAY_MESSAGE = __("Максимальный день платежа - 31");
        const validationSchema = yup.object().shape({
            paymentPeriod: enumRuleRequired<PaymentPeriod>(),
            firstPaymentMonthDay: nullableInterger
                .required(FIELD_SHOULD_BE_FILLED)
                .min(1, MIN_DAY_MESSAGE)
                .max(31, MAX_DAY_MESSAGE)
                .typeError(INVALID_FORMAT),
            secondPaymentMonthDay:
                values && values.paymentPeriod !== PaymentPeriod.TwicePerMonth
                    ? nullableInterger.typeError(INVALID_FORMAT)
                    : nullableInterger
                          .required(FIELD_SHOULD_BE_FILLED)
                          .min(1, MIN_DAY_MESSAGE)
                          .max(31, MAX_DAY_MESSAGE)
                          .test({
                              message: __(
                                  "Второй день платежа должен быть больше первого дня платежа",
                              ),
                              test(secondDay: number) {
                                  // если первого дня нет - не будем ничего проверять
                                  if (!values?.firstPaymentMonthDay) {
                                      return true;
                                  }

                                  const firstPaymentMonthDay = parseInt(
                                      values.firstPaymentMonthDay as any,
                                  );
                                  return firstPaymentMonthDay < secondDay;
                              },
                          })
                          .typeError(INVALID_FORMAT),
            createPaymentsAutomaticaly: yup.boolean().required(INVALID_FORMAT),
        });

        return validationSchema;
    }

    return getValiadationSchema;
}
