/* eslint-disable @typescript-eslint/ban-types */
import { FormikHelpers as FormikActions } from "components/formik";
import * as yup from "yup";
import { get } from "lodash";
import { t as __ } from "components/language-provider";
import { translationsList } from "services/i18n/translations-list";

// Cмысл в том, что если поле еть в схеме, мы полностью берем его тип из схемы,
// в противном случае тип берется из начальных значений (иногда полезно, когда в памяти формика
// есть что-то что не прохоидит через поля)
type InputAndValidationType<InputType, ValidationType> = Omit<
    InputType,
    keyof ValidationType
> &
    ValidationType;

// должно быть также как и в onSubmitWrapper, но infer и
// необходимость именно функции возвращающей схемы (а не просто схемы)
// не позволяет объединить их полностью
export type IOutputValues<InputType, SchemaFunctionType> =
    SchemaFunctionType extends (
        ...args: any[]
    ) => // важно импользовать yup.Shape т.к. в противном случае, по не совсем точно понятнм причинам,
    // будет влиять как мы пишем схему yup.object({}) или yup.object().shape({}) или yup.object().shape({}).required()
    // что вероятно важно, но будет являться предметом дальнейших исследований при возникновении проблем
    yup.ObjectSchema<yup.Shape<any, infer ValidationType>>
        ? InputAndValidationType<InputType, ValidationType>
        : never;

export const onSubmitWrapper =
    <InputType, ValidationType extends object>(
        onSubmit: (
            values: InputAndValidationType<InputType, ValidationType>,
            formikActions: FormikActions<InputType>,
        ) => Promise<any>,
        validationSchema: yup.ObjectSchema<yup.Shape<any, ValidationType>>,
    ) =>
    async (
        // Значения сюда приходят после валидации, но не после кастинга
        // По типам - там может быть все что угодно, т.к. кастинга еще не было
        // (вполне обычная ситуация, что строка вместо числа)
        // т.е. в конечном итоге мы не знаем точно, какие значения там,
        // кроме может быть названия полей (но не типы).
        values: InputType,
        formikActions: FormikActions<InputType>,
    ) => {
        try {
            // После кастинга, мы точно знаем что там то что в схеме валидации и тех же типов
            // По поводу каких-то других полей, которые есть в начальных значениях, но их нет в схеме
            // мы ничего не можем гарантировать, по крайней мере точно ничего об их типе.
            // Так как, опять же, в примере с числом, в начальных значениях могло быть число,
            // а потом строка из импута, и валидация это не проверяет. Однако, по некоторым причинам в памяти формика
            // может быть также то что не проходит через поля,
            // и то что там есть - будет скорее всего того типа которым оно было в инишиал
            // (это гарантирует просто тайпскрипт). Поэтому as InputAndValidationType
            const castedValues = validationSchema.cast(
                values,
            ) as InputAndValidationType<InputType, ValidationType>;
            if (!castedValues) {
                throw new Error("Unsuccessful casting");
            }
            // formikActions.setValues(castedValues);
            await onSubmit(castedValues, formikActions);
        } catch (ex) {
            log.error("Error in onSubmit function", ex);

            let errors = get(ex, "graphQLErrors[0].data.details.result.errors");
            if (errors) {
                const acc: { [key: string]: string } = {};
                errors = Object.keys(errors).reduce((prev, curr) => {
                    acc[curr] = translations[errors[curr]]
                        ? translations[errors[curr]]()
                        : errors[curr];
                    return acc;
                }, acc);
                formikActions.setErrors(errors);
            }

            throw ex;
        }
        //  finally {
        // onSubmit может обновить стейт компонента в результате чего компонент,
        // который рендерил Formik может перестать существовать.
        // Если такое случается то вызов setSubmittin (который в свою очередь обновляет state формика) приводит к
        // "Warning: Can't perform a React state update on an unmounted component".
        // В данном случае проблем быть не должно, но в бытовой ситуацию - это означает утечку.
        // Тем не менее, выозов setSubmitting делать не нужно
        // потому что Formik САМ ВЫЗОВЕТ ЭТОТ МЕТОД (если это нужно), если handleSubmit ассинхронный.

        // formikActions.setSubmitting(false);
        // }
    };

// TODO: некоторые ошибки, такие как "This email is already registered"
// не могут быть обнаружены на клиентской валидации. Другие дублируюьтся на клиенсткой.
// Похоже что все должны быть сдесь (т.к. клиентская валидация может не совпасть в серверной)
// или надо принять что те что имеют клиентскую не должны быть здесь и оставить тут чисто серверные
const translations: { [key: string]: () => string } = {
    "This email is already registered. Please try another one": () =>
        __("Такой email уже зарегистрирован. Попробуйте использовать другой."),
    "This login is already registered. Please try another one": () =>
        __("Такой логин уже зарегистрирован. Попробуйте использовать другой."),
    "Parameter SubId must contain more than one character or be null": () =>
        __(
            "Параметр Subid должен содержать более одного символа или быть равным нулю.",
        ),
    "This promo code already exists, try another one.": () =>
        __("Этот промокод уже создан, попробуйте другой."),
    "Payment system field contains invalid data.": () => __("Неверный формат"),
    "The field StaticParameters must be correct query string parameters.":
        translationsList(__).IVALID_PARAMETERS_STRING,
    "Count of payments does not match the dates of the start and end of the contract.":
        () =>
            __(
                "Количество платежей не соответствует датам начала и окончания контракта.",
            ),
    "Start date of payment plan less finplan start date": () =>
        __("Дата начала план платежа меньше даты начала финплана"),
    "End date of payment plan more finplan end date": () =>
        __("Дата окончания план платежа больше даты окончания финплана"),
    "Payment plan date less start date of payment plan": () =>
        __("Плановая дата меньше даты начала план платежа"),
    "Payment plan date more end date of payment plan": () =>
        __("Плановая дата больше даты окончания план платежа"),
};
