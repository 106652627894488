import { Context, createContext, ReactChild, useState } from "react";

interface IProps {
    children: ReactChild;
}

export type SetColumnData = React.Dispatch<
    React.SetStateAction<{
        name: string;
        id?: number;
        tableName: string;
    }>
>;

const PickCurrentLineContext: Context<any> = createContext({});
const PickCurrentLineProvider = ({ children }: IProps) => {
    const [columnData, setColumnData] = useState({
        name: "",
        id: null,
        tableName: "",
    });

    return (
        <PickCurrentLineContext.Provider
            value={{
                columnData,
                setColumnData,
            }}
        >
            {children}
        </PickCurrentLineContext.Provider>
    );
};

export { PickCurrentLineContext, PickCurrentLineProvider };
