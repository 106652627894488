import * as _ from "lodash";
import { makeUtcCustomPeriod } from "services/project-utils";
import { AllUniversalOutputValues } from "components/filters/types";
import {
    GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes,
    PartnerSites_authorized_partnerAndManager_data_sites,
} from "gql/types/operation-result-types";

export type SubmitValues = Pick<
    AllUniversalOutputValues,
    "currency" | "marketingToolId" | "subId" | "period" | "registrationSource"
> & {
    website: PartnerSites_authorized_partnerAndManager_data_sites | undefined;
    marketingToolsType:
        | GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes
        | undefined;
};

export const generalQueryParams = (filter: SubmitValues) => {
    const {
        currency,
        website,
        period,
        marketingToolId,
        marketingToolsType,
        subId,
        registrationSource,
    } = filter;

    const { start, end } = makeUtcCustomPeriod(period.start, period.end);

    return {
        currencyId: currency && _.parseInt(currency.id),
        siteId: website && website.id,
        subId,
        startPeriod: (start && start.toISOString()) || "",
        endPeriod: (end && end.toISOString()) || "",
        marketingTool: marketingToolsType && {
            id: _.parseInt(marketingToolsType.id),
            name: marketingToolsType.name,
        },
        marketingToolId:
            (marketingToolId && marketingToolId.toString()) || undefined,
        registrationSourceId: registrationSource && registrationSource.id,
        methood: "get",
    };
};
