import { ReportFormat } from "gql/types/enumReportFormat";

export const exportButtonsByReportFormatDesc = [
    {
        format: ReportFormat.XLSX,
        caption: "Excel",
    },
    {
        format: ReportFormat.CSV,
        caption: "CSV",
    },
];
