import {
    FormDirectionRow,
    FormGroupButton,
    FormGroup,
    FormGroupInputWrapper,
    FormGroupCheckbox,
} from "styled/layouts";
import { FakeLabelSC } from "./styled";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Form as FormikForm } from "components/formik";
import { IBaseFieldProps } from "./services/get-filter-fields";
import {
    ILoadingContext,
    ObjectSchemaOfFields,
} from "./hooks/use-is-select-iptions-loading";

interface IForm {
    submitButtonName?: string;
    fields: {
        [key: string]: (props: IBaseFieldProps) => JSX.Element;
    };
    validationSchema: ObjectSchemaOfFields;
    loadingContext: ILoadingContext;
}

export const Form = (props: IForm) => (
    <FormikForm>
        <FormDirectionRow>
            {Object.entries(props.fields).map(([name, Field], index) => {
                if (name === "period") {
                    return (
                        <Field
                            key={index}
                            name={name}
                            fakeSwapLabel={FakeLabel()}
                            validationSchema={props.validationSchema}
                            loadingContext={props.loadingContext}
                        />
                    );
                }
                let Group = FormGroup;
                if (
                    name === "onlyNewPlayers" ||
                    name === "withoutDepositsOnly"
                ) {
                    Group = FormGroupCheckbox;
                }
                return React.createElement(
                    Group,
                    { key: index },
                    <FormGroupInputWrapper>
                        <Field
                            name={name}
                            validationSchema={props.validationSchema}
                            fakeSwapLabel={FakeLabel()}
                            loadingContext={props.loadingContext}
                        />
                    </FormGroupInputWrapper>,
                );
            })}
            {props.submitButtonName && (
                <FormGroupButton>
                    <FakeLabel />
                    <SubmitButton>{props.submitButtonName}</SubmitButton>
                </FormGroupButton>
            )}
        </FormDirectionRow>
    </FormikForm>
);

export const FakeLabel = () => {
    return <FakeLabelSC role="presentation">&nbsp;</FakeLabelSC>;
};
