import {
    mergeSelectorData,
    getOriginalDataForObject,
    mockLanguages,
} from "components/filters/services";
import { useTranslation } from "react-i18next";
import { useGetLanguages } from "hooks/query/use-language";
import { GetLanguages_languages } from "gql/types/operation-result-types";

export const useLanguagesWithTranslations = () => {
    const [__] = useTranslation();
    const { languages, loading } = useGetLanguages();
    const translatedData = React.useMemo(
        () =>
            mergeSelectorData(
                mockLanguages(__),
                getOriginalDataForObject(languages),
            ),
        [__, languages],
    );
    return {
        languages: translatedData as Array<{
            label: string;
            value: GetLanguages_languages;
        }>,
        loading,
    };
};
