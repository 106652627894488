import { OrderStatus } from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import _ from "lodash";
import { getOrderStatusTranslation } from "services/project-utils";
import { Variables } from "services/variables";

const EMPTY_LINES_BEFORE_DATA = 2;
const FILTER_COLUMN = 5;

export const createSaveReport =
    (format: string, props: any, __: TFunction) => async () => {
        const [{ default: xlsx }, { default: moment }] = await Promise.all([
            await import("xlsx"),
            await import("moment"),
        ]);
        const workbook = xlsx.utils.book_new();
        const headers: string[] = [];
        const accessors = _.map(props.columns, column => {
            headers.push(column.Header as string);
            return column.accessor as string;
        });
        const [filtersColumn1, filtersColumn2] = _.chunk(
            _.keys(props.filters),
            Math.round(_.keys(props.filters).length / 2),
        );
        const hasHeaderInfo =
            !_.includes([".csv"], format) && filtersColumn1 && filtersColumn2;
        const headerRow =
            (hasHeaderInfo &&
                Math.max(3, filtersColumn1.length) + EMPTY_LINES_BEFORE_DATA) ||
            0;

        const values = _.map(props.data, item => {
            return _.map(accessors, accessor => {
                return item[accessor as any];
            });
        });
        _.forEach(values, row => {
            _.forEach(row, (cell, i) => {
                switch (cell) {
                    case OrderStatus.PAID:
                    case OrderStatus.FOR_NEXT_PERIOD:
                    case OrderStatus.AWAITING_PAYMENT:
                    case OrderStatus.PENDING:
                        row[i] = getOrderStatusTranslation(cell, __);
                        break;
                }
            });
        });

        const data = [
            ..._.range(headerRow).map(() => [_.range(10).map(() => "")]),
            headers,
            ...values,
        ];

        const formatFilter = (key: string) => {
            if (!key) {
                return;
            }
            let value: any = props.filters[key as "currency"];
            switch (key) {
                case "currency":
                    key = __("Валюта");
                    value = value ? value.T : __("Не указано");
                    break;
                case "period":
                    key = __("Период");
                    value = `${value.start.format(
                        Variables.dateFormat.minutes,
                    )} - ${value.end.format(Variables.dateFormat.minutes)}`;
                    break;
                case "website":
                    key = __("Сайт");
                    break;
                case "mediaId":
                    key = __("ID медиа");
                    break;
                default:
                    value = _.toString(value);
            }
            return { key, value };
        };

        if (hasHeaderInfo) {
            _.forEach(filtersColumn1, (key, i) => {
                let formatted = formatFilter(key);
                if (formatted) {
                    data[i][FILTER_COLUMN] = formatted.key;
                    data[i][FILTER_COLUMN + 1] =
                        formatted.value || __("Не указано");
                }
                formatted = formatFilter(filtersColumn2[i]);
                if (formatted) {
                    data[i][FILTER_COLUMN + 3] = formatted.key;
                    data[i][FILTER_COLUMN + 4] =
                        formatted.value || __("Не указано");
                }
            });
        }
        const worksheet = xlsx.utils.aoa_to_sheet(data);
        worksheet.A1 = { v: _.upperFirst(props.filename), t: "s" };
        worksheet.A2 = {
            v: moment().format(Variables.dateFormat.seconds),
            t: "s",
        };
        worksheet.A3 = { v: __("ID партнера"), t: "s" };
        worksheet.C3 = {
            v: `${props.user.affiliateId} (${props.user.name})`,
            t: "s",
        };
        worksheet["!merges"] = [
            { s: { r: 0, c: 0 }, e: { r: 0, c: FILTER_COLUMN - 2 } },
            { s: { r: 1, c: 0 }, e: { r: 1, c: FILTER_COLUMN - 2 } },
            { s: { r: 2, c: 0 }, e: { r: 2, c: 1 } },
        ];
        worksheet["!cols"] = headers.map((header, i) => ({
            wch: _.max(_.map(data, row => _.toString(row[i]).length + 2)),
        }));
        xlsx.utils.book_append_sheet(workbook, worksheet);
        xlsx.writeFile(workbook, `${props.filename}.${format}`, {
            cellStyles: true,
        });
    };
