import {
    PartnerAccount_authorized_partnerAccount,
    PartnerInformation,
} from "gql/types/operation-result-types";
import { INotificationPopupState } from "../../notification-popup";
import { PopupType } from "../popup-id";
import { PopupData } from "../popups-data";

interface IInfoBlockPopupDataProps {
    id: number;
    type: PopupType;
    adAgentId: string;
    partnerAccountData: PartnerAccount_authorized_partnerAccount["mainAccountData"];
}

export class InfoBlockPopupData extends PopupData {
    constructor(public props: IInfoBlockPopupDataProps) {
        super(props.id, props.type);
    }

    public show(
        information: INotificationPopupState & PartnerInformation,
    ): boolean {
        const { user } = information.authorized;
        const { clickedDontShowAgain } = information;

        const isAdAgentMatch = user.adAgentId === this.props.adAgentId;

        const shouldShowPopup = isAdAgentMatch && !clickedDontShowAgain;

        return shouldShowPopup;
    }
}
