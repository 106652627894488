import { useTranslation } from "react-i18next";
import { FormGroup, FormGroupInputWrapper } from "styled/layouts";
import { FakeLabel } from "components/filters/form";
import { Field } from "components/formik";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import * as yup from "yup";
import { Form } from "components/formik";

interface SearchProps {
    onApply: (text: string) => Promise<void>;
    onTextChanges?: (text: string) => void;
}

export const Search = ({ onApply, onTextChanges }: SearchProps) => {
    const [__] = useTranslation();

    return (
        <FormikConsoleErrors
            initialValues={{
                search: "",
            }}
            validationSchema={yup.object().shape({
                search: yup.string(),
            })}
            onSubmit={async ({ search }) => await onApply(search)}
        >
            {({ setFieldValue }) => (
                <Form>
                    <FormGroup>
                        <FakeLabel />
                        <FormGroupInputWrapper>
                            <Field
                                name="search"
                                placeholder={__("Поиск")}
                                style={{ maxWidth: "200px" }}
                                type="text"
                                autoComplete="on"
                                onChange={e => {
                                    const value = e.target.value;
                                    setFieldValue("search", value);
                                    onTextChanges?.(value);
                                }}
                            />
                        </FormGroupInputWrapper>
                    </FormGroup>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
