import { useMemo } from "react";
import { GetPaymentSystems_paymentSystems_fields } from "gql/types/operation-result-types";
import { usePaymentSystems } from "hooks/query/use-payment-systems";
import { useTranslationList } from "hooks/use-translations-list";
import { getPaymentSystemTranslations } from "components/filters/services/get-payment-system-translations";
import { useTranslation } from "react-i18next";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { _1XPARTNERS_SPAIN } from "server/partner-programs";

export function usePaymentSystemsWithFields(showDisabled: boolean) {
    const { paymentSystems: initialPaymentSystems } = usePaymentSystems();
    const { INVALID_FORMAT, FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const [__] = useTranslation();

    const { partnersProgramId } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    const sytemsToShow = (initialPaymentSystems || []).filter(
        ps => showDisabled || ps.isDisplay,
    );

    // Было бы удобно сделать валидация промо функцией объектов полей,
    // тогда валидация поля выглядела бы так field.validate()
    // Однако, т.к. поле хранится в стейте реакта, то хранение функции в стейте как части поля - противопоказанно,
    // т.к. может приводить к неожиданному поведению и в частности проводило к бесконечному циклу рендеров.
    function validateField(
        field: GetPaymentSystems_paymentSystems_fields,
        newValue: string,
    ) {
        if (field.required && !newValue) {
            return FIELD_SHOULD_BE_FILLED();
        }

        if (!new RegExp(field.validationTemplate).test(newValue)) {
            return INVALID_FORMAT();
        }
    }

    const paymentSystems = useMemo(() => {
        const translations = getPaymentSystemTranslations(__);
        return sytemsToShow.map(ps => {
            const translatedName =
                partnersProgramId === _1XPARTNERS_SPAIN
                    ? translations.get(ps.id)
                    : ps.name;
            return {
                ...ps,
                name: translatedName || ps.name,
                fields: ps.fields.map(f => ({
                    ...f,
                    value: "",
                })),
            };
        });
    }, [__, partnersProgramId, sytemsToShow]);

    return {
        paymentSystems,
        validateField,
    };
}
