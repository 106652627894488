import { ReactTable } from "components/react-table";
import { AllUniversalOutputValues } from "components/filters/types";
import { useMutation, useQuery } from "@apollo/client";
import PARTNER_INVOICES from "./query.gql";
import {
    ApplyStatusInvoices,
    ApplyStatusInvoicesVariables,
    PartnerInvoices,
    PartnerInvoiceStatus,
    PartnerInvoicesVariables,
    PartnerInvoiceType,
    RegenerateInvoice,
    RegenerateInvoiceVariables,
    SortedPartnerInvoicesType,
} from "gql/types/operation-result-types";
import { makeUtcCustomPeriod } from "services/project-utils";
import { useColumns } from "./use-columns";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { LoadingText } from "components/react-table/loading-text";
import { FormGroup } from "styled/layouts";
import { Search } from "components/search";
import { useRef, useState } from "react";
import { Field } from "components/formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import APPLY_STATUS_INVOICES from "./apply-status.gql";
import { errorHandler } from "services/error-handler";
import REGENERATE_INVOICE from "./regenerate-invoice.gql";
import { useModalState } from "hooks/use-modal-state";
import { ModalSize } from "components/components-common/modal/modal-size";
import { RegenerateInvoiceModal } from "../regenerate-invoice-modal";

interface PartnerInvoicesProps {
    submittedValues: Pick<
        AllUniversalOutputValues,
        "period" | "currency" | "commisionGroup" | "partnersGroup"
    > & {
        invoiceType?: PartnerInvoiceType | null;
        invoiceStatus?: PartnerInvoiceStatus | null;
    };
}

export const PartnerInvoicesReportTable = ({
    submittedValues,
}: PartnerInvoicesProps) => {
    const [__] = useTranslation();
    const externalPaginationAndSorting = usePaginationAndSorting();
    const searchValueRef = useRef("");
    const [currentInvoiceId, setCurrentInvoiceId] = useState<number>();
    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });

    const { start, end } = makeUtcCustomPeriod(
        submittedValues.period.start,
        submittedValues.period.end,
    );
    const [queryCount, setQueryCount] = useState(0);

    const variables = {
        filter: {
            dateStart: start?.toISOString(),
            dateEnd: end?.toISOString(),
            currencyId: Number(submittedValues.currency.id),
            commissionGroupId: submittedValues.commisionGroup?.id,
            partnerGroupId: submittedValues.partnersGroup?.id,
            invoiceType: submittedValues.invoiceType
                ? submittedValues.invoiceType
                : null,
            invoiceStatus: submittedValues.invoiceStatus
                ? submittedValues.invoiceStatus
                : null,
            ...externalPaginationAndSorting.state,
            sorted: externalPaginationAndSorting.state
                .sorted as SortedPartnerInvoicesType,
        },
    };

    const queryResult = useQuery<PartnerInvoices, PartnerInvoicesVariables>(
        PARTNER_INVOICES,
        {
            variables,
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "network-only",
        },
    );
    const dataInvoices =
        queryResult.data?.authorized.manager.payments.partnerInvoices;

    const [mutation] = useMutation<
        ApplyStatusInvoices,
        ApplyStatusInvoicesVariables
    >(APPLY_STATUS_INVOICES, {
        refetchQueries: () => {
            return [
                {
                    query: PARTNER_INVOICES,
                    variables,
                },
            ];
        },
        onCompleted: () => setQueryCount(curQueryCount => curQueryCount + 1),
    });
    const [mutationRegenerate, { loading: loadingRegenerate }] = useMutation<
        RegenerateInvoice,
        RegenerateInvoiceVariables
    >(REGENERATE_INVOICE, {
        refetchQueries: () => {
            return [
                {
                    query: PARTNER_INVOICES,
                    variables,
                },
            ];
        },
    });

    const onClickRegenerate = async () => {
        if (currentInvoiceId) {
            try {
                await mutationRegenerate({
                    variables: {
                        invoiceId: currentInvoiceId,
                    },
                });
                toast.success(__("Инвойс перегенерирован"));
            } catch (ex: any) {
                void errorHandler(ex);
            }
        }
    };

    const columns = useColumns({
        setCurrentInvoiceId,
        loadingRegenerate,
        open,
    });

    const applyStatus = async (
        ids: Set<number>,
        status: PartnerInvoiceStatus | null,
    ) => {
        try {
            if (!status) {
                toast.error(__("Статус не выбран"));
                return;
            }
            if (!ids.size) {
                toast.error(__("Инвойс не выбран"));
                return;
            }
            await mutation({
                variables: {
                    statusId: status,
                    invoicesIds: Array.from(ids),
                },
            });
            toast.success(__("Статус успешно изменен"));
        } catch (ex: any) {
            void errorHandler(ex);
        }
    };

    return (
        <>
            <ReactTable
                columns={columns}
                externalPaginationAndSorting={externalPaginationAndSorting}
                loading={queryResult.loading}
                data={dataInvoices?.invoices || []}
                dataLength={dataInvoices?.rowsCount}
                loadingText={<LoadingText fastLoading={true} />}
                searchComponent={
                    <FormGroup>
                        <Search
                            onTextChanges={text => {
                                searchValueRef.current = text;
                            }}
                            onApply={async () => {
                                void queryResult.refetch({
                                    filter: {
                                        ...variables.filter,
                                        search: searchValueRef.current,
                                    },
                                });
                            }}
                        />
                    </FormGroup>
                }
                rowsCheck={{
                    resultHash: queryCount.toString(),
                }}
                form={{
                    initialStatus: PartnerInvoiceStatus.APPROVED,
                    onSubmit: applyStatus,
                    statusField: (
                        <Field
                            name="status"
                            type="react-select"
                            options={[
                                {
                                    value: PartnerInvoiceStatus.APPROVED,
                                    label: __("Отправить на выплату"),
                                },
                                {
                                    value: PartnerInvoiceStatus.PAID,
                                    label: __("Оплатить"),
                                },
                                {
                                    value: PartnerInvoiceStatus.POSTPONED,
                                    label: __("Перенести на следующий месяц"),
                                },
                            ]}
                            minWidth={200}
                            label={__("Статус")}
                        />
                    ),
                }}
            />
            <RegenerateInvoiceModal
                modalProps={modalProps}
                close={close}
                onClickRegenerate={onClickRegenerate}
                loading={loadingRegenerate}
            />
        </>
    );
};
