import { useTranslation } from "react-i18next";
import { RouteComponentProps, Switch } from "react-router-dom";
import urljoin from "url-join";
import { AdvertisingPayments } from "./adversiting-payments";
import { FinPlan } from "./fin-plan";
import { AddFinPlanSteps } from "./fin-plan/add-fin-plan-steps";
import {
    AdversitingAction,
    ViewAndEditPaymentSteps,
} from "./adversiting-payments/view-edit-steps";
import { AddPaymentSteps } from "./adversiting-payments/add-steps";
import { AddPaymentsFromFile } from "./adversiting-payments/add-payment-from-file";
import { EditFinPlanSteps } from "./fin-plan/edit-fin-plan-steps";
import { PageRoute } from "components/check-roles/guard";

interface IInjectedProps extends RouteComponentProps<{ url: string }> {}

interface IProps extends IInjectedProps {}

export const Advertising = ((props: IProps) => {
    const { url } = props.match;
    const [__] = useTranslation();

    return (
        <Switch>
            <PageRoute
                path={urljoin(url, "/payments")}
                component={AdvertisingPayments}
                pageTitle={__("Рекламные выплаты")}
            />
            <PageRoute
                path={urljoin(url, "/payments/view/:id")}
                component={(params: any) => (
                    <ViewAndEditPaymentSteps
                        {...params}
                        adversitingAction={AdversitingAction.View}
                    />
                )}
                additionalPageTitle={__("Просмотреть выплату")}
            />
            <PageRoute
                path={urljoin(url, "/payments/edit/:id")}
                component={(params: any) => (
                    <ViewAndEditPaymentSteps
                        {...params}
                        adversitingAction={AdversitingAction.Edit}
                    />
                )}
                additionalPageTitle={__("Редактировать выплату")}
            />
            <PageRoute
                path={urljoin(url, "/payments/add")}
                component={AddPaymentSteps}
                pageTitle={__("Рекламные выплаты")}
                additionalPageTitle={__("Добавить выплату")}
            />
            <PageRoute
                path={urljoin(url, "/payments/add-from-file")}
                component={AddPaymentsFromFile}
                pageTitle={__("Рекламные выплаты")}
                additionalPageTitle={__("Добавить выплату из файла")}
            />
            <PageRoute
                path={urljoin(url, "/fin-plans")}
                component={FinPlan}
                pageTitle={__("Фин план")}
            />
            <PageRoute
                path={urljoin(url, "/fin-plans/edit/:id")}
                component={EditFinPlanSteps}
                additionalPageTitle={__("Редактировать фин план")}
            />
            <PageRoute
                path={urljoin(url, "/fin-plans/add")}
                component={AddFinPlanSteps}
                pageTitle={__("Фин план")}
                additionalPageTitle={__("Добавить фин план")}
            />
        </Switch>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
