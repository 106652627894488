import { Switch, useRouteMatch } from "react-router-dom";
import urljoin from "url-join";
import { PageRoute } from "components/check-roles/guard";
import { useTranslation } from "react-i18next";
import { PartnerGroupsTable } from "./partner-groups-table";
import { AddPartnerGroup } from "./add-partner-group";
import { EditPartnerGroup } from "./edit-partner-group";

export const PartnersGroups = () => {
    const [__] = useTranslation();
    const { url } = useRouteMatch();
    const pageTitle = __("Партнерские группы");

    return (
        <Switch>
            <PageRoute
                path={url}
                component={PartnerGroupsTable}
                pageTitle={pageTitle}
            />
            <PageRoute
                path={urljoin(url, "add")}
                component={AddPartnerGroup}
                pageTitle={pageTitle}
                additionalPageTitle={__("Создать партнерскую группу")}
            />
            <PageRoute
                path={urljoin(url, "edit/:id")}
                component={EditPartnerGroup}
                pageTitle={pageTitle}
                additionalPageTitle={__("Редактировать партнерскую группу")}
            />
        </Switch>
    );
};
