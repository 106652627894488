import { GetSubscriptions } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import GET_SUBSCRIPTIONS from "../gql/get-subscribtions.gql";

export function useSubscriptions() {
    const { data, loading } = useQuery<GetSubscriptions>(GET_SUBSCRIPTIONS, {
        ssr: true,
    });

    const subscriptionsList = g(
        data,
        "authorized",
        "partnerAccount",
        "getMailSubscriptions",
    );

    return {
        subscriptionsList,
        loading,
    };
}
