import { ModalSize } from "components/components-common/modal/modal-size";
import { useModalState } from "hooks/use-modal-state";
import { Modal } from "components/components-common/modal";
import { Paragraph } from "services/styled-components/typogoraphy";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { BtnColor, ButtonColored } from "styled/button";
import { useTranslation } from "react-i18next";
import { forwardRef, useState, useImperativeHandle } from "react";
import { SetComissionGroupIdToDeleteProps } from "../index";
import DELETE_COMMISSION_GROUP from "./delete.gql";
import {
    DeleteCommissionGroup,
    DeleteCommissionGroupVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import { errorHandler } from "services/error-handler";
import { toast } from "react-toastify";

interface DeleteComissionGroupModalProps {
    updateComissionGroups?: () => void;
}

const DeleteComissionGroupModalInternal: React.ForwardRefRenderFunction<
    SetComissionGroupIdToDeleteProps,
    DeleteComissionGroupModalProps
> = (props, ref) => {
    const { updateComissionGroups } = props;
    const [__] = useTranslation();

    const [comissionGroupIdToDelete, setCurrentComissionGroupIdToDelete] =
        useState<number>();

    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });

    const closeModal = () => {
        setCurrentComissionGroupIdToDelete(undefined);
        close();
    };

    const [mutation] = useMutation<
        DeleteCommissionGroup,
        DeleteCommissionGroupVariables
    >(DELETE_COMMISSION_GROUP);

    const deleteComissionGroup = async () => {
        if (comissionGroupIdToDelete) {
            try {
                await mutation({ variables: { id: comissionGroupIdToDelete } });
                toast.success(__("Комиссионная группа удалена"));
                if (updateComissionGroups) {
                    updateComissionGroups();
                }
            } catch (error: any) {
                void errorHandler(error);
            }
        }
    };

    useImperativeHandle(ref, () => ({
        setComissionGroupIdToDelete: (comissionGroupId: number) => {
            setCurrentComissionGroupIdToDelete(comissionGroupId);
            open();
        },
    }));

    return (
        <Modal {...modalProps}>
            <Paragraph>{__("Удалить комиссионную группу?")}</Paragraph>
            <FormDirectionRow>
                <FormGroupButton>
                    <ButtonColored
                        type="button"
                        onClick={async () => {
                            await deleteComissionGroup();
                            closeModal();
                        }}
                    >
                        OK
                    </ButtonColored>
                </FormGroupButton>
                <FormGroupButton>
                    <ButtonColored
                        btnColor={BtnColor.Faded}
                        onClick={close}
                        type="button"
                    >
                        {__("Отмена")}
                    </ButtonColored>
                </FormGroupButton>
            </FormDirectionRow>
        </Modal>
    );
};

export const DeleteComissionGroupModal = forwardRef(
    DeleteComissionGroupModalInternal,
);
