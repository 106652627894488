import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import React, { useRef, useEffect } from "react";

interface ChartProps {
    options: Record<string, unknown>;
}

export const Chart = ({ options }: ChartProps) => {
    const chartComponent = useRef<HighchartsReact | null>(null);

    useEffect(() => {
        const timer = setInterval(() => {
            chartComponent.current?.chart.reflow();
        }, 500);
        return () => clearInterval(timer);
    });

    return (
        <HighchartsReact
            ref={chartComponent}
            highcharts={Highcharts}
            constructorType="stockChart"
            options={options}
        />
    );
};
