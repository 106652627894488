import { Spiner } from "components/loaders/spiner";
import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { AdminWrapper, ChartStyled } from "styled/layouts";

interface IProps {
    loading: boolean;
    title: string;
    component: JSX.Element;
}

export const ChartWrapper = React.memo<IProps>(props => {
    const [__] = useTranslation();

    const { loading, title, component: Component } = props;

    return (
        <AdminWrapper width="calc(50% - 10px)">
            <Title>{__(title)}</Title>
            <ChartStyled>{loading ? <Spiner /> : Component}</ChartStyled>
        </AdminWrapper>
    );
});
