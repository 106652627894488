import { WebsiteField } from "components/filters/fields/website";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Form } from "components/formik";
import {
    GetMedia_authorized_partner_media_mediaType,
    GetMediaCode,
    GetMediaCodeVariables,
    PartnerSites_authorized_partnerAndManager_data_sites,
} from "gql/types/operation-result-types";
import { useModalState } from "hooks/use-modal-state";
import { useTranslationList } from "hooks/use-translations-list";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useLazyQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Paragraph } from "services/styled-components/typogoraphy";
import * as yup from "yup";
import { ModalLWithSmallWidthDisappearingFixed } from "./styled";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";

const GET_MEDIA_CODE = gql`
    query GetMediaCode($mediaId: Int!, $mediaType: String!, $siteId: Int!) {
        authorized {
            partner {
                getMediaCode(
                    mediaId: $mediaId
                    mediaType: $mediaType
                    siteId: $siteId
                )
            }
        }
    }
`;

interface IProps {}

export interface ISetMedia {
    setMedia: (
        mediaId: number,
        mediaType: GetMedia_authorized_partner_media_mediaType,
    ) => void;
}

const GetCodeWithModalInternal: React.ForwardRefRenderFunction<
    ISetMedia,
    IProps
> = (props, ref) => {
    const [getCode, { loading, data }] = useLazyQuery<
        GetMediaCode,
        GetMediaCodeVariables
    >(GET_MEDIA_CODE);
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const [validationSchema] = useState(() =>
        yup.object().shape({
            website: yup.object().required(FIELD_SHOULD_BE_FILLED),
        }),
    );
    const [site, setSite] = useState<
        PartnerSites_authorized_partnerAndManager_data_sites | undefined
    >(undefined);
    const [curMediaId, setCurMediaId] = useState<number>(0);
    const [curMediaType, setCurMediaType] =
        useState<GetMedia_authorized_partner_media_mediaType>({
            id: "0",
            __typename: "MediaType",
            name: "",
        });

    useEffect(() => {
        if (site) {
            void getCode({
                variables: {
                    mediaId: curMediaId,
                    siteId: site.id,
                    mediaType: curMediaType.id,
                },
            });
        }
    }, [curMediaId, curMediaType.id, getCode, site]);

    const modalState = useModalState();
    useImperativeHandle(ref, () => ({
        setMedia: (
            mediaId: number,
            mediaType: GetMedia_authorized_partner_media_mediaType,
        ) => {
            setCurMediaId(mediaId);
            setCurMediaType(mediaType);
            modalState.open();
        },
    }));

    return (
        <ModalLWithSmallWidthDisappearingFixed {...modalState.modalProps}>
            <FormikConsoleErrors
                initialValues={{
                    website: site,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, formikActions) => {
                    setSite(values.website);
                    formikActions.setSubmitting(false);
                }}
            >
                <Form>
                    <Paragraph>
                        <WebsiteField name="website" key="website" />
                    </Paragraph>
                    {loading ? (
                        __("Загрузка...")
                    ) : (
                        <textarea
                            value={g(
                                data,
                                "authorized",
                                "partner",
                                "getMediaCode",
                            )}
                            cols={50}
                            rows={10}
                        />
                    )}
                    <SubmitButton>{__("Получить код")}</SubmitButton>
                </Form>
            </FormikConsoleErrors>
        </ModalLWithSmallWidthDisappearingFixed>
    );
};

export const GetCodeWithModal = forwardRef(GetCodeWithModalInternal);
