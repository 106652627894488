import { NavLinkProps } from "react-router-dom";
import { ColoredNavLink, StyledNavLink } from "styled/link";

interface IProps extends NavLinkProps {
    showBackground?: boolean;
    buttonGradient?: string;
    withRadius?: boolean;
}

export const LocalizedNavLinkWithOptionalBackground = React.memo(
    (props: IProps) => {
        const { showBackground: colored, ...rest } = props;

        if (colored || props.buttonGradient) {
            return <ColoredNavLink {...rest} />;
        }

        return <StyledNavLink {...rest} />;
    },
);
