import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetManagerGraph,
    GetManagerGraphVariables,
} from "gql/types/operation-result-types";
import { usePooling } from "hooks/use-pooling";
import { useQuery } from "@apollo/client";
import GET_MANAGER_GRAPH from "../gql/get-manager-graph.gql";

export const useManagerChart = (
    mockPeriod: string,
    currency:
        | GetCurrencies_authorized_partnerAndManager_data_currencies
        | undefined,
) => {
    const id = currency?.id;
    const queryResult = useQuery<GetManagerGraph, GetManagerGraphVariables>(
        GET_MANAGER_GRAPH,
        {
            variables: {
                currencyId: Number(id),
                period: Number(mockPeriod),
            },
            skip: !id,
            fetchPolicy: "network-only",
            ssr: false,
        },
    );

    const data =
        queryResult.data?.authorized.manager.main.mainStatistic.graph.data;

    const status =
        queryResult.data?.authorized.manager.main.mainStatistic.graph.status;

    usePooling(queryResult, status);

    return {
        data,
        loading: queryResult.loading,
        queryResult,
    };
};
