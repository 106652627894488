import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { FormDirectionRow, FormGroup, FormGroupButton } from "styled/layouts";
import { FakeLabel } from "components/filters/form";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { CurrencyField } from "components/filters/fields";
import { Form, Field } from "components/formik";
import { CampaignField } from "components/filters/fields/campaign";
import { useTranslation } from "react-i18next";
import {
    AddPartnersPromocode,
    AddPartnersPromocodeVariables,
} from "gql/types/operation-result-types";
import { useValidationSchema } from "./validation-scheme";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { errorHandler } from "services/error-handler";
import { useMutation } from "@apollo/client";
import ADD_PARTNERS_PROMOCODE from "./add-promocode.gql";
import { SiteField } from "../../../fields/site";
import { ErrorType } from "server/errors/error-type";

export const AddPromocodeForm = ({
    partnerId,
    onLoaded,
}: {
    partnerId: number;
    onLoaded: (id?: number) => void;
}) => {
    const [__] = useTranslation();

    const [mutation] = useMutation<
        AddPartnersPromocode,
        AddPartnersPromocodeVariables
    >(ADD_PARTNERS_PROMOCODE);

    const validationSchema = useValidationSchema();

    return (
        <FormikConsoleErrors
            initialStatus={{
                params: {},
            }}
            initialValues={{
                site: null,
                currency: null,
                campaign: null,
                promocode: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async (values, { resetForm }) => {
                try {
                    const { data } = await mutation({
                        variables: {
                            promoCode: {
                                partnerId,
                                currencyId: Number(values.currency.id),
                                siteId: Number(values.site.id),
                                companyId: Number(values.campaign.id),
                                promoCode: values.promocode,
                            },
                        },
                    });
                    void onLoaded(
                        data?.authorized.manager.main.addPromoCode.id,
                    );
                    resetForm();
                } catch (ex: any) {
                    void errorHandler(ex, error => {
                        switch (error.data.code) {
                            case ErrorType.LIMIT_OF_PROMOCODES:
                                return __("Лимит превышен");
                            case ErrorType.PROMOCODE_ALREADY_EXISTS:
                                return __("Промокод существует");
                            case ErrorType.VALIDATION_ERROR:
                                return __("Ошибка валидации");
                        }
                    });
                }
            }, validationSchema)}
        >
            {() => (
                <Form>
                    <FormDirectionRow>
                        <FormGroup>
                            <SiteField name="site" partnerId={partnerId} />
                        </FormGroup>
                        <FormGroup>
                            <CurrencyField
                                name="currency"
                                label={__("Валюта")}
                                isClearable={false}
                                partnerId={partnerId}
                                selectFirst
                            />
                        </FormGroup>
                        <FormGroup>
                            <CampaignField
                                name="campaign"
                                selectFirst={false}
                                partnerId={partnerId}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                name="promocode"
                                type="text"
                                label={__("Промокод")}
                                autoComplete="on"
                            />
                        </FormGroup>
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>
                                {__("Сгенерировать промо-код")}
                            </SubmitButton>
                        </FormGroupButton>
                    </FormDirectionRow>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
