import { css } from "styled-components";

const fontsPath = "../assets/fonts/hind/";

export const HindFonts = css`
    @font-face {
        font-family: "Hind";
        src: url("${fontsPath}Hind-Light.ttf") format("ttf");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Hind";
        src: url("${fontsPath}Hind-Regular.ttf") format("ttf");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Hind";
        src: url("${fontsPath}Hind-Medium.ttf") format("ttf");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Hind";
        src: url("${fontsPath}Hind-SemiBold.ttf") format("ttf");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Hind";
        src: url("${fontsPath}Hind-Bold.ttf") format("ttf");
        font-weight: 700;
        font-style: normal;
    }
`;
