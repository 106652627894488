import { TFunction } from "react-i18next";

interface ILegendItemClick {
    target: {
        visible: boolean;
        chart: {
            legend: {
                allItems: unknown[];
            };
            yAxis: Array<{ update: (param: unknown) => void }>;
        };
        index: number;
    };
}

export const legendItemClick = (event: ILegendItemClick) => {
    const visibleLegendsIndex = [];
    if (event.target.visible !== true) {
        visibleLegendsIndex.push(event.target.index);
    }
    event.target.chart.legend.allItems.forEach((item: any) => {
        if (item.index !== event.target.index && item.visible === true) {
            visibleLegendsIndex.push(item.index);
        }
    });
    const margin = 1;
    const oneHeight = Math.floor(
        (100 - visibleLegendsIndex.length) / visibleLegendsIndex.length,
    );
    let index = 0;
    for (let ii = 0; ii < event.target.chart.yAxis.length; ii++) {
        if (ii < 3 && visibleLegendsIndex.includes(ii)) {
            event.target.chart.yAxis[ii].update({
                height: oneHeight + "%",
                top: index === 0 ? "0%" : (oneHeight + margin) * index + "%",
            });
            index++;
        }
    }
};

export const langOptionChart = (__: TFunction) => {
    return {
        lang: {
            rangeSelectorZoom: __("Фильтр"),
            months: [
                __("Январь"),
                __("Февраль"),
                __("Март"),
                __("Апрель"),
                __("Май"),
                __("Июнь"),
                __("Июль"),
                __("Август"),
                __("Сентябрь"),
                __("Октябрь"),
                __("Ноябрь"),
                __("Декабрь"),
            ],
            shortMonths: [
                __("Янв"),
                __("Фев"),
                __("Мар"),
                __("Апр"),
                __("Май"),
                __("Июн"),
                __("Июл"),
                __("Авг"),
                __("Сен"),
                __("Окт"),
                __("Ноя"),
                __("Дек"),
            ],
            weekdays: [
                __("Воскресенье"),
                __("Понедельник"),
                __("Вторник"),
                __("Среда"),
                __("Четверг"),
                __("Пятница"),
                __("Суббота"),
            ],
            shortWeekdays: [
                __("Вт"),
                __("Пн"),
                __("Вт"),
                __("Ср"),
                __("Чт"),
                __("Пт"),
                __("Сб"),
            ],
        },
    };
};
