import { UniversalForm } from "components/filters";
import {
    ManagerQuickReport,
    ManagerQuickReportVariables,
} from "gql/types/operation-result-types";
import { usePooling } from "hooks/use-pooling";
import * as _ from "lodash";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import MANAGER_QUICK_REPORT from "./query.gql";
import { makeUtcCustomPeriod } from "services/project-utils";
import { QuickReportTable } from "views/partner-manager/quick-report";
import { SitePartitionType } from "views/site-patrition-wrapper";

export const QuickReport = React.memo(() => {
    const [__] = useTranslation();

    const [getQuickReport, queryResult] = useLazyQuery<
        ManagerQuickReport,
        ManagerQuickReportVariables
    >(MANAGER_QUICK_REPORT);

    usePooling(
        queryResult,
        queryResult.data?.authorized.manager.reports.quickReport.status,
        4000,
    );

    const sitePartition: SitePartitionType = SitePartitionType.Manager;

    return (
        <UniversalForm
            fields={[
                "currency",
                "period",
                "commisionGroup",
                "partnersGroup",
                "partnerId",
                "marketingToolId",
            ]}
            onSubmit={async submittedValues => {
                const {
                    currency,
                    period,
                    marketingToolId,
                    commisionGroup,
                    partnerId,
                    partnersGroup,
                } = submittedValues;
                const { start, end } = makeUtcCustomPeriod(
                    period.start,
                    period.end,
                );
                await getQuickReport({
                    variables: {
                        filter: {
                            currencyId: _.parseInt(currency && currency.id),
                            startPeriod: (start && start.toISOString()) || "",
                            endPeriod: (end && end.toISOString()) || "",
                            promoId:
                                (marketingToolId &&
                                    marketingToolId.toString()) ||
                                undefined,
                            commisionGroupId: commisionGroup?.id,
                            partnerId,
                            partnersGroupId: partnersGroup?.id,
                        },
                    },
                });
            }}
            submitButtonName={__("Сгенерировать отчет")}
        >
            {({ form, submittedValues }) => {
                return (
                    <>
                        {form}
                        <QuickReportTable
                            tableData={
                                queryResult.data?.authorized.manager.reports
                                    .quickReport.total
                            }
                            currency={submittedValues?.currency}
                            queryResult={queryResult}
                            sitePartition={sitePartition}
                        />
                    </>
                );
            }}
        </UniversalForm>
    );
});
