import { AdminWrapper, OnOppositeSides } from "styled/layouts";
import { Title } from "services/styled-components/typogoraphy";
import { FormDirectionColumn, FormGroup } from "styled/layouts";
import { TablePeriodField } from "components/filters/fields/table-period";
import { Form } from "components/formik";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { useTranslation } from "react-i18next";
import { ShortTimePeriods } from "gql/types/operation-result-types";
import { ReactElement } from "react";

interface IProps {
    table: (props: { shortPeriod: ShortTimePeriods | null }) => ReactElement;
    initialShortPeriod: ShortTimePeriods;
}

export const QuickStatistic = (props: IProps) => {
    const [__] = useTranslation();

    return (
        <AdminWrapper>
            <FormikConsoleErrors
                initialValues={{
                    shortPeriod: props.initialShortPeriod,
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSubmit={() => {}}
            >
                {formikProps => (
                    <>
                        <OnOppositeSides>
                            <Title>{__("Быстрая статистика")}</Title>
                            <Form>
                                <FormDirectionColumn>
                                    <FormGroup>
                                        <TablePeriodField />
                                    </FormGroup>
                                </FormDirectionColumn>
                            </Form>
                        </OnOppositeSides>
                        {props.table({
                            shortPeriod: formikProps.values.shortPeriod,
                        })}
                    </>
                )}
            </FormikConsoleErrors>
        </AdminWrapper>
    );
};
