import { usePrevious } from "hooks/use-previous";
import { ReactNode, useEffect } from "react";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import { DomUtils } from "services/dom-utils";

interface IInjectedProps extends RouteComponentProps {}

interface IProps extends IInjectedProps {
    children?: ReactNode;
}

export const ScrollToTop = withRouter(
    React.memo((props: IProps) => {
        const previousLocationPathname = usePrevious(props.location.pathname);
        const { hash } = useLocation();

        useEffect(() => {
            if (
                previousLocationPathname &&
                props.location.pathname !== previousLocationPathname &&
                hash === ""
            ) {
                DomUtils.animate(
                    document.scrollingElement || document.documentElement,
                    "scrollTop",
                    "",
                    0,
                    0,
                    300,
                    true,
                );
            }
        });
        return (props.children as any) || <></>;
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
