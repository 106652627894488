import { Field } from "components/formik";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { CurrencyField } from "components/filters/fields/currency";
import { Form } from "components/formik";
import { FormDirectionRow, FormGroup, FormGroupButton } from "styled/layouts";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";
import {
    AddPartnersBonus,
    AddPartnersBonusVariables,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
} from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import ADD_BONUS from "./add-bonus.gql";
import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { errorHandler } from "services/error-handler";

export const AddBonusForm = ({
    partnerId,
    onLoaded,
}: {
    partnerId: number;
    onLoaded: (id: number) => void;
}) => {
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const { objectRequiredRule } = useObjectValidations();
    const [mutation] = useMutation<AddPartnersBonus, AddPartnersBonusVariables>(
        ADD_BONUS,
    );

    const validationSchema = yup.object({
        amountRequest: yup.number().required(FIELD_SHOULD_BE_FILLED),
        currency:
            objectRequiredRule<GetCurrencies_authorized_partnerAndManager_data_currencies>(),
        description: yup.string().nullable(),
    });

    return (
        <FormikConsoleErrors
            initialValues={{
                amountRequest: "",
                currency:
                    null as GetCurrencies_authorized_partnerAndManager_data_currencies | null,
                description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async (values, { resetForm }) => {
                try {
                    const { data } = await mutation({
                        variables: {
                            bonus: {
                                partnerId,
                                currencyId: Number(values.currency.id),
                                amount: values.amountRequest,
                                description: values.description,
                            },
                        },
                    });
                    const id = data?.authorized.manager.main.addBonus.id;
                    if (id) {
                        void onLoaded(id);
                    }
                    resetForm();
                } catch (ex: any) {
                    void errorHandler(ex);
                }
            }, validationSchema)}
        >
            <Form>
                <FormDirectionRow>
                    <FormGroup>
                        <Field
                            name="amountRequest"
                            type="text"
                            label={__("Сумма бонуса")}
                            style={{ minWidth: "250px" }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <CurrencyField
                            name="currency"
                            isClearable={false}
                            label={__("Валюта")}
                            partnerId={partnerId}
                            selectFirst
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="description"
                            type="text"
                            label={__("Описание бонуса")}
                            style={{ minWidth: "250px" }}
                        />
                    </FormGroup>
                    <FormGroupButton>
                        <FakeLabel />
                        <SubmitButton>{__("Начислить бонус")}</SubmitButton>
                    </FormGroupButton>
                </FormDirectionRow>
            </Form>
        </FormikConsoleErrors>
    );
};
