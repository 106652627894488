import { useTranslation } from "react-i18next";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { Edit } from "@styled-icons/boxicons-regular/Edit/Edit";
import { ViewShow } from "@styled-icons/zondicons/ViewShow";
import { ViewHide } from "@styled-icons/zondicons/ViewHide";

interface IEditButtons {
    isVisible?: boolean;
    loading: boolean;
    disabled?: boolean;
    handleEdit: () => void;
    handleViewChange: () => void;
}

export const EditButtons = ({
    isVisible,
    disabled,
    handleEdit,
    handleViewChange,
}: IEditButtons) => {
    const [__] = useTranslation();

    return (
        <>
            <ButtonColoredWithIcon
                disabled={disabled}
                btnColor={BtnColor.Faded}
                type="button"
                onClick={handleEdit}
                title={__("Редактировать")}
            >
                <Edit size={15} />
            </ButtonColoredWithIcon>
            <ButtonColoredWithIcon
                disabled={disabled}
                btnColor={BtnColor.Faded}
                type="button"
                onClick={handleViewChange}
                title={isVisible ? __("Скрыть") : __("Показать")}
            >
                {isVisible ? <ViewHide size={15} /> : <ViewShow size={15} />}
            </ButtonColoredWithIcon>
        </>
    );
};
