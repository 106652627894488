import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Field, Form, FormikValues } from "components/formik";
import { useTranslation } from "react-i18next";
import { FeedbackFormContainer } from "./styled";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import * as yup from "yup";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    initialValues: FormikValues;
    validationSchema: yup.ObjectSchema;
    onSubmit: any;
}

export const FeedbackForm = React.memo((props: IProps) => {
    const [__] = useTranslation();

    return (
        <FeedbackFormContainer>
            <FormikConsoleErrors
                initialValues={props.initialValues}
                validationSchema={props.validationSchema}
                onSubmit={(values, actions) => {
                    props.onSubmit(values, actions);
                }}
            >
                {() => {
                    return (
                        <Form>
                            <>
                                <Field
                                    name="message"
                                    type="textarea"
                                    autoComplete="on"
                                    label={__("Введите сообщение")}
                                />
                                <SubmitButton width="100%">
                                    {__("Отправить")}
                                </SubmitButton>
                            </>
                        </Form>
                    );
                }}
            </FormikConsoleErrors>
        </FeedbackFormContainer>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
