import {
    adminBorderColor,
    adminBorderColor2,
    adminBorderColor3,
} from "services/styled-components/themes";
import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";

export const TabsSC = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    flex: 1 0 auto;
    position: relative;
    /* stylelint-disable */

    > .transparent {
        & + .tabs_panels {
            background-color: transparent;
        }
    }

    .settings_tabs {
        display: flex;
        flex-flow: row nowrap;
        overflow: auto;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        ${media.sm`
            justify-content: center;
        `}
        .buttontab {
            color: ${props => props.theme.primaryColor};
            background-color: ${adminBorderColor2};
            font-size: 0.8em;
            border-radius: 5px 5px 0 0;
            padding: 1.5em;
            ${media.xs`
                margin-left: 1px;
                margin-right: 1px;
                padding: 0.5em 1.5em;
            `}
            ${media.md`
                margin-left: 1%;
                margin-right: 1%;
            `}
            &:hover {
                background-color: ${adminBorderColor2};
                padding: 1.5em;
                ${media.xs`
                    margin-left: 1px;
                    margin-right: 1px;
                    padding: 0.5em 1.5em;
                `}
                ${media.md`
                    margin-left: 1%;
                    margin-right: 1%;
                `}
            }
        }
        .buttontabactive {
            background-color: ${adminBorderColor2};
            color: ${props => props.theme.activeColor};
        }
    }
    .two_tabs {
        ~ .tabs_panels > *:first-child {
            border-top: 0 none;
        }
        .buttontab {
            font-size: 15px;
            width: 50%;
            border: 0 none;
            margin: 0;
            padding: 15px 0;
            text-transform: uppercase;
            border-radius: 0;
            font-weight: normal;
            background-color: ${props => props.theme.activeColor};
            &:hover {
                margin: 0;
                padding: 15px 0;
            }
            ${media.xsMax`
                font-size: 70%;
            `}
        }
        .buttontabactive {
            background-color: ${adminBorderColor2};
        }
        ${media.xsMax`
            margin-top: -0.2em;
        `}
    }
`;

export const TabsPanels = styled.section`
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;
    flex-direction: column;
    flex-wrap: nowrap;
    /* > *:first-child {
        border-top: 1px solid ${adminBorderColor};
    } */
`;

export interface IProps {
    active?: boolean;
}
export const ButtonTab = styled.div<IProps>`
    cursor: pointer;
    position: relative;
    width: min-content;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.adminTitleColor};
    font-size: 0.75em;
    padding: 0.8em 2.3em;
    transition-property: margin, padding, color;
    transition-duration: 0.1s;
    transition-timing-function: ease;
    /* border: 1px solid ${adminBorderColor}; */
    background-color: ${adminBorderColor3};
    color: ${props => props.theme.primaryColor};
    ${props =>
        props.active &&
        css`
            cursor: auto;
            background-color: ${props.theme.backgroundColor};
            color: ${props.theme.textColor};
            &::after {
                background-color: ${props.theme.backgroundColor};
            }
        `}
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -1.35em;
        height: 101%;
        width: 2.6em;
        transform: skew(-25deg);
        z-index: 1;
    }
    &:first-of-type {
        &::after {
            left: auto;
            right: -1.35em;
            transform: skew(25deg);
        }
    }
    ${media.xsMax`
            flex: 1 0 auto;
            margin: 0;
            border-left: 0 none !important;
        `}
    &:focus {
        outline: 0 none;
    }
`;

export const TabsNavigation = styled.div`
    display: flex;
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: 0.44em;

    ${media.xsMax`
        padding-top: 0.2em;
        display: flex;
    `}
`;

export const OrdersCunter = styled.span`
    font-weight: 500;
    color: ${props => props.theme.activeColor};
`;
