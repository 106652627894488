import { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { ReactTable } from "components/react-table";
import { Search } from "components/search";
import { SetFieldValue } from "components/formik";
import {
    getColumns,
    useControlCheckboxHandlers,
} from "views/manager/main/partners-groups/common";
import {
    GetCountries_countries,
    AvailablePartners_authorized_manager_main_availablePartners_partners,
} from "gql/types/operation-result-types";
import { FormValues } from "./models";

interface IProps {
    formValues: FormValues;
    filter?: {
        partnerId?: number | null;
        country?: GetCountries_countries;
    };
    setFieldValue: SetFieldValue;
}

const SelectedPartnersComponent = ({
    filter,
    formValues,
    setFieldValue,
}: IProps) => {
    const [__] = useTranslation();
    const { onRemove, onRemoveAll } = useControlCheckboxHandlers({
        list: formValues.partners,
        fieldName: "partners",
        setFieldValue,
    });
    const [searchValue, setSearchValue] = useState("");
    const columns =
        getColumns<AvailablePartners_authorized_manager_main_availablePartners_partners>(
            {
                __,
                onChange: onRemove,
                onChangeAll: onRemoveAll,
                defaultSelected: true,
            },
        );
    const tableData = formValues.partners.filter(partner => {
        let result = true;

        if (filter?.partnerId) {
            result = partner.id === filter.partnerId;
        }

        if (filter?.country) {
            result = partner.country === filter.country.name;
        }

        if (searchValue) {
            result = JSON.stringify(partner).includes(searchValue);
        }

        return result;
    });

    return (
        <ReactTable
            columns={columns}
            data={tableData}
            searchComponent={
                <Search
                    onApply={async newSearchValue =>
                        setSearchValue(newSearchValue)
                    }
                />
            }
        />
    );
};

export const SelectedPartners = memo(SelectedPartnersComponent);
