import React from "react";
import { ExportButton as _ExportButton } from "components/react-table/table-select-variables/export/components/export-button";
import { ReportFormat } from "gql/types/enumReportFormat";
import { useExportPartners } from "../hooks/use-export-partnes";
import { IExportPartnersFilter } from "server/middlewares/get-export-partners";

interface IProps {
    filters: IExportPartnersFilter;
    format: ReportFormat;
    caption: string;
}

export const ExportButton = React.memo((props: IProps) => {
    const { format, caption, filters } = props;
    const { loading, exportReport } = useExportPartners(filters, format);

    return (
        <_ExportButton
            key={format}
            exportData={exportReport}
            saving={loading}
            caption={caption}
        />
    );
});
