import { ISelectFild } from "components/filters/select-filed-interface";
import { Field } from "components/formik";
import { useIsSelectOptionsLoading } from "components/filters/hooks/use-is-select-iptions-loading";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import {
    PartnerSitesList,
    PartnerSitesListVariables,
} from "gql/types/operation-result-types";
import PARTNER_SITES from "../gql/partner-sites.gql";

interface IProps extends ISelectFild {
    partnerId: number;
}

export const SiteField = (props: IProps) => {
    const [__] = useTranslation();
    const { name, disabled, partnerId, ...rest } = props;

    const { data, loading } = useQuery<
        PartnerSitesList,
        PartnerSitesListVariables
    >(PARTNER_SITES, {
        variables: {
            partnerId,
        },
    });
    const sites = data?.authorized.manager.main.sites;

    const options = (sites || []).map(site => ({
        value: site,
        label: site.name,
    }));

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={name}
            label={__("Сайт")}
            type="react-select"
            options={options}
            isLoading={loading}
            isDisabled={disabled || loading}
            minWidth={300}
            {...rest}
        />
    );
};
