import { GetPaymentSystems } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

export enum PaymentSystemType {
    BankTransfer = 49,
    PlayerAccount = 51,
    BankTransferWithCompany = 255,
}

const GET_PAYMENT_SYSTEMS = gql`
    query GetPaymentSystems {
        paymentSystems {
            id
            code
            name
            isDisplay
            fields {
                id
                name
                validationTemplate
                required
            }
        }
    }
`;

export const usePaymentSystems = () => {
    const paymentSystemsList = useQuery<GetPaymentSystems>(
        GET_PAYMENT_SYSTEMS,
        {
            ssr: true,
        },
    );

    const paymentSystems = g(paymentSystemsList, "data", "paymentSystems");

    return {
        paymentSystems,
        loading: paymentSystemsList.loading,
        error: paymentSystemsList.error,
    };
};
