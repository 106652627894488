import { SelectSC } from "components/formik/universal-field/fields/select/styled";
import { StyledUniversalField } from "components/formik/universal-field/styled";
import { textInputs, darken } from "polished";
import {
    adminBorderColor,
    backgroundColor0,
} from "services/styled-components/themes";
import { internalPages } from "services/styled-components/typogoraphy";
import {
    hiddenFunc,
    maxWidth,
    media,
    IE,
} from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { ButtonColored, ButtonStyled } from "./button";
import { rtl } from "services/styled-components/rtl";
import {
    ColoredNavLink,
    StyledNavLink,
    DefaultColoredLinkButton,
} from "styled/link";
import {
    Title as TitleTypogoraphy,
    Paragraph,
} from "services/styled-components/typogoraphy";

/* stylelint-disable */

interface IProps {
    forAdminPanel?: boolean;
    isProfileMenuClicked?: boolean;
    isMainMenuClicked?: boolean;
    isMenuBurgerClickedOnLanding?: boolean;
}

interface IFormGroupButton {
    hasExtraMargin?: boolean;
}

export const BaseTitle = css`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2em;
`;

export const DecorLine = styled.div<IProps>`
    width: 32px;
    height: 4px;
    background: ${props => props.theme.accentColor};
    ${media.smMax`
        margin: 0 auto;
    `}
`;

export const Content = styled.div<IProps>`
    min-height: 100vh;

    ${IE`
        height: 1px;
    `}

    position: relative;
    color: ${props => props.theme.bodyColor};
    background-color: ${backgroundColor0};
    font-size: 16px;
    font-family: "Ubuntu", Helvetica, Arial, sans-serif;
    font-weight: normal;
    ${media.ltMax`
        font-size: 14px;
    `}
    &::before {
        content: "";
        z-index: -1;
        opacity: 0;
        transition: all 0.3s linear;
    }

    ${props =>
        (props.isProfileMenuClicked || props.isMainMenuClicked) &&
        media.smMax`
            &::before {
                content: "";
                width: 100%;
                height: 100%;
                position: fixed;
                background: rgba(0, 0, 0, 0.8);
                z-index: 8;
                opacity: 1;
                transition: all .3s linear;
            }
    `};

    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    ::-webkit-scrollbar {
        width: 6px;
        height: 4px;
        background-color: rgba(0, 0, 0, 0.06);
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background-color: rgba(172, 212, 233, 0.3);
        transition: background-color 0.2s linear;
    }
    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    :hover::-webkit-scrollbar {
        background-color: rgba(0, 0, 0, 0.15);
    }
    :hover::-webkit-scrollbar-thumb {
        background-color: rgba(172, 212, 233, 0.6);
    }
    :hover::-webkit-scrollbar-thumb:hover {
        background-color: rgba(89, 168, 211, 0.7);
    }
`;

export const ContentColumn = styled(Content)<IProps>`
    flex-direction: column;
    transition: width 0.3s ease-in-out;
    width: ${props => (props.forAdminPanel ? "calc(100% - 228px)" : "100%")};
    flex: 1 1 auto;
    ${props =>
        props.isMainMenuClicked &&
        css`
            width: calc(100% - 70px);
        `}

    ${props => media.mdMax`
        width: ${props.forAdminPanel ? "calc(100% - 70px)" : "100%"};
    `}
`;

export const AuthContent = styled.div<IProps>`
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
    background-image: url("/config-files/auth_bg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const AdminAreaWrapper = styled(Content)<IProps>`
    min-height: 100vh;
    flex-direction: column;
    transition: all 0.3s ease-in-out;
    will-change: width, flex;

    ${rtl`margin-left: auto;`};
    &::before {
        content: none;
    }

    width: calc(100% - 228px);

    ${props => media.mdMax`
            width: calc(100% - 70px);
        `}
    ${props => media.smMax`
        width:100%;
        margin-top: 64px;
    `}

    ${props =>
        props.isMainMenuClicked &&
        css`
            width: calc(100% - 70px);

            ${media.mdMax`
                width: calc(100% - 228px);
            `}

            ${media.smMax`
                width:100%;
                margin-top: 64px;
            `}
        `}
`;

export const Main = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    ${media.mdMax`
        margin-top: 56px;
    `}
`;

export const MainSign = styled.div<IProps>`
    overflow-x: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
`;

export const FormWrapper = styled.div`
    text-align: center;
    /* display: table; */
    margin: 0 auto;
    max-width: 280px;
    > ${textInputs()} {
        width: 100%;
    }
    ${media.sm`
        max-width: 600px;
    `}
`;

export const ButtonInput = styled.span`
    > input,
    > input + button,
    > input + a {
        margin-bottom: 1em;
    }
    ${media.md`
        display: flex; 
        > ${textInputs()} {
            width: auto;
            flex: 1 0 auto;
            ${rtl`
                border-right-width: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            `};
            line-height: normal;
            & + button,
            & + a {
                line-height: normal;
                ${rtl`
                    border-left-width: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                `};

            }
        }
    `}
`;

export const WrapperFullWidth = styled.section`
    padding-left: 10px;
    padding-right: 10px;
    ${hiddenFunc}
`;

export const Wrapper = styled.div`
    position: relative;
    max-width: ${maxWidth};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`;

export const InfoPageWrapper = styled.div`
    padding: 20px 0;
    margin-bottom: 40px;
    color: ${props => props.theme.landingTextColor};
    ${internalPages}

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding-top: 0;
    }

    & h1 {
        padding-bottom: 30px;
        font-size: 2em;
        text-transform: uppercase;
        font-weight: bold;
        color: ${props => darken(0.1, props.theme.landingTextColor)};
    }

    & img {
        max-width: 100%;
        height: auto;
    }

    table[border="1"] td {
        border: 1px solid currentColor;
    }
`;

interface IAdminWrapper {
    width?: string;
    containslist?: boolean;
}

export const AdminWrapper = styled.div<IAdminWrapper>`
    /* display: grid; */
    position: relative;
    border: 1px solid ${adminBorderColor};
    padding: 15px;
    margin-bottom: 10px;
    ${({ width }) => media.md`
        width: ${width ? width : "100%"};`}
    & ul {
        ${({ containslist }) =>
            containslist &&
            css`
                list-style-type: disc;
                ${rtl`margin-left: 20px;`};
            `}
    }
`;

export const AdminWrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -5px;
    margin-right: -5px;
    ${AdminWrapper} {
        margin-left: 5px;
        margin-right: 5px;
    }
    ${props => media.md`
        flex-direction: row;
    `}
`;

export const ChartStyled = styled.div`
    height: 100%;
    width: 100%;
    min-height: 440px;
    max-height: 440px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const OnOppositeSides = styled.div`
    position: relative;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;

export const AdminWrapperEdit = styled(AdminWrapper)`
    ${ButtonColored}, ${ButtonStyled} {
        width: 100%;
    }
    p {
        display: flex;
        margin-left: -5px;
        margin-right: -5px;
        > div,
        > button,
        > a {
            flex: 1 1 auto;
            margin: 5px;
            vertical-align: bottom;
            width: 100%;
            ${media.sm`
                width: 50%;
            `}
        }
        ${StyledUniversalField} {
            ${textInputs()} {
                width: 100%;
            }
        }
        ${SelectSC} {
            width: 100%;
        }
    }
`;

export const WrapperContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;

    ${AdminWrapperEdit} {
        margin-left: 5px;
        margin-right: 5px;
        width: 100%;
    }

    ${media.sm`
        flex-direction: row;
        ${AdminWrapperEdit} {
            margin-left: 5px;
            margin-right: 5px;
            width: calc(${100 / 2}% - 10px);
        }
    `}

    ${media.lg`
        flex-direction: row;
        ${AdminWrapperEdit} {
            margin-left: 5px;
            margin-right: 5px;
            width: calc(${100 / 3}% - 10px);
        }
    `}
`;

const GeneralFormGroup = css`
    display: flex;
    flex-direction: column;
    margin-bottom: 14px;
    position: relative;

    ${media.smMax`
        width: 100%;
        ${rtl`margin-right: 0!important;`};
    `}
`;

// нужен чтобы добавить иконку к полю , например  Группирует лейбл (иногда передается в импут)
// с импутом и иногда с кнопкой к импуту но тогда это (кнопка с импутом) заварачивается в FormGroupInputWrapper
// группирует импут с вспомогатльными элементами (лейбл,иконка)
export const FormGroup = styled.div`
    ${GeneralFormGroup}

    > ${ButtonStyled},
        > ${ColoredNavLink},
        > ${StyledNavLink},
        > ${DefaultColoredLinkButton} {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
`;

export const FormGroupCheckbox = styled.div`
    ${GeneralFormGroup}
`;

export const FormGroupButton = styled.div<IFormGroupButton>`
    display: flex;
    flex-direction: column;

    ${props =>
        props.hasExtraMargin &&
        css`
            margin-top: 40px;
        `}

    ${media.smMax`
        width: 100%;
    `}
`;

const marginDirection = css<{ direction?: "right" | "left" }>`
    ${props =>
        props.direction === "right"
            ? css`
                  ${rtl`margin-left: 10px;`};
              `
            : css`
                  ${rtl`margin-right: 10px;`};
              `}

    ${media.smMax`
        margin-left: 0;
        margin-right: 0;
    `}

    :last-child {
        ${props =>
            props.direction === "right"
                ? css`
                      ${rtl`margin-left: 0;`};
                  `
                : css`
                      ${rtl`margin-right: 0;`};
                  `}
    }
`;

export const FormGroupLabel = styled.label`
    margin-bottom: 5px;
`;

// объединяет что-то с импутом, например кнопку (такую как copy)
export const FormGroupInputWrapper = styled.div`
    display: flex;
    width: 100%;

    ${StyledUniversalField} {
        flex: 1 1 auto;
    }
`;

interface ITitle {
    color?: string;
}

export const Title = styled.h2<ITitle>`
    ${BaseTitle};
    padding: 0 0 30px;
    color: ${props =>
        props.color ? props.color : darken(0.1, props.theme.landingTextColor)};
    text-align: center;
    span + span {
        color: ${props => props.theme.accentColor};
    }
`;

// для группировки компонентов по вертикали
export const FormDirectionColumn = styled.div`
    display: flex;
    flex-direction: column;
    ${({ justify }: { justify?: string }) =>
        justify &&
        css`
            justify-content: ${justify};
        `}
`;

interface IFormDirectionRowProps {
    direction?: "right" | "left";
    stretch?: boolean;
}

// контенер для элеметов, которые идут друг за другом слева на плаво (или наоборот)
export const FormDirectionRow = styled.div<IFormDirectionRowProps>`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    ${props =>
        props.direction === "left"
            ? css`
                  justify-content: start;
              `
            : css`
                  justify-content: end;
              `}
    ${FormGroup} {
        ${marginDirection};
        ${props =>
            props.stretch
                ? css`
                      flex-grow: 1;
                  `
                : css`
                      flex-grow: 0;
                  `}
        justify-content: center;
    }

    ${FormGroupCheckbox} {
        ${marginDirection};
    }

    ${FormGroupButton} {
        justify-content: flex-end;
        margin-bottom: 20px;
        ${marginDirection};
    }

    ${TitleTypogoraphy}, ${Paragraph} {
        ${marginDirection};
        align-self: center;
    }

    & .range .DateRangePicker {
        max-width: 18.75em;
    }

    ${media.mdMax`
        ${SelectSC}, 
         ${StyledUniversalField} input,
         & .range .DateRangePicker {
            max-width: 100% !important;
        }
      
    `}
`;

FormDirectionRow.defaultProps = {
    direction: "left",
    stretch: false,
};

// контейнер не для элментов, а для котйнеров элметов
export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    ${({ align }: { align?: string }) =>
        align &&
        css`
            justify-content: ${align};
        `}

    ${FormGroup} {
        ${marginDirection};
        justify-content: center;
    }

    ${FormGroupButton} {
        justify-content: flex-end;
        margin-bottom: 20px;
        ${marginDirection};
    }
`;

// export const RowTwoColumnsParagraph = styled(Paragraph)`
// нужен чтобы на десктопе элементы располагались в одну строку, а на мобильном в колонку
export interface IRowTwoColumnsParagraph {
    formGroupsMaxWidth?: string;
}

export const RowTwoColumnsParagraph = styled.div<IRowTwoColumnsParagraph>`
    ${FormGroup} {
        flex-grow: 1;
    }
    ${props => css`
        ${media.sm`
                display: flex;
                justify-content: space-around;
                 ${FormGroup} {
                     max-width: ${
                         !props.formGroupsMaxWidth
                             ? "49%"
                             : props.formGroupsMaxWidth
                     }; // Такая ширина блока позволяет задать отспут между интупами, которые располагаются в одну строку
                }
            `}
    `}
`;
