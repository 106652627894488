import { ButtonTab } from "components/tabs/styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    counter?: JSX.Element;
    title?: string;
    onClick?: () => void;
    active?: boolean;
    value?: number;
}

export const TabSwitcher = React.memo((props: IProps) => {
    return (
        <ButtonTab active={props.active} onClick={props.onClick}>
            {props.title} {props.counter && props.counter}
        </ButtonTab>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
