import {
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
    GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans,
} from "gql/types/operation-result-types";

export function onCompareSumms(
    currentAmount: number,
    paymentPlans:
        | GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans[]
        // eslint-disable-next-line max-len
        | GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans[]
        | undefined
        | null,
    contractSum: number,
    editablePlanAmount = 0,
) {
    const summPlansSumm =
        (paymentPlans || []).reduce((summ, plan) => {
            summ += Number(plan.amount);
            return summ;
        }, 0) +
        currentAmount -
        editablePlanAmount;
    return Number(summPlansSumm) > Number(contractSum);
}
