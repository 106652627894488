import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { useTranslation } from "react-i18next";
import { CancelButton } from "../../../buttons";

interface ToFinplanProps {
    onCancel: () => void;
}

export const ToFinplan = ({ onCancel }: ToFinplanProps) => {
    const [__] = useTranslation();

    return (
        <FormDirectionRow direction="right" stretch>
            <FormGroupButton>
                <CancelButton onClick={onCancel}>
                    {__("К фин плану")}
                </CancelButton>
            </FormGroupButton>
        </FormDirectionRow>
    );
};
