import {
    GetCountries,
    GetCountries_countries,
} from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";
import { useMemo } from "react";

const GET_COUNTRIES = gql`
    query GetCountries {
        countries {
            id
            name
            code
            phoneCode
        }
    }
`;

export const useCountries = () => {
    const countryList = useQuery<GetCountries>(GET_COUNTRIES, {
        ssr: true,
    });

    const countries = g(countryList, "data", "countries");

    const countryCodes = useMemo(
        () =>
            (countries || []).reduce(
                (map, current) => map.set(current.id, current.code),
                new Map<
                    GetCountries_countries["id"],
                    GetCountries_countries["code"]
                >(),
            ),
        [countries],
    );

    return {
        countries,
        loading: countryList.loading,
        error: countryList.error,
        countryCodes,
    };
};
