import { UserCircle } from "@styled-icons/fa-solid/UserCircle/UserCircle";
import { UserConsumer } from "components/user-consumer";
import { useOnClickOutside } from "hooks/use-on-click-outside";
import { useRef, useState } from "react";
import { ProfileMenuOptions } from "./profile-menu-options";
import { ProfileMenuSC, UserInfo } from "./styled";

interface IInjectedProps {}
interface IProps extends IInjectedProps {}

export const ProfileMenu = React.memo((props: IProps) => {
    const refProfileMenu = useRef(null);
    const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
    const switchIsUserMenuVisible = () =>
        setIsUserMenuVisible(!isUserMenuVisible);

    useOnClickOutside(refProfileMenu, () => setIsUserMenuVisible(false));

    return (
        <ProfileMenuSC
            ref={refProfileMenu}
            opened={isUserMenuVisible}
            onClick={switchIsUserMenuVisible}
        >
            <ProfileMenuOptions />

            <UserConsumer>
                {user => (
                    <UserInfo>
                        <UserCircle size={20} />
                        <div>
                            <span>
                                Aff ID:
                                {user && `${user.affiliateId}`}
                            </span>
                        </div>
                    </UserInfo>
                )}
            </UserConsumer>
        </ProfileMenuSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
