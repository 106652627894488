import React from "react";
import { MenuGroup } from "components/menu/main-menu/styled";
import { useTranslation } from "react-i18next";
import { useMenuGroups, IMenuGroup } from "./hooks/use-menu-groups";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { MenuGroupTitleWrapper } from "../components/drop-menu-title";
import { useUser } from "hooks/query/use-user";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const AgentMenuItems = React.memo((props: IProps) => {
    const [__] = useTranslation();

    const menuGroups = useMenuGroups(__);
    const { user } = useUser();

    const renderMenuGroups = (groups: IMenuGroup[]) => {
        return (
            <>
                {groups.map((group, index) => {
                    if (group.isHidden) {
                        return null;
                    }

                    return (
                        <React.Fragment key={`${group.groupTitle}_${index}`}>
                            <MenuGroupTitleWrapper>
                                <group.Icon size={15} />
                                <span>{group.groupTitle}</span>
                            </MenuGroupTitleWrapper>
                            <MenuGroup>
                                {group.links.map((link, linkIndex) => (
                                    <li key={`${link.title}_${linkIndex}`}>
                                        <LocalizedNavLinkWithOptionalBackground
                                            exact
                                            to={link.to}
                                        >
                                            <link.Icon size={15} />
                                            <span>{link.title}</span>
                                        </LocalizedNavLinkWithOptionalBackground>
                                    </li>
                                ))}
                            </MenuGroup>
                        </React.Fragment>
                    );
                })}
            </>
        );
    };

    // TODO данное условие с user?.id (аккаунт менеджера для тестирования )
    // дает возможность отделу тестирования видеть на проде все пункты меню.
    // как только все пункты будут доступны всему прод окружению, это условие нужно убрать
    if (
        (__ENVIRONMENT__.production &&
            user?.id === "76cb7535-046f-4c2c-a9ad-75fa85768ede") ||
        !__ENVIRONMENT__.production
    ) {
        return renderMenuGroups(menuGroups.develop);
    } else {
        return renderMenuGroups(menuGroups.production);
    }
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
