import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "components/formik";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

interface IInjectedProps {}

interface IProps extends IInjectedProps, ISelectFild {}

export const MockPeriodField = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { ...rest } = props;
    const [options] = useState(() => [
        { value: "1", label: __("7 дней") },
        { value: "2", label: __("1 месяц") },
        { value: "4", label: __("6 месяцев") },
    ]);
    useIsSelectOptionsLoading(props.name, false, options, props.loadingContext);
    return (
        <Field
            label={props.label || ""}
            type="react-select"
            options={options}
            selectFirst={true}
            isClearable={false}
            minWidth={170}
            {...rest}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
