import { useTranslation } from "react-i18next";
import { ErrorText } from "./styled";

interface INoData {
    textColor?: string;
}

export const NoDataText = () => {
    const [__] = useTranslation();
    return <span>{__("Нет информации")}</span>;
};
export const NoData = (props: INoData) => {
    return (
        <ErrorText style={{ color: props?.textColor }}>
            <NoDataText />
        </ErrorText>
    );
};

export { ErrorText as LoadingText };
