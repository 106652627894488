import { css } from "styled-components";

const fontsPath = "../assets/fonts/golos-text/";

export const GolosTextFonts = css`
    @font-face {
        font-family: "Golos Text";
        src: url("${fontsPath}GolosText-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Golos Text";
        src: url("${fontsPath}GolosText-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Golos Text";
        src: url("${fontsPath}GolosText-SemiBold.ttf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Golos Text";
        src: url("${fontsPath}GolosText-Bold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Golos Text";
        src: url("${fontsPath}GolosText-ExtraBold.ttf") format("truetype");
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: "Golos Text";
        src: url("${fontsPath}GolosText-Black.ttf") format("truetype");
        font-weight: 900;
        font-style: normal;
    }
`;
