export const makeGetTrProps =
    (selectedIds: Array<number | string>) => (_: any, rowInfo: any) => ({
        style: {
            display: rowInfo ? "flex" : "none",
            ...(selectedIds.includes(rowInfo?.original?.id) && {
                background: "lightgrey",
                opacity: "0.4",
                "pointer-events": "none",
            }),
        },
    });
