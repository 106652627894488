import { css } from "styled-components";
const fontsPath = "../assets/fonts/roboto-flex/";

export const RobotoFlexFonts = css`
    @font-face {
        font-family: "Roboto Flex";
        font-display: swap;
        src: url("${fontsPath}RobotoFlex-Regular.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}RobotoFlex-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}RobotoFlex-Regular.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}RobotoFlex-Regular.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto";
        font-display: swap;
        src: url("${fontsPath}RobotoFlex-Regular.ttf") format("truetype");
        font-weight: 900;
        font-style: normal;
    }
`;
