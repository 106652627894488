import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";

export const useCommissionGroupsColumns = () => {
    const [__] = useTranslation();
    const columns: IReactTableColumn[] = [
        {
            Header: __("ID группы"),
            accessor: "id",
            disabled: true,
            minWidth: 80,
        },
        {
            Header: __("Валюта"),
            accessor: "currency",
            minWidth: 80,
        },
        {
            Header: __("Структура комиссий"),
            accessor: "description",
            minWidth: 120,
        },
        {
            Header: __("Название"),
            accessor: "name",
            disabled: true,
            minWidth: 180,
        },
        {
            Header: __("Участники"),
            accessor: "countOfPartners",
            minWidth: 80,
        },
        {
            Header: __("Статус"),
            accessor: "status",
            minWidth: 80,
        },
    ];

    return columns;
};
