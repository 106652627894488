import { useTranslation } from "react-i18next";
import { RouteComponentProps, Switch } from "react-router-dom";
import urljoin from "url-join";
import { PageRoute } from "components/check-roles/guard";
import { PartnerList } from "./partner-list";
import { PartnersGroups } from "./partners-groups";
import { CommissionGroups } from "./commission-groups";
import { AddPartner } from "./add-partner";
import { EditPartner } from "views/manager/main/partner-list/edit-partner";
import { PartnerListType } from "gql/types/operation-result-types";
import { Postbacks } from "./postbacks";

const MemoizedPartnerList = React.memo(() => (
    <PartnerList partnerListType={PartnerListType.PartnerList} />
));

interface IProps extends RouteComponentProps<{ url: string }> {}

export const Main = (props: IProps) => {
    const { url } = props.match;
    const [__] = useTranslation();

    return (
        <Switch>
            <PageRoute
                path={urljoin(url, "postbacks")}
                component={Postbacks}
                pageTitle={"Postbacks"}
                exact={false}
            />
            <PageRoute
                path={urljoin(url, "partner-list")}
                component={MemoizedPartnerList}
                pageTitle={__("Список партнеров")}
            />
            <PageRoute
                path={urljoin(url, "partner-list/add")}
                component={AddPartner}
                pageTitle={__("Список партнеров")}
                additionalPageTitle={__("Добавить партнера")}
            />
            <PageRoute
                path={urljoin(url, "partner-list/edit-partner/:id")}
                component={EditPartner}
                additionalPageTitle={__("Редактировать партнера")}
            />
            <PageRoute
                exact={false}
                path={urljoin(url, "partners-groups")}
                component={PartnersGroups}
                pageTitle={__("Партнерские группы")}
            />
            <PageRoute
                path={urljoin(url, "commission-groups")}
                component={CommissionGroups}
                pageTitle={__("Комиссионные группы")}
            />
        </Switch>
    );
};
