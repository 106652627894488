import { handleActions } from "redux-actions";

export interface ISettings {
    geoCountryCode: string | string[] | undefined;
}

export const settingsInititalState: ISettings = {
    geoCountryCode: undefined,
};

export const settingsReducer = handleActions({}, settingsInititalState);
