import { useTranslation } from "react-i18next";
import { Field } from "components/formik";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { PasswordReactTooltip } from "components/components-common/react-tooltip";

export function PasswordField() {
    const [__] = useTranslation();
    const {
        stylesTheme: { accentColor, textColor },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    return (
        <>
            <Field
                name="password"
                type="password"
                label={`${__("Пароль")} *`}
                data-for="passwordTooltip"
                data-tip
                autoComplete="new-password"
            />
            <PasswordReactTooltip
                backgroundColor={accentColor}
                textColor={textColor}
            />
        </>
    );
}
