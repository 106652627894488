import { QueryResult, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
    Campaigns as TCampaigns,
    CampaignsVariables,
    CampaignsFilter,
    UpdateCampaignStatus,
    UpdateCampaignStatusVariables,
    CampaignStatus,
} from "gql/types/operation-result-types";
import { ReactTable } from "components/react-table";
import { Field } from "components/formik";
import { AdminWrapper } from "styled/layouts";
import GET_CAMPAIGNS from "../gql/get-campaigns.gql";
import UPDATE_CAMPAIGN_STATUS from "../gql/update-campaign-status.gql";
import { getStatusOptions, updateStatus } from "../helpers";
import { useColumns } from "./columns";

interface IProps {
    campaignsQueryResult: QueryResult<TCampaigns, CampaignsVariables>;
    filter: CampaignsFilter;
    resultHash: string;
}

export const CampaignsTable = ({
    campaignsQueryResult,
    filter,
    resultHash,
}: IProps) => {
    const [__] = useTranslation();
    const colums = useColumns();
    const [mutation] = useMutation<
        UpdateCampaignStatus,
        UpdateCampaignStatusVariables
    >(UPDATE_CAMPAIGN_STATUS, {
        refetchQueries: [
            {
                query: GET_CAMPAIGNS,
                variables: filter,
            },
        ],
    });
    const { data, loading } = campaignsQueryResult;
    const campaigns = data?.authorized.manager.campaigns.campaigns;

    return (
        <AdminWrapper>
            <ReactTable
                columns={colums}
                data={campaigns}
                loading={loading}
                rowsCheck={{
                    resultHash,
                }}
                form={{
                    initialStatus: null as CampaignStatus | null,
                    onSubmit: async (checked, status) =>
                        await updateStatus(
                            checked as Set<number>,
                            status,
                            mutation,
                            __,
                        ),

                    statusField: (
                        <Field
                            name="status"
                            type="react-select"
                            options={getStatusOptions(__)}
                            minWidth={200}
                            placeholder={__("Выбрать")}
                            label={__("Статус")}
                        />
                    ),
                }}
            />
        </AdminWrapper>
    );
};
