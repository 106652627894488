import { IOutputValues } from "components/formik/on-submit-wrapper";
import { createWebsiteRule } from "services/validators";
import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";

export const postbackBaselineType = "3";

export class FormHelper {
    public static postbackBaselineType = 3;
    public getInitialState() {
        const state: IFormState = {
            id: null,
            statusId: null,
            site: null,
            campaign: null,
            typeRequest: null,
            postbackType: null,
            variableNameInPartnerSystem: "",
            minSum: null,
            minSumBet: null,
            url: "",
            staticParameters: null,
            isClickIdPostbackParameter: false,
            clickIdPostbackParameter: "click_id",
            isPartnerIdPostbackParameter: false,
            partnerIdPostbackParameter: "partner_id",
            isTransactionPostbackParameter: false,
            transactionPostbackParameter: "transaction",
            isSiteIdPostbackParameter: false,
            siteIdPostbackParameter: "site_id",
            isOtherPostbackParameter: false,
            otherPostbackParameter: "other",
            isSumdepPostbackParameter: false,
            sumdepPostbackParameter: "sumdep",
            /**
             * внутренний id дочернего постбека (уникальный для партнера)
             */
            childId: null,
            LFT: "",
            LFP: "",
            comment: "",
        };
        return state;
    }
}

export function useValidationSchema() {
    const {
        FIELD_SHOULD_BE_FILLED,
        INVALID_FORMAT,
        IVALID_PARAMETERS_STRING,
        INVALID_NUMBER,
        INVALID_MIN_DEPOSIT_SUM,
    } = useTranslationList();

    const doubleDigitPositiveNumberRule = (
        value: string | null,
        subValue: string | null,
    ) => {
        return yup
            .string()
            .nullable()
            .required(FIELD_SHOULD_BE_FILLED)
            .matches(/(^\d+(?:[.,](?:\d\d))?$)|(^$)/, INVALID_NUMBER)
            .test({
                test() {
                    if (
                        value &&
                        parseFloat(value) <= 0 &&
                        subValue &&
                        parseFloat(subValue) <= 0
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                },
                message: INVALID_MIN_DEPOSIT_SUM,
            });
    };
    return (values?: IFormState) => {
        const schemaObject = {
            id: yup.number().integer().nullable().defined(),
            statusId: yup.number().integer().nullable(true).defined(),
            site: yup
                .object({ id: yup.number().required(FIELD_SHOULD_BE_FILLED) })
                .nullable()
                .required(FIELD_SHOULD_BE_FILLED),
            campaign: yup
                .object({
                    id: yup.string().required(FIELD_SHOULD_BE_FILLED),
                })
                .nullable()
                .required(FIELD_SHOULD_BE_FILLED),
            typeRequest: yup
                .number()
                .nullable()
                .required(FIELD_SHOULD_BE_FILLED),
            postbackType: yup
                .number()
                .nullable()
                .required(FIELD_SHOULD_BE_FILLED),
            variableNameInPartnerSystem:
                values && values.postbackType === 4
                    ? yup.string().required(FIELD_SHOULD_BE_FILLED)
                    : yup.string().defined(),
            minSum:
                values &&
                values.postbackType === FormHelper.postbackBaselineType
                    ? doubleDigitPositiveNumberRule(
                          values.minSum,
                          values.minSumBet,
                      )
                    : yup.string().nullable(true).defined(),
            minSumBet:
                values &&
                values.postbackType === FormHelper.postbackBaselineType
                    ? doubleDigitPositiveNumberRule(
                          values.minSumBet,
                          values.minSum,
                      )
                    : yup.string().nullable(true).defined(),
            url: createWebsiteRule(INVALID_FORMAT).required(
                FIELD_SHOULD_BE_FILLED,
            ),
            staticParameters: yup
                .string()
                .nullable(true)
                .matches(
                    /^([a-zA-Z(-_)0-9]+=[\w-]+&)*?([a-zA-Z(-_)0-9]+=[\w-]+)$/,
                    IVALID_PARAMETERS_STRING,
                )
                .defined(),
            isClickIdPostbackParameter: yup
                .boolean()
                .required(FIELD_SHOULD_BE_FILLED),
            clickIdPostbackParameter: yup
                .string()
                .typeError(INVALID_FORMAT)
                .required(FIELD_SHOULD_BE_FILLED),
            isPartnerIdPostbackParameter: yup.boolean().defined(),
            partnerIdPostbackParameter: yup
                .string()
                .typeError(INVALID_FORMAT)
                .required(FIELD_SHOULD_BE_FILLED),
            isTransactionPostbackParameter: yup.boolean().defined(),
            transactionPostbackParameter: yup
                .string()
                .typeError(INVALID_FORMAT)
                .required(FIELD_SHOULD_BE_FILLED),
            isSiteIdPostbackParameter: yup.boolean().defined(),
            siteIdPostbackParameter: yup
                .string()
                .typeError(INVALID_FORMAT)
                .required(FIELD_SHOULD_BE_FILLED),
            isOtherPostbackParameter: yup.boolean().defined(),
            otherPostbackParameter: yup
                .string()
                .typeError(INVALID_FORMAT)
                .required(FIELD_SHOULD_BE_FILLED),
            isSumdepPostbackParameter: yup.boolean().defined(),
            sumdepPostbackParameter: yup
                .string()
                .typeError(INVALID_FORMAT)
                .required(FIELD_SHOULD_BE_FILLED),

            childId: yup.number().integer().nullable(true),
        };
        const schema = yup.object(schemaObject);
        // export type

        return schema;
    };
}

export interface IFormState {
    id: number | null;
    statusId: number | null;
    site: { id: number } | null;
    campaign: { id: string } | null;
    typeRequest: number | null;
    postbackType: number | null;
    variableNameInPartnerSystem: string;
    minSum: string | null;
    minSumBet: string | null;
    url: string;
    staticParameters: string | null;
    isClickIdPostbackParameter: boolean;
    clickIdPostbackParameter: string | null;
    isPartnerIdPostbackParameter: boolean;
    partnerIdPostbackParameter: string | null;
    isTransactionPostbackParameter: boolean;
    transactionPostbackParameter: string | null;
    isSiteIdPostbackParameter: boolean;
    siteIdPostbackParameter: string | null;
    isOtherPostbackParameter: boolean;
    otherPostbackParameter: string | null;
    isSumdepPostbackParameter: boolean;
    sumdepPostbackParameter: string | null;
    /**
     * внутренний id дочернего постбека (уникальный для партнера)
     */
    childId: number | null;
    LFT: string;
    LFP: string;
    comment?: string;
}

export type IPostbackOutputValues = IOutputValues<
    IFormState,
    ReturnType<typeof useValidationSchema>
>;

export const transformFromFormikToRequest = (data: IPostbackOutputValues) => {
    // site, campaign, нужно исключить т.к. в грфююл их нет и происходит ошибка
    const { id, site, campaign, LFT, LFP, statusId, ...rest } = {
        ...data,
        typeRequest: data.typeRequest,
        siteId: data.site.id,
        campaignId: parseInt(data.campaign.id),
        minSum:
            data.postbackType === Number(postbackBaselineType)
                ? data.minSum
                : "",
        minSumBet:
            data.postbackType === Number(postbackBaselineType)
                ? data.minSumBet
                : "",
    };
    return rest;
};

export const getFormData = new FormHelper();
