import { useSelector } from "react-redux";
import { settingsSelectors } from "services/redux/root/settings/selectors";

export const useForbiddenCountries = (
    forbiddenCountries: string[],
): boolean => {
    const { geoCountryCode } = useSelector(state =>
        settingsSelectors.getSettings(state),
    );

    return !!(
        geoCountryCode &&
        forbiddenCountries.includes(
            Array.isArray(geoCountryCode) ? geoCountryCode[0] : geoCountryCode,
        )
    );
};
