import { LoadingText } from "components/react-table/loading-text";
import { ReportReactTable } from "components/react-table/report-react-table";
import { usePartnerLinks } from "../hooks/use-partner-links";
import React from "react";
import { useColumns } from "../hooks/use-columns";
import { PARTNER_LINKS_TABLE_NAME } from "../filter";

export const AvailableTab = () => {
    const rest = usePartnerLinks(false);
    const columns = useColumns();

    return (
        <ReportReactTable
            {...rest}
            columns={columns}
            tableName={PARTNER_LINKS_TABLE_NAME}
            defaultSorted={[
                {
                    id: "linkId",
                    desc: false,
                },
            ]}
            loadingText={<LoadingText fastLoading />}
        />
    );
};
