import { useDefinedContext } from "hooks/use-context-exist";
import { ReactNode } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { PartnersProgramGeneralInformationContext } from "views";
import { useTranslation } from "react-i18next";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    children: ReactNode;
}

// поддерживает название темы, может сохранять выбранную тему в куки
// в стайлед компонент мы получается должны писать условия в зависимоти от назавания
// темы то или другое свойство
// не совсем подходит для нашего случая, т.к. во первых нашему пользователю не надо
// сохранять тему в куки, во вторых если мы по названию темы будем ориентироваться, то
// то будет куча условий. Юзаю его т.к. тут еще GlobalStyles . Хотя вроде как то это можно было
// кастомизировать, но я не понял как.
export const ThemeProvider = React.memo((props: IProps) => {
    const generalInfo = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [, i18n] = useTranslation();
    return (
        <StyledThemeProvider
            theme={{
                ...generalInfo.stylesTheme,
                buttons: generalInfo.buttons,
                dir: i18n.dir(),
                partnersProgramId: generalInfo.partnersProgramId,
            }}
        >
            {props.children}
        </StyledThemeProvider>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
