import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";

export const useValidationSchema = () => {
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();

    const currency: yup.Schema<GetCurrencies_authorized_partnerAndManager_data_currencies> =
        yup
            .object({
                __typename: yup.string(),
                id: yup.string(),
                name: yup.string(),
            })
            .nullable()
            .default(null)
            .required(FIELD_SHOULD_BE_FILLED);

    return yup.object({
        name: yup.string().required(FIELD_SHOULD_BE_FILLED),
        currency,
    });
};
