import { useTranslation } from "react-i18next";
import { getCampaignStatus } from "services/project-utils";
import { Field } from "components/formik";
import { CampaignStatus } from "gql/types/operation-result-types";
import { ISelectFild } from "../select-filed-interface";

export const CampaignStatusField = (props: ISelectFild) => {
    const [__] = useTranslation();

    return (
        <Field
            label={__("Статус")}
            type="react-select"
            placeholder=""
            options={[
                {
                    label: getCampaignStatus(__, CampaignStatus.INACTIVE),
                    value: CampaignStatus.INACTIVE,
                },
                {
                    label: getCampaignStatus(__, CampaignStatus.ACTIVE),
                    value: CampaignStatus.ACTIVE,
                },
            ]}
            minWidth={200}
            {...props}
        />
    );
};
