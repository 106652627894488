import * as yup from "yup";
import { QueryResult } from "@apollo/client";
import { UniversalForm } from "components/filters";
import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { PaymentFilesTable } from "./report-table";
import { Field } from "components/formik";
import { useObjectValidations } from "validation/validaion";
import {
    IBaseFieldProps,
    defaultInputFieldProps,
} from "components/filters/services/get-filter-fields";
import { useParams } from "react-router-dom";
import {
    Status,
    UpdatePaymentFile,
    UpdatePaymentFileVariables,
    PaymentFiles,
    PaymentFilesVariables,
} from "gql/types/operation-result-types";
import { useTranslationList } from "hooks/use-translations-list";
import UPDATE_PAYMNET_FILE from "../gql/update-payment-file.gql";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { ITablePagination } from "hooks/use-pagination";

interface IPartnerPaymentFiles {
    queryResult: QueryResult<PaymentFiles, PaymentFilesVariables>;
    externalPaginationAndSorting: ITablePagination;
    refetchFiles: () => void;
}

export const PartnerPaymentFiles = ({
    queryResult,
    externalPaginationAndSorting,
    refetchFiles,
}: IPartnerPaymentFiles) => {
    const [__] = useTranslation();
    const { nullableInterger } = useObjectValidations();
    const { INVALID_FORMAT } = useTranslationList();
    const { id } = useParams<{ id: string }>();

    const statusOptions = [
        { value: Status.ACTIVE, label: __("Активный") },
        { value: Status.INACTIVE, label: __("Не активный") },
    ];

    const [update] = useMutation<UpdatePaymentFile, UpdatePaymentFileVariables>(
        UPDATE_PAYMNET_FILE,
    );

    return (
        <>
            <Title>{__("Платежные файлы партнера")}</Title>
            <UniversalForm
                fields={[]}
                additionalFields={{
                    partnerId: {
                        initialValue: Number(id),
                        component: (props: IBaseFieldProps) => (
                            <Field
                                {...defaultInputFieldProps(props)}
                                disabled
                                label={__("ID")}
                            />
                        ),
                        validationSchema: nullableInterger,
                    },
                    fileName: {
                        initialValue: "",
                        component: (props: IBaseFieldProps) => (
                            <Field
                                {...defaultInputFieldProps(props)}
                                label={__("Имя файла")}
                            />
                        ),
                        validationSchema: yup
                            .string()
                            .matches(
                                /^[a-zA-ZА-Яа-я0-9\-_]{0,30}$/,
                                INVALID_FORMAT,
                            ),
                    },
                    status: {
                        initialValue: "",
                        component: (props: IBaseFieldProps) => (
                            <Field
                                {...defaultInputFieldProps(props)}
                                type="react-select"
                                options={statusOptions}
                                label={__("Статус")}
                            />
                        ),
                    },
                    checkedFileId: {
                        initialValue: "",
                        component: () => <></>,
                    },
                    checkedFileName: {
                        initialValue: "",
                        component: () => <></>,
                    },
                }}
                submitButtonName={__("Применить")}
                onSubmit={async values => {
                    const newFileName =
                        values.fileName || values.checkedFileName;

                    if (!values.checkedFileId) {
                        toast.error(__("Файл не выбран"));
                        return;
                    }

                    if (!values.status) {
                        toast.error(__("Статус не выбран"));
                        return;
                    }

                    if (!newFileName) {
                        toast.error(__("Не указано имя файла"));
                        return;
                    }

                    void update({
                        variables: {
                            partnerId: Number(id),
                            fileId: Number(values.checkedFileId),
                            data: {
                                name: newFileName,
                                status: values.status === Status.ACTIVE ? 1 : 0,
                            },
                        },
                    });

                    toast.success(__("Файл успешно изменен"));
                    refetchFiles();
                }}
            >
                {({ form, submittedValues, actualValues }) => {
                    return (
                        <>
                            {form}
                            <FirstLoadTable
                                actualValues={actualValues}
                                submittedValues={submittedValues}
                            >
                                {() => (
                                    <PaymentFilesTable
                                        queryResult={queryResult}
                                        externalPaginationAndSorting={
                                            externalPaginationAndSorting
                                        }
                                    />
                                )}
                            </FirstLoadTable>
                        </>
                    );
                }}
            </UniversalForm>
        </>
    );
};
