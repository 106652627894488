import { IPropsReactTable, ReactTable } from "components/react-table";
import {
    ReportStatus,
    User_user_hiddenColumns,
} from "gql/types/operation-result-types";
import { useUser } from "hooks/query/use-user";
import { QueryResult } from "@apollo/client";
import { usePooling } from "hooks/use-pooling";
import { IReactTableColumn } from "components/react-table/column";

interface IReportReactTableProps<
    DataType,
    ReportType,
    ReportVariablesType,
    StatusType,
> extends IPropsReactTable<DataType, StatusType> {
    queryResult?: QueryResult<ReportType, ReportVariablesType>;
    reportStatus?: ReportStatus | undefined;
    reportName?: Exclude<keyof User_user_hiddenColumns, "__typename">;
}

// добавляет функциональность тотала, переодического опроса результата и скрытых колонок по правам.
// Это все специфично для отчетов
export const ReportReactTable = <
    DataType,
    ReportType,
    ReportVariablesType,
    StatusType,
>({
    reportStatus,
    queryResult,
    total,
    columns: forwardedColumns,
    data,
    reportName,
    ...rest
}: IReportReactTableProps<
    DataType,
    ReportType,
    ReportVariablesType,
    StatusType
>) => {
    usePooling<any, any>(queryResult, reportStatus, 4000);
    const { hiddenColumns: allReportsHiddenColumns } = useUser();

    const columns = React.useMemo(() => {
        if (!total) {
            return forwardedColumns;
        }
        if (data !== undefined && data.length !== 0) {
            // в функцию футер, приходят другие данные, чем в функцию Cell
            const Footer = (footerInfo: {
                data: any;
                column: IReactTableColumn;
            }) => {
                const totalCellValue = g(total, footerInfo.column.id as any);
                /*
                    Иногда АПИ возврашает даты в тоталах.
                    Дата никогда не может фигурировать в тоталах!
                    Ставим таски для АПИ на вырезание лишних данных, но это условие удалим только после
                    того как будем уверены, что всё в порядке.
                */
                if (footerInfo.column.dataType === "date") {
                    return null;
                }

                // Аналогичная проблема, но уже с айдишниками.
                if (
                    footerInfo.column.id === "siteId" ||
                    footerInfo.column.id === "mediaId" ||
                    footerInfo.column.id === "playerId" ||
                    footerInfo.column.id === "subPartnerId"
                ) {
                    return null;
                }

                if (typeof footerInfo.column.Cell === "function") {
                    return footerInfo.column.Cell(
                        {
                            value: totalCellValue,
                            column: footerInfo.column,
                            original: total,
                        },
                        footerInfo.column,
                    );
                }

                return totalCellValue;
            };

            return forwardedColumns.map(column => ({ ...column, Footer }));
        }
        return forwardedColumns;
    }, [data, forwardedColumns, total]);

    const displayedColumns = React.useMemo(() => {
        if (!(reportName && allReportsHiddenColumns)) {
            return columns;
        }

        const reportsHiddenColumns = allReportsHiddenColumns[reportName];
        if (!reportsHiddenColumns) {
            return columns;
        }

        return columns.filter(
            column => !reportsHiddenColumns.includes(column.accessor),
        );
    }, [reportName, allReportsHiddenColumns, columns]);

    return (
        <ReactTable
            columns={displayedColumns}
            data={data}
            total={total}
            loading={queryResult ? queryResult.loading : false}
            {...rest}
        />
    );
};
