import arrowBottomSmall from "assets/images/arrow-bottom-small-reverse-color.svg";
import {
    adminBorderColor3,
    backgroundColor0,
} from "services/styled-components/themes";
import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";

interface IDropdownSelectorProps {
    opened: boolean;
}

export const SelectorInfo = styled.div<IDropdownSelectorProps>`
    ${rtl`padding: 9px 60px 9px 15px;`};
    position: relative;
    background: ${adminBorderColor3};
    cursor: pointer;
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        ${rtl`right: 15px;`};
        width: 8px;
        height: 5px;
        margin-top: -2px;
        background-image: url(${arrowBottomSmall});
        transition: all 0.3s ease;
    }

    &:hover ::after {
        ${props =>
            props.opened
                ? css`
                      margin-top: -4px;
                  `
                : css`
                      margin-top: 0px;
                  `}
    }
`;

export const DropdownSelector = styled.div<IDropdownSelectorProps>`
    position: relative;
    display: inline-flex;
    ${props =>
        props.opened &&
        css`
            ${SelectorInfo}::after {
                transform: rotate(-180deg);
            }
        `}

    ul {
        z-index: 4;
        background: ${backgroundColor0};
        position: absolute;
        top: 100%;
        ${rtl`
            left: 0;
            right: auto
        `};
        min-width: 100%;
        max-width: 100%;
        padding: 15px 15px 6px 15px;
        opacity: 0;
        box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
            0 2px 10px 0 rgba(34, 36, 38, 0.15);
        max-height: 0;
        overflow: hidden;
        transition: display 0s linear 0.3s, opacity 0.3s linear,
            max-height 0s linear 0.3s;
        ${props =>
            props.opened &&
            css`
                max-height: 500px;
                overflow-y: auto;
                opacity: 1;
                transition-delay: 0s;
            `}
    }

    li {
        font-size: 0.97em;
        margin-bottom: 2px;
    }
`;
