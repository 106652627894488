import { GetCampaigns } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_COMPAIGNS = gql`
    query GetCampaigns($partnerId: Int) {
        authorized {
            partnerAndManager {
                data {
                    campaigns(partnerId: $partnerId) {
                        id
                        name
                        currency
                        domain
                        mediaId
                        directLink
                        hasApk
                        onlyMainPage
                    }
                }
            }
        }
    }
`;

export const useCampaigns = (partnerId?: number) => {
    const campaignList = useQuery<GetCampaigns>(GET_COMPAIGNS, {
        ssr: true,
        variables: {
            partnerId,
        },
    });

    const campaigns =
        campaignList.data?.authorized.partnerAndManager.data.campaigns;
    return {
        campaigns,
        loading: campaignList.loading,
        error: campaignList.error,
    };
};
