import { Reducer } from "react";

export interface ISorted {
    id: string;
    desc: boolean;
}
export interface IState {
    pageNumber?: number;
    sorted?: ISorted;
    countOnPage?: number;
}
export interface IActions {
    setPagenum: (pageNumber: number) => void;
    setSorted: (sorted: ISorted) => void;
    setCountOnPage: (count: string) => void;
}

export interface ITablePagination {
    state: IState;
    actions: IActions;
}

interface IPayload extends IState {
    type: string;
}

export const usePaginationAndSorting: () => ITablePagination = () => {
    const reducer = (curState: IState, payload: IPayload) => {
        switch (payload.type) {
            case "CHANGE_PAGE":
                return {
                    ...curState,
                    pageNumber: payload.pageNumber,
                };
            case "SORTED_DATA":
                return {
                    ...curState,
                    sorted: payload.sorted,
                };
            case "SET_COUNT_ITEMS_PAGE":
                return {
                    ...curState,
                    countOnPage: payload.countOnPage,
                };
            default:
                return curState;
        }
    };

    const [state, dispatch] = React.useReducer(
        // TODO: можно ли обойтись без as ?
        reducer as Reducer<IState, IPayload>,
        {
            sorted: undefined,
            pageNumber: 1,
            countOnPage: 10,
        },
    );

    const setPagenum = (pageNumber: number) =>
        dispatch({ type: "CHANGE_PAGE", pageNumber });

    const setSorted = (sorted: ISorted) =>
        dispatch({ type: "SORTED_DATA", sorted });

    const setCountOnPage = (count: string) =>
        dispatch({
            type: "SET_COUNT_ITEMS_PAGE",
            countOnPage: Number(count),
        });

    return {
        state,
        actions: { setPagenum, setSorted, setCountOnPage },
    };
};
