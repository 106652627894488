import { useState } from "react";
import { AdminWrapperEdit, WrapperContainer } from "styled/layouts";
import { ActivationCodes } from "./activation-codes";
import { ActivationData } from "./activation-data";

export const TwoFactorActivation = () => {
    const [pageCodes, setPageCodes] = useState<string[] | undefined>(undefined);

    return (
        <WrapperContainer>
            <AdminWrapperEdit>
                {pageCodes ? (
                    <ActivationCodes codes={pageCodes} />
                ) : (
                    <ActivationData setPageCodes={setPageCodes} />
                )}
            </AdminWrapperEdit>
        </WrapperContainer>
    );
};
