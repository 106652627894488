import { css } from "styled-components";

const fontsPath = "../assets/fonts/atyp/";

export const AtypFonts = css`
    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-Thin.eot");
        src: url("${fontsPath}AtypText-Thin.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-Thin.woff2") format("woff2"),
            url("${fontsPath}AtypText-Thin.woff") format("woff"),
            url("${fontsPath}AtypText-Thin.ttf") format("truetype");
        font-weight: 100;
        font-style: normal;
    }

    @font-face {
        font-family: "Atyp";
        src: url("AtypText-Light.eot");
        src: local("Atyp Text Light"), local("AtypText-Light"),
            url("AtypText-Light.eot?#iefix") format("embedded-opentype"),
            url("AtypText-Light.woff2") format("woff2"),
            url("AtypText-Light.woff") format("woff"),
            url("AtypText-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-Regular.eot");
        src: url("${fontsPath}AtypText-Regular.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-Regular.woff2") format("woff2"),
            url("${fontsPath}AtypText-Regular.woff") format("woff"),
            url("${fontsPath}AtypText-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-Medium.eot");
        src: url("${fontsPath}AtypText-Medium.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-Medium.woff2") format("woff2"),
            url("${fontsPath}AtypText-Medium.woff") format("woff"),
            url("${fontsPath}AtypText-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-Semibold.eot");
        src: url("${fontsPath}AtypText-Semibold.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-Semibold.woff2") format("woff2"),
            url("${fontsPath}AtypText-Semibold.woff") format("woff"),
            url("${fontsPath}AtypText-Semibold.ttf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-ThinItalic.eot");
        src: url("${fontsPath}AtypText-ThinItalic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-ThinItalic.woff2") format("woff2"),
            url("${fontsPath}AtypText-ThinItalic.woff") format("woff"),
            url("${fontsPath}AtypText-ThinItalic.ttf") format("truetype");
        font-weight: 100;
        font-style: italic;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-LightItalic.eot");
        src: url("${fontsPath}AtypText-LightItalic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-LightItalic.woff2") format("woff2"),
            url("${fontsPath}AtypText-LightItalic.woff") format("woff"),
            url("${fontsPath}AtypText-LightItalic.ttf") format("truetype");
        font-weight: 300;
        font-style: italic;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-Italic.eot");
        src: url("${fontsPath}AtypText-Italic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-Italic.woff2") format("woff2"),
            url("${fontsPath}AtypText-Italic.woff") format("woff"),
            url("${fontsPath}AtypText-Italic.ttf") format("truetype");
        font-weight: 400;
        font-style: italic;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-MediumItalic.eot");
        src: url("${fontsPath}AtypText-MediumItalic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-MediumItalic.woff2") format("woff2"),
            url("${fontsPath}AtypText-MediumItalic.woff") format("woff"),
            url("${fontsPath}AtypText-MediumItalic.ttf") format("truetype");
        font-weight: 500;
        font-style: italic;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-SemiboldItalic.eot");
        src: url("${fontsPath}AtypText-SemiboldItalic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-SemiboldItalic.woff2") format("woff2"),
            url("${fontsPath}AtypText-SemiboldItalic.woff") format("woff"),
            url("${fontsPath}AtypText-SemiboldItalic.ttf") format("truetype");
        font-weight: 600;
        font-style: italic;
    }

    @font-face {
        font-family: "Atyp";
        src: url("${fontsPath}AtypText-BoldItalic.eot");
        src: url("${fontsPath}AtypText-BoldItalic.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}AtypText-BoldItalic.woff2") format("woff2"),
            url("${fontsPath}AtypText-BoldItalic.woff") format("woff"),
            url("${fontsPath}AtypText-BoldItalic.ttf") format("truetype");
        font-weight: 700;
        font-style: italic;
    }
`;
