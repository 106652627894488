import * as _ from "lodash";
import { useEffect } from "react";
import { withApollo, WithApolloClient } from "@apollo/client/react/hoc";
import { useCookies } from "react-cookie";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose, Dispatch } from "redux";
import { Variables } from "services/variables";

interface IInjectedProps
    extends WithApolloClient<unknown>,
        RouteComponentProps {
    dispatch: Dispatch;
}

interface IProps extends IInjectedProps {}

export const ParseParams = compose(
    withApollo,
    withRouter,
)(
    React.memo((props: IProps) => {
        const [, setCookies] = useCookies([Variables.cookies.tag]);
        useEffect(() => {
            const queryString = props.location.search.substring(1);
            if (queryString) {
                const search = new URLSearchParams(queryString);
                search.forEach((value, key) => {
                    switch (_.lowerCase(key)) {
                        case "tag":
                            // don't check for existing cookies "!cookies[Variables.cookies.tag]"
                            // because when we try new registration we need new cookies
                            // this is old site's behaive and very convinient when testing registration
                            if (value) {
                                setCookies(Variables.cookies.tag, value, {
                                    expires: (() => {
                                        const date = new Date();
                                        date.setDate(date.getDate() + 7);
                                        return date;
                                    })(),
                                    path: "/",
                                });
                            }
                            break;
                    }
                });
            }
        });
        return null;
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
