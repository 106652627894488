import { AdminWrapperEdit, WrapperContainer } from "styled/layouts";
import { CommonInfo } from "../common-form-advertising/common-info";
import { PaymentInfo } from "views/manager/advertising/components/payment-info";
import { DealType } from "../common-form-advertising/deal-type";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form } from "components/formik";
import { MutationFunction, useMutation } from "@apollo/client";
import {
    AddAdvertisementPayment,
    AddAdvertisementPaymentVariables,
    AdvertisementStatus,
    GetCountries_countries,
    GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments,
    AdvertisementPaymentInput,
    ContractPlacement,
} from "gql/types/operation-result-types";
import ADD_ADVERTISEMENT_PAYMENT from "./add-payment.gql";
import { errorHandler } from "services/error-handler";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { IOutputValues } from "components/formik/on-submit-wrapper";
import { useValidationSchema } from "../common-form-advertising/validation-scheme";
import { useTranslation } from "react-i18next";
import { ErrorType } from "server/errors/error-type";
import { toast } from "react-toastify";
import { getPaymentSystem } from "../common-form-advertising/get-payment-system";
import { usePaymentSystemsWithFields } from "hooks/query/use-payment-systems-with-fields";
import { AdvertisingCompany } from "views/manager/advertising/components/company-field";
import GET_PLAN from "views/manager/advertising/fin-plan/gql/get-plan.gql";

export interface IAddPaymentData {
    planId?: number;
    paymentPlanId?: string;
    onPaymentAdded: (
        payment: { id: number } & AdvertisementPaymentInput,
    ) => void;
    initialValues: ReturnType<typeof useDefaultInitilValues>;
    advertisingCompany?: AdvertisingCompany | null;
}

export function useDefaultInitilValues(
    partnerId?: number | undefined,
    siteId?: number | null | undefined,
) {
    return {
        partnerId,
        siteId,
        currencyAdversitingPayments:
            // eslint-disable-next-line max-len
            null as GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments | null,
        amountRequest: null as number | null,
        amountRequestRUB: null as number | null,
        contractPlacements: undefined as ContractPlacement[] | undefined,
        comments: null as string | null,
        paymentSystem: null as
            | ReturnType<
                  typeof usePaymentSystemsWithFields
              >["paymentSystems"][0]
            | null,
        dealType: "Digital",
        statusAdvertisingPayments: AdvertisementStatus.NEW,
        search: "" as string | undefined,
        company: null as number | null | undefined,
        country: null as GetCountries_countries | null | undefined,
        filesContent: [{ name: "", path: "" }],
    };
}

export const AddPayment = (props: IAddPaymentData) => {
    const [__] = useTranslation();
    const [mutation] = useMutation<
        AddAdvertisementPayment,
        AddAdvertisementPaymentVariables
    >(ADD_ADVERTISEMENT_PAYMENT);

    const validationSchema = useValidationSchema();
    return (
        <FormikConsoleErrors
            enableReinitialize
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async values => {
                try {
                    const payment = await addPayment({
                        mutation,
                        values,
                        planId: props.planId,
                        paymentPlanId: props.paymentPlanId,
                    });
                    props.onPaymentAdded(payment);
                } catch (ex: any) {
                    void errorHandler(ex, error => {
                        switch (error.data.code) {
                            case ErrorType.ADV_PAYMENT_SUM_PLACEMENT_UNEQUAL:
                                toast.error(
                                    __(
                                        "Сумма к оплате должна быть равна сумме сумм по типам размещений.",
                                    ),
                                );
                                break;
                        }
                    });
                    throw ex;
                }
            }, validationSchema)}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Form>
                        <WrapperContainer>
                            <CommonInfo
                                values={values}
                                setFieldValue={setFieldValue}
                            />
                            <AdminWrapperEdit>
                                <PaymentInfo
                                    advertisingCompany={
                                        props.advertisingCompany
                                    }
                                />
                            </AdminWrapperEdit>
                            <DealType />
                        </WrapperContainer>
                    </Form>
                );
            }}
        </FormikConsoleErrors>
    );
};

export interface AddPaymentProps {
    mutation: MutationFunction<
        AddAdvertisementPayment,
        AddAdvertisementPaymentVariables
    >;
    values: IOutputValues<
        ReturnType<typeof useDefaultInitilValues>,
        typeof useValidationSchema
    >;
    planId?: number;
    paymentPlanId?: string;
}

const addPayment = async ({
    mutation,
    values,
    planId,
    paymentPlanId,
}: AddPaymentProps) => {
    const documentLinks = values.filesContent
        .filter(item => !!item.path)
        .map(item => item.path);

    const payment: AdvertisementPaymentInput = {
        partnerId: values.partnerId,
        siteId: values.siteId,
        currencyId: values.currencyAdversitingPayments.id,
        amountRequest: values.amountRequest,
        payment: getPaymentSystem(values),
        placements: values.contractPlacements,
        comments: values.comments,
        documentLinks,
    };

    const { data } = await mutation({
        variables: {
            payment,
            planId,
            paymentPlanId,
        },
        refetchQueries: [
            {
                query: GET_PLAN,
                variables: {
                    id: planId,
                },
            },
        ],
    });

    if (!data) {
        throw new Error("Adv payment probably wasn't created");
    }

    const id =
        data.authorized.manager.advertising.advertisingPayments
            .addAdvertisingPayment.id;

    return { id, ...payment };
};
