import { memo, useEffect } from "react";
import { CurrencyAdversitingPaymentsField } from "components/filters/fields/currency-adversiting-payments";
import { CountryField } from "components/filters/fields/country";
import { Period } from "components/filters/fields/period";
import { AddPlacement } from "../add-fin-plan-steps/add-contract/add-placement";
import { AdminWrapperContainer, AdminWrapper, FormGroup } from "styled/layouts";
import { Field, Form } from "components/formik";
import { useTranslation } from "react-i18next";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { useInitilaValuesAndValidation } from "./use-initilal-values-and-validation";
import { AddFileArray } from "../../components/add-file-array";
import { AdvertisingCompany } from "../../components/company-field";
import { PaymentInfo } from "views/manager/advertising/components/payment-info";

export type ContractValues = ReturnType<
    typeof useInitilaValuesAndValidation
>["initialValues"];

interface IProps {
    values: ContractValues;
    setFieldValue: (field: string, value: any) => void;
    onFormChange?: (formValues: ContractValues) => void;
    submitButtonText: string;
    company?: AdvertisingCompany;
    disabledFields?: boolean;
    disabledButton?: boolean;
    finplanId?: number;
}

export const ContractForm = memo((props: IProps) => {
    const {
        values,
        setFieldValue,
        onFormChange,
        submitButtonText,
        company,
        disabledFields,
        disabledButton,
    } = props;
    const [__] = useTranslation();

    useEffect(() => {
        onFormChange?.(values);
    }, [onFormChange, values]);

    return (
        <Form>
            <AdminWrapperContainer>
                <AdminWrapper width="32%">
                    <FormGroup>
                        <Field
                            name="contractNumber"
                            type="text"
                            label={__("№ контракта")}
                            disabled={disabledFields}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="contractName"
                            type="text"
                            label={__("Название")}
                            disabled={disabledFields}
                        />
                    </FormGroup>
                    <Period
                        name="contractDuration"
                        showPeriods={false}
                        label={__("Длительность контракта")}
                        disabled={disabledFields}
                    />
                    <FormGroup>
                        <Field
                            name="contractSumm"
                            type="text"
                            label={__("Сумма контракта")}
                            disabled={disabledFields}
                        />
                    </FormGroup>
                    <FormGroup>
                        <CurrencyAdversitingPaymentsField
                            name="currencyAdversitingPayments"
                            selectFirst
                            isClearable={false}
                            label={__("Валюта")}
                            disabled={disabledFields}
                        />
                    </FormGroup>
                    <FormGroup>
                        <CountryField
                            name="country"
                            key="country"
                            isClearable={false}
                            isDisabled={disabledFields}
                        />
                    </FormGroup>
                    <PaymentInfo
                        advertisingCompany={company}
                        options={{
                            hideFileFields: true,
                            countryFieldLabel: __("Страна платежа"),
                        }}
                        disabled={disabledFields}
                        countryFieldName="contractCountry"
                    />
                    <AddFileArray
                        name="filesContent"
                        values={values}
                        setFieldValue={setFieldValue}
                        uploadFileMethod="finplan/upload"
                        accept=".pdf, image/jpeg, image/jpg, image/png, .doc, .docx, .xls, .xlsx, .csv, .odt, .ods, .ppt, .pptx"
                        disabled={disabledFields}
                        baseDownloadPath={
                            props.finplanId
                                ? `/lapi/files/finplan-file-downloading/${props.finplanId}`
                                : undefined
                        }
                    />
                </AdminWrapper>
                <AdminWrapper width="67%">
                    <FormGroup>
                        <Field
                            name="contactFullName"
                            type="text"
                            label={__("ФИО контакта")}
                            disabled={disabledFields}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="phone"
                            type="text"
                            label={__("Телефон контакта")}
                            disabled={disabledFields}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="email"
                            type="email"
                            label="email"
                            disabled={disabledFields}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="message"
                            type="textarea"
                            label={__("Доп. информация")}
                            rows={5}
                            autoComplete="on"
                            disabled={disabledFields}
                        />
                    </FormGroup>
                    <AddPlacement
                        name="contractPlacements"
                        values={values.contractPlacements}
                        disabled={disabledFields}
                    />
                    {!disabledButton && (
                        <FormDirectionRow direction="right" stretch>
                            <FormGroupButton>
                                <SubmitButton>{submitButtonText}</SubmitButton>
                            </FormGroupButton>
                        </FormDirectionRow>
                    )}
                </AdminWrapper>
            </AdminWrapperContainer>
        </Form>
    );
});
