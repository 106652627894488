import { Field } from "components/formik";
import { useTranslation } from "react-i18next";
import { usePostbackLists } from "../../hooks/use-postback-lists";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    name: string;
    selectFirst?: boolean;
    isClearable?: boolean;
    id: number | null;
}

export const MethodField = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { data, loading } = usePostbackLists(props.id);

    return (
        <Field
            name={props.name}
            label={__("Метод запроса")}
            type="react-select"
            options={(data?.methods || []).map(
                method =>
                    ({
                        value: method.id,
                        label: method.name,
                    } as any),
            )}
            isLoading={loading}
            isDisabled={loading}
            selectFirst={props.selectFirst}
            isClearable={props.isClearable}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
