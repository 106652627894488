import { Edit } from "@styled-icons/boxicons-regular/Edit/Edit";
import { Show } from "@styled-icons/boxicons-regular/Show/Show";
import { EyeSlash } from "@styled-icons/fa-regular/EyeSlash/EyeSlash";
import { Delete } from "@styled-icons/material/Delete/Delete";
import { CopyToClipboard } from "components/copy-to-clipboard";
import styled, { css } from "styled-components";
import { IconedButton } from "styled/iconed-button";
import { Row } from "styled/layouts";
import { BtnColor } from "../../styled/button";

import { useTranslation } from "react-i18next";

interface CellWithButtonsProps {
    text: string;
    link?: boolean;
    button?: React.ReactNode;
}

export const PartnerTableState = ({
    hidden,
    toggleLink,
    loading,
}: {
    hidden: boolean;
    toggleLink?: () => void;
    loading?: boolean;
}) => {
    const [__] = useTranslation();
    return (
        <Row>
            {hidden ? (
                <IconedButton
                    text={__("Показать")}
                    color={BtnColor.Extra}
                    icon={CustomEyeSlashIcon}
                    action={toggleLink}
                    loading={loading}
                />
            ) : (
                <IconedButton
                    text={__("Скрыть")}
                    color={BtnColor.Faded}
                    icon={CustomShowIcon}
                    action={toggleLink}
                    loading={loading}
                />
            )}
        </Row>
    );
};

export const CellWithButtons = ({
    text,
    link,
    button,
}: CellWithButtonsProps) => {
    return (
        <>
            {link ? (
                <a href={text} target="_blank" rel="noopener noreferrer">
                    {text}
                </a>
            ) : (
                <span>{text}</span>
            )}
            {button ? button : <CopyToClipboard text={text} />}
        </>
    );
};

const customStyles = css`
    color: currentColor;
    width: 15px;
    margin-right: 8px;
`;

export const CustomDeleteIcon = styled(Delete)`
    ${customStyles}
`;

export const CustomEditIcon = styled(Edit)`
    ${customStyles}
`;

const CustomShowIcon = styled(Show)`
    ${customStyles}
`;

const CustomEyeSlashIcon = styled(EyeSlash)`
    ${customStyles}
`;
