import sortAltIco from "assets/images/sort-alt.svg";
import sortAscIco from "assets/images/sort-asc.svg";
import sortDescIco from "assets/images/sort-desc.svg";
import { ellipsis, position } from "polished";
import OriginalReactTable from "react-table";
import {
    adminBorderColor3,
    infoColor,
    backgroundColor0,
} from "services/styled-components/themes";
import { media } from "services/styled-components/variables";
import { pseudoElem } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";

export const StyledReactTable = styled(OriginalReactTable)<any>`
    position: relative;
    ${props =>
        (!props.data || props.data.length === 0) &&
        css`
            display: flex;
            flex-direction: column;
        `}
    .rt-table {
        position: relative;
        overflow: auto;
        margin-bottom: 5px;
        max-width: 100%;
        &::after {
            ${pseudoElem}
            ${position("absolute", "0")}
            margin: auto;
            pointer-events: none;
        }
    }
    .rt-tbody {
        min-height: ${props =>
            props.data && props.data.length ? "50px" : "100px"};
        /* background: #f9f9f9; */
        ${props =>
            (!props.data || props.data.length === 0) &&
            css`
                display: contents;
            `}
    }
    .rt-thead,
    .rt-tbody,
    .rt-tfoot {
        min-width: 100% !important;
    }

    .rt-tr {
        display: flex;
        width: 100%;
        min-width: fit-content;
        .rt-expandable {
            cursor: pointer;
            position: relative;
            min-width: auto;
            & .rt-expander {
                position: absolute;
                ${rtl`right: 50%;`};
                top: 50%;
                transform: translate(50%, -50%);
                color: transparent;
                background: ${props => props.theme.activeColor};
                margin: 0;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                transition: 0.5s;
                &:before {
                    content: "+";
                    position: absolute;
                    width: 16px;
                    ${rtl`right: 8px;`};
                    top: 7px;
                    transform: translate(50%, -50%);
                    font-size: 19px;
                    line-height: 19px;
                    color: ${backgroundColor0};
                    text-align: center;
                }
                &::selection {
                    background-color: transparent;
                    color: transparent;
                }
            }
            & .-open {
                background: #736eaf;
                &:before {
                    content: "-";
                }
            }
        }
    }
    .rt-th,
    .rt-td {
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        padding: 6px 14px;
        border-bottom: 2px solid #ffffff;
        outline: none;
        ${ellipsis()}
        white-space: pre-wrap;
        font-size: 13px;
        &.-cursor-pointer {
            cursor: pointer;
        }
        display: flex;
        & a,
        & span {
            flex: 1 1 auto;
            word-break: break-word;
            text-align: center;
            ${rtl`padding-right: 10px;`};
        }
        a.no-indent {
            ${rtl`padding-right: 0;`};
        }
    }
    .rt-th {
        position: relative;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 75px;
        color: ${props => props.theme.textColor};
        background: ${props => props.theme.backgroundColor};
        padding-left: 5px;
        word-break: break-word;
        &:not(:last-child) {
            border-right: 2px solid #ffffff;
        }
        &::after {
            content: url(${sortAltIco});
            position: absolute;
            ${rtl`right: 2px;`};
            top: 50%;
            transform: translateY(-45%) rotate(90deg);
            width: 14px;
            color: ${props => props.theme.textColor};
            fill: ${props => props.theme.textColor};
        }
        &.no-sortable {
            padding-right: 6px;
            &::after {
                content: none;
            }
        }
        .rt-resizable-header-content {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & .rt-resizer {
            width: 15px;
            height: 100%;
            background: ${props => props.theme.activeColor};
            top: 0;
            position: absolute;
            cursor: col-resize;
            ${rtl`right: 0;`};
        }

        &.-sort-asc {
            &::after {
                transform: translateY(-45%);
                content: url(${sortAscIco});
            }
        }
        &.-sort-desc {
            &::after {
                transform: translateY(-45%);
                content: url(${sortDescIco});
            }
        }

        & * {
            font-weight: bold;
        }
    }
    .th-expander {
        min-width: auto;
    }
    .rt-td {
        background: ${adminBorderColor3};
        min-width: 75px;
        word-break: break-word;
        &:not(:last-child) {
            border-right: 2px solid #ffffff;
        }
    }

    .rt-tfoot {
        & .rt-td {
            min-width: auto;
            background-color: ${props => props.theme.backgroundColor};
            color: ${props => props.theme.textColor};
        }
    }

    .pagination-bottom {
        order: 3;
        > div {
            ${media.sm`
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            `}
            > *:first-child {
                margin-top: 10px;
                font-size: 0.9em;
                color: ${infoColor};
            }
            > *:nth-child(2) {
                margin-top: 10px;
                ${rtl`
                    margin-left: auto;
                    margin-right: 15px;
                `};
            }
            > *:last-child {
                margin-top: 10px;
                /* margin-left: auto; */
            }
        }
    }

    .-loading {
        position: absolute;
        top: 50%;
        ${rtl`left: 50%;`};
        transform: translate(-50%, -50%);
        background: ${backgroundColor0};
    }

    .-loading,
    .rt-noData {
        display: none;

        &.-active {
            display: block;
        }
    }

    .rt-noData {
        display: block;
        padding: 40px;
        order: 2;
        text-align: center;
        background-color: ${adminBorderColor3};
    }

    ${media.smMax`
        .rt-thead {
        }
        .rt-tr {
        }

        .rt-th,
        .rt-td {
            padding: 8px 12px;
        }
        .rt-th {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    `}
`;
