import {
    useLanguage,
    createLanguageRedirectLocation as createLanguageRedirectRouteLocation,
} from "components/language-provider";
import { languageListByPartner } from "services/i18n/language-list";
import { useOnClickOutside } from "hooks/use-on-click-outside";
import { useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePartnersProgram } from "hooks/use-partners-program-id";

export const useLanguageSwitcher = () => {
    const { language: curLanguage, setLanguage: setCurLanguage } =
        useLanguage();
    const refLanguageSwitcher = useRef(null);
    const [listOpened, setListOpened] = useState(false);
    useOnClickOutside(refLanguageSwitcher, () => setListOpened(false));
    const routeLocation = useLocation();
    const partnersProgram = usePartnersProgram();

    // map(lg => lg) чтобы не мутировать внутреннюю коллекцию,
    // например, использованием sort, что приводит к странным ошибкам
    // улалить, с исправлением либы
    const languageList = languageListByPartner(partnersProgram?.programId)
        .getAll()
        .map(lg => lg);
    const sortedLanguageList = useMemo(() => {
        return languageList.sort((lang1, lang2) => {
            // Некоторые shortName в UpperCase. Поэтому если не выполнить toLowerCase,
            // то сортировка будет выполнена неправильно.
            const sN1 = lang1.shortName.toLowerCase();
            const sN2 = lang2.shortName.toLowerCase();
            // eslint-disable-next-line no-nested-ternary
            return sN1 < sN2 ? -1 : sN1 > sN2 ? 1 : 0;
        });
    }, [languageList]);

    return {
        sortedLanguageList,
        curLanguage,
        setCurLanguage,
        routeLocation,
        createLanguageRedirectRouteLocation,
        refLanguageSwitcher,
        listOpened,
        setListOpened,
    };
};
