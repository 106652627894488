import styled, { css } from "styled-components";
import { LocalizedLink, LocalizedNavLink } from "components/language-provider";
import { LinkButton } from "styled/internal-buttons/buttons";

interface IButtonWithGradient {
    buttonGradient?: string;
    withRadius?: boolean;
}

// TODO: не использовать, убрать export
export const linkCss = css`
    text-decoration: none;
    color: ${props => props.theme.primaryColor};
    transition: 0.3s ease 0s;

    &:hover,
    &.active {
        color: ${props => props.theme.activeColor};
    }
`;

// рекат роутер ссылка которая выглядит как ссылка
export const StyledNavLink = styled(LocalizedNavLink)`
    ${linkCss};
`;

export const GradientAndRadiusStyles = css<IButtonWithGradient>`
    border-radius: ${props => props.theme.buttons.default.borderRadius};

    ${props =>
        props.buttonGradient &&
        css`
            background-image: ${props.buttonGradient};
            border: 0;

            &:hover,
            :active {
                opacity: 0.9;
            }
        `};

    ${props =>
        props.withRadius &&
        css`
            border-radius: 36px;

            & svg {
                display: none;
            }

            &:hover,
            :active {
                box-shadow: rgba(251, 106, 106, 0.8) 0px 0px 20px;
            }
        `};
`;

// рекат роутер кнопка которая выглядит как кнопка
export const ColoredNavLink = styled(LocalizedNavLink)<IButtonWithGradient>`
    ${LinkButton};
    ${GradientAndRadiusStyles};
`;

// TODO: используется только в новостях, рассмотреть замену на StyledNavLink
export const StyledLink = styled(LocalizedLink)`
    ${linkCss};
`;

// когда нужна a html ccылка которая выглядит как кнопка
export const DefaultColoredLinkButton = styled.a<IButtonWithGradient>`
    ${LinkButton};
    ${GradientAndRadiusStyles}
`;
