import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AdversitingAction } from "views/manager/advertising/adversiting-payments/view-edit-steps";
import {
    EditOrViewAdvertisingPayment,
    PaymentPlan,
} from "../hooks/use-payment-connect-edit-view-states";
import { ToFinplan } from "./to-finplan";
import { Title } from "services/styled-components/typogoraphy";
import { ViewAndEditPayment } from "views/manager/advertising/adversiting-payments/components/view-and-edit-payment";
import { useAdvertisementPayment } from "views/manager/advertising/adversiting-payments/view-edit-steps/use-get-advertisement-payment";
import { UpdateAdvertisementPaymentParams } from "gql/types/operation-result-types";
import { useCurrencyAdversitingPayments } from "hooks/query/use-currency-adversiting-payments";

interface EditAdvertisingPaymentFromFinPlanProps {
    stateToEditOrView: EditOrViewAdvertisingPayment;
    finPlanId: number;
    setPaymentPlans: Dispatch<SetStateAction<PaymentPlan[] | undefined>>;
}

export const ViewAndEditPaymentFromFinPlan = ({
    stateToEditOrView,
    setPaymentPlans,
}: EditAdvertisingPaymentFromFinPlanProps) => {
    const [__] = useTranslation();
    const { paymentPlan, adversitingAction } = stateToEditOrView;
    if (!paymentPlan.advPaymentId) {
        throw new Error("Plan didn't have a payment associated with it");
    }
    const { data: currencyList } = useCurrencyAdversitingPayments();

    const updatePaymentPlansAfterPaymentEdit = async (
        payment: UpdateAdvertisementPaymentParams,
    ) => {
        setPaymentPlans(prevPaymentPlans =>
            (prevPaymentPlans || []).map(curPlan => {
                if (curPlan.advPaymentId === payment.id) {
                    return {
                        ...curPlan,
                        advPaymentAmount: payment.amountRequest,
                        advPaymentCurrencyName:
                            currencyList?.find(
                                cur => cur.id === payment.currencyId,
                            )?.name || "",
                        advPaymentStatus: payment.statusId,
                    };
                }
                return curPlan;
            }),
        );

        toast.success(
            format(__("Рекламная выплата {paymentId} успешно изменена"), {
                paymentId: payment.id,
            }),
        );
    };

    const title =
        adversitingAction === AdversitingAction.View
            ? __("Просмотр рекламной выплаты")
            : __("Редактирование рекламной выплаты");

    const { payment, siteId } = useAdvertisementPayment(
        paymentPlan.advPaymentId,
    );

    if (!payment) {
        return <Title>{__("Загрузка данных")}</Title>;
    }

    return (
        <>
            <Title>{title}</Title>
            <ViewAndEditPayment
                adversitingAction={adversitingAction}
                payment={payment}
                siteId={siteId}
                onEdit={async editedPayment => {
                    await updatePaymentPlansAfterPaymentEdit(editedPayment);
                }}
            />
            <ToFinplan
                onCancel={() =>
                    setPaymentPlans(prevPaymentPlans => prevPaymentPlans)
                }
            />
        </>
    );
};
