import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";
import { makeUtcCustomPeriod } from "services/project-utils";
import { AllUniversalOutputValues } from "components/filters/types";

export type SubmitValues = Pick<
    AllUniversalOutputValues,
    | "commisionGroup"
    | "partnersGroup"
    | "partnerId"
    | "period"
    | "registrationSource"
> & {
    currency:
        | GetCurrencies_authorized_partnerAndManager_data_currencies
        | undefined;
};

export const generalQueryParams = (filter: SubmitValues) => {
    const { start, end } = makeUtcCustomPeriod(
        filter.period.start,
        filter.period.end,
    );
    return {
        currencyId: parseInt(filter.currency?.id || "0"),
        commisionGroupId: filter.commisionGroup?.id,
        partnersGroupId: filter.partnersGroup?.id,
        partnerId: filter.partnerId,
        startPeriod: (start && start.toISOString()) || "",
        endPeriod: (end && end.toISOString()) || "",
        registrationSourceId: filter.registrationSource?.id,
    };
};
