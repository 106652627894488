import styled from "styled-components";
import { media } from "services/styled-components/variables";

export const Wrapper = styled.div`
    & svg {
        width: 100%;
        height: 100%;
        display: block;
    }
`;

export const ContentNoInformation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 21em;
    min-height: 9.4em;

    ${media.xsMax`
        min-width: 17em;
        min-height: 7em;
    `}
`;
