import { css } from "styled-components";

const fontsPath = "../assets/fonts/roboto-mono/";

export const RobotoMonoFonts = css`
    @font-face {
        font-family: "Roboto Mono";
        src: url("${fontsPath}RobotoMono-Light.woff2") format("woff2"),
            url("${fontsPath}RobotoMono-Light.woff") format("woff");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Mono";
        src: url("${fontsPath}RobotoMono-LightItalic.woff2") format("woff2"),
            url("${fontsPath}RobotoMono-LightItalic.woff") format("woff");
        font-weight: 300;
        font-style: italic;
    }
    @font-face {
        font-family: "Roboto Mono";
        src: url("${fontsPath}RobotoMono-Regular.woff2") format("woff2"),
            url("${fontsPath}RobotoMono-Regular.woff") format("woff");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Mono";
        src: url("${fontsPath}RobotoMono-Italic.woff2") format("woff2"),
            url("${fontsPath}RobotoMono-Italic.woff") format("woff");
        font-weight: 400;
        font-style: italic;
    }
    @font-face {
        font-family: "Roboto Mono";
        src: url("${fontsPath}RobotoMono-Bold.woff2") format("woff2"),
            url("${fontsPath}RobotoMono-Bold.woff") format("woff");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Mono";
        src: url("${fontsPath}RobotoMono-BoldItalic.woff2") format("woff2"),
            url("${fontsPath}RobotoMono-BoldItalic.woff") format("woff");
        font-weight: 700;
        font-style: italic;
    }
`;
