import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useTranslationList } from "hooks/use-translations-list";
import { useMessengersInForms } from "hooks/use-messenger-login-validation";
import { Field } from "components/formik";
import { FormGroup } from "styled/layouts";
import { IFormValues } from "views/manager/main/partner-list/edit-partner/edit-partner-tabs/profile/models";
import { FormRow } from "views/manager/main/partner-list/edit-partner/edit-partner-tabs/profile/styled";
import { Button } from "./styled";

interface IProps {
    disabled: boolean;
    onEditClick: () => void;
}

export const ContactInfo = (props: IProps) => {
    const { disabled, onEditClick } = props;
    const [__] = useTranslation();
    const { INVALID_FORMAT } = useTranslationList();
    const { values } = useFormikContext<IFormValues>();
    const {
        messengerOptions,
        getPlaceholder,
        getLabel,
        loading: messengersLoading,
    } = useMessengersInForms();

    return (
        <>
            <FormRow>
                <Field
                    name="url"
                    type="text"
                    label={__("Сайт")}
                    disabled={disabled}
                />
                <Field
                    name="phoneNumber"
                    type="text"
                    label={__("Номер телефона")}
                    disabled={disabled}
                />
            </FormRow>
            <FormGroup>
                <Field
                    name="email"
                    type="text"
                    label="Email"
                    disabled={disabled}
                />
            </FormGroup>
            <FormRow>
                <Field
                    name="messenger"
                    type="react-select"
                    label={__("Мессенджер")}
                    options={messengerOptions}
                    isLoading={messengersLoading}
                    isDisabled={disabled}
                    isClearable={false}
                />
                <Field
                    name="messengerCredentials"
                    type="text"
                    label={getLabel(values.messenger?.id)}
                    placeholder={getPlaceholder(values.messenger?.id)}
                    disabled={disabled}
                    validate={(value?: string) => {
                        const regexp = new RegExp(
                            values.messenger?.validationTemplate || "",
                        );

                        if (!regexp.test(value || "")) {
                            return INVALID_FORMAT();
                        }
                    }}
                />
            </FormRow>
            <Button disabled={!disabled} onClick={onEditClick}>
                {__("Редактировать")}
            </Button>
        </>
    );
};
