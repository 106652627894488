import { ContentLoader } from "components/components-common/content-loader";
import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { AdminWrapperEdit } from "styled/layouts";
import { SubscribtionsForm } from "./components/subscribtions-form";
import { useSubscriptions } from "./hooks/use-subscriptions";
import { List } from "react-content-loader";
import {
    GetSubscriptions_authorized_partnerAccount_getMailSubscriptions,
    MailSubscribe,
    MailSubscribeVariables,
} from "gql/types/operation-result-types";
import SUBSCRIBE from "./gql/subscribe.gql";
import GET_SUBSCRIPTIONS from "./gql/get-subscribtions.gql";
import { useMutation } from "@apollo/client";
import { NoData } from "components/no-data";
import * as _ from "lodash";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Subscriptions = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { subscriptionsList: subscriptions, loading } = useSubscriptions();
    const [subscribe] = useMutation<MailSubscribe, MailSubscribeVariables>(
        SUBSCRIBE,
        {
            refetchQueries: [{ query: GET_SUBSCRIPTIONS }],
        },
    );

    const onApply = async (
        newSubscriptions: GetSubscriptions_authorized_partnerAccount_getMailSubscriptions[],
    ) => {
        if (subscriptions) {
            const newSubscrGroup = _.groupBy(newSubscriptions, "id");
            const changedSubscriptionsIds = _.map(
                subscriptions,
                (
                    subscr: GetSubscriptions_authorized_partnerAccount_getMailSubscriptions,
                ) => ({
                    ...subscr,
                    newIsEnabled: newSubscrGroup[subscr.id][0].isEnabled,
                }),
            )
                .filter(subscr => subscr.isEnabled !== subscr.newIsEnabled)
                .map(subscr => subscr.id);
            await subscribe({
                variables: { subscriptionIds: changedSubscriptionsIds },
            });
        }
    };

    return (
        <AdminWrapperEdit>
            <ContentLoader component={List} loading={loading}>
                <Title>{__("Подписки")}</Title>
                {subscriptions ? (
                    <SubscribtionsForm
                        subscriptionsList={subscriptions}
                        onApply={onApply}
                    />
                ) : (
                    <NoData />
                )}
            </ContentLoader>
        </AdminWrapperEdit>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
