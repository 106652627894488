import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    PartnerInformation,
} from "gql/types/operation-result-types";
import { INotificationPopupState } from "../../notification-popup";
import { PopupType } from "../popup-id";
import { PopupData } from "../popups-data";

interface ITelegramPopupDataProps {
    id: number;
    type: PopupType;
    linkInPopup: string;
    currency: GetCurrencies_authorized_partnerAndManager_data_currencies;
    timesToShow?: number;
    adAgentId: string;
    materialGroupId?: string;
}

export class TelegramPopupData extends PopupData {
    constructor(public props: ITelegramPopupDataProps) {
        super(
            props.id,
            props.type,
            undefined,
            props.linkInPopup,
            props.currency,
        );
    }

    public show(
        information: INotificationPopupState & PartnerInformation,
    ): boolean {
        const { user, partnerAndManager } = information.authorized;
        const { adAgentId, materialGroupId, timesToShow } = this.props;

        const isCurrencyMatch = partnerAndManager.data.currencies.some(
            cur => cur.id === this.currency?.id,
        );

        const isAdAgentMatch = user.adAgentId === adAgentId;
        const isMaterialGroupMatch =
            !materialGroupId || user.materialGroupId === materialGroupId;
        const isTimesToShowMatch =
            !timesToShow || information.countOfTimesWasShown < timesToShow;
        const isNotClickedDontShowAgain = !information.clickedDontShowAgain;

        const shouldShowPopup =
            isCurrencyMatch &&
            isAdAgentMatch &&
            isMaterialGroupMatch &&
            isTimesToShowMatch &&
            isNotClickedDontShowAgain;

        return shouldShowPopup;
    }
}
