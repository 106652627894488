import { GetManagerGraph_authorized_manager_main_mainStatistic_graph_data } from "gql/types/operation-result-types";
import Highcharts from "highcharts/highstock";
import moment from "moment";
import {
    legendItemClick,
    langOptionChart,
} from "views/partner-manager/chart/common-options";

interface IOptions {
    transformData:
        | GetManagerGraph_authorized_manager_main_mainStatistic_graph_data[]
        | undefined;
    __: any;
}

export const options = ({ transformData, __ }: IOptions) => {
    const views: Array<[number, number | null]> = [];
    const clicks: Array<[number, number | null]> = [];
    const directLink: Array<[number, number | null]> = [];
    const registrations: Array<[number, number | null]> = [];
    const newAccounts: Array<[number, number | null]> = [];
    const deposits: Array<[number, number | null]> = [];
    const comissions: Array<[number, number | null]> = [];
    const profit: Array<[number, number | null]> = [];
    const groupingUnits: any = [
        ["week", [1]],
        ["month", [1]],
    ];

    if (transformData) {
        transformData.forEach(v => {
            const periodV = moment(v.period).utc().add(3, "hours").valueOf();
            views.push([periodV, v.countOfViews]);
            clicks.push([periodV, v.countOfClicks]);
            directLink.push([periodV, v.countOfDirectLinks]);
            registrations.push([periodV, v.countOfRegistrations]);
            newAccounts.push([periodV, v.countOfNewAccountsWithDeposits]);
            deposits.push([periodV, v.depositAmount]);
            comissions.push([periodV, v.comissionAnmount]);
            profit.push([periodV, v.profit]);
        });
    }

    if (typeof Highcharts.setOptions === "function") {
        Highcharts.setOptions(langOptionChart(__));
    }

    return {
        chart1: {
            credits: {
                enabled: false,
            },
            rangeSelector: {
                selected: 3,
                inputEnabled: false,
                buttons: [
                    {
                        type: "week",
                        count: 1,
                        text: __("1н."),
                    },
                    {
                        type: "month",
                        count: 1,
                        text: __("1м."),
                    },
                    {
                        type: "all",
                        text: __("Все"),
                    },
                ],
                buttonTheme: {
                    maxWidth: "100%",
                    width: "auto",
                },
            },
            yAxis: [
                {
                    labels: {
                        align: "right",
                        x: -3,
                    },
                    height: "23%",
                    lineWidth: 2,
                    showEmpty: false,
                },
                {
                    labels: {
                        align: "right",
                        x: -3,
                    },
                    top: "25%",
                    height: "23%",
                    offset: 0,
                    lineWidth: 2,
                    showEmpty: false,
                },
                {
                    labels: {
                        align: "right",
                        x: -3,
                    },
                    top: "50%",
                    height: "23%",
                    offset: 0,
                    lineWidth: 2,
                    showEmpty: false,
                },
                {
                    labels: {
                        align: "right",
                        x: -3,
                    },
                    top: "75%",
                    height: "23%",
                    offset: 0,
                    lineWidth: 2,
                    showEmpty: false,
                },
            ],
            legend: {
                enabled: true,
                align: "center",
                floating: false,
                itemMarginTop: 5,
                itemMarginBottom: 5,
                symbolRadius: 0,
                borderWidth: 0,
                verticalAlign: "bottom",
            },
            tooltip: {
                split: true,
                xDateFormat: "%A, %b %e",
            },
            colors: [
                "rgb(0, 188, 212)",
                "rgb(60, 159, 221)",
                "rgb(115, 110, 175)",
                "rgb(181, 48, 50)",
            ],
            plotOptions: {
                area: {
                    events: {
                        legendItemClick,
                    },
                },
            },
            series: [
                {
                    type: "area",
                    name: __("Просмотры"),
                    data: views,
                    dataGrouping: {
                        units: groupingUnits,
                    },
                },
                {
                    type: "area",
                    name: __("Клики"),
                    data: clicks,
                    yAxis: 1,
                    dataGrouping: {
                        units: groupingUnits,
                    },
                },
                {
                    type: "area",
                    name: __("Прямые ссылки"),
                    data: directLink,
                    yAxis: 2,
                    dataGrouping: {
                        units: groupingUnits,
                    },
                },
                {
                    type: "area",
                    name: __("Регистрации"),
                    data: registrations,
                    yAxis: 3,
                    dataGrouping: {
                        units: groupingUnits,
                    },
                },
            ].filter(Boolean),
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                        },
                        chartOptions: {
                            subtitle: {
                                text: null,
                            },
                            navigator: {
                                enabled: false,
                            },
                            yAxis: [
                                {
                                    title: {
                                        text: null,
                                    },
                                },
                                {
                                    title: {
                                        text: null,
                                    },
                                },
                                {
                                    title: {
                                        text: null,
                                    },
                                },
                                {
                                    title: {
                                        text: null,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        chart2: {
            credits: {
                enabled: false,
            },
            rangeSelector: {
                selected: 3,
                inputEnabled: false,
                buttons: [
                    {
                        type: "week",
                        count: 1,
                        text: __("1н."),
                    },
                    {
                        type: "month",
                        count: 1,
                        text: __("1м."),
                    },
                    {
                        type: "all",
                        text: __("Все"),
                    },
                ],
                buttonTheme: {
                    maxWidth: "100%",
                    width: "auto",
                },
            },
            yAxis: [
                {
                    labels: {
                        align: "right",
                        x: -3,
                    },
                    height: "23%",
                    lineWidth: 2,
                    showEmpty: false,
                },
                {
                    labels: {
                        align: "right",
                        x: -3,
                    },
                    top: "25%",
                    height: "23%",
                    offset: 0,
                    lineWidth: 2,
                    showEmpty: false,
                },
                {
                    labels: {
                        align: "right",
                        x: -3,
                    },
                    top: "50%",
                    height: "23%",
                    offset: 0,
                    lineWidth: 2,
                    showEmpty: false,
                },
                {
                    labels: {
                        align: "right",
                        x: -3,
                    },
                    top: "75%",
                    height: "23%",
                    offset: 0,
                    lineWidth: 2,
                    showEmpty: false,
                },
            ],
            legend: {
                enabled: true,
                align: "center",
                floating: false,
                itemMarginTop: 5,
                itemMarginBottom: 5,
                symbolRadius: 0,
                borderWidth: 0,
                verticalAlign: "bottom",
            },
            tooltip: {
                split: true,
                xDateFormat: "%A, %b %e",
            },

            colors: [
                "rgb(128, 128, 128)",
                "rgb(51, 51, 51)",
                "rgb(251, 207, 113)",
                "rgb(143, 172, 68)",
            ],
            plotOptions: {
                area: {
                    events: {
                        legendItemClick,
                    },
                },
            },
            series: [
                {
                    type: "area",
                    name: __("Новые аккаунты с депозитами"),
                    data: newAccounts,
                    dataGrouping: {
                        units: groupingUnits,
                    },
                },
                {
                    type: "area",
                    name: __("Сумма депозитов"),
                    data: deposits,
                    yAxis: 1,
                    dataGrouping: {
                        units: groupingUnits,
                    },
                },
                {
                    type: "area",
                    name: __("Сумма комиссий"),
                    data: comissions,
                    yAxis: 2,
                    dataGrouping: {
                        units: groupingUnits,
                    },
                },
                {
                    type: "area",
                    name: __("Доход"),
                    data: profit,
                    yAxis: 3,
                    dataGrouping: {
                        units: groupingUnits,
                    },
                },
            ].filter(Boolean),
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 400,
                        },
                        chartOptions: {
                            subtitle: {
                                text: null,
                            },
                            navigator: {
                                enabled: false,
                            },
                            yAxis: [
                                {
                                    title: {
                                        text: null,
                                    },
                                },
                                {
                                    title: {
                                        text: null,
                                    },
                                },
                                {
                                    title: {
                                        text: null,
                                    },
                                },
                                {
                                    title: {
                                        text: null,
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    };
};
