import { useTranslation } from "react-i18next";

export const HowToCreateARef = () => {
    const [__] = useTranslation();
    return (
        <>
            <h2>
                <strong>{__("Как создать партнерскую ссылку")}</strong>
            </h2>
            <ol>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Выбери нужный сайт в поле \"Сайт\"",
                    )}
                </li>
                <li>
                    {__(
                        "Выбери валюту, в которой будут отображаться данные. Валюта по умолчанию - USD.",
                    )}
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Выбери \"Кампанию\" из раскрывающегося списка. \"Кампания\" - это страница, на которую попадет пользователь при клике.",
                    )}
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Добавь параметр Sub ID, если это необходимо. В поле \"Sub ID\" можно добавить любой маркер для трекинга (Например, размер баннера, название рекламной кампании, а также любой динамический параметр для отслеживания).",
                    )}
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Нажми на кнопку \"Сгенерировать ссылку\". Ссылка отобразится ниже.",
                    )}
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Скопируй ссылку, нажав на значок \"Копировать\" в поле \"Готовая ссылка\" справа.",
                    )}
                </li>
                <li>
                    {__(
                        /* prettier-ignore */
                        "Неправильную ссылку можно скрыть, нажав на кнопку \"Скрыть\" в поле \"Состояние\"",
                    )}
                </li>
            </ol>
        </>
    );
};
