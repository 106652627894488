import { useTranslation } from "react-i18next";
import { DefaultColoredLinkButton } from "styled/link";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { FormGroup, RowTwoColumnsParagraph } from "styled/layouts";
import { Paragraph } from "services/styled-components/typogoraphy";

interface IProps {
    codes: string[];
}

export const ActivationCodes = (props: IProps) => {
    const [__] = useTranslation();

    return (
        <>
            <h2>{__("Резервные коды:")}</h2>
            {props.codes.map((item, index) => (
                <Paragraph key={index}>{item}</Paragraph>
            ))}
            <RowTwoColumnsParagraph>
                <FormGroup>
                    <DefaultColoredLinkButton
                        href={URL.createObjectURL(
                            new Blob([props.codes.join("\n")], {
                                type: "text/plain",
                            }),
                        )}
                        download={"2fa_backup_codes.txt"}
                    >
                        {__("Скачать коды")}
                    </DefaultColoredLinkButton>
                </FormGroup>
                <FormGroup>
                    <LocalizedNavLinkWithOptionalBackground
                        to="/partner/user-profile"
                        showBackground
                    >
                        {__("Вернуться")}
                    </LocalizedNavLinkWithOptionalBackground>
                </FormGroup>
            </RowTwoColumnsParagraph>
        </>
    );
};
