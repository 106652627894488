import { AllUniversalOutputValues } from "components/filters/types";
import {
    GetCampaigns_authorized_partnerAndManager_data_campaigns,
    GetCountries_countries,
    PartnerSites_authorized_partnerAndManager_data_sites,
} from "gql/types/operation-result-types";
import * as _ from "lodash";
import { makeUtcCustomPeriod } from "services/project-utils";

export type SubmitValues = Pick<
    AllUniversalOutputValues,
    | "currency"
    | "marketingToolId"
    | "period"
    | "playerId"
    | "onlyNewPlayers"
    | "withoutDepositsOnly"
    | "registrationSource"
> & {
    campaign:
        | GetCampaigns_authorized_partnerAndManager_data_campaigns
        | undefined;
    country: GetCountries_countries | undefined;
    website: PartnerSites_authorized_partnerAndManager_data_sites | undefined;
    subId: string | null | undefined;
};

export const generalQueryParams = (filter: SubmitValues) => {
    const {
        currency,
        period,
        marketingToolId,
        country,
        playerId,
        withoutDepositsOnly,
        onlyNewPlayers,
        campaign,
        website,
        registrationSource,
        subId,
    } = filter;

    const { start, end } = makeUtcCustomPeriod(period.start, period.end);

    return {
        currencyId: _.parseInt(currency && currency.id),
        country: country && country.name,
        mediaId: (marketingToolId && marketingToolId.toString()) || undefined,
        siteId: (website && website.id) || undefined,
        startPeriod: (start && start.toISOString()) || "",
        endPeriod: (end && end.toISOString()) || "",
        campaign: campaign && {
            id: _.parseInt(campaign.id),
            name: campaign.name,
        },
        playerId: playerId || undefined,
        registrationSourceId: registrationSource && registrationSource.id,
        onlyNewPlayers: onlyNewPlayers || false,
        withoutDepositsOnly: withoutDepositsOnly || false,
        subId: subId || "",
        methood: "get",
    };
};
