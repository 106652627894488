import { EditPartnerCommon } from "./edit-partner-common";
import { EditPartnerTabs } from "./edit-partner-tabs";

interface IInjectedProps {
    match: {
        params: {
            id: string;
        };
    };
}

interface IProps extends IInjectedProps {}

export const EditPartner = (({ match }: IProps) => {
    return (
        <>
            <EditPartnerCommon id={Number(match.params.id)} />
            <EditPartnerTabs id={match.params.id} />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
