import { ReactTable } from "components/react-table";
import { LoadingText } from "components/react-table/loading-text";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { IReactTableColumn } from "components/react-table/column/";
import React from "react";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { usePostbacks } from "../hooks/use-postbacks";
import { HelmetWrapper } from "components/helmet/helmet-wrapper";
import { TableItem } from "./table-item";

export const PostbacksList = React.memo(() => {
    const [__] = useTranslation();
    const { data, loading } = usePostbacks();

    const columns: IReactTableColumn[] = [
        {
            Header: "№",
            accessor: "id",
            disabled: true,
            minWidth: 50,
        },
        {
            Header: __("Действия"),
            disabled: true,
            accessor: "id",
            width: 340,
            Cell: cellInfo => (
                <TableItem
                    url="/partner/marketing-tools/postbacks"
                    id={cellInfo.value}
                />
            ),
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            minWidth: 120,
        },
        {
            Header: __("Postback URL"),
            accessor: "url",
            minWidth: 120,
        },
        {
            Header: __("Событие"),
            accessor: "typeName",
            minWidth: 100,
        },
        {
            Header: __("Статус"),
            accessor: "statusName",
            minWidth: 90,
        },
        {
            Header: __("Комментарий"),
            accessor: "comment",
            minWidth: 80,
        },
    ];

    return (
        <HelmetWrapper pageTitle={__("Postbacks")}>
            <AdminWrapper>
                <p>&#123;reg&#125; - Registration</p>
                <p>&#123;ftd&#125; - First deposit</p>
                <p>&#123;bl&#125; - Baseline</p>
                <p>&#123;rs&#125; - RS</p>
                <p>
                    <LocalizedNavLinkWithOptionalBackground
                        showBackground={true}
                        to="/partner/marketing-tools/postbacks/create-postback"
                    >
                        {__("Создать postback")}
                    </LocalizedNavLinkWithOptionalBackground>
                </p>
                <ReactTable
                    tableName="Postbacks"
                    columns={columns}
                    data={data}
                    loading={loading}
                    loadingText={<LoadingText fastLoading={true} />}
                />
            </AdminWrapper>
        </HelmetWrapper>
    );
}) as React.ComponentType;
