import styled from "styled-components";

export const Img = styled.img`
    width: 100%;
    display: block;
`;

export const LinkBanner = styled.a`
    display: contents;
`;
