import { PlacementType } from "gql/types/operation-result-types";

export const placementOptions = [
    {
        value: PlacementType.BTL,
        label: "BTL",
    },
    {
        value: PlacementType.OOH,
        label: "OOH",
    },
    {
        value: PlacementType.OTHER,
        label: "Other",
    },
    {
        value: PlacementType.PRINT,
        label: "Print",
    },
    {
        value: PlacementType.RADIO,
        label: "Radio",
    },
    {
        value: PlacementType.SPONSORSHIP,
        label: "Sponsorship",
    },
    {
        value: PlacementType.TRANSPORT,
        label: "Transport",
    },
    {
        value: PlacementType.TV,
        label: "TV",
    },
];
