import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import GET_PLAN from "views/manager/advertising/fin-plan/gql/get-plan.gql";
import {
    GetPlan,
    GetPlanVariables,
    PartnerListType,
    Rights,
} from "gql/types/operation-result-types";
import { Steps } from "components/steps";
import { BackNextButtons } from "components/steps/buttons";
import { ChoosePartner } from "views/manager/advertising/components/choose-partner";
import { Loader } from "views/manager/advertising/fin-plan/loader";
import { ContractValues } from "views/manager/advertising/fin-plan/contract-form";
import { SelectedPartner } from "views/manager/advertising/fin-plan/edit-fin-plan-steps/selected-partner";
import { EditContract } from "views/manager/advertising/fin-plan/edit-fin-plan-steps/edit-contract";
import { CreatedFinplan, CreateFinplanByContract } from "./add-contract";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";

export const AddFinPlanSteps = () => {
    const [__] = useTranslation();
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const [step, setStep] = useState(0);
    const [partnerId, setPartnerId] = useState<number>();
    // finPlan и contractFormValues используются до создания финплана
    const finPlan = useRef<CreatedFinplan>();
    // сохраняем значения формы для реинициализации при переходе между 1 и 2 шагами
    const contractFormValues = useRef<ContractValues>();

    const [getFinPlanById, { data, loading }] = useLazyQuery<
        GetPlan,
        GetPlanVariables
    >(GET_PLAN, { fetchPolicy: "network-only" });
    const finPlanFetched =
        data?.authorized.manager.advertising.finantialPlans.plan;

    // при переходе на 2 шаг запрашиваем фин план по id, если ранее он был создан (был переход со 2 на 3 шаг),
    // на 3 шаге, чтобы при создании рек выплаты был объект компании, а не только id
    useEffect(() => {
        if ((step === 1 || step === 2) && finPlan.current?.id) {
            void getFinPlanById({
                variables: { id: finPlan.current?.id },
            });
        }
    }, [getFinPlanById, step]);

    if (!permissions?.rights.includes(Rights.CREATE_FIN_PLAN)) {
        return <></>;
    }

    let stepComponents = [
        <ChoosePartner
            key="step-0"
            partnerId={partnerId}
            partnerListType={PartnerListType.FinPlan}
            choosePartner={curPartnerId => {
                setPartnerId(curPartnerId);
                setStep(step + 1);
            }}
        />,
        <CreateFinplanByContract
            key="step-1"
            // when we be here, partnerId can't be undefined, but before that it can
            // so it's ok to use "as"
            partnerId={partnerId as number}
            initialFormValues={contractFormValues.current}
            onFormChange={formValues => {
                contractFormValues.current = formValues;
            }}
        />,
    ];

    if (finPlanFetched) {
        stepComponents = [
            <SelectedPartner key="step-0" partnerId={partnerId} />,
            <EditContract
                key="step-1"
                plan={finPlanFetched}
                // можно редактировать номер, длительность и валюту, если нет привязанных выплат
                isCreation={finPlanFetched.paymentPlans?.every(
                    plan => !plan.advPaymentId,
                )}
                onFinplanCreated={() => {
                    void getFinPlanById({
                        variables: { id: finPlanFetched.id },
                    });
                    setStep(step + 1);
                }}
            />,
        ];
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Steps
                titleText={__("Добавьте фин план в 2 шага:")}
                stepTitles={[__("Выбрать партнёра"), __("Контракт")]}
                currentStep={step}
            />
            {stepComponents[step]}
            <BackNextButtons
                step={step}
                setStep={setStep}
                numberOfSteps={stepComponents.length}
                canGoBack
                canGoNext={
                    (step === 0 && !!partnerId) ||
                    (step === 1 && !!finPlanFetched)
                }
            />
        </>
    );
};
