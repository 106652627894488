import { useQuery, gql } from "@apollo/client";
import { usePaginationAndSorting } from "hooks/use-pagination";
import {
    GetPartnersGroups,
    GetPartnersGroupsVariables,
} from "gql/types/operation-result-types";

export const GET_PARTNERS_GROUPS = gql`
    query GetPartnersGroups($query: PartnersQueryParams!) {
        authorized {
            manager {
                main {
                    partnersGroups(query: $query) {
                        partnerGroups {
                            id
                            name
                            currencyId
                            countOfPartners
                        }
                        rowsCount
                    }
                }
            }
        }
    }
`;

export const usePartnersGroups = (noPagination?: boolean) => {
    const externalPaginationAndSorting = usePaginationAndSorting();
    const { data, loading } = useQuery<
        GetPartnersGroups,
        GetPartnersGroupsVariables
    >(GET_PARTNERS_GROUPS, {
        variables: {
            // по умолчанию бек возвращает максимум на страницу - 100 записей
            query: noPagination ? {} : externalPaginationAndSorting.state,
        },
        fetchPolicy: "network-only",
        ssr: true,
    });

    return {
        data: data?.authorized.manager.main.partnersGroups,
        paginationAndSorting: externalPaginationAndSorting,
        loading,
    };
};
