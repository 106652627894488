import { TabContent } from "components/tabs/components/tab-content";
import { TabSwitcher } from "components/tabs/components/tab-switcher";
import * as _ from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { ITabProps } from "./components/tab";
import { OrdersCunter, TabsNavigation, TabsSC } from "./styled";
interface IInjectedProps {}

interface IProps extends IInjectedProps {
    name: string;
    withLog?: boolean;
    children: unknown;
    default?: number;
    selected?: number;
    onTabClick?: (index: number) => void;
}

export const Tabs = React.memo((props: IProps) => {
    const [selected, setSelected] = useState<number | undefined>();

    const isControlled = props.selected !== undefined;

    useEffect(() => {
        if (isControlled && props.onTabClick === undefined) {
            log.warn(
                `Tabs ${props.name} used with uncontrolled state but "onTabClick" property is not set, please set onTabClick handler`,
            );
        }
    }, [isControlled, props.name, props.onTabClick]);

    const children = _.isArray(props.children)
        ? props.children
        : [props.children];
    const switchers: any[] = [];
    let content = null;

    const selectedTabIndex = getSelectedTabIndex(props, selected, isControlled);
    _.map(children, (child, i) => {
        const childProps = getProps(child);
        if (childProps === null) {
            return;
        }
        if (selectedTabIndex === i) {
            content = childProps.children;
        }
        switchers.push(
            <TabSwitcher
                key={i}
                title={childProps.title}
                onClick={() => {
                    onClick(props, setSelected, isControlled, i);
                    if (childProps.action) {
                        childProps.action(i);
                    }
                }}
                value={i}
                active={selectedTabIndex === i}
                counter={
                    childProps.counter !== undefined
                        ? createCounter(childProps.counter)
                        : undefined
                }
            />,
        );
    });

    return (
        <TabsSC>
            {/* <TabsNavigation className={`${props.className}`}> */}
            <TabsNavigation>{switchers}</TabsNavigation>
            {<TabContent>{content}</TabContent>}
        </TabsSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

const getProps = (
    child: unknown,
): null | (ITabProps & { children?: ReactNode }) => {
    const childObject = Object(child);
    if (Object.keys(childObject).includes("props")) {
        return childObject.props;
    }
    return null;
};

const createCounter = (value: number) => {
    return <OrdersCunter> ({value})</OrdersCunter>;
};

const onClick = (
    props: IProps,
    setSelected: (selected: number) => void,
    isControlled: boolean,
    tabIndex: number,
) => {
    if (props.onTabClick) {
        props.onTabClick(tabIndex);
    }
    if (!isControlled) {
        setSelected(tabIndex);
    }
};

const getSelectedTabIndex = (
    props: IProps,
    selected: number | undefined,
    isControlled: boolean,
) => {
    let uncontrolledTabIndex =
        selected !== undefined ? selected : props.default;
    if (!isControlled && uncontrolledTabIndex === undefined) {
        log.warn(
            `Tabs ${props.name} used with uncontrolled state but "default" property is not set, please set default tab index`,
        );
        uncontrolledTabIndex = 0;
    }
    return isControlled ? props.selected : uncontrolledTabIndex;
};
