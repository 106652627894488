import { css } from "styled-components";

const fontsPath = "../assets/fonts/roboto-condensed/";

export const RobotoCondensedFonts = css`
    @font-face {
        font-family: "Roboto Condensed";
        src: url("${fontsPath}RobotoCondensed-Light.woff2") format("woff2"),
            url("${fontsPath}RobotoCondensed-Light.woff") format("woff");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Condensed";
        src: url("${fontsPath}RobotoCondensed-Regular.woff2") format("woff2"),
            url("${fontsPath}RobotoCondensed-Regular.woff") format("woff");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Roboto Condensed";
        src: url("${fontsPath}RobotoCondensed-Bold.woff2") format("woff2"),
            url("${fontsPath}RobotoCondensed-Bold.woff") format("woff");
        font-weight: 700;
        font-style: normal;
    }
`;
