import { AddressCard } from "@styled-icons/fa-regular/AddressCard/AddressCard";
import { MoneyBillAlt } from "@styled-icons/fa-regular/MoneyBillAlt/MoneyBillAlt";
import { Bullhorn } from "@styled-icons/fa-solid/Bullhorn/Bullhorn";
import { ChartArea } from "@styled-icons/fa-solid/ChartArea/ChartArea";
import { ChartBar } from "@styled-icons/fa-solid/ChartBar/ChartBar";
import { DollarSign } from "@styled-icons/fa-solid/DollarSign/DollarSign";
import { Envelope } from "@styled-icons/fa-solid/Envelope/Envelope";
import { ExternalLinkAlt } from "@styled-icons/fa-solid/ExternalLinkAlt/ExternalLinkAlt";
import { Home } from "@styled-icons/fa-solid/Home/Home";
import { Key } from "@styled-icons/fa-solid/Key/Key";
import { Link } from "@styled-icons/fa-solid/Link/Link";
import { Palette } from "@styled-icons/fa-solid/Palette/Palette";
import { ShareSquare } from "@styled-icons/fa-solid/ShareSquare/ShareSquare";
import { User } from "@styled-icons/fa-solid/User/User";
import { Users } from "@styled-icons/fa-solid/Users/Users";
import { Barcode } from "@styled-icons/icomoon/Barcode/Barcode";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import {
    MenuGroup,
    MenuTitle,
    HiddenMobileLastMenuElement,
} from "components/menu/main-menu/styled";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const PartnerMenuItems = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <>
            <MenuTitle>
                <span>{__("Главное меню")}</span>
            </MenuTitle>
            <MenuGroup>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner"
                    >
                        <Home size={15} />
                        <span>{__("Главная")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/websites"
                    >
                        <Link size={15} />
                        <span>{__("Веб-сайты")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/comission-structure"
                    >
                        <DollarSign size={15} />
                        <span>{__("Структура комиссий")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                {permissions && permissions.paymentHistory && (
                    <li>
                        <LocalizedNavLinkWithOptionalBackground
                            exact={true}
                            to="/partner/payment-history"
                        >
                            <MoneyBillAlt size={15} />
                            <span>{__("История выплат")}</span>
                        </LocalizedNavLinkWithOptionalBackground>
                    </li>
                )}
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/user-profile"
                    >
                        <User size={15} />
                        <span>{__("Профиль")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/contacts"
                    >
                        <Envelope size={15} />
                        <span>{__("Контакты")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
            </MenuGroup>
            <MenuTitle>
                <span>{__("Маркетинг")}</span>
            </MenuTitle>
            <MenuGroup>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/marketing-tools/links"
                    >
                        <ExternalLinkAlt size={15} />
                        <span>{__("Партнерские ссылки")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/marketing-tools/promo-codes"
                    >
                        <Barcode size={15} />
                        <span>{__("Промокоды")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/marketing-tools/media"
                    >
                        <Bullhorn size={15} />
                        <span>{__("Медиа")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                {permissions && permissions.postbacks && (
                    <li>
                        <LocalizedNavLinkWithOptionalBackground
                            exact={true}
                            to="/partner/marketing-tools/postbacks"
                        >
                            <ShareSquare size={15} />
                            <span>{__("Postbacks")}</span>
                        </LocalizedNavLinkWithOptionalBackground>
                    </li>
                )}
                {permissions && permissions.tokensForGetingActualDomain && (
                    <li>
                        <LocalizedNavLinkWithOptionalBackground
                            exact={true}
                            to="/partner/marketing-tools/domain-token"
                        >
                            <Key size={15} />
                            <span>{__("Токен домена")}</span>
                        </LocalizedNavLinkWithOptionalBackground>
                    </li>
                )}
            </MenuGroup>

            <MenuTitle>
                <span>{__("Отчеты")}</span>
            </MenuTitle>

            <MenuGroup>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/reports/quick"
                    >
                        <ChartBar size={15} />
                        <span>{__("Краткий суммарный")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/reports/full"
                    >
                        <ChartArea size={15} />
                        <span>{__("Полный")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/reports/marketing-tools"
                    >
                        <Palette size={15} />
                        <span>{__("Маркетинговые инструменты")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/reports/players"
                    >
                        <AddressCard size={15} />
                        <span>{__("По игрокам")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
                <li>
                    <LocalizedNavLinkWithOptionalBackground
                        exact={true}
                        to="/partner/reports/sub-partners"
                    >
                        <Users size={15} />
                        <span>{__("По суб-партнерам")}</span>
                    </LocalizedNavLinkWithOptionalBackground>
                </li>
            </MenuGroup>
            <HiddenMobileLastMenuElement />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
