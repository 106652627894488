import * as yup from "yup";
import { useObjectValidations } from "validation/validaion";
import {
    GetCampaigns_authorized_partnerAndManager_data_campaigns,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    PartnerSitesList_authorized_manager_main_sites,
} from "gql/types/operation-result-types";
import { useTranslationList } from "hooks/use-translations-list";

export function useValidationSchema() {
    const { objectRequiredRule } = useObjectValidations();
    const { CONTAINS_INVALID_CHARACTERS } = useTranslationList();

    const schema = yup.object({
        currency:
            objectRequiredRule<GetCurrencies_authorized_partnerAndManager_data_currencies>(),
        site: objectRequiredRule<PartnerSitesList_authorized_manager_main_sites>(),
        campaign:
            objectRequiredRule<GetCampaigns_authorized_partnerAndManager_data_campaigns>(),
        promocode: yup
            .string()
            .matches(
                /^(?=([A-Za-z0-9_]){3,255}$)(?=.*[A-Za-z]).*$/,
                CONTAINS_INVALID_CHARACTERS,
            ),
    });
    return schema;
}
