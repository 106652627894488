import { useTranslation } from "react-i18next";
import { RouteComponentProps, Switch } from "react-router-dom";
import urljoin from "url-join";
import { PageRoute } from "components/check-roles/guard";
import { Campaigns } from "./campaigns";

interface IProps extends RouteComponentProps<{ url: string }> {}

export const Marketing = (props: IProps) => {
    const { url } = props.match;
    const [__] = useTranslation();

    return (
        <Switch>
            <PageRoute
                exact
                path={urljoin(url, "campaigns")}
                component={Campaigns}
                pageTitle={__("Кампании")}
            />
        </Switch>
    );
};
