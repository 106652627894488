import { FormikContextType } from "components/formik";
import { useTranslation } from "react-i18next";
import {
    changeOption,
    IProps,
    SelectedOption,
    useSelect,
} from "./hooks/use-select";
import { SelectSC } from "./styled";

interface IInjectedProps {
    formik: FormikContextType<any>;
}

export const Select = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const {
        onChange,
        fieldOnChange,
        name,
        selectFirst,
        isLoading,
        isSearchable = true,
        isClearable = true,
        ...rest
    } = props;

    const [options, selectedOption] = useSelect({
        ...rest,
        name,
        onChange,
        fieldOnChange,
        selectFirst,
    });

    return (
        <SelectSC
            classNamePrefix="react-select"
            placeholder={__("Выберите...")}
            {...rest}
            ref={props.innerRef}
            options={options}
            value={selectedOption || ""}
            onChange={(newSelectedOption: SelectedOption) => {
                return changeOption({
                    name,
                    selectedOption: newSelectedOption,
                    fieldOnChange,
                    onChange,
                });
            }}
            isSearchable={isSearchable}
            isClearable={isClearable}
            menuPlacement="auto"
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
