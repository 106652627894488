import {
    AdvertisementStatus,
    DealType,
    GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment,
} from "gql/types/operation-result-types";
import { useCurrencyAdversitingPayments } from "hooks/query/use-currency-adversiting-payments";
import { useCountriesWithTranslations } from "hooks/query/query-with-translations/use-countries-with-translations";
import { AdversitingAction } from "../../view-edit-steps";
import { usePaymentSystemsWithFields } from "hooks/query/use-payment-systems-with-fields";
import { convertToAmountRUB } from "./convert-to-amount-rub";

interface IUseGetInitialValuesFromPayment {
    // партнер передается отдельным полем, т.к. в некоторых случаях он мог редактироваться
    // и уже не совпадать с advPayment
    partnerId?: number;
    // сайт передается отдельным полем, т.к. в некоторых случаях он мог редактироваться
    // и уже не совпадать с advPayment
    siteId?: number | null;
    statusAdvertisingPayments?: AdvertisementStatus;
    adversitingAction?: AdversitingAction;
}

export function useGetInitialValuesFromPayment({
    partnerId,
    siteId,
    statusAdvertisingPayments = AdvertisementStatus.NEW,
    adversitingAction,
}: IUseGetInitialValuesFromPayment) {
    const { data: currencyList } = useCurrencyAdversitingPayments();
    const { countries } = useCountriesWithTranslations();
    const { paymentSystems } = usePaymentSystemsWithFields(true);

    return (
        advPayment: GetAdvertisementPayment_authorized_manager_advertising_advertisingPayments_advertisingPayment,
    ) => {
        const foundCountry = countries.find(
            country => country.value.id === advPayment.countryId,
        )?.value;

        const currency =
            currencyList &&
            currencyList.find(crn => crn.id === advPayment.currencyId);

        const currentPaymentSystem = paymentSystems.find(
            ps => ps.id === advPayment.payment.id,
        );

        if (currentPaymentSystem) {
            // мы объединяем объект платежной системы, в котором есть валидация и названия,
            // с объектом платежной системы из платежа, в котором есть значения полей
            // TODO: возможно это луше делать за грфюэлем?
            currentPaymentSystem.fields = currentPaymentSystem.fields?.map(
                field => ({
                    ...field,
                    value:
                        advPayment.payment.fields?.find(
                            paymentField => paymentField.id === field.id,
                        )?.value || "",
                }),
            );
        }

        const filesContent =
            !advPayment.documentLinks?.length &&
            adversitingAction !== AdversitingAction.View
                ? [{ name: "", path: "" }]
                : (advPayment.documentLinks || []).map(path => {
                      return {
                          name: path.split("/").pop() || "",
                          path,
                      };
                  });

        return {
            partnerId,
            siteId,
            currencyAdversitingPayments: currency ? currency : null,
            amountRequest: advPayment.amountRequest,
            amountRequestRUB: convertToAmountRUB(
                advPayment.amountRequest,
                currency,
            ),
            contractPlacements: advPayment.placements?.map(placement => {
                const { __typename, ...rest } = placement;
                return rest;
            }),
            statusAdvertisingPayments,
            comments: advPayment.comments,
            paymentSystem: currentPaymentSystem || null,
            dealType:
                advPayment.dealType === DealType.DIGITAL
                    ? "Digital"
                    : "Offline",
            search: advPayment.advertisingCompany?.name.slice(0, 3),
            company: advPayment.advertisingCompany?.id,
            country: foundCountry,
            filesContent,
        };
    };
}
