import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";

export const useColumns = (currencyName?: string) => {
    const [__] = useTranslation();

    const currency = currencyName || "";

    const columns: IReactTableColumn[] = [
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Id партнера"),
            accessor: "partnerId",
            minWidth: 80,
        },
        {
            Header: __("Имя пользователя"),
            accessor: "userName",
            minWidth: 80,
        },
        {
            Header: __("Период"),
            accessor: "period",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Общая комиссия"),
            accessor: "totalCommission",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("Перенос"),
            accessor: "carryover",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("Сумма бонусов"),
            accessor: "bonusAmount",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("Доход"),
            accessor: "profit",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("Сумма холда"),
            accessor: "holdAmount",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("Выплачено"),
            accessor: "paid",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("Дата выплаты"),
            accessor: "paymentDate",
            minWidth: 90,
            dataType: "date",
        },
        {
            Header: __("Доступно к выводу"),
            accessor: "availableToWithdraw",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
    ];

    return columns;
};
