import { Field } from "components/formik";
import { FinantialPlanStatus, Rights } from "gql/types/operation-result-types";
import { useDefinedContext } from "hooks/use-context-exist";
import { useTranslation } from "react-i18next";
import { getFinplanStatusWithTranslation } from "services/project-utils";
import { PartnersProgramGeneralInformationContext } from "views";

interface IProps {
    name?: string;
    currentStatus?: FinantialPlanStatus | null;
    previousStatus?: FinantialPlanStatus | null;
    disabled?: boolean;
}

export const ApplyStatusField = ({
    currentStatus,
    disabled,
    name,
    previousStatus,
}: IProps) => {
    const [__] = useTranslation();

    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    function isDisabled(status: FinantialPlanStatus) {
        if (!currentStatus || status !== currentStatus) {
            return false;
        }

        return currentStatus !== FinantialPlanStatus.INTERMIDIATE;
    }

    function getOption(status: FinantialPlanStatus) {
        return {
            value: status,
            label: getFinplanStatusWithTranslation(status, __),
            isDisabled: isDisabled(status),
        };
    }

    const getStatusOptions = () => {
        let options: Array<{
            value: FinantialPlanStatus;
            label: string | undefined;
            isDisabled?: boolean;
        }>;
        if (
            permissions?.rights.includes(Rights.CREATE_FIN_PLAN) &&
            permissions?.rights.includes(Rights.APPROVE_FIN_PLAN)
        ) {
            options = [
                getOption(FinantialPlanStatus.CREATED),
                getOption(FinantialPlanStatus.APPROVED),
                getOption(FinantialPlanStatus.REVISION_REQUIRED),
                getOption(FinantialPlanStatus.ARCHIVE),
            ];
        } else if (permissions?.rights.includes(Rights.APPROVE_FIN_PLAN)) {
            options = [
                getOption(FinantialPlanStatus.APPROVED),
                getOption(FinantialPlanStatus.REVISION_REQUIRED),
                getOption(FinantialPlanStatus.ARCHIVE),
            ];
        } else if (permissions?.rights.includes(Rights.CREATE_FIN_PLAN)) {
            options = [
                getOption(FinantialPlanStatus.CREATED),
                getOption(FinantialPlanStatus.ARCHIVE),
            ];
        } else {
            options = [getOption(FinantialPlanStatus.ARCHIVE)];
        }

        if (currentStatus === FinantialPlanStatus.INTERMIDIATE) {
            options = [...options, getOption(FinantialPlanStatus.INTERMIDIATE)];
        }

        if (
            currentStatus === FinantialPlanStatus.ARCHIVE &&
            previousStatus &&
            !options.some(opt => opt.value === previousStatus)
        ) {
            options = [
                ...options,
                {
                    value: previousStatus,
                    label: getFinplanStatusWithTranslation(previousStatus, __),
                    isDisabled: false,
                },
            ];
        }

        if (
            currentStatus &&
            !options.some(opt => opt.value === currentStatus)
        ) {
            options = [...options, getOption(currentStatus)];
        }

        return options;
    };

    return (
        <Field
            name={name || "status"}
            type="react-select"
            options={getStatusOptions()}
            minWidth={200}
            label={__("Статус")}
            isDisabled={disabled}
        />
    );
};
