import { GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans } from "gql/types/operation-result-types";
import { useRef, useState } from "react";
import { AdversitingAction } from "views/manager/advertising/adversiting-payments/view-edit-steps";
import { SetPaymentPlanIdProps } from "../delete-payment-plan-modal";

export interface EditOrViewAdvertisingPayment {
    paymentPlan: PaymentPlan;
    adversitingAction: AdversitingAction;
}

// eslint-disable-next-line max-len
export type PaymentPlan =
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans;

export const usePaymentConnectEditViewStates = () => {
    const [paymentPlanToConnect, setPaymentPlanToConnect] =
        useState<PaymentPlan>();
    const [paymentPlanToCreatePayment, setPaymentPlanToCreatePayment] =
        useState<PaymentPlan>();
    const [editPaymentPlan, setEditPaymentPlan] = useState<PaymentPlan>();
    const [editOrViewPayment, setEditOrViewPayment] =
        useState<EditOrViewAdvertisingPayment>();

    const paymentPlanDeleteComponentRef = useRef<SetPaymentPlanIdProps>(null);

    return {
        paymentPlanToConnect,
        setPaymentPlanToConnect,
        paymentPlanToCreatePayment,
        setPaymentPlanToCreatePayment,
        editPaymentPlan,
        setEditPaymentPlan,
        paymentPlanDeleteComponentRef,
        editOrViewPayment,
        setEditOrViewPayment,
    };
};
