import { UniversalForm } from "components/filters";
import { useTranslation } from "react-i18next";
import { AdminWrapper } from "styled/layouts";
import { FirstLoadTable } from "views/partner-manager/first-load-table";
import { PartnerInvoicesReportTable } from "./report-table";
import {
    IBaseFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";
import { Period, Periods } from "components/filters/fields/period";
import { useObjectValidations } from "validation/validaion";
import moment from "moment";
import { InvoiceTypeField } from "./fields/invoice-type-field";
import { InvoiceStatusField } from "./fields/invoice-status-field";

export const PartnerInvoices = () => {
    const [__] = useTranslation();
    const { objectRequiredRule } = useObjectValidations();

    return (
        <UniversalForm
            fields={["currency", "commisionGroup", "partnersGroup"]}
            additionalFields={{
                period: {
                    initialValue: {
                        interval: Periods.CURRENT_MONTH,
                        start: moment().startOf("month"),
                        end: moment().endOf("month"),
                    },
                    component: (props: IBaseFieldProps) => (
                        <Period {...selectFieldsProps(props)} />
                    ),
                    validationSchema: objectRequiredRule<{
                        start: import("moment").Moment | null;
                        end: import("moment").Moment | null;
                    }>(),
                },
                invoiceType: {
                    initialValue: null,
                    component: () => <InvoiceTypeField />,
                },
                invoiceStatus: {
                    initialValue: null,
                    component: () => <InvoiceStatusField />,
                },
            }}
            submitButtonName={__("Сгенерировать отчет")}
        >
            {({ form, submittedValues, actualValues }) => {
                return (
                    <AdminWrapper>
                        {form}
                        <FirstLoadTable
                            actualValues={actualValues}
                            submittedValues={submittedValues}
                        >
                            {values => (
                                <PartnerInvoicesReportTable
                                    submittedValues={values}
                                />
                            )}
                        </FirstLoadTable>
                    </AdminWrapper>
                );
            }}
        </UniversalForm>
    );
};
