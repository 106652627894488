import GET_SITES from "gql/queries/partners/sites.gql";
import { AddSite, AddSiteVariables } from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import ADD_SITE from "../gql/add-site.gql";

export const useAddSite = () => {
    const [addSite] = useMutation<AddSite, AddSiteVariables>(ADD_SITE, {
        refetchQueries: [
            {
                query: GET_SITES,
                variables: {
                    filter: { hidden: true },
                },
            },
            {
                query: GET_SITES,
                variables: {
                    filter: { hidden: false },
                },
            },
        ],
    });

    return { addSite };
};
