import * as React from "react";
import { TooltipSC, TooltipArow, TooltipContent } from "./styled";

interface IProps {
    arrowContent: React.ReactNode;
    children: React.ReactNode;
}

export const Tooltip = ({ children, arrowContent }: IProps) => {
    return (
        <TooltipSC>
            {children}
            <TooltipArow />
            <TooltipContent>{arrowContent}</TooltipContent>
        </TooltipSC>
    );
};
