import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form, Field } from "components/formik";
import {
    FormDirectionRow,
    FormGroup,
    FormGroupButton,
    FormDirectionColumn,
    FormGroupCheckbox,
} from "styled/layouts";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { Variables } from "services/variables";
import { useTranslation } from "react-i18next";
import { Periods } from "components/filters/fields/period";
import { getDatesByPeriod } from "components/filters/services/get-dates-by-period";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";
import {
    PartnerCommissionGroups_authorized_manager_main_partnerCommissionGroups_commissionGroups,
    UpdateCommissionGroup,
    UpdateCommissionGroupVariables,
} from "gql/types/operation-result-types";
import { useValidationSchema } from "./use-validation-schema";
import * as moment from "moment";
import { errorHandler } from "services/error-handler";
import { Dispatch, SetStateAction, useContext } from "react";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { useMutation } from "@apollo/client";
import UPDATE_COMMISSION_GROUP from "./update.gql";
import PARTNER_COMMISSION_GROUPS from "../query.gql";

interface IProps {
    partnerId: number;
    currentEditingGroup: PartnerCommissionGroups_authorized_manager_main_partnerCommissionGroups_commissionGroups;
    setIdEditingGroup: Dispatch<SetStateAction<number | undefined>>;
}

export const EditGroupForm = ({
    partnerId,
    currentEditingGroup,
    setIdEditingGroup,
}: IProps) => {
    const [__] = useTranslation();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const { end } = getDatesByPeriod(Periods.CURRENT_MONTH);

    const initialValues = {
        dateStart: moment(
            currentEditingGroup.dateStart,
            Variables.dateFormat.seconds,
        ),
        dateEnd: currentEditingGroup.dateEnd
            ? moment(currentEditingGroup.dateEnd, Variables.dateFormat.seconds)
            : null,
        noLimitedGroup: currentEditingGroup.dateEnd === null ? true : false,
    };

    const validationSchema = useValidationSchema();

    const [mutation] = useMutation<
        UpdateCommissionGroup,
        UpdateCommissionGroupVariables
    >(UPDATE_COMMISSION_GROUP, {
        refetchQueries: [
            {
                query: PARTNER_COMMISSION_GROUPS,
                variables: { partnerId },
            },
        ],
    });

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async values => {
                try {
                    const dateEnd = values.noLimitedGroup
                        ? null
                        : values.dateEnd?.toISOString();
                    await mutation({
                        variables: {
                            newCommissionGroup: {
                                id: currentEditingGroup.id,
                                partnerId,
                                noLimitedGroup: values.noLimitedGroup || false,
                                dateEnd,
                            },
                        },
                    });
                    setIdEditingGroup(undefined);
                    setColumnData({
                        name: "",
                        id: null,
                        tableName: "commission-groups-table",
                    });
                } catch (ex: any) {
                    void errorHandler(ex);
                }
            }, validationSchema)}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <FormDirectionRow>
                        <FormDirectionColumn>
                            <FormGroup>
                                <Field
                                    name="dateStart"
                                    type="date-single-picker"
                                    label={__("Дата начала")}
                                    disabled
                                />
                            </FormGroup>
                            <FormGroupCheckbox>
                                <Field
                                    name="noLimitedGroup"
                                    type="checkbox"
                                    label={__("Бессрочная группа")}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const currentValue = e.target.checked;
                                        setFieldValue(
                                            "noLimitedGroup",
                                            currentValue,
                                        );
                                        setFieldValue(
                                            "dateEnd",
                                            currentValue ? null : end,
                                        );
                                    }}
                                />
                            </FormGroupCheckbox>
                        </FormDirectionColumn>
                        <FormGroup>
                            <Field
                                name="dateEnd"
                                type="date-single-picker"
                                label={__("Дата окончания")}
                                disabled={values.noLimitedGroup}
                            />
                        </FormGroup>
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>{__("Редактировать")}</SubmitButton>
                        </FormGroupButton>
                    </FormDirectionRow>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
