import React from "react";
import styled, { keyframes } from "styled-components";

interface ITypes {
    [key: string]: string;
}

export const Roller = ({ color }: { color?: string }) => {
    return (
        <RollerBox color={color}>
            <RollerItem setting="item1" />
            <RollerItem setting="item2" />
            <RollerItem setting="item3" />
            <RollerItem setting="item4" />
            <RollerItem setting="item5" />
            <RollerItem setting="item6" />
            <RollerItem setting="item7" />
            <RollerItem setting="item8" />
        </RollerBox>
    );
};

const types: ITypes = {
    item1: `
        animation-delay: -0.036s;
	`,
    item2: `
        animation-delay: -0.072s;
	`,
    item3: `
        animation-delay: -0.108s;
	`,
    item4: `
        animation-delay: -0.144s;
	`,
    item5: `
        animation-delay: -0.18s;
	`,
    item6: `
        animation-delay: -0.216s;
	`,
    item7: `
        animation-delay: -0.252s;
	`,
    item8: `
        animation-delay: -0.288s;
	`,
};

const ldsRoller = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const RollerBox = styled.div`
    display: inline-block;
    position: relative;
    width: 12px;
    height: 12px;
    & div:after {
        background: ${({ color }) => color};
    }
`;

const RollerItem = styled.div<{ setting: keyof ITypes }>`
    animation: ${ldsRoller} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 6px 6px;
    ${({ setting }) => types[setting]}
    &:after {
        content: " ";
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: ${props => props.theme.activeColor};
        margin: -4px 0 0 -4px;
    }
`;
