import { useTranslation } from "react-i18next";
import {
    ApplyFinPlanStatus,
    ApplyFinPlanStatusVariables,
    FinantialPlans,
    FinantialPlanStatus,
    FinantialPlansVariables,
    Rights,
} from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { FormGroupButton } from "styled/layouts";
import { FakeLabel } from "components/filters/form";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { useRef, useState } from "react";
import { AllUniversalOutputValues } from "components/filters/types";
import { makeUtcCustomPeriod } from "services/project-utils";
import { useColumns } from "./use-columns";
import { ReactTable } from "components/react-table";
import FINANTIAL_PLANS from "../query.gql";
import APPLY_FINPLAN_STATUS from "../gql/apply-finplan-status.gql";
import { RowInfo } from "react-table";
import { ApplyStatusField } from "./apply-status-field";
import { WrappedApiRequestError } from "server/errors/request-error";
import { DeleteFinPlanModal } from "../payment-plans-operations/delete-fin-plan-modal";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";

interface FinPlanReportTableProps {
    submittedValues: Pick<
        AllUniversalOutputValues,
        "period" | "partnerId" | "currencyAdversitingPayments"
    > & {
        planId: number | null | undefined;
        planName: string | null | undefined;
        finplanStatus: FinantialPlanStatus | null;
    };
}

const TD_ERROR_COLOR = "#ff000078";

export interface SetFinPlanIdToDeleteProps {
    setFinPlanIdToDelete: (finPlanIdToDelete: number) => void;
}

export const FinPlanReportTable = ({
    submittedValues,
}: FinPlanReportTableProps) => {
    const [__] = useTranslation();
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const finPlanDeleteComponentRef = useRef<SetFinPlanIdToDeleteProps>(null);
    const columns = useColumns(finPlanDeleteComponentRef);
    const [queryCount, setQueryCount] = useState(0);

    const { start, end } = makeUtcCustomPeriod(
        submittedValues.period.start,
        submittedValues.period.end,
    );

    const variables = {
        filter: {
            startPeriod: (start && start.toISOString()) || "",
            endPeriod: (end && end.toISOString()) || "",
            status: submittedValues.finplanStatus,
            currencyId: submittedValues.currencyAdversitingPayments?.id,
            planId: submittedValues.planId,
            planName: submittedValues.planName,
            partnerId: submittedValues.partnerId,
        },
    };

    const queryResult = useQuery<FinantialPlans, FinantialPlansVariables>(
        FINANTIAL_PLANS,
        {
            variables,
            fetchPolicy: "network-only",
            notifyOnNetworkStatusChange: true,
            onCompleted: () =>
                setQueryCount(curQueryCount => curQueryCount + 1),
        },
    );

    const [mutation] = useMutation<
        ApplyFinPlanStatus,
        ApplyFinPlanStatusVariables
    >(APPLY_FINPLAN_STATUS, {
        refetchQueries: () => {
            return [
                {
                    query: FINANTIAL_PLANS,
                    variables,
                },
            ];
        },
    });

    const applyStatus = async (
        ids: Set<number>,
        status: FinantialPlanStatus | null,
    ) => {
        try {
            if (!status) {
                toast.error(__("Статус не выбран"));
                return;
            }

            if (!Array.from(ids).length) {
                toast.error(__("Финплан для изменения статуса не выбран"));
                return;
            }

            await mutation({
                variables: {
                    statusId: status,
                    planId: Array.from(ids),
                },
            });

            toast.success(__("Статус успешно изменен"));
        } catch (ex: any) {
            void errorHandler(ex, error => {
                switch (error.data.code) {
                    case ErrorType.IMPOSIBLE_STATUS_CHANGING:
                        return __("Невозможно применить статус");
                    default:
                        return (error as WrappedApiRequestError).data.details
                            .result?.message;
                }
            });
        }
    };

    return (
        <>
            <AdminWrapper>
                <ReactTable
                    tableName={"finplans"}
                    columns={columns}
                    loading={queryResult?.loading}
                    data={
                        queryResult?.data?.authorized.manager.advertising
                            .finantialPlans.plans.plansRows || []
                    }
                    linkButtons={
                        permissions?.rights.includes(Rights.CREATE_FIN_PLAN)
                            ? [
                                  <FormGroupButton key={1}>
                                      <FakeLabel />
                                      <LocalizedNavLinkWithOptionalBackground
                                          showBackground={true}
                                          to="/agent/advertising/fin-plans/add"
                                          exact={true}
                                      >
                                          {__("Добавить финплан")}
                                      </LocalizedNavLinkWithOptionalBackground>
                                  </FormGroupButton>,
                              ]
                            : undefined
                    }
                    rowsCheck={{
                        resultHash: queryCount.toString(),
                        shouldShowCheckbox: original => {
                            return original.statusName !== "In progress";
                        },
                    }}
                    form={{
                        initialStatus: null as FinantialPlanStatus | null,
                        onSubmit: async (checked, status) =>
                            await applyStatus(checked as Set<number>, status),
                        statusField: <ApplyStatusField />,
                    }}
                    getTdProps={(tableState: any, rowInfo: RowInfo) => ({
                        style: {
                            backgroundColor:
                                rowInfo &&
                                rowInfo.original.sumOfContract <
                                    rowInfo.original.paymentPlanAmountSumm &&
                                TD_ERROR_COLOR,
                        },
                    })}
                />
            </AdminWrapper>
            <DeleteFinPlanModal
                ref={finPlanDeleteComponentRef}
                updateFinPlans={queryResult?.refetch}
            />
        </>
    );
};
