import { SelectSC } from "components/formik/universal-field/fields/select/styled";
import { media } from "services/styled-components/variables";
import styled from "styled-components";

export const SelectPage = styled(SelectSC)`
    font-size: 1em;
    margin-top: 10px;

    .react-select__control {
        height: 2.5em;
        min-height: 2.5em;
        border-color: hsl(0, 0%, 80%);

        ${media.smMax`
            height: 2.5em;
        `}
    }
`;
