import { FinantialPlanStatus } from "gql/types/operation-result-types";
import { Field } from "components/formik";
import { useTranslation } from "react-i18next";
import { getFinplanStatusWithTranslation } from "services/project-utils";

export const StatusField = () => {
    const [__] = useTranslation();

    const options = [
        {
            value: FinantialPlanStatus.CREATED,
            label: getFinplanStatusWithTranslation(
                FinantialPlanStatus.CREATED,
                __,
            ),
        },
        {
            value: FinantialPlanStatus.PAYING,
            label: getFinplanStatusWithTranslation(
                FinantialPlanStatus.PAYING,
                __,
            ),
        },
        {
            value: FinantialPlanStatus.PAID,
            label: getFinplanStatusWithTranslation(
                FinantialPlanStatus.PAID,
                __,
            ),
        },
        {
            value: FinantialPlanStatus.ARCHIVE,
            label: getFinplanStatusWithTranslation(
                FinantialPlanStatus.ARCHIVE,
                __,
            ),
        },
        {
            value: FinantialPlanStatus.INTERMIDIATE,
            label: getFinplanStatusWithTranslation(
                FinantialPlanStatus.INTERMIDIATE,
                __,
            ),
        },
        {
            value: FinantialPlanStatus.APPROVED,
            label: getFinplanStatusWithTranslation(
                FinantialPlanStatus.APPROVED,
                __,
            ),
        },
        {
            value: FinantialPlanStatus.REVISION_REQUIRED,
            label: getFinplanStatusWithTranslation(
                FinantialPlanStatus.REVISION_REQUIRED,
                __,
            ),
        },
    ];

    return (
        <Field
            name="finplanStatus"
            key="finplanStatus"
            type="react-select"
            options={options}
            isLoading={false}
            selectFirst={false}
            label={__("Статус")}
            minWidth={200}
        />
    );
};
