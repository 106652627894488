import { TFunction } from "i18next";

export const mockSiteCategory = (__: TFunction) => [
    { value: "6", label: __("Спортивные новости") },
    { value: "3", label: __("Прогнозы на спорт") },
    { value: "12", label: __("Спортивные трансляции") },
    { value: "21", label: __("Прочее") },
    { value: "9", label: __("Букмекеры и ставки") },
    { value: "18", label: __("Спорт") },
    { value: "15", label: __("Казино") },
    { value: "22", label: "SEO web-site" },
    { value: "23", label: "Facebook" },
    { value: "24", label: "Uac" },
    { value: "25", label: "Aso" },
    { value: "26", label: "Tiktok" },
    { value: "27", label: "Telegram" },
    { value: "28", label: "Streamer" },
    { value: "29", label: "Ad networks" },
    { value: "30", label: "Sms/email" },
];

export const mockLanguages = (__: TFunction) => [
    { value: "1", label: __("Английский") },
    { value: "2", label: __("Немецкий") },
    { value: "3", label: __("Русский") },
    { value: "4", label: __("Грузинский") },
    { value: "5", label: __("Монгольский") },
    { value: "6", label: __("Польский") },
    { value: "7", label: __("Болгарский") },
    { value: "8", label: __("Шведский") },
    { value: "9", label: __("Румынский") },
    { value: "10", label: __("Чешский") },
    { value: "11", label: __("Французский") },
    { value: "12", label: __("Арабский") },
    { value: "13", label: __("Итальянский") },
    { value: "14", label: __("Испанский") },
    { value: "15", label: __("Персидский") },
    { value: "16", label: __("Сербский") },
    { value: "17", label: __("Турецкий") },
    { value: "18", label: __("Словацкий") },
    { value: "19", label: __("Португальский") },
    { value: "20", label: __("Белорусский") },
    { value: "21", label: __("Датский") },
    { value: "22", label: __("Греческий") },
    { value: "23", label: __("Эстонский") },
    { value: "24", label: __("Финский") },
    { value: "25", label: __("Иврит") },
    { value: "26", label: __("Хинди") },
    { value: "27", label: __("Хорватский") },
    { value: "28", label: __("Венгерский") },
    { value: "29", label: __("Индонезийский") },
    { value: "30", label: __("Японский") },
    { value: "31", label: __("Корейский") },
    { value: "32", label: __("Литовский") },
    { value: "33", label: __("Латышский") },
    { value: "34", label: __("Македонский") },
    { value: "35", label: __("Малайский") },
    { value: "36", label: __("Норвежский") },
    { value: "37", label: __("Нидерландский") },
    { value: "38", label: __("Португальский (Бразилия)") },
    { value: "39", label: __("Тайский") },
    { value: "40", label: __("Украинский") },
    { value: "41", label: __("Вьетнамский") },
    { value: "42", label: __("Китайский (традиционный)") },
    { value: "43", label: __("Китайский") },
    { value: "44", label: __("Азербайджанский") },
    { value: "45", label: __("Казахский") },
    { value: "46", label: __("Узбекский") },
    { value: "47", label: __("Таджикский") },
    { value: "48", label: __("Бирманский") },
    { value: "49", label: __("Курдский Сорани") },
    { value: "50", label: __("Курдский Курманджи") },
    { value: "51", label: __("Курдский Бадини") },
    { value: "52", label: __("Курдский Заза") },
    { value: "53", label: __("Албанский") },
    { value: "54", label: __("Боснийский") },
    { value: "55", label: __("Кхмерский") },
    { value: "59", label: __("Бенгальский") },
    { value: "67", label: __("Словенский") },
    { value: "68", label: __("Тагальский") },
    { value: "72", label: __("Урду") },
    { value: "76", label: __("Сомалийский") },
];

export const mockHowDidYouKnowOptions = (__: TFunction) => [
    { value: "3", label: __("Реклама") },
    { value: "6", label: __("Ссылка на сайт") },
    { value: "9", label: __("Посоветовал знакомый") },
];

export const mockCountryList = (__: TFunction) => [
    { value: "1", label: __("Россия") },
    { value: "2", label: __("Украина") },
    { value: "3", label: __("Абхазия") },
    { value: "4", label: __("Австралия") },
    { value: "5", label: __("Австрия") },
    { value: "6", label: __("Азербайджан") },
    { value: "7", label: __("Албания") },
    { value: "8", label: __("Алжир") },
    { value: "9", label: __("Ангола") },
    { value: "10", label: __("Ангуилья") },
    { value: "11", label: __("Андорра") },
    { value: "12", label: __("Антигуа и Барбуда") },
    { value: "13", label: __("Нидерландские Антильские острова") },
    { value: "14", label: __("Аргентина") },
    { value: "15", label: __("Армения") },
    { value: "16", label: __("Аруба") },
    { value: "17", label: __("Афганистан") },
    { value: "18", label: __("Багамские о-ва") },
    { value: "19", label: __("Бангладеш") },
    { value: "20", label: __("Барбадос") },
    { value: "21", label: __("Бахрейн") },
    { value: "22", label: __("Беларусь") },
    { value: "23", label: __("Белиз") },
    { value: "24", label: __("Бельгия") },
    { value: "25", label: __("Бенин") },
    { value: "26", label: __("Бермуды") },
    { value: "27", label: __("Болгария") },
    { value: "28", label: __("Боливия") },
    { value: "29", label: __("Босния/Герцеговина") },
    { value: "30", label: __("Ботсвана") },
    { value: "31", label: __("Бразилия") },
    { value: "32", label: __("Виргинские острова") },
    { value: "33", label: __("Бруней") },
    { value: "34", label: __("Буркина Фасо") },
    { value: "35", label: __("Бурунди") },
    { value: "36", label: __("Бутан") },
    { value: "37", label: __("Уоллис и Футуна") },
    { value: "38", label: __("Вануату") },
    { value: "39", label: __("Великобритания") },
    { value: "40", label: __("Венгрия") },
    { value: "41", label: __("Венесуэла") },
    { value: "42", label: __("Восточный Тимор") },
    { value: "43", label: __("Вьетнам") },
    { value: "44", label: __("Габон") },
    { value: "45", label: __("Гаити") },
    { value: "46", label: __("Гайана") },
    { value: "47", label: __("Гамбия") },
    { value: "48", label: __("Гана") },
    { value: "49", label: __("Гваделупа") },
    { value: "50", label: __("Гватемала") },
    { value: "51", label: __("Гвинея") },
    { value: "52", label: __("Гвинея-Бисау") },
    { value: "53", label: __("Германия") },
    { value: "54", label: __("остров Гернси") },
    { value: "55", label: __("Гибралтар") },
    { value: "56", label: __("Гондурас") },
    { value: "57", label: __("Гонконг") },
    { value: "58", label: __("Гренада") },
    { value: "59", label: __("Гренландия") },
    { value: "60", label: __("Греция") },
    { value: "61", label: __("Грузия") },
    { value: "62", label: __("Дания") },
    { value: "63", label: __("остров Джерси") },
    { value: "64", label: __("Джибути") },
    { value: "65", label: __("Доминиканская республика") },
    { value: "66", label: __("Египет") },
    { value: "67", label: __("Замбия") },
    { value: "68", label: __("Западная Сахара") },
    { value: "69", label: __("Зимбабве") },
    { value: "70", label: __("Израиль") },
    { value: "71", label: __("Индия") },
    { value: "72", label: __("Индонезия") },
    { value: "73", label: __("Иордания") },
    { value: "74", label: __("Ирак") },
    { value: "75", label: __("Иран") },
    { value: "76", label: __("Ирландия") },
    { value: "77", label: __("Исландия") },
    { value: "78", label: __("Испания") },
    { value: "79", label: __("Италия") },
    { value: "80", label: __("Йемен") },
    { value: "81", label: __("Кабо-Верде") },
    { value: "82", label: __("Казахстан") },
    { value: "83", label: __("Камбоджа") },
    { value: "84", label: __("Камерун") },
    { value: "85", label: __("Канада") },
    { value: "86", label: __("Катар") },
    { value: "87", label: __("Кения") },
    { value: "88", label: __("Кипр") },
    { value: "89", label: __("Кирибати") },
    { value: "90", label: __("Китай") },
    { value: "91", label: __("Колумбия") },
    { value: "92", label: __("Коморские о-ва") },
    { value: "93", label: __("Конго (Brazzaville)") },
    { value: "94", label: __("Конго (Kinshasa)") },
    { value: "95", label: __("Коста-Рика") },
    { value: "96", label: __("Кот-дИвуар") },
    { value: "97", label: __("Куба") },
    { value: "98", label: __("Кувейт") },
    { value: "99", label: __("острова Кука") },
    { value: "101", label: __("Лаос") },
    { value: "102", label: __("Латвия") },
    { value: "103", label: __("Лесото") },
    { value: "104", label: __("Либерия") },
    { value: "105", label: __("Ливан") },
    { value: "106", label: __("Ливия") },
    { value: "107", label: __("Литва") },
    { value: "108", label: __("Лихтенштейн") },
    { value: "109", label: __("Люксембург") },
    { value: "110", label: __("Маврикий") },
    { value: "111", label: __("Мавритания") },
    { value: "112", label: __("Мадагаскар") },
    { value: "113", label: __("Македония") },
    { value: "114", label: __("Малави") },
    { value: "115", label: __("Малайзия") },
    { value: "116", label: __("Мали") },
    { value: "117", label: __("Мальдивские о-ва") },
    { value: "118", label: __("Мальта") },
    { value: "119", label: __("Мартиника") },
    { value: "120", label: __("Мексика") },
    { value: "121", label: __("Мозамбик") },
    { value: "122", label: __("Молдова") },
    { value: "123", label: __("Монако") },
    { value: "124", label: __("Монголия") },
    { value: "125", label: __("Марокко") },
    { value: "126", label: __("Мьянма") },
    { value: "127", label: __("остров Мэн") },
    { value: "128", label: __("Намибия") },
    { value: "129", label: __("Науру") },
    { value: "130", label: __("Непал") },
    { value: "131", label: __("Республика Нигер") },
    { value: "132", label: __("Нигерия") },
    { value: "133", label: __("Нидерланды (Голландия)") },
    { value: "134", label: __("Никарагуа") },
    { value: "135", label: __("Новая Зеландия") },
    { value: "136", label: __("Новая Каледония") },
    { value: "137", label: __("Норвегия") },
    { value: "138", label: __("Остров Норфолк") },
    { value: "139", label: __("О.А.Э.") },
    { value: "140", label: __("Оман") },
    { value: "141", label: __("Пакистан") },
    { value: "142", label: __("Панама") },
    { value: "143", label: __("Папуа Новая Гвинея") },
    { value: "144", label: __("Парагвай") },
    { value: "145", label: __("Перу") },
    { value: "146", label: __("остров Питкэрн") },
    { value: "147", label: __("Польша") },
    { value: "148", label: __("Португалия") },
    { value: "149", label: __("Пуэрто Рико") },
    { value: "150", label: __("Реюньон") },
    { value: "151", label: __("Руанда") },
    { value: "152", label: __("Румыния") },
    { value: "153", label: __("США") },
    { value: "154", label: __("Сальвадор") },
    { value: "155", label: __("Самоа") },
    { value: "156", label: __("Сан-Марино") },
    { value: "157", label: __("Сан-Томе и Принсипи") },
    { value: "158", label: __("Саудовская Аравия") },
    { value: "159", label: __("Свазиленд") },
    { value: "160", label: __("Святая Люсия") },
    { value: "161", label: __("остров Святой Елены") },
    { value: "162", label: __("Северная Корея") },
    { value: "163", label: __("Сейшеллы") },
    { value: "164", label: __("Сен-Пьер и Микелон") },
    { value: "165", label: __("Сенегал") },
    { value: "166", label: __("Сент Китс и Невис") },
    { value: "167", label: __("Сент-Винсент и Гренадины") },
    { value: "168", label: __("Сербия") },
    { value: "169", label: __("Сингапур") },
    { value: "170", label: __("Сирия") },
    { value: "171", label: __("Словакия") },
    { value: "172", label: __("Словения") },
    { value: "173", label: __("Соломоновы Острова") },
    { value: "174", label: __("Сомали") },
    { value: "175", label: __("Судан") },
    { value: "176", label: __("Суринам") },
    { value: "177", label: __("Сьерра-Леоне") },
    { value: "178", label: __("Таджикистан") },
    { value: "179", label: __("Тайвань") },
    { value: "180", label: __("Таиланд") },
    { value: "181", label: __("Танзания") },
    { value: "182", label: __("Того") },
    { value: "183", label: __("Токелау") },
    { value: "184", label: __("Тонга") },
    { value: "185", label: __("Тринидад и Тобаго") },
    { value: "186", label: __("Тувалу") },
    { value: "187", label: __("Тунис") },
    { value: "188", label: __("Туркменистан") },
    { value: "189", label: __("Туркс и Кейкос") },
    { value: "190", label: __("Турция") },
    { value: "191", label: __("Уганда") },
    { value: "192", label: __("Узбекистан") },
    { value: "193", label: __("Уругвай") },
    { value: "194", label: __("Фарерские острова") },
    { value: "195", label: __("Фиджи") },
    { value: "196", label: __("Филиппины") },
    { value: "197", label: __("Финляндия") },
    { value: "198", label: __("Франция") },
    { value: "199", label: __("Французская Гвинея") },
    { value: "200", label: __("Таити") },
    { value: "201", label: __("Хорватия") },
    { value: "202", label: __("Чад") },
    { value: "203", label: __("Черногория") },
    { value: "204", label: __("Чехия") },
    { value: "205", label: __("Чили") },
    { value: "206", label: __("Швейцария") },
    { value: "207", label: __("Швеция") },
    { value: "208", label: __("Шри-Ланка") },
    { value: "209", label: __("Эквадор") },
    { value: "210", label: __("Экваториальная Гвинея") },
    { value: "211", label: __("Эритрея") },
    { value: "212", label: __("Эстония") },
    { value: "213", label: __("Эфиопия") },
    { value: "214", label: __("ЮАР") },
    { value: "215", label: __("Южная Корея") },
    { value: "216", label: __("Южная Осетия") },
    { value: "217", label: __("Ямайка") },
    { value: "218", label: __("Япония") },
    { value: "227", label: __("ЦАР") },
    { value: "228", label: __("Кыргызстан") },
    { value: "237", label: __("Палестина") },
];

export const mockPaymentSystems = () => [{ value: "35", label: "Bitcoin" }];

export const mockPaymentSystemsFields = (__: TFunction) => [
    { value: "1", label: __("Номер кошелька") },
    { value: "2", label: __("Номер кошелька") },
    { value: "3", label: __("Номер кошелька") },
    { value: "7", label: __("Номер кошелька") },
    { value: "9", label: __("Почта") },
    { value: "13", label: __("Почта") },
    { value: "15", label: __("Номер кошелька или почта") },
    { value: "17", label: __("Номер банковской карты") },
    { value: "19", label: __("Номер банковской карты (Visa)") },
    { value: "21", label: __("Номер банковской карты (MasterCard)") },
    { value: "23", label: __("ФИО") },
    { value: "25", label: __("Имя Фамилия") },
    { value: "27", label: __("Фамилия") },
    { value: "29", label: __("Имя") },
    { value: "31", label: __("Дата рождения(ДД.ММ.ГГГГ)") },
    { value: "33", label: __("Месяц(ММ)") },
    { value: "35", label: __("Год(ГГГГ)") },
    { value: "37", label: __("Город") },
    { value: "39", label: __("Месяц(ММ)/Год(ГГГГ)") },
    { value: "41", label: __("Наименование банка") },
    { value: "43", label: __("Код банка") },
    { value: "45", label: __("Тег") },
    { value: "47", label: __("Сообщение Nem") },
    { value: "49", label: __("Идентификатор платежа") },
    { value: "51", label: __("Идентификатор платежа") },
    { value: "53", label: __("Номер кошелька") },
    { value: "55", label: __("Почта") },
    { value: "56", label: __("Пользовательский аккаунт") },
    { value: "57", label: __("Код страны") },
];
