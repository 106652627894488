import arrowBottomSmall from "assets/images/arrow-bottom-small.svg";
import styled, { css } from "styled-components";
import { rtl } from "services/styled-components/rtl";
import { backgroundColor0 } from "services/styled-components/themes";

interface IExportProps {
    opened: boolean;
    disabled?: boolean;
}

export const ExportSelect = styled.div<IExportProps>`
    ${rtl`padding: 10px 40px 10px 15px;`};
    position: relative;
    color: ${backgroundColor0};
    background: ${props => props.theme.additionalColor4};
    text-transform: uppercase;
    cursor: pointer;
    min-width: 150px;
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        ${rtl`right: 15px;`};
        width: 8px;
        height: 5px;
        margin-top: -2px;
        background-image: url(${arrowBottomSmall});
        transition: all 0.3s ease;
    }

    &:hover ::after {
        ${props =>
            props.opened
                ? css`
                      margin-top: -4px;
                  `
                : css`
                      margin-top: 0px;
                  `}
    }
`;

export const ExportVariants = styled.ul`
    z-index: -10;
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    min-width: 100%;
    max-width: 100%;
    padding: 10px 0 8px 0;
    background: #fff;
    opacity: 0;
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12),
        0 2px 10px 0 rgba(34, 36, 38, 0.15);
    max-height: 0;
    overflow: hidden;
    transition: display 0s linear 0.3s, opacity 0.3s linear,
        max-height 0s linear 0.3s;
`;

export const ExportVariant = styled.li`
    button {
        width: 100%;
        padding: 5px 15px;
        text-align: left;
        outline: none;

        &:hover {
            color: #fff;
            background: ${props => props.theme.additionalColor4};
        }
    }
`;

export const ExportSC = styled.div<IExportProps>`
    position: relative;
    display: inline-flex;
    ${props =>
        props.opened &&
        css`
            ${ExportSelect}::after {
                transform: rotate(-180deg);
            }

            ${ExportVariants} {
                max-height: 500px;
                overflow-y: auto;
                opacity: 1;
                transition-delay: 0s;
                z-index: 1;
            }
        `}
`;

export const LoadingOverlay = styled.div`
    position: absolute;
    background: rgba(11, 11, 11, 0.55);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const LoadingWrapper = styled.div`
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 0 10px;
    text-align: center;
    font-size: 16px;
    max-width: 800px;

    p {
        margin-bottom: 0;
    }
`;
