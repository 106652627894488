import { Times } from "@styled-icons/fa-solid/Times/Times";
import { ProfileAsideOptions } from "components/menu/menu-user";
import { CloseButton } from "components/menu/menu-components/close-button/styled";
import { AsideContent } from "components/menu/main-menu";
import { SitePartitionType } from "views/site-patrition-wrapper";
import { UserConsumer } from "components/user-consumer";
import { connect } from "react-redux";
import { Action } from "redux";
import { StatesActionCreators } from "services/redux/root/states/actions";
import { statesSelectors } from "services/redux/root/states/selectors";
import { AsideUserInfo, AsideUserMenu } from "./styled";
import { useEffect, useRef } from "react";
import { CloseSurroundingArea } from "components/menu/menu-components/close-surrounding-area";

interface IInjectedProps {
    isProfileMenuClicked: boolean;
    setIsProfileMenuClicked: (visibility: boolean) => Action;
}

interface IProps extends IInjectedProps {
    adminRole: SitePartitionType;
}

export const MobileMenuUser = connect(
    state => ({
        isProfileMenuClicked: statesSelectors.getIsProfileMenuClicked(state),
    }),
    dispatch => ({
        setIsProfileMenuClicked: (visibility: boolean) =>
            dispatch(StatesActionCreators.setIsProfileMenuClicked(visibility)),
    }),
)(
    React.memo((props: IProps) => {
        const refMenu = useRef(null);

        useEffect(() => {
            document.body.style.overflow = props.isProfileMenuClicked
                ? "hidden"
                : "auto";
        }, [props.isProfileMenuClicked]);

        return (
            <AsideUserMenu
                ref={refMenu}
                isProfileMenuClicked={props.isProfileMenuClicked}
            >
                {props.isProfileMenuClicked && (
                    <CloseSurroundingArea
                        refToAreaToSurround={refMenu}
                        onClick={() =>
                            props.setIsProfileMenuClicked(
                                !props.isProfileMenuClicked,
                            )
                        }
                    />
                )}

                <CloseButton
                    orientation={"right"}
                    onClick={() =>
                        props.setIsProfileMenuClicked(
                            !props.isProfileMenuClicked,
                        )
                    }
                >
                    <Times size={30} />
                </CloseButton>
                <AsideContent>
                    <UserConsumer>
                        {user => (
                            <AsideUserInfo>
                                Aff ID: {user && `${user.affiliateId}`}
                            </AsideUserInfo>
                        )}
                    </UserConsumer>
                    <ProfileAsideOptions />
                </AsideContent>
            </AsideUserMenu>
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
