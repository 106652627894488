import { User_user } from "gql/types/operation-result-types";
import { PartnerAccount } from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import PARTNER_ACCOUNT from "../../partner-account.gql";
import { UserTitle } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    user: User_user | undefined;
}

export const UserName = ((props: IProps) => {
    const [__] = useTranslation();
    const { data, loading } = useQuery<PartnerAccount>(PARTNER_ACCOUNT, {
        ssr: true,
    });
    // можно в принципе взять name из user и не городить завпрос внизу.
    // однако это не случай, когда админ идет смотреть этого пользователя
    // хотя это не точно, возможно админ именно заходит под пользователем
    return (
        <UserTitle>
            {__("Пользователь")}:{" "}
            <span>
                {loading
                    ? "..."
                    : data?.authorized.partnerAccount.mainAccountData?.login ||
                      ""}
            </span>
        </UserTitle>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
