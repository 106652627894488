import { Field } from "components/formik";
import { GetMarketingToolsTypes } from "gql/types/operation-result-types";
import _ from "lodash";
import { useQuery, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

const GET_MARKETING_TOOLS_TYPES = gql`
    query GetMarketingToolsTypes {
        authorized {
            partner {
                data {
                    marketingToolsTypes {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const MarketingToolsTypeField = React.memo((props: ISelectFild) => {
    const { name, ...rest } = props;
    const [__] = useTranslation();
    const { data, loading } = useQuery<GetMarketingToolsTypes>(
        GET_MARKETING_TOOLS_TYPES,
        {
            ssr: true,
        },
    );

    const options = _.map(
        g(data, "authorized", "partner", "data", "marketingToolsTypes"),
        marketingTools => ({
            value: marketingTools as any,
            label: marketingTools.name,
        }),
    );

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={name}
            label={__("Тип инструмента")}
            type="react-select"
            options={options}
            isLoading={loading}
            minWidth={200}
            {...rest}
        />
    );
}) as React.ComponentType<ISelectFild>;
