import React from "react";
import { ExportButton as _ExportButton } from "components/react-table/table-select-variables/export/components/export-button";
import { useExportAdvertisingPayments } from "views/manager/advertising/adversiting-payments/report-table/use-export-advertising-payments";
import { GetAdvertisementPaymentOrdersFilter } from "gql/types/operation-result-types";
import { ReportFormat } from "gql/types/enumReportFormat";

interface IProps {
    filters: GetAdvertisementPaymentOrdersFilter;
    button: {
        format: ReportFormat;
        caption: string;
    };
}

export const ExportButton = React.memo((props: IProps) => {
    const {
        button: { format, caption },
        filters,
    } = props;
    const { loading, exportReport } = useExportAdvertisingPayments(
        filters,
        format,
    );

    return (
        <_ExportButton
            key={format}
            exportData={exportReport}
            saving={loading}
            caption={caption}
        />
    );
});
