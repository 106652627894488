import React from "react";

interface IProps {
    width?: string;
    height?: string;
    color?: string;
}

export const AndroidIcon = React.memo((props: IProps) => {
    return (
        <svg
            viewBox="0 0 24 24"
            width={props.width}
            height={props.height}
            aria-hidden="true"
            focusable="false"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            color={props.color}
        >
            <path
                transform="translate(0, 3.5)"
                d="M 17.527344 10.457031 C 16.976562 10.457031 16.53125 9.996094 16.53125 9.425781 C 16.527344 8.851562 16.976562 8.390625 17.527344 8.390625 C 18.082031 8.390625 18.527344 8.851562 18.527344 9.421875 C 18.527344 9.996094 18.082031 10.457031 17.527344 10.457031 M 6.484375 10.457031 C 5.929688 10.457031 5.484375 9.996094 5.484375 9.425781 C 5.484375 8.851562 5.929688 8.390625 6.484375 8.390625 C 7.035156 8.390625 7.480469 8.851562 7.480469 9.421875 C 7.480469 9.996094 7.035156 10.457031 6.484375 10.457031 M 17.886719 4.226562 L 19.882812 0.648438 C 20 0.441406 19.929688 0.179688 19.734375 0.0585938 C 19.535156 -0.0585938 19.28125 0.0117188 19.164062 0.214844 L 17.144531 3.84375 C 15.597656 3.113281 13.859375 2.703125 12.003906 2.703125 C 10.152344 2.703125 8.414062 3.113281 6.867188 3.84375 L 4.847656 0.214844 C 4.730469 0.0117188 4.476562 -0.0585938 4.277344 0.0585938 C 4.082031 0.175781 4.011719 0.441406 4.128906 0.644531 L 6.125 4.226562 C 2.695312 6.160156 0.347656 9.753906 0.00390625 13.996094 L 24.007812 13.996094 C 23.664062 9.753906 21.316406 6.160156 17.886719 4.226562"
            ></path>
        </svg>
    );
});
