import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { LogoImg, LogoImgSmall, LogoLink } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    className?: string;
}

export const Logo = React.memo((props: IProps) => {
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    return (
        <LogoLink {...props} exact={true} to={"/partner"}>
            <LogoImg
                src={"/config-files/logo"}
                alt={
                    partnersGeneralInformation.agentSiteInformation.companyName
                }
            />
            <LogoImgSmall
                src={"/config-files/logo_small"}
                alt={
                    partnersGeneralInformation.agentSiteInformation.companyName
                }
            />
        </LogoLink>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
