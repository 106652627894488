import * as yup from "yup";
import {
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetPartnersGroups_authorized_manager_main_partnersGroups_partnerGroups,
    PartnerSites_authorized_partnerAndManager_data_sites,
} from "gql/types/operation-result-types";
import { useObjectValidations } from "validation/validaion";
import { useTranslationList } from "hooks/use-translations-list";

export const useValidationSchema = () => {
    const { interger, objectRule, momentSchema } = useObjectValidations();
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();

    return yup
        .object({
            partnerId: interger,
            currency:
                objectRule<GetCurrencies_authorized_partnerAndManager_data_currencies>().required(
                    FIELD_SHOULD_BE_FILLED,
                ),
            partnersGroup:
                objectRule<GetPartnersGroups_authorized_manager_main_partnersGroups_partnerGroups>().required(
                    FIELD_SHOULD_BE_FILLED,
                ),
            period: yup
                .object({
                    start: momentSchema.required(FIELD_SHOULD_BE_FILLED),
                    end: momentSchema,
                })
                .required(FIELD_SHOULD_BE_FILLED),
            site: yup.object<PartnerSites_authorized_partnerAndManager_data_sites>(),
            endless: yup.boolean().required(),
        })
        .required();
};

export type TFormValues = yup.InferType<ReturnType<typeof useValidationSchema>>;
