import { useMemo } from "react";
import { AdvertisingPaymentsFromFile } from "..";
import { useCurrencyAdversitingPayments } from "hooks/query/use-currency-adversiting-payments";
import { GetPaymentSystems_paymentSystems } from "gql/types/operation-result-types";

export const useInitialValues = (
    editingPayment: AdvertisingPaymentsFromFile,
    foundedPaymentSystem?: GetPaymentSystems_paymentSystems,
) => {
    const { walletNumber, partnerId, siteId } = editingPayment;

    const { data: currencies } = useCurrencyAdversitingPayments();

    const foundedCurrency = (currencies || []).find(currency => {
        return currency.name === editingPayment.currencyName;
    });

    return useMemo(
        () => ({
            walletNumber,
            description: editingPayment.comments,
            sum: editingPayment.amountRequest,
            currencyAdversitingPayments: foundedCurrency,
            documentLinks: editingPayment.documentLink,
            partnerId,
            paymentMethodId: foundedPaymentSystem?.id || null,
            siteId: siteId ? siteId : "",
        }),
        [
            walletNumber,
            partnerId,
            editingPayment,
            foundedCurrency,
            siteId,
            foundedPaymentSystem,
        ],
    );
};
