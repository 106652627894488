import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import { getInvoiceTypeName } from "services/project-utils";
import { getInvoiceStatusName } from "services/project-utils";
import { CellWithButtons } from "components/table-item";
import {
    PartnerInvoiceStatus,
    PartnerInvoices_authorized_manager_payments_partnerInvoices_invoices,
} from "gql/types/operation-result-types";
import { ArrowSync } from "@styled-icons/fluentui-system-filled/ArrowSync";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";

interface IProps {
    setCurrentInvoiceId: (invoiceId: number) => void;
    loadingRegenerate: boolean;
    open: () => void;
}

export const useColumns = ({
    setCurrentInvoiceId,
    loadingRegenerate,
    open,
}: IProps) => {
    const [__] = useTranslation();

    const columns: IReactTableColumn[] = [
        {
            Header: __("ID инвойса"),
            accessor: "id",
            minWidth: 120,
            Cell: ({
                original,
            }: {
                original: PartnerInvoices_authorized_manager_payments_partnerInvoices_invoices;
            }) => {
                const button = (
                    <>
                        {original.invoiceStatus ===
                            PartnerInvoiceStatus.UNPAID &&
                            !!original.active && (
                                <ButtonColoredWithIcon
                                    btnColor={BtnColor.Faded}
                                    title={__("Перегенерировать")}
                                    type="button"
                                    onClick={() => {
                                        setCurrentInvoiceId(original.id);
                                        open();
                                    }}
                                    disabled={!!loadingRegenerate}
                                >
                                    <ArrowSync size={15} />
                                </ButtonColoredWithIcon>
                            )}
                    </>
                );
                return (
                    <CellWithButtons
                        button={button}
                        link={false}
                        text={original.id.toString()}
                    />
                );
            },
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Статус"),
            accessor: "invoiceStatus",
            minWidth: 80,
            Cell: ({ value }) => <>{getInvoiceStatusName(value, __)}</>,
        },
        {
            Header: __("Id партнера"),
            accessor: "partnerId",
            minWidth: 80,
        },
        {
            Header: __("Имя пользователя"),
            accessor: "userName",
            minWidth: 80,
        },
        {
            Header: __("Период"),
            accessor: "period",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Тип инвойса"),
            accessor: "invoiceType",
            minWidth: 80,
            Cell: ({ value }) => <>{getInvoiceTypeName(value, __)}</>,
        },
        {
            Header: __("Сумма к оплате"),
            accessor: "summRequest",
            minWidth: 80,
        },
        {
            Header: __("Платежная система"),
            accessor: "paymentName",
            minWidth: 80,
        },
        {
            Header: __("Номер счета"),
            accessor: "accountNumber",
            minWidth: 80,
        },
        {
            Header: __("Id транзакции"),
            accessor: "transactionId",
            minWidth: 80,
        },
        {
            Header: __("Игроков"),
            accessor: "players",
            minWidth: 80,
        },
    ];

    return columns;
};
