import { Img, LinkBanner } from "./styled";
import { INotificationContentProps } from "..";
import { BannerPopupData } from "./banner-popup-data";

interface IProps extends INotificationContentProps {
    data: BannerPopupData;
}

export const BannerContent = (props: IProps) => (
    <LinkBanner href={props.data.data.link} target="__blank">
        <Img src={props.data.data.src} loading="lazy" />
    </LinkBanner>
);
