import {
    DealType,
    UpdateAdvertisementPayment,
    UpdateAdvertisementPaymentParams,
    UpdateAdvertisementPaymentVariables,
} from "gql/types/operation-result-types";
import { MutationFunction } from "@apollo/client";
import { IOutputValues } from "components/formik/on-submit-wrapper";
import { useValidationSchema } from "../common-form-advertising/validation-scheme";
import { useGetInitialValuesFromPayment } from "../common-form-advertising/get-initial-values-from-payment";
import { getPaymentSystem } from "../common-form-advertising/get-payment-system";

export interface UpdateStatusProps {
    mutation: MutationFunction<
        UpdateAdvertisementPayment,
        UpdateAdvertisementPaymentVariables
    >;
    values: IOutputValues<
        ReturnType<ReturnType<typeof useGetInitialValuesFromPayment>>,
        typeof useValidationSchema
    >;
    id: number;
}

export const updatePayment = async ({
    mutation,
    values,
    id,
}: UpdateStatusProps) => {
    const documentLinks = values.filesContent
        .filter(item => !!item.path)
        .map(item => item.path);

    const payment: UpdateAdvertisementPaymentParams = {
        id,
        partnerId: values.partnerId,
        statusId: values.statusAdvertisingPayments,
        siteId: values.siteId,
        // почему хард код dealType (стояло 2)??? при редактировании c 1 - ok
        dealType: values.dealType.toUpperCase() === DealType.DIGITAL ? 1 : 2,
        currencyId: values.currencyAdversitingPayments.id,
        amountRequest: values.amountRequest,
        payment: getPaymentSystem(values),
        placements: values.contractPlacements,
        comments: values.comments,
        documentLinks,
    };

    await mutation({
        variables: {
            advPayment: payment,
        },
    });

    return payment;
};
