import { LocalizedLink } from "@webx-react/i18n";
import { BtnColor, ButtonColoredWithIcon } from "styled/button";
import { Settings2Outline } from "@styled-icons/evaicons-outline/Settings2Outline";
import { useTranslation } from "react-i18next";
import { Row } from "styled/layouts";

interface IButtonsWithIdColumn {
    id?: string;
}

const ModerateButton = ({ id }: IButtonsWithIdColumn) => {
    const [__] = useTranslation();

    return (
        <ButtonColoredWithIcon
            btnColor={BtnColor.Faded}
            title={__("Модерация")}
            type="button"
            style={{
                marginLeft: 10,
            }}
        >
            <LocalizedLink
                className="no-indent"
                to={`agent/main/postbacks/moderate/${id}`}
            >
                <Settings2Outline size={15} />
            </LocalizedLink>
        </ButtonColoredWithIcon>
    );
};

export const ButtonsWithIdColumn = ({ id }: IButtonsWithIdColumn) => {
    return (
        <Row align="center">
            {id}
            <ModerateButton id={id} />
        </Row>
    );
};
