import { Field, Form } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { MessengerField } from "components/filters/fields/messenger";
import { ComissionGroupField } from "components/filters/fields/commision-group";
import { PartnerGroupField } from "components/filters/fields/partner-group";
import { CurrencyField, LanguageField } from "components/filters/fields";
import { useTranslation } from "react-i18next";
import { CountryField } from "components/filters/fields/country";
import { PasswordField } from "views/auth/sign-up/form/components/fields/password";
import { MessengerLoginField } from "components/filters/fields/messengerLoginField";
import { AdminWrapper, FormGroup } from "styled/layouts";
import { RowTwoColumnsParagraph } from "styled/layouts";
import { FormGroupButton } from "styled/layouts";

const TWO_ROWS_MAX_WIDTH = "99%";

export const AddPartnerForm = () => {
    const [__] = useTranslation();

    return (
        <Form>
            <AdminWrapper>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <Field
                            name="login"
                            type="text"
                            label={`${__("Имя пользователя (Логин)")} *`}
                            placeholder={__("Имя пользователя (Логин)")}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <PasswordField />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <Field
                            name="confirmPassword"
                            type="password"
                            label={`${__("Повторите пароль")} *`}
                            placeholder={__("Повторите пароль")}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph>
                    <FormGroup>
                        <Field
                            name="firstname"
                            type="text"
                            label={`${__("Имя")} *`}
                            placeholder={__("Имя")}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="surname"
                            type="text"
                            label={`${__("Фамилия")} *`}
                            placeholder={__("Фамилия")}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
            </AdminWrapper>
            <AdminWrapper>
                <RowTwoColumnsParagraph>
                    <FormGroup>
                        <Field
                            name="email"
                            type="email"
                            label={`${__("Email адрес")} *`}
                            placeholder={__("Email адрес")}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="phone"
                            type="text"
                            label={__("Номер телефона")}
                            placeholder={__("Номер телефона")}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <Field
                            name="website"
                            type="text"
                            label={__("Сайт")}
                            placeholder={__("Сайт")}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph>
                    <FormGroup>
                        <MessengerField />
                    </FormGroup>
                    <FormGroup>
                        <MessengerLoginField />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph>
                    <FormGroup>
                        <CountryField
                            name="country"
                            label={`${__("Страна")} *`}
                            selectFirst
                            isClearable={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <LanguageField
                            name="language"
                            label={`${__("Предпочитаемый язык")} *`}
                            selectFirst
                            isClearable={false}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
            </AdminWrapper>
            <AdminWrapper>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <Field
                            name="message"
                            type="textarea"
                            label={__("Примечание")}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
            </AdminWrapper>
            <AdminWrapper>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <CurrencyField
                            name="currency"
                            label={`${__("Валюта")} *`}
                            selectFirst
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <ComissionGroupField name="commisionGroup" />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <PartnerGroupField name="partnersGroup" />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <FormGroupButton>
                            <SubmitButton>
                                {__("Добавить партнера")}
                            </SubmitButton>
                        </FormGroupButton>
                    </FormGroup>
                </RowTwoColumnsParagraph>
            </AdminWrapper>
        </Form>
    );
};
