import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import { CellWithButtons } from "components/table-item";
import { getStatusName } from "services/project-utils";
import {
    AdvertisementStatus,
    DealType,
    // eslint-disable-next-line max-len
    GetAdvertisementPaymentOrders_authorized_manager_advertising_advertisingPayments_payments_advertisementPaymentOrders,
} from "gql/types/operation-result-types";
import {
    ViewAdvertisingButton,
    OpenModalToConnectPaymentButton,
    DublicateAdvertisingButoon,
    EditAdvertisingButton,
} from "./buttons";
import { showEditButton } from "views/manager/advertising/adversiting-payments/report-table/report-table-columns/show-edit-button";

export type AdvertisingPayment =
    // eslint-disable-next-line max-len
    GetAdvertisementPaymentOrders_authorized_manager_advertising_advertisingPayments_payments_advertisementPaymentOrders;

interface IOriginal {
    original: AdvertisingPayment;
}

export const useAdvertisementPaymentsColumns = (
    onChoosePayment?: (advPayment: AdvertisingPayment) => void,
) => {
    const [__] = useTranslation();

    const showDublicateButton = (
        statusName: AdvertisementStatus,
        dealType: string,
    ) =>
        (statusName === AdvertisementStatus.DECLINED ||
            statusName === AdvertisementStatus.CANCELED ||
            statusName === AdvertisementStatus.SUCCESS) &&
        dealType === DealType.DIGITAL;

    const isFromFinPlan = Boolean(onChoosePayment);

    const columns: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "id",
            minWidth: 120,
            Cell: ({ original }) => {
                const button = (
                    <>
                        <ViewAdvertisingButton
                            to={`/agent/advertising/payments/view/${original.id}`}
                        />
                        {showEditButton(original as AdvertisingPayment) &&
                            !onChoosePayment && (
                                <EditAdvertisingButton
                                    to={`/agent/advertising/payments/edit/${original.id}`}
                                />
                            )}
                        {onChoosePayment && (
                            <OpenModalToConnectPaymentButton
                                onClick={() => {
                                    onChoosePayment(
                                        original as AdvertisingPayment,
                                    );
                                }}
                            />
                        )}
                        {showDublicateButton(
                            original.statusName,
                            original.dealType,
                        ) && (
                            <DublicateAdvertisingButoon
                                id={Number(original.id)}
                            />
                        )}
                    </>
                );
                return (
                    <CellWithButtons
                        button={button}
                        link={false}
                        text={original.id}
                    />
                );
            },
        },
        ...(isFromFinPlan
            ? [
                  {
                      Header: __("Тип сделки"),
                      accessor: "dealType",
                      minWidth: 80,
                      Cell: ({ original }: IOriginal) => {
                          return original.dealType === DealType.DIGITAL
                              ? "Digital"
                              : "Offline";
                      },
                  },
              ]
            : []),
        {
            Header: __("Создал пользователь"),
            accessor: "whoAdded",
            minWidth: 80,
        },
        {
            Header: __("Создал ФИО"),
            accessor: "whoAddedFullName",
            minWidth: 80,
        },
        {
            Header: __("Создал Должность"),
            accessor: "whoAddedPosition",
            minWidth: 80,
        },
        {
            Header: __("Дата создания"),
            accessor: "dateOfCreation",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Платежная система"),
            accessor: "paymentMethodName",
            minWidth: 80,
        },
        {
            Header: __("Кошелек"),
            accessor: "walletNumber",
            minWidth: 80,
        },
        {
            Header: __("Сумма к оплате"),
            accessor: "amountRequest",
            minWidth: 80,
        },
        {
            Header: __("Оплачен"),
            accessor: "paidAmount",
            minWidth: 80,
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Комментарий"),
            accessor: "comments",
            minWidth: 80,
        },
        {
            Header: __("ID партнера"),
            accessor: "partnerId",
            minWidth: 80,
        },
        {
            Header: __("ID сайта"),
            accessor: "siteId",
            minWidth: 80,
        },
        {
            Header: __("Статус"),
            accessor: "statusName",
            minWidth: 80,
            Cell: ({ original }) => (
                <>{getStatusName(original.statusName, __)}</>
            ),
        },
        {
            Header: __("Дата выплаты"),
            accessor: "paymentDate",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Проверил пользователь"),
            accessor: "whoChecked",
            minWidth: 80,
        },
        {
            Header: __("Проверил ФИО"),
            accessor: "whoCheckedFullName",
            minWidth: 80,
        },
        {
            Header: __("Проверил должность"),
            accessor: "whoCheckedPosition",
            minWidth: 80,
        },
        {
            Header: __("Дата проверки"),
            accessor: "checkDate",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("ID транзакции"),
            accessor: "transactionId",
            minWidth: 80,
        },
        {
            Header: __("ID транзакции в ПС"),
            accessor: "transactionPaySysId",
            minWidth: 80,
        },
    ];

    return columns;
};
