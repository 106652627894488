import { createGlobalStyle, css } from "styled-components";
import styledNormalize from "styled-normalize";
import { media } from "services/styled-components/variables";
import {
    StyledInner,
    StyledRootWrapper,
} from "../../components/components-common/modal/styled";

const globalStylesCss = css`
    /* тут должны быть только стили, которые актуальны для всех разделов всех партнерок, например */
    /* styledNormalize и ResetCss */
    ${styledNormalize}

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .ReactModal__Body--open {
        overflow: hidden !important;
    }
    .ReactModal__Content {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 35;
        font-size: 16px;
        font-weight: normal;
        ${media.ltMax`
        font-size: 14px;
    `}
    }
    .ReactModal__Content--after-open {
        ${StyledInner} {
            opacity: 1;
            transform: translateX(0);
        }
    }
    .ReactModal__Content--before-close {
        pointer-events: none;
        ${StyledInner} {
            transform: translateX(100px);
            opacity: 0;
        }
    }
    .ReactModal__Overlay--after-open {
        ${StyledRootWrapper} {
            opacity: 1;
        }
    }
    .ReactModal__Overlay--before-close {
        ${StyledRootWrapper} {
            opacity: 0;
        }
    }
    .style-primary .inner {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
    }
    .style-success .inner {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }
    .style-danger .inner {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
    .size-large .inner {
        max-width: 800px;
    }
    .size-medium .inner {
        max-width: 550px;
    }
    .size-small .inner {
        max-width: 350px;
    }
`;
export const PureGlobalStyles = createGlobalStyle`${globalStylesCss};` as any;
