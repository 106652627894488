import { Field } from "components/formik";
import { useTranslation } from "react-i18next";

enum TypeNameId {
    REG = 1,
    FTD = 2,
    BL = 3,
    RS = 4,
    APP = 5,
}

export const TypeNameIdField = () => {
    const [__] = useTranslation();
    const options = [
        { value: TypeNameId.REG, label: "{reg}" },
        { value: TypeNameId.FTD, label: "{ftd}" },
        { value: TypeNameId.BL, label: "{bl}" },
        {
            value: TypeNameId.RS,
            label: "{rs}",
        },
        { value: TypeNameId.APP, label: "{app}" },
    ];

    return (
        <Field
            name="typeNameId"
            key="typeNameId"
            type="react-select"
            options={options}
            isLoading={false}
            selectFirst={false}
            label={__("Событие")}
            minWidth={200}
        />
    );
};
