import styled, { css } from "styled-components";
import {
    dangerColor,
    adminBorderColor2,
    disabledColor,
} from "services/styled-components/themes";
import { rtl } from "services/styled-components/rtl";

export const CheckboxSC = styled.input<{
    type: "checkbox" | "radio";
    checked: boolean;
}>`
    appearance: none;
    position: absolute;
    opacity: 0;

    & + label {
        cursor: pointer;
        position: relative;
        ${rtl`padding: 0 0 0 1.1em;`};
        ${rtl`text-align: left;`};
        span {
            display: inline-block;
            padding-left: 0.65em;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            ${rtl`left: 0;`};
            border: 1px solid ${adminBorderColor2};
            width: 1em;
            height: 1em;
            ${props =>
                props.type === "radio" &&
                css`
                    border-radius: 50%;
                `}
        }
        &::after {
            position: absolute;
            text-align: center;
            ${rtl`left: 1px;`};
            width: 1em;
            height: 1em;
            transition: all 0.2s, transform 0.2s;
        }
        & + a {
            position: relative;
        }
    }

    ${props =>
        props.checked
            ? css`
                  position: absolute;
                  opacity: 0;
                  & + label {
                      ${props.type === "checkbox" &&
                      css`
                          &::after {
                              content: "\\2714\\fe0e";
                              top: 0;
                          }
                      `}
                      ${props.type === "radio" &&
                      css`
                          &::after {
                              top: 1px;
                              border-radius: 50%;
                              border: 2px solid transparent;
                              box-sizing: border-box;
                              background-clip: content-box;
                              background-color: ${props.theme.primaryColor};
                          }
                      `}
                  }
              `
            : css`
                  & + label::after {
                      content: "";
                  }
                  &.error + label:before {
                      box-shadow: 0px 1px 0px 1px ${dangerColor},
                          0px -1px 0px 1px ${dangerColor},
                          1px 0px 0px 1px ${dangerColor},
                          -1px 0px 0px 1px ${dangerColor} !important;
                  }
              `}

    &:disabled + label::after {
        color: ${disabledColor};
    }
`;
