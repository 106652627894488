import { useTranslation } from "react-i18next";
import { INotificationContentProps } from "../../notification-popup";
import { PopupType } from "../popup-id";
import { ReferencePopupData } from "./reference-popup-data";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import { FormGroupCheckbox, FormGroupButton } from "styled/layouts";
import { Field, Form } from "components/formik";
import { ButtonColored } from "styled/button";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";

interface IProps extends INotificationContentProps {
    data: ReferencePopupData;
}

export const BlockReferenceContent = (props: IProps) => {
    const popUpProps = props.data.props;
    const [__] = useTranslation();

    const checkboxFieldName = "DisableModal" + PopupType[popUpProps.type];

    return (
        <FormikConsoleErrors
            initialValues={{
                [checkboxFieldName]: false,
            }}
            onSubmit={() => {
                // noop
            }}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <Title>{popUpProps.title}</Title>

                    <Paragraph>{popUpProps.description}</Paragraph>
                    <Paragraph>
                        {popUpProps.infoText}
                        <strong>
                            {popUpProps.linkInPopup || props.data.linkInPopup}
                        </strong>
                    </Paragraph>

                    <FormGroupCheckbox>
                        <Field
                            name={checkboxFieldName}
                            type="checkbox"
                            label={__("Больше не показывать")}
                            checked={values[checkboxFieldName]}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setFieldValue(
                                    checkboxFieldName,
                                    e.target.checked,
                                );
                                props.dontShowAgain(e.target.checked);
                            }}
                        />
                    </FormGroupCheckbox>

                    <FormGroupButton>
                        <ButtonColored
                            onClick={props.onRequestClose}
                            type="button"
                        >
                            {__("Закрыть")}
                        </ButtonColored>
                    </FormGroupButton>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
