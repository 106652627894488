import { ReactText, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { SetFieldValue } from "components/formik";
import { ReactTable } from "components/react-table";
import { Search } from "components/search";
import {
    AvailablePartners as IAvailablePartners,
    AvailablePartnersVariables,
    GetCountries_countries,
    AvailablePartners_authorized_manager_main_availablePartners_partners,
} from "gql/types/operation-result-types";
import {
    makeGetTrProps,
    IListItem,
    useControlCheckboxHandlers,
} from "views/manager/main/partners-groups/common";
import AVAILABLE_PARTNERS from "./available-partners.gql";
import { getColumns } from "../columns";

interface IProps {
    fieldName: string;
    selectedPartners: IListItem[];
    currencyId?: ReactText;
    filter?: {
        partnerId?: number | null;
        country?: GetCountries_countries;
    };
    partnerGroupId?: ReactText;
    showPartnersWithoutGroup?: boolean;
    setFieldValue: SetFieldValue;
}

const AvailablePartnersComponent = ({
    fieldName,
    currencyId,
    selectedPartners,
    filter,
    partnerGroupId,
    showPartnersWithoutGroup,
    setFieldValue,
}: IProps) => {
    const [__] = useTranslation();
    const { onAdd, onAddAll } = useControlCheckboxHandlers({
        list: selectedPartners,
        fieldName,
        setFieldValue,
    });
    const externalPaginationAndSorting = usePaginationAndSorting();
    const searchValue = useRef("");
    const { data, loading } = useQuery<
        IAvailablePartners,
        AvailablePartnersVariables
    >(AVAILABLE_PARTNERS, {
        variables: {
            query: externalPaginationAndSorting.state,
            filter: {
                currencyId: currencyId ? Number(currencyId) : null,
                showPartnersWithoutGroup,
                partnerId: filter?.partnerId,
                countryId: filter?.country?.id,
                search: searchValue.current,
                partnerGroupId: partnerGroupId ? Number(partnerGroupId) : null,
            },
        },
        fetchPolicy: "network-only",
        ssr: true,
    });
    const columns =
        getColumns<AvailablePartners_authorized_manager_main_availablePartners_partners>(
            { __, onChange: onAdd, onChangeAll: onAddAll },
        );
    const tableData = data?.authorized.manager.main.availablePartners.partners;
    const dataLength =
        data?.authorized.manager.main.availablePartners.rowsCount;
    const selectedIds = selectedPartners.map(partner => partner.id);

    return (
        <ReactTable
            columns={columns}
            data={tableData}
            dataLength={dataLength}
            loading={loading}
            externalPaginationAndSorting={externalPaginationAndSorting}
            searchComponent={
                <Search
                    onTextChanges={value => {
                        searchValue.current = value;
                    }}
                    onApply={async () => {
                        externalPaginationAndSorting.actions.setPagenum(1);
                    }}
                />
            }
            getTrProps={makeGetTrProps(selectedIds)}
        />
    );
};

export const AvailablePartners = memo(AvailablePartnersComponent);
