import { Schema } from "yup";
import { IBaseFieldProps } from "../services/get-filter-fields";

export const mergeSettings = (
    fields: string[],
    defaultInitialValues: Record<string, unknown>,
    fieldsValidationSchema: Record<string, Schema<unknown>>,
    uniqueValidation: Record<string, Schema<unknown> | undefined> | undefined,
    fieldsList: Record<string, (props: IBaseFieldProps) => JSX.Element>,
    additionalFields:
        | Record<
              string,
              {
                  initialValue: unknown;
                  validationSchema?: Schema<unknown>;
                  component: (props: IBaseFieldProps) => JSX.Element;
              }
          >
        | undefined,
) => {
    // инициилизировать начальные значения в которые будут заполняться все настройки формы
    const initilUniversalFielsSettings: {
        initialValues: Record<string, unknown>;
        validationSchema: Record<string, Schema<unknown>>;
        fieldsComponents: Record<
            string,
            (props: IBaseFieldProps) => JSX.Element
        >;
    } = {
        initialValues: {},
        validationSchema: {},
        fieldsComponents: {},
    };

    // взять начальные значения, валидация и компоненты полей, соотв. переданным полям
    // также учесть уникальную валидацию
    let universalFieldsSettings = fields.reduce((acc, field) => {
        acc.initialValues[field] = defaultInitialValues[field];

        if (fieldsValidationSchema[field]) {
            acc.validationSchema[field] = fieldsValidationSchema[field];
        }
        const fieldUniqueValidation =
            uniqueValidation && uniqueValidation[field];
        if (fieldUniqueValidation) {
            acc.validationSchema[field] = fieldUniqueValidation;
        }
        acc.fieldsComponents[field] = fieldsList[field];
        return acc;
    }, initilUniversalFielsSettings);

    // смержить параметр дополнительных полей с общими
    if (additionalFields) {
        universalFieldsSettings = Object.entries(additionalFields).reduce(
            (acc, [field, value]) => {
                acc.initialValues[field] = value.initialValue;
                if (value.validationSchema) {
                    acc.validationSchema[field] = value.validationSchema;
                }
                acc.fieldsComponents[field] = value.component;
                return acc;
            },
            universalFieldsSettings,
        );
    }
    return universalFieldsSettings;
};
