import {
    ApplyFinPlanStatus,
    ApplyFinPlanStatusVariables,
    FinantialPlanContract,
    FinantialPlanStatus,
    GetCountries_countries,
    GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments,
    GetPlan_authorized_manager_advertising_finantialPlans_plan,
    GetPlan_authorized_manager_advertising_finantialPlans_plan_contract,
    Rights,
    UpdateFinplansContract,
    UpdateFinplansContractVariables,
} from "gql/types/operation-result-types";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { useInitilaValuesAndValidation } from "../../contract-form/use-initilal-values-and-validation";
import { ContractForm } from "../../contract-form";
import { useCountries } from "hooks/query/use-countries";
import { useCurrencyAdversitingPayments } from "hooks/query/use-currency-adversiting-payments";
import { IPlacement } from "../../add-fin-plan-steps/add-contract/add-placement";
import { Title } from "services/styled-components/typogoraphy";
import { Variables } from "services/variables";
import * as moment from "moment";
import UPDATE_FINPLAN from "./edit-contract.gql";
import { useMutation } from "@apollo/client";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { errorHandler } from "services/error-handler";
import { makeUtcCustomPeriod } from "services/project-utils";
import { useTranslation } from "react-i18next";
import { AdminWrapper, FormGroup } from "styled/layouts";
import { ApplyStatusField } from "views/manager/advertising/fin-plan/report-table/apply-status-field";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import APPLY_FINPLAN_STATUS from "views/manager/advertising/fin-plan/gql/apply-finplan-status.gql";
import { ErrorType } from "server/errors/error-type";
import { WrappedApiRequestError } from "server/errors/request-error";
import { useHistory } from "react-router-dom";

function getInitilValues(
    status: FinantialPlanStatus,
    contract: GetPlan_authorized_manager_advertising_finantialPlans_plan_contract,
    countries: GetCountries_countries[] | undefined,
    currencyList: // eslint-disable-next-line max-len
    | GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments[]
        | undefined,
) {
    const getCountry = (id: number | null) =>
        countries?.find(country => country.id === id);
    const country = getCountry(contract.countryId) || null;
    const contractCountry = getCountry(contract.contractCountryId) || null;

    const currency =
        currencyList &&
        currencyList.find(crn => crn.id === contract.advCurrencyId);

    const contractPlacements: IPlacement[] = contract.contractPlacements.map(
        item => {
            return {
                type: item.type,
                typeName: item.typeName || "",
                sum: item.sum,
                percentSum: item.percentSum,
            };
        },
    );

    const filesContent = (contract.filePaths || []).map(path => {
        return {
            name: path.split("/").pop() || "",
            path,
        };
    });

    return {
        contractNumber: contract.contractNumber,
        contractName: contract.contractName,
        contractDuration: {
            interval: "CUSTOM",
            start: moment(contract.dateStart, Variables.dateFormat.seconds),
            end: moment(contract.dateEnd, Variables.dateFormat.seconds),
        },
        country,
        contractCountry,
        paymentSystem: contract.paymentMethod || null,
        contactFullName: contract.contactFullName || "",
        phone: contract.phone || "",
        email: contract.email || "",
        currencyAdversitingPayments: currency || null,
        contractSumm: contract.contractSum,
        filesContent,
        contractPlacements,
        message: contract.message || "",
        company: contract.company?.id || null,
        finplanContractStatus: status,
    };
}

interface IProps {
    plan: GetPlan_authorized_manager_advertising_finantialPlans_plan;
    isCreation?: boolean;
    onFinplanCreated: () => void;
}

export const EditContract = (props: IProps) => {
    const [__] = useTranslation();
    const { validationSchema } = useInitilaValuesAndValidation();
    const { goBack } = useHistory();

    const { countries } = useCountries();
    const { data: currencyList } = useCurrencyAdversitingPayments();
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const { id: finPlanId, contract, status } = props.plan;
    const isAllDisabled =
        status === FinantialPlanStatus.APPROVED ||
        status === FinantialPlanStatus.PAYING;
    const isChangeStatusDisabled = isAllDisabled;
    const isContractFormDisabled =
        isAllDisabled || !permissions?.rights.includes(Rights.CREATE_FIN_PLAN);

    const [updateStatus] = useMutation<
        ApplyFinPlanStatus,
        ApplyFinPlanStatusVariables
    >(APPLY_FINPLAN_STATUS);

    const [updateFinplan] = useMutation<
        UpdateFinplansContract,
        UpdateFinplansContractVariables
    >(UPDATE_FINPLAN);

    return (
        <FormikConsoleErrors
            initialValues={getInitilValues(
                status,
                contract,
                countries,
                currencyList,
            )}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmitWrapper(async (values, formikActions) => {
                try {
                    if (isContractFormDisabled) {
                        await updateStatus({
                            variables: {
                                statusId: values.finplanContractStatus,
                                planId: [finPlanId],
                            },
                        });
                        return goBack();
                    }

                    const { start, end } = makeUtcCustomPeriod(
                        values.contractDuration.start,
                        values.contractDuration.end,
                    );

                    if (!start || !end) {
                        // TODO: throw error ?
                        return;
                    }

                    const filePaths = values.filesContent
                        .filter(item => !!item.path)
                        .map(item => item.path);

                    const editedContract: FinantialPlanContract = {
                        contractNumber: values.contractNumber,
                        contractName: values.contractName,
                        dateStart: start.toISOString(),
                        dateEnd: end.toISOString(),
                        countryId: values.country?.id,
                        contractCountryId: values.contractCountry?.id,
                        paymentMethod: {
                            id: values.paymentSystem.id,
                            fields: values.paymentSystem.fields?.map(
                                ({ id, value }) => ({
                                    id,
                                    value,
                                }),
                            ),
                        },
                        contactFullName: values.contactFullName,
                        phone: values.phone,
                        email: values.email,
                        advCurrencyId: values.currencyAdversitingPayments.id,
                        contractSum: values.contractSumm,
                        contractPlacements: values.contractPlacements || [],
                        message: values.message,
                        companyId: values.company,
                        filePaths,
                        status: values.finplanContractStatus,
                    };

                    await updateFinplan({
                        variables: {
                            planId: finPlanId,
                            contract: editedContract,
                            isCreation: props.isCreation,
                        },
                    });
                    props.onFinplanCreated();
                } catch (ex: any) {
                    void errorHandler(ex, error => {
                        switch (error.data.code) {
                            case ErrorType.IMPOSIBLE_STATUS_CHANGING:
                                return __("Невозможно применить статус");
                            default:
                                return (error as WrappedApiRequestError).data
                                    .details.result?.message;
                        }
                    });
                    formikActions.resetForm();
                    throw ex;
                }
            }, validationSchema)}
        >
            {({ values, setFieldValue }) => (
                <>
                    <AdminWrapper width="32%">
                        <FormGroup>
                            <ApplyStatusField
                                name="finplanContractStatus"
                                disabled={isChangeStatusDisabled}
                                currentStatus={props.plan.status}
                                previousStatus={props.plan.previousStatus}
                            />
                        </FormGroup>
                    </AdminWrapper>
                    <Title>
                        {__(
                            props.isCreation
                                ? "Информация о контракте"
                                : "Редактировать контракт",
                        )}
                    </Title>
                    <ContractForm
                        values={values}
                        setFieldValue={setFieldValue}
                        submitButtonText={__("Сохранить")}
                        company={contract.company || undefined}
                        disabledFields={isContractFormDisabled}
                        disabledButton={
                            isChangeStatusDisabled && isContractFormDisabled
                        }
                        finplanId={finPlanId}
                    />
                </>
            )}
        </FormikConsoleErrors>
    );
};
