import { Field } from "components/formik";
import { useSiteCategoriesWithTranslations } from "hooks/query/query-with-translations/use-site-categories-with-translations";
import { useTranslation } from "react-i18next";
import { ISelectFild } from "../select-filed-interface";
import { useIsSelectOptionsLoading } from "components/filters/hooks/use-is-select-iptions-loading";

export const SiteCategoryField = React.memo((props: ISelectFild) => {
    const { name, ...rest } = props;
    const [__] = useTranslation();

    const { siteCategories, loading } = useSiteCategoriesWithTranslations();

    useIsSelectOptionsLoading(
        name,
        loading,
        siteCategories,
        props.loadingContext,
    );

    return (
        <Field
            name={props.name}
            label={__("Категория сайта")}
            type="react-select"
            options={siteCategories}
            isLoading={loading}
            minWidth={250}
            {...rest}
        />
    );
}) as React.ComponentType<ISelectFild>;
