import { Code } from "@styled-icons/fa-solid/Code/Code";
import { ReactTable } from "components/react-table";
import { LoadingText } from "components/react-table/loading-text";
import {
    GetMedia,
    GetMedia_authorized_partner_media,
    GetMedia_authorized_partner_media_mediaType,
    GetMediaVariables,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetMediaTypes_authorized_partner_data_mediaTypes,
    GetCampaigns_authorized_partnerAndManager_data_campaigns,
    GetLanguages_languages,
} from "gql/types/operation-result-types";
import _ from "lodash";
import React, { useRef } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { BtnColor, ButtonColored } from "styled/button";
import { DownloadMediaFile } from "../download-media-file";
import { GetCodeWithModal, ISetMedia } from "../get-code-with-modal";
import {
    MediaPreviewWithModal,
    useMediaPreviewWidth,
} from "views/partner-manager/media-preview-with-modal";
import GET_MEDIA from "../query.gql";
import { StyledReactTableCell } from "./styled";
import { IReactTableColumn } from "components/react-table/column/";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    submittedValues: {
        mediaName: string | null | undefined;
        width: number | null | undefined;
        height: number | null | undefined;
        currency:
            | GetCurrencies_authorized_partnerAndManager_data_currencies
            | undefined;
        mediaType: GetMediaTypes_authorized_partner_data_mediaTypes | undefined;
        language: GetLanguages_languages | undefined;
        campaign:
            | GetCampaigns_authorized_partnerAndManager_data_campaigns
            | undefined;
    };
}

export const MediaReportTable = React.memo(
    ({ submittedValues: filters }: IProps) => {
        const { currency, mediaType, language, mediaName, campaign } = filters;

        const [__] = useTranslation();
        const queryResult = useQuery<GetMedia, GetMediaVariables>(GET_MEDIA, {
            variables: {
                filter: {
                    currencyId: currency && currency.id,
                    mediaTypeId: mediaType && _.parseInt(mediaType.id),
                    languageId: language && language.id,
                    mediaName,
                    width: filters.width,
                    height: filters.height,
                    campaignId: campaign && _.parseInt(campaign.id),
                },
            },
        });
        const codeComponentRef = useRef<ISetMedia>(null);

        const data = g(queryResult.data, "authorized", "partner", "media");

        const previewWidth = useMediaPreviewWidth();

        const columns: IReactTableColumn[] = [
            {
                Header: __("ID"),
                accessor: "mediaId",
                disabled: true,
                minWidth: 100,
            },
            {
                Header: __("Превью"),
                accessor: "preview",
                Cell: ({ original }) => (
                    <MediaPreviewWithModal
                        previewInsideTable={true}
                        media={original as GetMedia_authorized_partner_media}
                        previewWidth="100%"
                    />
                ),
                minWidth: previewWidth,
            },
            {
                Header: __("Действия"),
                accessor: "htmlCode",
                minWidth: 228,
                Cell: ({ original }) => {
                    return (
                        <StyledReactTableCell>
                            <ButtonColored
                                minWidth={190}
                                width="100%"
                                btnColor={BtnColor.Special}
                                onClick={() => {
                                    if (codeComponentRef.current) {
                                        codeComponentRef.current.setMedia(
                                            original.mediaId as number,
                                            original.mediaType as GetMedia_authorized_partner_media_mediaType,
                                        );
                                    }
                                }}
                            >
                                <Code size={13} />
                                {__("Получить код")}
                            </ButtonColored>
                            <DownloadMediaFile
                                width={"100%"}
                                mediaId={original.mediaId as number}
                            />
                        </StyledReactTableCell>
                    );
                },
            },
            {
                Header: __("Имя медиа"),
                accessor: "mediaName",
                minWidth: 110,
            },
            {
                Header: __("Тип медиа"),
                accessor: "mediaType",
                disabled: true,
                Cell: ({
                    original: {
                        mediaType: { name },
                    },
                }) => <div>{name}</div>,
                minWidth: 70,
            },
            {
                Header: __("Язык"),
                accessor: "languageName",
                minWidth: 110,
            },
            {
                Header: __("Размер"),
                accessor: "width",
                Cell: ({ original: { width, height } }) => (
                    <div>
                        {width} x {height}
                    </div>
                ),
                minWidth: 100,
            },
            {
                Header: __("Валюта"),
                accessor: "currencyName",
                minWidth: 80,
            },
        ];

        return (
            <>
                <ReactTable
                    columns={columns}
                    data={data}
                    loading={queryResult.loading}
                    loadingText={<LoadingText fastLoading={false} />}
                />
                <GetCodeWithModal ref={codeComponentRef} />
            </>
        );
    },
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
