import { Title } from "services/styled-components/typogoraphy";
import { useTranslation } from "react-i18next";
import { SiteList } from "./site-list";

interface IProps {
    siteId: number | undefined | null;
    partnerId: number | undefined;
    chooseSite: (siteId: number) => void;
}

export function ChooseSite({ siteId, partnerId, chooseSite }: IProps) {
    const [__] = useTranslation();
    return (
        <>
            {siteId && (
                <Title>
                    {format(__("Выбранный сайт: {siteId}"), {
                        siteId,
                    })}
                </Title>
            )}
            <SiteList
                partnerId={partnerId}
                chooseSite={(currentSiteId: number) => {
                    chooseSite(currentSiteId);
                }}
            />
        </>
    );
}
