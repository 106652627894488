import { FormikHandlers, FormikState } from "components/formik";

export interface IMultiSelectProps {
    options: Options;
    selectFirst?: boolean;
    isLoading?: boolean;
    form: FormikState<unknown>;
    // вызывается при "начале" изменения значения, и определяет как оно будет изменено (?)
    onChange: FormikHandlers["handleChange"];
    // вызывается по-факту, после изменения значения в селекте
    fieldOnChange?: FormikHandlers["handleChange"];
    name: string;
    id: string;
    isSearchable?: boolean;
    isClearable?: boolean;
    value: string[];
}

type Options = Array<{
    value: { id: string; name: string };
    label: FunctionStringCallback;
    isDisabled: boolean;
}>;

interface IChangeOption {
    onChange: FormikHandlers["handleChange"];
    fieldOnChange?: FormikHandlers["handleChange"];
    selectedOptions: SelectedOption[];
    name: string;
}

export type SelectedOption =
    | {
          value: { id: string; name: string };
          label: FunctionStringCallback;
          isDisabled: boolean;
      }
    | undefined;

export const useSelect = ({
    options,
    onChange,
    fieldOnChange,
    name,
    value,
}: IMultiSelectProps) => {
    const selected = React.useMemo(() => {
        const selectedOptions = options?.filter(option =>
            value?.includes(option.value.id),
        );

        if (!selectedOptions) {
            setTimeout(
                () =>
                    changeOption({
                        name,
                        selectedOptions,
                        onChange,
                        fieldOnChange,
                    }),
                1,
            );
        }

        return selectedOptions;
    }, [options, value, name, onChange, fieldOnChange]);

    return [options, selected];
};

export const changeOption = ({
    onChange,
    fieldOnChange,
    selectedOptions,
    name,
}: IChangeOption) => {
    const getValue = (options?: SelectedOption[]) => {
        if (options) {
            return options.reduce<string[]>((valueIds, option) => {
                const valueId = option?.value.id.toString();
                if (valueId) {
                    valueIds.push(valueId);
                }
                return valueIds;
            }, []);
        }
    };

    const event = {
        target: {
            value: getValue(selectedOptions),
            name,
        },
    };

    if (typeof fieldOnChange === "function") {
        fieldOnChange(event);
    }
    return onChange(event);
};
