import { ReactTable } from "components/react-table";
import { AllUniversalOutputValues } from "components/filters/types";
import { useQuery } from "@apollo/client";
import PAYMENTS_HISTORY from "./query.gql";
import {
    PaymentsHistory,
    PaymentsHistoryVariables,
    SortedPaymentsHistoryType,
} from "gql/types/operation-result-types";
import { makeUtcCustomPeriod } from "services/project-utils";
import { useColumns } from "./use-columns";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { LoadingText } from "components/react-table/loading-text";
import { Export } from "components/react-table/table-select-variables/export";
import { ExportButton } from "./export";

interface IPaymentHistoryReportTable {
    submittedValues: Pick<
        AllUniversalOutputValues,
        "period" | "currency" | "commisionGroup" | "partnersGroup" | "partnerId"
    >;
}

export const PaymentHistoryReportTable = ({
    submittedValues,
}: IPaymentHistoryReportTable) => {
    const externalPaginationAndSorting = usePaginationAndSorting();
    const columns = useColumns(submittedValues.currency.name);
    const { start, end } = makeUtcCustomPeriod(
        submittedValues.period.start,
        submittedValues.period.end,
    );

    const { data, loading } = useQuery<
        PaymentsHistory,
        PaymentsHistoryVariables
    >(PAYMENTS_HISTORY, {
        variables: {
            filter: {
                dateStart: (start && start.toISOString()) || null,
                dateEnd: (end && end.toISOString()) || null,
                currencyId: Number(submittedValues.currency.id),
                commissionGroupId: submittedValues.commisionGroup?.id,
                partnerGroupId: submittedValues.partnersGroup?.id,
                partnerId: submittedValues.partnerId || null,
                ...externalPaginationAndSorting.state,
                sorted: externalPaginationAndSorting.state
                    .sorted as unknown as SortedPaymentsHistoryType,
            },
        },
        notifyOnNetworkStatusChange: true,
    });
    const paymentsHistoryData =
        data?.authorized.manager.payments.paymentsHistory;

    const exportComponent = (
        <Export
            ExportButton={ExportButton}
            filters={{
                currency: submittedValues.currency,
                commisionGroup: submittedValues.commisionGroup,
                partnersGroup: submittedValues.partnersGroup,
                partnerId: submittedValues.partnerId,
                dateStart: start?.toISOString(),
                dateEnd: end?.toISOString(),
            }}
        />
    );

    return (
        <ReactTable
            columns={columns}
            externalPaginationAndSorting={externalPaginationAndSorting}
            loading={loading}
            data={paymentsHistoryData?.paymentHistory || []}
            dataLength={paymentsHistoryData?.rowsCount}
            loadingText={<LoadingText fastLoading={true} />}
            exportComponent={exportComponent}
        />
    );
};
