import { GetHowDidYouKnowOptions } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_HOW_DID_YOU_KNOW_OPTIONS = gql`
    query GetHowDidYouKnowOptions {
        howDidYouKnowOptions {
            id
            name
        }
    }
`;

export const useHowDidYouKnowOptions = () => {
    const howDidYouKnowOptionsList = useQuery<GetHowDidYouKnowOptions>(
        GET_HOW_DID_YOU_KNOW_OPTIONS,
        {
            ssr: true,
        },
    );

    const howDidYouKnowOptions = g(
        howDidYouKnowOptionsList,
        "data",
        "howDidYouKnowOptions",
    );

    return {
        howDidYouKnowOptions,
        loading: howDidYouKnowOptionsList.loading,
        error: howDidYouKnowOptionsList.error,
    };
};
