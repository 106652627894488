import { useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import StateManager from "react-select";
import { FormGroup } from "styled/layouts";
import { Field, useFormikContext } from "components/formik";
import {
    AdvertisingCompanyByName,
    AdvertisingCompanyByNameVariables,
    AdvertisingCompanyByName_authorized_manager_advertising_advertisingPayments_advertisingCompaniesByName,
} from "gql/types/operation-result-types";
import ADVERTISING_COMPANY_BY_NAME from "./query.gql";
import { useTranslationList } from "hooks/use-translations-list";
import { useSearchCompany } from "./use-search-company";

export type AdvertisingCompany =
    AdvertisingCompanyByName_authorized_manager_advertising_advertisingPayments_advertisingCompaniesByName;

interface ICompanyField {
    company?: AdvertisingCompany | null;
    disabled?: boolean;
}

export const CompanyField = ({ company, disabled }: ICompanyField) => {
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const selectRef = useRef<StateManager>(null);

    const { errors } = useFormikContext<{
        search?: string;
    }>();

    const { companiesOptions, getOptioinsCompanies } = useSearchCompany({
        company,
    });

    const [getCompanies, { loading }] = useLazyQuery<
        AdvertisingCompanyByName,
        AdvertisingCompanyByNameVariables
    >(ADVERTISING_COMPANY_BY_NAME);

    const searchValidateError = __("Введите первые три буквы компании");

    return (
        <>
            {!disabled && (
                <FormGroup>
                    <Field
                        showErrorOnChange
                        name="search"
                        type="text"
                        label={__("Поиск")}
                        onKeyDown={async (
                            e: React.KeyboardEvent<HTMLInputElement>,
                        ) => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                const partOfName = e.currentTarget.value;
                                if (errors.search !== searchValidateError) {
                                    const dataResult = await getCompanies({
                                        variables: {
                                            partOfName,
                                        },
                                    });
                                    const resutCompanies =
                                        dataResult.data?.authorized.manager
                                            .advertising.advertisingPayments
                                            .advertisingCompaniesByName;
                                    getOptioinsCompanies(resutCompanies);
                                    selectRef.current?.focus();
                                }
                            }
                        }}
                        validate={value => {
                            const result = new RegExp(/^[\w\d]{3}$/).test(
                                value,
                            );
                            if (value && !result) {
                                return searchValidateError;
                            }
                        }}
                    />
                </FormGroup>
            )}
            <FormGroup>
                <Field
                    name="company"
                    label={__("Компания")}
                    type="react-select"
                    innerRef={selectRef}
                    openMenuOnFocus
                    options={companiesOptions}
                    isClearable={false}
                    isDisabled={disabled || loading}
                    validate={value => {
                        if (!value) {
                            return FIELD_SHOULD_BE_FILLED();
                        }
                    }}
                />
            </FormGroup>
        </>
    );
};
