import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form } from "components/formik";
import { FormDirectionRow, FormGroup, FormGroupButton } from "styled/layouts";
import { Field } from "components/formik";
import { FakeLabel } from "components/filters/form";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { useTranslation } from "react-i18next";
import { Period } from "components/filters/fields/period";
import { useValidationSchema } from "./validation-scheme";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { Variables } from "services/variables";
import {
    AdvertisingPaymentType,
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
} from "gql/types/operation-result-types";
import * as moment from "moment";
import {
    AddOrEditPaymentPlanModal,
    submitValues,
} from "../../edit-fin-plan-steps/edit-adv-payments-plans/add-or-edit-payment-plan-modal";
import { useModalState } from "hooks/use-modal-state";
import { ModalSize } from "components/components-common/modal/modal-size";
import { ButtonColored } from "styled/button";

function getInitilValues(
    // eslint-disable-next-line max-len
    paymentPlan?: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
) {
    return {
        type: paymentPlan?.type || AdvertisingPaymentType.CORRECTION,
        amount: paymentPlan?.amount || "",
        paymentDateTime: paymentPlan
            ? moment(paymentPlan.paymentDateTime, Variables.dateFormat.seconds)
            : null,
        paymentPlanDuration: {
            interval: paymentPlan ? "CUSTOM" : "",
            start: paymentPlan
                ? moment(paymentPlan.start, Variables.dateFormat.seconds)
                : null,
            end: paymentPlan
                ? moment(paymentPlan.end, Variables.dateFormat.seconds)
                : null,
        },
    };
}

export interface SubmitValues {
    type: AdvertisingPaymentType;
    start: string;
    end: string;
    amount: number;
    paymentDateTime: string;
}

export interface SubmitValuesWithReset {
    submittedPaymentPlanValues: SubmitValues;
    resetForm?: () => void;
}

export const PaymentPlanForm = ({
    onSubmit,
    submitButtonText,
    paymentPlan,
    onCompareSumms,
    onCancel,
}: {
    onSubmit: ({
        submittedPaymentPlanValues,
        resetForm,
    }: SubmitValuesWithReset) => Promise<void>;
    onCancel?: () => void;
    submitButtonText?: string;
    // eslint-disable-next-line max-len
    paymentPlan?: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans;
    onCompareSumms?: (currentAmount: number) => boolean;
}) => {
    const [__] = useTranslation();
    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });
    const formatSeconds = Variables.dateFormat.seconds;

    const validationSchema = useValidationSchema();

    const optionsType = [
        { value: AdvertisingPaymentType.MAIN, label: __("Основной") },
        { value: AdvertisingPaymentType.CORRECTION, label: __("Корекция") },
    ];

    return (
        <>
            <FormikConsoleErrors
                initialValues={getInitilValues(paymentPlan)}
                validationSchema={validationSchema}
                onSubmit={onSubmitWrapper(async (values, { resetForm }) => {
                    try {
                        const start =
                            values.paymentPlanDuration.start.format(
                                formatSeconds,
                            );
                        const end =
                            values.paymentPlanDuration.end.format(
                                formatSeconds,
                            );
                        const paymentDateTime =
                            values.paymentDateTime.format(formatSeconds);

                        const submittedPaymentPlanValues = {
                            type: values.type,
                            start,
                            end,
                            amount: values.amount,
                            paymentDateTime,
                        };

                        const isSummMoreResult = onCompareSumms
                            ? onCompareSumms(values.amount)
                            : undefined;

                        if (isSummMoreResult) {
                            submitValues.setValues({
                                submittedPaymentPlanValues,
                                resetForm,
                            });
                            open();
                        } else {
                            void onSubmit({
                                submittedPaymentPlanValues,
                                resetForm,
                            });
                        }
                    } catch (ex: any) {
                        await errorHandler(ex, error => {
                            switch (error.data.code) {
                                case ErrorType.VALIDATION_ERROR:
                                    throw ex;
                            }
                        });
                    }
                }, validationSchema)}
            >
                <Form>
                    <FormDirectionRow>
                        <FormGroup>
                            <Field
                                name="type"
                                label={__("Тип платежа")}
                                type="react-select"
                                options={optionsType}
                                isClearable={false}
                            />
                        </FormGroup>
                        <Period
                            name="paymentPlanDuration"
                            showPeriods={false}
                            label={__("Период платежа")}
                        />
                        <FormGroup>
                            <Field
                                name="amount"
                                type="text"
                                label={__("Плановая сумма")}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Field
                                name="paymentDateTime"
                                type="date-single-picker"
                                label={__("Плановая дата")}
                            />
                        </FormGroup>
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>{submitButtonText}</SubmitButton>
                        </FormGroupButton>
                        {onCancel && (
                            <FormGroupButton>
                                <FakeLabel />
                                <ButtonColored type="button" onClick={onCancel}>
                                    {__("Отменить")}
                                </ButtonColored>
                            </FormGroupButton>
                        )}
                    </FormDirectionRow>
                </Form>
            </FormikConsoleErrors>
            <AddOrEditPaymentPlanModal
                modalProps={modalProps}
                close={close}
                onSubmit={onSubmit}
            />
        </>
    );
};
