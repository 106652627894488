import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";

interface IProps {
    partnerId?: number;
}

export const SelectedPartner = ({ partnerId }: IProps) => {
    const [__] = useTranslation();

    if (!partnerId) {
        return null;
    }

    return (
        <Title>
            {format(__("Выбранный партнер: {partnerId}"), {
                partnerId,
            })}
        </Title>
    );
};
