import { Switch, useRouteMatch } from "react-router-dom";
import urljoin from "url-join";
import { PageRoute } from "components/check-roles/guard";
import { useTranslation } from "react-i18next";
import { QuickReport } from "./quick-report";
import { FullReport } from "./full-report";

export const Reports = () => {
    const [__] = useTranslation();
    const { url } = useRouteMatch();

    return (
        <Switch>
            <PageRoute
                path={urljoin(url, "quick")}
                component={QuickReport}
                pageTitle={__("Краткий суммарный")}
            />
            <PageRoute
                path={urljoin(url, "full")}
                component={FullReport}
                pageTitle={__("Полный отчет")}
            />
        </Switch>
    );
};
