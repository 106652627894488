import styled from "styled-components";

export const PostbackWrapper = styled.div`
    width: 50%;
    @media screen and (max-width: 1500px) {
        width: 100%;
    }
`;

export const Description = styled.span`
    font-size: 0.8rem;
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
`;
