import {
    CreateAdvertisingPaymentPlan,
    CreateAdvertisingPaymentPlanVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import CREATE_ADVERTISING_PAYMENT_PLAN from "./create.gql";
import { PaymentPlanForm, SubmitValuesWithReset } from "../payment-plan-form";
import { useTranslation } from "react-i18next";

export const CreateAdvPaymentPlan = ({
    finPlanId,
    onCreatePlan,
    onCompareSumms,
}: {
    finPlanId: number;
    onCreatePlan: (idPaymentPlan: string | undefined) => Promise<void>;
    onCompareSumms?: (currentAmount: number) => boolean;
}) => {
    const [__] = useTranslation();

    const [mutation] = useMutation<
        CreateAdvertisingPaymentPlan,
        CreateAdvertisingPaymentPlanVariables
    >(CREATE_ADVERTISING_PAYMENT_PLAN);

    return (
        <PaymentPlanForm
            onSubmit={async ({
                submittedPaymentPlanValues,
                resetForm,
            }: SubmitValuesWithReset) => {
                const { data } = await mutation({
                    variables: {
                        planId: finPlanId,
                        paymentPlan: submittedPaymentPlanValues,
                    },
                });
                const idPaymentPlan =
                    data?.authorized.manager.advertising.finantialPlans
                        .createAdversitingPaymentPlan.id;
                void onCreatePlan(idPaymentPlan);
                if (resetForm) {
                    resetForm();
                }
            }}
            submitButtonText={__("Добавить платеж в план")}
            onCompareSumms={onCompareSumms}
        />
    );
};
