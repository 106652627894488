import {
    FormikConfig,
    FormikErrors,
    FormikValues,
    useFormik,
} from "components/formik";

export const useFormikConsoleErrors = <Values extends FormikValues>(
    props: FormikConfig<Values>,
) => {
    const formik = useFormik(props);
    const { errors, submitCount } = formik;
    useConsoleErrors(errors, submitCount);
    return formik;
};

export const useConsoleErrors = <Values extends FormikValues>(
    errors: FormikErrors<Values>,
    submitCount: number,
) => {
    React.useEffect(() => {
        if (
            !__ENVIRONMENT__.production &&
            submitCount > 0 &&
            Object.keys(errors).length > 0
        ) {
            // eslint-disable-next-line no-console
            log.error(errors);
        }
    }, [errors, submitCount]);
};
