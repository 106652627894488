import styled from "styled-components";

export const StyledInfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    a {
        &:hover {
            color: ${props => props.theme.accentColor};
        }
    }
`;
