import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Steps } from "components/steps";
import { BackNextButtons } from "components/steps/buttons";
import { useQuery } from "@apollo/client";
import GET_PLAN from "views/manager/advertising/fin-plan/gql/get-plan.gql";
import {
    FinantialPlanStatus,
    GetPlan,
    GetPlanVariables,
} from "gql/types/operation-result-types";
import { Loader } from "views/manager/advertising/fin-plan/loader";
import { SelectedPartner } from "./selected-partner";
import { EditAdvPaymentsPlans } from "./edit-adv-payments-plans";
import { EditContract } from "./edit-contract";
import { useLocalizedAbsolutePush } from "../../../../../hooks/use-localised-absolute-push";
import { PickCurrentLineContext } from "../../../../../components/get-active-link-context";

interface IProps {
    match: {
        params: {
            id: string;
        };
    };
}

export const EditFinPlanSteps = ({ match }: IProps) => {
    const [__] = useTranslation();
    const push = useLocalizedAbsolutePush();
    const { setColumnData } = useContext(PickCurrentLineContext);

    const id = Number(match.params.id);
    const queryResult = useQuery<GetPlan, GetPlanVariables>(GET_PLAN, {
        variables: {
            id,
        },
        ssr: true,
    });
    const plan =
        queryResult.data?.authorized.manager.advertising.finantialPlans.plan;
    const allowPaymentPlansStep =
        plan &&
        (plan.status === FinantialPlanStatus.APPROVED ||
            plan.status === FinantialPlanStatus.PAYING);
    const [step, setStep] = useState(allowPaymentPlansStep ? 2 : 1);
    useEffect(() => {
        setStep(allowPaymentPlansStep ? 2 : 1);
    }, [allowPaymentPlansStep]);

    if (!plan) {
        return <Loader />;
    }

    const stepComponents = [
        <SelectedPartner key="step-0" partnerId={plan.partnerId} />,
        <EditContract
            key="step-1"
            plan={plan}
            onFinplanCreated={() => {
                void queryResult.refetch();
                if (allowPaymentPlansStep) {
                    setStep(step + 1);
                } else {
                    setColumnData({
                        name: "id",
                        id: plan?.id,
                        tableName: "finplans",
                    });
                    push("/agent/advertising/fin-plans");
                }
            }}
        />,
    ];
    const stepTitles = [
        __("Посмотреть партнера"),
        __("Редактировать контракт"),
    ];

    if (allowPaymentPlansStep) {
        stepComponents.push(
            <EditAdvPaymentsPlans
                key="step-2"
                finplan={plan}
                queryResult={queryResult}
            />,
        );
        stepTitles.push(__("Редактировать план платежи"));
    }

    return (
        <>
            <Steps
                titleText={__("Финансовый план:")}
                stepTitles={stepTitles}
                currentStep={step}
            />
            {stepComponents[step]}
            <BackNextButtons
                step={step}
                setStep={setStep}
                numberOfSteps={stepComponents.length}
                canGoNext={(step === 1 && allowPaymentPlansStep) || step !== 1}
                canGoBack={true}
            />
        </>
    );
};
