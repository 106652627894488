import { User } from "gql/types/operation-result-types";
import { useUser } from "hooks/query/use-user";
import { ReactElement } from "react";
import { UserExists } from "./exists";
import { RedirectIfUserExists } from "./redirect-if-exists";
import { RedirectIfUserNotExists } from "./redirect-if-not-exists";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    children: (
        user: NonNullable<User["user"]> | undefined,
        loading: boolean,
    ) => ReactElement | null;
}

export const UserConsumer = React.memo((props: IProps) => {
    const { user, loading } = useUser();

    return props.children(user || undefined, loading);
}) as any as React.ComponentType<Subtract<IProps, IInjectedProps>> & {
    Exists: typeof UserExists;
    RedirectIfExists: typeof RedirectIfUserExists;
    RedirectIfNotExists: typeof RedirectIfUserNotExists;
};

UserConsumer.Exists = UserExists;
UserConsumer.RedirectIfExists = RedirectIfUserExists;
UserConsumer.RedirectIfNotExists = RedirectIfUserNotExists;
