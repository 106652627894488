import { LoadingText } from "components/react-table/loading-text";
import {
    ManagerFullReport,
    ManagerFullReportVariables,
} from "gql/types/operation-result-types";
import { Export } from "../report-table/full-report/index";
import { useReportColumns } from "views/partner-manager/report-columns";
import { useTranslation } from "react-i18next";
import { QueryResult } from "@apollo/client";
import { ReportReactTable } from "components/react-table/report-react-table";
import { generalQueryParams, SubmitValues } from "../general-query-params";
import { ITablePagination } from "hooks/use-pagination";

interface IProps {
    actualValues?: SubmitValues;
    queryResult?: QueryResult<ManagerFullReport, ManagerFullReportVariables>;
    externalPaginationAndSorting: ITablePagination;
}

export const FullReportEmptyTable = ({
    actualValues,
    queryResult,
    externalPaginationAndSorting,
}: IProps) => {
    const [__] = useTranslation();

    const columns = useReportColumns(actualValues?.currency?.name || "");
    columns.unshift({
        Header: __("ID партнера"),
        accessor: "partnerId",
        disabled: true,
        minWidth: 80,
    });
    columns.push({
        Header: __("Соотношение регистрации/Установки"),
        accessor: "registrationsToInstallationsRatio",
        dataType: "percent",
        minWidth: 100,
    });
    columns.push({
        Header: __("Уникальные установки"),
        accessor: "uniqueInstallations",
        minWidth: 100,
    });

    return (
        <ReportReactTable
            exportComponent={
                actualValues && (
                    <Export filters={generalQueryParams(actualValues)} />
                )
            }
            reportName="Full"
            columns={columns}
            shownColumnsByDefault={[
                "partnerId",
                "siteId",
                "siteName",
                "countOfRegistrations",
                "depositAmount",
                "bonusAmount",
                "profit",
                "comissionRS",
            ]}
            externalPaginationAndSorting={externalPaginationAndSorting}
            queryResult={queryResult}
            data={
                queryResult?.data?.authorized.manager.reports.fullReport.rows ||
                []
            }
            total={
                queryResult?.data?.authorized.manager.reports.fullReport.total
            }
            reportStatus={
                queryResult?.data?.authorized.manager.reports.fullReport.status
            }
            dataLength={
                queryResult?.data?.authorized.manager.reports.fullReport
                    .pagesCount
            }
            loadingText={<LoadingText fastLoading={true} />}
        />
    );
};
