import { Access } from "components/check-roles/access";
import { anyRole, adAgent, manager } from "components/check-roles/rules";
import { LinkToUserProfile } from "components/menu/menu-user/link-to-user-profile";
import { SignOutComponent } from "components/menu/menu-user/sign-out";
import { SignOutManager } from "components/menu/menu-user/sign-out-manager";

export const ProfileMenuOptions = React.memo(() => {
    return (
        <ul>
            <Access guards={[anyRole([adAgent, manager])]}>
                <li>
                    <SignOutManager />
                </li>
            </Access>

            <li>
                <LinkToUserProfile />
            </li>
            <li>
                <SignOutComponent />
            </li>
        </ul>
    );
});
