import { QueryResult, useQuery } from "@apollo/client";
import { ReactElement } from "react";
import { DocumentNode } from "graphql";
import { ITablePagination } from "hooks/use-pagination";

export interface QueryResultFilterType<QueryFilterVariablesType, SortedType> {
    filter: QueryFilterVariablesType & {
        pageNumber?: number;
        sorted?: SortedType;
        countOnPage?: number;
    };
}

export interface QueryTableProps<
    FormValuesType,
    QueryResultType,
    QueryFilterVariablesType,
    SortedType,
> {
    actualValues?: FormValuesType;
    emptyTable: (props: {
        actualValues?: FormValuesType;
        queryResult?: QueryResult<
            QueryResultType,
            QueryResultFilterType<QueryFilterVariablesType, SortedType>
        >;
        externalPaginationAndSorting: ITablePagination;
    }) => ReactElement;
    QUERY: DocumentNode;
    transfromToQueryParams: (
        submittedValues: FormValuesType,
    ) => QueryFilterVariablesType;
}

export const QueryTable = <
    FormValuesType,
    QueryResultType,
    QueryFilterVariablesType,
    SortedType,
>(
    props: QueryTableProps<
        FormValuesType,
        QueryResultType,
        QueryFilterVariablesType,
        SortedType
    > & {
        submittedValues: FormValuesType;
        externalPaginationAndSorting: ITablePagination;
    },
) => {
    const queryResult = useQuery<
        QueryResultType,
        QueryResultFilterType<QueryFilterVariablesType, SortedType>
    >(props.QUERY, {
        variables: {
            filter: {
                ...props.transfromToQueryParams(props.submittedValues),
                ...props.externalPaginationAndSorting.state,
                sorted: props.externalPaginationAndSorting.state
                    .sorted as unknown as SortedType,
            },
        },
        skip: !props.submittedValues,
    });

    return props.emptyTable({
        actualValues: props.actualValues,
        queryResult,
        externalPaginationAndSorting: props.externalPaginationAndSorting,
    });
};
