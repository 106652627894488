import { WrapperFullWidth, InfoPageWrapper } from "styled/layouts";
import { ContactsInformation } from "../../../landing/contacts/contacts-information";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Contacts = React.memo((props: IProps) => {
    return (
        <WrapperFullWidth>
            <InfoPageWrapper>
                <ContactsInformation />
            </InfoPageWrapper>
        </WrapperFullWidth>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
