import { User_user } from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import moment from "moment";
import { getCurrencyById } from "services/project-utils";

interface ITranslator {
    culture: string;
    currencyId: number;
    endPeriod?: string | null;
    startPeriod?: string | null;
    user: User_user;
}

export const getCommonTableTranslator = (
    { culture, currencyId, endPeriod, startPeriod, user }: ITranslator,
    __: TFunction,
) => ({
    dateReport: moment().locale(culture).format("L"),
    period: {
        header: __("Период"),
        value:
            endPeriod &&
            startPeriod &&
            `${moment(startPeriod).locale(culture).format("L")} - ${moment(
                endPeriod,
            )
                .locale(culture)
                .format("L")}`,
    },
    affiliateId: {
        header: __("ID партнера"),
        value: user.affiliateId,
    },
    currency: {
        header: __("Валюта"),
        value: getCurrencyById(currencyId),
    },
});
