import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { Field } from "components/formik";
import { FormGroup } from "styled/layouts";
import { useDefinedContext } from "hooks/use-context-exist";
import { useCurrency } from "hooks/query/use-currency";
import { CountryField, LanguageField } from "components/filters/fields";
import { StatusField } from "views/manager/main/partner-list/status-field";
import { FormRow } from "views/manager/main/partner-list/edit-partner/edit-partner-tabs/profile/styled";

export const GeneralInfo = () => {
    const [__] = useTranslation();
    const { data: currencies, loading: currenciesLoading } = useCurrency();
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const currencyOptions = useMemo(
        () =>
            currencies?.map(currency => ({
                value: currency,
                label: currency.name,
            })),
        [currencies],
    );

    return (
        <>
            <FormRow>
                <Field
                    name="registrationDate"
                    type="text"
                    label={__("Дата регистрации")}
                    disabled
                />
                <StatusField name="status" placeholder="" isClearable={false} />
            </FormRow>
            <FormRow>
                <Field
                    name="registrationSource"
                    type="text"
                    label={__("Источник регистрации")}
                    disabled
                />
                <CountryField
                    name="country"
                    key="country"
                    isClearable={false}
                />
            </FormRow>
            <FormRow>
                <Field
                    name="players"
                    type="text"
                    label={__("Игроков")}
                    disabled
                />
                <LanguageField
                    name="language"
                    label={__("Язык")}
                    isClearable={false}
                />
            </FormRow>
            <FormGroup>
                <Field
                    name="currencies"
                    type="react-multi-select"
                    label={__("Валюта")}
                    options={currencyOptions}
                    isLoading={currenciesLoading}
                />
            </FormGroup>
            <FormRow>
                <Field
                    name="partnerSiteId"
                    type="text"
                    label={`${__("Привлечен партнером")} (Site ID)`}
                    disabled={!permissions?.editIntroducedByField}
                />
                <Field
                    name="partnerId"
                    type="text"
                    label={__("Привлечен партнером")}
                />
            </FormRow>
            <Field name="note" type="textarea" label={__("Заметка")} rows={6} />
        </>
    );
};
