import { IReactTableColumn } from "components/react-table/column";
import {
    AdvertisingPaymentType,
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
} from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { getStatusName } from "services/project-utils";
import { initialValues } from "../initial-values";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";
import { CellWithButtons } from "components/table-item";
import { SetPaymentPlanIdProps } from "../../../../payment-plans-operations/delete-payment-plan-modal";
import { showEditButton } from "views/manager/advertising/adversiting-payments/report-table/report-table-columns/show-edit-button";
import {
    CreateAdvertisingPaymentButton,
    DeletePaymentPlanButton,
    EditConnectedAdvertisingButton,
    GoToPaymentListForConnectionButton,
    ViewConnectedAdvertisingButton,
} from "views/manager/advertising/fin-plan/buttons";
import { EditOrViewAdvertisingPayment } from "views/manager/advertising/fin-plan/payment-plans-operations/hooks/use-payment-connect-edit-view-states";
import { AdversitingAction } from "views/manager/advertising/adversiting-payments/view-edit-steps";

interface UseColumnsProps {
    paymentPlanDeleteComponentRef: React.MutableRefObject<
        SetPaymentPlanIdProps | undefined | null
    >;
    οnCreateAdvertisingPayment: (
        // eslint-disable-next-line max-len
        paymentPlan: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
    ) => void;
    isOtherActionsDisabled: boolean;
    onEditPaymentPlan: (
        // eslint-disable-next-line max-len
        paymentPlan: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
    ) => void;
    onConnectToPayment: (
        // eslint-disable-next-line max-len
        paymentPlan: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
    ) => void;
    onEditOrViewAdvertisingPayment: (
        data: EditOrViewAdvertisingPayment,
    ) => void;
    canEdit?: boolean;
}

export type GenerateAdvPaymentsValues = typeof initialValues;

export function useColumns(props: UseColumnsProps) {
    const {
        paymentPlanDeleteComponentRef,
        isOtherActionsDisabled,
        onEditPaymentPlan,
        onConnectToPayment,
        onEditOrViewAdvertisingPayment,
        canEdit,
    } = props;
    const [__] = useTranslation();

    function getTypeName(type: AdvertisingPaymentType) {
        const typeMap = new Map([
            [AdvertisingPaymentType.MAIN, __("Основной")],
            [AdvertisingPaymentType.CORRECTION, __("Коррекция")],
        ]);

        return typeMap.get(type) || "";
    }

    const columns: IReactTableColumn[] = [
        {
            Header: __("Номер"),
            accessor: "order",
            minWidth: 120,
            Cell: ({
                original,
            }: {
                // eslint-disable-next-line max-len
                original: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans;
            }) => {
                const buttons = (
                    <>
                        {!original.advPaymentId && canEdit && (
                            <>
                                <ButtonColoredWithIcon
                                    btnColor={BtnColor.Faded}
                                    title={__("Редактировать")}
                                    type="button"
                                    onClick={() => onEditPaymentPlan(original)}
                                    disabled={isOtherActionsDisabled}
                                >
                                    <EditOutline size={15} />
                                </ButtonColoredWithIcon>
                                <DeletePaymentPlanButton
                                    onClick={() => {
                                        if (
                                            paymentPlanDeleteComponentRef.current
                                        ) {
                                            paymentPlanDeleteComponentRef.current.setPaymentPlanId(
                                                String(original.id),
                                            );
                                        }
                                    }}
                                    disabled={isOtherActionsDisabled}
                                />
                                <GoToPaymentListForConnectionButton
                                    onClick={() => {
                                        onConnectToPayment(original);
                                    }}
                                    disabled={isOtherActionsDisabled}
                                />
                                <CreateAdvertisingPaymentButton
                                    onClick={() => {
                                        props.οnCreateAdvertisingPayment(
                                            original,
                                        );
                                    }}
                                    disabled={isOtherActionsDisabled}
                                />
                            </>
                        )}

                        {original.advPaymentId && (
                            <>
                                <ViewConnectedAdvertisingButton
                                    onClick={() =>
                                        onEditOrViewAdvertisingPayment({
                                            paymentPlan: original,
                                            adversitingAction:
                                                AdversitingAction.View,
                                        })
                                    }
                                />
                                {canEdit && (
                                    <>
                                        {original.advPaymentStatus &&
                                            showEditButton({
                                                statusName:
                                                    original.advPaymentStatus,
                                            }) && (
                                                <EditConnectedAdvertisingButton
                                                    onClick={() =>
                                                        onEditOrViewAdvertisingPayment(
                                                            {
                                                                paymentPlan:
                                                                    original,
                                                                adversitingAction:
                                                                    AdversitingAction.Edit,
                                                            },
                                                        )
                                                    }
                                                />
                                            )}
                                        <GoToPaymentListForConnectionButton
                                            onClick={() => {
                                                onConnectToPayment(original);
                                            }}
                                            disabled={isOtherActionsDisabled}
                                            rebind
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>
                );
                return (
                    <CellWithButtons
                        button={buttons}
                        link={false}
                        text={original.order.toString()}
                    />
                );
            },
        },
        {
            Header: __("Тип платежа"),
            accessor: "type",
            minWidth: 80,
            Cell: ({ original }) => <>{getTypeName(original.type)}</>,
        },
        {
            Header: __("Дата начала"),
            accessor: "start",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Дата окончания"),
            accessor: "end",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Плановая сумма"),
            accessor: "amount",
            minWidth: 80,
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Плановая дата"),
            accessor: "paymentDateTime",
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("ID выплаты"),
            accessor: "advPaymentId",
            minWidth: 80,
        },
        {
            Header: __("Сумма выплаты"),
            accessor: "advPaymentAmount",
            minWidth: 80,
        },
        {
            Header: __("Валюта выплаты"),
            accessor: "advPaymentCurrencyName",
            minWidth: 80,
        },
        {
            Header: __("Статус"),
            accessor: "advPaymentStatus",
            minWidth: 80,
            Cell: ({ original }) => (
                <>{getStatusName(original.advPaymentStatus, __)}</>
            ),
        },
    ];

    return columns;
}
