import { ModalSize } from "components/components-common/modal/modal-size";
import { ModalStyle } from "components/components-common/modal/modal-style";
import { useState } from "react";

interface UseModalStateProps {
    style?: ModalStyle;
    size?: ModalSize;
    name?: string;
}

export function useModalState({ style, size, name }: UseModalStateProps = {}) {
    const [isOpen, setOpen] = useState(false);
    const [styleState, setStyle] = useState<ModalStyle | undefined>(style);
    const [sizeState, setSize] = useState<ModalSize | undefined>(size);
    const open = () => {
        setOpen(true);
    };
    const close: (event?: React.MouseEvent<HTMLElement>) => void = () => {
        setOpen(false);
        if (name) {
            log.info(`${name} closed`);
        }
    };
    return {
        open,
        close,
        setStyle,
        setSize,
        modalProps: {
            isOpen,
            onRequestClose: close,
            modalSize: sizeState,
            modalStyle: styleState,
        },
    };
}
