import styled from "styled-components";

export const RelativeBlock = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const ShieldWithCursor = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
`;
