import { useTranslation } from "react-i18next";

interface IInjectedProps {}
interface IProps extends IInjectedProps {
    fastLoading: boolean;
}

export const LoadingText = (props: IProps) => {
    const [__] = useTranslation();
    return props.fastLoading ? (
        <div>
            <p>{__("Загрузка...")}</p>
        </div>
    ) : (
        <div>
            <p>
                {__(
                    "Отчет формируется.... Этот процесс может занять более 15 минут. Вы можете продолжать пользоваться сайтом",
                )}
            </p>
        </div>
    );
};
