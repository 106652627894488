import {
    FinantialPlanStatus,
    GetPlan,
    GetPlanVariables,
    GetPlan_authorized_manager_advertising_finantialPlans_plan,
} from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";
import { ReactTable } from "components/react-table";
import { useColumns } from "./generate-form/use-columns";
import { GenerateForm } from "./generate-form";
import { useContext } from "react";
import { EditAdvPaymentPlan } from "../../payment-plans-operations/edit-adv-payment-plan";
import { QueryResult } from "@apollo/client";
import { DeletePaymentPlanModal } from "../../payment-plans-operations/delete-payment-plan-modal";
import { ToFinplans } from "./to-finplans";
import { CreatePaymentForPaymentPlan } from "../../payment-plans-operations/create-payment-for-pan-payment";
import { ConnectPaymentToFinPlan } from "../../payment-plans-operations/connect-payment-to-fin-plan";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { usePaymentConnectEditViewStates } from "../../payment-plans-operations/hooks/use-payment-connect-edit-view-states";
import { ViewAndEditPaymentFromFinPlan } from "../../payment-plans-operations/edit-avertising-payment-from-fin-plan";
import { CreateAdvPaymentPlan } from "../../payment-plans-operations/create-adv-payment-plan";
import { LogFinplanTable } from "./log-finplan-table";
import { onCompareSumms } from "../../payment-plans-operations/compare-summs";

interface IProps {
    finplan: GetPlan_authorized_manager_advertising_finantialPlans_plan;
    queryResult: QueryResult<GetPlan, GetPlanVariables>;
}

export const EditAdvPaymentsPlans = ({ finplan, queryResult }: IProps) => {
    const {
        paymentPlanToConnect,
        setPaymentPlanToConnect,
        editPaymentPlan,
        setEditPaymentPlan,
        paymentPlanToCreatePayment,
        setPaymentPlanToCreatePayment,
        paymentPlanDeleteComponentRef,
        editOrViewPayment,
        setEditOrViewPayment,
    } = usePaymentConnectEditViewStates();

    const canEdit =
        finplan.status === FinantialPlanStatus.APPROVED ||
        finplan.status === FinantialPlanStatus.PAYING;
    const canGeneratePlanPayments = canEdit && !finplan.paymentPlans?.length;

    const columns = useColumns({
        onConnectToPayment: paymentPlan => setPaymentPlanToConnect(paymentPlan),
        οnCreateAdvertisingPayment: paymentPlan => {
            setPaymentPlanToCreatePayment(paymentPlan);
        },
        onEditPaymentPlan: paymentPlan => {
            setEditPaymentPlan(paymentPlan);
            setColumnData({
                name: "order",
                id: paymentPlan.order,
                tableName: "adv-payments-plans-edit",
            });
        },
        paymentPlanDeleteComponentRef,
        isOtherActionsDisabled: !!editPaymentPlan,
        onEditOrViewAdvertisingPayment: stateToEditOrView =>
            setEditOrViewPayment(stateToEditOrView),
        canEdit,
    });
    const { setColumnData } = useContext(PickCurrentLineContext);

    if (editOrViewPayment) {
        return (
            <ViewAndEditPaymentFromFinPlan
                finPlanId={finplan.id}
                stateToEditOrView={editOrViewPayment}
                setPaymentPlans={() => {
                    void queryResult.refetch();
                    setEditOrViewPayment(undefined);
                }}
            />
        );
    }

    if (paymentPlanToConnect) {
        return (
            <ConnectPaymentToFinPlan
                paymentPlanToConnect={paymentPlanToConnect}
                finPlanId={finplan.id}
                setPaymentPlans={() => {
                    void queryResult.refetch();
                    setColumnData({
                        name: "order",
                        id: paymentPlanToConnect.order,
                        tableName: "adv-payments-plans-edit",
                    });
                    setPaymentPlanToConnect(undefined);
                }}
            />
        );
    }

    if (paymentPlanToCreatePayment) {
        return (
            <CreatePaymentForPaymentPlan
                finplan={finplan}
                paymentPlan={paymentPlanToCreatePayment}
                setPaymentPlans={() => {
                    void queryResult.refetch();
                    setPaymentPlanToCreatePayment(undefined);
                }}
            />
        );
    }

    return (
        <>
            <LogFinplanTable
                logs={finplan.logs}
                paymentPlans={finplan.paymentPlans}
            />
            {canGeneratePlanPayments && (
                <GenerateForm
                    setPaymentPlans={() => {
                        void queryResult.refetch();
                    }}
                    planId={finplan.id}
                />
            )}
            {canEdit &&
                (!editPaymentPlan ? (
                    <CreateAdvPaymentPlan
                        finPlanId={finplan.id}
                        onCreatePlan={async (idPaymentPlan?: string) => {
                            const { data } = await queryResult.refetch();
                            const plans =
                                data.authorized.manager.advertising
                                    .finantialPlans.plan.paymentPlans;
                            const newCurrentPlan = (plans || []).find(
                                item => item.id === idPaymentPlan,
                            );
                            setColumnData({
                                name: "order",
                                id: newCurrentPlan?.order,
                                tableName: "adv-payments-plans-edit",
                            });
                        }}
                        onCompareSumms={(currentAmount: number) =>
                            onCompareSumms(
                                currentAmount,
                                finplan.paymentPlans,
                                finplan.contract.contractSum,
                            )
                        }
                    />
                ) : (
                    <EditAdvPaymentPlan
                        paymentPlan={editPaymentPlan}
                        finPlanId={finplan.id}
                        onFinishEditing={() => {
                            void queryResult.refetch();
                            setColumnData({
                                name: "",
                                id: null,
                                tableName: "",
                            });
                            setEditPaymentPlan(undefined);
                        }}
                        onCompareSumms={(currentAmount: number) =>
                            onCompareSumms(
                                currentAmount,
                                finplan.paymentPlans,
                                finplan.contract.contractSum,
                                editPaymentPlan.amount,
                            )
                        }
                    />
                ))}
            <AdminWrapper>
                <ReactTable
                    data={finplan.paymentPlans || []}
                    columns={columns}
                    tableName="adv-payments-plans-edit"
                />
            </AdminWrapper>
            <ToFinplans />
            <DeletePaymentPlanModal
                ref={paymentPlanDeleteComponentRef}
                setPaymentPlans={() => {
                    void queryResult.refetch();
                }}
                finPlanId={finplan.id}
            />
        </>
    );
};
