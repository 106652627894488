import { Field, useFormikContext } from "components/formik";
import { useTranslation } from "react-i18next";
import { AdvertisementStatus } from "gql/types/operation-result-types";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions } from "gql/types/operation-result-types";
import { ISelectFild } from "components/filters/select-filed-interface";
import { useIsSelectOptionsLoading } from "components/filters/hooks/use-is-select-iptions-loading";
import { getStatusName } from "services/project-utils";

interface IProps extends ISelectFild {
    forChangingStatus?: boolean;
    initialStatus?: AdvertisementStatus | null;
    getSpecilaOrderOptions: (
        permissions: GetPartnersProgramGeneralInfo_partnersProgram_generalInformation_permissions | null,
    ) => AdvertisementStatus[];
}

export const StatusField = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { ...rest } = props;
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const { values } = useFormikContext();
    const currentStatus = (values as any)[rest.name] as AdvertisementStatus;
    function getAllOptions() {
        const allStatuses: Array<{
            value: AdvertisementStatus;
            label: string;
            isDisabled: boolean;
        }> = [];
        const arrayStatusItem = [
            AdvertisementStatus.NEW,
            AdvertisementStatus.CREATED,
            AdvertisementStatus.APPROVED,
            AdvertisementStatus.SENT,
            AdvertisementStatus.NEEDS_IMPROVEMENT,
            AdvertisementStatus.SUCCESS,
            AdvertisementStatus.DECLINED,
            AdvertisementStatus.PARTIAL_PAYOUT,
            AdvertisementStatus.CANCELED,
            AdvertisementStatus.ARCHIVE,
        ];

        arrayStatusItem.map(item => {
            allStatuses.push({
                value: item,
                label: getStatusName(item, __),
                isDisabled: false,
            });
        });

        return allStatuses;
    }

    const allOptions = getAllOptions();

    const filteredStatuses = getStatuses() || [];

    useIsSelectOptionsLoading(
        props.name,
        false,
        filteredStatuses,
        props.loadingContext,
    );

    function getOptionInSpecialOrder(
        specialOrder?: AdvertisementStatus[] | undefined,
    ) {
        if (!specialOrder) {
            return allOptions;
        }
        const options = specialOrder
            .map(status => allOptions.find(item => item.value === status))
            .filter(item => item !== undefined);

        if (currentStatus && !specialOrder.includes(currentStatus)) {
            const currentOption = allOptions.find(
                item => item.value === currentStatus,
            );
            if (currentOption) {
                currentOption.isDisabled = true;
            }
            options.unshift(currentOption);
        }

        return options;
    }

    function getStatuses() {
        if (!permissions) {
            return [];
        }

        return getOptionInSpecialOrder(
            props.getSpecilaOrderOptions(permissions),
        );
    }

    return (
        <Field
            label={__("Статус")}
            type="react-select"
            options={filteredStatuses}
            isLoading={false}
            isDisabled={props.disabled || false}
            placeholder={__("Все")}
            minWidth={200}
            {...rest}
        />
    );
});
