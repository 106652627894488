import styled from "styled-components";
import { ButtonCss } from "styled/internal-buttons/buttons";

export const ButtonFlex = styled.button`
    ${ButtonCss};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    color: ${props => props.theme.primaryColor};
`;
