import { FullReportFilter, User_user } from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { getCommonTableTranslator } from "../../services/get-commot-table-translator";

interface ITableData {
    user?: User_user;
    values: FullReportFilter;
    reportFormat: string;
    culture: string;
}

export const tableData = (
    { user, values, reportFormat, culture }: ITableData,
    __: TFunction,
) => {
    const { endPeriod, startPeriod, merchant } = values;
    if (user) {
        return {
            reportFormat,
            translator: {
                commissionGroup: {
                    header: __("Комиссионная группа"),
                    value: __("Все"),
                },
                affiliateGroup: {
                    header: __("Партнерская группа"),
                    value: __("Все"),
                },
                reportType: __("Полный отчет"),
                ...getCommonTableTranslator(
                    {
                        culture,
                        currencyId: merchant,
                        endPeriod,
                        startPeriod,
                        user,
                    },
                    __,
                ),
                columns: {
                    column1: __("ID партнера"),
                    column2: __("ID сайта"),
                    column3: __("Сайт"),
                    column4: __("Просмотры"),
                    column5: __("Клики"),
                    column6: __("Прямые ссылки"),
                    column7: "CTR",
                    column8: __("Регистрации"),
                    column9: __("Регистрации с депозитом"),
                    column10: __("Аккаунты с депозитами"),
                    column11: __("Новые аккаунты с депозитами"),
                    column12: __("Сумма новых депозитов"),
                    column13: __("Сумма депозитов"),
                    column14: __("Количество депозитов"),
                    column15: __("Активные игроки"),
                    column16: __("Средний доход с игрока"),
                    column17: __("Сумма бонусов"),
                    column18: __("Доход компании (общий)"),
                    column19: __("комиссия RS"),
                    column20: __("CPA"),
                    column21: __("Сумма комиссий"),
                    column22: __("Уникальные установки"),
                },
            },
        };
    }
};
