import { GetLanguages } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_LANGUAGES = gql`
    query GetLanguages {
        languages {
            id
            name
            code
            nativeName
        }
    }
`;

export const useGetLanguages = () => {
    const languageList = useQuery<GetLanguages>(GET_LANGUAGES, {
        ssr: true,
    });

    const languages = g(languageList, "data", "languages");

    return {
        languages,
        loading: languageList.loading,
        error: languageList.error,
    };
};
