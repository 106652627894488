import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { QueryResult } from "@apollo/client";
import { ReactTable } from "components/react-table";
import { useFormikContext } from "components/formik";
import { ITablePagination } from "hooks/use-pagination";
import {
    PlayerActivityReport,
    PlayerActivityReportVariables,
} from "gql/types/operation-result-types";
import { getColumns } from "./columns";
import { Export } from "./export";
import { IFormValues } from "./models";

interface IProps {
    externalPaginationAndSorting: ITablePagination;
    queryResult: QueryResult<
        PlayerActivityReport,
        PlayerActivityReportVariables
    >;
}

export const PlayersActivityTable = ({
    externalPaginationAndSorting,
    queryResult,
}: IProps) => {
    const [__] = useTranslation();
    const columns = getColumns(__);
    const { items, rowsCount } =
        queryResult.data?.authorized.manager.reports.playersActivityReport ||
        {};
    const { values, isSubmitting, setFieldValue, submitForm } =
        useFormikContext<IFormValues>();

    useEffect(() => {
        if (queryResult.data || (values.partnerId && values.siteId)) {
            void submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalPaginationAndSorting.state]);

    return (
        <ReactTable
            shownColumnsByDefault={columns
                .map(({ accessor }) => accessor)
                .filter(accessor => accessor !== "comment")}
            columns={columns}
            data={items}
            dataLength={rowsCount}
            loading={queryResult.loading}
            externalPaginationAndSorting={externalPaginationAndSorting}
            exportComponent={
                <Export
                    onClick={reportFormat => {
                        setFieldValue("reportFormat", reportFormat);
                        void submitForm().then(() =>
                            setFieldValue("reportFormat", null),
                        );
                    }}
                    isSubmitting={isSubmitting}
                    format={values.reportFormat}
                />
            }
        />
    );
};
