import { useState, Dispatch } from "react";
import { Modal } from "components/components-common/modal";
import { ModalSize } from "components/components-common/modal/modal-size";
import { useModalState } from "hooks/use-modal-state";
import { useTranslation } from "react-i18next";
import { Paragraph } from "services/styled-components/typogoraphy";
import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { AdvertisingPayments } from "views/manager/advertising/adversiting-payments";
import { CancelButton, SubmitButton } from "../../buttons";
import {
    ConnectPaymentPlanToPayment,
    ConnectPaymentPlanToPaymentVariables,
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
} from "gql/types/operation-result-types";
import CONNECT_PAYMENT_PLAN_TO_PAYMENT from "./connect-payment-plan-to-payment.gql";
import { useMutation } from "@apollo/client";
import { AdvertisingPayment } from "views/manager/advertising/adversiting-payments/report-table/report-table-columns";
import { SetStateAction } from "react";
import { Title } from "services/styled-components/typogoraphy";

interface СonnectPaymentPlanToPayment {
    // eslint-disable-next-line max-len
    paymentPlanToConnect: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans;
    finPlanId: number;
    setPaymentPlans: Dispatch<
        SetStateAction<
            // eslint-disable-next-line max-len
            | GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans[]
            | undefined
        >
    >;
}

export const ConnectPaymentToFinPlan = ({
    finPlanId,
    setPaymentPlans,
    paymentPlanToConnect,
}: СonnectPaymentPlanToPayment) => {
    const [advertisingPayment, setAdvertisingPayment] =
        useState<AdvertisingPayment>();
    const [__] = useTranslation();
    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });

    const setChoosenPaymentAndOpenConfirmModal = (
        props: AdvertisingPayment,
    ) => {
        setAdvertisingPayment(props);
        open();
    };

    const closeModal = () => {
        setAdvertisingPayment(undefined);
        close();
    };

    const [connect] = useMutation<
        ConnectPaymentPlanToPayment,
        ConnectPaymentPlanToPaymentVariables
    >(CONNECT_PAYMENT_PLAN_TO_PAYMENT);

    const handleConnectPaymentPlanToPayment = async (
        advertisingPaymentProps: AdvertisingPayment,
    ) => {
        if (!finPlanId) {
            return;
        }

        const advPaymentId = advertisingPaymentProps.id;

        const result = await connect({
            variables: {
                planId: finPlanId,
                advPaymentId,
                id: paymentPlanToConnect.id,
            },
        });

        if (
            result.data?.authorized.manager.advertising.finantialPlans
                .connectPaymentPlanToPayment
        ) {
            setPaymentPlans(paymentPlans => {
                if (!paymentPlans) {
                    return;
                }
                const currentPlanIndex = paymentPlans.findIndex(
                    paymentPlan => paymentPlan.id === paymentPlanToConnect.id,
                );

                const newPaymentPlans = [...paymentPlans];
                newPaymentPlans[currentPlanIndex] = {
                    ...newPaymentPlans[currentPlanIndex],
                    advPaymentId: advertisingPaymentProps.id,
                    advPaymentAmount: advertisingPaymentProps.amountRequest,
                    advPaymentCurrencyName:
                        advertisingPaymentProps.currencyName,
                    advPaymentStatus: advertisingPaymentProps.statusName,
                };
                return newPaymentPlans;
            });
        }
    };

    const handleSubmit = async () => {
        if (advertisingPayment) {
            void handleConnectPaymentPlanToPayment(advertisingPayment);
        }
        closeModal();
    };

    return (
        <>
            <Title>{__("Выберите рекламную выплату")}</Title>
            <AdvertisingPayments
                onChoosePayment={setChoosenPaymentAndOpenConfirmModal}
            />
            <Modal {...modalProps}>
                <Paragraph>{__("Вы уверены в правильности выбора?")}</Paragraph>
                <FormDirectionRow>
                    <FormGroupButton>
                        <SubmitButton onClick={handleSubmit}>
                            {__("Да")}
                        </SubmitButton>
                    </FormGroupButton>
                    <FormGroupButton>
                        <CancelButton onClick={closeModal}>
                            {__("Нет")}
                        </CancelButton>
                    </FormGroupButton>
                </FormDirectionRow>
            </Modal>
        </>
    );
};
