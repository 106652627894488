import {
    columns,
    media,
    pseudoElem,
} from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { ButtonColored } from "styled/button";
import { transparentize, position } from "polished";
import {
    adminBorderColor3,
    infoColor,
    backgroundColor0,
} from "services/styled-components/themes";
import {} from "services/styled-components/variables";

export const TableWraper = styled.div`
    position: relative;
    margin-bottom: 15px;
    max-width: 100%;
    &::after {
        ${pseudoElem}
        ${position("absolute", "0")}
        margin: auto;
        pointer-events: none;
    }
`;

export const tableResponsive = css<IProps>`
    ${props => media.smMax`
        td {
            padding: 5px;
        }
        thead, 
        .thead {
            display: none;
        }
        > tbody > tr,
        > .tbody > .tr {
            > td,
            > .td {
                position: relative;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                position: relative;
                text-align: left;
                &:first-of-type {
                    align-items: flex-end;
                    background-color: ${props.theme.backgroundColor};
                    color: ${props.theme.textColor};
                    z-index: 1;
                }
                &::before {
                    content: attr(data-title)"\\0020";
                    display: block;
                    font-weight: bold;
                    padding-right: 1em;
                    /* flex-basis: 50%; */
                    text-align: left;
                    word-wrap: normal;
                    word-break: normal;
                }
            }
        }
    `}
`;
export const tableBordered = css`
    margin: -1px;
    th,
    td {
        border: 1px solid ${props => props.theme.textColor};
    }
`;

export const tableStriped = css`
    tbody {
        tr:nth-of-type(odd) {
            background-color: ${props =>
                transparentize(0.8, props.theme.accentColor)};
        }
        tr:nth-of-type(even) {
            background-color: ${backgroundColor0};
        }
    }
`;

export const tableHover = css`
    tbody {
        tr:hover {
            background-color: ${infoColor};
            cursor: pointer;
        }
    }
`;

export type TableType = "striped" | "hover" | "bordered" | "responsive";

interface IProps {
    types?: TableType[];
}

export const Table = styled.table<IProps>`
    /* stylelint-disable */

    display: table;
    width: 100%;
    max-width: 100%;
    position: relative;
    text-align: center;
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
    td,
    th {
        padding: 10px;
        box-sizing: border-box;
        vertical-align: middle;
        font-size: 13px;
    }
    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
        position: relative;
        tr {
            color: ${props => props.theme.primaryColor};
            background-color: ${adminBorderColor3};
            th {
                background-color: ${props => props.theme.backgroundColor};
                color: ${props => props.theme.textColor};
                /* border: 1px solid transparent; */
            }
        }
    }
    tbody {
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;
        width: 100%;
    }
    ${props => (props.types || []).includes("striped") && tableStriped};
    ${props => (props.types || []).includes("hover") && tableHover};
    ${props => (props.types || []).includes("bordered") && tableBordered};
    ${props => (props.types || []).includes("responsive") && tableResponsive};

    ${ButtonColored} {
        margin: 5px auto;
        ${media.sm`
            &:first-of-type {
                margin-top: -10px;
            }
            &:last-of-type {
                margin-bottom: -10px;
            }
        `}
        svg {
            vertical-align: middle;
        }
    }
`;

export const TableWraperQuickReport = styled(TableWraper)`
    margin-top: 5px;
    margin-bottom: 0;
`;
const widthOfColumns = [50, 50];
export const TableQuickReport = styled(Table)`
    th,
    td {
        ${columns(widthOfColumns)}
        &:first-child {
            text-align: left;
        }
    }
`;

export const RegularText = styled.div`
    padding: 10px;
    font-size: 16px;
    color: #273a4c;
    text-align: center;
    background-color: ${adminBorderColor3};
`;
