import { useMutation } from "@apollo/client";
import { Paragraph } from "services/styled-components/typogoraphy";
import { useTranslation } from "react-i18next";
import { useModalState } from "hooks/use-modal-state";
import { ModalSize } from "components/components-common/modal/modal-size";
import { Modal } from "components/components-common/modal";
import DELETE_FIN_PLAN from "./delete.gql";
import {
    DeleteFinPlan,
    DeleteFinPlanVariables,
} from "gql/types/operation-result-types";

import { FormDirectionRow, FormGroupButton } from "styled/layouts";
import { CancelButton, SubmitButton } from "../../buttons";
import { toast } from "react-toastify";
import { forwardRef, useImperativeHandle, useState } from "react";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { SetFinPlanIdToDeleteProps } from "../../report-table";

interface DeleteFinPlanModalProps {
    updateFinPlans?: () => void;
}

const DeleteFinPlanModalInternal: React.ForwardRefRenderFunction<
    SetFinPlanIdToDeleteProps,
    DeleteFinPlanModalProps
> = (props, ref) => {
    const { updateFinPlans } = props;
    const [__] = useTranslation();
    const [finPlanIdToDelete, setCurrentFinPlanIdToDelete] = useState<number>();

    const { modalProps, open, close } = useModalState({
        size: ModalSize.Large,
    });

    const [mutation, { loading }] = useMutation<
        DeleteFinPlan,
        DeleteFinPlanVariables
    >(DELETE_FIN_PLAN);

    const closeModal = () => {
        setCurrentFinPlanIdToDelete(undefined);
        close();
    };

    const deletePaymentPlan = async () => {
        if (finPlanIdToDelete) {
            try {
                const deleted = await mutation({
                    variables: {
                        planId: finPlanIdToDelete,
                    },
                });
                if (deleted && !deleted?.errors) {
                    toast.success(
                        format(__("Фин план {finPlanId} успешно удален."), {
                            finPlanId: finPlanIdToDelete,
                        }),
                    );
                    if (updateFinPlans) {
                        updateFinPlans();
                    }
                }
            } catch (er: any) {
                void errorHandler(er, error => {
                    switch (error.data.code) {
                        case ErrorType.INVALID_OPERATION:
                            return format(
                                __(
                                    "Фин план {finPlanId} содержит связанные рекламные выплаты.",
                                ),
                                {
                                    finPlanId: finPlanIdToDelete,
                                },
                            );
                        case ErrorType.RESOURCE_HAS_BEEN_ALREADY_DELETED:
                            return format(
                                __("Фин план {finPlanId} уже удален."),
                                {
                                    finPlanId: finPlanIdToDelete,
                                },
                            );
                        default:
                            return __("Ошибка при удалении фин плана.");
                    }
                });
            } finally {
                closeModal();
            }
        }
    };

    const handleSubmit = async () => {
        await deletePaymentPlan();
        closeModal();
    };

    useImperativeHandle(ref, () => ({
        setFinPlanIdToDelete: (finPlanId: number) => {
            setCurrentFinPlanIdToDelete(finPlanId);
            open();
        },
    }));

    return (
        <Modal {...modalProps}>
            <Paragraph>{__("Удалить фин план?")}</Paragraph>
            <FormDirectionRow>
                <FormGroupButton>
                    <SubmitButton onClick={handleSubmit} disabled={loading} />
                </FormGroupButton>
                <FormGroupButton>
                    <CancelButton onClick={closeModal}>
                        {__("Отмена")}
                    </CancelButton>
                </FormGroupButton>
            </FormDirectionRow>
        </Modal>
    );
};

export const DeleteFinPlanModal = forwardRef(DeleteFinPlanModalInternal);
