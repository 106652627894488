import { Lock } from "@styled-icons/fa-solid/Lock/Lock";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Field, Form } from "components/formik";
import { ITwoFACode } from "hooks/sign-in/2fa/use-2fa-submit";
import { useTranslation } from "react-i18next";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    validationSchema: any | (() => any);
    initialValues: ITwoFACode;
    onSubmit: any;
    submitText: string;
}

export const TwoFAForm = (props: IProps) => {
    const [__] = useTranslation();
    return (
        <FormikConsoleErrors
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
            validationSchema={props.validationSchema}
        >
            {() => (
                <Form>
                    <p>
                        <Lock size={18} />
                        <Field
                            name="code"
                            type="text"
                            placeholder={__("Код")}
                            autoComplete="on"
                        />
                    </p>

                    <SubmitButton>{props.submitText}</SubmitButton>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
