import axios from "axios";
import { saveAs } from "file-saver";
import { parse } from "content-disposition";

interface IParams {
    url: string;
    hash?: string | null;
    tablaData: any;
}

export const savePartnerReport = async ({ url, hash, tablaData }: IParams) => {
    if (hash) {
        const response = await axios({
            url: "/lapi/get-partner-report",
            method: "POST",
            responseType: "blob",
            data: {
                ...tablaData,
                id: hash,
                url,
            },
        });

        const disposition = parse(response.headers["content-disposition"]);

        saveAs(new Blob([response.data]), disposition.parameters.filename);
    }
};
