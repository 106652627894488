import { LoadingText } from "components/react-table/loading-text";
import {
    GetSubpartnerReport,
    GetSubpartnerReportVariables,
} from "gql/types/operation-result-types";
import { ReportReactTable } from "components/react-table/report-react-table";
import { Export } from "./export";
import { IReactTableColumn } from "components/react-table/column/";
import { useTranslation } from "react-i18next";
import { SubmitValues, generalQueryParams } from "../general-query-params";
import { QueryResult } from "@apollo/client";
import { ITablePagination } from "hooks/use-pagination";

interface IProps {
    actualValues?: SubmitValues;
    queryResult?: QueryResult<
        GetSubpartnerReport,
        GetSubpartnerReportVariables
    >;
    externalPaginationAndSorting: ITablePagination;
}

export const SubPartnerReportEmptyTable = ({
    actualValues,
    queryResult,
    externalPaginationAndSorting,
}: IProps) => {
    const [__] = useTranslation();
    const currency = actualValues?.currency?.name || "";

    /*
        ВНИМАНИЕ!
        Конфигурация minWidth была подобрана с учётом комфорта юзера по запросу менеджера Евгения Веремьева.
        Пожалуйста, не меняйте minWidth по собственному усмотрению. Сначала согласуйте с Евгением.
    */
    const columns: IReactTableColumn[] = [
        {
            Header: __("Сайт"),
            accessor: "siteName",
            disabled: true,
            minWidth: 100,
        },
        {
            Header: __("ID"),
            accessor: "subPartnerId",
            minWidth: 60,
        },
        {
            Header: __("Уровень суб-партнера"),
            accessor: "supPartnerLvl",
            minWidth: 80,
        },
        {
            Header: __("Дата регистрации суб-партнера"),
            accessor: "registrationDate",
            dataType: "date",
            minWidth: 80,
        },
        {
            Header: __("ID сайта регистрации"),
            accessor: "siteId",
            minWidth: 80,
        },
        {
            Header: __("Процент"),
            accessor: "percentOfComissionOfLvl",
            minWidth: 80,
        },
        {
            Header: __("Просмотры"),
            accessor: "countOfViews",
            minWidth: 80,
        },
        {
            Header: __("Клики"),
            accessor: "countOfClicks",
            minWidth: 80,
        },
        {
            Header: __("Прямые ссылки"),
            accessor: "countOfDirectLinks",
            minWidth: 80,
        },
        {
            Header: __("Регистрации"),
            accessor: "countOfRegistrations",
            minWidth: 80,
        },
        {
            Header: __("Новые аккаунты с депозитами"),
            accessor: "countOfNewAccountsWithDeposits",
            minWidth: 80,
        },
        {
            Header: __("Сумма всех депозитов"),
            accessor: "depositAmount",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("Доход"),
            accessor: "profit",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("CPA"),
            accessor: "CPA",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("Реферальная комиссия"),
            accessor: "referalComission",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
    ];

    return (
        <ReportReactTable
            exportComponent={
                actualValues && (
                    <Export filters={generalQueryParams(actualValues)} />
                )
            }
            reportName="SubPartner"
            columns={columns}
            shownColumnsByDefault={[
                "siteName",
                "subPartnerId",
                "supPartnerLvl",
                "siteId",
                "percentOfComissionOfLvl",
                "countOfViews",
                "countOfClicks",
                "countOfDirectLinks",
                "countOfRegistrations",
                "countOfNewAccountsWithDeposits",
                "depositAmount",
                "profit",
                "CPA",
                "referalComission",
            ]}
            queryResult={queryResult}
            externalPaginationAndSorting={externalPaginationAndSorting}
            data={
                queryResult?.data?.authorized.partner.reports.subpartnerReport
                    .rows || []
            }
            total={
                queryResult?.data?.authorized.partner.reports.subpartnerReport
                    .total
            }
            reportStatus={
                queryResult?.data?.authorized.partner.reports.subpartnerReport
                    .status
            }
            dataLength={
                queryResult?.data?.authorized.partner.reports.subpartnerReport
                    .pagesCount
            }
            loadingText={<LoadingText fastLoading={true} />}
        />
    );
};
