import _ from "lodash";
import { SelectPage } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    options: Array<{ value: number; label: string }>;
    onChange: (value: number) => void;
    value?: number;
    inputId: string;
}

export const PageSizeSelect = ((props: IProps) => {
    return (
        <SelectPage
            classNamePrefix="react-select"
            menuPlacement="auto"
            inputId={props.inputId}
            options={props.options}
            value={_.find(props.options, { value: props.value })}
            onChange={({ value }: { value: number; label: string }) =>
                props.onChange(value)
            }
            minWidth={100}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
