import { css } from "styled-components";

const fontsPath = "../assets/fonts/league-gothic/";

export const LeagueGothicFonts = css`
    @font-face {
        font-family: "LeagueGothic";
        src: url("${fontsPath}league-gothic.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
`;
