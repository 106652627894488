import { usePartnersProgram } from "hooks/use-partners-program-id";
import { languageListByPartner } from "services/i18n/language-list";
import { Variables } from "services/variables";
import { ReactNode } from "react";
import {
    LanguageProvider as WebXLanguageProvider,
    useLanguage as webXUseLanguage,
    Language,
} from "@webx-react/i18n";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import urljoin from "url-join";

export {
    LocalizedNavLink,
    LocalizedRedirect,
    LocalizedLink,
    createLanguageRedirectLocation,
    t,
    initI18n,
} from "@webx-react/i18n";

interface IProps {
    acceptLanguage?: string | undefined;
    children: ReactNode;
    geoCountryCode: string | string[] | undefined;
}

// переопределяем хук из либы webx-react/i18n т.к. его поведение не подходит
export function useLanguage() {
    const { language } = webXUseLanguage();
    const partnersProgram = usePartnersProgram();
    let { pathname } = useLocation();
    const [, setCookie] = useCookies();
    if (!partnersProgram) {
        throw new Error("Partners program is not defined");
    }
    const languageList = languageListByPartner(partnersProgram.programId);

    function setLanguage(newLanguage: Language) {
        // если язык на которором отображается сайт не то же самое что ноый язык
        if (language.code !== newLanguage.code) {
            // подменить в адресе язык и пергрузить страницу по новому адреcу
            // установка языка все равно произойдет в мидлваре серверной части, которая
            // нужна для установки язков по прямым запросам к серверной части
            // (однако для языка с / (eng) это не срабатывает, поэтому делаем тут также кое-что см. ниже)

            const pathRegex = new RegExp(/^\/(?<lng>.+?)\//g);
            const matchRes = pathRegex.exec(pathname);
            const pathLanguage = languageList.find({
                // matchRes?.groups?.lng (именовынные группы) не работают на клиента по каким-то причинам
                shortName: matchRes?.[1],
            });

            // очистить pathname oт языка если он есть
            if (pathLanguage) {
                pathname = pathname.replace(
                    pathRegex,
                    //
                    "/",
                );
            }

            // Сформировать новый путь
            // Работает для всех случаех, в частности есть urlPrefix нет
            const newPath = urljoin("/", newLanguage.urlPrefix, pathname);

            // Установить в куки новый язык
            // По сути, можно было бы этого не делать, но делаем из-за языка без префикса, в частности en,
            // который не устанавливается на серверной части в мидлваре бека
            setCookie(
                Variables.cookies.language,
                newLanguage.code,
                Variables.cookiesEndlessOptions,
            );

            window.location.assign(newPath);
        }

        // если адрес совпадает то язык уже был установлен, в мидлваре бека
    }

    return { language, setLanguage };
}

// Обертак вокруг @webx-react/i18n . См описание ниже. Не устанавливает язык.
export function LanguageProvider(props: IProps) {
    // обертка вокруг LanguageProvider из либы webx-react/i18n
    const partnersProgram = usePartnersProgram();

    if (!partnersProgram) {
        return null;
    }
    const languageList = languageListByPartner(partnersProgram.programId);

    // для Украины нужно ориентироваться на гео, а не на настройки браузера
    const acceptLanguage =
        props.geoCountryCode === "UA" ? "uk-UA,uk;q=1.0" : props.acceptLanguage;

    // Описание того как работает LanguageProvider из либы webx-react/i18n
    // (но это не точно, т.к. описания либы нет и она уже не поддерживается):

    // Если выбранный язык (если он выбран) не совпадает с языком в пути,
    // то происходит редирект по новому пути в соотв. с выбранным языком.
    // В противном случае продолжается рендеринг в соотв. с выбранным языком.

    // Если язык не выбран, то просиходит определение на каком языке рендерить.
    // Определяется язык по браузеру. Если определенный язык совпадает с языком в пути,
    // то делаем рендеринг на этом языке но не делаем этот язык выбранным.
    // Если не совпадает, то редиректим по новому пути в соотв. с определенным языком.

    // Если на клиентской стороне потом использовать хук useLanguage,
    // то полученный язык - это не выбранный язык (в куках), это язык в котором отображается страница.

    // PS: Простановка язка с помощью useLanguage даже если уже был выбран или
    // опеределен этот язык - приводит к перезагрузке - но это скорее баг либы

    return (
        <WebXLanguageProvider
            browserRedirect={!!acceptLanguage}
            acceptLanguage={acceptLanguage}
            languageList={languageList}
            languageCookieName={Variables.cookies.language}
        >
            {props.children}
        </WebXLanguageProvider>
    );
}
