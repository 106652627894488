import styled from "styled-components";

export const TableWrap = styled.div`
    margin: 2rem 0;
`;

export const TablesContainer = styled(TableWrap)`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 2rem;

    @media (min-width: 1250px) {
        flex-direction: row;

        > div {
            width: 100%;

            :first-child {
                margin-right: 2rem;
            }
        }
    }
`;

export const FormWrap = styled.div`
    max-width: 250px;
`;
