import { ReactNode } from "react";
import { RelativeBlock, ShieldWithCursor } from "./styled";

interface IClicksShieldProps {}

interface IClicksShield extends IClicksShieldProps {
    children: ReactNode;
    onClick: (e: any) => void;
}

export const ClicksShieldWithCursor = React.memo((props: IClicksShield) => {
    return (
        <RelativeBlock onClick={props.onClick}>
            <ShieldWithCursor />
            {props.children}
        </RelativeBlock>
    );
}) as React.ComponentType<Subtract<IClicksShield, IClicksShieldProps>>;
