import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form, Field } from "components/formik";
import {
    FormDirectionRow,
    FormGroup,
    FormGroupButton,
    FormDirectionColumn,
    FormGroupCheckbox,
} from "styled/layouts";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { Variables } from "services/variables";
import { useTranslation } from "react-i18next";
import { Period, Periods } from "components/filters/fields/period";
import { CurrencyField } from "components/filters/fields";
import { ComissionGroupField } from "components/filters/fields/commision-group";
import { getDatesByPeriod } from "components/filters/services/get-dates-by-period";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { FakeLabel } from "components/filters/form";
import { SiteField } from "views/manager/main/partner-list/edit-partner/fields/site";
import {
    AddCommissionGroup,
    AddCommissionGroupVariables,
} from "gql/types/operation-result-types";
import { useValidationSchema } from "./use-validation-schema";
import { useMutation } from "@apollo/client";
import ADD_COMMISSION_GROUP from "./add.gql";
import PARTNER_COMMISSION_GROUPS from "../query.gql";
import { toast } from "react-toastify";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";

export const AddGroupForm = ({ partnerId }: { partnerId: number }) => {
    const [__] = useTranslation();
    const { start, end } = getDatesByPeriod(Periods.CURRENT_MONTH);

    const initialValues = {
        groupDuration: {
            interval: Periods.CURRENT_MONTH,
            start,
            end,
        },
        noLimitedGroup: false,
        commisionGroup: null,
        site: null,
        currency: null,
    };

    const validationSchema = useValidationSchema();

    const [mutation] = useMutation<
        AddCommissionGroup,
        AddCommissionGroupVariables
    >(ADD_COMMISSION_GROUP, {
        refetchQueries: [
            {
                query: PARTNER_COMMISSION_GROUPS,
                variables: { partnerId },
            },
        ],
    });

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async (values, { resetForm }) => {
                const dateStart = values.groupDuration.start?.format(
                    Variables.dateFormat.seconds,
                );
                const dateEnd = values.groupDuration.end?.format(
                    Variables.dateFormat.seconds,
                );
                if (values.commisionGroup && values.currency) {
                    try {
                        await mutation({
                            variables: {
                                commissionGroup: {
                                    partnerId,
                                    commissionGroupId: values.commisionGroup.id,
                                    currencyId: Number(values.currency.id),
                                    noLimitedGroup:
                                        values.noLimitedGroup || false,
                                    dateStart,
                                    dateEnd: dateEnd || "",
                                    siteId: values.site?.id || null,
                                },
                            },
                        });
                        toast.success(__("Комиссионная группа добавлена"));
                        if (resetForm) {
                            resetForm();
                        }
                    } catch (ex: any) {
                        void errorHandler(ex, error => {
                            switch (error.data.code) {
                                case ErrorType.IDENTICAL_CONNECTION_EXISTS:
                                    return __(
                                        "Идентичная связь существует. Невозможно создать комиссионную группу партнеру",
                                    );
                            }
                        });
                    }
                }
            }, validationSchema)}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <FormDirectionRow>
                        <FormDirectionColumn>
                            <Period
                                name="groupDuration"
                                showPeriods={false}
                                label={__("Период")}
                                disabled={
                                    values.noLimitedGroup ? "endDate" : false
                                }
                            />
                            <FormGroupCheckbox>
                                <Field
                                    name="noLimitedGroup"
                                    type="checkbox"
                                    label={__("Бессрочная группа")}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        const currentValue = e.target.checked;
                                        setFieldValue(
                                            "noLimitedGroup",
                                            currentValue,
                                        );
                                        setFieldValue(
                                            "groupDuration.end",
                                            currentValue ? null : end,
                                        );
                                    }}
                                />
                            </FormGroupCheckbox>
                        </FormDirectionColumn>
                        <FormGroup>
                            <CurrencyField
                                name="currency"
                                label={__("Валюта")}
                                isClearable={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <ComissionGroupField
                                name="commisionGroup"
                                isClearable={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <SiteField
                                name="site"
                                partnerId={partnerId}
                                placeholder={__("Все сайты")}
                            />
                        </FormGroup>
                        <FormGroupButton>
                            <FakeLabel />
                            <SubmitButton>{__("Добавить")}</SubmitButton>
                        </FormGroupButton>
                    </FormDirectionRow>
                </Form>
            )}
        </FormikConsoleErrors>
    );
};
