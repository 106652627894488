import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import { SetFinPlanIdToDeleteProps } from ".";
import { CellWithButtons } from "components/table-item";
import { FinantialPlanStatus } from "gql/types/operation-result-types";
import { DeleteFinPlanButton, EditFinPlanButton } from "../buttons";
import {
    getFinplanStatusNameEnum,
    getFinplanStatusWithTranslation,
} from "services/project-utils";

const getCanEdit = (statusName: string) => {
    return (
        getFinplanStatusNameEnum(statusName) !== FinantialPlanStatus.ARCHIVE &&
        getFinplanStatusNameEnum(statusName) !== FinantialPlanStatus.PAID
    );
};

const getCanDelete = (statusName: string) => {
    const status = getFinplanStatusNameEnum(statusName);

    return (
        status === FinantialPlanStatus.CREATED ||
        status === FinantialPlanStatus.INTERMIDIATE
    );
};

export const useColumns = (
    finPlanDeleteComponentRef: React.MutableRefObject<
        SetFinPlanIdToDeleteProps | undefined | null
    >,
) => {
    const [__] = useTranslation();

    const handleDeleteFinPlan = (finPlanId: number) => {
        finPlanDeleteComponentRef.current?.setFinPlanIdToDelete(finPlanId);
    };

    const colums: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "id",
            minWidth: 120,
            Cell: ({ original }) => {
                const button = (
                    <>
                        {getCanEdit(original.statusName) && (
                            <EditFinPlanButton
                                to={`/agent/advertising/fin-plans/edit/${original.id}`}
                            />
                        )}
                        {getCanDelete(original.statusName) && (
                            <DeleteFinPlanButton
                                onClick={() => handleDeleteFinPlan(original.id)}
                            />
                        )}
                    </>
                );
                return (
                    <CellWithButtons
                        button={button}
                        link={false}
                        text={original.id}
                    />
                );
            },
        },
        {
            Header: __("Название"),
            accessor: "name",
            minWidth: 80,
        },
        {
            Header: __("Дата начала"),
            accessor: "dateStart",
            minWidth: 80,
            dataType: "dateWithTime",
        },
        {
            Header: __("Дата окончания"),
            accessor: "dateEnd",
            minWidth: 80,
            dataType: "dateWithTime",
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("ID партнера"),
            accessor: "partnerId",
            minWidth: 80,
        },
        {
            Header: __("Создал пользователь"),
            accessor: "createdBy",
            minWidth: 80,
        },
        {
            Header: __("Дата создания"),
            accessor: "dateOfCreation",
            minWidth: 80,
            dataType: "dateWithTime",
        },
        {
            Header: "Total",
            accessor: "sumOfContract",
            minWidth: 80,
        },
        {
            Header: __("Выплачено"),
            accessor: "paid",
            minWidth: 80,
        },
        {
            Header: __("Статус"),
            accessor: "statusName",
            minWidth: 80,
            Cell: ({ original }) =>
                getFinplanStatusWithTranslation(
                    getFinplanStatusNameEnum(original.statusName),
                    __,
                ),
        },
        {
            Header: __("Изменил"),
            accessor: "changedBy",
            minWidth: 80,
        },
        {
            Header: __("Дата изменения"),
            accessor: "dateOfChange",
            minWidth: 80,
            dataType: "dateWithTime",
        },
    ];

    return colums;
};
