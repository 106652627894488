import { useMemo } from "react";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { useTranslation } from "react-i18next";
import { QrCode } from "components/qrcode";
import { TwoFAForm } from "components/two-fa-form";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import {
    ITwoFACode,
    use2faValidationSchema,
    use2faInitialValues,
} from "hooks/sign-in/2fa/use-2fa-submit";
import { useMutation, useQuery, gql } from "@apollo/client";
import {
    ActivateTwoFA,
    ActivateTwoFAVariables,
    GetAuthState,
    TwoFASetupData,
} from "gql/types/operation-result-types";
import { errorHandler } from "services/error-handler";
import GET_AUTH_STATE from "../../gql/get-auth-state.gql";

const TWO_FA_SETUP_DATA = gql`
    query TwoFASetupData {
        authorized {
            partnerAccount {
                twoFA {
                    setupData {
                        outpauth
                    }
                }
            }
        }
    }
`;

const ACTIVATE_TWO_FA = gql`
    mutation ActivateTwoFA($code: String!) {
        authorized {
            partnerAccount {
                twoFA {
                    activate(code: $code)
                }
            }
        }
    }
`;

interface IProps {
    setPageCodes: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ActivationData = (props: IProps) => {
    const [__] = useTranslation();
    const { refetch } = useQuery<GetAuthState>(GET_AUTH_STATE);
    const setupDataResult = useQuery<TwoFASetupData>(TWO_FA_SETUP_DATA);

    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    const [mutation] = useMutation<ActivateTwoFA, ActivateTwoFAVariables>(
        ACTIVATE_TWO_FA,
    );

    const initialValues = use2faInitialValues();
    const validationSchema = use2faValidationSchema();

    const otpauth = g(
        setupDataResult.data,
        "authorized",
        "partnerAccount",
        "twoFA",
        "setupData",
        "outpauth",
    );

    const onSubmit = useMemo(() => {
        return onSubmitWrapper(async (values: ITwoFACode) => {
            try {
                const resultCodes = await mutation({
                    variables: { code: values.code },
                });
                const codes =
                    resultCodes.data?.authorized.partnerAccount.twoFA
                        .activate || [];
                // TODO: при неверной активации должно бросаться исключение, а не возвращаться пустой массив
                if (codes.length === 0) {
                    return;
                }
                await refetch();
                props.setPageCodes(codes);
            } catch (e: any) {
                void errorHandler(e);
            }
        }, validationSchema);
    }, [mutation, props, refetch, validationSchema]);

    return (
        <>
            <h2>
                {format(
                    __(
                        "1. Добавьте {PartnerProgramCompanyName} в Google Authenticator",
                    ),
                    {
                        PartnerProgramCompanyName:
                            partnersGeneralInformation.partnerProgramCompanyName,
                    },
                )}
            </h2>
            <p>
                {format(
                    __(
                        "Откройте Google Authenticator и добавьте {PartnerProgramCompanyName}, отсканировав QR-код на странице.",
                    ),
                    {
                        PartnerProgramCompanyName:
                            partnersGeneralInformation.partnerProgramCompanyName,
                    },
                )}
            </p>
            <h2>
                {__(
                    "2. Введите шестизначный код, который генерирует Google Authenticator",
                )}
            </h2>
            <p>
                {format(
                    __(
                        "Убедитесь, что {PartnerProgramCompanyName} правильно добавлен в Google Authenticator, введя шестизначный код, который Google Authenticator укажет для {PartnerProgramCompanyName}, ниже, а затем нажмите «Включить» (Enable).",
                    ),
                    {
                        PartnerProgramCompanyName:
                            partnersGeneralInformation.partnerProgramCompanyName,
                    },
                )}
            </p>
            <QrCode text={otpauth || ""} />
            <TwoFAForm
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitText={__("Включить")}
            />
        </>
    );
};
