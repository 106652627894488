import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "@apollo/client";
import { Moment } from "moment";
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import { makeUtcCustomPeriod } from "services/project-utils";
import {
    IBaseFieldProps,
    defaultInputFieldProps,
    selectFieldsProps,
} from "components/filters/services/get-filter-fields";
import { Field } from "components/formik";
import { UniversalForm } from "components/filters";
import { getDatesByPeriod } from "components/filters/services/get-dates-by-period";
import { Period, Periods } from "components/filters/fields/period";
import { usePaginationAndSorting } from "hooks/use-pagination";
import {
    PlayerActivityReport as IPlayerActivityReport,
    PlayerActivityReportVariables,
} from "gql/types/operation-result-types";
import PLAYER_ACTIVITY_REPORT from "./player-activity-report.gql";
import { PlayersActivityTable } from "./player-activity-table";
import { exportPlayersActivityReport } from "./export";
import { IFormValues } from "./models";

export const PlayerActivityReport = () => {
    const [__] = useTranslation();
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();
    const { interger, periodSchema } = useObjectValidations();
    const [loadReport, queryResult] = useLazyQuery<
        IPlayerActivityReport,
        PlayerActivityReportVariables
    >(PLAYER_ACTIVITY_REPORT, {
        ssr: true,
    });
    const externalPaginationAndSorting = usePaginationAndSorting();
    const requiredNumberSchema = interger.required(FIELD_SHOULD_BE_FILLED);
    const [onlyNewChecked, setOnlyNewChecked] = useState(false);

    return (
        <UniversalForm
            fields={["partnerId"]}
            uniqueValidation={{
                partnerId: requiredNumberSchema,
            }}
            additionalFields={{
                siteId: {
                    initialValue: undefined,
                    component: (props: IBaseFieldProps) => (
                        <Field
                            {...defaultInputFieldProps(props)}
                            label={__("ID сайта")}
                        />
                    ),
                },
                period: {
                    initialValue: {
                        interval: Periods.TODAY,
                        ...getDatesByPeriod(Periods.TODAY),
                    },
                    component: (props: IBaseFieldProps) => (
                        <Period
                            {...selectFieldsProps(props)}
                            label={__("Дата начала -> окончания")}
                        />
                    ),
                    validationSchema: periodSchema,
                },
                onlyDeps: {
                    initialValue: undefined,
                    component: (props: IBaseFieldProps) => {
                        return (
                            <>
                                <Field
                                    {...defaultInputFieldProps(props)}
                                    label={__("Только игроки с депозитами")}
                                    type="checkbox"
                                    checked={!onlyNewChecked}
                                    onChange={() => setOnlyNewChecked(false)}
                                />
                                <Field
                                    {...defaultInputFieldProps(props)}
                                    label={__("Только новые игроки")}
                                    type="checkbox"
                                    name="onlyNew"
                                    checked={onlyNewChecked}
                                    onChange={() => setOnlyNewChecked(true)}
                                />
                            </>
                        );
                    },
                },
            }}
            onSubmit={async (values: IFormValues) => {
                if (!values) {
                    return;
                }

                const { partnerId, siteId, period } = values;
                const periodUtc = makeUtcCustomPeriod(
                    period.start,
                    period.end,
                ) as {
                    start: Moment;
                    end: Moment;
                };
                const filter = {
                    partnerId,
                    siteId: siteId ? Number(siteId) : null,
                    startDate: periodUtc.start.toISOString(),
                    endDate: periodUtc.end.toISOString(),
                    onlyNew: onlyNewChecked,
                };

                if (values.reportFormat) {
                    return await exportPlayersActivityReport(
                        filter,
                        values.reportFormat,
                    );
                }

                await loadReport({
                    variables: {
                        query: externalPaginationAndSorting.state,
                        filter,
                    },
                });
            }}
            submitButtonName={__("Сгенерировать отчет")}
        >
            {({ form }) => (
                <>
                    {form}
                    <PlayersActivityTable
                        externalPaginationAndSorting={
                            externalPaginationAndSorting
                        }
                        queryResult={queryResult}
                    />
                </>
            )}
        </UniversalForm>
    );
};
