import { exportButtonsDesc } from "components/react-table/table-select-variables/export/services/buttons";

import React from "react";
import { Dropdown } from "components/react-table/table-select-variables/export/components/dropdown";
import { ExportButton } from "./export-button";
import { SubpartnerReportFilter } from "gql/types/operation-result-types";

interface IProps {
    filters: SubpartnerReportFilter;
}

export const Export = React.memo((props: IProps) => {
    const { filters } = props;
    return (
        <Dropdown>
            {exportButtonsDesc.map(b => (
                <ExportButton key={b.format} button={b} filters={filters} />
            ))}
        </Dropdown>
    );
});
