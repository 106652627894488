import { useTranslation } from "react-i18next";
import { RouteComponentProps, Switch } from "react-router-dom";
import urljoin from "url-join";
import { PageRoute } from "components/check-roles/guard";
import { PaymentHistory } from "./payment-history";
import { PartnerInvoices } from "./partner-invoices";

interface IProps extends RouteComponentProps<{ url: string }> {}

export const Payments = (props: IProps) => {
    const { url } = props.match;
    const [__] = useTranslation();

    return (
        <Switch>
            <PageRoute
                exact
                path={urljoin(url, "payment-history")}
                component={PaymentHistory}
                pageTitle={__("История выплат")}
            />
            <PageRoute
                path={urljoin(url, "invoice")}
                component={PartnerInvoices}
                pageTitle={__("Инвойсы партнеров")}
            />
        </Switch>
    );
};
