import { Export } from "./export";
import {
    ReportStatus,
    GetOrdersAndPayments,
    GetOrdersAndPaymentsVariables,
} from "gql/types/operation-result-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReportReactTable } from "components/react-table/report-react-table";
import { getOrderStatusTranslation } from "services/project-utils";
import { IReactTableColumn } from "components/react-table/column/";
import { QueryResult } from "@apollo/client";
import { AllUniversalOutputValues } from "components/filters/types";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    currency: {
        name: string;
    };
    values: Pick<AllUniversalOutputValues, "currency" | "period">;
    queryResult: QueryResult<
        GetOrdersAndPayments,
        GetOrdersAndPaymentsVariables
    >;
}

export const ApplicationStatus = React.memo((props: any) => {
    const [__] = useTranslation();
    const { currency, queryResult, values } = props;

    const data =
        g(
            queryResult.data,
            "authorized",
            "partner",
            "ordersAndPayments",
            "orders",
        ) || [];

    // Конфигурация minWidth была подобрана с учётом комфорта юзера по запросу менеджера Евгения Веремьева.
    // Пожалуйста, не меняйте ей по собственному усмотрению. Сначала согласуйте с Евгением.
    const columns: IReactTableColumn[] = [
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            disabled: true,
            minWidth: 60,
        },
        {
            Header: __("Дата"),
            accessor: "date",
            minWidth: 90,
        },
        {
            Header: __("Выплата"),
            accessor: "paymentAmount",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 100,
        },
        {
            Header: __("Доход"),
            accessor: "profit",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 100,
        },
        {
            Header: __("Остаток"),
            accessor: "rest",
            dataType: "currency",
            currency: currency && currency.name,
            minWidth: 100,
        },
        {
            Header: __("Статус"),
            accessor: "status",
            Cell: cellInfo => {
                return getOrderStatusTranslation(cellInfo.value, __);
            },
            minWidth: 100,
        },
    ];

    const exportComponent = (
        <Export
            exportParams={{
                filename: __("Статус заявок"),
                filters: values,
                data,
                columns,
            }}
        />
    );

    return (
        <ReportReactTable
            exportComponent={exportComponent}
            columns={columns}
            queryResult={queryResult}
            data={data}
            reportStatus={ReportStatus.SUCCESS}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
