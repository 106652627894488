import { UniversalForm } from "components/filters";
import { UserListEmptyTable } from "./empty-table";
import { FirstLoadTable } from "views/partner-manager/first-load-table";

export const UserList = () => {
    return (
        <UniversalForm fields={[]}>
            {({ submittedValues, actualValues }) => {
                return (
                    <FirstLoadTable
                        actualValues={actualValues}
                        submittedValues={submittedValues}
                    >
                        {() => <UserListEmptyTable />}
                    </FirstLoadTable>
                );
            }}
        </UniversalForm>
    );
};
