import { User_user } from "gql/types/operation-result-types";
import { useUser } from "hooks/query/use-user";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { HiddenPages } from "../../../../server/general-settings-config";
import { usePermissions } from "../use-permissions";

interface IProps {
    children: JSX.Element;
    guards?: Array<(context: { user?: User_user }) => boolean>;
    name?: HiddenPages | "";
}

export const Access = ({ guards = [], children, name = "" }: IProps) => {
    const { checkPermissions } = usePermissions();
    const { loading } = useUser();
    const {
        visibility: { hiddenPages },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    const hasCompletedGuards = React.useMemo(
        () => checkPermissions(guards),
        [guards, checkPermissions],
    );

    // Проверить, есть ли переданное имя в конфиге домена, если есть, вернуть null
    if (loading || hiddenPages.includes(name)) {
        return null;
    }

    return hasCompletedGuards ? children : null;
};
