import { useDefinedContext } from "hooks/use-context-exist";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";
import { FlattenSimpleInterpolation } from "styled-components";
import {
    CookiesToast,
    CookiesToastContent,
    CookieOk,
    CookieMoreInfo,
} from "./styled";

interface IProps {
    onConfrim: () => void;
    btnCSS?: FlattenSimpleInterpolation;
    btnColor?: string;
    linkColor?: string;
    bgColor?: string;
}

export const CookiesAgreementToastContent = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const partnersGeneralInformation = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    return (
        <CookiesToast bgColor={props.bgColor}>
            <CookiesToastContent>
                {format(
                    __(
                        "Используя данный сайт, вы соглашаетесь с тем, что компания {PartnerProgramCompanyName} может использовать файлы «cookie» для хранения ваших учетных данных, а также с целью сбора статистики для улучшения работы сайта и предоставления вам маркетинговых материалов с вашего разрешения и с учетом ваших интересов.",
                    ),
                    {
                        PartnerProgramCompanyName:
                            partnersGeneralInformation.partnerProgramCompanyName,
                    },
                )}
                <CookieMoreInfo linkColor={props.linkColor} to="/cookies">
                    {__("Подробнее")}
                </CookieMoreInfo>

                <CookieOk
                    btnColor={props.btnColor}
                    btnCSS={props.btnCSS}
                    onClick={props.onConfrim}
                >
                    Ok
                </CookieOk>
            </CookiesToastContent>
        </CookiesToast>
    );
});
