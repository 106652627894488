import { useRef, memo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { SetFieldValue } from "components/formik";
import { ReactTable } from "components/react-table";
import { Search } from "components/search";
import {
    GroupPartners as IGroupPartners,
    GroupPartnersVariables,
    GetCountries_countries,
    GroupPartners_authorized_manager_main_groupPartners_partners,
} from "gql/types/operation-result-types";
import {
    getColumns,
    makeGetTrProps,
    IListItem,
    useControlCheckboxHandlers,
} from "views/manager/main/partners-groups/common";
import GROUP_PARTNERS from "./group-partners.gql";

interface IProps {
    fieldName: string;
    selectedPartners: IListItem[];
    partnerGroupId: number;
    filter?: {
        partnerId?: number | null;
        country?: GetCountries_countries;
    };
    setFieldValue: SetFieldValue;
}

const GroupPartnersComponent = ({
    fieldName,
    partnerGroupId,
    selectedPartners,
    filter,
    setFieldValue,
}: IProps) => {
    const [__] = useTranslation();
    const { onAdd, onAddAll } = useControlCheckboxHandlers({
        list: selectedPartners,
        fieldName,
        setFieldValue,
    });
    const externalPaginationAndSorting = usePaginationAndSorting();
    const searchValue = useRef("");
    const { data, loading } = useQuery<IGroupPartners, GroupPartnersVariables>(
        GROUP_PARTNERS,
        {
            variables: {
                query: externalPaginationAndSorting.state,
                filter: {
                    partnerId: filter?.partnerId,
                    partnerGroupId,
                    countryId: filter?.country?.id,
                    search: searchValue.current,
                },
            },
            fetchPolicy: "network-only",
            ssr: true,
        },
    );
    const columns =
        getColumns<GroupPartners_authorized_manager_main_groupPartners_partners>(
            { __, onChange: onAdd, onChangeAll: onAddAll },
        );
    const tableData = data?.authorized.manager.main.groupPartners.partners;
    const dataLength = data?.authorized.manager.main.groupPartners.rowsCount;
    const selectedIds = selectedPartners.map(partner => partner.id);

    return (
        <ReactTable
            columns={columns}
            data={tableData}
            dataLength={dataLength}
            loading={loading}
            externalPaginationAndSorting={externalPaginationAndSorting}
            searchComponent={
                <Search
                    onTextChanges={value => {
                        searchValue.current = value;
                    }}
                    onApply={async () => {
                        externalPaginationAndSorting.actions.setPagenum(1);
                    }}
                />
            }
            getTrProps={makeGetTrProps(selectedIds)}
        />
    );
};

export const GroupPartners = memo(GroupPartnersComponent);
