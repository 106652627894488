export { getFileNameWithoutExtention, uploadFile } from "./helpers";
import { FormGroup, FormGroupInputWrapper } from "styled/layouts";
import {
    FileInput,
    FileWrapper,
    UploadButton,
    DeleteFileButton,
} from "./styled";
import { Field } from "components/formik";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { Upload } from "@styled-icons/boxicons-regular/Upload";
import { Close } from "@styled-icons/material-rounded/Close";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";
import { useTranslationList } from "hooks/use-translations-list";

interface AddFileProps {
    name: string;
    values: Record<string, unknown>;
    setFieldValue: (field: string, value: any) => void;
    index?: number;
    accept?: string;
    buttonType: "upload" | "clean";
    placeholder?: string;
    stylesFileField?: CSSProperties;
    label?: string;
    currentValue?: File;
    disabled?: boolean;
    isRequiredFile?: boolean;
}

export const AddFile = ({
    values,
    setFieldValue,
    accept,
    buttonType,
    placeholder,
    stylesFileField,
    name: fieldName,
    label,
    currentValue,
    disabled,
    isRequiredFile,
}: AddFileProps) => {
    const [__] = useTranslation();
    const { stylesTheme } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();

    return (
        <FormGroup>
            <FormGroupInputWrapper>
                <label style={{ flexGrow: 1 }}>
                    <FileInput
                        type="file"
                        accept={accept}
                        onChange={event => {
                            const file = event.target.files?.[0];
                            if (!file) {
                                return;
                            }
                            // TODO: если это все занести внутрь UniversalField и писать просто type="file",
                            // то по идее можно будет избавиться от переопеределения toString
                            Object.getPrototypeOf(file).toString = function () {
                                return this.name;
                            };
                            setFieldValue(fieldName, file);
                            event.target.value = "";
                        }}
                        disabled={disabled}
                    />
                    <FileWrapper>
                        <Field
                            type="text"
                            name={`${fieldName}`}
                            label={label || __("Файл")}
                            placeholder={placeholder}
                            style={stylesFileField}
                            readOnly
                            disabled={disabled}
                            validate={value => {
                                if (!value && isRequiredFile) {
                                    return FIELD_SHOULD_BE_FILLED();
                                }
                            }}
                        />
                    </FileWrapper>
                </label>
                <>
                    {buttonType === "upload" && currentValue && (
                        <UploadButton
                            href={URL.createObjectURL(currentValue)}
                            download={currentValue.name}
                        >
                            <Upload
                                size={26}
                                color={stylesTheme.adminTitleColor}
                            />
                        </UploadButton>
                    )}
                    {buttonType === "clean" && values[fieldName] && (
                        <DeleteFileButton
                            onClick={() => {
                                setFieldValue(fieldName, undefined);
                            }}
                        >
                            <Close
                                size={28}
                                color={stylesTheme.adminTitleColor}
                            />
                        </DeleteFileButton>
                    )}
                </>
            </FormGroupInputWrapper>
        </FormGroup>
    );
};
