import { Field } from "components/formik";
import { PartnerInvoiceStatus } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { getInvoiceStatusName } from "services/project-utils";

export const InvoiceStatusField = () => {
    const [__] = useTranslation();

    const options = Object.keys(PartnerInvoiceStatus).map(status => {
        const currentStatus = status as PartnerInvoiceStatus;
        return {
            value: currentStatus,
            label: getInvoiceStatusName(currentStatus, __),
        };
    });

    return (
        <Field
            name="invoiceStatus"
            key="invoiceStatus"
            type="react-select"
            options={options}
            isLoading={false}
            selectFirst={false}
            label={__("Статус инвойса")}
            minWidth={200}
        />
    );
};
