import { MenuFooter } from "components/menu/menu-footer";

import { SecondMenuSC } from "./styled";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const SecondMenu = React.memo((props: IProps) => {
    return (
        <SecondMenuSC>
            <MenuFooter />
        </SecondMenuSC>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
