import axios, { AxiosResponse } from "axios";
import { TPathsToUpload } from "server/controllers/api/files-api/upload-file-general";
import { RequestError } from "server/errors/request-error";

// Общий метод загрузки файла
// "Философия":

// Графюэль не поддерживает загрузку файлов из коробки и не рекоммендует
// вообще гнать загрузку файлов через него.
// Рекомендованный способ загрузки файлов, это загружать их через другой "канал",
// как особенные данные большого объема, получать после загрузки ссылку на них и ее уже класть в графюэль запросы
// см. https://www.apollographql.com/blog/backend/file-uploads/file-upload-best-practices/

// В идеале мы грузим файл в облако, сервера которого всегда рядом, он быстро загружается и реплицируется,
// мы отправляем ссылку на файл в графюэль, и т.п.
// в конечной точке по ссылке там получают файл из того же облака которое всегда рядом.

// Однако, нам не дали доступ к облаку, и мы можем только загружать файлы через методы апи,
// которых много, кажлый на каждый случай.
// Поэтому мы все же имитируем загрузку файла, одним методом, который идет на один наш серверный метод (не графюэль)
// /lapi/files/upload-file-general который затем "веером" расходится на разные методы загрузки файлов АПИ.

// Дальше идет весьма сложная часть для понимания, сосредоточтесь.

// uploadingPath стоит воспринимать не более как строку, ссылку по которому можно загрузить файл (соот. методу АПИ),
// Читайте ее как "3h3423ff543aabf434", как что-то не читаемое.
// additionalInformation - стоит воспринимать как просто какую-то полезную нагрузку, типа токена чтобы прошла загрузка
// В содержание которой тоже не стоит всматриваться, возможно стоит сделать ээ тоже json строкой,
// чтобы имена АПИ параметров, которые они тоже хотят чтобы загрузить файл не спойлили наши конвенции имен

// И последнее. Есть 2 приницпиально разных случая.
// В каждом из них, со стороны пользвателя все выглядит как одно и тоже,
// пользователь заполняет поле файла, или несколько полей, и потом жмет кнопку формы.
// Однако сама загрузка файла, может происходить 2 разными способами:
// 1. Например, если у АПИ есть метод который загружает просто файл, и дает нам ссылку,
// то загрузка файла произойдет при выборе пользователем файла в поле файла,
// и потом ссылка добавиться в основной запрос формы (случай финплана)
// 2. Если апи не дает отдельный метод на загрузку файла, то файл загружается с другими полями формы.
// (случай заргузки файла рекламных выплат)

// К счастью во втором случае, АПИ обычно не хочет много полей, обычно только имя файла и может что-то еще.
// Они как раз идут как additionalInformation
// Но, предлагаетя думать о юзкейзах типа 2 тоже как о простой загрузке файла,
// со скипнутой ("невидимой ") частью графюэля, которая не нужна т.к. загрузка уже произошла.

// Последнее аффектит как вызывается метод uploadFile. В 1 случае из колбека поля, во 2 из сабмита формы.
// Хотелось бы чтобы все время из колбека поля файла, но к сожалению это невозможно.

export async function uploadFile(
    uploadingPath: TPathsToUpload,
    file: File,
    additionalInformation?: Record<string, unknown>,
) {
    const formData = new FormData();
    formData.append("uploadingPath", uploadingPath);
    formData.append("file", file);

    // add all fields from additionalInformation to formData
    if (additionalInformation) {
        Object.entries(additionalInformation).forEach(([key, value]) => {
            formData.append(key, value as string);
        });
    }

    try {
        const response: AxiosResponse<void | RequestError> = await axios({
            url: "/lapi/files/upload-file-general",
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        });

        return response.data;
    } catch (ex: any) {
        // проверка на RequestError. "Нормальная проверка" не проходит так как не передается поля message (почему?)
        // TODO: сделать норм проверку как мы обычно проверяем на RequestError
        if (ex.response.data?.data?.code) {
            throw new RequestError(ex.response.data.data.code);
        }
        throw ex;
    }
}

export const getFileNameWithoutExtention = (fileName: string) => {
    return fileName.split(".").slice(0, -1).join(".");
};
