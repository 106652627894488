import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { Tag } from "../tag";
import { APIDescriptionSC, CodeTag, PreTag } from "./styled";

export const APIDescription = () => {
    const [__] = useTranslation();

    return (
        <APIDescriptionSC>
            <Title>{__("API получения домена для партнерской ссылки")}</Title>
            <p>{__("Для вызова метода API вам нужно сделать такой запрос")}</p>
            <ul>
                <li>
                    <strong>URL :</strong> /domainresolver/api/domain
                </li>
                <li>
                    <strong>Method :</strong> GET
                </li>
                <li>
                    <strong>Authorization header :</strong> Bearer token
                </li>
            </ul>
            <br />
            <p>{__("Метод API возвращает данные в формате JSON")}</p>

            <PreTag>
                <CodeTag>
                    <Tag tag="{" tabs={1}>
                        <span className="code-attr">&quot;domains&quot;</span>:
                        <Tag tag="{" tabs={2}>
                            <span className="code-attr">&quot;RU&quot;</span>:
                            <Tag tag="[" tabs={3}>
                                <span className="code-string">
                                    &quot;domain here&quot;
                                </span>
                            </Tag>
                        </Tag>
                    </Tag>
                </CodeTag>
            </PreTag>
        </APIDescriptionSC>
    );
};
