import { LocalizedRedirect } from "components/language-provider";
import { ReactElement } from "react";
import { UserConsumer } from "..";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    to: string;
    children: ReactElement;
}

export const RedirectIfUserNotExists = React.memo((props: IProps) => {
    return (
        <UserConsumer>
            {(user, loading) => {
                if (loading) {
                    return null;
                }
                return !user ? (
                    <LocalizedRedirect to={props.to} />
                ) : (
                    props.children
                );
            }}
        </UserConsumer>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
