import { Title } from "services/styled-components/typogoraphy";
import {
    WrapperSteps,
    StepItem,
    StepCircle,
    StepTitle,
    StepItemContent,
} from "./styled";
import { Checkmark } from "@styled-icons/zondicons/Checkmark";

interface IProps {
    titleText: string;
    stepTitles: string[];
    currentStep: number;
}

export const Steps = ({ titleText, stepTitles, currentStep }: IProps) => {
    return (
        <>
            <Title>{titleText}</Title>
            <WrapperSteps>
                {stepTitles.map((title, i) => (
                    <StepItem key={i}>
                        <StepItemContent
                            active={currentStep === i}
                            completed={i <= currentStep - 1}
                        >
                            <StepCircle>
                                {i <= currentStep - 1 ? (
                                    <Checkmark size={16} />
                                ) : (
                                    `${i + 1}`
                                )}
                            </StepCircle>
                            <StepTitle>{title}</StepTitle>
                        </StepItemContent>
                    </StepItem>
                ))}
            </WrapperSteps>
        </>
    );
};
