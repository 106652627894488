// import { GlobalModalStyles } from "components/components-common/modal/styled";
import { ParseParams } from "components/parse-params";
import { LocalizedRedirect } from "components/language-provider";
import {
    SitePartitionType,
    SitePartitionWrapper,
} from "views/site-patrition-wrapper";
import { connect } from "react-redux";
import {
    Route,
    RouteComponentProps,
    Switch,
    withRouter,
} from "react-router-dom";
import { compose } from "redux";
import { ISettings } from "services/redux/root/settings/reducer";
import { settingsSelectors } from "services/redux/root/settings/selectors";
import { statesSelectors } from "services/redux/root/states/selectors";
import urljoin from "url-join";
import { Landing } from "./landing";

import { hot } from "react-hot-loader/root";
import { ConfirmEmailPage } from "views/landing/confirm-email-page";

interface IInjectedProps extends RouteComponentProps {
    lastNotFoundPathname?: string;
    settings: ISettings;
}

interface IProps extends IInjectedProps {}

export const Routes = hot(
    compose(
        withRouter,
        connect(state => ({
            lastNotFoundPathname:
                statesSelectors.getLastNotFoundPathname(state),
            settings: settingsSelectors.getSettings(state),
        })),
    )((props: IProps) => {
        const {
            match: { url: matchUrl },
        } = props;

        // matchUrl содержит матч по которому мы сюда попали.
        // для /es/ например это будет /es
        // а для /en/ однако же будет / т.к. по этому адресу мы попадаем сюда
        // соттв. соотвествия не будет, между /adress и между /en/adress в адресной строке

        return (
            <>
                <Switch>
                    {/* МЕНЕДЖЕРСКО-АГЕНТСКИЙ РАЗДЕЛ */}
                    <Route
                        path={urljoin(matchUrl, "adagent")}
                        render={() => <LocalizedRedirect to="/agent" />}
                    />
                    <Route path={urljoin(matchUrl, "agent")}>
                        <SitePartitionWrapper
                            adminRole={SitePartitionType.Manager}
                        />
                    </Route>

                    <Route
                        path={urljoin(matchUrl, "partner/confirmemail")}
                        component={ConfirmEmailPage}
                        exact
                    />

                    {/* ПАРТНЕРСКИЙ РАЗДЕЛ */}
                    <Route path={urljoin(matchUrl, "partner")}>
                        <SitePartitionWrapper
                            adminRole={SitePartitionType.Partner}
                        />
                    </Route>

                    {/* ЛЕНДИНГ */}
                    <Route path={urljoin(matchUrl)} component={Landing} />
                </Switch>
                <ParseParams />
            </>
        );
    }) as React.ComponentType<Subtract<IProps, IInjectedProps>>,
);
