import { Title } from "components/title";
import { GetCurrencies_authorized_partnerAndManager_data_currencies } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { AdminWrapperContainer } from "styled/layouts";
import { useManagerChart } from "../../hooks/use-manager-chart";
import { ChartWrapper } from "views/partner-manager/chart/chart-wrapper";
import { options } from "./options";
import { Chart } from "views/partner-manager/chart";

interface IProps {
    currency?: GetCurrencies_authorized_partnerAndManager_data_currencies;
    mockPeriod: string;
    loadCurrency: boolean;
}

export const StatisticsChart = React.memo<IProps>(
    ({ currency, mockPeriod, loadCurrency }) => {
        const [__] = useTranslation();
        const {
            data: transformData,
            loading,
            queryResult,
        } = useManagerChart(mockPeriod, currency);

        if (queryResult.error) {
            return (
                <Title>
                    {__("При получении данных для графиков произошла ошибка")}
                </Title>
            );
        }
        const PassChart = (
            <Chart options={options({ transformData, __ }).chart1} />
        );
        const PassChartWrapper = (
            <ChartWrapper
                loading={loading || loadCurrency}
                title={__("Переходы")}
                component={PassChart}
            />
        );

        const ProfitsChart = (
            <Chart options={options({ transformData, __ }).chart2} />
        );

        const ProfitsChartWrapper = (
            <ChartWrapper
                loading={loading || loadCurrency}
                title={__("Доходы")}
                component={ProfitsChart}
            />
        );

        return (
            <AdminWrapperContainer>
                {PassChartWrapper}
                {ProfitsChartWrapper}
            </AdminWrapperContainer>
        );
    },
);
