import { useTranslation } from "react-i18next";
import { QuickStatistic } from "views/partner-manager/statistic-table";
import { TopPartners } from "./top-partners";
import { QuickStatisticTable } from "./quick-statistic-table";
import { TopMarketingTools } from "./top-marketing-tools";
import { ShortTimePeriods } from "gql/types/operation-result-types";
import { ManagerStatisticsChart } from "./manager-statistics-chart";

export const Home = React.memo(() => {
    const [__] = useTranslation();

    return (
        <>
            {__ENVIRONMENT__.production ? (
                <>
                    {__(
                        "Для выполнения этой операции перейдите на агентский домен.",
                    )}
                </>
            ) : (
                <>
                    <ManagerStatisticsChart />
                    <QuickStatistic
                        table={QuickStatisticTable}
                        initialShortPeriod={ShortTimePeriods.TODAY}
                    />
                    <TopPartners />
                    <TopMarketingTools />
                </>
            )}
        </>
    );
});
