import { RouteComponentProps, Switch } from "react-router-dom";
import urljoin from "url-join";

import { Route } from "react-router-dom";
import { PostbacksList } from "./postbacks-list";
import { UpdatePostback } from "./update";
import { CreatePostback } from "./create";

interface IInjectedProps extends RouteComponentProps<{ url: string }> {}

interface IProps extends IInjectedProps {}

export const Postbacks = React.memo((props: IProps) => {
    const { url } = props.match;

    return (
        <Switch>
            <Route
                path={urljoin(url, "/create-postback")}
                component={CreatePostback}
            />
            <Route
                path={urljoin(url, "/update/:id")}
                component={UpdatePostback}
            />
            <Route path={urljoin(url, "/")} component={PostbacksList} />
        </Switch>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
