import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentFilesTable } from "./payment-files-table";
import { Steps } from "components/steps";
import { BackNextButtons } from "components/steps/buttons";
import { PaymentsFromFileTable } from "./payments-from-file-table";

export const AddPaymentsFromFile = () => {
    const [__] = useTranslation();
    const [step, setStep] = useState(0);
    const [fileId, setFileId] = useState<number>();

    const stepData: Array<{
        component: ReactNode;
        stepTitle: string;
    }> = [
        {
            component: (
                <>
                    <PaymentFilesTable
                        chooseFile={currentFileId => {
                            setFileId(currentFileId);
                            setStep(step + 1);
                        }}
                    />
                </>
            ),
            stepTitle: __("Файлы"),
        },
        {
            component: fileId && <PaymentsFromFileTable fileId={fileId} />,
            stepTitle: __("Платежи"),
        },
    ];

    const canGoNext = step !== 0 || fileId !== undefined;

    return (
        <>
            <Steps
                titleText={__("Добавьте рекламную выплату из файла:")}
                stepTitles={stepData.map(item => item.stepTitle)}
                currentStep={step}
            />
            {stepData[step].component}
            <BackNextButtons
                step={step}
                setStep={setStep}
                numberOfSteps={stepData.length}
                canGoNext={canGoNext}
                canGoBack={true}
            />
        </>
    );
};
