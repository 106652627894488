import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import {
    AdvertisementPaymentsFiles,
    AdvertisementPaymentsFilesVariables,
} from "gql/types/operation-result-types";
import { ReactTable } from "components/react-table";
import { useQuery } from "@apollo/client";
import { CellWithButtons } from "components/table-item";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { CheckSquare } from "@styled-icons/boxicons-regular/CheckSquare/CheckSquare";
import { makeUtcCustomPeriod } from "services/project-utils";
import ADVERTISEMENT_PAYMENTS_FILES from "../query.gql";
import { LoadFile } from "../load-file";

interface IProps {
    submittedValues: {
        periodCustom: {
            start: import("moment").Moment;
            end: import("moment").Moment;
        };
        fileId: number | null | undefined;
        fileName: string | null | undefined;
    };
    chooseFile: (fileId: number) => void;
}

export const AdvertisementPaymentsFilesTable = ({
    submittedValues,
    chooseFile,
}: IProps) => {
    const [__] = useTranslation();

    const { start, end } = makeUtcCustomPeriod(
        submittedValues.periodCustom.start,
        submittedValues.periodCustom.end,
    );

    const queryResult = useQuery<
        AdvertisementPaymentsFiles,
        AdvertisementPaymentsFilesVariables
    >(ADVERTISEMENT_PAYMENTS_FILES, {
        variables: {
            filter: {
                fileId: submittedValues.fileId,
                fileName: submittedValues.fileName,
                startPeriod: (start && start.toISOString()) || "",
                endPeriod: (end && end.toISOString()) || "",
            },
        },
    });

    const columns: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "id",
            minWidth: 80,
            Cell: ({ original }) => {
                const handleClick = () => chooseFile(original.id);
                const button = (
                    <ButtonColoredWithIcon
                        btnColor={BtnColor.Faded}
                        type="button"
                        onClick={handleClick}
                    >
                        <CheckSquare size={15} />
                    </ButtonColoredWithIcon>
                );
                return (
                    <CellWithButtons
                        button={button}
                        link={false}
                        text={original.id}
                    />
                );
            },
        },
        {
            Header: __("Имя файла"),
            accessor: "fileName",
            minWidth: 80,
        },
        {
            Header: __("Дата создания"),
            accessor: "dateOfCreation",
            minWidth: 80,
            dataType: "date",
        },
    ];

    return (
        <>
            <LoadFile onLoaded={() => queryResult.refetch()} />
            <ReactTable
                defaultSorted={[
                    {
                        id: "id",
                        desc: true,
                    },
                ]}
                columns={columns}
                data={
                    queryResult?.data?.authorized.manager.advertising
                        .advertisingPayments.advertisingPaymentsFiles.files ||
                    []
                }
                loading={queryResult?.loading}
            />
        </>
    );
};
