import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocalizedAbsolutePush } from "hooks/use-localised-absolute-push";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import {
    AddPartnerGroup as IAddPartnerGroup,
    AddPartnerGroupVariables,
} from "gql/types/operation-result-types";
import { errorHandler } from "services/error-handler";
import {
    AvailablePartners,
    PartnersFilter,
} from "views/manager/main/partners-groups/common";
import ADD_PARTNER_GROUP from "./add-partner-group.gql";
import { AddPartnerGroupForm } from "./form";
import { useValidationSchema } from "./validation";
import { FormValues } from "./models";
import { SelectedPartners } from "./selected-partners";

const initialValues: FormValues = {
    name: "",
    currency: null,
    partners: [],
};

export const AddPartnerGroup = () => {
    const [__] = useTranslation();
    const validationSchema = useValidationSchema();
    const [addPartnerGroup] = useMutation<
        IAddPartnerGroup,
        AddPartnerGroupVariables
    >(ADD_PARTNER_GROUP);
    const push = useLocalizedAbsolutePush();

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async values => {
                try {
                    if (!values.partners.length) {
                        toast.error(__("Партнеры не выбраны"));
                        return;
                    }

                    await addPartnerGroup({
                        variables: {
                            partnerGroup: {
                                name: values.name,
                                currencyId: Number(values.currency?.id),
                                partnerIds: values.partners.map(
                                    partner => partner.id,
                                ),
                            },
                        },
                    });

                    toast.success(__("Партнерская группа создана"));
                    push("/agent/main/partners-groups");
                } catch (error: any) {
                    void errorHandler(error);
                }
            }, validationSchema)}
        >
            {({ values, setFieldValue }) => (
                <AddPartnerGroupForm>
                    <PartnersFilter
                        tableName={__("Доступные партнеры")}
                        withoutGroupsFilter
                    >
                        {({ submittedValues, showPartnersWithoutGroup }) => (
                            <AvailablePartners
                                fieldName="partners"
                                selectedPartners={values.partners}
                                currencyId={values.currency?.id}
                                filter={submittedValues}
                                showPartnersWithoutGroup={
                                    showPartnersWithoutGroup
                                }
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </PartnersFilter>

                    <PartnersFilter tableName={__("Список участников")}>
                        {({ submittedValues }) => (
                            <SelectedPartners
                                formValues={values}
                                filter={submittedValues}
                                setFieldValue={setFieldValue}
                            />
                        )}
                    </PartnersFilter>
                </AddPartnerGroupForm>
            )}
        </FormikConsoleErrors>
    );
};
