import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Action, compose } from "redux";
import { StatesActionCreators } from "services/redux/root/states/actions";
import { statesSelectors } from "services/redux/root/states/selectors";
import { MenuBurger as MenuBurgerSC } from "components/header/styled";

interface IProps extends RouteComponentProps {
    isMainMenuClicked: boolean;
    setIsMainMenuClicked: (visibility: boolean) => Action;
}

export const MenuBurger = compose(
    withRouter,
    connect(
        state => ({
            isMainMenuClicked: statesSelectors.getIsMainMenuClicked(state),
        }),
        dispatch => ({
            setIsMainMenuClicked: (visibility: boolean) =>
                dispatch(StatesActionCreators.setIsMainMenuClicked(visibility)),
        }),
    ),
)(
    React.memo((props: IProps) => {
        return (
            <MenuBurgerSC
                clicked={props.isMainMenuClicked}
                onClick={() =>
                    props.setIsMainMenuClicked(!props.isMainMenuClicked)
                }
            >
                <span />
            </MenuBurgerSC>
        );
    }),
);
