import { PickCurrentLineContext } from "components/get-active-link-context";
import { ITablePagination } from "hooks/use-pagination";
import _ from "lodash";
import { ReactNode, useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RowInfo, TableProps } from "react-table";
import { FilterColumns } from "./filter-columns";
import { ReactTablePagination } from "./pagination";
import { StyledReactTable } from "./styled";
import { SubComponent } from "./sub-row";
import {
    IReactTableColumn,
    IReactTableColumnWithId,
    useColumnsWithIdAndTableId,
    useColumnsWithShow,
    useColumnsWithWidthAndTypedCells,
} from "./column/index";
import {
    LoadingOverlay,
    LoadingWrapper,
} from "./table-select-variables/styled";
import { NoDataText } from "components/no-data";
import { usePagination } from "./pagination/hooks";
import { FormDirectionRow, FormGroup } from "styled/layouts";
import { Row, FormGroupButton } from "styled/layouts";
import { FakeLabel } from "components/filters/form";
import { useRowsCheck, IId, IRowsCheckObj } from "./rows-check/use-rows-check";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { Form } from "formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import * as yup from "yup";

interface ITableForm<StatusType> {
    statusField: JSX.Element;
    initialStatus: StatusType | null;
    // TODO: move searchComponent here (at least)
    onSubmit: (
        checked: Set<string | number>,
        status: StatusType | null,
    ) => Promise<void>;
}

export interface IPropsReactTable<DataType, StatusType>
    extends Partial<Omit<TableProps<DataType>, "columns">> {
    columns: IReactTableColumn[];
    exportComponent?: JSX.Element;
    loadingText?: ReactNode | string;
    tableName?: string;
    dataLength?: number | null; // pagesCount
    externalPaginationAndSorting?: ITablePagination;
    defaultPageSize?: number;
    loading?: boolean;
    total?: any;
    shownColumnsByDefault?: string[];
    searchComponent?: ReactNode;
    linkButtons?: JSX.Element[];
    rowsCheck?: DataType extends IId ? IRowsCheckObj<DataType> : never;
    form?: ITableForm<StatusType>;
}

export const ReactTable = <DataType, StatusType>(
    props: IPropsReactTable<DataType, StatusType>,
) => {
    const {
        data,
        loading,
        exportComponent,
        loadingText,
        dataLength,
        externalPaginationAndSorting,
        searchComponent,
        linkButtons,
        getTrProps,
        columns,
        rowsCheck,
        form,
        ...rest
    } = props;

    const { columnData } = useContext(PickCurrentLineContext);

    const [__] = useTranslation();

    const { columns: columnsWithIdAndTableId, uniqueId } =
        useColumnsWithIdAndTableId(columns);
    const { checkedRows, columnsWithCheck } = useRowsCheck(
        columnsWithIdAndTableId,
        rowsCheck,
    );
    const columnsWithWidthAndTypedCells =
        useColumnsWithWidthAndTypedCells(columnsWithCheck);
    const [filteredColumns, setFilteredColumns] = useState<
        IReactTableColumnWithId[]
    >([]);
    const memoizedOnFilteredColumnsChange = useCallback(
        newfilteredColumns => {
            setFilteredColumns(newfilteredColumns);
        },
        [setFilteredColumns],
    );
    const { hiddenColumns, filteredColumnsWithShow } =
        useColumnsWithShow(filteredColumns);

    const paginationProps = usePagination(
        uniqueId,
        externalPaginationAndSorting,
        props.defaultPageSize,
    );

    const getTrPropsDefault = (tableState: any, rowInfo: any) => ({
        style: {
            display: rowInfo ? "flex" : "none",
            border:
                rowInfo &&
                columnData.id &&
                _.parseInt(rowInfo.original[columnData.name]) ===
                    _.parseInt(columnData.id)
                    ? "1px solid #232f3c"
                    : "",
        },
    });

    const { defaultPageSize, onPageSizeChange, currentPage, setCurrentPage } =
        paginationProps;

    return (
        <>
            {/* FormikConsoleErrors is table's form! It doesn't let all the
            fields here to spill to the outer forms */}
            <FormikConsoleErrors
                initialValues={{
                    status: form ? form.initialStatus : null,
                }}
                // eslint-disable-next-line @typescript-eslint/unbound-method
                onSubmit={onSubmitWrapper(
                    async ({ status }) =>
                        await form?.onSubmit(checkedRows, status),
                    yup.object(),
                )}
                validateOnChange={false}
                validateOnBlur={false}
            >
                <Form>
                    <Row>
                        <FormDirectionRow>
                            <FormGroup>
                                {(form?.statusField ||
                                    searchComponent ||
                                    exportComponent) && <FakeLabel />}
                                <FilterColumns
                                    columns={columnsWithWidthAndTypedCells}
                                    uniqueId={uniqueId}
                                    shownColumnsByDefault={
                                        props.shownColumnsByDefault
                                    }
                                    loading={loading}
                                    onFilteredColumnsChange={
                                        memoizedOnFilteredColumnsChange
                                    }
                                />
                            </FormGroup>
                            {searchComponent}
                            {form && (
                                <>
                                    <FormGroup>{form.statusField}</FormGroup>
                                    <FormGroupButton>
                                        <FakeLabel />
                                        <SubmitButton>
                                            {__("Применить")}
                                        </SubmitButton>
                                    </FormGroupButton>
                                </>
                            )}
                        </FormDirectionRow>

                        <FormDirectionRow direction={"right"}>
                            {linkButtons}
                            {exportComponent && (
                                <FormGroup>
                                    <FakeLabel />
                                    {exportComponent}
                                </FormGroup>
                            )}
                        </FormDirectionRow>
                    </Row>
                </Form>
            </FormikConsoleErrors>
            <StyledReactTable
                LoadingComponent={LoadingMessage}
                {...rest}
                resizable={false}
                data={data}
                loading={loading}
                manual={!!externalPaginationAndSorting}
                SubComponent={
                    hiddenColumns.length
                        ? (row: RowInfo) => (
                              <SubComponent
                                  total={props.total}
                                  row={row}
                                  hiddenColumns={hiddenColumns}
                              />
                          )
                        : null
                }
                noDataText={!loading && <NoDataText />}
                loadingText={loadingText || __("Загрузка...")}
                defaultPageSize={defaultPageSize}
                columns={filteredColumnsWithShow}
                page={currentPage}
                setCurrentPage={setCurrentPage}
                dataLength={dataLength}
                tableName={props.tableName}
                PaginationComponent={ReactTablePagination}
                onPageSizeChange={onPageSizeChange}
                key={uniqueId}
                actions={externalPaginationAndSorting?.actions}
                onSortedChange={(newSorted: any) => {
                    if (externalPaginationAndSorting) {
                        externalPaginationAndSorting.actions.setSorted(
                            newSorted[0],
                        );
                    }
                }}
                getTrProps={getTrProps || getTrPropsDefault}
                getTheadThProps={(
                    _finalState: unknown,
                    _rowInfo: unknown,
                    column: IReactTableColumnWithId,
                ) => ({
                    className: column.sortable === false ? "no-sortable" : "",
                })}
            />
        </>
    );
};

interface ILoading {
    loadingText: string;
    loading: boolean;
}

function LoadingMessage({ loadingText, loading }: ILoading) {
    return (
        loading && (
            <LoadingOverlay>
                <LoadingWrapper>{loadingText}</LoadingWrapper>
            </LoadingOverlay>
        )
    );
}
