import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import moment from "moment";

export function useValidationSchema() {
    const { FIELD_SHOULD_BE_FILLED, DATEEND_LESS_DATESTART } =
        useTranslationList();
    const { momentSchema } = useObjectValidations();

    const validationSchema = yup.object({
        noLimitedGroup: yup.boolean(),
        dateStart: momentSchema.required(),
        dateEnd: momentSchema
            .when("noLimitedGroup", {
                is: false,
                then: (schema: typeof momentSchema) =>
                    schema.required(FIELD_SHOULD_BE_FILLED),
            })
            .when(
                "dateStart",
                (dateStart: moment.Moment, schema: typeof momentSchema) => {
                    return schema.test({
                        test: (dateEnd: moment.Moment) =>
                            moment.isMoment(dateEnd)
                                ? dateEnd.isAfter(dateStart)
                                : true,
                        message: DATEEND_LESS_DATESTART,
                    });
                },
            ),
    });
    return validationSchema;
}
