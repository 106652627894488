import { useTranslation } from "react-i18next";
import { ReportReactTable } from "components/react-table/report-react-table";
import { LoadingText } from "components/react-table/loading-text";
import {
    GetAdvertisementPaymentOrders,
    GetAdvertisementPaymentOrdersVariables,
    SortedAdvertisementPaymentOrdersType,
    ReportStatus,
    ApplyStatus,
    ApplyStatusVariables,
    AdvertisementStatus,
    Rights,
} from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { FormGroup, FormGroupButton } from "styled/layouts";
import { FakeLabel } from "components/filters/form";
import GET_ADVERTISEMENT_PAYMENTS_ORDERS from "../gql/get-advertisement.gql";
import { useQuery, useMutation } from "@apollo/client";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { toast } from "react-toastify";
import APPLY_STATUS from "../gql/apply-status.gql";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import {
    useAdvertisementPaymentsColumns,
    AdvertisingPayment,
} from "./report-table-columns";
import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { ChangeStatusField } from "./change-status-field";
import { Search } from "components/search";
import { useRef, useState } from "react";
import { Export } from "./report";
import { AllUniversalOutputValues } from "components/filters/types";
import { makeUtcCustomPeriod } from "services/project-utils";
import { DealType } from "gql/types/operation-result-types";

interface AdvertisingPaymentsReportTableProps {
    submittedValues: Pick<
        AllUniversalOutputValues,
        "whoCreated" | "currencyAdversitingPayments"
    > & {
        statusAdvertisingPayments: AdvertisementStatus | null;
        periodCustom: {
            start: import("moment").Moment | null;
            end: import("moment").Moment | null;
        };
    };
    onChoosePayment?: (advPayment: AdvertisingPayment) => void;
}

const initialValues = {
    rowSelections: new Set<number>(),
    status: null as AdvertisementStatus | null,
};

export type ApplyStatusValues = typeof initialValues;

const notCheckedStatuses = [
    AdvertisementStatus.SENT,
    AdvertisementStatus.SUCCESS,
    AdvertisementStatus.DECLINED,
    AdvertisementStatus.PARTIAL_PAYOUT,
    AdvertisementStatus.CANCELED,
    AdvertisementStatus.ARCHIVE,
    AdvertisementStatus.APPROVED,
];

export const AdvertisingPaymentsReportTable = ({
    submittedValues,
    onChoosePayment,
}: AdvertisingPaymentsReportTableProps) => {
    const [__] = useTranslation();
    const externalPaginationAndSorting = usePaginationAndSorting();
    const columns = useAdvertisementPaymentsColumns(onChoosePayment);
    const { permissions } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );
    const searchValueRef = useRef("");

    const { start, end } = makeUtcCustomPeriod(
        submittedValues.periodCustom.start,
        submittedValues.periodCustom.end,
    );

    const isFromFinPlan = Boolean(onChoosePayment);

    const variables = {
        filter: {
            whoCreated: submittedValues.whoCreated,
            currencyId: submittedValues.currencyAdversitingPayments?.id,
            advertisementPaymentOrderStatusId:
                submittedValues.statusAdvertisingPayments
                    ? submittedValues.statusAdvertisingPayments
                    : null,
            startPeriod: (start && start.toISOString()) || "",
            endPeriod: (end && end.toISOString()) || "",
            ...externalPaginationAndSorting.state,
            sorted: externalPaginationAndSorting.state
                .sorted as unknown as SortedAdvertisementPaymentOrdersType,
            search: searchValueRef.current,
            dealType: isFromFinPlan ? DealType.DIGITAL : undefined,
            fromFinPlan: onChoosePayment && true,
        },
    };
    const [queryCount, setQueryCount] = useState(0);
    const queryResult = useQuery<
        GetAdvertisementPaymentOrders,
        GetAdvertisementPaymentOrdersVariables
    >(GET_ADVERTISEMENT_PAYMENTS_ORDERS, {
        variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: () => setQueryCount(curQueryCount => curQueryCount + 1),
    });

    const data =
        queryResult?.data?.authorized.manager.advertising.advertisingPayments
            .payments.advertisementPaymentOrders || [];

    const [mutation] = useMutation<ApplyStatus, ApplyStatusVariables>(
        APPLY_STATUS,
        {
            refetchQueries: [
                {
                    query: GET_ADVERTISEMENT_PAYMENTS_ORDERS,
                    variables,
                },
            ],
        },
    );

    const applyStatus = async (
        ids: Set<number>,
        status: AdvertisementStatus | null,
    ) => {
        if (!status) {
            toast.error(__("Статус не выбран"));
            return;
        }
        if (ids.size === 0) {
            toast.error(__("Запись не выбрана"));
            return;
        }

        try {
            await mutation({
                variables: {
                    statusId: status,
                    paymentsIds: Array.from(ids),
                },
            });
            toast.success(__("Статус успешно изменен"));
        } catch (ex: any) {
            void errorHandler(ex, error => {
                switch (error.data.code) {
                    case ErrorType.DIFFERENT_STATUSES:
                        return __(
                            "Для данного действия необходимо выбрать платежи в одинаковом статусе",
                        );
                    case ErrorType.IMPOSIBLE_STATUS_CHANGING:
                        return __("Невозможная смена статуса");
                }
            });
        }
    };

    return (
        <AdminWrapper>
            <ReportReactTable
                exportComponent={
                    submittedValues && !onChoosePayment ? (
                        <Export filters={variables.filter} />
                    ) : undefined
                }
                reportName="Advertising"
                columns={columns}
                externalPaginationAndSorting={externalPaginationAndSorting}
                defaultPageSize={
                    permissions?.rights.includes(
                        Rights.EDIT_ADVERSITING_PAYMENT,
                    )
                        ? 100
                        : undefined
                }
                queryResult={queryResult}
                data={data}
                reportStatus={ReportStatus.SUCCESS}
                dataLength={
                    queryResult?.data?.authorized.manager.advertising
                        .advertisingPayments.payments.rowsCount
                }
                loadingText={<LoadingText fastLoading={true} />}
                linkButtons={
                    permissions?.rights.includes(
                        Rights.CREATE_ADVERSITING_PAYMENT,
                    ) && !onChoosePayment
                        ? [
                              <FormGroupButton key={1}>
                                  <FakeLabel />
                                  <LocalizedNavLinkWithOptionalBackground
                                      showBackground={true}
                                      to="/agent/advertising/payments/add"
                                      exact={true}
                                  >
                                      {__("Добавить выплату")}
                                  </LocalizedNavLinkWithOptionalBackground>
                              </FormGroupButton>,
                              <FormGroupButton key={2}>
                                  <FakeLabel />
                                  <LocalizedNavLinkWithOptionalBackground
                                      showBackground={true}
                                      to="/agent/advertising/payments/add-from-file"
                                      exact={true}
                                  >
                                      {__("Добавить выплаты из файла")}
                                  </LocalizedNavLinkWithOptionalBackground>
                              </FormGroupButton>,
                          ]
                        : undefined
                }
                searchComponent={
                    <FormGroup>
                        <Search
                            onTextChanges={text => {
                                searchValueRef.current = text;
                            }}
                            onApply={async () => {
                                void queryResult.refetch({
                                    filter: {
                                        ...variables.filter,
                                        search: searchValueRef.current,
                                    },
                                });
                            }}
                        />
                    </FormGroup>
                }
                rowsCheck={
                    !onChoosePayment
                        ? {
                              resultHash: queryCount.toString(),
                              shouldShowCheckbox: original =>
                                  !notCheckedStatuses.includes(
                                      original.statusName,
                                  ),
                          }
                        : undefined
                }
                form={
                    !onChoosePayment
                        ? {
                              initialStatus: null as AdvertisementStatus | null,
                              onSubmit: async (checked, status) =>
                                  await applyStatus(
                                      checked as Set<number>,
                                      status,
                                  ),
                              statusField: <ChangeStatusField />,
                          }
                        : undefined
                }
            />
        </AdminWrapper>
    );
};
