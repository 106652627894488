import { PartnerAccount_authorized_partnerAccount_mainAccountData_paymentSystem_fields } from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { LabelOneLine } from "../../../styled";
import { Field } from "components/formik";
import { FormGroup } from "styled/layouts";

interface IProps {
    fields: PartnerAccount_authorized_partnerAccount_mainAccountData_paymentSystem_fields[];
}

export const PaymentFields = ({ fields }: IProps) => {
    const [DT] = useTranslation();

    return (
        <>
            {fields.map((field, i) => {
                return (
                    <FormGroup key={i}>
                        <Field
                            name={field.id}
                            type="text"
                            label={DT(field.name)}
                            labelComponent={LabelOneLine}
                            disabled
                        />
                    </FormGroup>
                );
            })}
        </>
    );
};
