import axios from "axios";
import { saveAs } from "file-saver";
import { GetAdvertisementPaymentOrdersFilter } from "gql/types/operation-result-types";
import { useState } from "react";
import { parse } from "content-disposition";
import { ReportFormat } from "gql/types/enumReportFormat";

export const useExportAdvertisingPayments = (
    filter: GetAdvertisementPaymentOrdersFilter,
    format: ReportFormat,
) => {
    const [loading, setLoading] = useState(false);

    const exportReport = React.useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.post<Blob>(
                "/lapi/get-advertisement-payment-report",
                {
                    filter,
                    format,
                },
                {
                    responseType: "blob",
                },
            );
            const disposition = parse(response.headers["content-disposition"]);

            const blob = new Blob([response.data], {
                type: response.data.type,
            });

            saveAs(blob, disposition.parameters.filename);
        } catch (error: any) {
            log.error(error);
        }

        setLoading(false);
    }, [filter, format]);

    return {
        loading,
        exportReport,
    };
};
