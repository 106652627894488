import { Utils } from "@webx/utils";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { IActions } from "hooks/use-pagination";
import _ from "lodash";
import Pagination from "rc-pagination";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FinalState } from "react-table";
import { PageSizeSelect } from "./page-size-select";
import { PaginationItem } from "./pagination-item";

interface IInjectedProps {
    key: string;
    setCurrentPage: any;
    tableName: string;
    dataLength?: number; // pagesCount
    actions?: IActions;
}

interface IProps extends IInjectedProps, FinalState {
    loading: boolean;
}

export const ReactTablePagination = ((props: IProps) => {
    const {
        page,
        pageSizeOptions,
        pageSize,
        onPageSizeChange,
        data,
        sortedData,
        setCurrentPage,
        tableName,
        actions,
        dataLength,
        loading,
    } = props;
    const [__] = useTranslation();
    const { columnData } = useContext(PickCurrentLineContext);
    const totalItems = data ? data.length : 0;
    const [showedNewItem, setShowedNewItem] = useState(false);

    useEffect(() => {
        if (
            columnData.id &&
            pageSize &&
            totalItems > 0 &&
            columnData.tableName === tableName &&
            !showedNewItem
        ) {
            const targetItem = sortedData.findIndex(
                obj => obj[columnData.name] === _.parseInt(columnData.id),
            );
            if (targetItem === -1) {
                return;
            } else {
                const currentPage = Math.floor(targetItem / pageSize);
                setCurrentPage(currentPage);
                setShowedNewItem(true);
            }
        }
    }, [
        columnData,
        sortedData,
        setCurrentPage,
        tableName,
        totalItems,
        showedNewItem,
        pageSize,
    ]);

    useEffect(() => {
        setShowedNewItem(false);
    }, [columnData]);

    const itemsCount = useMemo(() => {
        if (dataLength) {
            return dataLength;
        }
        if (totalItems > 0) {
            return totalItems;
        }
        return 0;
    }, [dataLength, totalItems]);

    return (
        <div>
            {pageSize && (
                <div>
                    {format(__("Записи с {from} по {to} (всего {n} записей)"), {
                        from:
                            page !== undefined && itemsCount
                                ? page * pageSize + 1
                                : 0,
                        to: Math.min(
                            page !== undefined && itemsCount
                                ? (page + 1) * pageSize
                                : 0,
                            itemsCount,
                        ),
                        n: itemsCount,
                    })}
                </div>
            )}
            <Pagination
                itemRender={(current, type, element) => (
                    <PaginationItem
                        current={current}
                        type={type}
                        element={element}
                        loading={loading}
                        page={page}
                    />
                )}
                current={page !== undefined ? page + 1 : 1}
                total={itemsCount}
                pageSize={pageSize}
                onChange={newPage => {
                    if (actions) {
                        actions.setPagenum(newPage);
                    }
                    setCurrentPage(newPage - 1);
                }}
                showTitle={false}
                showLessItems={Utils.browser.isMobileOrTablet()}
                locale={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    items_per_page: __("/ стр."),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    jump_to: __("Перейти"),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    jump_to_confirm: __("Подтвердить"),
                    page: "",
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    prev_page: __("Назад"),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    next_page: __("Вперед"),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    prev_5: format(__("Предыдущие {n}"), { n: 5 }),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    next_5: format(__("Следующие {n}"), { n: 5 }),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    prev_3: format(__("Предыдущие {n}"), { n: 3 }),
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    next_3: format(__("Следующие {n}"), { n: 3 }),
                }}
            />
            <PageSizeSelect
                inputId={`PageSizeSelect_${props.key}`}
                options={_.map(pageSizeOptions, opt => ({
                    value: opt,
                    label: opt + "",
                }))}
                value={pageSize}
                onChange={(newSize: number) => onPageSizeChange(newSize, 1)}
            />
        </div>
    );
}) as (props: Subtract<IProps, IInjectedProps>) => JSX.Element;
