import { useTranslation } from "react-i18next";
import { RowInfo } from "react-table";
import { SubRowWrapper, SubRowHeader, SubRowCell, CellWrapper } from "./styled";
import { NoData } from "components/no-data";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    total: any;
    row: RowInfo;
    hiddenColumns: any;
}

export const SubComponent = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const data = props.hiddenColumns
        .map((item: any) => {
            const value = props.row.original[item.accessor];
            const totalValue = g(props.total, item.accessor);

            const valueCell = item.Cell
                ? item.Cell({ ...props.row, value })
                : value;

            let wrapper;
            if (!isNaN(totalValue)) {
                const totalValueCell = item.Cell
                    ? item.Cell({ ...props.row, value: totalValue })
                    : totalValue;
                wrapper = (
                    <CellWrapper>
                        <SubRowCell>{valueCell}</SubRowCell>
                        <SubRowCell>{totalValueCell}</SubRowCell>
                    </CellWrapper>
                );
            } else {
                wrapper = (
                    <CellWrapper flexStart={true}>
                        <SubRowCell>{valueCell}</SubRowCell>
                    </CellWrapper>
                );
            }

            return (
                <SubRowWrapper key={item.accessor}>
                    <p>{item.Header}: </p>
                    {wrapper}
                </SubRowWrapper>
            );
        })
        .filter((item: any) => Boolean(item));

    if (!data.length) {
        return <NoData />;
    }

    return (
        <>
            {props.total && (
                <SubRowHeader>
                    <SubRowCell>{__("Текущий")}</SubRowCell>
                    <SubRowCell>{__("Всего")}</SubRowCell>
                </SubRowHeader>
            )}

            {data}
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
