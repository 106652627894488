import {
    FormGroup,
    FormGroupButton,
    FormDirectionRow,
    FormGroupCheckbox,
} from "styled/layouts";
import { Field } from "components/formik";
import { FakeLabel } from "components/filters/form";
import { DeleteButton } from "../../../buttons";
import { useTranslation } from "react-i18next";
import { FieldArray } from "formik";
import { PlacementType } from "gql/types/operation-result-types";
import { ButtonColored } from "styled/button";
import { placementOptions } from "views/manager/advertising/adversiting-payments/components/placement-options";

export interface IPlacement {
    type: PlacementType | null;
    typeName: string;
    sum: number | null;
    percentSum: boolean;
}

interface AddPlacementProps {
    name: string;
    values: IPlacement[];
    disabled?: boolean;
}

export const AddPlacement: React.FC<AddPlacementProps> = props => {
    const { name, values, disabled } = props;
    const [__] = useTranslation();

    return (
        <FieldArray name={name}>
            {({ remove, push }) => (
                <>
                    {values.length > 0 &&
                        values.map((placement, index) => (
                            <FormDirectionRow key={index} stretch>
                                <FormGroup>
                                    <Field
                                        name={`contractPlacements.${index}.type`}
                                        type="react-select"
                                        label={__("Тип размещения")}
                                        options={placementOptions}
                                        selectFirst={false}
                                        isClearable={false}
                                        minWidth={100}
                                        isDisabled={disabled}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name={`contractPlacements.${index}.typeName`}
                                        type="text"
                                        label={__("Другой тип")}
                                        placeholder={__("Название")}
                                        style={{ minWidth: "100px" }}
                                        disabled={
                                            placement.type !==
                                                PlacementType.OTHER || disabled
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        name={`contractPlacements.${index}.sum`}
                                        type="text"
                                        label={__("Cумма")}
                                        style={{ minWidth: "100px" }}
                                        disabled={disabled}
                                    />
                                </FormGroup>
                                <FormGroupCheckbox>
                                    <FakeLabel />
                                    <Field
                                        name={`contractPlacements.${index}.percentSum`}
                                        type="checkbox"
                                        label={__("в %")}
                                        className="labelNowrap"
                                        disabled={disabled}
                                    />
                                </FormGroupCheckbox>
                                {!disabled && (
                                    <FormGroupButton>
                                        <FakeLabel />
                                        <DeleteButton
                                            onClick={() => remove(index)}
                                        />
                                    </FormGroupButton>
                                )}
                            </FormDirectionRow>
                        ))}

                    {!disabled && (
                        <FormDirectionRow stretch>
                            <FormGroupButton>
                                <ButtonColored
                                    type="button"
                                    onClick={async () => {
                                        const newPlacement: IPlacement = {
                                            type: null,
                                            typeName: "",
                                            sum: null,
                                            percentSum: true,
                                        };
                                        push(newPlacement);
                                    }}
                                >
                                    {__("Добавить размещение")}
                                </ButtonColored>
                            </FormGroupButton>
                        </FormDirectionRow>
                    )}
                </>
            )}
        </FieldArray>
    );
};
