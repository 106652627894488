import { GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments } from "gql/types/operation-result-types";
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import * as yup from "yup";

export const useValidationSchema = () => {
    const { nullableInterger, objectRule, pointNumber } =
        useObjectValidations();
    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();

    return yup.object({
        walletNumber: yup.string().required(FIELD_SHOULD_BE_FILLED),
        description: yup.string(),
        currencyAdversitingPayments:
            // eslint-disable-next-line max-len
            objectRule<GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments>(),
        sum: pointNumber(),
        documentLinks: yup.string(),
        partnerId: nullableInterger.required(FIELD_SHOULD_BE_FILLED),
        siteId: nullableInterger,
        paymentMethodId: nullableInterger.required(FIELD_SHOULD_BE_FILLED),
    });
};
