import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { AsideContentSC } from "./styled";

interface IProps {
    children: React.ReactNode;
    updateMenuScrollOffset?: (offset: number) => void;
}
/*
    Scrollbars может плохо реагировать на resize ивенты окна.
    Из-за этого "thumb" может оставаться на экране даже когда скролла нет.
    НО, для мобильных девайсов, где может понадобиться resize используется нативный скролл.
    На изменения контента реагирует как и ожидается.

    свойство "overflow" трогать нельзя, а иначе сломается вёрстка на первый реднер.
    можно отлючать overflow-x или overflow-y.
*/
export const AsideContent = React.memo(
    ({ children, updateMenuScrollOffset }: IProps) => {
        return (
            <AsideContentSC>
                <Scrollbars
                    universal
                    renderThumbVertical={({ style, ...props }) => (
                        <div
                            {...props}
                            style={{
                                ...style,
                                backgroundColor: "#818183",
                                borderRadius: "5px",
                            }}
                            className="thumb-vertical"
                        />
                    )}
                    renderTrackVertical={({ style, ...props }) => {
                        const defaultStyles = {
                            ...style,
                            right: 2,
                            bottom: 2,
                            top: 2,
                            borderRadius: 3,
                        };
                        return (
                            <div
                                style={defaultStyles}
                                {...props}
                                className="track-vertical"
                            />
                        );
                    }}
                    renderView={props => <div {...props} className="view" />}
                    onScrollFrame={e =>
                        updateMenuScrollOffset &&
                        updateMenuScrollOffset(e.scrollTop)
                    }
                >
                    {children}
                </Scrollbars>
            </AsideContentSC>
        );
    },
);
