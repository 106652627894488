import { TitleContext } from "components/admin-context";
import { Helmet } from "react-helmet-with-visor";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    pageTitle?: string;
    additionalPageTitle?: string;
    children: JSX.Element;
}

export const HelmetWrapper = React.memo(
    ({ pageTitle, additionalPageTitle, children }: IProps) => {
        const { setPageTitle, setAddititonalPageTitle } =
            React.useContext(TitleContext);
        React.useEffect(() => {
            if (pageTitle) {
                setPageTitle(pageTitle);
            }
        }, [pageTitle, setPageTitle]);

        React.useEffect(() => {
            setAddititonalPageTitle(additionalPageTitle);
        }, [additionalPageTitle, setAddititonalPageTitle]);

        return (
            <>
                {pageTitle && (
                    <Helmet>
                        <title>{pageTitle}</title>
                    </Helmet>
                )}
                {children}
            </>
        );
    },
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
