import styled, { css } from "styled-components";

export const FileWrapper = styled.div`
    position: relative;
    width: 100%;

    input[type="text"] {
        padding-right: 35px;
        pointer-events: none;
    }
`;

export const FileInput = styled.input`
    opacity: 0;
    visibility: hidden;
    position: absolute;
`;

export const buttonInInputStyles = css`
    position: absolute;
    right: 8px;
    top: 30px;
    opacity: 0.7;
`;

export const UploadButton = styled.a`
    ${buttonInInputStyles};
`;

export const DeleteFileButton = styled.div`
    ${buttonInInputStyles};
`;
