import GET_SITES from "gql/queries/partners/sites.gql";
import { useQuery } from "@apollo/client";
import {
    PartnerSites,
    SitesFilter,
    PartnerSitesVariables,
} from "gql/types/operation-result-types";

type UseGetSitesProps = SitesFilter;

export const useGetSites = ({ hidden, partnerId }: UseGetSitesProps = {}) => {
    const queryResult = useQuery<PartnerSites, PartnerSitesVariables>(
        GET_SITES,
        {
            ssr: true,
            variables: {
                filter: { partnerId, hidden },
            },
        },
    );

    const sitesList = g(
        queryResult.data,
        "authorized",
        "partnerAndManager",
        "data",
        "sites",
    );

    return {
        sitesList,
        loading: queryResult.loading,
        error: queryResult.error,
    };
};
