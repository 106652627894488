import { Field, Form, useFormikContext } from "components/formik";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { useTranslation } from "react-i18next";
import { AdminWrapper, FormGroup } from "styled/layouts";
import { RowTwoColumnsParagraph, FormDirectionRow } from "styled/layouts";
import { FormGroupButton } from "styled/layouts";
import { PostbackColumn } from "./styled";
import { FakeLabel } from "components/filters/form";
import { getInitialValues } from "../get-initial-values";

const TWO_ROWS_MAX_WIDTH = "100%";

type IValues = ReturnType<typeof getInitialValues>;

export const ModeratePostbackForm = () => {
    const [__] = useTranslation();

    const { values } = useFormikContext<IValues>();

    return (
        <Form>
            <AdminWrapper>
                <FormDirectionRow>
                    <FormGroup>
                        <Field name="id" type="text" label="ID" disabled />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="partnerId"
                            type="text"
                            label={__("Партнер")}
                            disabled
                        />
                    </FormGroup>
                    <FormGroup>
                        <Field
                            name="internalId"
                            type="text"
                            label={__("Внутренний id")}
                            disabled
                        />
                    </FormGroup>
                </FormDirectionRow>
                <RowTwoColumnsParagraph>
                    <PostbackColumn>
                        <Field
                            name="campaignId"
                            type="react-select"
                            label={__("Кампания")}
                            options={values.campaigns}
                        />
                        <Field
                            name="typeRequestId"
                            type="react-select"
                            label={__("Метод запроса")}
                            options={values.typeRequests}
                        />
                    </PostbackColumn>
                    <PostbackColumn>
                        <Field
                            name="linkForPostback"
                            type="textarea"
                            label={__("Пример ссылки Postbacks")}
                            rows={5}
                            disabled
                        />
                    </PostbackColumn>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph>
                    <PostbackColumn>
                        <Field
                            name="postbackTypeId"
                            type="react-select"
                            label={__("Тип Postbacks")}
                            options={values.postbackTypes}
                        />
                        <Field
                            name="variable"
                            type="text"
                            label={__("Название события в вашей системе")}
                        />
                    </PostbackColumn>
                    <PostbackColumn>
                        <Field
                            name="linkForTraffic"
                            type="textarea"
                            label={__("Пример ссылки для размещения")}
                            rows={5}
                        />
                    </PostbackColumn>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <Field name="url" type="text" label={"URL"} />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <Field
                            name="staticParameters"
                            type="text"
                            label={"Static Parametrs"}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph>
                    <PostbackColumn>
                        <Field
                            name="isClickIdPostbackParameter"
                            type="checkbox"
                            label="{{click_id}}"
                        />
                        <Field
                            name="clickIdPostbackParameter"
                            type="text"
                            disabled={!values.isClickIdPostbackParameter}
                        />
                    </PostbackColumn>
                    <PostbackColumn>
                        <Field
                            name="isSiteIdPostbackParameter"
                            type="checkbox"
                            label="{{site_id}}"
                        />
                        <Field
                            name="siteIdPostbackParameter"
                            type="text"
                            disabled={!values.isSiteIdPostbackParameter}
                        />
                    </PostbackColumn>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph>
                    <PostbackColumn>
                        <Field
                            name="isPartnerIdPostbackParameter"
                            type="checkbox"
                            label="{{partner_id}}"
                        />
                        <Field
                            name="partnerIdPostbackParameter"
                            type="text"
                            disabled={!values.isPartnerIdPostbackParameter}
                        />
                    </PostbackColumn>
                    <PostbackColumn>
                        <Field
                            name="isOtherPostbackParameter"
                            type="checkbox"
                            label="{{other}}"
                        />
                        <Field
                            name="otherPostbackParameter"
                            type="text"
                            disabled={!values.isOtherPostbackParameter}
                        />
                    </PostbackColumn>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph>
                    <PostbackColumn>
                        <Field
                            name="isTransaction"
                            type="checkbox"
                            label="{{transaction}}"
                        />
                        <Field
                            name="transaction"
                            type="text"
                            disabled={!values.isTransaction}
                        />
                    </PostbackColumn>
                    <PostbackColumn>
                        <Field
                            name="isSumdepPostbackParameter"
                            type="checkbox"
                            label="{{sumdep}}"
                        />
                        <Field
                            name="sumdepPostbackParameter"
                            type="text"
                            disabled={!values.isSumdepPostbackParameter}
                        />
                    </PostbackColumn>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <Field
                            name="childGuid"
                            type="react-select"
                            label={__("Второй Postbacks")}
                            options={values.childGuids}
                        />
                    </FormGroup>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <PostbackColumn>
                        <Field
                            name="statusId"
                            type="react-select"
                            label={__("Текущий статус")}
                            options={values.statuses}
                            isClearable={false}
                            isDisabled
                        />
                    </PostbackColumn>
                    <PostbackColumn>
                        <Field
                            name="newStatusId"
                            type="react-select"
                            label={__("Новый статус")}
                            options={values.statuses}
                        />
                    </PostbackColumn>
                </RowTwoColumnsParagraph>
                <RowTwoColumnsParagraph formGroupsMaxWidth={TWO_ROWS_MAX_WIDTH}>
                    <FormGroup>
                        <FakeLabel />
                        <FormGroupButton>
                            <SubmitButton>{__("Применить")}</SubmitButton>
                        </FormGroupButton>
                    </FormGroup>
                </RowTwoColumnsParagraph>
            </AdminWrapper>
        </Form>
    );
};
