import { Column, ColumnRenderProps } from "react-table";
import { getSimpleHashCode } from "services/project-utils";
import { useState, useMemo, useCallback } from "react";
import _ from "lodash";
import { CurrencyCell } from "../currency-cell/index";
import { useTranslation } from "react-i18next";
import { transFormDate } from "views/partner/main/comission-structure/services/comission-description";
import { useResize } from "hooks/use-resize";

interface IOriginal {
    [key: string]: any;
}

export type IReactTableColumn = NonNullable<Omit<Column, "Cell" | "Header">> &
    (
        | {
              dataType?: "percent";
          }
        | {
              dataType?: "currency";
              currency: string | "RUB" | "USD" | "BTC";
          }
        | {
              dataType?: "boolean";
          }
        | {
              dataType?: "date";
          }
        | {
              dataType?: "dateWithTime";
          }
    ) & {
        accessor: string;
        Header:
            | string
            | ((cellInfo: ColumnRenderProps, column: any) => React.ReactNode);
        color?: string;
        disabled?: boolean;
    } & {
        Cell?:
            | ((cellInfo: IReportCellInfo, column: any) => React.ReactNode)
            | React.ReactNode;
    };

export type IReactTableColumnWithId = IReactTableColumn & { id: string };

// урезанный интерфейс CellInfo, чтобы быть способным вызывать ее из Footer
export interface IReportCellInfo {
    value: any;
    column: IReactTableColumn;
    original: IOriginal;
}

export function useColumnsWithIdAndTableId(columns: IReactTableColumn[]) {
    // тут происходит конвертация IReactTableColumn в IReactTableColumnWithId
    // чтобы гарантировать что у каждой колонки есть id. Задать егораньше - проблематично,
    // т.к. в большинстве случаев придется писать одну и ту же строчу 2 раза и в accessor и в id
    const columnsWithIds = useMemo(
        () =>
            columns.map(clm => {
                clm.id = `${clm.id || (clm.accessor as string)}`;
                return clm;
            }),
        [columns],
    ) as IReactTableColumnWithId[];

    const [uniqueId] = useState(
        () =>
            `rt_${getSimpleHashCode(
                _.map(columns, clm => `field_${clm.id}`).join("_"),
            )}`,
    );

    return {
        columns: columnsWithIds,
        uniqueId,
    };
}

export function useColumnsWithWidthAndTypedCells(
    columns: IReactTableColumnWithId[],
) {
    const [__] = useTranslation();
    const getColumnWidth = useCallback(
        (column: NonNullable<typeof columns>[0]) => {
            const maxWidth = 400;
            const magicSpacing = 18;
            const cellLength = Math.max(
                ..._.map(columns, row => (`${row.accessor}` || "").length),
                _.isString(column.Header)
                    ? Math.max(
                          ...column.Header.split(" ").map(word => word.length),
                      )
                    : 0,
            );
            return Math.min(maxWidth, cellLength * magicSpacing);
        },
        [columns],
    );

    const columnsWithTypedCells = useMemo(
        () =>
            _.map(columns, column => {
                if (!column.Cell) {
                    switch (column.dataType) {
                        case "percent":
                            column.Cell = cellInfo => `${cellInfo.value}%`;
                            break;
                        case "currency":
                            column.Cell = cellInfo => (
                                <CurrencyCell
                                    value={cellInfo.value}
                                    currency={column.currency}
                                />
                            );
                            break;
                        case "boolean":
                            column.Cell = cellInfo =>
                                cellInfo.value ? __("Да") : __("Нет");
                            break;
                        case "date":
                            column.Cell = cellInfo => {
                                return transFormDate(cellInfo.value);
                            };
                            break;
                        case "dateWithTime":
                            column.Cell = cellInfo => {
                                return transFormDate(cellInfo.value, true);
                            };
                            break;
                    }
                }

                if (!column.minWidth) {
                    column.minWidth = getColumnWidth(column);
                }
                return column;
            }),
        [__, columns, getColumnWidth],
    );

    return columnsWithTypedCells;
}

export function useColumnsWithShow(filteredColumns: IReactTableColumnWithId[]) {
    const { getVisibility } = useResize();
    const filteredColumnsWithShow = assignShowOnColums(
        filteredColumns,
        getVisibility,
    );

    const hiddenColumns = filteredColumnsWithShow.filter(
        c => c.show !== undefined && !c.show,
    );
    if (hiddenColumns.length) {
        filteredColumnsWithShow.unshift({
            id: "",
            Header: "",
            accessor: "expander",
            expander: true,
            headerClassName: "th-expander",
        });
    }

    return {
        hiddenColumns,
        filteredColumnsWithShow,
    };
}

// initialWidth - начальная ширина экрана, при которой можно показывать одну колонку
// отсальные, получаются путем прибавления ширины новых колонок
const assignShowOnColums = (
    assignColumns: IReactTableColumnWithId[],
    getVisibility: (media: number) => boolean,
) => {
    const stretchCoefficient = 1.5; // подобран абсолютно эмпирически
    let currentWidth = 0; // текущая ширина таблицы
    return assignColumns.map(clm => {
        const clmClone = _.clone(clm);
        if (!clmClone.show) {
            // по идее minWidth должен быть всегда задан
            const newCurrentWidth = currentWidth + (clm.minWidth || 100);
            // если ширина таблицы к текущему моменту превышает ширину экрна,
            // то столбец не будет показан
            clmClone.show = getVisibility(newCurrentWidth * stretchCoefficient);
            currentWidth = newCurrentWidth;
        }
        return clmClone;
    });
};
