import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";
import { useFormikContext } from "components/formik";
import { useState } from "react";
import * as moment from "moment";
import { Variables } from "services/variables";

import { useTranslation } from "react-i18next";

interface IDateProps {
    name: string;
    id: string;
    disabled?: boolean;
}

export const DatePicker = ({ name, id, disabled }: IDateProps) => {
    const [, i18n] = useTranslation();
    const formik = useFormikContext<Record<string, unknown>>();
    const [focus, setFocus] = useState(false);

    return (
        <SingleDatePicker
            date={(formik.values[name] as moment.Moment) || null}
            onDateChange={date => {
                formik.setFieldValue(name, date || "");
            }}
            focused={focus}
            onFocusChange={({ focused }) => setFocus(focused)}
            numberOfMonths={1}
            displayFormat={Variables.dateFormat.dateOnly}
            isOutsideRange={() => false}
            id={id}
            isRTL={i18n.dir() === "rtl"}
            placeholder=""
            disabled={disabled}
        />
    );
};
