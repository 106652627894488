import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";

export const useReportColumns = (currency: string) => {
    const [__] = useTranslation();
    const columns: IReactTableColumn[] = [
        {
            Header: __("ID сайта"),
            accessor: "siteId",
            disabled: true,
            minWidth: 80,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            disabled: true,
            minWidth: 120,
        },
        {
            Header: __("Просмотры"),
            accessor: "countOfViews",
            minWidth: 80,
        },
        {
            Header: __("Клики"),
            accessor: "countOfClicks",
            minWidth: 80,
        },
        {
            Header: __("Прямые ссылки"),
            accessor: "countOfDirectLinks",
            minWidth: 80,
        },
        {
            Header: "CTR",
            accessor: "CTR",
            dataType: "percent",
            minWidth: 80,
        },
        {
            Header: __("Регистрации"),
            accessor: "countOfRegistrations",
            minWidth: 80,
        },
        {
            Header: __("Регистрации с депозитом"),
            accessor: "countOfRegistrationsWithDeposits",
            minWidth: 80,
        },
        {
            Header: __("Аккаунты с депозитами"),
            accessor: "countOfAccountsWithDeposits",
            minWidth: 80,
        },
        {
            Header: __("Новые аккаунты с депозитами"),
            accessor: "countOfNewAccountsWithDeposits",
            minWidth: 80,
        },
        {
            Header: __("Сумма новых депозитов"),
            accessor: "newDepositsSum",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("Сумма всех депозитов"),
            accessor: "depositAmount",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("Количество депозитов"),
            accessor: "countOfDeposits",
            minWidth: 80,
        },
        {
            Header: __("Активные игроки"),
            accessor: "countOfActivePlayers",
            minWidth: 80,
        },
        {
            Header: __("Средний доход с игрока"),
            accessor: "averageProfitPlayers",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("Сумма бонусов"),
            accessor: "bonusAmount",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: __("Доход компании (общий)"),
            accessor: "profit",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
        {
            Header: "RS",
            accessor: "comissionRS",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("CPA"),
            accessor: "CPA",
            dataType: "currency",
            currency,
            minWidth: 80,
        },
        {
            Header: __("Сумма комиссий"),
            accessor: "comissionAmount",
            dataType: "currency",
            currency,
            minWidth: 100,
        },
    ];
    return columns;
};
