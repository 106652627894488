import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { ContractForm, ContractValues } from "../../contract-form";
import {
    CreateFinplan,
    CreateFinplanVariables,
    FinantialPlanContract,
    FinantialPlanStatus,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import CREATE_FINPLAN from "./create-finplan.gql";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { makeUtcCustomPeriod } from "services/project-utils";
import { errorHandler } from "services/error-handler";
import { useInitilaValuesAndValidation } from "../../contract-form/use-initilal-values-and-validation";
import { AdminWrapper, FormGroup } from "styled/layouts";
import { Field } from "components/formik";
import { useLocalizedAbsolutePush } from "hooks/use-localised-absolute-push";
import FINANTIAL_PLANS from "../../query.gql";
import moment from "moment";

export type CreatedFinplan = { id: number } & CreateFinplanVariables;

interface IProps {
    partnerId: number;
    initialFormValues?: ContractValues;
    onFormChange: (formValues: ContractValues) => void;
}

export const CreateFinplanByContract = (props: IProps) => {
    const [__] = useTranslation();
    const push = useLocalizedAbsolutePush();
    const [create] = useMutation<CreateFinplan, CreateFinplanVariables>(
        CREATE_FINPLAN,
    );
    const { initialValues: initialValuesDefaultAdd, validationSchema } =
        useInitilaValuesAndValidation();

    const initialValuesDefault = {
        ...initialValuesDefaultAdd,
        finplanContractStatus: FinantialPlanStatus.INTERMIDIATE,
    };

    const options = [
        {
            value: FinantialPlanStatus.INTERMIDIATE,
            label: __("Черновик"),
        },
        { value: FinantialPlanStatus.CREATED, label: __("Создан") },
    ];

    return (
        <FormikConsoleErrors
            initialValues={props.initialFormValues || initialValuesDefault}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(async values => {
                try {
                    const { start, end } = makeUtcCustomPeriod(
                        values.contractDuration.start,
                        values.contractDuration.end,
                    );

                    if (!start || !end) {
                        throw new Error("Invalid contractDuration");
                    }

                    const filePaths = values.filesContent
                        .filter(item => !!item.path)
                        .map(item => item.path);
                    if (!filePaths.length) {
                        filePaths.push("");
                    }

                    const contract: FinantialPlanContract = {
                        contractNumber: values.contractNumber,
                        contractName: values.contractName,
                        dateStart: start.toISOString(),
                        dateEnd: end.toISOString(),
                        countryId: values.country?.id,
                        contractCountryId: values.contractCountry?.id,
                        paymentMethod: {
                            id: values.paymentSystem.id,
                            fields: values.paymentSystem.fields?.map(
                                ({ id, value }) => ({
                                    id,
                                    value,
                                }),
                            ),
                        },
                        contactFullName: values.contactFullName,
                        phone: values.phone,
                        email: values.email,
                        advCurrencyId: values.currencyAdversitingPayments.id,
                        contractSum: values.contractSumm,
                        contractPlacements: values.contractPlacements || [],
                        message: values.message,
                        companyId: values.company,
                        filePaths,
                        status: values.finplanContractStatus,
                    };

                    const finplanToCreate = {
                        partnerId: props.partnerId,
                        contract,
                    };

                    await create({
                        variables: finplanToCreate,
                        refetchQueries: () => {
                            return [
                                {
                                    query: FINANTIAL_PLANS,
                                    variables: {
                                        filter: {
                                            startPeriod:
                                                moment().startOf("day"),
                                            endPeriod: moment().endOf("day"),
                                        },
                                    },
                                },
                            ];
                        },
                    });
                    push("/agent/advertising/fin-plans");
                } catch (e: any) {
                    void errorHandler(e);
                    throw e;
                }
            }, validationSchema)}
        >
            {({ values, setFieldValue }) => (
                <>
                    <AdminWrapper width="32%">
                        <FormGroup>
                            <Field
                                name="finplanContractStatus"
                                key="finplanContractStatus"
                                type="react-select"
                                options={options}
                                isLoading={false}
                                selectFirst={false}
                                label={__("Статус")}
                                minWidth={200}
                                isClearable={false}
                            />
                        </FormGroup>
                    </AdminWrapper>
                    <Title>{__("Информация о контракте")}</Title>
                    <ContractForm
                        values={values}
                        setFieldValue={setFieldValue}
                        onFormChange={props.onFormChange}
                        submitButtonText={__("Сохранить контракт")}
                    />
                </>
            )}
        </FormikConsoleErrors>
    );
};
