import { GetCommissionGroups } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

export const GET_COMMISSION_GROUPS = gql`
    query GetCommissionGroups {
        authorized {
            manager {
                main {
                    commisionGroups {
                        id
                        name
                        currency
                        description
                        countOfPartners
                        status
                    }
                }
            }
        }
    }
`;

export const useCommissionGroups = () => {
    const { data, loading } = useQuery<GetCommissionGroups>(
        GET_COMMISSION_GROUPS,
        {
            ssr: true,
        },
    );

    const commisionGroups = data?.authorized.manager.main.commisionGroups;

    return { data: commisionGroups, loading };
};
