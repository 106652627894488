import { media } from "services/styled-components/variables";
import styled, { css } from "styled-components";
import { WrapperFullWidth } from "styled/layouts";

export const FooterSC = styled.footer`
    font-size: 13px;
    flex: 0 0 auto;
    color: ${props => props.theme.textColor};
    ${props => css`
        background-color: ${props.theme.backBackgroundColor};
    `}
    padding: 25px 10px;
    ${/* sc-sel */ WrapperFullWidth} {
        padding-top: 0;
        padding-bottom: 0;
    }
    ${media.mdMax`
        text-align: center;
    `}

    .footer-ask-questions {
        display: none;
        margin-bottom: 25px;

        ${media.smMax`
            display: flex;

            &:not(:last-child) {
                border-right: 0;
            }

            a {
                width: auto;
            }
        `}
    }
`;

export const FooterContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    ${media.mdMax`
        flex-direction: column;
    `}
`;

export const FooterText = styled.p`
    font-size: 12px;
    color: ${props => props.theme.mutedTextColor};
    margin-bottom: 25px;
    a {
        color: ${props => props.theme.textColor};
        text-decoration: underline;
    }
    ${media.mdMax`
        display: flex;
        flex-direction: column;
    `}
`;

export const Copyright = styled.div`
    width: 100%;
`;
