import { IReactTableColumn } from "components/react-table/column";
import { TFunction } from "react-i18next";
import { ButtonsWithIdColumn } from "./buttons-with-id-column";

export const getPostbacksColumns = (__: TFunction) => {
    const columns: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "id",
            disabled: true,
            minWidth: 100,
            Cell: ({ original }) => <ButtonsWithIdColumn id={original?.id} />,
        },
        {
            Header: __("Партнер"),
            disabled: true,
            accessor: "partnerId",
            width: 100,
        },
        {
            Header: __("Внутренний ID"),
            accessor: "campaignId",
            minWidth: 120,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            minWidth: 120,
        },
        {
            Header: __("Postback короткий URL"),
            accessor: "url",
            minWidth: 120,
        },
        {
            Header: __("Событие"),
            accessor: "typeName",
            minWidth: 100,
        },
        {
            Header: __("Статус"),
            accessor: "statusName",
            minWidth: 90,
        },
        {
            Header: __("Комментарий"),
            accessor: "comment",
            minWidth: 80,
        },
    ];

    return columns;
};
