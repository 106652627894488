import { PartnerTableState } from "components/table-item";
import {
    ToggleShowLink,
    ToggleShowLinkVariables,
} from "gql/types/operation-result-types";
import { useMutation } from "@apollo/client";
import TOGGLE_SHOW_LINK from "../gql/toggle-show-link.gql";
import { successHandler, SuccessTypes } from "services/success-handler";
import { errorHandler } from "services/error-handler";
import GET_PARTNER_LINKS from "../gql/get-partner-links.gql";
import { useGetSites } from "hooks/query/use-get-sites";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface IProps {
    linkId: number;
    hidden: boolean;
    siteId: number;
}

export const ToogleShowLink = ({ linkId, hidden, siteId }: IProps) => {
    const { sitesList } = useGetSites();
    const [__] = useTranslation();
    const [mutation, { loading }] = useMutation<
        ToggleShowLink,
        ToggleShowLinkVariables
    >(TOGGLE_SHOW_LINK, {
        refetchQueries: [
            {
                query: GET_PARTNER_LINKS,
                variables: {
                    hidden,
                },
            },
            {
                query: GET_PARTNER_LINKS,
                variables: {
                    hidden: !hidden,
                },
            },
        ],
    });

    return (
        <PartnerTableState
            toggleLink={async () => {
                // нельзя открыть ссылку если ее сайт скрыт
                if (hidden) {
                    const linksSite = sitesList?.find(
                        site => site.id === siteId,
                    );
                    if (linksSite && linksSite?.hidden) {
                        toast.error(
                            format(
                                __(
                                    "Сделайте связанный сайт с id {siteId} видимым перед тем как сделать ссылку видимой",
                                ),
                                {
                                    siteId,
                                },
                            ),
                        );
                        return;
                    }
                }
                try {
                    await mutation({
                        variables: { id: linkId, hidden: !hidden },
                    });
                    successHandler(
                        hidden
                            ? SuccessTypes.SHOW_LINK
                            : SuccessTypes.HIDE_LINK,
                        linkId,
                    );
                } catch (error: any) {
                    void errorHandler(error);
                }
            }}
            hidden={hidden}
            loading={loading}
        />
    );
};
