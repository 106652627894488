import { usePaymentSystemsWithFields } from "../use-payment-systems-with-fields";

export function usePaymentSystemsWithFieldsAndTranslations(
    showDisabled: boolean,
) {
    const { paymentSystems, validateField } =
        usePaymentSystemsWithFields(showDisabled);
    return {
        paymentSystemsOptions: paymentSystems.map(ps => ({
            label: ps.name,
            value: ps,
        })),
        validateField,
    };
}
