import { Access } from "components/check-roles/access";
import { FooterText } from "components/footer/styled";
import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";
import { useDefinedContext } from "hooks/use-context-exist";
import { useTranslation } from "react-i18next";
import { PartnersProgramGeneralInformationContext } from "views";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Cookies = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { partnerProgramCompanyName } = useDefinedContext(
        PartnersProgramGeneralInformationContext,
    );

    return (
        <Access name="cookies">
            <FooterText>
                {format(
                    __(
                        "{PartnerProgramCompanyName} использует cookie-файлы, чтобы гарантировать вам максимальное удобство. Если Вы остаетесь на сайте, вы соглашаетесь на использование нами ваших cookie-файлов на {PartnerProgramCompanyName}.",
                    ),
                    {
                        PartnerProgramCompanyName: partnerProgramCompanyName,
                    },
                )}{" "}
                <LocalizedNavLinkWithOptionalBackground to="/cookies">
                    {__("Подробнее")}
                </LocalizedNavLinkWithOptionalBackground>
            </FooterText>
        </Access>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
