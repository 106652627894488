import { Tabs } from "components/tabs";
import { Tab } from "components/tabs/components/tab";
import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { HelmetWrapper } from "components/helmet/helmet-wrapper";

export function Content() {
    const [__] = useTranslation();

    return (
        <>
            <Title>{__("Группы текстов")}</Title>
            <Tabs name="editContent" default={0}>
                <Tab title={__("Правила и условия")} key={0}>
                    <HelmetWrapper
                        additionalPageTitle={__("Правила и условия")}
                    >
                        <Title>{__("Правила и условия")}</Title>
                    </HelmetWrapper>
                </Tab>
                <Tab title={__("Контакты")} key={1}>
                    <HelmetWrapper additionalPageTitle={__("Контакты")}>
                        <Title>{__("Контакты")}</Title>
                    </HelmetWrapper>
                </Tab>
                <Tab title={__("Политика конфиденциальности")} key={2}>
                    <HelmetWrapper
                        additionalPageTitle={__("Политика конфиденциальности")}
                    >
                        <Title>{__("Политика конфиденциальности")}</Title>
                    </HelmetWrapper>
                </Tab>
                <Tab title={"Cookies"} key={3}>
                    <HelmetWrapper additionalPageTitle={"Cookies"}>
                        <Title>Cookies</Title>
                    </HelmetWrapper>
                </Tab>
                <Tab title={"FAQ"} key={4}>
                    <HelmetWrapper additionalPageTitle={"FAQ"}>
                        <Title>FAQ</Title>
                    </HelmetWrapper>
                </Tab>
                <Tab title={__("Новости")} key={5}>
                    <HelmetWrapper additionalPageTitle={__("Новости")}>
                        <Title>{__("Новости")}</Title>
                    </HelmetWrapper>
                </Tab>
                <Tab title={__("Отзывы")} key={6}>
                    <HelmetWrapper additionalPageTitle={__("Отзывы")}>
                        <Title>{__("Отзывы")}</Title>
                    </HelmetWrapper>
                </Tab>
                <Tab title={__("Загруженные файлы")} key={7}>
                    <HelmetWrapper
                        additionalPageTitle={__("Загруженные файлы")}
                    >
                        <Title>{__("Загруженные файлы")}</Title>
                    </HelmetWrapper>
                </Tab>
            </Tabs>
        </>
    );
}
