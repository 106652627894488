import { ReactNode } from "react";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    loaded: boolean;
    children: ReactNode;
}

export const LoaderSpinner = React.memo((props: IProps) => {
    return <>{props.loaded ? props.children : <span />}</>;
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
