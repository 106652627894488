export enum ErrorType {
    // < 1000 логируются
    UNEXPECTED_ERROR = 1,
    INVALID_PARAMS,
    INTERMIDIATE_2FA_DOES_NOT_EXISTS, // для 2fa
    INVALID_CAPTCHA,
    INVALID_OPERATION,
    VALIDATION_ERROR,
    INTERNAL_SERVER_ERROR,
    TOO_MANY_REQUESTS,
    UNXPECTED, // depricated
    INVALID_TOKEN,

    FEEDBACK_WRONG_AGENT,
    FEEDBACK_MESSAGE_NO_SEND,

    PASSWORD_NO_RESET_PASSWORD,
    PASSWORD_RESET_ERROR,
    PASSWORD_RESET_MESSAGE_NO_SEND,

    SIGN_IN_LIKE_A_PARTNER_ERROR,
    SIGN_IN_LIKE_A_PARTNER_LOGIN_NEEDED,

    RESOURCE_HAS_BEEN_ALREADY_DELETED,

    // >= 1000 не логируются
    LOGIN_IS_ALREADY_TAKEN = 1000,
    EMAIL_IS_ALREADY_TAKEN,
    INVALID_USER_OR_PASSWORD,
    USER_ON_MODERATION,
    INVALID_CURRENT_PASSWORD,
    INVALID_TWO_FA_TOKEN,
    NO_ATTEMPTS_LEFT,
    INVALID_TEMPORARY_CODE,
    UNKNOWN_PLACE,
    ACCESS_DENIED,
    LIMIT_OF_PROMOCODES,
    SITE_ALREADY_EXISTS,
    LIMIT_OF_MEDIA_UPLOADS,
    FILE_ALREADY_EXISTS,
    DIFFERENT_STATUSES,
    IMPOSIBLE_STATUS_CHANGING,
    INVALID_FILE_STRUCTURE,
    ADV_PAYMENT_SUM_PLACEMENT_UNEQUAL,
    INVALID_FILE_FORMAT,
    DOWNLOAD_APK_TIME_LIMIT,
    DOWNLOAD_APK_ERROR,
    FILE_SIZE_EXCEEDED,
    // перезагружают текущую страницу
    DISALLOWED_REQUEST,
    USER_DOES_NOT_EXISTS,
    INVALID_CSRF_TOKEN,
    // TODO: нужен ли он вместе с ACCESS_DENIED? Однако API часто не кидает ACCESS_DENIED (403)
    UNEXPECTED_USER_ROLE,
    IDENTICAL_CONNECTION_EXISTS,
    PROMOCODE_ALREADY_EXISTS,
}
