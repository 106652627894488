import { GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments } from "gql/types/operation-result-types";

export const convertToAmountRUB = (
    amountRequest: string | number,
    // eslint-disable-next-line max-len
    currency?: GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments | null,
) => {
    if (amountRequest && currency && !isNaN(Number(amountRequest))) {
        return Number(
            (Number(amountRequest) / Number(currency.baseRate)).toFixed(2),
        );
    }

    return null;
};
