import React from "react";
import { ReportFormat } from "gql/types/enumReportFormat";
import { exportButtonsDesc } from "components/react-table/table-select-variables/export/services/buttons";
import { Dropdown } from "components/react-table/table-select-variables/export/components/dropdown";
import { ExportButton } from "./export-button";

interface IProps {
    isSubmitting: boolean;
    onClick: (reportFormat: ReportFormat) => void;
    format?: ReportFormat | null;
}

export const Export = ({ onClick, isSubmitting, format }: IProps) => {
    return (
        <Dropdown>
            {exportButtonsDesc.map(button => (
                <ExportButton
                    {...button}
                    onClick={onClick}
                    loading={
                        isSubmitting &&
                        ReportFormat[
                            button.format.toUpperCase() as keyof typeof ReportFormat
                        ] === format
                    }
                    key={button.format}
                />
            ))}
        </Dropdown>
    );
};
