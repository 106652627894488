import { PartnerTableState } from "components/table-item";
import HIDE_SITE from "../gql/hide-site.gql";
import { MutationFunction, useMutation } from "@apollo/client";
import { HideSite, HideSiteVariables } from "gql/types/operation-result-types";
import GET_SITES from "gql/queries/partners/sites.gql";
import { errorHandler } from "services/error-handler";
import { successHandler, SuccessTypes } from "services/success-handler";

interface IProps {
    siteId: number;
    hidden: boolean;
}

export const HideSiteComponent = ({ hidden, siteId }: IProps) => {
    const [mutation, { loading }] = useMutation<HideSite, HideSiteVariables>(
        HIDE_SITE,
        {
            refetchQueries: [
                {
                    query: GET_SITES,
                    variables: {
                        filter: { hidden: true },
                    },
                },
                {
                    query: GET_SITES,
                    variables: {
                        filter: { hidden: false },
                    },
                },
            ],
        },
    );
    return (
        <PartnerTableState
            toggleLink={() =>
                handleHideSite({
                    mutation,
                    siteId,
                    hidden,
                })
            }
            hidden={hidden}
            loading={loading}
        />
    );
};

interface IHideSite extends IProps {
    mutation: MutationFunction<HideSite, HideSiteVariables>;
}

const handleHideSite = async ({ mutation, hidden, siteId }: IHideSite) => {
    try {
        await mutation({
            variables: {
                id: siteId,
                hidden: !hidden,
            },
        });
        successHandler(
            hidden ? SuccessTypes.SHOW_SITE : SuccessTypes.HIDE_SITE,
            siteId,
        );
    } catch (ex: any) {
        void errorHandler(ex);
    }
};
