import { useMemo } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose, Dispatch } from "redux";
import { StatesActionCreators } from "services/redux/root/states/actions";

interface IProps extends RouteComponentProps {
    dispatch: Dispatch;
}

export const NotFoundHandler = compose(
    withRouter,
    connect(
        () => ({}),
        dispatch => ({ dispatch }),
    ),
)(
    React.memo((props: IProps) => {
        useMemo(() => {
            props.dispatch(
                StatesActionCreators.setLastNotFoundPathname(
                    props.location.pathname,
                ),
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.location.pathname]);
        return null;
    }),
);
