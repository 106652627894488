import React from "react";
import { useTranslation } from "react-i18next";
import {
    CampaignField,
    CampaignStatusField,
    CountryField,
    CurrencyField,
    LanguageField,
    MarketingToolsTypeField,
    MediaTypeField,
    MockPeriodField,
    Period as PeriodField,
    SiteCategoryField,
    WebsiteField,
    RegistrationSourcesField,
    CurrencyAdversitingPaymentsField,
} from "../fields";
import { Field } from "components/formik";
import { ComissionGroupField } from "../fields/commision-group";
import { PartnerGroupField } from "../fields/partner-group";
import { checkRequired } from "./check-required";
import { PartnerProgramRegistrationSourceField } from "../fields/partner-program-registration-source";
import {
    ILoadingContext,
    ObjectSchemaOfFields,
} from "../hooks/use-is-select-iptions-loading";

export interface IBaseFieldProps {
    name: string;
    fakeSwapLabel: JSX.Element;
    validationSchema: ObjectSchemaOfFields;
    loadingContext: ILoadingContext;
}

const Currency = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return <CurrencyField {...selectFieldsProps(props)} label={__("Валюта")} />;
};

const CurrencyAdversitingPayments = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <CurrencyAdversitingPaymentsField
            {...selectFieldsProps(props)}
            label={__("Валюта")}
        />
    );
};

const Website = (props: IBaseFieldProps) => {
    return <WebsiteField {...selectFieldsProps(props)} />;
};

const MarketingToolId = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("ID маркетингового инструмента")}
            style={{ minWidth: "250px" }}
        />
    );
};

const SubId = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("Sub ID")}
            style={{ minWidth: "150px" }}
        />
    );
};

const Period = (props: IBaseFieldProps) => {
    return <PeriodField {...selectFieldsProps(props)} />;
};

const PromoCode = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("Промокод")}
            style={{ maxWidth: "150px" }}
        />
    );
};

const Campaign = (props: IBaseFieldProps) => {
    return <CampaignField {...selectFieldsProps(props)} minWidth={550} />;
};

const CampaignId = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("ID кампании")}
            style={{ maxWidth: "150px" }}
        />
    );
};

const CampaignName = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("Имя кампании")}
            style={{ maxWidth: "250px" }}
        />
    );
};

const CampaignStatus = (props: IBaseFieldProps) => (
    <CampaignStatusField {...selectFieldsProps(props)} />
);

const SiteCategory = (props: IBaseFieldProps) => {
    return <SiteCategoryField {...selectFieldsProps(props)} />;
};

const Language = (props: IBaseFieldProps) => {
    return <LanguageField {...selectFieldsProps(props)} />;
};

const WebsiteName = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            placeholder={__("Введите свой сайт. Например: mysite.com")}
            label={__("Веб-сайт")}
            style={{ minWidth: "350px" }}
        />
    );
};

const MediaType = (props: IBaseFieldProps) => {
    return <MediaTypeField {...selectFieldsProps(props)} />;
};

const MarketingToolsType = (props: IBaseFieldProps) => {
    return <MarketingToolsTypeField {...selectFieldsProps(props)} />;
};

const RegistrationSources = (props: IBaseFieldProps) => {
    return <RegistrationSourcesField {...selectFieldsProps(props)} />;
};

const PartnerProgramRegistrationSource = (props: IBaseFieldProps) => {
    return (
        <PartnerProgramRegistrationSourceField {...selectFieldsProps(props)} />
    );
};

const Country = (props: IBaseFieldProps) => {
    return <CountryField {...selectFieldsProps(props)} />;
};

const Landing = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("Целевая страница")}
            placeholder="/live"
            style={{ minWidth: "200px" }}
        />
    );
};

const MockPeriod = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <MockPeriodField {...selectFieldsProps(props)} label={__("Период")} />
    );
};

const MediaName = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("Имя медиа")}
            style={{ minWidth: "200px" }}
        />
    );
};

const Width = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            placeholder={"100"}
            label={__("Ширина")}
            style={{ minWidth: "150px" }}
        />
    );
};

const Height = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            placeholder={"100"}
            label={__("Высота")}
            style={{ minWidth: "150px" }}
        />
    );
};

const PlayerId = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return <Field {...defaultInputFieldProps(props)} label={__("ID игрока")} />;
};

const WebsiteId = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("ID сайта")}
            style={{ maxWidth: "150px" }}
        />
    );
};

const OnlyNewPlayers = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("Только новые игроки")}
            type="checkbox"
        />
    );
};

const WithoutDepositOnly = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("Только игроки без депозитов")}
            type="checkbox"
        />
    );
};

const CommissionGroup = (props: IBaseFieldProps) => {
    return <ComissionGroupField {...selectFieldsProps(props)} />;
};

const PartnerGroup = (props: IBaseFieldProps) => {
    return <PartnerGroupField {...selectFieldsProps(props)} />;
};

const PartnerId = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field {...defaultInputFieldProps(props)} label={__("ID партнера")} />
    );
};

const WhoCreated = (props: IBaseFieldProps) => {
    const [__] = useTranslation();
    return (
        <Field
            {...defaultInputFieldProps(props)}
            label={__("Добавлено")}
            style={{ minWidth: "200px" }}
        />
    );
};

// TODO: Рассмотреть возможность использования единого компонента для всех id.
export const fieldsList = {
    currency: Currency,
    website: Website,
    websiteId: WebsiteId,
    marketingToolId: MarketingToolId,
    subId: SubId,
    period: Period,
    promoCode: PromoCode,
    campaign: Campaign,
    campaignId: CampaignId,
    campaignName: CampaignName,
    campaignStatus: CampaignStatus,
    siteCategory: SiteCategory,
    language: Language,
    websiteName: WebsiteName,
    mediaType: MediaType,
    marketingToolsType: MarketingToolsType,
    registrationSource: RegistrationSources,
    partnerProgramRegistrationSource: PartnerProgramRegistrationSource,
    country: Country,
    landing: Landing,
    mockPeriod: MockPeriod,
    mediaName: MediaName,
    width: Width,
    height: Height,
    playerId: PlayerId,
    onlyNewPlayers: OnlyNewPlayers,
    withoutDepositsOnly: WithoutDepositOnly,
    commisionGroup: CommissionGroup,
    partnersGroup: PartnerGroup,
    partnerId: PartnerId,
    whoCreated: WhoCreated,
    currencyAdversitingPayments: CurrencyAdversitingPayments,
};

export const defaultInputFieldProps = ({ name, ...rest }: IBaseFieldProps) =>
    ({
        autoComplete: "on",
        autoCapitalize: "off",
        autoCorrect: "off",
        type: "text",
        name,
        key: name,
        ...rest,
    } /* т.к. type тип "text" перобразуется в "string" */ as {
        autoComplete: "on" | "off";
        autoCapitalize: "on" | "off";
        autoCorrect: "on" | "off";
        type: "text";
        name: string;
        key: string;
    });

export const selectFieldsProps = ({
    name,
    validationSchema,
    ...rest
}: IBaseFieldProps) => ({
    name,
    key: name,
    selectFirst: checkRequired(validationSchema, name),
    isClearable: !checkRequired(validationSchema, name),
    ...rest,
});
