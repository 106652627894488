import { SyncAlt } from "@styled-icons/fa-solid/SyncAlt/SyncAlt";
import { PageRoute } from "components/check-roles/guard";
import { AuxiliaryBlockButton } from "components/header/styled";
import { ResetPartnerStatistics } from "gql/types/operation-result-types";
import { useMutation, gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ButtonColored } from "styled/button";
import { useSelector } from "react-redux";
import { statesSelectors } from "services/redux/root/states/selectors";
import GET_PARTNER_PAYMENT from "views/partner/main/home/gql/get-partner-payment.gql";
import GET_PARTNER_GRAPH from "views/partner/main/home/gql/get-partner-graph.gql";
import GET_PARTNER_QUICK_REPORT from "views/partner/main/home/gql/partner-quick-statistic.gql";

interface IInjectedProps extends RouteComponentProps {}

interface IProps extends IInjectedProps {
    className?: string;
}

const RESET_PARTNER_STATISTICS = gql`
    mutation ResetPartnerStatistics {
        authorized {
            partner {
                resetStatistics
            }
        }
    }
`;

export const UpdateStatistic = withRouter(
    React.memo((props: IProps) => {
        const [__] = useTranslation();
        const [resetStatistics] = useMutation<ResetPartnerStatistics>(
            RESET_PARTNER_STATISTICS,
        );
        const statisticLastParams = useSelector(
            statesSelectors.getStatisticLastParams,
        );

        const handleResetStatistic = async () => {
            await resetStatistics({
                refetchQueries: [
                    {
                        query: GET_PARTNER_PAYMENT,
                        variables: {
                            currencyId: Number(
                                statisticLastParams?.mainInfoAndGraph?.currency
                                    .id,
                            ),
                        },
                    },
                    {
                        query: GET_PARTNER_GRAPH,
                        variables: {
                            currencyId: Number(
                                statisticLastParams?.mainInfoAndGraph?.currency
                                    .id,
                            ),
                            period: Number(
                                statisticLastParams?.mainInfoAndGraph
                                    ?.mockPeriod,
                            ),
                        },
                    },
                    {
                        query: GET_PARTNER_QUICK_REPORT,
                        variables: {
                            period: statisticLastParams?.quick?.period,
                        },
                    },
                ],
            });
        };

        return (
            <PageRoute
                path={props.match.url}
                component={() => {
                    return (
                        <AuxiliaryBlockButton className={props.className}>
                            <ButtonColored
                                onClick={async () =>
                                    await handleResetStatistic()
                                }
                            >
                                <SyncAlt size={14} />
                                {__("Обновить статистику")}
                            </ButtonColored>
                        </AuxiliaryBlockButton>
                    );
                }}
            />
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
