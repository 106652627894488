import { ExportButton as BaseExportButton } from "components/react-table/table-select-variables/export/components/export-button";
import { ReportFormat } from "gql/types/enumReportFormat";

interface IProps {
    format: string;
    caption: string;
    loading: boolean;
    onClick: (reportFormat: ReportFormat) => void;
}

export const ExportButton = ({ format, caption, loading, onClick }: IProps) => (
    <BaseExportButton
        exportData={() =>
            onClick(
                ReportFormat[format.toUpperCase() as keyof typeof ReportFormat],
            )
        }
        saving={loading}
        caption={caption}
    />
);
