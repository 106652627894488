import {
    GetPlan_authorized_manager_advertising_finantialPlans_plan_logs,
    GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans,
    GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans_logs,
} from "gql/types/operation-result-types";
import { AdminWrapper } from "styled/layouts";
import { ReactTable } from "components/react-table";
import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";

export const LogFinplanTable = ({
    logs,
    paymentPlans,
}: {
    logs:
        | GetPlan_authorized_manager_advertising_finantialPlans_plan_logs[]
        | null;
    paymentPlans:
        | GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans[]
        | null;
}) => {
    const [__] = useTranslation();

    const paymentPlanLogs = (paymentPlans || []).reduce((acc, curr) => {
        if (curr.logs && curr.logs.length > 0) {
            acc.push(...curr.logs);
        }
        return acc;
    }, [] as GetPlan_authorized_manager_advertising_finantialPlans_plan_paymentPlans_logs[]);

    const paymentPlansLogs = (paymentPlanLogs || []).map(log => {
        return {
            comment: log.reason,
            dateOfChange: log.dateOfChange,
            statusName: "",
            updatedBy: log.updatedBy,
            orderPaymentPlan: log.order,
        };
    });

    const finplanLogs = (logs || []).map(log => {
        return {
            dateOfChange: log.dateOfChange,
            statusName: log.statusName,
            updatedBy: log.updatedBy,
            orderPaymentPlan: 0,
        };
    });

    const allLogs = finplanLogs.concat(paymentPlansLogs);

    const columns: IReactTableColumn[] = [
        {
            Header: __("Статус"),
            accessor: "statusName",
            minWidth: 80,
        },
        {
            Header: __("Номер платежа"),
            accessor: "orderPaymentPlan",
            minWidth: 80,
            Cell: ({ original }) =>
                original.orderPaymentPlan ? original.orderPaymentPlan : "",
        },
        {
            Header: __("Обновление"), // Имя пользователя кто обновил
            accessor: "updatedBy",
            minWidth: 80,
        },
        {
            Header: __("Комментарий/Причина"),
            accessor: "comment",
            minWidth: 80,
        },
        {
            Header: __("Дата"),
            accessor: "dateOfChange",
            minWidth: 80,
            dataType: "date",
        },
    ];

    return (
        <>
            <AdminWrapper>
                <ReactTable
                    defaultSorted={[
                        {
                            id: "dateOfChange",
                            desc: false,
                        },
                    ]}
                    columns={columns}
                    data={allLogs}
                />
            </AdminWrapper>
        </>
    );
};
