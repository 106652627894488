import {
    GetPartnerPaymentReport,
    GetPartnerPaymentReportVariables,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
} from "gql/types/operation-result-types";
import { useQuery } from "@apollo/client";
import GET_PARTNER_PAYMENT from "../gql/get-partner-payment.gql";

export const usePartnersPayment = (
    currency:
        | GetCurrencies_authorized_partnerAndManager_data_currencies
        | undefined,
) => {
    const id = g(currency, "id");
    const queryResult = useQuery<
        GetPartnerPaymentReport,
        GetPartnerPaymentReportVariables
    >(GET_PARTNER_PAYMENT, {
        variables: {
            currencyId: Number(id),
        },
        skip: !id,
        ssr: true,
        fetchPolicy: "network-only",
    });

    const data = g(
        queryResult.data,
        "authorized",
        "partner",
        "mainStatistic",
        "getPartnerPayment",
    );

    return { data, loading: queryResult.loading, queryResult };
};
