import axios from "axios";
import { saveAs } from "file-saver";
import { useState } from "react";
import { parse } from "content-disposition";

import { TFilters } from "../filter/create-link";
import { successHandler, SuccessTypes } from "services/success-handler";
import { useMutation } from "@apollo/client";
import {
    PrepareForDownloadApk,
    PrepareForDownloadApkStatus,
    PrepareForDownloadApkVariables,
} from "gql/types/operation-result-types";
import PREPARE_APK from "../gql/prepare-apk.gql";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { RequestError } from "server/errors/request-error";
import { useTranslation } from "react-i18next";

export const useDownloadApk = () => {
    const [loading, setLoading] = useState(false);
    const [__] = useTranslation();

    const [prepareApk] = useMutation<
        PrepareForDownloadApk,
        PrepareForDownloadApkVariables
    >(PREPARE_APK);

    const downloadApk = async (filter: TFilters) => {
        try {
            setLoading(true);
            const preparingResponse = await prepareApk({
                variables: {
                    filter: {
                        siteId: filter.website.id,
                        currencyId: Number(filter.currency.id),
                        campaignId: Number(filter.campaign.id),
                    },
                },
            });

            const statusPreparing =
                preparingResponse.data?.authorized.partner
                    ?.partnerLinksMutations.prepareForDownloadApk.status;

            if (statusPreparing === PrepareForDownloadApkStatus.ERROR) {
                throw new RequestError(ErrorType.DOWNLOAD_APK_ERROR);
            }

            if (statusPreparing === PrepareForDownloadApkStatus.TIME_LIMIT) {
                throw new RequestError(ErrorType.DOWNLOAD_APK_TIME_LIMIT);
            }

            const response = await axios.get("/lapi/get-partner-campaign-apk");
            const disposition = parse(response.headers["content-disposition"]);

            const blob = new Blob([response.data], {
                type: response.data.type,
            });

            saveAs(blob, disposition.parameters.filename);
            successHandler(
                SuccessTypes.SUCCESS_DOWNLOAD_CAMPAIGN_APK,
                filter.campaign.name,
            );
        } catch (ex: any) {
            void errorHandler(ex, error => {
                switch (error.data.code) {
                    case ErrorType.DOWNLOAD_APK_TIME_LIMIT:
                        return __("Превышен лимит времени при скачивании APK");
                    default:
                        return __("Ошибка при скачивании APK");
                }
            });
        } finally {
            setLoading(false);
        }
    };

    return { downloadApk, loading };
};
