import { useTranslation } from "react-i18next";
import { isBoolean, capitalize } from "lodash";
import { ReferralDomains_authorized_manager_main_referralDomains_data } from "gql/types/operation-result-types";
import { IReactTableColumn } from "components/react-table/column";
import { CellWithButtons } from "components/table-item";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";

interface IUseColumnsParams {
    disabled: boolean;
    onEdit: (
        data: ReferralDomains_authorized_manager_main_referralDomains_data,
    ) => void;
    onDelete: (currencyName: string) => void;
}

export const useColumns = ({
    disabled,
    onEdit,
    onDelete,
}: IUseColumnsParams): IReactTableColumn[] => {
    const [__] = useTranslation();

    return [
        {
            Header: __("ID"),
            accessor: "id",
            disabled: true,
            minWidth: 120,
            Cell: ({
                original,
            }: {
                original: ReferralDomains_authorized_manager_main_referralDomains_data;
            }) => {
                const button = (
                    <>
                        <ButtonColoredWithIcon
                            btnColor={BtnColor.Faded}
                            title={__("Редактировать")}
                            type="button"
                            disabled={disabled}
                            onClick={() => {
                                onEdit(original);
                            }}
                        >
                            <EditOutline size={15} />
                        </ButtonColoredWithIcon>
                        <ButtonColoredWithIcon
                            btnColor={BtnColor.Faded}
                            title={__("Удалить")}
                            type="button"
                            disabled={disabled}
                            onClick={() => onDelete(original.currency || "")}
                        >
                            <Delete size={15} />
                        </ButtonColoredWithIcon>
                    </>
                );
                return (
                    <CellWithButtons
                        button={button}
                        text={String(original.id)}
                    />
                );
            },
        },
        {
            Header: __("Валюта"),
            accessor: "currency",
            minWidth: 80,
        },
        {
            Header: __("Ресурс"),
            accessor: "resource",
            minWidth: 160,
        },
        {
            Header: "Host name",
            accessor: "hostName",
            minWidth: 160,
            Cell: ({ value }) => <CellWithButtons text={value} />,
        },
        {
            Header: __("Автоматическое обновление"),
            accessor: "autoUpdate",
            minWidth: 160,
            Cell: ({ value }) => isBoolean(value) && capitalize(String(value)),
        },
        {
            Header: __("Автоматическая рассылка"),
            accessor: "autoEmailSending",
            minWidth: 160,
            Cell: ({ value }) => isBoolean(value) && capitalize(String(value)),
        },
    ];
};
