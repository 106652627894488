export const exportButtonsDesc = [
    {
        format: "xlsx",
        caption: "Excel",
    },
    {
        format: "csv",
        caption: "CSV",
    },
];
