import ReactTooltip from "react-tooltip";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    backgroundColor?: string;
    textColor?: string;
}

// чтобы показывался datatip нужно дополнительно
// прописать data-tip и data-for="passwordTooltip" на соотвествующем input элементе
export const PasswordReactTooltip = ({
    backgroundColor,
    textColor,
}: IProps) => {
    // патч библиотеки, чтобы тултип не рендерился при SSR
    // (и зачем ему там рендериться)
    // иначе мелькает черный бокс
    // (основанно на том что useEffect работает только в клиенте)
    const [SSR, setSSR] = useState(true);
    useEffect(() => {
        setSSR(false);
    }, []);
    const [__] = useTranslation();

    return (
        <>
            {!SSR && (
                // mousedown и delayShow т.к. в данном случае,
                // при клике поле сдвигается вниз сообщением об ошибке прошлого поля
                // что приводит к тому что вторая часть клика уже происходит за пределами поля (и это уже не клик)
                // и неправильному вычислению позиции тултипа
                <ReactTooltip
                    id="passwordTooltip"
                    place="top"
                    effect="solid"
                    event="mousedown focus"
                    globalEventOff="blur"
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    wrapper="span"
                    delayShow={200}
                    // с isCapture=true события focus, mousedown  будет прихоидть сначала ReactTooltip и потом дальше
                    // без этого не работает, т.к. видимо кто-то отменяет событие и оно не доходит в ReactTooltip
                    isCapture={true}
                >
                    {__(
                        "Длина 8 - 100. Должен содержать A-Z, a-z, 0-9, и допустимо _ , #, /, &",
                    )}
                </ReactTooltip>
            )}
        </>
    );
};
