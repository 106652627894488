import { useMutation } from "@apollo/client";
import {
    EditPartnerSite,
    EditPartnerSiteVariables,
    GetPartnerSitesParams,
} from "gql/types/operation-result-types";
import EDIT_PARTNER_SITE from "../../../../gql/edit-partner-site.gql";
import GET_SITES from "../../../../gql/get-partner-sites.gql";

export const useEditPartnerSite = (
    getSitesVariables: GetPartnerSitesParams,
) => {
    const [editPartnerSite, { loading }] = useMutation<
        EditPartnerSite,
        EditPartnerSiteVariables
    >(EDIT_PARTNER_SITE, {
        refetchQueries: [
            {
                query: GET_SITES,
                variables: { query: getSitesVariables },
            },
        ],
    });

    return {
        editPartnerSite,
        loading,
    };
};
