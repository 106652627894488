import { GetCategories } from "gql/types/operation-result-types";
import { useQuery, gql } from "@apollo/client";

const GET_CATEGORIES = gql`
    query GetCategories {
        siteCategories {
            id
            name
        }
    }
`;

export const useSiteCategory = () => {
    const siteCategory = useQuery<GetCategories>(GET_CATEGORIES, {
        ssr: true,
    });

    const data = g(siteCategory, "data", "siteCategories");

    return { data, loading: siteCategory.loading, error: siteCategory.error };
};
