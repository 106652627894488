import { SubmitButton } from "components/formik/submit-button/submit-button";
import {
    Field,
    FieldArray,
    Form,
    FormikHelpers as FormikActions,
} from "components/formik";
import { GetSubscriptions_authorized_partnerAccount_getMailSubscriptions } from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { successHandler, SuccessTypes } from "services/success-handler";
import { FormGroup, RowTwoColumnsParagraph } from "styled/layouts";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    subscriptionsList: GetSubscriptions_authorized_partnerAccount_getMailSubscriptions[];
    onApply: (
        newSubscriptions: GetSubscriptions_authorized_partnerAccount_getMailSubscriptions[],
    ) => Promise<void>;
}

interface IValues {
    subscriptions: GetSubscriptions_authorized_partnerAccount_getMailSubscriptions[];
}

export const SubscribtionsForm = React.memo(
    ({ subscriptionsList, onApply }: IProps) => {
        const [__] = useTranslation();
        const [DT] = useTranslation();

        return (
            <FormikConsoleErrors
                initialValues={{ subscriptions: subscriptionsList }}
                onSubmit={(values: IValues, formikActions) =>
                    onSubmit(values, formikActions, onApply, __)
                }
            >
                {({ values, setFieldValue }) => {
                    const subscribedToSome = values.subscriptions.some(
                        v => v.isEnabled,
                    );
                    return (
                        <Form>
                            <FieldArray
                                name="subscriptions"
                                render={() =>
                                    values.subscriptions.map(
                                        (subscribtion, index) => (
                                            <Field
                                                key={subscribtion.id}
                                                name={subscribtion.id.toString()}
                                                type="checkbox"
                                                label={DT(subscribtion.title)}
                                                checked={subscribtion.isEnabled}
                                                onChange={(e: any) =>
                                                    setFieldValue(
                                                        `subscriptions[${index}].isEnabled`,
                                                        e.target.checked,
                                                    )
                                                }
                                            />
                                        ),
                                    )
                                }
                            />

                            <RowTwoColumnsParagraph>
                                <FormGroup>
                                    <SubmitButton>
                                        {__("Подтвердить")}
                                    </SubmitButton>
                                </FormGroup>
                                <FormGroup>
                                    {" "}
                                    <SubmitButton
                                        onClick={() =>
                                            manageAllSubscriptions(
                                                !subscribedToSome,
                                                setFieldValue,
                                                values,
                                            )
                                        }
                                    >
                                        {subscribedToSome
                                            ? __("Отписаться от всего")
                                            : __("Подписаться на всё")}
                                    </SubmitButton>
                                </FormGroup>
                            </RowTwoColumnsParagraph>
                        </Form>
                    );
                }}
            </FormikConsoleErrors>
        );
    },
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

function manageAllSubscriptions(
    subscribe: boolean,
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean,
    ) => void,
    values: IValues,
) {
    values.subscriptions.forEach((_, index) =>
        setFieldValue(`subscriptions[${index}].isEnabled`, subscribe),
    );
}

async function onSubmit(
    values: IValues,
    formikActions: FormikActions<Record<string, unknown>>,
    onApply: (
        newSubscriptions: GetSubscriptions_authorized_partnerAccount_getMailSubscriptions[],
    ) => Promise<void>,
    __: TFunction,
) {
    try {
        await onApply(values.subscriptions);

        successHandler(SuccessTypes.ACCOUNT_SUBSCRIBE);
    } catch (e: any) {
        formikActions.resetForm();
        void errorHandler(e, () => {
            return __("Ошибка при изменении подписки");
        });
    }
}
