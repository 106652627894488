import { CalendarCheck } from "@styled-icons/fa-regular/CalendarCheck/CalendarCheck";
import { MoneyBillAlt } from "@styled-icons/fa-regular/MoneyBillAlt/MoneyBillAlt";
import { Bitcoin } from "@styled-icons/boxicons-regular/Bitcoin/Bitcoin";
import { Bolt } from "@styled-icons/fa-solid/Bolt/Bolt";
import { ChartLine } from "@styled-icons/fa-solid/ChartLine/ChartLine";
import { Check } from "@styled-icons/fa-solid/Check/Check";
import { DollarSign } from "@styled-icons/fa-solid/DollarSign/DollarSign";
import { RubleSign } from "@styled-icons/fa-solid/RubleSign/RubleSign";
import { InfoCircle } from "@styled-icons/fa-solid/InfoCircle";
import { Spiner } from "components/loaders/spiner";
import {
    MainInfoItemDesc,
    MainInfoItemSC,
    MainInfoItemTitle,
    MainInfoItemTypeType,
    MilliBTCInsertion,
} from "./styled";
import { Tooltip } from "../tooltip";
import { useTranslation } from "react-i18next";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    itemType: MainInfoItemTypeType;
    amount?: number;
    period: string;
    currency?: string;
    loading: boolean;
    hold?: number;
}

const getCurrency = (currencyName?: string, size = "24") => {
    switch (currencyName) {
        case "RUB":
            return <RubleSign size={size} />;
        case "USD":
            return <DollarSign size={size} />;
        case "mBTC":
            return (
                <span>
                    <MilliBTCInsertion size={size}>m</MilliBTCInsertion>
                    <Bitcoin size={size} />
                </span>
            );
        default:
            return "";
    }
};

// TODO: эти 2 switch внутри непонятно зачем. Можно передать просто через параметры эти иконки и
// и вообще избавиться от MainInfoItemTypeType.
export const MainInfoItem = React.memo(
    ({ currency, itemType, amount, period, loading, hold }: IProps) => {
        const [__] = useTranslation();

        return (
            <MainInfoItemSC mainInfoItemType={itemType}>
                {loading ? (
                    <Spiner />
                ) : (
                    <>
                        {(() => {
                            switch (itemType) {
                                case MainInfoItemTypeType.IsAvailable:
                                    return <MoneyBillAlt size="161" />;
                                case MainInfoItemTypeType.Yesterday:
                                    return <Bolt size="161" />;
                                case MainInfoItemTypeType.CurrentMonth:
                                    return <ChartLine size="161" />;
                                case MainInfoItemTypeType.ThirtyDays:
                                    return <CalendarCheck size="161" />;
                                case MainInfoItemTypeType.Total:
                                    return <Check size="161" />;
                            }
                        })()}
                        <MainInfoItemTitle>
                            {getCurrency(currency)}
                            <span>{amount !== undefined ? amount : "--"}</span>
                        </MainInfoItemTitle>
                        <MainInfoItemDesc>
                            {(() => {
                                switch (itemType) {
                                    case MainInfoItemTypeType.IsAvailable:
                                        return <MoneyBillAlt size="21" />;
                                    case MainInfoItemTypeType.Yesterday:
                                        return <Bolt size="21" />;
                                    case MainInfoItemTypeType.CurrentMonth:
                                        return <ChartLine size="21" />;
                                    case MainInfoItemTypeType.ThirtyDays:
                                        return <CalendarCheck size="21" />;
                                    case MainInfoItemTypeType.Total:
                                        return <Check size="21" />;
                                }
                            })()}{" "}
                            {period}
                            {hold !== undefined ? (
                                <>
                                    {" "}
                                    {/* TODO: заиспользовать react-tooltip (см. PasswordReactTooltip)
                                    вместо этого Tooltip */}
                                    <Tooltip
                                        arrowContent={
                                            <>
                                                {__(
                                                    "Сумма от комиссии на холде",
                                                )}{" "}
                                                {hold}{" "}
                                                {getCurrency(currency, "12")}
                                            </>
                                        }
                                    >
                                        <InfoCircle size="15" />
                                    </Tooltip>
                                </>
                            ) : null}
                        </MainInfoItemDesc>
                    </>
                )}
            </MainInfoItemSC>
        );
    },
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
