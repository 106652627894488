import { media } from "services/styled-components/variables";
import styled from "styled-components";

import { LocalizedNavLinkWithOptionalBackground } from "components/routing/localized-navlink-with-optional-background";

// Тут описанны требования к лого, на которые сейчас рассчитанна партнерка (наш контракт по лого).
// Некоторые, лого предоставляеются без учета этих требований, из-за чего они выгдядят не очень.
// Если стейкохлдерам таких партнерок вдруг станет не все равно как выглядит их лого,
// прежде всего надо будет запросить новое лого в соотв. с этими требованиями.

// Высоты >= 35px; 150x35 - идеальные пропорции; допускается отклонение пропорций до 220x35 в сторону ширины;
// отклонение пропорций в сторону высоты не важно;
// Отступы сверху, снизу, слева и справа не допускаются;
export const LogoImg = styled.img`
    display: none;
    max-width: 150px;
    max-height: 35px;
`;

// Высоты >= 60px; 100x50 - идеальные пропорции; допускается отклонение пропорций от 45x50  до 150x50;
// Отступы сверху, снизу, слева и справа не допускаются;
// Внутри svg должны быть проставленны размеры - ширина и высота,
// и все лого должно пропорционально масштабироваться при изменении этих размеров.
export const LogoImgSmall = styled.img`
    display: block;
    max-width: 60px;
    max-height: 50px;
`;

export const LogoLink = styled(LocalizedNavLinkWithOptionalBackground)`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    ${media.lt`
        ${LogoImg} {
            display: block;
        }
        ${LogoImgSmall} {
            display: none;
        }
    `}
`;
