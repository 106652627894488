import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { useTranslation } from "react-i18next";
import { Title } from "services/styled-components/typogoraphy";
import { SuccessTypes, successHandler } from "services/success-handler";
import { errorHandler } from "services/error-handler";
import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { useValidationSchema } from "./hooks/use-validation-schema";
import { useInitialValues } from "./hooks/use-initial-values";
import { PaymentInfoForm } from "./payment-info-form";
import { useMutation, useQuery } from "@apollo/client";
import GET_PARTNER from "../../../gql/get-partner.gql";
import UPDATE_PAYMENT_METHOD from "../gql/update-payment-method.gql";
import {
    PartnerVariables,
    Partner,
    UpdatePaymentMethod,
    UpdatePaymentMethodVariables,
    Partner_authorized_manager_main_partner_payment,
    Partner_authorized_manager_main_partner_payment_fields,
} from "gql/types/operation-result-types";
import { useRouteMatch } from "react-router-dom";

interface IPaymentSystemField
    extends Partner_authorized_manager_main_partner_payment_fields {
    required?: boolean;
    validationTemplate?: string;
}
interface IPaymentSystem
    extends Partner_authorized_manager_main_partner_payment {
    isDiaplay?: boolean;
    code?: string;
    fields: IPaymentSystemField[] | null;
}

export const PartnerPaymentInfo = () => {
    const [__] = useTranslation();
    const { params } = useRouteMatch<{
        id: string;
    }>();

    const partnerId = Number(params.id);

    const [updatePaymentMethod] = useMutation<
        UpdatePaymentMethod,
        UpdatePaymentMethodVariables
    >(UPDATE_PAYMENT_METHOD);

    const { data, loading, refetch } = useQuery<Partner, PartnerVariables>(
        GET_PARTNER,
        {
            variables: {
                id: partnerId,
            },
            ssr: true,
        },
    );
    const partnerData = data?.authorized.manager.main.partner;

    const initialValues = useInitialValues(Number(params.id), partnerData);
    const validationSchema = useValidationSchema();

    return (
        <>
            <Title>{__("Общие данные")}</Title>
            {loading ? (
                __("Загрузка данных...")
            ) : (
                <FormikConsoleErrors
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={onSubmitWrapper(async values => {
                        const paymentSystem =
                            values.paymentSystem as IPaymentSystem;

                        try {
                            await updatePaymentMethod({
                                variables: {
                                    partnerId,
                                    data: {
                                        advertisingCompanyId: values.company,
                                        payment: {
                                            id: paymentSystem.id,
                                            name: paymentSystem.name,
                                            fields: paymentSystem.fields?.map(
                                                field => {
                                                    const [, fieldId] =
                                                        field.id.split("_");

                                                    return {
                                                        id: parseInt(fieldId),
                                                        name: field.name,
                                                        value: field.value,
                                                        required:
                                                            field.required,
                                                        validationTemplate:
                                                            field.validationTemplate,
                                                    };
                                                },
                                            ),
                                            isDisplay: paymentSystem.isDiaplay,
                                            code: paymentSystem.code,
                                        },
                                    },
                                },
                            });

                            await refetch();

                            return successHandler(
                                SuccessTypes.EDIT_PARTNER_ACCOUNT,
                            );
                        } catch (error: any) {
                            void errorHandler(error);
                            throw error;
                        }
                    }, validationSchema)}
                >
                    <PaymentInfoForm />
                </FormikConsoleErrors>
            )}
        </>
    );
};
