import * as yup from "yup";
import { useTranslationList } from "hooks/use-translations-list";
import {
    AdvertisementStatus,
    GetCountries_countries,
    GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments,
    PlacementType,
} from "gql/types/operation-result-types";
import { useObjectValidations } from "validation/validaion";

export function useValidationSchema() {
    const { enumRuleRequired, pointNumber } = useObjectValidations();
    const {
        FIELD_SHOULD_BE_FILLED,
        UP_TO_MAX_CHARACTERS,
        INVALID_FORMAT,
        FIELD_SHOULD_BE_ΕΜPΤΥ,
    } = useTranslationList();

    function name(maxCharacters: number) {
        return yup
            .string()
            .max(maxCharacters, UP_TO_MAX_CHARACTERS)
            .matches(/^[a-zA-Zа-яА-Я\s_.,]+$/, INVALID_FORMAT);
    }

    return yup.object({
        partnerId: yup.number().required(FIELD_SHOULD_BE_FILLED),
        siteId: yup.number().notRequired().nullable(),
        statusAdvertisingPayments: enumRuleRequired<AdvertisementStatus>(),
        currencyAdversitingPayments: yup
            // eslint-disable-next-line max-len
            .object<GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments>()
            .nullable()
            .required(FIELD_SHOULD_BE_FILLED),
        amountRequest: yup.number().nullable().required(FIELD_SHOULD_BE_FILLED),
        contractPlacements: yup.array().of(
            yup
                .object({
                    type: enumRuleRequired<PlacementType>(),
                    typeName: yup.string().when("type", {
                        is: (type: PlacementType) =>
                            type === PlacementType.OTHER,
                        then: name(50).required(FIELD_SHOULD_BE_FILLED),
                        // otherwise field should be empty
                        otherwise: yup.string().test({
                            test: value => !value,
                            message: FIELD_SHOULD_BE_ΕΜPΤΥ,
                        }),
                    }),
                    sum: pointNumber(),
                    percentSum: yup.boolean().required(),
                })
                .required(),
        ),
        comments: yup.string().nullable(),
        paymentSystem: yup
            .object({
                id: yup.number().nullable().required(FIELD_SHOULD_BE_FILLED),
                fields: yup.array().of(
                    yup
                        .object({
                            id: yup.string().required(FIELD_SHOULD_BE_FILLED),
                            value: yup.string(),
                        })
                        .required(),
                ),
            })
            .required(),
        company: yup.number().nullable(),
        country: yup.object<GetCountries_countries>().nullable(),
    });
}
