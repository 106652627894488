import styled from "styled-components";
import { media } from "services/styled-components/variables";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 424px;
    margin: -5px;
    padding: 50px;
    border-radius: 10px;
    background-color: #1b1b1dcc;
    line-height: 1;
    text-align: center;
    color: #ffffff;
`;

export const Icon = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    margin-bottom: 25px;
    border: 1px solid #ffffff;
    border-radius: 8px;
    color: #ffffff !important;

    &:hover {
        background-color: #08a973;
        box-shadow: 0px 0px 25px 0px #08a973;
        border-color: #08a973;
    }
`;

export const Title = styled.p`
    margin-bottom: 18px;
    font-weight: 800;
    font-size: 22px;
    text-transform: uppercase;

    ${media.sm`
        font-size: 24px;
    `}
`;

export const Subtitle = styled.div`
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 400;
`;

export const Link = styled.a`
    margin-bottom: 25px;
    text-transform: uppercase;
    text-decoration: none !important;
    font-size: 18px;
    color: #08a973 !important;
    font-weight: 500;
`;

export const ConnectButton = styled.a`
    width: 100%;
    padding: 15px;
    margin-bottom: 25px;
    text-transform: uppercase;
    text-decoration: none !important;
    color: #f0f0f0 !important;
    background-color: #08a973;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 800;

    &:hover {
        background-color: #cc0000;
        box-shadow: 0px 0px 25px 0px #cc0000;
    }
`;

export const BottomText = styled.p`
    margin-bottom: 0;
    color: #72777c;
    font-size: 14px;
    line-height: 1.12;
`;
