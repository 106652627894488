import { useMessengers } from "hooks/query/use-messengers";
import { useCountries } from "hooks/query/use-countries";
import { useGetLanguages } from "hooks/query/use-language";
import { Partner_authorized_manager_main_partner } from "gql/types/operation-result-types";
import { transFormDate } from "views/partner/main/comission-structure/services/comission-description";
import { IFormValues } from "../models";

export const useInitialFormValues = (
    partner?: Partner_authorized_manager_main_partner,
): { initialValues: IFormValues; loading: boolean } => {
    const { messengers, loading: messengersLoading } = useMessengers();
    const { countries, loading: countriesLoading } = useCountries();
    const { languages, loading: languagesLoading } = useGetLanguages();

    return {
        initialValues: {
            url: partner?.site,
            phoneNumber: partner?.phone,
            email: partner?.email,
            messenger: messengers?.find(
                messanger => messanger.id === partner?.messenger?.id,
            ),
            messengerCredentials: partner?.messenger?.credentials,
            registrationDate: transFormDate(partner?.registrationDate || ""),
            registrationSource: partner?.registrationSource,
            players: partner?.players,
            partnerSiteId: partner?.partnerSiteId,
            status: partner?.status,
            country: countries?.find(
                country => country.id === partner?.countryId,
            ),
            language: languages?.find(
                language => language.id === partner?.languageId,
            ),
            currencies:
                partner?.currencies?.map(currencyId => String(currencyId)) ||
                [],
            partnerId: partner?.partnerId,
            note: partner?.note,
            setCodeUsageLimit: partner?.promoCodsLimit,
        },
        loading: messengersLoading || countriesLoading || languagesLoading,
    };
};
