import { Modal } from "components/components-common/modal";
import {
    StyledClose,
    StyledInner,
} from "components/components-common/modal/styled";
import { Paragraph, Title } from "services/styled-components/typogoraphy";
import { ButtonColored } from "styled/button";
import styled from "styled-components";

export const InfoModal = styled(Modal)`
    ${StyledInner} {
        background-color: #e9eef2;
        border-radius: 20px;
        padding: 47px 74px 62px;
        display: flex;
        flex-direction: column;
        gap: 36px;
        max-width: 1002px;
        width: 100%;
    }

    ${StyledClose} {
        display: none;
    }
`;

export const TitleSC = styled(Title)`
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0;
    color: #181819;
`;

export const ParagraphSC = styled(Paragraph)`
    font-size: 24px;
    line-height: 1.5;
    color: #181819;
`;

export const Button = styled(ButtonColored)`
    background: linear-gradient(90deg, #db5318 0%, #fca259 100%);
    font-size: 16px;
    line-height: 1.2;
    padding: 15px 0;
    width: 386px;
    border-radius: 60px;
    align-self: center;
`;
