import { User } from "gql/types/operation-result-types";
import { ReactElement } from "react";
import { UserConsumer } from "..";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    children: (user: NonNullable<User["user"]>) => ReactElement;
}

export const UserExists = React.memo((props: IProps) => {
    return (
        <UserConsumer>
            {user => (user ? props.children(user) : null)}
        </UserConsumer>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
