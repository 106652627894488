import { useHowDidYouKnowOptions } from "hooks/query/use-how-did-you-know-options";
import {
    getOriginalData,
    mergeSelectorData,
    mockHowDidYouKnowOptions,
} from "components/filters/services";
import { useTranslation } from "react-i18next";

export const useHowDidYouKnowWithTranslations = () => {
    const [__] = useTranslation();
    const { howDidYouKnowOptions, loading } = useHowDidYouKnowOptions();
    const translatedData = React.useMemo(
        () =>
            mergeSelectorData(
                mockHowDidYouKnowOptions(__),
                getOriginalData(howDidYouKnowOptions),
            ),
        [__, howDidYouKnowOptions],
    );

    return {
        howDidYouKnowList: translatedData as Array<{
            label: string;
            value: number;
        }>,
        loading,
    };
};
