import { TabsPanels } from "components/tabs/styled";
import { ReactNode } from "react";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    children: ReactNode;
}

export const TabContent = React.memo((props: IProps) => {
    return <TabsPanels>{props.children}</TabsPanels>;
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
