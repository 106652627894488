import { CheckboxSC } from "./styled";

export interface CheckboxProps {
    id: string;
    type: "checkbox" | "radio";
    name?: string;
    label?: string;
    value?: any;
    disabled?: boolean;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = ({ id, label, ...rest }: CheckboxProps) => {
    return (
        <>
            <CheckboxSC id={id} {...rest} />
            <label htmlFor={id}>{label && <span>{label}</span>}</label>
        </>
    );
};
