import { useTranslation } from "react-i18next";
import { FormGroup } from "styled/layouts";
import { Field, useFormikContext } from "components/formik";
import { ContentLoader } from "components/components-common/content-loader";
import { List } from "react-content-loader";
import { FieldArray } from "formik";
import { AdversitingAction } from "views/manager/advertising/adversiting-payments/view-edit-steps";
import {
    AdvertisingCompany,
    CompanyField,
} from "views/manager/advertising/components/company-field";
import { CountryField } from "components/filters/fields/country";
import { useTranslationList } from "hooks/use-translations-list";
import { AddFileArray } from "views/manager/advertising/components/add-file-array";
import { usePaymentSystemsWithFields } from "hooks/query/use-payment-systems-with-fields";
import { usePaymentSystemsWithFieldsAndTranslations } from "hooks/query/query-with-translations/use-payment-systems-with-translations";
import { PaymentSystemType } from "hooks/query/use-payment-systems";

interface PaymentInfoProps {
    adversitingAction?: AdversitingAction;
    advertisingCompany?: AdvertisingCompany | null;
    options?: {
        countryFieldLabel?: string;
        hideFileFields?: boolean;
        hideCountry?: boolean;
    };
    paymentSystemLabel?: string;
    disabled?: boolean;
    countryFieldName?: string;
}
export const PaymentInfo = ({
    advertisingCompany,
    options,
    paymentSystemLabel,
    disabled,
    countryFieldName,
}: PaymentInfoProps) => {
    const [__] = useTranslation();
    const [DT] = useTranslation();
    const { values, setFieldValue } = useFormikContext<{
        partnerId: number;
        siteId: number | null;
        paymentSystem:
            | ReturnType<
                  typeof usePaymentSystemsWithFields
              >["paymentSystems"][0]
            | null;
        filesContent: Array<{ name: string; path: string }>;
    }>();
    const { paymentSystemsOptions, validateField } =
        usePaymentSystemsWithFieldsAndTranslations(false);

    const { FIELD_SHOULD_BE_FILLED } = useTranslationList();

    const isBankTransferWithCompany =
        values.paymentSystem?.id === PaymentSystemType.BankTransferWithCompany;

    const isOneOfBankTransfers =
        isBankTransferWithCompany ||
        values.paymentSystem?.id === PaymentSystemType.BankTransfer;

    return (
        <ContentLoader component={List} loading={!paymentSystemsOptions}>
            <FormGroup>
                <Field
                    name="paymentSystem"
                    label={
                        paymentSystemLabel ||
                        __("Предпочитаемый способ оплаты:")
                    }
                    type="react-select"
                    options={paymentSystemsOptions}
                    selectFirst={false}
                    isSearchable
                    isClearable={false}
                    isDisabled={disabled}
                />
            </FormGroup>
            {isBankTransferWithCompany && (
                <CompanyField
                    company={advertisingCompany}
                    disabled={disabled}
                />
            )}
            {!options?.hideCountry && isOneOfBankTransfers && (
                <FormGroup>
                    <CountryField
                        name={countryFieldName || "country"}
                        key={countryFieldName || "country"}
                        label={options?.countryFieldLabel}
                        isDisabled={disabled}
                        isClearable={false}
                        validate={value => {
                            if (!value) {
                                return FIELD_SHOULD_BE_FILLED();
                            }
                        }}
                    />
                </FormGroup>
            )}
            <FieldArray
                name="paymentSystem.fields"
                render={() => (
                    <>
                        {values.paymentSystem?.fields?.map((field, index) => {
                            return (
                                <FormGroup key={index}>
                                    <Field
                                        name={`paymentSystem.fields[${index}].value`}
                                        type="text"
                                        label={`${DT(field.name)}`}
                                        validate={value =>
                                            validateField(field, value)
                                        }
                                        disabled={disabled}
                                    />
                                </FormGroup>
                            );
                        })}
                    </>
                )}
            />
            {!options?.hideFileFields && isOneOfBankTransfers && (
                <AddFileArray
                    name="filesContent"
                    values={values}
                    setFieldValue={setFieldValue}
                    uploadFileMethod="advertisingPayment/uploadadvertisementpaymentfile"
                    additionalParamsForMethod={{
                        AffiliateId: values.partnerId,
                        ...(values.siteId ? { SiteId: values.siteId } : {}),
                    }}
                    accept=".pdf, image/jpeg, image/jpg, image/png, .bmp"
                    disabled={disabled}
                    isRequiredFile
                />
            )}
        </ContentLoader>
    );
};
