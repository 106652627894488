import { Cookies, CookiesProvider } from "react-cookie";
import { LanguageProvider } from "components/language-provider";
import { Query } from "@apollo/client/react/components";
import { Provider } from "react-redux";
import { Store } from "redux";
import { Routes } from "./routes";
import { useAddUserDataToLog } from "hooks/use-enhance-log";
import { GetPartnersProgramGeneralInfo_partnersProgram_generalInformation } from "gql/types/operation-result-types";
import { PureGlobalStyles } from "services/styled-components/pure-global-styles";
import { usePartnersProgram } from "hooks/use-partners-program-id";

const originalFetchData = Query.prototype.fetchData;
Query.prototype.fetchData = function (...args: any[]) {
    if (this.props.ssr !== true) {
        return false;
    }
    return originalFetchData.apply(this, args as any);
};

interface IProps {
    store: Store;
    cookies?: Cookies;
    projectNameInTitle: string;
    acceptLanguage?: string;
    geoCountryCode?: string | string[] | undefined;
}

export const PartnersProgramGeneralInformationContext = React.createContext<
    GetPartnersProgramGeneralInfo_partnersProgram_generalInformation | undefined
>(undefined);

export const ViewsRoot = ((props: IProps) => {
    useAddUserDataToLog();
    const partnersProgram = usePartnersProgram();
    if (!partnersProgram) {
        return null;
    }

    return (
        <Provider store={props.store}>
            <CookiesProvider cookies={props.cookies}>
                <LanguageProvider
                    acceptLanguage={props.acceptLanguage}
                    geoCountryCode={props.geoCountryCode}
                >
                    <PureGlobalStyles />
                    <Routes />
                </LanguageProvider>
            </CookiesProvider>
        </Provider>
    );
}) as React.ComponentType<IProps>;
