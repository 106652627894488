import { useTranslation } from "react-i18next";
import { Periods } from "../fields/period";

export const usePeriodOptions = () => {
    const [__] = useTranslation();

    return [
        { value: Periods.CUSTOM, label: __("Произвольный период") },
        { value: Periods.TODAY, label: __("Сегодня") },
        { value: Periods.YESTERDAY, label: __("Вчера") },
        { value: Periods.CURRENT_MONTH, label: __("Текущий месяц") },
        { value: Periods.PREVIOUS_MONTH, label: __("Прошлый месяц") },
        { value: Periods.CURRENT_YEAR, label: __("Текущий год") },
        { value: Periods.PREVIOUS_YEAR, label: __("Прошлый год") },
    ];
};
