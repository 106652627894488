import styled, { keyframes } from "styled-components";

interface IProps {
    animationDelay: any;
    color: string;
}

export const Animation = keyframes`
0%, 70%, 100% {
    transform: scale3D(1,1,1);
}
35% {
    transform: scale3D(0,0,1);
}
`;

interface IColor {
    color: string;
}
export const Preloader = styled.div<IColor>`
    display: flex;
    opacity: 1;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${props => props.color};
`;

export const PreloaderCube = styled.div`
    z-index: 105;
    margin: auto;
    width: 80px;
    height: 80px;
    display: flex;
    flex-wrap: wrap;
`;

export const Cube = styled.div<IProps>`
    width: calc(100% / 3);
    height: calc(100% / 3);
    flex-grow: 1;
    background-color: ${props => props.color};
    animation: ${Animation} 1.3s infinite ease-in-out;
    animation-delay: ${props => props.animationDelay};
`;
