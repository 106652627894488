import { TelegramPlane } from "@styled-icons/fa-brands/TelegramPlane/TelegramPlane";
import {
    Container,
    Icon,
    Title,
    Subtitle,
    Link,
    ConnectButton,
    BottomText,
} from "./styled";

export const PartnerBanner = () => {
    return (
        <Container>
            <Icon
                href="https://t.me/STARZAC_BOT"
                target="_blank"
                rel="noopener noreferrer"
            >
                <TelegramPlane size={25} />
            </Icon>
            <Title>Looking for help?</Title>
            <Subtitle>
                Please contact our new partner support via Telegram contact:
            </Subtitle>
            <Link
                href="https://t.me/waw888starz"
                target="_blank"
                rel="noopener noreferrer"
            >
                @waw888starz
            </Link>
            <ConnectButton
                href="https://t.me/waw888starz"
                target="_blank"
                rel="noopener noreferrer"
            >
                Connect
            </ConnectButton>
            <BottomText>
                Do not hesitate to contact us. Have a good day!
            </BottomText>
        </Container>
    );
};
