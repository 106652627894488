import { PARIPULSE_PARTNERS } from "server/partner-programs";
import { UniversalField } from "components/formik/universal-field";
import { Field, useFormikContext } from "components/formik";
import { usePartnersProgram } from "hooks/use-partners-program-id";
import { useCampaigns } from "hooks/query/use-campaigns";
import { useTranslation } from "react-i18next";
import { ISelectFild } from "../select-filed-interface";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { useMemo } from "react";

interface IInjectedProps {}

interface IProps extends IInjectedProps, ISelectFild {
    partnerId?: number;
    minWidth?: number;
}

export const CampaignField = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const { programId } = usePartnersProgram() || {};
    const { campaigns, loading } = useCampaigns(props.partnerId);
    const { values, status } = useFormikContext();
    const { name, ...rest } = props;
    const { campaignBlink, params = {} } = status;
    const currency = (values as any).currency;

    const options = useMemo(() => {
        if (!campaigns) {
            return [];
        }

        let filteredCampaigns = campaigns;

        if (programId === PARIPULSE_PARTNERS) {
            const sortableNames = ["player_reg", "aff_reg", "app_download"];
            const sortableCampaigns = new Map<
                string,
                typeof campaigns[number]
            >();
            filteredCampaigns = filteredCampaigns.filter(campaign => {
                const shouldKeep = sortableNames.includes(campaign.name);
                if (shouldKeep) {
                    sortableCampaigns.set(campaign.name, campaign);
                }
                return !shouldKeep;
            });
            filteredCampaigns = [
                sortableCampaigns.get(sortableNames[0]),
                sortableCampaigns.get(sortableNames[1]),
                ...filteredCampaigns,
                sortableCampaigns.get(sortableNames[2]),
            ].filter(<T,>(item: T): item is NonNullable<T> => Boolean(item));
        }

        if (params.directLink !== undefined) {
            filteredCampaigns = filteredCampaigns.filter(
                campaign => campaign.directLink === params.directLink,
            );
        }

        return filteredCampaigns.map(campaign => ({
            value: campaign,
            label: campaign.name,
            isDisabled: currency
                ? parseInt(currency.id) !== campaign.currency
                : false,
        }));
    }, [campaigns, currency, params.directLink, programId]);

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={props.name}
            label={__("Кампания")}
            type="react-select"
            component={UniversalField}
            options={options}
            isLoading={loading}
            isDisabled={loading}
            blink={campaignBlink}
            minWidth={400}
            {...rest}
        />
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
