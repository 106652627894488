import { IReactTableColumn } from "components/react-table/column";
import {
    PartnerListType,
    PartnerStatus,
} from "gql/types/operation-result-types";
import { useTranslation } from "react-i18next";
import { getPartnerStatus } from "services/project-utils";
import { ButtonColoredWithIcon, BtnColor } from "styled/button";
import { CheckSquare } from "@styled-icons/boxicons-regular/CheckSquare/CheckSquare";
import { CellWithButtons } from "components/table-item";
import { LocalizedLink } from "@webx-react/i18n";
import { EditOutline } from "@styled-icons/evaicons-outline/EditOutline";

interface UsePartnerListColumnsProps {
    partnerListType: PartnerListType;
    choosePartner?: (partnerId: number) => void;
}

export const usePartnerListColumns = ({
    partnerListType,
    choosePartner,
}: UsePartnerListColumnsProps) => {
    const [__] = useTranslation();

    const baseColumns: IReactTableColumn[] = [
        {
            Header: __("ID партнера"),
            accessor: "partnerId",
            disabled: true,
            minWidth: 80,
            Cell: cellInfo => {
                const button = (
                    <>
                        {partnerListType === PartnerListType.PartnerList && (
                            <ButtonColoredWithIcon
                                btnColor={BtnColor.Faded}
                                title={__("Редактировать")}
                                type="button"
                            >
                                <LocalizedLink
                                    className="no-indent"
                                    to={`/agent/main/partner-list/edit-partner/${cellInfo.original.partnerId}`}
                                >
                                    <EditOutline size={15} />
                                </LocalizedLink>
                            </ButtonColoredWithIcon>
                        )}
                        {choosePartner && (
                            <ButtonColoredWithIcon
                                onClick={() => choosePartner(cellInfo.value)}
                                title={__("Выбрать")}
                                btnColor={BtnColor.Faded}
                            >
                                <CheckSquare size={15} />
                            </ButtonColoredWithIcon>
                        )}
                    </>
                );
                return (
                    <CellWithButtons
                        button={button}
                        link={false}
                        text={cellInfo.value}
                    />
                );
            },
        },
        {
            Header: __("Имя партнера"),
            accessor: "partnerName",
            disabled: true,
            minWidth: 120,
        },
        {
            Header: __("Email"),
            accessor: "email",
            minWidth: 120,
            Cell: cellInfo =>
                cellInfo.value ? (
                    <CellWithButtons link={false} text={cellInfo.value} />
                ) : (
                    ""
                ),
        },
        {
            Header: __("Страна"),
            accessor: "country",
            minWidth: 90,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            minWidth: 120,
            Cell: cellInfo =>
                cellInfo.value ? (
                    <CellWithButtons link={true} text={cellInfo.value} />
                ) : (
                    ""
                ),
        },
        {
            Header: __("Дата регистрации"),
            accessor: "registrationDate",
            minWidth: 80,
            dataType: "date",
        },
    ];

    const otherColumns: IReactTableColumn[] = [
        {
            Header: __("Дата окончания комиссионной группы"),
            accessor: "commissionGroupEndDate",
            show: false,
            minWidth: 80,
            dataType: "date",
        },
        {
            Header: __("Партнерская группа"),
            accessor: "partnersGroup",
            show: false,
            minWidth: 80,
        },
        {
            Header: __("Комиссионная группа"),
            accessor: "comissionGroup",
            show: false,
            minWidth: 80,
        },
        {
            Header: __("Привлечен партнером"),
            accessor: "subpurtnerOf",
            show: false,
            minWidth: 80,
        },
        {
            Header: __("Статус"),
            accessor: "status",
            show: false,
            minWidth: 80,
            Cell: cellInfo =>
                getPartnerStatus(__, cellInfo.value as PartnerStatus),
        },
        {
            Header: __("Игроков"),
            accessor: "countOfAccountsWithDeposits",
            show: false,
            minWidth: 100,
        },
    ];

    const columns = {
        [PartnerListType.AdvertisingPayments]: baseColumns,
        [PartnerListType.FinPlan]: baseColumns,
        [PartnerListType.PartnerList]: baseColumns.concat(otherColumns),
    };

    return columns[partnerListType];
};
