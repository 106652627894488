import { LabelComponent } from "components/formik/universal-field";
import { Field } from "components/formik";
import { useLanguagesWithTranslations } from "hooks/query/query-with-translations/use-languages-with-translations";
import { useTranslation } from "react-i18next";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

interface IProps extends ISelectFild {
    labelComponent?: LabelComponent;
}

export const LanguageField = React.memo((props: IProps) => {
    const { name, ...rest } = props;
    const [__] = useTranslation();
    const { languages, loading } = useLanguagesWithTranslations();
    useIsSelectOptionsLoading(name, loading, languages, props.loadingContext);

    return (
        <Field
            name={props.name}
            label={props.label || __("Язык")}
            labelComponent={props.labelComponent}
            type="react-select"
            options={languages}
            isLoading={loading}
            {...rest}
        />
    );
});
