import { AndroidLogo } from "components/android-app/android-logo";
import { Access } from "components/check-roles/access";
import { anyRole, adAgent, manager } from "components/check-roles/rules";
import { AskQuestions } from "components/header/components/ask-questions";
import { useDefinedContext } from "hooks/use-context-exist";
import { connect } from "react-redux";
import { Action } from "redux";
import { StatesActionCreators } from "services/redux/root/states/actions";
import { statesSelectors } from "services/redux/root/states/selectors";
import { PartnersProgramGeneralInformationContext } from "views";
import { MenuItem } from "./menu-item";
import {
    AsideLanguageSwitcher,
    AsideLinkWrapper,
    LinkProfile,
    SignOut,
    SignOutFromPartnerInterface,
} from "./styled";
import {
    _1XBET_PARTNERS,
    PARTNERS_1XBET,
} from "../../../../server/partner-programs";

interface IInjectedProps {
    isProfileMenuClicked: boolean;
    setIsProfileMenuClicked: (visibility: boolean) => Action;
}

interface IProps extends IInjectedProps {}

export const ProfileAsideOptions = connect(
    state => ({
        isProfileMenuClicked: statesSelectors.getIsProfileMenuClicked(state),
    }),
    dispatch => ({
        setIsProfileMenuClicked: (visibility: boolean) =>
            dispatch(StatesActionCreators.setIsProfileMenuClicked(visibility)),
    }),
)(
    React.memo((props: IProps) => {
        const partnersGeneralInformation = useDefinedContext(
            PartnersProgramGeneralInformationContext,
        );
        return (
            <AsideLinkWrapper
                onClick={() =>
                    props.setIsProfileMenuClicked(!props.isProfileMenuClicked)
                }
            >
                <Access guards={[anyRole([adAgent, manager])]}>
                    <SignOutFromPartnerInterface />
                </Access>

                {partnersGeneralInformation.partnersProgramId ===
                    _1XBET_PARTNERS ||
                partnersGeneralInformation.partnersProgramId ===
                    PARTNERS_1XBET ? (
                    <AskQuestions />
                ) : null}

                <LinkProfile />

                {partnersGeneralInformation.androidAppLinks && (
                    <MenuItem>
                        <AndroidLogo
                            size="22"
                            link={partnersGeneralInformation.androidAppLinks}
                        />
                    </MenuItem>
                )}

                <AsideLanguageSwitcher
                    textColor={partnersGeneralInformation.stylesTheme.textColor}
                    bgColor={
                        partnersGeneralInformation.stylesTheme
                            .languageSwitcherBG
                    }
                    accentColor={
                        partnersGeneralInformation.stylesTheme.accentColor
                    }
                    screen="mobile"
                />

                <SignOut />
            </AsideLinkWrapper>
        );
    }),
) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
