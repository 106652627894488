import { Times } from "@styled-icons/fa-solid/Times/Times";
import { Logo } from "components/logo";
import { SitePartitionType } from "views/site-patrition-wrapper";
import { connect } from "react-redux";
import { Action } from "redux";
import { StatesActionCreators } from "services/redux/root/states/actions";
import { statesSelectors } from "services/redux/root/states/selectors";
import { LogoContainer } from "components/menu/main-menu/styled";
import { AsideContent } from "components/menu/main-menu";
import { useRef, useEffect } from "react";
import { CloseButton } from "components/menu/menu-components/close-button/styled";
import { MainMenuContent } from "components/menu/main-menu/main-menu-content";
import { MainMenuSC } from "../main-menu/styled";
import { StyledCloseWrapp } from "components/overlays/styled";
import { sizes } from "services/styled-components/variables";

interface IInjectedProps {
    isMainMenuClicked: boolean;
    setIsMainMenuClicked: (visibility: boolean) => Action;
}

export interface IMobileMainMenuProps extends IInjectedProps {
    adminRole: SitePartitionType;
}

export const MobileMainMenu = connect(
    state => ({
        isMainMenuClicked: statesSelectors.getIsMainMenuClicked(state),
    }),
    dispatch => ({
        setIsMainMenuClicked: (visibility: boolean) =>
            dispatch(StatesActionCreators.setIsMainMenuClicked(visibility)),
    }),
)(
    React.memo((props: IMobileMainMenuProps) => {
        const refMenu = useRef(null);
        const { adminRole, isMainMenuClicked, setIsMainMenuClicked, ...rest } =
            props;

        useEffect(() => {
            if (window.matchMedia(`(max-width: ${sizes.sm}px)`).matches) {
                document.body.style.overflow = isMainMenuClicked
                    ? "hidden"
                    : "auto";
            }
        }, [isMainMenuClicked]);

        return (
            <>
                <MainMenuSC
                    ref={refMenu}
                    isMainMenuClicked={isMainMenuClicked}
                    onClick={() => setIsMainMenuClicked(!isMainMenuClicked)}
                    {...rest}
                >
                    <CloseButton orientation="left">
                        <Times size={30} />
                    </CloseButton>

                    <AsideContent>
                        {/* в мобильном меню не нужно лого */}
                        {/* нужно будет его удалить, но надо учесть это в стилсях, где -70px */}
                        <LogoContainer>
                            <Logo />
                        </LogoContainer>

                        <MainMenuContent adminRole={adminRole} />
                    </AsideContent>
                </MainMenuSC>
                {isMainMenuClicked && (
                    <StyledCloseWrapp
                        onClick={() => setIsMainMenuClicked(!isMainMenuClicked)}
                        {...rest}
                    />
                )}
            </>
        );
    }),
) as React.ComponentType<Subtract<IMobileMainMenuProps, IInjectedProps>>;
