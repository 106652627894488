import {
    CalculationType,
    GetCommissionList_authorized_partner_comissionStructures_description_CPADescription,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { formatCurrency } from "services/project-utils";
import { getDescriptionDate, IDescriptionFoo } from "../comission-description";

export const getCpaDescription = (
    structureList: IDescriptionFoo,
    __: TFunction,
) => {
    const cpaComission = g(
        structureList,
        "description",
    ) as GetCommissionList_authorized_partner_comissionStructures_description_CPADescription;
    if (cpaComission) {
        const {
            amount,
            daysOfHold,
            dateStart,
            dateEnd,
            comissionPaymentAboveLevels,
            currency,
            parametres,
            levels,
        } = cpaComission;

        const Amount = !levels?.length
            ? format(__("Сумма CPA: {cpaAnmount}"), {
                  cpaAnmount: formatCurrency(amount) + `(${currency})`,
              }) + "; "
            : "";

        const DayHoldup = format(__("Дней холда: {dayHoldup}"), {
            dayHoldup: daysOfHold,
        });

        let TypeTCOMI = "";
        let ComissionLevel = "";

        if (parametres?.length) {
            TypeTCOMI = parametres
                .map(item => {
                    const { parametrNumber, comissionType } = item;

                    if (parametrNumber === 1) {
                        switch (comissionType) {
                            case CalculationType.COUNT_OF_REGISTRATIONS:
                                return (
                                    __("Регистрация") + ": " + `${item.amount}`
                                );
                            case CalculationType.COUNT_OF_REGISTRATIONS_WITH_DEPOSITS:
                                return (
                                    __("Регистрация с депозитом") +
                                    ": " +
                                    `${item.amount}`
                                );
                            case CalculationType.COUNT_OF_BETS:
                                return (
                                    __("Число ставок") + ": " + `${item.amount}`
                                );
                            default:
                                return "";
                        }
                    }
                    switch (comissionType) {
                        case CalculationType.PROFIT:
                            return (
                                __("Доход") +
                                ": " +
                                `${formatCurrency(item.amount)}` +
                                `(${currency})`
                            );
                        case CalculationType.DEPOSIT_AMOUNT:
                            return (
                                __("Сумма депозитов") +
                                ": " +
                                `${formatCurrency(item.amount)}` +
                                `(${currency})`
                            );
                        case CalculationType.BETS_AMOUNT:
                            return (
                                __("Сумма ставок") +
                                ": " +
                                `${formatCurrency(item.amount)}` +
                                `(${currency})`
                            );
                        default:
                            return "";
                    }
                })
                .join("; ");
            TypeTCOMI += "; ";
        }

        if (levels?.length) {
            ComissionLevel = levels
                .map(item =>
                    item
                        ? format(__("Уровень {comissionLevels}"), {
                              comissionLevels: `${item.order}/${
                                  item.playersAmount
                              }/${formatCurrency(
                                  item.paymentPlayer,
                              )}(${currency})`,
                          })
                        : "",
                )
                .join(" ");
            ComissionLevel +=
                " " +
                __("Оплата за игрока при превышении установленных уровней") +
                ": " +
                `${formatCurrency(comissionPaymentAboveLevels)}(${currency})` +
                "; ";
        }

        const date = getDescriptionDate(dateStart, dateEnd);

        return `${Amount}${DayHoldup}; ${TypeTCOMI}${ComissionLevel}${date}`;
    }
};
