import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Modal } from "components/components-common/modal";
import {
    ConfirmEmail,
    ConfirmEmailVariables,
} from "gql/types/operation-result-types";
import { useLocalizedAbsolutePush } from "hooks/use-localised-absolute-push";
import { ButtonColored } from "styled/button";
import { Container } from "views/landing/confirm-email-page/styled";
import CONFIRM_EMAIL from "views/landing/confirm-email-page/confirm-email.gql";
import { ThemeProvider } from "components/theme/theme-provider";
import { GeneralInfromationContextProvider } from "components/general-information-context-provider";
import { RootHelmetAndScriptsBodyInjector } from "components/root-helmet";
import { RegularGlobalStyles } from "services/styled-components/regular-global-styles";
import { TitleContextWraper } from "components/admin-context";
import { HelmetWrapper } from "components/helmet/helmet-wrapper";
import PARTNER_ACCOUNT from "views/partner/main/profile/partner-account.gql";
import { errorHandler } from "services/error-handler";

interface IProps extends RouteComponentProps {}

export const ConfirmEmailPage = (props: IProps) => {
    const [__] = useTranslation();
    const [isModalOpened, setIsModalOpened] = useState(false);
    const confirmSent = useRef(false);
    const push = useLocalizedAbsolutePush();

    const [confirmEmail] = useMutation<ConfirmEmail, ConfirmEmailVariables>(
        CONFIRM_EMAIL,
        {
            refetchQueries: [{ query: PARTNER_ACCOUNT }],
        },
    );

    const onConfirmEmail = useCallback(
        async (token: string) => {
            confirmSent.current = true;

            try {
                const { data } = await confirmEmail({
                    variables: {
                        id: token.trim(),
                    },
                });

                if (data?.authorization.confirmEmail) {
                    return setIsModalOpened(true);
                } else {
                    toast.error(
                        __("Что-то пошло не так, ошибка: confirmEmail"),
                    );
                    push("/");
                }
            } catch (error: any) {
                void errorHandler(error);
                push("/");
            }
        },
        [__, confirmEmail, push],
    );

    useEffect(() => {
        const search = new URLSearchParams(props.location.search);
        const id = search.get("id");
        if (id && !confirmSent.current) {
            void onConfirmEmail(id);
        }

        if (!id) {
            push("/");
        }
    }, [onConfirmEmail, props.location.search, push]);

    const handleClose = () => {
        setIsModalOpened(false);
        push("/partner");
    };

    return (
        <GeneralInfromationContextProvider>
            <ThemeProvider>
                <RootHelmetAndScriptsBodyInjector />
                <RegularGlobalStyles />
                <TitleContextWraper>
                    <HelmetWrapper>
                        <Modal
                            isOpen={isModalOpened}
                            onRequestClose={handleClose}
                        >
                            <Container>
                                <h2>{__("Ваша почта подтверждена!")}</h2>
                                <ButtonColored onClick={handleClose}>
                                    {__("Закрыть")}
                                </ButtonColored>
                            </Container>
                        </Modal>
                    </HelmetWrapper>
                </TitleContextWraper>
            </ThemeProvider>
        </GeneralInfromationContextProvider>
    );
};
