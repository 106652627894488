import {
    ReferralDomainData,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    ReferralDomains_authorized_manager_main_referralDomains_data,
} from "gql/types/operation-result-types";
import { useCurrency } from "hooks/query/use-currency";

type TFormValues = Omit<ReferralDomainData, "partnerId" | "currencyId"> & {
    currency: GetCurrencies_authorized_partnerAndManager_data_currencies | null;
};

type IUseInitialValues = (
    data: ReferralDomains_authorized_manager_main_referralDomains_data | null,
) => {
    initialValues: TFormValues;
    loading: boolean;
};

export const useInitialValues: IUseInitialValues = data => {
    const { data: currencies, loading: currenciesLoading } = useCurrency();

    return {
        initialValues: {
            currency:
                currencies?.find(
                    currency => currency.name === data?.currency,
                ) || null,
            resource: data?.resource || "",
            hostName: data?.hostName || "",
            autoEmailSending: data?.autoEmailSending || false,
            autoUpdate: data?.autoUpdate || false,
        },
        loading: currenciesLoading,
    };
};
