import { css } from "styled-components";

const fontsPath = "../assets/fonts/onest/";

export const OnestFonts = css`
    @font-face {
        font-family: "Onest";
        src: url("${fontsPath}Onest-Thin.woff") format("truetype");
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: "Onest";
        src: url("${fontsPath}Onest-Light.woff") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "Onest";
        src: url("${fontsPath}Onest-Regular.woff") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Onest";
        src: url("${fontsPath}Onest-Medium.woff") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Onest";
        src: url("${fontsPath}Onest-Bold.woff") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "Onest";
        src: url("${fontsPath}Onest-ExtraBold.woff") format("truetype");
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: "Onest";
        src: url("${fontsPath}Onest-Black.woff") format("truetype");
        font-weight: 900;
        font-style: normal;
    }
`;
