import { IReactTableColumn } from "components/react-table/column";
import { useTranslation } from "react-i18next";
import { CellWithButtons } from "components/table-item";

export const useColumns = () => {
    const [__] = useTranslation();

    const columns: IReactTableColumn[] = [
        {
            Header: "ID",
            accessor: "id",
            minWidth: 80,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            minWidth: 80,
        },
        {
            Header: __("Валюта"),
            accessor: "currencyName",
            minWidth: 80,
        },
        {
            Header: __("Промо-код"),
            accessor: "promoCode",
            minWidth: 80,
            Cell: ({ value }) =>
                value ? <CellWithButtons link={false} text={value} /> : "",
        },
        {
            Header: "Btag",
            accessor: "btag",
            minWidth: 80,
            Cell: ({ value }) =>
                value ? <CellWithButtons link={false} text={value} /> : "",
        },
    ];

    return columns;
};
