import { useMutation } from "@apollo/client";
import {
    ChangePartnerSiteVisibility,
    ChangePartnerSiteVisibilityVariables,
    GetPartnerSitesParams,
} from "gql/types/operation-result-types";
import CHANGE_PARTNER_SITE_VISIBILITY from "../../../../gql/change-partner-site-visibility.gql";
import GET_SITES from "../../../../gql/get-partner-sites.gql";

export const useChangePartnerSiteVisibility = (
    getSitesVariables: GetPartnerSitesParams,
) => {
    const [changePartnerSiteVisibility, { loading }] = useMutation<
        ChangePartnerSiteVisibility,
        ChangePartnerSiteVisibilityVariables
    >(CHANGE_PARTNER_SITE_VISIBILITY, {
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_SITES,
                variables: {
                    query: { ...getSitesVariables, isVisible: true },
                },
            },
            {
                query: GET_SITES,
                variables: {
                    query: { ...getSitesVariables, isVisible: false },
                },
            },
        ],
    });

    return {
        changePartnerSiteVisibility,
        loading,
    };
};
