import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { AdminWrapperEdit } from "styled/layouts";
import { Title, Paragraph } from "services/styled-components/typogoraphy";
import { ContentLoader } from "components/components-common/content-loader";
import { GetInformationAboutManager } from "gql/types/operation-result-types";
import GET_INFO_ABOUT_MANAGER from "./get-information-about-manager-for-partner.gql";
import { List } from "react-content-loader";
import { StyledInfoRow } from "./styled";
import { ReactNode } from "react";

const renderInfoRow = (name: string, value?: ReactNode) => {
    return value ? (
        <StyledInfoRow>
            <Paragraph>{name}</Paragraph>
            {value}
        </StyledInfoRow>
    ) : null;
};

export const ContactManagerInfo = () => {
    const [__] = useTranslation();

    const infoQuery = useQuery<GetInformationAboutManager>(
        GET_INFO_ABOUT_MANAGER,
        {
            ssr: true,
        },
    );

    const information =
        infoQuery.data?.authorized.partner.getInformationAboutManagerForPartner;

    const hasSomeValue =
        information &&
        Object.entries(information).some(
            ([key, value]) => key !== "__typename" && value,
        );

    if (!information || (!hasSomeValue && !infoQuery.loading)) {
        return null;
    }

    return (
        <AdminWrapperEdit>
            <ContentLoader component={List} loading={infoQuery.loading}>
                <Title>{__("Контакты вашего менеджера")}</Title>
                {renderInfoRow(
                    __("Сайт"),
                    information.url ? (
                        <a
                            href={information.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {information.url}
                        </a>
                    ) : null,
                )}
                {renderInfoRow(__("Email адрес"), information.email)}
                {renderInfoRow(__("Мессенджер"), information.messengerName)}
                {renderInfoRow(
                    __("Логин мессенджера"),
                    information.messengerUserName,
                )}
                {renderInfoRow(__("Номер телефона"), information.phone)}
            </ContentLoader>
        </AdminWrapperEdit>
    );
};
