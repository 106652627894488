import { Field, useFormikContext } from "components/formik";
import { usePartnersGroups } from "hooks/query/use-partners-groups";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useIsSelectOptionsLoading } from "../hooks/use-is-select-iptions-loading";
import { ISelectFild } from "../select-filed-interface";

export const PartnerGroupField = React.memo((props: ISelectFild) => {
    const { name, ...rest } = props;
    const [__] = useTranslation();
    const { data, loading } = usePartnersGroups(true);
    const { values, status } = useFormikContext();
    const currency = (values as any).currency;

    const options = useMemo(
        () =>
            data?.partnerGroups.map(partnerGroup => ({
                value: partnerGroup,
                label: partnerGroup.name,
                isDisabled: currency
                    ? parseInt(currency.id) !== partnerGroup.currencyId
                    : false,
            })) || [],
        [data, currency],
    );

    useIsSelectOptionsLoading(name, loading, options, props.loadingContext);

    return (
        <Field
            name={props.name}
            label={__("Партнерская группа")}
            type="react-select"
            options={options}
            isLoading={loading}
            isDisabled={loading}
            blink={status?.partnersGroupBlink}
            minWidth={300}
            {...rest}
        />
    );
}) as React.ComponentType<ISelectFild>;
