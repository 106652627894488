import { ReactElement, useState } from "react";
import { Spiner } from "components/loaders/spiner";
import { SpinerWrapper } from "./styled";

// Позволяет загружать с начальными значениями, до того как пользователь
// нажал кнопку, но затем толко по нажатию кнопки
export const FirstLoadTable = <
    FormValuesType extends NonNullable<unknown>,
>(props: {
    actualValues: FormValuesType | undefined | null;
    submittedValues: FormValuesType | undefined | null;
    children: (values: FormValuesType) => ReactElement;
}) => {
    const [firstActualValue, setFirstActualValue] = useState<FormValuesType>();

    if (props.actualValues && !firstActualValue) {
        setFirstActualValue(props.actualValues);
    }

    const values = props.submittedValues || firstActualValue;

    return values ? (
        props.children(values)
    ) : (
        <SpinerWrapper>
            <Spiner />
        </SpinerWrapper>
    );
};
