import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { createSaveReport } from "components/react-table/table-select-variables/export/services/create-save-report";
import { ExportButton as _ExportButton } from "components/react-table/table-select-variables/export/components/export-button";
import { IExportParams } from ".";
import { User } from "gql/types/operation-result-types";

interface IProps {
    exportParams: IExportParams;
    user: NonNullable<User["user"]>;
    button: {
        format: string;
        caption: string;
    };
}

export const ExportButton = React.memo((props: IProps) => {
    const {
        button: { format, caption },
        exportParams,
        user,
    } = props;

    const [__] = useTranslation();
    const [saving, setSaving] = useState(false);
    const s = createSaveReport(format, { ...exportParams, user }, __);
    const save = async () => {
        if (saving) {
            return;
        }

        setSaving(true);
        try {
            await s();
        } catch (e) {
            //
        }
        setSaving(false);
    };
    return (
        <_ExportButton exportData={save} saving={saving} caption={caption} />
    );
});
