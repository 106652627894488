import ReactSelect from "react-select";
import {
    adminBorderColor2,
    focusColor,
    backgroundColor0,
    disabledColor,
    inverseColor,
} from "services/styled-components/themes";
import styled, { css } from "styled-components";
import { ReactSelectSC } from "styled/react-select";
import { media } from "services/styled-components/variables";
import { FUNPARI_PARTNERS } from "server/partner-programs";

interface IProps {
    minWidth?: number;
    color?: string;
    border?: string;
}
export const SelectSC = styled(ReactSelect)<IProps>`
    ${ReactSelectSC}
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    min-width: 170px;
    ${props =>
        props.minWidth &&
        css`
            min-width: ${props.minWidth}px;
            ${media.xsMax`
                min-width: auto;
            `}
        `}
    .react-select__control {
        border-radius: 0;
        border-color: ${adminBorderColor2};
        min-height: 2.25em;
        height: 100%;
        background: ${({ color }) => color && color};
        border: ${({ border }) => border && border};
        &--is-disabled {
            background-color: ${disabledColor};
            .react-select__indicator-separator {
                background-color: ${adminBorderColor2};
            }
            .react-select__indicator {
                color: ${adminBorderColor2};
            }
        }
    }
    .react-select__control--is-focused {
        box-shadow: 0 0 0.25em ${focusColor};
        :hover {
            border-color: ${adminBorderColor2};
        }
    }
    .react-select__menu {
        z-index: 2;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
    }
    .react-select__option {
        color: ${props => props.theme.primaryColor};
        background-color: ${backgroundColor0};
        &--is-disabled {
            color: #999;
        }
    }
    .react-select__option--is-focused,
    .react-select__option--is-selected:hover {
        ${props =>
            css`
                color: ${props.theme.partnersProgramId === FUNPARI_PARTNERS
                    ? inverseColor
                    : backgroundColor0};
            `}

        background-color: ${props => props.theme.accentColor};
    }
    .react-select__option--is-focused.react-select__option--is-selected {
        background-color: ${props => props.theme.accentHoverColor};
    }
    .react-select__single-value {
        &--is-disabled {
            color: inherit;
        }
    }
    input:focus {
        box-shadow: none !important;
    }
`;
