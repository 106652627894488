import { combineReducers, ReducersMapObject } from "redux";
import { all } from "redux-saga/effects";
import { settingsReducer } from "./settings/reducer";
import { statesReducer } from "./states/reducer";

export function* rootSaga() {
    yield all([]);
}

export const createRootReducer = (reducersToCombine: ReducersMapObject) =>
    combineReducers({
        settings: settingsReducer,
        states: statesReducer,
        ...reducersToCombine,
    });
