import { onSubmitWrapper } from "components/formik/on-submit-wrapper";
import { SubmitButton } from "components/formik/submit-button/submit-button";
import { Field, Form, FormikHelpers as FormikActions } from "components/formik";
import { useTranslationList } from "hooks/use-translations-list";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import { errorHandler } from "services/error-handler";
import { Title } from "services/styled-components/typogoraphy";
import * as yup from "yup";
import { LabelOneLine } from "../../styled";
import { RowTwoColumnsParagraph } from "styled/layouts";
import { FormGroup } from "styled/layouts";
import { FormikConsoleErrors } from "components/formik/formik-console-errors";
import { PasswordReactTooltip } from "components/components-common/react-tooltip";
import { PartnersProgramGeneralInformationContext } from "views";
import { useDefinedContext } from "hooks/use-context-exist";
import { passwordRegex } from "services/validators";

interface IInjectedProps {}

interface IProps extends IInjectedProps {
    onSubmit: (
        values: typeof initialValues,
        formikActions: FormikActions<typeof initialValues>,
    ) => Promise<void>;
}

export const ChangePasswordForm = React.memo((props: IProps) => {
    const [__] = useTranslation();
    const {
        WEAK_PASSWORD,
        DOES_NOT_MATCH_WITH_PASSWORD,
        FIELD_SHOULD_BE_FILLED,
        AT_LEAST_MIN_CHARACTERS,
        UP_TO_MAX_CHARACTERS,
        INVALID_PASSWORD,
    } = useTranslationList();
    const [validationSchema] = useState(() =>
        yup.object().shape({
            oldPassword: yup.string().required(FIELD_SHOULD_BE_FILLED),
            password: yup
                .string()
                .required(FIELD_SHOULD_BE_FILLED)
                .min(8, AT_LEAST_MIN_CHARACTERS)
                .max(100, UP_TO_MAX_CHARACTERS)
                .matches(passwordRegex, INVALID_PASSWORD)
                .test({
                    test: async value => {
                        return !(
                            await import("@webx/password-strength")
                        ).PasswordStrength.getInfo(value).error;
                    },
                    message: WEAK_PASSWORD,
                }),
            confirmPassword: yup
                .string()
                .required(FIELD_SHOULD_BE_FILLED)
                .test({
                    message: DOES_NOT_MATCH_WITH_PASSWORD,
                    test(value) {
                        return value === this.parent.password;
                    },
                }),
        }),
    );
    const {
        stylesTheme: { accentColor, textColor },
    } = useDefinedContext(PartnersProgramGeneralInformationContext);

    return (
        <FormikConsoleErrors
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitWrapper(props.onSubmit, validationSchema)}
        >
            {() => {
                return (
                    <Form>
                        <Title>{__("Изменить пароль")}</Title>

                        <FormGroup>
                            <Field
                                name="oldPassword"
                                type="password"
                                label={`${__("Старый пароль")}`}
                                autoComplete="off"
                            />
                        </FormGroup>
                        <RowTwoColumnsParagraph>
                            <FormGroup>
                                <Field
                                    name="password"
                                    type="password"
                                    label={`${__("Новый пароль")}`}
                                    labelComponent={LabelOneLine}
                                    autoComplete="off"
                                    data-for="passwordTooltip"
                                    data-tip
                                />
                                <PasswordReactTooltip
                                    backgroundColor={accentColor}
                                    textColor={textColor}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    name="confirmPassword"
                                    type="password"
                                    label={`${__("Повторите новый пароль")}`}
                                    labelComponent={LabelOneLine}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        </RowTwoColumnsParagraph>
                        <SubmitButton>{__("Изменить пароль")}</SubmitButton>
                    </Form>
                );
            }}
        </FormikConsoleErrors>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;

export const initialValues: {
    oldPassword: string;
    password: string;
    confirmPassword: string;
} = {
    oldPassword: "",
    password: "",
    confirmPassword: "",
};
