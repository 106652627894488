import { useCallback, useEffect, useState } from "react";
import { PopupType } from "../popup-id";
import { useCookies } from "react-cookie";
import { INotificationPopupState } from "..";

const pοpupPrefixKey = "NotificationHistoryCookies_";
const getStorageKey = (popupId: number) => `${pοpupPrefixKey}${popupId}`;

export const usePopupHistory = (popupId: PopupType) => {
    const [cookies, , removeCookie] = useCookies([getStorageKey(popupId)]);

    const [popupHistory, setPopupHistory] = useState<INotificationPopupState>(
        () => {
            const storageKey = getStorageKey(popupId);
            const storedHistory = localStorage.getItem(storageKey);

            if (storedHistory) {
                return JSON.parse(storedHistory);
            }

            // Switch from cookies to localStorage
            const cookieHistory = cookies[storageKey];
            if (cookieHistory) {
                localStorage.setItem(storageKey, JSON.stringify(cookieHistory));
                removeCookie(storageKey);
                return cookieHistory;
            }

            return {
                clickedDontShowAgain: false,
                countOfTimesWasShown: 0,
                extraData: {},
            };
        },
    );

    useEffect(() => {
        const storageKey = getStorageKey(popupId);
        localStorage.setItem(storageKey, JSON.stringify(popupHistory));
    }, [popupHistory, popupId]);

    return {
        popupHistory,
        setPopupHistory,
    };
};

export const usePopupHistories = () => {
    const getPopupsIds = useCallback(() => {
        return Object.keys(localStorage)
            .filter(key => key.startsWith(pοpupPrefixKey))
            .map(key => parseInt(key.split("_")[1]));
    }, []);

    const removePopupHistory = useCallback((popupId: PopupType) => {
        const storageKey = getStorageKey(popupId);
        localStorage.removeItem(storageKey);
    }, []);

    return {
        getPopupsIds,
        removePopupHistory,
    };
};
