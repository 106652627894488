import { useCookies } from "react-cookie";
import { useState, useEffect, useCallback } from "react";
import { ITablePagination } from "hooks/use-pagination";
import { Variables } from "services/variables";
import _ from "lodash";

export function usePagination(
    uniqueId: string,
    externalPaginationAndSorting?: ITablePagination,
    defaultPageSize = 10,
) {
    const [currentPage, setCurrentPage] = useState(0);
    const [cookies, setCookies] = useCookies([]);
    const defaultPageSizeCookies = `${uniqueId}_dps`;
    const [currentPageSize, setCurrentPageSize] = useState(
        cookies[defaultPageSizeCookies] || defaultPageSize,
    );

    useEffect(() => {
        const externalPageNumber =
            externalPaginationAndSorting?.state.pageNumber;
        if (externalPageNumber) {
            setCurrentPage(externalPageNumber ? externalPageNumber - 1 : 0);
        }
    }, [externalPaginationAndSorting?.state.pageNumber]);

    useEffect(() => {
        if (externalPaginationAndSorting) {
            externalPaginationAndSorting.actions.setCountOnPage(
                cookies[defaultPageSizeCookies] || defaultPageSize,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPageSizeChange = useCallback(
        (newPageSize: any) => {
            const targetItem = currentPageSize * currentPage;
            const targetPage = Math.floor(targetItem / newPageSize);
            setCurrentPage(targetPage);
            if (externalPaginationAndSorting) {
                externalPaginationAndSorting.actions.setPagenum(targetPage);
                externalPaginationAndSorting.actions.setCountOnPage(
                    newPageSize,
                );
            }
            setCookies(
                defaultPageSizeCookies,
                newPageSize,
                Variables.cookiesEndlessOptions,
            );
            setCurrentPageSize(newPageSize);
        },
        [
            currentPage,
            currentPageSize,
            defaultPageSizeCookies,
            externalPaginationAndSorting,
            setCookies,
        ],
    );

    defaultPageSize =
        _.parseInt(cookies[defaultPageSizeCookies]) || defaultPageSize;

    return {
        defaultPageSize,
        onPageSizeChange,
        currentPage,
        setCurrentPage,
    };
}
