import styled from "styled-components";

export const Container = styled.div`
    width: 18.8em;
    margin-right: 0.8em;
`;
export const Text = styled.p`
    color: #273a4c;
    font-size: 1.1em;
`;
