import axios from "axios";
import { saveAs } from "file-saver";
import { parse } from "content-disposition";
import { PlayersActivityReportFilter } from "gql/types/operation-result-types";
import { ReportFormat } from "gql/types/enumReportFormat";

export const exportPlayersActivityReport = async (
    filter: PlayersActivityReportFilter,
    format: ReportFormat,
) => {
    const response = await axios.post<Blob>(
        "/lapi/get-player-activity-report",
        {
            filter,
            format,
        },
        {
            responseType: "blob",
        },
    );
    const disposition = parse(response.headers["content-disposition"]);
    const blob = new Blob([response.data], {
        type: response.data.type,
    });

    saveAs(blob, disposition.parameters.filename);
};
