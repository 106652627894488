import { ErrorType } from "./error-type";

import { createError, isInstance } from "apollo-errors";
const customApolloErrorFactory = createError("RequestError", {
    message: "RequestError has occurred",
    options: {
        showPath: true,
        showLocations: true,
    },
});

export interface WrappedApiRequestError extends RequestError {
    data: {
        code: ErrorType;
        details: Partners.ErrorModelActionResultModel;
    };
}

export class RequestError implements Error {
    public name: any;
    public message: any;
    public stack?: string | undefined;

    public constructor(
        errorType: ErrorType,
        message?: string | null,
        details?: any,
    ) {
        return new customApolloErrorFactory({
            data: {
                code: errorType,
                details,
            },
            message: message || ErrorType[errorType],
        });
    }

    public static isInstance: (error: any) => boolean = error =>
        error && (isInstance(error) || error.name === "RequestError");
}
