import { FieldArray } from "formik";
import { DeleteButton, DownloadButton } from "../../fin-plan/buttons";
import { FormGroupButton, FormDirectionRow } from "styled/layouts";
import { FakeLabel } from "components/filters/form";
import { AddFile, uploadFile } from "../add-file";
import { errorHandler } from "services/error-handler";
import { ErrorType } from "server/errors/error-type";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TPathsToUpload } from "server/controllers/api/files-api/upload-file-general";
import { ButtonColored } from "styled/button";
import { useState } from "react";

interface AddFileProps {
    name: string;
    values: { filesContent: Array<{ name: string; path: string }> };
    setFieldValue: (field: string, value: any) => void;
    uploadFileMethod: TPathsToUpload;
    additionalParamsForMethod?: Record<string, unknown>;
    accept?: string;
    disabled?: boolean;
    isRequiredFile?: boolean;
    baseDownloadPath?: string;
}

export const AddFileArray: React.FC<AddFileProps> = props => {
    const {
        name,
        values,
        setFieldValue,
        uploadFileMethod,
        additionalParamsForMethod,
        accept,
        disabled,
        isRequiredFile,
    } = props;
    const [__] = useTranslation();
    const [initialFiles] = useState(values.filesContent);

    return (
        <FieldArray name={name}>
            {({ remove, push }) => (
                <>
                    {values.filesContent?.map((fileItem, index) => (
                        <FormDirectionRow key={index} stretch>
                            <AddFile
                                name={`filesContent[${index}].name`}
                                values={values}
                                setFieldValue={async (
                                    field,
                                    value: File | undefined,
                                ) => {
                                    setFieldValue(field, value?.name || "");
                                    try {
                                        if (value) {
                                            const path = await uploadFile(
                                                uploadFileMethod,
                                                value,
                                                additionalParamsForMethod,
                                            );
                                            setFieldValue(
                                                `filesContent[${index}].path`,
                                                path,
                                            );
                                            toast.success(
                                                __("Файл успешно загружен"),
                                            );
                                        }
                                    } catch (e: any) {
                                        setFieldValue(field, "");
                                        void errorHandler(e, error => {
                                            switch (error.data.code) {
                                                case ErrorType.INVALID_FILE_FORMAT:
                                                    toast.error(
                                                        __(
                                                            "Неверный формат файла",
                                                        ),
                                                    );
                                                    break;
                                                case ErrorType.FILE_SIZE_EXCEEDED:
                                                    toast.error(
                                                        __(
                                                            "Превышен допустимый размер файла",
                                                        ),
                                                    );
                                                    break;
                                            }
                                        });
                                    }
                                }}
                                index={index}
                                accept={accept}
                                buttonType="upload"
                                disabled={disabled}
                                isRequiredFile={isRequiredFile}
                                placeholder={accept?.replace(/image\//g, ".")}
                            />
                            {!disabled && (
                                <FormGroupButton>
                                    <FakeLabel />
                                    <DeleteButton
                                        onClick={() => remove(index)}
                                    />
                                </FormGroupButton>
                            )}
                            {values.filesContent[index].path &&
                                props.baseDownloadPath &&
                                initialFiles.some(
                                    initFile =>
                                        initFile.name ===
                                        values.filesContent[index].name,
                                ) && (
                                    <FormGroupButton>
                                        <FakeLabel />
                                        <a
                                            href={`${
                                                props.baseDownloadPath
                                            }/${encodeURIComponent(
                                                values.filesContent[index].path,
                                            )}`}
                                            download
                                        >
                                            <DownloadButton />
                                        </a>
                                    </FormGroupButton>
                                )}
                        </FormDirectionRow>
                    ))}

                    {!disabled && (
                        <FormDirectionRow stretch>
                            <FormGroupButton>
                                <ButtonColored
                                    type="button"
                                    onClick={() => push({ name: "", path: "" })}
                                >
                                    {__("Добавить файл")}
                                </ButtonColored>
                            </FormGroupButton>
                        </FormDirectionRow>
                    )}
                </>
            )}
        </FieldArray>
    );
};
