import { Logo } from "components/logo";
import { SitePartitionType } from "views/site-patrition-wrapper";
import { connect } from "react-redux";
import { statesSelectors } from "services/redux/root/states/selectors";
import { LogoContainer, MainMenuSC } from "../main-menu/styled";
import { AsideContent } from "../main-menu";
import { MainMenuContent } from "../main-menu/main-menu-content";

interface IInjectedProps {
    isMainMenuClicked: boolean;
}

export interface IDesktopMainMenuProps extends IInjectedProps {
    adminRole: SitePartitionType;
}

export const DesktopMainMenu = connect(state => ({
    isMainMenuClicked: statesSelectors.getIsMainMenuClicked(state),
}))(
    React.memo((props: IDesktopMainMenuProps) => {
        const [menuScrollOffset, setMenuScrollOffset] =
            React.useState<number>(0);
        const { adminRole, isMainMenuClicked, ...rest } = props;

        const updateMnuScrollOffsetHandler = React.useCallback(
            (offset: number) => {
                setMenuScrollOffset(offset);
            },
            [],
        );

        return (
            <MainMenuSC
                isMainMenuClicked={isMainMenuClicked}
                menuScrollOffset={menuScrollOffset}
                {...rest}
            >
                <AsideContent
                    updateMenuScrollOffset={updateMnuScrollOffsetHandler}
                >
                    <LogoContainer>
                        <Logo />
                    </LogoContainer>

                    <MainMenuContent adminRole={adminRole} />
                </AsideContent>
            </MainMenuSC>
        );
    }),
) as React.ComponentType<Subtract<IDesktopMainMenuProps, IInjectedProps>>;
