import { PostbacksForm } from "../form";
import { getFormData, IPostbackOutputValues } from "../services/form-helper";
import { useCreate } from "../hooks/use-create";
import { CreatePostbackVariables } from "gql/types/operation-result-types";
import { transformFromFormikToRequest } from "../services/form-helper";
import { successHandler, SuccessTypes } from "services/success-handler";
import { errorHandler } from "services/error-handler";
import { useContext } from "react";
import {
    PickCurrentLineContext,
    SetColumnData,
} from "components/get-active-link-context";
import { useLocalizedAbsolutePush } from "hooks/use-localised-absolute-push";

export const CreatePostback = () => {
    const { createPostback } = useCreate();
    const { setColumnData } = useContext(PickCurrentLineContext);
    const push = useLocalizedAbsolutePush();
    return (
        <PostbacksForm
            initialValues={getFormData.getInitialState()}
            onSubmit={handleSubmit(createPostback, push, setColumnData)}
            id={null}
        />
    );
};

const handleSubmit =
    (
        createPostback: (
            variables: CreatePostbackVariables,
        ) => Promise<number | undefined>,
        push: ReturnType<typeof useLocalizedAbsolutePush>,
        setColumnData: SetColumnData,
    ) =>
    async (state: IPostbackOutputValues) => {
        try {
            const filter = transformFromFormikToRequest(state);
            const id = await createPostback({ filter });
            setColumnData({ name: "id", id, tableName: "Postbacks" });
            push("/partner/marketing-tools/postbacks");
            successHandler(SuccessTypes.CREATE_POSTBACK, id);
        } catch (e: any) {
            void errorHandler(e);
            // пробрасываем ошибку для дальнейшей обработки.
            throw e;
        }
    };
