import { css } from "styled-components";

const fontsPath = "../assets/fonts/noto-sans/";

export const NotoSansFonts = css`
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}NotoSans-ExtraLight.ttf") format("truetype");
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}NotoSans-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}NotoSans-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}NotoSans-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}NotoSans-SemiBold.ttf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}NotoSans-Bold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}NotoSans-ExtraBold.ttf") format("truetype");
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: "NotoSans";
        src: url("${fontsPath}NotoSans-Black.ttf") format("truetype");
        font-weight: 900;
        font-style: normal;
    }
`;
