import { css } from "styled-components";

const fontsPath = "../assets/fonts/pfd-in-display-pro/";

export const PFDinTextCondProFonts = css`
    @font-face {
        font-family: "PFDinTextCondPro";
        src: url("${fontsPath}PFDinTextCondPro-Bold.woff2") format("woff2"),
            url("${fontsPath}PFDinTextCondPro-Bold.woff") format("woff");
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "PFDinDisplayPro";
        src: url("${fontsPath}PFDinDisplayPro-Regular.woff2") format("woff2"),
            url("${fontsPath}PFDinDisplayPro-Regular.woff") format("woff"),
            url("${fontsPath}PFDinDisplayPro-Regular.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}PFDinDisplayPro-Regular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "PFDinDisplayPro";
        src: url("${fontsPath}PFDinDisplayPro-Bold.woff2") format("woff2"),
            url("${fontsPath}PFDinDisplayPro-Bold.woff") format("woff"),
            url("${fontsPath}PFDinDisplayPro-Bold.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}PFDinDisplayPro-Bold.ttf") format("truetype");
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "PFDinDisplayPro";
        src: url("${fontsPath}PFDinDisplayPro-Light.woff2") format("woff2"),
            url("${fontsPath}PFDinDisplayPro-Light.woff") format("woff"),
            url("${fontsPath}PFDinDisplayPro-Light.eot?#iefix")
                format("embedded-opentype"),
            url("${fontsPath}PFDinDisplayPro-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
    }
`;
