import {
    GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans,
    AdvertisementStatus,
    GetPlan_authorized_manager_advertising_finantialPlans_plan,
} from "gql/types/operation-result-types";
import { SetStateAction } from "react";
import {
    AddPayment,
    useDefaultInitilValues,
} from "views/manager/advertising/adversiting-payments/components/add-payment";
import { convertToAmountRUB } from "views/manager/advertising/adversiting-payments/components/common-form-advertising/convert-to-amount-rub";
import { useCurrencyAdversitingPayments } from "hooks/query/use-currency-adversiting-payments";
import { CreatedFinplan } from "../../add-fin-plan-steps/add-contract";
import { Title } from "services/styled-components/typogoraphy";
import { useTranslation } from "react-i18next";
import { usePaymentSystemsWithFields } from "hooks/query/use-payment-systems-with-fields";
import { useCountries } from "hooks/query/use-countries";

interface IProps {
    setPaymentPlans: (
        value: SetStateAction<
            // eslint-disable-next-line max-len
            | GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans[]
            | undefined
        >,
    ) => void;
    finplan:
        | CreatedFinplan
        | GetPlan_authorized_manager_advertising_finantialPlans_plan;
    // eslint-disable-next-line max-len
    paymentPlan: GenerateAdversitingPaymentsPlans_authorized_manager_advertising_finantialPlans_generateAdversitingPaymentsPlans;
}

export function CreatePaymentForPaymentPlan(props: IProps) {
    const [__] = useTranslation();
    let initialValues = useDefaultInitilValues(props.finplan.partnerId);
    const { data: currencyList } = useCurrencyAdversitingPayments();
    const { countries } = useCountries();
    const currency =
        currencyList &&
        currencyList.find(
            crn => crn.id === props.finplan.contract.advCurrencyId,
        );

    const paymentSystemFromFinplan = props.finplan.contract
        .paymentMethod as ReturnType<
        typeof usePaymentSystemsWithFields
    >["paymentSystems"][0];
    const companyFromFinPlan = (
        props.finplan as GetPlan_authorized_manager_advertising_finantialPlans_plan
    ).contract.company;
    const countryIdFromFinPlan = props.finplan.contract.countryId;

    initialValues = {
        ...initialValues,
        currencyAdversitingPayments: currency || null,
        amountRequest: props.paymentPlan.amount,
        amountRequestRUB: convertToAmountRUB(
            props.paymentPlan.amount,
            currency,
        ),
        contractPlacements: props.finplan.contract.contractPlacements.map(
            placement => {
                return {
                    type: placement.type,
                    typeName: placement.typeName,
                    sum: placement.percentSum
                        ? props.paymentPlan.amount * (placement.sum / 100)
                        : placement.sum,
                    percentSum: placement.percentSum,
                };
            },
        ),
        dealType: "Offline",
        paymentSystem: paymentSystemFromFinplan || initialValues.paymentSystem,
        company: companyFromFinPlan?.id || initialValues.company,
        country: countryIdFromFinPlan
            ? countries?.find(country => country.id === countryIdFromFinPlan)
            : initialValues.country,
        filesContent: (props.finplan.contract.filePaths || []).map(
            filePath => ({
                name: filePath.split("/").pop() || "",
                path: filePath,
            }),
        ),
    };
    return (
        <>
            <Title>{__("Добавление рекламной выплаты")}</Title>
            <AddPayment
                planId={props.finplan.id}
                paymentPlanId={props.paymentPlan.id}
                onPaymentAdded={payment => {
                    props.setPaymentPlans(oldPaymentPlans => {
                        if (!oldPaymentPlans) {
                            return;
                        }
                        const newPaymentPlans = [...oldPaymentPlans];
                        const paymentPlanIndex = newPaymentPlans.findIndex(
                            paymentPlan =>
                                paymentPlan.id === props.paymentPlan.id,
                        );
                        const paymentPlan = newPaymentPlans[paymentPlanIndex];
                        const newPaymentPlan = {
                            ...paymentPlan,
                            advPaymentId: payment.id,
                            advPaymentAmount: payment.amountRequest,
                            advPaymentCurrencyName:
                                currencyList?.find(
                                    cur => cur.id === payment.currencyId,
                                )?.name || "",
                            // should we use mutation result? Status new is ok here
                            advPaymentStatus: AdvertisementStatus.NEW,
                        };
                        newPaymentPlans[paymentPlanIndex] = newPaymentPlan;
                        return newPaymentPlans;
                    });
                }}
                initialValues={initialValues}
                advertisingCompany={companyFromFinPlan}
            />
        </>
    );
}
