import { LoadingText } from "components/react-table/loading-text";
import { Export } from "./export";
import {
    GetPlayersReport,
    GetPlayersReportVariables,
} from "gql/types/operation-result-types";
import React from "react";
import { ReportReactTable } from "components/react-table/report-react-table";
import { IReactTableColumn } from "components/react-table/column/";
import { useTranslation } from "react-i18next";
import { QueryResult } from "@apollo/client";
import { SubmitValues, generalQueryParams } from "../general-query-params";
import { ITablePagination } from "hooks/use-pagination";

interface IProps {
    actualValues?: SubmitValues;
    queryResult?: QueryResult<GetPlayersReport, GetPlayersReportVariables>;
    externalPaginationAndSorting: ITablePagination;
}

export const PlayersReportEmptyTable = ({
    actualValues,
    queryResult,
    externalPaginationAndSorting,
}: IProps) => {
    const [__] = useTranslation();
    const currency = actualValues?.currency?.name || "";

    /*
        ВНИМАНИЕ!
        Конфигурация minWidth была подобрана с учётом комфорта юзера по запросу менеджера Евгения Веремьева.
        Пожалуйста, не меняйте minWidth по собственному усмотрению. Сначала согласуйте с Евгением.
    */
    const columns: IReactTableColumn[] = [
        {
            Header: __("ID сайта"),
            accessor: "siteId",
            minWidth: 60,
        },
        {
            Header: __("Сайт"),
            accessor: "siteName",
            disabled: true,
            minWidth: 100,
        },
        {
            Header: __("ID Инструмента"),
            accessor: "mediaId",
            minWidth: 60,
        },
        {
            Header: __("SUBID"),
            accessor: "subId",
            minWidth: 60,
        },
        {
            Header: "CLICKID",
            accessor: "clickId",
            minWidth: 60,
        },
        {
            Header: __("ID игрока"),
            accessor: "playerId",
            disabled: true,
            minWidth: 60,
        },
        {
            Header: __("Дата регистрации"),
            accessor: "registrationDate",
            dataType: "date",
            minWidth: 70,
        },
        {
            Header: __("Страна"),
            accessor: "country",
            minWidth: 90,
        },
        {
            Header: __("Сумма депозитов"),
            accessor: "depositAmount",
            dataType: "currency",
            currency,
            minWidth: 80,
        },
        {
            Header: __("Сумма ставок"),
            accessor: "betsAmount",
            dataType: "currency",
            currency,
            minWidth: 80,
        },
        {
            Header: __("Сумма бонусов"),
            accessor: "bonusAmount",
            dataType: "currency",
            currency,
            minWidth: 80,
        },
        {
            Header: __("Доход компании(общий)"),
            accessor: "companyProfit",
            dataType: "currency",
            currency,
            minWidth: 90,
        },
        {
            Header: __("RS"),
            accessor: "comissionRS",
            dataType: "currency",
            currency,
            minWidth: 80,
        },
        {
            Header: __("CPA"),
            accessor: "CPA",
            dataType: "currency",
            currency,
            minWidth: 80,
        },
        {
            Header: __("Сумма комиссий"),
            accessor: "comissionAmount",
            dataType: "currency",
            currency,
            minWidth: 80,
        },
        {
            Header: __("Hold time"),
            accessor: "holdTime",
            dataType: "date",
            minWidth: 80,
        },
        {
            Header: __("Заблокирован"),
            accessor: "blocked",
            dataType: "boolean",
            minWidth: 80,
        },
    ];

    return (
        // Конфигурация minWidth была подобрана с учётом комфорта юзера по запросу менеджера Евгения Веремьева.
        // Пожалуйста, не меняйте ей по собственному усмотрению. Сначала согласуйте с Евгением.
        <ReportReactTable
            exportComponent={
                actualValues && (
                    <Export filters={generalQueryParams(actualValues)} />
                )
            }
            reportName="Players"
            columns={columns}
            shownColumnsByDefault={[
                "siteId",
                "siteName",
                "subId",
                "playerId",
                "registrationDate",
                "country",
                "depositAmount",
                "companyProfit",
            ]}
            queryResult={queryResult}
            externalPaginationAndSorting={externalPaginationAndSorting}
            data={
                queryResult?.data?.authorized.partner.reports.playersReport
                    .rows || []
            }
            total={
                queryResult?.data?.authorized.partner.reports.playersReport
                    .total
            }
            reportStatus={
                queryResult?.data?.authorized.partner.reports.playersReport
                    .status
            }
            dataLength={
                queryResult?.data?.authorized.partner.reports.playersReport
                    .pagesCount
            }
            loadingText={<LoadingText fastLoading={false} />}
        />
    );
};
