import {
    GetCampaigns_authorized_partnerAndManager_data_campaigns,
    GetCountries_countries,
    GetLanguages_languages,
    GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes,
    GetMediaTypes_authorized_partner_data_mediaTypes,
    GetRegistrationSources_registrationSources,
    GetCurrencies_authorized_partnerAndManager_data_currencies,
    GetPartnersGroups_authorized_manager_main_partnersGroups_partnerGroups,
    GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments,
    PartnerProgramRegistrationSources_authorized_manager_data_partnerProgramRegistrationSource,
    GetCommissionGroups_authorized_manager_main_commisionGroups,
    PartnerSites_authorized_partnerAndManager_data_sites,
    CampaignStatus,
} from "gql/types/operation-result-types";
import { Periods } from "../fields/period";
import { getDatesByPeriod } from "../services/get-dates-by-period";

export const defaultInitialValues = {
    // TODO: currency и другие поля могут быть необязательными если задана uniqueValidation
    // но uniqueValidation не учитывается в типизации выходных параметров
    currency: undefined as
        | GetCurrencies_authorized_partnerAndManager_data_currencies
        | undefined,
    campaign: undefined as
        | GetCampaigns_authorized_partnerAndManager_data_campaigns
        | undefined,
    period: {
        interval: Periods.TODAY,
        ...getDatesByPeriod(Periods.TODAY),
    },
    website: undefined as
        | PartnerSites_authorized_partnerAndManager_data_sites
        | undefined,
    mediaType: undefined as
        | GetMediaTypes_authorized_partner_data_mediaTypes
        | undefined,
    marketingToolsType: undefined as
        | GetMarketingToolsTypes_authorized_partner_data_marketingToolsTypes
        | undefined,
    country: undefined as GetCountries_countries | undefined,
    language: undefined as GetLanguages_languages | undefined,
    commisionGroup: undefined as
        | GetCommissionGroups_authorized_manager_main_commisionGroups
        | undefined,
    partnersGroup: undefined as
        | GetPartnersGroups_authorized_manager_main_partnersGroups_partnerGroups
        | undefined,
    registrationSource: undefined as
        | GetRegistrationSources_registrationSources
        | undefined,
    partnerProgramRegistrationSource: undefined as
        | PartnerProgramRegistrationSources_authorized_manager_data_partnerProgramRegistrationSource
        | undefined,
    currencyAdversitingPayments: undefined as  // eslint-disable-next-line max-len
        | GetCurrenciesAdversitingPayments_authorized_manager_advertising_advertisingPayments_currencyAdvertisingPayments
        | undefined,
    marketingToolId: undefined as number | undefined,
    width: undefined as number | undefined,
    height: undefined as number | undefined,
    playerId: undefined as number | undefined,
    websiteId: undefined as number | undefined,
    partnerId: undefined as number | undefined,
    campaignId: undefined as number | undefined,
    campaignName: undefined as string | undefined,
    campaignStatus: undefined as CampaignStatus | undefined,
    mockPeriod: "2" as string | undefined,
    siteCategory: undefined as string | undefined,
    onlyNewPlayers: undefined as boolean | undefined,
    withoutDepositsOnly: undefined as boolean | undefined,
    promoCode: undefined as string | undefined,
    landing: undefined as string | undefined,
    subId: undefined as string | undefined,
    websiteName: undefined as string | undefined,

    mediaName: undefined as string | undefined,

    whoCreated: undefined as string | undefined,
};
