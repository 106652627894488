import { FormikHelpers as FormikActions } from "components/formik";
import {
    EditPassword as PartnerEditPasswordMutation,
    EditPasswordVariables,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { MutationFunction, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { errorHandler } from "services/error-handler";
import { successHandler, SuccessTypes } from "services/success-handler";
import { AdminWrapperEdit } from "styled/layouts";
import {
    initialValues as initialValuesResetPassword,
    ChangePasswordForm,
} from "../../change-password";
import EDIT_PARTNER_PASSWORD from "./partner-edit-password.gql";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const ChangePassword = (props: IProps) => {
    const [mutation] = useMutation<
        PartnerEditPasswordMutation,
        EditPasswordVariables
    >(EDIT_PARTNER_PASSWORD);
    const [__] = useTranslation();

    return (
        <AdminWrapperEdit>
            <ChangePasswordForm
                onSubmit={onSubmitChangePassword(mutation, __)}
            />
        </AdminWrapperEdit>
    );
};

const onSubmitChangePassword =
    (
        mutation: MutationFunction<
            PartnerEditPasswordMutation,
            EditPasswordVariables
        >,
        __: TFunction,
    ) =>
    async (
        {
            oldPassword,
            password,
            confirmPassword,
        }: typeof initialValuesResetPassword,
        formikActions: FormikActions<typeof initialValuesResetPassword>,
    ) => {
        try {
            await mutation({
                variables: {
                    oldPassword,
                    password,
                    confirmPassword,
                },
            });

            successHandler(SuccessTypes.EDIT_PARTNER_ACCOUNT);
            formikActions.resetForm();
        } catch (e: any) {
            void errorHandler(e, () => {
                return __("Ошибка смены пароля");
            });
        }
    };
