import { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
    PartnerGroups as IPartnerGroups,
    PartnerGroupsVariables,
    PartnerGroups_authorized_manager_main_partnerGroups_data,
    DeletePartnerPartnerGroup,
    DeletePartnerPartnerGroupVariables,
} from "gql/types/operation-result-types";
import { usePaginationAndSorting } from "hooks/use-pagination";
import { PickCurrentLineContext } from "components/get-active-link-context";
import { AdminWrapper } from "styled/layouts";
import { ReactTable } from "components/react-table";
import { AddPartnerGroupForm } from "./add-partner-group-form";
import { EditPartnerGroupForm } from "./edit-partner-group-form";
import GET_PARTNER_GROUPS from "./get-partner-groups.gql";
import DELETE_PARTNER_GROUP from "./delete-partner-group.gql";
import { useColumns } from "./use-columns";

export const PartnerGroups = () => {
    const { id: partnerId } = useParams<{ id: string }>();
    const [groupToEdit, setGroupToEdit] =
        useState<PartnerGroups_authorized_manager_main_partnerGroups_data | null>(
            null,
        );
    const { setColumnData } = useContext(PickCurrentLineContext);
    const externalPaginationAndSorting = usePaginationAndSorting();
    const partnerGroupsVariables: PartnerGroupsVariables = {
        params: {
            id: Number(partnerId),
            ...externalPaginationAndSorting.state,
        },
    };
    const { data, loading } = useQuery<IPartnerGroups, PartnerGroupsVariables>(
        GET_PARTNER_GROUPS,
        {
            variables: partnerGroupsVariables,
        },
    );
    const [deleteGroup, { loading: loadingDeleteGroup }] = useMutation<
        DeletePartnerPartnerGroup,
        DeletePartnerPartnerGroupVariables
    >(DELETE_PARTNER_GROUP, {
        onCompleted: () => toast.success("Группа удалена"),
        onError: () => toast.error("Группа не удалена"),
        refetchQueries: [
            {
                query: GET_PARTNER_GROUPS,
                variables: partnerGroupsVariables,
            },
        ],
    });
    const columns = useColumns({
        disabled: !!groupToEdit || loadingDeleteGroup,
        onEdit: group => setGroupToEdit(group),
        onDelete: id => deleteGroup({ variables: { id } }),
    });
    const response = data?.authorized.manager.main.partnerGroups;

    useEffect(
        () => () => {
            // сбрасываем после редактирования в EditPartnerGroupForm
            setColumnData({
                name: "",
                id: null,
                tableName: "",
            });
        },
        [setColumnData],
    );

    return (
        <AdminWrapper>
            {groupToEdit ? (
                <EditPartnerGroupForm
                    group={groupToEdit}
                    paginationAndSortingState={
                        externalPaginationAndSorting.state
                    }
                    resetGroup={() => setGroupToEdit(null)}
                />
            ) : (
                <AddPartnerGroupForm
                    paginationAndSortingState={
                        externalPaginationAndSorting.state
                    }
                />
            )}
            <ReactTable
                tableName="partner-groups"
                columns={columns}
                data={response?.data}
                dataLength={response?.totalCount}
                loading={loading}
                externalPaginationAndSorting={externalPaginationAndSorting}
            />
        </AdminWrapper>
    );
};
