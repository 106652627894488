import { ReactTable } from "components/react-table";
import { useCommissionGroups } from "hooks/query/use-commission-groups";
import { useCommissionGroupsColumns } from "views/manager/main/commission-groups/commission-groups-table";

export const CommissionGroups = React.memo(() => {
    const columns = useCommissionGroupsColumns();
    const { data, loading } = useCommissionGroups();

    return (
        <ReactTable
            columns={columns}
            shownColumnsByDefault={["id", "name", "currency"]}
            data={data}
            loading={loading}
        />
    );
});
