import {
    GetCommissionList_authorized_partner_comissionStructures_description_RevenueShareDescription,
    CalculationType,
} from "gql/types/operation-result-types";
import { TFunction } from "i18next";
import { formatCurrency } from "services/project-utils";
import { getDescriptionDate, IDescriptionFoo } from "../comission-description";

export const getRevenueDescription = (
    structureList: IDescriptionFoo,
    __: TFunction,
) => {
    const currentComission = g(
        structureList,
        "description",
    ) as GetCommissionList_authorized_partner_comissionStructures_description_RevenueShareDescription;

    const {
        calculationType,
        comissionPersent,
        negativeComission,
        adminPersent,
        dateStart,
        dateEnd,
        currency,
        comissionPercentAboveLevels,
        levels,
    } = currentComission;

    const percentageAndLevelsStr = !levels?.length
        ? format(__("Процент комиссии: {persent}%"), {
              persent: comissionPersent,
          }) + "; "
        : "";

    const negativeComissionStr = format(
        __("Отрицательная комиссия: {comission}"),
        {
            comission: negativeComission ? __("Да") : __("Нет"),
        },
    );

    const admin = format(__("Администраторский: {persent}%"), {
        persent: adminPersent ? adminPersent : 0,
    });

    const getType = () => {
        switch (calculationType) {
            case CalculationType.PROFIT:
                return __("Доход");
            case CalculationType.DEPOSIT_AMOUNT:
                return __("Сумма депозитов");
            case CalculationType.BETS_AMOUNT:
                return __("Сумма ставок");
            default:
                return "";
        }
    };
    const calculationTypeStr = calculationType
        ? format(__("Тип расчета MLM: {type}"), {
              type: getType(),
          }) + "; "
        : "";

    let levelsStrData = "";
    let levelsStr = "";

    if (levels?.length) {
        let strCalculationTypes =
            __("Тип MLM") + ": " + __("Номер уровня") + "/";
        const strCommissionAboveLevels =
            __("Комиссия % при превышении установленных уровней") +
            ": " +
            `${comissionPercentAboveLevels}%` +
            "; ";

        levelsStrData = levels
            .map(item => {
                const { order, calculations, comissionPercent } = item;
                let str = `${order}/`;
                let strCalculationValues = "";

                calculations?.forEach(calculation => {
                    switch (calculation.type) {
                        case CalculationType.PROFIT:
                            strCalculationValues += `${formatCurrency(
                                calculation.value,
                            )}(${currency})/`;
                            break;
                        case CalculationType.COUNT_OF_REGISTRATIONS_WITH_DEPOSITS:
                            strCalculationValues += `${calculation.value}/`;
                            break;
                        case CalculationType.NEW_DEPOSITS_SUM:
                            strCalculationValues += `${formatCurrency(
                                calculation.value,
                            )}(${currency})/`;
                            break;
                        default:
                            return "";
                    }
                });
                str += `${strCalculationValues}${comissionPercent}%`;
                return str;
            })
            .join(" | ");

        levels[0].calculations?.forEach(calculation => {
            switch (calculation.type) {
                case CalculationType.PROFIT:
                    strCalculationTypes += __("Доход") + "/";
                    break;
                case CalculationType.COUNT_OF_REGISTRATIONS_WITH_DEPOSITS:
                    strCalculationTypes += __("Регистрация с депозитом") + "/";
                    break;
                case CalculationType.NEW_DEPOSITS_SUM:
                    strCalculationTypes += __("Сумма новых депозитов") + "/";
                    break;
                default:
                    return "";
            }
        });
        strCalculationTypes += __("Комиссия %");
        levelsStrData +=
            " " +
            `${strCalculationTypes}` +
            "; " +
            `${strCommissionAboveLevels}`;
        levelsStr = format(__("Уровни: {comissionLevels}"), {
            comissionLevels: levelsStrData,
        });
    }

    const date = getDescriptionDate(dateStart, dateEnd);

    return `${percentageAndLevelsStr}${negativeComissionStr}; ${admin}; ${calculationTypeStr}${levelsStr}${date}`;
};
