import { Field } from "components/formik";
import { useTranslation } from "react-i18next";
import { usePartnerStatuses } from "services/project-utils";
import { ISelectFild } from "components/filters/select-filed-interface";

export function StatusField(props: ISelectFild) {
    const [__] = useTranslation();
    const statuses = usePartnerStatuses();

    return (
        <Field
            label={__("Статус")}
            type="react-select"
            options={Array.from(statuses.entries()).map(([key, value]) => ({
                value: key,
                label: value,
            }))}
            isLoading={false}
            placeholder={__("Все")}
            minWidth={200}
            {...props}
        />
    );
}
