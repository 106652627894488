import * as yup from "yup";
import { phoneRegex, createWebsiteRule } from "services/validators";
import { useTranslationList } from "hooks/use-translations-list";
import { useObjectValidations } from "validation/validaion";
import {
    GetCountries_countries,
    GetLanguages_languages,
    PartnerStatus,
} from "gql/types/operation-result-types";

export const useValidationSchema = () => {
    const {
        AT_LEAST_MIN,
        UP_TO_MAX,
        AT_LEAST_MIN_CHARACTERS,
        UP_TO_MAX_CHARACTERS,
        FIELD_SHOULD_BE_FILLED,
        INVALID_FORMAT,
    } = useTranslationList();
    const { email, interger, nullableInterger, objectRequiredRule } =
        useObjectValidations();

    return yup.object({
        url: createWebsiteRule(INVALID_FORMAT)
            .max(255, UP_TO_MAX_CHARACTERS)
            .required(FIELD_SHOULD_BE_FILLED),
        phoneNumber: yup.string().matches(phoneRegex, INVALID_FORMAT),
        email: email(true)
            .min(6, AT_LEAST_MIN_CHARACTERS)
            .max(50, UP_TO_MAX_CHARACTERS),
        status: yup.string().oneOf(Object.values(PartnerStatus)),
        country: objectRequiredRule<GetCountries_countries>(),
        language: objectRequiredRule<GetLanguages_languages>(),
        currencies: yup
            .array()
            .of(yup.string())
            .required(FIELD_SHOULD_BE_FILLED),
        partnerId: interger,
        note: yup.string(),
        setCodeUsageLimit: nullableInterger
            .min(0, AT_LEAST_MIN)
            .max(2147483647, UP_TO_MAX),
    });
};
